<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Annual Returns">
        <Escalation :task-id="task.taskId" type="annual-returns" permission="can_view_annual_returns_escalations"/>
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.reason"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <p v-if="form.reason" class="my-2 text-xs">{{form.reason.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row class="my-1 mx-1">
      <b-col>
        <b-row class="mt-1">
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{task.data.companyName}}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Application Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Years Filing</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.data.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <p class="text-gray text-capitalize" v-for="(year, idx) in task.data.years" :key="idx">- {{year}}</p>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <PRNViewer :tracking-number="task.data.trackingNo"/>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-row>
              <b-col>
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Filing Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <AnnualReturnForms :task="task.data" v-if="currentRoute === 'forms'"/>
                <AnnualReturnFormFiles :task="task.data" v-if="currentRoute === 'attachments'"/>
                <AnnualReturnMessages :task-id="task.data.taskId" v-if="currentRoute === 'messages'"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Escalation from "@/views/home/commons/Escalation.vue";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import AnnualReturnForms from "@/views/home/services/annual-returns/task/AnnualReturnForms.vue";
import AnnualReturnFormFiles from "@/views/home/services/annual-returns/task/AnnualReturnFormFiles.vue";
import AnnualReturnMessages from "@/views/home/services/annual-returns/task/AnnualReturnMessages.vue";

export default {
  name: "AnnualReturnsTask",
  components: { AnnualReturnMessages, AnnualReturnFormFiles, AnnualReturnForms, PRNViewer, Escalation },
  props:{task:Object},
  data(){
    return{
      margin:"2px",
      currentRoute: "forms",
      loading:false,
      messages:[],
      taskId:null,
      maxText:500,
      snapshot:null,
      snapshots:[],
      form:{
        formId:null,
        queueId:null,
        reason:null,
        status:null,
      },
    }
  },
  methods:{
    ...mapActions(["updateAnnualReturnsStatus", "fetchAnnualReturnsTask"]),
    onApprove(){
      this.form.queueId = this.task.taskId
      this.form.formId = this.task.data.id
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.form.queueId = this.task.taskId
      this.form.formId = this.task.data.id
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.reason != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateAnnualReturnsStatus(this.form)
      }

      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    }
  },
  computed:{
    ...mapGetters(["getAnnualReturnsTask", "getAnnualReturnsUpdateStatusSuccess", "getAnnualReturnsUpdateStatusSuccessError"])
  },
  watch:{
    getAnnualReturnsUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      window.location.reload()
    },
    getAnnualReturnsUpdateStatusSuccessError(data){
      this.loading = false
      this.errorToast("Error!", data.message)
    }
  }
};
</script>

<style scoped>

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

</style>