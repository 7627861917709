import {api} from "@/app/services/api.service";

const state = {
    charge_registry:null,
    charge_file:null,
    charge_error:null
};

const getters = {
    getChargeRegistry: (state) => state.charge_registry,
    getChargeError: (state) => state.charge_error,
};

const actions = {
    async fetchChargeRegistry({commit}, data) {
        let path = "/incorporation/registry/charges?page="+data.page+"&pageSize="+data.limit
        if(data.search){
            path+="&search="+data.search
        }
        if(data.startAmountSecured){
            path+="&startAmountSecured="+data.startAmountSecured
        }
        if(data.endAmountSecured){
            path+="&endAmountSecured="+data.endAmountSecured
        }
        if(data.status){
            path+="&status="+data.status
        }
        if(data.startDate){
            path+="&startDate="+data.startDate
        }
        if(data.endDate){
            path+="&endDate="+data.endDate
        }

        await api()
            .get(path)
            .then(response => {
                commit("setChargeRegistry", response.data);
            })
            .catch(error => {
                commit("setChargeError", error.response);
            });
    },
};

const mutations = {
    setChargeRegistry: (state, response) => {
        state.charge_registry = response;
    },
    setChargeError: (state, response) => {
        state.charge_error = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
