import {api} from "@/app/services/api.service";

const state = {
    practitioner_registry:null,
    practitioner_details:null,
    error:null
};

const getters = {
    getPractitionerRegistry: (state) => state.practitioner_registry,
    getPractitionerDetails: (state) => state.practitioner_details,
    getPractitionerRegistryError: (state) => state.error,
};

const actions = {
    async fetchPractitionerRegistry({commit}, data) {
        await api()
            .get("/insolvency/practitioner/registry"+data)
            .then(response => {
                commit("setPractitionerRegistry", response.data);
            })
            .catch(error => {
                commit("setPractitionerRegistryError", error.response);
            });
    },
    async fetchPractitionerDetails({commit}, id) {
        await api()
          .get("/insolvency/api/practitioners/unique-practitioner-number/"+id)
          .then(response => {
              commit("setPractitionerDetails", response.data);
          })
          .catch(error => {
              commit("setPractitionerRegistryError", error.response);
          });
    },
};

const mutations = {
    setEDMSFile: (state, response) => {
        state.edms_file = response;
    },
    setPractitionerRegistry: (state, response) => {
        state.error = null;
        state.practitioner_registry= response;
    },
    setPractitionerDetails: (state, response) => {
        state.practitioner_details = response;
    },
    setPractitionerRegistryError: (state, response) => {
        state.error = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
