<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="mx-auto mt-2">

          <b-row class="mx-1">
            <b-col cols="4">
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Date of Commencement"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="file.dateOfCommencement"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mx-1 mt-3">
            <b-col cols="4">
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="ISIC Code"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="file.natureOfBusiness.code"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Nature of Business"
                label-for="input-1">
                <b-form-textarea
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="file.natureOfBusiness.description"
                  required>
                </b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-card title="ISIC Breakdown">
                <b-card-body>
                  <p v-for="(level, idx) in file.natureOfBusiness.breakdown" :key="idx" :class="'ml-'+ (idx * 3)">
                    <span :class="[(idx === file.natureOfBusiness.breakdown.length - 1) ? 'font-bold' : '']">
                      -> {{level}}
                    </span>
                  </p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: "BusinessNameDetails",
  props:{
    file:Object
  },
}
</script>

<style scoped>

</style>