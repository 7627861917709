<template>
  <div>
      <b-row v-if="task.brn">

          <b-col cols="12">
              <div class="flex flex-column">
                  <span class="font-bold text-blue h5">{{ task.brn }}</span>
                  <span class="font-bold text-gray h3">{{ task.companyName }}  </span>
              </div>
              <div class="flex mt-1">
                  <b-badge class="bg-blue text-capitalize">
                      Entity Type
                  </b-badge>
                  <b-badge class="mx-1 bg-green text-capitalize">
                      {{ cleanEntityType(task.entityType) }}
                  </b-badge>
              </div>
              <span class="italic text-gray text-xs font-bold" v-if="task.dateOfIncorporation">Registration Date: {{ dateFormat(task.dateOfIncorporation) }}

                </span><br/>
          </b-col>
      </b-row>
      <b-row v-if="task.payment">
          <b-col cols="12">
              <PRNViewer  :tracking-number="task.trackingNumber"/>
          </b-col>
      </b-row>
      <b-row >
          <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2">Application Details</td>
                  </tr>
                  <tr>
                      <th class="px-2 py-1">Tracking Number</th>
                      <th class="px-2 py-1">Status</th>
                      <th class="px-2 py-1">Type</th>
                      <th class="px-2 py-1">Submission Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" >
                      <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
                      </td>
                      <td class="px-2 py-1">
                          <span class="text-blue text-capitalize">{{cleanUnderscores(task.status.toLowerCase())}}</span>
                      </td>
                      <td class="px-2 py-1">
                          <span class="text-gray text-capitalize">{{cleanEntityType(task.type.toLowerCase())}}</span>
                      </td>
                      <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.createdAt)}}
                  </span>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </b-col>
      </b-row>
    <b-row v-if="task != null">
        <b-col v-if="task.type ==='PETITION'">

            <table
                    v-if="task.amendments !== undefined"

                    class="min-w-full leading-normal my-1 text-gray"
                    style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2">Petition Details</td>
                </tr>
                <tr class="bg-gray-pale">
                    <td  class="px-2 py-2 font-bold">Amendment Number</td>
                    <td  class="px-2 py-2 font-bold">Nature Of Applicant	</td>
                    <td  class="px-2 py-2 font-bold">Nature Of Applicant Description	</td>
                    <td  class="px-2 py-2 font-bold">Subject	</td>
                    <td  class="px-2 py-2 font-bold">Complainants</td>
                    <td  class="px-2 py-2 font-bold">Respondents</td>
                </tr>
                </thead>
                <tbody>
                <tr  style="border: solid 1px #dedede;" >

                    <td class="px-2 py-1 ">{{ task.petition.amendmentNumber }}</td>
                    <td class="px-2 py-1 ">{{ task.petition.natureOfApplicant }}</td>
                    <td class="px-2 py-1 ">{{ task.petition.natureOfApplicantDescription }}</td>
                    <td class="px-2 py-1 ">{{ task.petition.subject }}</td>
                    <td class="px-2 py-1 ">{{ task.petition.complainants.forEach(com=>{
                        return com.name + ','
                    })  }}</td>
                    <td class="px-2 py-1 ">{{
                            task.petition.respondents.forEach(com=>{
                                return com.name + ','
                            })
                        }}</td>
                </tr>

                </tbody>
            </table>


            <table
                    v-if="task.amendments !== undefined"

                    class="min-w-full leading-normal my-1 text-gray"
                    style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2"> Amendments to the petition</td>
                </tr>
                <tr class="bg-gray-pale">
                    <td class="px-2 py-2 font-bold">amendmentNumber	1</td>
                    <td class="px-2 py-2 font-bold">natureOfApplicant	</td>
                    <td class="px-2 py-2 font-bold">natureOfApplicantDescription	</td>
                    <td class="px-2 py-2 font-bold">subject	</td>
                    <td class="px-2 py-2 font-bold">Complainants</td>
                    <td class="px-2 py-2 font-bold">Respondent</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(amendment,idx) in task.amendments" style="border: solid 1px #dedede;" :key="idx">

                    <td class="px-2 py-1 ">{{ amendment.amendmentNumber }}</td>
                    <td class="px-2 py-1 ">{{ amendment.natureOfApplicant }}</td>
                    <td class="px-2 py-1 ">{{ amendment.natureOfApplicantDescription }}</td>
                    <td class="px-2 py-1 ">{{ amendment.subject }}</td>
                    <td class="px-2 py-1 ">{{ amendment.complainants.forEach(com=>{
                        return com.name + ','
                    })  }}</td>
                    <td class="px-2 py-1 ">{{
                            amendment.respondents.forEach(com=>{
                                return com.name + ','
                            })
                        }}</td>
                </tr>

                </tbody>
            </table>

        </b-col>
      <b-col v-else cols="12" class="my-2">
          <b-row>
              <b-col>




                  <b-row v-if="task.boardResolution">
                      <b-col cols="12" :class="[task.boardResolution.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Board Resolution</label>
                          <label class="float-end" v-if="task.boardResolution.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>
                                  <p> Place Of Meeting: <b>{{ task.boardResolution.placeOfMeeting }}</b></p>
                                  <p>Date Of Meeting:
                                      <b>{{ dateFormat(task.boardResolution.dateOfMeeting) }}</b></p>


                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                  <label>
                                      Signatories
                                      <b-badge class="bg-blue text-capitalize">
                                          {{ task.boardResolution.resolutionSignatories.length }}
                                      </b-badge>
                                  </label>
                                  <div>
                                      <table  style="width: 90%">
                                          <thead>
                                          <th>#</th>
                                          <th>
                                              Name
                                          </th>
                                          </thead>
                                          <tbody>

                                          <tr v-for="(signatory, idx) in task.boardResolution.resolutionSignatories"
                                              :key="idx">
                                              <td>
                                                  {{idx+1}}
                                              </td>
                                              <td>
                                                  {{ signatory.signatoryName }}
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.specialResolution !== undefined">
                      <b-col v-if="task.specialResolution" cols="12" :class="[task.specialResolution.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Special Resolution</label>
                          <label class="float-end" v-if="task.specialResolution.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>
                                  <p> Place Of Meeting: <b>{{ task.specialResolution.placeOfMeeting }}</b></p>
                                  <p>Date Of Meeting:
                                      <b>{{ dateFormat(task.specialResolution.dateOfMeeting) }}</b></p>


                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                  <label>
                                      Signatories
                                      <b-badge class="bg-blue text-capitalize">
                                          {{ task.specialResolution.resolutionSignatories.length }}
                                      </b-badge>
                                  </label>
                                  <div>
                                      <table  style="width: 90%">
                                          <thead>
                                          <th>#</th>
                                          <th>
                                              Name
                                          </th>
                                          </thead>
                                          <tbody>

                                          <tr v-for="(signatory, idx) in task.specialResolution.resolutionSignatories"
                                              :key="idx">
                                              <td>
                                                  {{idx+1}}
                                              </td>
                                              <td>
                                                  {{ signatory.signatoryName }}
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.declarationOfSolvency !== undefined ">
                      <b-col v-if="task.declarationOfSolvency" cols="12" :class="[task.declarationOfSolvency.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Declaration of solvency</label>
                          <label class="float-end" v-if="task.declarationOfSolvency.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>


                                  <p>Date declared :
                                      <b>{{ dateFormat(task.declarationOfSolvency.dateOfDeclaration) }}</b></p>


                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                  <label>
                                      Signatories
                                      <b-badge class="bg-blue text-capitalize">
                                          {{ task.declarationOfSolvency.signatories.length }}
                                      </b-badge>
                                  </label>
                                  <div>
                                      <table  style="width: 90%">
                                          <thead>
                                          <th>#</th>
                                          <th>
                                              Name
                                          </th>
                                          </thead>
                                          <tbody>

                                          <tr v-for="(signatory, idx) in task.declarationOfSolvency.signatories"
                                              :key="idx">
                                              <td>
                                                  {{idx+1}}
                                              </td>
                                              <td>
                                                  {{ signatory.signatoryName }}
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row class="mt-2" v-if="task.finalReport !== undefined">
                      <b-col v-if="task.finalReport" cols="12" :class="[task.finalReport.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >
                          <label class="text-blue fw-bold text-lg">Final Report</label>
                          <label class="float-end" v-if="task.finalReport.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <table class="min-w-full leading-normal mt-1 text-gray"
                                 style="border: solid 1px #dedede;">
                              <thead>
                              <tr :class="[task.finalReport.status === 'APPROVED' ? 'bg-green-pale' : 'bg-gray-pale']">
                                  <td colspan="12" class="px-2 py-2 font-bold">Inspection Venue</td>
                              </tr>
                              </thead>
                              <tbody>
                              <b-row class="mx-1 py-1">
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Region"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.physicalAddress.region.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="District"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.physicalAddress.district.name.toLowerCase()"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Sub County"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.physicalAddress.subcounty.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Parish"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.physicalAddress.parish.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Village"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.physicalAddress.village.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Street"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.streetAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Plot"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Postal Address"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                          <PostalAddressValidator v-if="task.finalReport.postalAddress" :box-number="task.finalReport.postalAddress"/>
                                      </b-form-group>

                                      <b-form-group
                                              class="text-sm my-1 flex"
                                              id="input-group-1"
                                              label="Inspection time"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.finalReport.inspectionTime"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>

                                  </b-col>
                              </b-row>
                              </tbody>
                          </table>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.finalMeeting !== undefined" >
                      <b-col v-if="task.finalMeeting" cols="12" :class="[task.finalMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Final Meeting</label>
                          <label class="float-end" v-if="task.finalMeeting.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>


                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Type"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.meetingType"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Date"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.meetingDate"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Time"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.meetingTime"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>

                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Venue"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.meetingVenue"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Venue"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.proxyVenue"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Deadline Date"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.proxyDeadlineDate"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>

                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Deadline Time"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.proxyDeadlineTime"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Caller"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.meetingCallerName"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Position"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.finalMeeting.meetingCallerPosition"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>


                          </b-row>

                          <b-row>
                              <b-col>
                                  <label>Meeting Attendees</label>
                                  <table style="width: 100%">
                                      <thead>
                                      <tr class="bg-gray-pale border-1 text-black">
                                          <td>No.</td>
                                          <td>Name</td>
                                          <td>Position</td>
                                          <td>Phone</td>
                                          <td>Email</td>
                                      </tr>
                                      </thead>

                                      <tbody class="border-1">
                                      <tr v-for="(atd,idx) in task.finalMeeting.attendees" :key="idx">
                                          <td>{{idx+1}}</td>
                                          <td>{{ atd.entityBrn  ? atd.name+' on behalf of ('+atd.entityName+')' : atd.name

                                              }}</td>
                                          <td>{{atd.position}}</td>
                                          <td>{{atd.phone}}</td>
                                          <td>{{atd.email}}</td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.creditorMeeting !==undefined"  >

                      <b-col v-if="task.creditorMeeting" cols="12" :class="[task.creditorMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Creditors Meeting</label>
                          <label class="float-end" v-if="task.creditorMeeting.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>


                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Type"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingType"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Date"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingDate"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Time"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingTime"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>

                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Venue"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingVenue"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Venue"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.proxyVenue"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Deadline Date"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyNa"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.proxyDeadlineDate"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>

                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Deadline Time"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.proxyDeadlineTime"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Caller"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingCallerName"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Position"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingCallerPosition"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>


                          </b-row>

                          <b-row>
                              <b-col>
                                  <label>Meeting Attendees</label>
                                  <table style="width: 100%">
                                      <thead>
                                      <tr class="bg-gray-pale border-1 text-black">
                                          <td>No.</td>
                                          <td>Name</td>
                                          <td>Position</td>
                                          <td>Phone</td>
                                          <td>Email</td>
                                      </tr>
                                      </thead>

                                      <tbody class="border-1">
                                      <tr v-for="(atd,idx) in task.creditorMeeting.attendees" :key="idx">
                                          <td>{{idx+1}}</td>
                                          <td>{{ atd.entityBrn  ? atd.name+' on behalf of ('+atd.entityName+')' : atd.name

                                              }}</td>
                                          <td>{{atd.position}}</td>
                                          <td>{{atd.phone}}</td>
                                          <td>{{atd.email}}</td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>


                  </b-row>
                  <b-row v-if="task.practitionerAppointment !==undefined">
                      <b-col v-if="task.practitionerAppointment" cols="12" :class="[task.practitionerAppointment.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">

                          <label class="text-blue fw-bold text-lg">Appointed Practitioner </label>
                          <label class="float-end" v-if="task.practitionerAppointment.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>
                                  <p> Name: <b>{{ task.practitionerAppointment.practitioner.surName }} {{ task.practitionerAppointment.practitioner.givenName }}</b></p>
                                  <p> UPN: <b>{{ task.practitionerAppointment.practitioner.no }}</b></p>
                                  <p> Type: <b>{{ task.practitionerAppointment.appointmentType }}</b></p>
                                  <p> Status: <span :class="'coloredStatus(task.practitionerAppointment.status)'">{{ task.practitionerAppointment.status }}</span></p>
                                  <p> Accepted: <b>{{ task.practitionerAppointment.acknowledged }}</b></p>

                              </b-col>
                              <b-col>
                                  <p> Email: <b>{{ task.practitionerAppointment.practitioner.emailAddress }}</b></p>
                                  <p> Phone: <b>{{ task.practitionerAppointment.practitioner.phoneNumber }}</b></p>
                                  <p> Registration Date: <b>{{ dateFormat(task.practitionerAppointment.practitioner.createdAt) }}</b></p>
                                  <p> Appointment Date: <b>{{ dateFormat(task.practitionerAppointment.practitioner.appointmentDate) }}</b></p>

                              </b-col>
                          </b-row>

                      </b-col>
                  </b-row>
                  <b-row v-for="(practitioner,idx) in task.appointments" :key="idx">
                      <b-col v-if="practitioner.practitioner" cols="12" :class="[practitioner.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Previously Appointed Practitioners </label>
                          <label class="float-end" v-if="practitioner.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>
                                  <p> Name: <b>{{ practitioner.practitioner.surName }} {{ practitioner.practitioner.givenName }}</b></p>
                                  <p> UPN: <b>{{ practitioner.practitioner.no }}</b></p>
                                  <p> Email: <b>{{ practitioner.practitioner.emailAddress }}</b></p>
                              </b-col>
                              <b-col>
                                  <p> Phone: <b>{{ practitioner.practitioner.phoneNumber }}</b></p>
                                  <p> Registration Date: <b>{{ dateFormat(practitioner.practitioner.createdAt) }}</b></p>
                                  <p> Appointment Date: <b>{{ dateFormat(practitioner.practitioner.appointmentDate) }}</b></p>

                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.lastInterimReport !== undefined">
                      <b-col v-if="task.lastInterimReport" cols="12" :class="[task.lastInterimReport.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >
                          <label class="text-blue fw-bold text-lg">Latest Interim Report</label>
                          <label class="float-end" v-if="task.lastInterimReport.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <table :class=" task.lastInterimReport.status === 'APPROVED' ? 'min-w-full leading-normal mt-1 text-gray' : 'min-w-full leading-normal mt-1 text-gray' "
                                 style="border: solid 1px #dedede;">
                              <thead>
                              <tr
                                      :class="[task.lastInterimReport.status === 'APPROVED' ? 'bg-green-pale' : 'bg-gray-pale']"
                              >
                                  <td colspan="12" class="px-2 py-2 font-bold">Inspection Venue</td>
                              </tr>
                              </thead>
                              <tbody>
                              <b-row class="mx-1 py-1">
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Region"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.region.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="District"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.district.name.toLowerCase()"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Sub County"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.subcounty.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Parish"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.parish.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Village"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.village.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Street"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.streetAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Plot"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Postal Address"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                          <PostalAddressValidator v-if="task.lastInterimReport.postalAddress" :box-number="task.lastInterimReport.postalAddress"/>
                                      </b-form-group>

                                      <b-form-group
                                              class="text-sm my-1 flex"
                                              id="input-group-1"
                                              label="Inspection time"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.inspectionTime"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>


                                  </b-col>
                              </b-row>
                              </tbody>
                          </table>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.interimReports !== undefined">
                      <b-col v-if="task.interimReports" cols="12" class="mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3" >
                          <label class="text-blue fw-bold text-lg">Interim Reports</label>
                          <b-row v-for="(rpt , idd) in task.interimReports" :key="idd" class="mt-1">
                              <b-col cols="12" :class="[rpt.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >
                                  <label class="text-black fw-bold text-sm">Report Number : {{rpt.reportNumber}} </label>
                                  <label class="float-end" v-if="rpt.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                                  <table class="min-w-full leading-normal mt-1 text-gray"
                                         style="border: solid 1px #dedede;">
                                      <thead>
                                      <tr :class="[rpt.status === 'APPROVED' ? 'bg-green-pale' : 'bg-gray-pale']"
                                      >
                                          <td colspan="12" class="px-2 py-2 font-bold">Inspection Venue</td>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <b-row class="mx-1 py-1">
                                          <b-col>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="Region"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.physicalAddress.region.name"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="District"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus text-capitalize"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.physicalAddress.district.name.toLowerCase()"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="Sub County"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus text-capitalize"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.physicalAddress.subcounty.name"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>
                                          </b-col>
                                          <b-col>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="Parish"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.physicalAddress.parish.name"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="Village"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus text-capitalize"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.physicalAddress.village.name"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="Street"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus text-capitalize"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.streetAddress"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>
                                          </b-col>
                                          <b-col>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="Plot"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus text-capitalize"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.postalAddress"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>
                                              <b-form-group
                                                      class="text-sm my-1"
                                                      id="input-group-1"
                                                      label="Postal Address"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus text-capitalize"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.postalAddress"
                                                          required>
                                                  </b-form-input>
                                                  <PostalAddressValidator v-if="rpt.postalAddress" :box-number="rpt.postalAddress"/>
                                              </b-form-group>

                                              <b-form-group
                                                      class="text-sm my-1 flex"
                                                      id="input-group-1"
                                                      label="Inspection time"
                                                      label-for="input-1">
                                                  <b-form-input
                                                          id="companyName"
                                                          class="form-text text-sm noFocus text-capitalize"
                                                          type="text"
                                                          :disabled="true"
                                                          :value="rpt.inspectionTime"
                                                          required>
                                                  </b-form-input>
                                              </b-form-group>

                                          </b-col>
                                      </b-row>
                                      </tbody>
                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.preliminaryReport !== undefined">
                      <b-col v-if="task.preliminaryReport" cols="12" :class="[task.preliminaryReport.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >
                          <label class="text-blue fw-bold text-lg">Preliminary Report</label>
                          <label class="float-end" v-if="task.preliminaryReport.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <table class="min-w-full leading-normal mt-1 text-gray"
                                 style="border: solid 1px #dedede;">
                              <thead>
                              <tr
                                      :class="[task.preliminaryReport.status === 'APPROVED' ? 'bg-green-pale' : 'bg-gray-pale']"
                              >
                                  <td colspan="12" class="px-2 py-2 font-bold">Inspection Venue</td>
                              </tr>
                              </thead>
                              <tbody>
                              <b-row class="mx-1 py-1">
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Region"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.physicalAddress.region.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="District"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.physicalAddress.district.name.toLowerCase()"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Sub County"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.physicalAddress.subcounty.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Parish"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.physicalAddress.parish.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Village"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.physicalAddress.village.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Street"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.streetAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Plot"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Postal Address"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                          <PostalAddressValidator v-if="task.preliminaryReport.postalAddress" :box-number="task.preliminaryReport.postalAddress"/>
                                      </b-form-group>

                                      <b-form-group
                                              class="text-sm my-1 flex"
                                              id="input-group-1"
                                              label="Inspection time"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.preliminaryReport.inspectionTime"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>


                                  </b-col>
                              </b-row>
                              </tbody>
                          </table>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.boardResolution !== undefined">
                      <b-col v-if="task.boardResolution" cols="12" :class="[task.boardResolution.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Resolution  to Wind Up</label>
                          <label class="float-end" v-if="task.boardResolution.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>

                                  <p> Resolution Type: <b>{{ task.boardResolution.resolutionType }}</b></p>
                                  <p> Place Of Meeting: <b>{{ task.boardResolution.placeOfMeeting }}</b></p>
                                  <p>Date Of Meeting:
                                      <b>{{ dateFormat(task.boardResolution.dateOfMeeting) }}</b></p>


                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <div v-if="task.courtOrders.length > 0" >
                      <label class="text-blue fw-bold text-lg">Court Order details</label>
                  <b-row v-for="(courtOrder,ids) in task.courtOrders" :key="ids" >
                      <b-col cols="12" :class="[courtOrder.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">

                          <label class="float-end" v-if="courtOrder.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col cols="2">

                                  Case Number: <b-form-input disabled v-model="courtOrder.caseNumber"/>
                              </b-col> <b-col cols="2">

                                  Date: <b-form-input disabled v-model="courtOrder.courtDate"/>
                              </b-col>
                              <b-col cols="2">
                                  Court Venue: <b-form-input disabled v-model="courtOrder.issuingCourt"/>
                              </b-col>
                              <b-col>
                                  Details:
                                  <b-textarea disabled v-model="courtOrder.description"></b-textarea>

                              </b-col>
                          </b-row>
                      </b-col>

                  </b-row>
                  </div>

                  <b-row v-if="task.form20 !== undefined"><b-col v-if="task.form20">
                  <b-row v-if="task.form20.marketableSecurities.length > 0" >
                      <b-col cols="12" :class="[task.form20.marketableSecurities.length > 0  ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <b-row>
                              <b-col>
                                  <label class="text-blue fw-bold text-lg">Marketable Securities </label>
                                  <label class="float-end" v-if="task.form20.marketableSecurities.length > 0"> <i class="fa fa-lock"></i> </label>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1">Name Of Organisation</th>
                                          <th class="px-2 py-1 text-center">Details Of Security</th>
                                          <th class="px-2 py-1 text-center">Book value</th>
                                          <th class="px-2 py-1 text-center">Estimated return</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.marketableSecurities" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.nameOfOrganisation}}
                                          </td>

                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{affair.detailsOfSecurity}}
                          </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.bookValue).toLocaleString()}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.estimatedReturn).toLocaleString()}}
                          </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.tradeDebtors.length > 0" >
                      <b-col cols="12" :class="[task.form20.tradeDebtors.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <b-row>
                              <b-col>
                                  <label class="text-blue fw-bold text-lg">Trade Debtors </label>
                                  <label class="float-end" v-if="task.form20.tradeDebtors.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1">Asset</th>
                                          <th class="px-2 py-1 text-center">Book Value</th>
                                          <th class="px-2 py-1 text-center">Estimated return value</th>
                                          <th class="px-2 py-1 text-center">Debtor Address</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.tradeDebtors" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.securityParticulars}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.bookValue).toLocaleString()}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.estimatedReturn).toLocaleString()}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{dateFormat(affair.debtorAddress)}}
                          </span>
                                          </td>
                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.contingentLiabilities.length > 0">
                      <b-col cols="12" :class="[task.form20.contingentLiabilities.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Unsecured creditors - contingent liabilities </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name and Address of creditor </th>
                                          <th class="px-2 py-1 text-center">Nature of liability</th>
                                          <th class="px-2 py-1 text-center">Amount of claim</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.contingentLiabilities" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.creditorName}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.description}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.loansAndAdvancesVtoA.length > 0" >
                      <b-col cols="12" :class="[task.form20.loansAndAdvancesVtoA.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <b-row>
                              <b-col>
                                  <label class="text-blue fw-bold text-lg">Loans and Advances </label>
                                  <label class="float-end" v-if="task.form20.loansAndAdvancesVtoA.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1">Number in register</th>
                                          <th class="px-2 py-1 text-center">ShareHolder Name</th>
                                          <th class="px-2 py-1 text-center">No of Shares</th>
                                          <th class="px-2 py-1 text-center">Amount Per Share</th>
                                          <th class="px-2 py-1 text-center">Total Amount Due</th>
                                          <th class="px-2 py-1 text-center">Estimated Return</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.tradeDebtors" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{
                                                  Number(affair.noInShareRegister).toLocaleString()
                                              }}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{affair.shareHolderName}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.noOfShares).toLocaleString()}}
                          </span>
                                          </td>    <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.amountPerShare).toLocaleString()}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.totalAmountDue).toLocaleString()}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.estimatedReturn).toLocaleString()}}
                          </span>
                                          </td>
                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.loansAndAdvancesVtoA.length > 0" >
                      <b-col cols="12" :class="[task.form20.loansAndAdvancesVtoA.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <b-row>
                              <b-col>
                                  <label class="text-blue fw-bold text-lg">Loans and Advances Schedule  V to A</label>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Number in share register</th>
                                          <th class="px-2 py-1 text-center">Name address of shareholders</th>
                                          <th class="px-2 py-1 text-center">No of shares</th>
                                          <th class="px-2 py-1 text-center">Amount of call per share capital</th>
                                          <th class="px-2 py-1 text-center">Total amount due</th>
                                          <th class="px-2 py-1 text-center">Estimated return</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.tradeDebtors" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>

                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{Number(affair.noInShareRegister).toLocaleString()}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{affair.shareHolderName}}
                            {{affair.shareHolderAddress}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{affair.noOfShares}}
                          </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{affair.amountPerShare}}
                          </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{affair.totalAmountDue}}
                          </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                          <span>
                            {{affair.estimatedReturn}}
                          </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.assetsSecured.length > 0">
                      <b-col cols="12" :class="[task.form20.assetsSecured.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1">Asset</th>
                                          <th class="px-2 py-1 text-center">Nature of security</th>
                                          <th class="px-2 py-1 text-center">Date granted</th>
                                          <th class="px-2 py-1 text-center">Name of creditor</th>
                                          <th class="px-2 py-1 text-center">Address and Occupation</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.assetsSecured" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.particularsOfAsset}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.natureOfSecurity}}
                                </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{dateFormat(affair.dateGranted)}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.creditorName}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.address}} &nbsp; {{affair.occupation}}
                                </span>
                                          </td>
                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.billOfExchange.length > 0">
                      <b-col cols="12" :class="[task.form20.billOfExchange.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Bills of exchange , promissory notes , etc available as assets </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name of bill acceptor</th>
                                          <th class="px-2 py-1 text-center">Amount of Bill or note</th>
                                          <th class="px-2 py-1 text-center">Date when due</th>
                                          <th class="px-2 py-1 text-center">Estimated return</th>
                                          <th class="px-2 py-1 text-center">Particulars of any property held as security for payment of bill or note</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.billOfExchange" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.nameOfAcceptor}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{dateFormat(affair.dateDue)}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.estimatedReturn}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.particulars}}
                                </span>
                                          </td>
                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.billsPayable.length > 0">
                      <b-col cols="12" :class="[task.form20.billsPayable.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Unsecured creditors - Bills payable ,promissory notes , etc </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name and address of acceptor of bill or note</th>
                                          <th class="px-2 py-1 text-center">Name and address of holder</th>
                                          <th class="px-2 py-1 text-center">Date when due</th>
                                          <th class="px-2 py-1 text-center">Amount of claim</th>
                                          <th class="px-2 py-1 text-center">Particulars of bill or note</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.billsPayable" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.nameOfAcceptor}} &nbsp; {{affair.addressOfAcceptor}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.nameOfHolder}} &nbsp; {{affair.addressOfHolder}}
                                </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{dateFormat(affair.dateDue)}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.particulars}}
                                </span>
                                          </td>
                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.preferentialCreditors.length > 0">
                      <b-col cols="12" :class="[task.form20.preferentialCreditors.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Preferential creditors for salaries, wages and otherwise </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name of creditor</th>
                                          <th class="px-2 py-1 text-center">Address</th>
                                          <th class="px-2 py-1 text-center">Nature of claim</th>
                                          <th class="px-2 py-1 text-center">Total amount claim</th>
                                          <th class="px-2 py-1 text-center">Amount ranking as preferential</th>
                                          <th class="px-2 py-1 text-center">Balance not preferential carried to list `E`</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.preferentialCreditors" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.creditorName}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.address}}
                                </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.natureOfClaim}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amountOfClaim}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.particulars}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amountRankingAsPreferential}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.balanceNotPreferential}}
                                </span>
                                          </td>
                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.debenturesSecured.length > 0">
                      <b-col cols="12" :class="[task.form20.debenturesSecured.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">List of holders of debentures secured by a floating charge </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name and Address of holder </th>
                                          <th class="px-2 py-1 text-center">Amount</th>
                                          <th class="px-2 py-1 text-center">Description of assets over which security extends</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.debenturesSecured" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.holderName}} &nbsp; {{affair.address}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.address}}
                                </span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.assetDescription}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.tradeAccounts.length > 0">
                      <b-col cols="12" :class="[task.form20.tradeAccounts.length > 0 ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Unsecured creditors - trade accounts </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name and Address of creditor </th>
                                          <th class="px-2 py-1 text-center">Amount of the debt</th>
                                          <th class="px-2 py-1 text-center">Additional information</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.tradeAccounts" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.creditorName}} &nbsp; {{affair.address}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.description}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.form20.contingentLiabilities.length > 0">
                      <b-col cols="12" :class="[task.form20.contingentLiabilities.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Unsecured creditors - contingent liabilities </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name and Address of creditor </th>
                                          <th class="px-2 py-1 text-center">Nature of liability</th>
                                          <th class="px-2 py-1 text-center">Amount of claim</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.contingentLiabilities" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.creditorName}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.description}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.form20.nonSecuredAssetsDerivedTotals.marketableSecurities.length > 0">
                      <b-col cols="12" :class="[task.form20.nonSecuredAssetsDerivedTotals.marketableSecurities.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Nonsecured assets - marketable Securities </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Book Value </th>
                                          <th class="px-2 py-1 text-center">Estimated return</th>
                                          <th class="px-2 py-1 text-center">Currency</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.nonSecuredAssetsDerivedTotals.marketableSecurities" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.totalBookValue}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.totalEstimatedReturn}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.currency}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.form20.nonSecuredAssetsDerivedTotals.tradeDebtors.length > 0">
                        <b-col cols="12" :class="[task.form20.nonSecuredAssetsDerivedTotals.tradeDebtors.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                            <label class="text-blue fw-bold text-lg">Nonsecured assets - Trade Debtors </label>
                            <b-row>
                                <b-col>
                                    <table class="min-w-full leading-normal">
                                        <thead>
                                        <tr>
                                            <th class="px-2 py-1">#</th>
                                            <th class="px-2 py-1 text-center">Book Value </th>
                                            <th class="px-2 py-1 text-center">Estimated return</th>
                                            <th class="px-2 py-1 text-center">Currency</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.nonSecuredAssetsDerivedTotals.tradeDebtors" :key="idx">
                                            <td class="px-2 py-1">
                                                {{idx+1}}.
                                            </td>
                                            <td class="px-2 py-1">
                                                {{affair.totalBookValue}}
                                            </td>
                                            <td class="px-2 py-1 text-center">
                                    <span>
                                    {{affair.totalEstimatedReturn}}
                                    </span>
                                            </td>

                                            <td class="px-2 py-1 text-center">
                                    <span>
                                    {{affair.currency}}
                                    </span>
                                            </td>

                                        </tr>
                                        </tbody>

                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row v-if="task.form20.nonSecuredAssetsDerivedTotals.loansAndAdvances.length > 0">
                      <b-col cols="12" :class="[task.form20.nonSecuredAssetsDerivedTotals.loansAndAdvances.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Nonsecured assets - Loans and Advances </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Book Value </th>
                                          <th class="px-2 py-1 text-center">Estimated return</th>
                                          <th class="px-2 py-1 text-center">Currency</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.nonSecuredAssetsDerivedTotals.loansAndAdvances" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.totalBookValue}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.totalEstimatedReturn}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.currency}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
</b-row>

<!--                  billsReceivable-->

                    <b-row v-if="task.form20.nonSecuredAssetsDerivedTotals.billsReceivable.length > 0">
                      <b-col cols="12" :class="[task.form20.nonSecuredAssetsDerivedTotals.billsReceivable.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Nonsecured assets - Bills Receivable </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Book Value </th>
                                          <th class="px-2 py-1 text-center">Estimated return</th>
                                          <th class="px-2 py-1 text-center">Currency</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.nonSecuredAssetsDerivedTotals.billsReceivable" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.totalBookValue}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.totalEstimatedReturn}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.currency}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
</b-row>

<!--                  unpaidShares-->

                    <b-row v-if="task.form20.nonSecuredAssetsDerivedTotals.unpaidShares.length > 0">
                      <b-col cols="12" :class="[task.form20.nonSecuredAssetsDerivedTotals.unpaidShares.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Nonsecured assets - Unpaid Shares </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Book Value </th>
                                          <th class="px-2 py-1 text-center">Estimated return</th>
                                          <th class="px-2 py-1 text-center">Currency</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.nonSecuredAssetsDerivedTotals.unpaidShares" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.totalBookValue}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.totalEstimatedReturn}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.currency}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
</b-row>

                  </b-col></b-row>



                  <b-row v-if="task.reasons !== undefined">
                      <b-col v-if="task.reasons" cols="12" :class="[task.reasons.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >
                          <b-row v-if="task.reasons.length > 0">
                              <label class="text-blue fw-bold text-lg">Reasons to Wind Up</label>

                              <b-col cols="12"
                                     v-for="(reason, idx) in task.reasons" :key="idx">
                                  <b-row>
                                      <b-col>
                                          <b>{{idx+1}}</b>
                                      </b-col>
                                      <b-col cols="11">
                                          <b-textarea disabled v-model="reason.reason"></b-textarea>
                                      </b-col>
                                  </b-row>
                              </b-col>
                          </b-row>


                          <!--     <b-row v-else-if="filing.filingCode === 'FORM29_NOTICE_OF_SPECIAL_RESOLUTION'">
                                         <b-col cols="12" :class="[task.creditorMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" v-for="(reason, idx) in filing.data" :key="idx">
                                             <b-row>
                                                 <b-col>
                                                     <p> Date Of Cessation:   <b>{{dateFormat(reason.dateOfCessation)}}</b></p>
                                                     <p> Date Of Resolution:   <b>{{dateFormat(reason.dateOfResolving)}}</b></p>
                                                     <p> Venue Of Meeting:   <b>{{reason.venueOfMeeting}}</b></p>
                                                     <p> Name Of Practitioner:   <b>{{reason.nameOfAppointedPractitioner}}</b></p>
                                                 </b-col>
                                             </b-row>
                                         </b-col>
                                     </b-row>
                                     <b-row v-else-if="filing.filingCode === 'FORM3_ANNUAL_RETURNS'">
                                         <b-col cols="12" :class="[task.creditorMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                             <table class="min-w-full leading-normal">
                                                 <thead>
                                                 <tr>
                                                     <th class="px-2 py-1">#</th>
                                                     <th class="px-2 py-1">Company Number</th>
                                                     <th class="px-2 py-1">Company Name</th>
                                                     <th class="px-2 py-1">UPN</th>
                                                     <th class="px-2 py-1">Practitioner Name</th>
                                                     <th class="px-2 py-1">Practitioner Address</th>
                                                     <th class="px-2 py-1">Prct Postal Address</th>
                                                     <th class="px-2 py-1">Role of Appointee</th>
                                                     <th class="px-2 py-1">Status of Proceeding</th>
                                                     <th class="px-2 py-1">Date of Appointment</th>

                                                 </tr>
                                                 </thead>
                                                 <tbody>
                                                 <tr style="border: solid 1px #dedede;" v-for="(entry, idx) in filing.data" :key="idx">
                                                     <td class="px-2 py-1">
                                                         {{idx+1}}.
                                                     </td>
                                                     <td class="px-2 py-1">{{entry.companyBrn}}</td>
                                                     <td class="px-2 py-1">{{entry.companyName}}</td>
                                                     <td class="px-2 py-1">{{entry.uniquePractitionerNumber}}</td>
                                                     <td class="px-2 py-1">{{entry.practitionerName}}</td>
                                                     <td class="px-2 py-1">{{entry.practitionerAddress}}</td>
                                                     <td class="px-2 py-1">{{entry.practitionerPostalAddress}}</td>
                                                     <td class="px-2 py-1 text-capitalize text-green">{{entry.roleOfTheAppointee.toLowerCase()}}</td>
                                                     <td class="px-2 py-1 text-capitalize text-blue">{{cleanString(entry.statusOfProceeding).toLowerCase()}}</td>
                                                     <td class="px-2 py-1">
                                                         <span>{{dateFormat(entry.dateOfAppointment)}}</span>
                                                     </td>
                                                 </tr>
                                                 </tbody>

                                             </table>
                                         </b-col>
                                     </b-row>
                                     <b-row v-else-if="filing.filingCode === 'FORM25_NOTICE_OF_LIQUIDATORS_REPORT'">
                                         <b-col cols="12" :class="[task.creditorMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                             <table class="min-w-full leading-normal">
                                                 <thead>
                                                 <tr>
                                                     <th class="px-2 py-1">#</th>
                                                     <th class="px-2 py-1">Company Number</th>
                                                     <th class="px-2 py-1">Company Name</th>
                                                     <th class="px-2 py-1">Liquidator</th>
                                                     <th class="px-2 py-1">Inspection Venue</th>
                                                     <th class="px-2 py-1">Report Type</th>
                                                     <th class="px-2 py-1">Inspection Start</th>
                                                     <th class="px-2 py-1">Inspection End</th>
                                                     <th class="px-2 py-1">Date of Report</th>

                                                 </tr>
                                                 </thead>
                                                 <tbody>
                                                 <tr style="border: solid 1px #dedede;" v-for="(report, idx) in filing.data" :key="idx">
                                                     <td class="px-2 py-1">
                                                         {{idx+1}}.
                                                     </td>
                                                     <td class="px-2 py-1">{{report.companyBrn}}</td>
                                                     <td class="px-2 py-1">{{report.companyName}}</td>
                                                     <td class="px-2 py-1">{{report.nameOfLiquidator}}</td>
                                                     <td class="px-2 py-1">{{report.inspectionVenue}}</td>
                                                     <td class="px-2 py-1">{{cleanString(report.reportType)}}</td>
                                                     <td class="px-2 py-1">{{report.inspectionStartTime}}</td>
                                                     <td class="px-2 py-1">{{report.inspectionEndTime}}</td>
                                                     <td class="px-2 py-1">
                                                         <span>{{dateFormat(report.dateOfReport)}}</span>
                                                     </td>
                                                 </tr>
                                                 </tbody>

                                             </table>
                                         </b-col>
                                     </b-row>
                                     <b-row v-else-if="filing.filingCode === 'FORM30_DECLARATION_OF_SOLVENCY'">
                                         <b-col cols="12" :class="[task.creditorMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" v-for="(reason, idx) in filing.data" :key="idx">
                                             <b-row>
                                                 <b-col>
                                                     <p>Directors;</p>
                                                     <b-row v-for="(director, idx) in reason.namesOfDirector" :key="idx">
                                                         <b-col>
                                                             <p> - {{director}}</p>
                                                         </b-col>
                                                     </b-row>
                                                     <p class="mt-2" > Date Filed:   <b>{{dateFormat(reason.creationDate)}}</b></p>
                                                 </b-col>
                                             </b-row>
                                         </b-col>
                                     </b-row>
                                     <b-row v-else-if="filing.filingCode === 'FORM12_NOTICE_OF_APPOINTMENT'">
                                         <b-col cols="12" :class="[task.creditorMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" v-for="(reason, idx) in filing.data" :key="idx">
                                             <b-row>
                                                 <b-col>
                                                     <p> Name of Appointee:   <b>{{reason.nameOfAppointee}}</b></p>
                                                     <p> Role of Appointee:   <b>{{reason.roleOfTheAppointee}}</b></p>
                                                     <p> Date of Filed:  <b>{{dateFormat(reason.dateOfFiling)}}</b></p>
                                                 </b-col>
                                             </b-row>
                                         </b-col>
                                     </b-row>
                                     -->


                      </b-col>
                  </b-row>



              </b-col>
          </b-row>


      </b-col>
    </b-row>
  </div>
</template>

<script>

import PostalAddressValidator from "@/views/home/commons/PostalAddressValidator.vue";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";

export default {
  name: "WindingInsolvencyForms",
  components: {
      PRNViewer,
      PostalAddressValidator
  },
  props:{
    task:Object
  },
  data(){
    return{
    }
  },
  mounted() {

  },
  methods: {
      coloredStatus(status){
          if(status === 'APPROVED'){
              return 'texT-green'
          }else if(status === 'REJECTED'){
              return 'text-red'
          }else if(status === 'FILING'){
              return 'text-gray'
          }
      },

  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>