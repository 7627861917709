<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal title="Application Details" ref="applicationDetails" size="xl" hide-footer>
        <CertificationDetails :tracking-number="trackingNumber"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Certification Applications</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="flex mx-2 mt-2">
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="waiting">
          <span class="h3 mx-auto">{{stats.pending}}</span>
        </div>
        <div class="waiting">
          <span class="text-sm mx-auto">In Queue</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="normal">
          <span class="h3 mx-auto">{{stats.assigned}}</span>
        </div>
        <div class="normal">
          <span class="text-sm mx-auto">Assigned</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning">
          <span class="h3 mx-auto">{{stats.queried}}</span>
        </div>
        <div class="warning">
          <span class="text-sm mx-auto">Queried</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="positive">
          <span class="h3 mx-auto">{{stats.approved}}</span>
        </div>
        <div class="positive">
          <span class="text-sm mx-auto">Approved</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="negative">
          <span class="h3 mx-auto">{{stats.escalated}}</span>
        </div>
        <div class="negative">
          <span class="text-sm mx-auto">Escalated</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning-secondary">
          <span class="h3 mx-auto">{{stats.pending + stats.queried + stats.approved + stats.escalated}}</span>
        </div>
        <div class="warning-secondary">
          <span class="text-sm mx-auto">Total</span>
        </div>
      </div>

    </b-row>
    <b-row class="flex">
      <b-col cols="12">
        <b-row class="pt-3">
          <b-col>
            <AllCertificationSubmissions v-on:openDetails="openDetails"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import CertificationDetails from "@/views/home/services/certification/submissions/CertificationDetails";
import AllCertificationSubmissions
  from "@/views/home/services/certification/submissions/AllCertificationSubmissions";

export default {
  name: "CertificationApplications",
  components: {
    AllCertificationSubmissions,
    CertificationDetails,},
  data(){
    return{
      currentRoute: "pending",
      trackingNumber:null,
      stats:{
        "draft": 0,
        "pending": 0,
        "queried": 0,
        "assigned": 0,
        "escalated": 0,
        "rejected": 0,
        "approved": 0
      }
    }
  },
  mounted() {
    this.fetchCertificationStatistics()
  },
  methods:{
    ...mapActions(["fetchCertificationStatistics"]),
    select(route){
      this.currentRoute = route
    },
    canViewMenuItem(permission) {
      return this.$can(permission, "general");
    },
    openDetails(trackingNumber){
      this.trackingNumber = trackingNumber
      this.$refs.applicationDetails.show()
    }
  },
  computed:{
    ...mapGetters(["getCertificationStatistics"])
  },
  watch:{
    getCertificationStatistics(data){
      this.stats = data
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.warning-secondary{
  @apply flex text-flopurple;
}

.negative{
  @apply flex text-red;
}
.stat-count{
  display: inline;
  width: 15%;
}

</style>