<template>
  <b-row class="py-2">
    <b-col>
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Amalgamation
          </b-badge>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <pre>{{data}}</pre>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CompanyAmalgamation",
  props:{
    data:Object,
    brn:String,
    type:String
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>