<template>
    <div class="bg-white rounded-sm m-2">

        <b-row class="flex">
            <b-col cols="12">
                <b-row class="pt-3">
                    <b-col cols="12">


                        <form>

                            <p class="text-sm text-gray"><b>Court Order for Winding Up</b></p>
                            <hr />




                            <b-row class="mb-5 mt-5">

                                <b-col cols="6">
                                    <label>Issuing court</label>
                                    <b-form-input class="form-control my-1" placeholder="issuingcourt " v-model="form.issuingCourt" />



                                    <div class="mx-auto mb-2">
                                        <label class="text-gray text-sm">Venue</label>
                                        <b-form-group
                                                class="text-sm"
                                                label-for="input-1">
                                            <b-form-textarea
                                                    id="venue"
                                                    class="form-text mb-1 noFocus text-sm"
                                                    type="text"
                                                    rows="2"
                                                    v-model="form.venue"
                                                    required>
                                            </b-form-textarea>
                                        </b-form-group>
                                    </div>

                                    <div class="mx-auto mt-2 mb-2">
                                        <label class="text-gray text-sm">Date Of ruling</label>
                                        <datepicker
                                                :disabled-dates="{from:new Date()}"
                                                placeholder="dd/mm/yyyy"
                                                class="noFocus text-sm"
                                                :typeable="false"
                                                bootstrap-styling
                                                id="dateOfBirth"
                                                format="dd/MM/yyyy"
                                                required
                                                v-model="form.courtDate">
                                        </datepicker>
                                    </div>


                                </b-col>

                                <b-col cols="6">
                                    <label>Case Number</label>
                                    <b-form-input class="form-control my-1"  placeholder="caseNumber" v-model="form.caseNumber" />

                                    <div class="mx-auto mb-2">
                                        <label class="text-gray text-sm">Description</label>
                                        <b-form-group
                                                class="text-sm"
                                                label-for="input-1">
                                            <b-form-textarea
                                                    id="venue"
                                                    class="form-text mb-1 noFocus text-sm"
                                                    type="text"
                                                    rows="5"
                                                    v-model="form.description"
                                                    required>
                                            </b-form-textarea>
                                        </b-form-group>
                                    </div>

                                </b-col>

                            </b-row>



                        </form>



                    </b-col>
                </b-row>



                <b-row class="mt-3">
                    <b-col cols="12">
                        <button id="backBtn" type="button" class="back-button" @click="prev">
                     <span class="mx-3">
                        Back
                     </span>
                        </button>


                        <button id="nextBtn" type="button" class="button float-end" @click="saveDetails">
                     <span class="mx-3">
                        Save & Continue
                        <b-spinner small v-if="loading"></b-spinner>
                     </span>
                        </button>
                    </b-col>
                </b-row>






            </b-col>
        </b-row>


    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";
import datepicker from "vuejs-datepicker"

export default {
    name: "CourtDetails",
    components: {datepicker},
    data() {
        return {
            currentRoute: "pending",
            loading: false,
            form:{
                venue:null,
                courtDate:null,
                description:null,
                trackingNumber:null,
                caseNumber:null
            }
        }
    },
    mounted() {

    },
    methods: {
        ...mapActions([]),
        select(route) {
            this.currentRoute = route
        },
        saveDetails(){
            this.loading = true
            this.saveCourtOrderDetails(this.form)
        },
        async saveCourtOrderDetails(data) {
            await api().post("insolvency/process/court-order", data)
                .then((response) => {
                    let trackingNo = response.data.data
                    this.$emit("nextTab", trackingNo);
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.errorToast("Error", error.response.data.message);
                });
        },
        prev(){
            this.$emit("backTab");
        },
    },
    computed: {
        ...mapGetters(
            [
                "getWindingDraftData"
            ]
        )
    },
    watch:{
        getWindingDraftData(data){
            if(data && data.data){
                let  info = data.data

                this.form.trackingNumber = info.trackingNumber ? info.trackingNumber : null
                if(data.data.courtOrder) {
                    let courtOrderDetails = data.data.courtOrder
                    this.form.issuingCourt	 = courtOrderDetails.issuingCourt
                    this.form.courtDate = courtOrderDetails.courtDate
                    this.form.description = courtOrderDetails.description
                    this.form.caseNumber = courtOrderDetails.caseNumber
                }
            }
        }
    }

}
</script>

<style scoped>

.button {
    @apply bg-blue text-white py-2 rounded;
}

.button:hover {
    @apply bg-blue-dark;
}


.back-button {
    @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
    @apply bg-gray text-white;
}
</style>