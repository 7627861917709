<template>
  <div>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="flex h-full">
                <div class="cursor-pointer" :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Reservation Information</div>
                  </div>
                </div>
                <div class="cursor-pointer" :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Attachments</div>
                  </div>
                </div>
                <div class="cursor-pointer" :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Messages</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <DispensationApplication v-if="currentRoute === 'forms'" :task="task"/>
              <DispensationFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
              <DispensationMessages v-if="currentRoute === 'messages'" :task="task"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import DispensationMessages from "@/views/home/services/dispensation/task/DispensationMessages.vue";
import DispensationFormFiles from "@/views/home/services/dispensation/task/DispensationFormFiles.vue";
import DispensationApplication from "@/views/home/services/dispensation/task/DispensationApplication.vue";
export default {
  name: "DispensationApplicationDetails",
  components: {DispensationApplication, DispensationFormFiles, DispensationMessages},
  props:{
    task:Object
  },
  data(){
    return{
      currentRoute: "forms",
    }
  },
  methods:{
    select(route){
      this.currentRoute = route
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>