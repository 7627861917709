<template>
  <div>
    <notifications group="foo" position="top right" :max="1" class="top-padding"/>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'App',
  methods: {
    ...mapMutations(["resetNetworkStatus"]),
  },
  computed: {
    ...mapGetters(["getNetworkStatus"])
  },
  watch: {
    getNetworkStatus(message) {
      if (message !== null) {
        this.errorToast('Network Error', message);
      }
      this.resetNetworkStatus()
    }
  }
}
</script>


<style>

*{
  scrollbar-width: thin;
  scrollbar-color: #cdcdcd #f0f0f0;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  /*background: linear-gradient(to top, #33ccff 0%, #cc00ff 100%)*/
}

.top-padding{
  margin-top: 70px;
}

.noFocus:focus {
  outline: none 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.noFocus {
  box-shadow: none !important
}

</style>