<template>
  <div>

    <b-row>
      <b-col>
        <div class="mb-1 ml-2 text-center bg-gray-pale mx-auto" style="font-size: 12px;"><b>Reasons for Winding Up</b></div>
      </b-col>
    </b-row>


    <b-row class="mt-12">
      <b-col cols="12">
        <div>

          <p class="text-sm text-gray"><b>Enter your reasons</b></p>
          <hr />


          <b-row class="mt-3">
            <b-col cols="6" v-for="(list, index) in form.reasons" :key="index">
              <div>
                <b-input-group>
                  <b-form-textarea
                    :placeholder="'Add reason '+(index+1)"
                    maxlength="500"
                    id="reasons"
                    class="form-text noFocus text-sm"
                    type="text"
                    v-model="list.reason"
                    required>
                  </b-form-textarea>

                  <b-input-group-append>
                    <i v-b-tooltip.hover.bottom title="Remove objective" v-if="form.reasons.length > 1"
                       @click="removeReason(index)" class="fas fa-times text-gray text-red ml-4"
                       style="cursor: pointer; margin-top: 10px;"></i>
                  </b-input-group-append>
                </b-input-group>
                <small :style="characterCount(list.objective) === 0 ? 'color:red':'color:#737272'" class="float-end"
                       :class="form.reasons.length > 1? 'mr-7':''">{{ characterCount(list.reason) }}</small>
              </div>
            </b-col>
          </b-row>


          <b-row class="mb-2 mt-3">
            <b-col cols="12">
              <button
                type="button"
                class="button-add text-white text-sm mt-2 noFocus"
                @click="addReason">
                   <span class="mx-3">
                     <i class="fas fa-plus iconTextSize ml-1 mr-1"></i>
                     Add another reason
                   </span>
              </button>
            </b-col>
          </b-row>

        </div>

      </b-col>
    </b-row>


    <b-row class="mt-3">
      <b-col cols="12">
        <button id="backBtn" type="button" class="back-button" @click="prev">
           <span class="mx-3">
              Back
           </span>
        </button>


        <button id="nextBtn" type="button" class="button float-end" @click="saveReasons">
           <span class="mx-3">
              Save & Continue
              <b-spinner small v-if="loading"></b-spinner>
           </span>
        </button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { api } from "@/app/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "Form20Reasons",
  data(){
    return{
      loading:false,
      form : {
        trackingNumber: null,
        reasons: [
          {
            reason: null,
            index: 1
          }
        ]
      
      },
    }
  },
  methods:{
    addReason() {
      this.form.reasons.push({ reason: null, index: this.form.reasons.length });

    },
    removeReason(index) {
      this.form.reasons.splice(index, 1);
      this.form.reasons.forEach((item, index) => {
        item.index = index;
      });
    },
    characterCount(reason) {
      if (reason) {
        let counter = (500 - reason.length);
        return counter >= 0 ? counter : 0;
      }

    },
    prev(){
      this.$emit("backTab");
    },
    onSubmit(){
      this.$emit("nextTab");
    },
   async saveReasons(){
      this.loading = true;
     api().post("insolvency/process/reasons",this.form).then(
          (response)=>{
            this.loading = false;
            this.successToast("Success", response.data.message);
            this.$emit("nextTab",this.form.trackingNumber);
          }
     ).catch((error)=>{
       this.loading = false;
       this.errorToast("Error", error.response.data.message);
     });
    }
  },
  computed:{
    ...mapGetters(["getWindingDraftData", "getWindingDraftDataError"]),
  },
  watch:{
    getWindingDraftData(data){
      if(data && data.data){
        let dataInfo = data.data
        this.form.trackingNumber = dataInfo.trackingNumber;
        if(dataInfo.reasons && (dataInfo.reasons.length > 0)) {
          this.form.reasons = dataInfo.reasons;
        }
      }
    },
    getWindingDraftDataError(error) {
      this.errorToast("Error", error.response.data.message);
    }
  }
}
</script>



<style scoped>

.button {
  @apply bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}


.button-add{
    @apply bg-green text-white py-1 rounded;
}

.button-add:hover{
    @apply bg-green-dark;
}

.button-add-blue{
    @apply bg-blue text-white py-1 rounded;
}
.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}
</style>