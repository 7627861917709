<template>
  <p :id="'name'+idx">
    {{highlightedName}}
  </p>
</template>

<script>
export default {
  name: "NameHighlight",
  props:{
    name:String,
    idx:Number,
    flags:Array
  },
  data(){
    return{
      highlightedName:null
    }
  },
  mounted() {
    this.builder()
  },
  methods:{
    builder(){
      this.highlightedName = this.name
      for(let j = 0; j < this.flags.length; j++){
        let v = this.flags[j].word.trim()
        let regexp = new RegExp( `${v}`, 'ig')
        this.highlightedName = this.highlightedName.replace(regexp,
            "<b class='bg-red text-white py-1'>"+
            this.flags[j].word.trim()
            +"</b>");
      }
      document.getElementById('name'+this.idx).innerHTML = this.highlightedName
    }
  },
}
</script>

<style scoped>

</style>