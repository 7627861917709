<template>
    <div class="overflow-hidden bg-gray p-2">
        <b-row class="my-2">
            <b-col cols="12">
                <b-overlay rounded="sm" :show="loading">
                <iframe v-if="url" class="pdf" :src="url+'#toolbar=0'"></iframe>
                <p v-else class="bg-gray min-h-screen text-center">
                    <span class="py-5 text-white">Please wait document loading ...</span>
                </p>
                </b-overlay>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {api} from "@/app/services/api.service";
import server from "../../../../../app/env/server.json"
export default {
    name: "CertificatePreview",
    data(){
        return{
            loading:false,
            url: null
        }
    },
    props:{
        pdfUrl:String
    },
    mounted() {
        this.loading = true
     this.openFile(this.pdfUrl)
    },
    methods:{
        openFile(url){
           // make axios call to the pdfUrl to get the  base64 of the file
            api(server.IP).get(url).then(response => {
                this.url = response.data.data
                this.loading = false
            }).catch(error => {
                console.log(error)
                this.loading = false
            })
        }
    },


}
</script>



<style scoped>
.pdf{
    width: 100%;
    height: 800px;
    overflow-x: auto;
    border:1px solid #b1b1b1;
}
</style>