<template>
  <b-row>
    <b-col cols="12">
      <b-row class="pt-1">
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'directors') ? 'menu-item-active' : 'menu-item']" @click="select('directors')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Directors</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{(directorsTotal)}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'secretary') ? 'menu-item-active' : 'menu-item']" @click="select('secretary')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Secretary</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{(secretaryTotal)}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'reps') ? 'menu-item-active' : 'menu-item']" @click="select('reps')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Representatives</div>
                <b-badge class="bg-blue mx-1 mb-1">
                  {{(repsTotal)}}
                </b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'address') ? 'menu-item-active' : 'menu-item']" @click="select('address')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Address</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'documents') ? 'menu-item-active' : 'menu-item']" @click="select('documents')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Documents</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'filing-history') ? 'menu-item-active' : 'menu-item']" @click="select('filing-history')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Filing History</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <CompanyDirectors v-if="currentRoute === 'directors'"/>
            <CompanySecretary v-if="currentRoute === 'secretary'"/>
            <CompanyRepresentatives v-if="currentRoute === 'reps'"/>
            <ForeignCompanyAddress :brn="company.brn" v-if="currentRoute === 'address'"/>
            <CompanyDocuments :brn="company.brn" v-if="currentRoute === 'documents'"/>
            <FilingHistory :brn="company.brn" v-if="currentRoute === 'filing-history'"/>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import CompanyDirectors from "@/views/home/services/registry/business/files/tabs/company_data/CompanyDirectors";
import CompanyDocuments from "@/views/home/services/registry/business/files/tabs/company_data/CompanyDocuments";
import FilingHistory from "@/views/home/services/registry/business/files/tabs/company_data/FilingHistory";
import {mapActions, mapGetters} from "vuex";
import CompanySecretary from "@/views/home/services/registry/business/files/tabs/company_data/CompanySecretary";
import ForeignCompanyAddress
  from "@/views/home/services/registry/business/files/tabs/company_data/ForeignCompanyAddress";
import CompanyRepresentatives
  from "@/views/home/services/registry/business/files/tabs/company_data/CompanyRepresentatives.vue";
export default {
  name: "ForeignCompanyFile",
  components: {
    CompanyRepresentatives,
    ForeignCompanyAddress,
    CompanySecretary, FilingHistory, CompanyDocuments, CompanyDirectors
  },
  props:{
    company:Object
  },
  data(){
    return{
      currentRoute:"directors",
      subscribersTotal:0,
      directorsTotal:0,
      secretaryTotal:0,
      repsTotal:0
    }
  },
  mounted() {
    this.fetchCompanySubscribers(this.company.brn)
    this.fetchCompanyRepresentatives(this.company.brn)
    this.fetchCompanyDirectors(this.company.brn)
    this.fetchCompanySecretary(this.company.brn)
    this.fetchFilingCompliance(this.company.brn)

  },
  methods:{
    ...mapActions(["fetchCompanyDirectors", "fetchCompanySecretary", "fetchCompanyRepresentatives",  "fetchCompanySubscribers", "fetchFilingCompliance"]),
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getCompanySubscribers", "getCompanyDirectors", "getCompanyRepresentatives", "getCompanySecretary", "getFilingCompliance"])
  },
  watch:{
    getCompanySubscribers(data){
      this.subscribersTotal = data.data.content.length
    },
    getCompanyDirectors(data){
      this.directorsTotal = data.data.content.length
    },
    getCompanySecretary(data){
      if(data){
        this.secretaryTotal = 1
      }
    },
    getCompanyRepresentatives(data){
      this.repsTotal = data.data.content.length
    }
  }
}
</script>

<style scoped>

</style>