<template>
  <div class="error">
    <b-row>
      <b-col lg="5" class="mx-auto min-h-screen" style="margin-top: 10%;">
        <b-card tile class="text-center">
          <img :src="require('../../../assets/images/ic_404.png')" width="30%" class="mx-auto py-3 px-3">
          <b-card-text class="h1 mt-2">
            O<span class="text-blue">op</span>s!
          </b-card-text>

          <b-card-text class="mt-3 h4">
            Now where did that page run off to?
          </b-card-text>

          <b-card-text class="mt-3 text-md">
            I don't think we have the resource you are looking for right now.
          </b-card-text>
          <b-card-text class="mb-4 text-md">
            However,
            <router-link class="text-decoration-none font-bold text-blue" :to="{ name: 'login' }">click here</router-link>
            to go to the home page.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Error404"
};
</script>

<style scoped>
.error{
  overflow: hidden;
  background-color: #f7f7f8;
}
</style>
