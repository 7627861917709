<template>
    <div class="my-3">

        <b-row class="mb-2">
            <b-col>
                <DisputeResolutionFormDetailsBox v-if="task" :application="task"></DisputeResolutionFormDetailsBox>
            </b-col>
        </b-row>


    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import DisputeResolutionFormDetailsBox
    from "@/views/home/services/dispute-resolution/submissions/components/DIsputeResolutionFormDetailsBox.vue";


export default {
    name: "DisputeResolutionFormDetails",
    components: {
        DisputeResolutionFormDetailsBox

    },
    data() {
        return {
            attachmentUrl: null,
            individualDirectors: [],
            corporateDirectors: [],
            individualSecretary: null,
            corporateSecretary: null,
            adoptionTableText: null,
            task: null
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        cleanName(name) {
            if (name) {
                let n = name.replaceAll("_", " ")
                return n.toLowerCase()
            } else {
                return "N/A"
            }
        },

    },
    computed: {
        ...mapGetters(["getDisputeResolutionTask"])
    },

    watch: {
        getDisputeResolutionTask(data) {
            //alert(JSON.stringify(data))
            this.task = data
        }
    }
}
</script>

<style scoped>
th, td {
    font-size: 12px;
}
</style>