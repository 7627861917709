<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal title="Application Details" ref="applicationDetails" size="xl" hide-footer>
          <InsolvencyPetitionDetails :tracking-number="trackingNumber"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Insolvency Petition Applications</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="flex mx-3 mt-3">

        <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
            <div class="pre-waiting">
                <span v-if="getInsolvencyStatistics.stats.draft === undefined" class="h3 mx-auto">0</span>
                <span v-else class="h3 mx-auto">{{getInsolvencyStatistics.stats.draft.toLocaleString()}}</span>
            </div>
            <div class="pre-waiting">
                <span class="text-sm mx-auto">Draft </span>
            </div>
        </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="waiting">
          <span v-if="getInsolvencyStatistics.stats.pending === undefined" class="h3 mx-auto">0</span>
          <span v-else class="h3 mx-auto">{{getInsolvencyStatistics.stats.pending.toLocaleString()}}</span>
        </div>
        <div class="waiting">
          <span class="text-sm mx-auto">In Queue</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="normal">
          <span v-if="getInsolvencyStatistics.stats.ASSIGNED === undefined" class="h3 mx-auto">0</span>
          <span v-else class="h3 mx-auto">{{getInsolvencyStatistics.stats.ASSIGNED.toLocaleString()}}</span>
        </div>
        <div class="normal">
          <span class="text-sm mx-auto">Assigned</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="off-normal">
          <span v-if="getInsolvencyStatistics.stats.escalations === undefined" class="h3 mx-auto">0</span>
          <span v-else class="h3 mx-auto">{{getInsolvencyStatistics.stats.escalations.toLocaleString()}}</span>
        </div>
        <div class="off-normal">
          <span class="text-sm mx-auto">Escalated</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning">
          <span v-if="getInsolvencyStatistics.stats.queried === undefined" class="h3 mx-auto">0</span>
          <span v-else class="h3 mx-auto">{{getInsolvencyStatistics.stats.queried.toLocaleString()}}</span>
        </div>
        <div class="warning">
          <span class="text-sm mx-auto">Queried</span>
        </div>
      </div>

        <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
            <div class="positive">
                <span v-if="getInsolvencyStatistics.stats.closed === undefined" class="h3 mx-auto">0</span>
                <span v-else class="h3 mx-auto">{{getInsolvencyStatistics.stats.closed.toLocaleString()}}</span>
            </div>
            <div class="positive">
                <span class="text-sm mx-auto">Approved</span>
            </div>
        </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning-secondary">
            <span v-if="getInsolvencyStatistics.stats.total === undefined" class="h3 mx-auto">0</span>
          <span v-else class="h3 mx-auto">{{getInsolvencyStatistics.stats.total.toLocaleString()}}</span>
        </div>
        <div class="warning-secondary">
          <span class="text-sm mx-auto">Total</span>
        </div>
      </div>

    </b-row>
    <b-row class="flex">
      <b-col cols="12">
        <b-row class="pt-3">
          <b-col>
            <AllInsolvencyPetitionSubmissions v-on:openDetails="openDetails"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import AllInsolvencyPetitionSubmissions
    from "@/views/home/services/insolvency/submissions/AllInsolvencyPetitionSubmissions.vue";
import InsolvencyPetitionDetails from "@/views/home/services/insolvency/task-queue/InsolvencyPetitionDetails.vue";

export default {
  name: "InsolvencyPetitionApplications",
  components: {
      InsolvencyPetitionDetails,
      AllInsolvencyPetitionSubmissions,
  },
  data(){
    return{
      currentRoute: "pending",
      trackingNumber:null,
    }
  },
  mounted() {
      this.fetchInsolvencyStatistics("PETITION")
  },
  methods:{
    ...mapActions(["fetchInsolvencyStatistics"]),
    select(route){
      this.currentRoute = route
    },
    canViewMenuItem(permission) {
      return this.$can(permission, "general");
    },
    openDetails(trackingNumber){
      this.trackingNumber = trackingNumber
      this.$refs.applicationDetails.show()
    }
  },
  computed:{
    ...mapGetters(["getInsolvencyStatistics"])
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.pre-waiting{
  @apply flex text-gray_title;
}

.off-normal{
  @apply flex text-blue-light;
}

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.warning-secondary{
  @apply flex text-flopurple;
}

.negative{
  @apply flex text-red-light;
}
.stat-count{
  display: inline;
  width: 12%;
  @apply my-1;
}

</style>