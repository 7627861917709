<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col>
          <div class="mx-auto mt-2">
            <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-2" v-if="history.length > 0">
        <b-col class="px-2 py-1 bg-headed_grey rounded" >
          <b-row>
            <b-col cols="10" class="mx-2 bg-header_grey rounded px-2 py-1 font-monospace">
              <b-row class="text-sm" v-for="(filing, idx) in history" :key="idx">
                <b-col cols="2">
                  <span class="italic text-blue text-xs float-end mt-3">{{filing.filingDate.replace("T", " ")}}</span>
                </b-col>
                <b-col cols="9">
                  <b-row>
                    <b-col cols="1">
                      <div style="margin-top: 20px; margin-left: 20px">
                        <span class="circle"></span>
                        <div class="line"></div>
                      </div>
                    </b-col>
                    <b-col cols="11" class="my-3">
                      <b class="text-gray"
                         v-for="(change, idx2) in filing.changes" :key="idx2">
                        {{statusTranslator(change.typeOfChangeBeingFiled)}}
                        <i v-if="idx2 < (filing.changes.length - 1)">&</i>
                      </b>
                      <p class="text-xs" v-for="(narration, idx3) in filing.narrations" :key="idx3">
                        {{narration.description}}
                      </p>
                      <i class="text-blue text-xs">{{filing.trackingNo}}</i>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1">
                  <i class="fa fa-check text-green text-sm mt-5"></i>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="pagination.totalPages > 1">
            <b-col cols="1">
              <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
            </b-col>
            <b-col cols="1">
              <b-row>
                <b-col cols="12">
                  <p class="paginate-details text-center">
                    <b-badge class="bg-blue text-white">
                      {{currentPage}}/{{pagination.totalPages}}
                    </b-badge>
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1" >
              <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
            </b-col>
            <b-col class="9"></b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-2" v-else>
        <b-col class="px-2 py-1 bg-headed_grey rounded" >
          <b-alert show variant="info" class="text-sm">
            <i class="fa fa-info-circle mr-2"></i> No filing history yet
          </b-alert>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
export default {
  name: "FilingHistory",
  props:{
    brn:String
  },
  data(){
    return{
      userProfile:{},
      loading:true,
      currentPage:1,
      history:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
    }
  },
  mounted(){
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchCompanyFilingHistory"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    fetch(){
      this.fetchCompanyFilingHistory(this.brn)
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    statusTranslator(code){
      if(code === "DORMANCY"){
        return "Filed for Dormancy"
      }
      if(code === "CHANGE_OF_NAME"){
        return "Changed Company Name"
      }
      if(code === "CHANGE_OF_ADDRESS"){
        return "Changed Address of Company"
      }
      if(code === "CHANGE_OF_SECRETARIES"){
        return "Appointed a new Company Secretary"
      }
      if(code === "CHANGE_OF_DIRECTORS"){
        return "Changed the Company Directors"
      }
      if(code === "CHANGE_OF_MEMBERSHIP"){
        return "Changed membership of the Company"
      }
      if(code === "CHANGE_SHARE_CAPITAL"){
        return "Changed Share Capital of the Company"
      }
      if(code === "CHANGE_OF_MEMBERSHIP"){
        return "Changed membership of the Company"
      }
      if(code === "CHANGE_OF_MEMBERSHIP"){
        return "Changed membership of the Company"
      }
      if(code === "APPEND_INFORMATION"){
        return "Filed a Resolution"
      }
      if(code === "FILE_FORM_18"){
        return "Filed Form 18 to provide the Address of the Company"
      }
      if(code === "FILE_FORM_20"){
        return "Filed Form 20 to provide the Directors and Secretary of the Company"
      }
      if(code === "FILE_FORM_10"){
        return "Filed Form 10 to allot Shares of the Company"
      }
      if(code === "FORM_13" || code === "FORM_14" || code === "FORM_15" || code === "FORM_17"){
        return "Filed Charges"
      }
    }
  },
  computed:{
    ...mapGetters(["getCompanyFilingHistory", "getBusinessDetailError"])
  },
  watch:{
    getCompanyFilingHistory(data){
      this.loading = false
      this.history = data.data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getBusinessDetailError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }

}
</script>

<style scoped>
.circle {
  position: absolute;
  transform: translate(-50%, -35%);
  width: 25px;
  height: 25px;
  background-color: #157EC2;
  border-radius: 100%;
}
.line {
  position: absolute;
  transform: translate(-50%, -15%);
  width: 2px;
  height: 120px;
  background-color: #157EC2;
}
</style>