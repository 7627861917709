<template>
  <div class="error">
    <b-row>
      <b-col lg="5" class="mx-auto min-h-screen" style="margin-top: 10%;">
        <b-card tile class="text-center">
          <img :src="require('../../../assets/images/ic_403.png')" width="30%" class="mx-auto py-3 px-3">
          <b-card-text class="h1 mt-2">
            S<span class="text-red">top</span> It!
          </b-card-text>

          <b-card-text class="mt-3 h4">
            FORBIDDEN ACCESS
          </b-card-text>

          <b-card-text style="font-size: 17px;" class="mt-3">
            You do not have the Permission to view this resource. <br/> For us to be sure you have the correct permissions.
          </b-card-text>
          <b-card-text class="text-md">
            Kindly
            <a @click="doLogout" href="#" class="text-decoration-none font-bold text-blue">click here</a>
            to login again.
          </b-card-text>
          <b-card-text class="mb-4 text-md">
            <button class="bg-green text-decoration-none text-white px-3 py-3 mt-3 text-lg rounded-md" @click="goToHome">Go to Dashboard Page</button>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "Error403",

  methods: {
    async doLogout() {
      await sessionStorage.clear();
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href)
    },
    goToHome(){
      let r = this.$router.resolve({
        name: "Home",
      });
      window.location.assign(r.href)
    }
  }
};
</script>

<style scoped>
.error{
  overflow: hidden;
  background-color: #f7f7f8;
}
</style>
