import Vue from "vue";
import Vuex from "vuex";
import dashboard from "@/views/home/vuex-store/dashboard";
import reservations from "@/views/home/services/reservation/vuex-store/reservations";
import accounts from "@/views/home/services/accounts/vuex-store/accounts";
import business_registry from "@/views/home/services/registry/vuex-store/business_registry";
import legal_documents_registry from "@/views/home/services/registry/vuex-store/legal_documents_registry";
import dictionary from "@/views/home/services/reservation/vuex-store/dictionary";
import business_name_registration from "@/views/home/services/registration/vuex-store/business_name_registration";
import company_incorporation from "@/views/home/services/incorporation/vuex-store/company_incorporation";
import login_store from "@/views/auth/vuex-store/login_store";
import user_account_registration from "@/views/auth/vuex-store/register_store";
import roles_store from "@/views/home/services/accounts/vuex-store/roles_store";
import name_search from "@/views/home/services/quick-name-search/vuex-store/name_search";
import building_society_incorporation
  from "@/views/home/services/bsi_incorporation/vuex-store/building_society_incorporation";
import registration_assignments from "@/views/home/services/registration/vuex-store/registration_assignments";
import registration_escalations from "@/views/home/services/registration/vuex-store/registration_escalations";
import legal_documents from "@/views/home/services/legal-documents/vuex-store/legal_documents";
import company_continuous_filing from "@/views/home/services/continuous-filing/vuex-store/company_continuous_filing";
import business_file from "@/views/home/services/registry/vuex-store/business_file";
import ura from "@/views/home/vuex-store/ura";
import insolvency from "@/views/home/services/insolvency/vuex-store/insolvency";
import practitioners_registry from "@/views/home/services/registry/vuex-store/practitioners_registry";
import filing_form_data from "@/views/home/services/continuous-filing/task/forms/vuex-store/filing_form_data";
import amendments from "@/views/home/services/amendments/vuex-store/amendments";
import tracking from "@/views/home/services/tracking/vuex-store/tracking";
import entity_documents from "@/views/home/services/registry/vuex-store/entity_documents";
import certification from "@/views/home/services/certification/vuex-store/certification";
import analytics_dashboard from "@/views/home/services/analytics/vuex-store/analytics_dashboard";
import dispensation from "@/views/home/services/dispensation/vuex-store/dispensation";
import charge_registry from "@/views/home/services/registry/vuex-store/charge_registry";
import record_search from "@/views/home/services/search_reports/vuex-store/record_search";
import beneficial_owners_registry from "@/views/home/services/registry/vuex-store/beneficial_owners_registry";
import company_dataupdate from "@/views/home/services/data-update/vuex-store/company_dataupdate";
import business_name_dataupdate from "@/views/home/services/data-update/vuex-store/business_name_dataupdate";
import annual_returns from "@/views/home/services/annual-returns/vuex-store/annual_returns";
import dispute_resolution from "@/views/home/services/dispute-resolution/vuex-store/dispute_resolution";
import data_correction from "@/views/home/services/data-correction/vuex-store/data_correction";
import reports from "@/views/home/services/reports/vuex-store/reports";
import insolvency_sate_manager from "@/views/home/services/insolvency/vuex-store/insolvency_sate_manager";
import Occupations from "@/views/home/services/insolvency/vuex-store/occupations";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accounts,
    dispensation,
    dashboard,
    analytics_dashboard,
    reservations,
    business_file,
    business_registry,
    legal_documents_registry,
    business_name_registration,
    company_incorporation,
    building_society_incorporation,
    registration_assignments,
    registration_escalations,
    dictionary,
    login_store,
    user_account_registration,
    roles_store,
    legal_documents,
    company_continuous_filing,
    name_search,
    insolvency,
    practitioners_registry,
    filing_form_data,
    ura,
    amendments,
    tracking,
    entity_documents,
    certification,
    charge_registry,
    record_search,
    beneficial_owners_registry,
    company_dataupdate,
    business_name_dataupdate,
    annual_returns,
    dispute_resolution,
    data_correction,
    reports,
    insolvency_sate_manager,
    Occupations
  },
  state: {
    network_status: null
  },
  getters: {
    getNetworkStatus: (state) => state.network_status
  },
  mutations: {
    resetNetworkStatus: (state) => {
      state.network_status = null;
    }
  },
});
