<template>
  <b-row>
    <b-col>
      <b-card :header="badge">
        <b-row class="my-2">
          <b-col>
            <p>Registation Number: <b>{{getBusinessNameDetails.brn}}</b></p>
            <p>Name: <b>{{getBusinessNameDetails.name}}</b></p>
            <p>Date of Commencement: <b>{{getBusinessNameDetails.dateOfCommencement}}</b></p>
          </b-col>
        </b-row>

        <table class="min-w-full leading-normal">
          <thead class="bg-header_grey border border-header_grey">
          <tr>
            <th class="px-2 py-1" colspan="12">Individual Partners</th>
          </tr>
          </thead>
          <thead class="border border-gray">
          <tr>
            <th class="px-2 py-1">#</th>
            <th class="px-2 py-1">Name</th>
            <th class="px-2 py-1">ID Number</th>
            <th class="px-2 py-1">Sex</th>
            <th class="px-2 py-1">Nationality</th>
          </tr>
          </thead>
          <tbody>
          <tr style="border: solid 1px #dedede;" v-for="(partner, idx) in getBusinessPartners.individualPartners" :key="idx">
            <td class="px-2 py-1">
              {{idx+1}}.
            </td>
            <td class="px-2 py-1">
              {{partner.givenName}} {{partner.surName}}
            </td>
            <td class="px-2 py-1 text-capitalize">
              {{partner.idNumber}}
            </td>
            <td class="px-2 py-1 text-capitalize">
              {{partner.sex.toLowerCase()}}
            </td>
            <td class="px-2 py-1 text-capitalize">
              {{partner.nationality}}
            </td>

          </tr>
          <tr>
            <td v-if="getBusinessPartners.individualPartners.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-xs">No results found</span>
            </td>
          </tr>
          </tbody>

        </table>
        <table class="min-w-full leading-normal mt-3">
          <thead class="bg-header_grey border border-header_grey">
          <tr>
            <th class="px-2 py-1" colspan="12">Corporate Partners</th>
          </tr>
          </thead>
          <thead class="border border-gray">
          <tr>
            <th class="px-2 py-1">#</th>
            <th class="px-2 py-1">Name</th>
            <th class="px-2 py-1">Registration Number</th>
          </tr>
          </thead>
          <tbody>
          <tr style="border: solid 1px #dedede;" v-for="(partner, idx) in getBusinessPartners.corporatePartners" :key="idx">
            <td class="px-2 py-1">
              {{idx+1}}.
            </td>
            <td class="px-2 py-1">
              {{partner.registeredName}}
            </td>
            <td class="px-2 py-1 text-capitalize">
              {{partner.brn}}
            </td>
          </tr>
          <tr>
            <td v-if="getBusinessPartners.corporatePartners.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-xs">No results found</span>
            </td>
          </tr>
          </tbody>

        </table>

        <table class="min-w-full leading-normal mt-3">
          <thead class="bg-header_grey border border-header_grey">
          <tr>
            <th class="px-2 py-1" colspan="12">Business Address</th>
          </tr>
          </thead>
          <tbody>
            <tr style="border: solid 1px #dedede;" v-if="getBusinessNameDetails.physicalAddress">
              <td class="px-2 py-1 font-bold">
                Country
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.physicalAddress.country.name}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;" v-if="getBusinessNameDetails.physicalAddress">
              <td class="px-2 py-1 font-bold">
                District
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.physicalAddress.district.name}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;" v-if="getBusinessNameDetails.physicalAddress">
              <td class="px-2 py-1 font-bold">
                Region
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.physicalAddress.region.name}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;" v-if="getBusinessNameDetails.physicalAddress">
              <td class="px-2 py-1 font-bold">
                Sub County
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.physicalAddress.subcounty.name}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;" v-if="getBusinessNameDetails.physicalAddress">
              <td class="px-2 py-1 font-bold">
               Parish
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.physicalAddress.parish.name}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;" v-if="getBusinessNameDetails.physicalAddress">
              <td class="px-2 py-1 font-bold">
                Village
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.physicalAddress.village.name}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 font-bold">
                Postal Address
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.postalAddress}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 font-bold">
                Plot
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.plot}}
              </td>
            </tr>
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 font-bold">
                Street
              </td>
              <td class="px-2 py-1">
                {{getBusinessNameDetails.street}}
              </td>
            </tr>
          </tbody>

        </table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BusinessParticulars",
  props:{
    brn:String,
    badge:String
  },
  mounted(){
    this.fetchBusinessNameDetailsByBRN(this.brn)
    this.fetchBusinessNamePartners(this.brn)
  },
  methods:{
    ...mapActions(["fetchBusinessNameDetailsByBRN", "fetchBusinessNamePartners", "fetchAddressByVillageId"])
  },
  computed:{
    ...mapGetters(["getBusinessNameDetails", "getBusinessPartners"])
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
p{
  font-size: 14px;
}
</style>