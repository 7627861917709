<template>
  <b-row>
    <b-col cols="12" class="py-2">
      <b-row>
        <b-col cols="12" class="text-center">
          <span class="text-grey">Account Management</span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="10" class="mx-auto">
          <b-form class="w-full">
            <b-form-input
                v-model="form.givenName"
                type="text"
                placeholder="Given Name"
                class="mt-2 text-xs noFocus"
                disabled="true"
                required>
            </b-form-input>
            <b-form-input
                v-model="form.surName"
                type="text"
                placeholder="Sur Name"
                class="mt-2 text-xs noFocus"
                disabled="true"
                required>
            </b-form-input>
            <b-form-input
                v-model="form.middleName"
                type="text"
                placeholder="Middle Name"
                class="mt-2 text-xs noFocus"
                disabled="true"
                required>
            </b-form-input>
            <b-form-input
                v-model="form.idNumber"
                type="text"
                placeholder="ID Number"
                class="mt-2 text-xs noFocus"
                disabled="true"
                required>
            </b-form-input>

            <b-form-input
                v-model="form.email"
                type="text"
                placeholder="Email Address"
                class="mt-2 text-xs noFocus"
                disabled="true"
                required>
            </b-form-input>

            <b-input-group>
              <b-form-input
                  v-model="form.phoneNumber"
                  type="text"
                  placeholder="Phone Number"
                  class="mt-2 text-xs noFocus"
                  :disabled="editPhone"
                  required>
              </b-form-input>
              <b-input-group-append>
                <i class="fa fa-pen mt-3 ml-1 text-gray hover:text-blue" style="cursor: pointer;" @click="editPhone = !editPhone"></i>
              </b-input-group-append>
            </b-input-group>

            <b-form-input
                v-model="form.dob"
                type="date"
                class="mt-2 text-xs noFocus"
                disabled="true"
                required>
            </b-form-input>
          </b-form>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="text-center">
          <button class="button">Update Information</button>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import jwtService from "@/app/services/jwt.service";

export default {
  name: "AccountManagement",
  data(){
    return{
      userProfile:{},
      form:{},
      editPhone:true,
    }
  },
  mounted() {
    this.userProfile = jwtService.getUser()
    this.form = this.userProfile
  },
}
</script>

<style scoped>
.button {
  @apply text-center rounded-sm bg-blue text-white px-5 py-1 font-bold;
  font-size: 12px;
}

.button:hover {
  @apply bg-blue-dark border-blue-dark;
}

</style>