<template>
  <div>
    <b-row v-if="$route.path.includes('data-update')">
      <b-col cols="12">
        <b-form-group label="Status" v-slot="{ ariaDescribedby }">

        <b-form-radio-group
          id="radio-group-1"
          v-model="subscriber.leaving"
          :options="leavingStatuses"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <label class="text-gray mb-1" style="font-size: 14px;">Nationality<i style="font-size: 10px"
                                                                             class="fas fa-asterisk ml-1 text-red"></i></label>
        <b-form-group>
          <b-form-select
            :disabled="readOnlyForm"
            id="nationality"
            @change="actionOnNationality"
            class="form-select noFocus text-gray text-sm"
            v-model="subscriber.nationality"
            :options="nationalityLIst">
          </b-form-select>
        </b-form-group>

        <b-row class="mt-2">
          <b-col v-if="subscriber.nationality && subscriber.nationality !== 'Ugandan'" cols="12" sm="12" md="12"
                 :lg="subscriber.idType?6:12">
            <label class="text-gray mb-1" style="font-size: 14px;">Type of ID<i style="font-size: 10px"
                                                                                class="fas fa-asterisk ml-1 text-red"></i></label>
            <b-form-group>
              <b-form-select
                :disabled="readOnlyForm"
                id="idType"
                @change="checkIdType(subscriber.idType)"
                class="form-select  noFocus text-gray text-sm"
                v-model="subscriber.idType"
                :options="idTypes">
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col v-if="subscriber.idType || subscriber.nationality === 'Ugandan'" cols="12"
                 :lg="subscriber.nationality === 'Ugandan'?12:6" md="12" sm="12">
            <label class="text-gray" style="font-size: 14px;">{{ IDNumberPlaceHolder }}<i style="font-size: 10px"
                                                                                          class="fas fa-asterisk ml-1 text-red"></i></label>
            <b-form-group
              id="input-group-1"
              label-for="input-1">
              <b-form-input
                id="idNumber"
                maxlength="20"
                class="form-text noFocus text-uppercase text-sm"
                type="text"
                v-model="subscriber.idNumber"
                @input="validateIdTypes"
                :disabled="readOnlyForm"
                required>
              </b-form-input>
            </b-form-group>
            <b-progress v-if="loaderProgress" :value="100" height="3px" variant="#007ac5" animated="true"></b-progress>
            <small v-if="ninStatusMsg" :style="'color:'+ninColor">{{ ninStatusMsg }}</small>
          </b-col>
        </b-row>

        <span v-if="showFormDetails">

          <div v-if="showIfNinIsRequired">
        <label class="text-gray mb-1" style="font-size: 14px;">Surname (Last Name)<i style="font-size: 10px"
                                                                                     class="fas fa-asterisk ml-1 text-red"></i></label>
        <b-form-group
          id="input-group-1"
          label-for="input-1">
          <b-form-input
            :disabled="readOnlyForm"
            :readonly="readOnlyNin"
            id="surName"
            class="form-text mb-3 noFocus text-uppercase text-sm"
            type="text"
            v-model="subscriber.surName"
            required>
          </b-form-input>
        </b-form-group>
          </div>

        <b-row v-if="showIfNinIsRequired">
          <b-col cols="12" sm="12" md="12" lg="6">
           <label class="text-gray mb-1" style="font-size: 14px;">Given Name (First Name)<i style="font-size: 10px"
                                                                                            class="fas fa-asterisk ml-1 text-red"></i></label>
            <b-form-group
              id="input-group-1"
              label-for="input-1">
              <b-form-input
                :readonly="readOnlyNin"
                id="givenName"
                class="form-text mb-3 noFocus text-uppercase text-sm"
                type="text"
                v-model="subscriber.givenName"
                :disabled="readOnlyForm"
                required>
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="12" lg="6">
            <label class="text-gray mb-1" style="font-size: 14px;">Middle Name</label>
            <b-form-group
              id="input-group-1"
              label-for="input-1">
              <b-form-input
                :readonly="readOnlyNin"
                id="middleName"
                class="form-text mb-3 noFocus text-uppercase text-sm"
                type="text"
                :disabled="readOnlyForm"
                v-model="subscriber.middleName">
              </b-form-input>
            </b-form-group>
          </b-col>

        </b-row>

          <b-row>
            <b-col cols="12" sm="12" md="12" lg="6">
                <label class="text-gray mb-1" style="font-size: 14px;">Date of Birth</label>
               <datepicker
                 :disabled-dates="{from:new Date()}"
                 class="noFocus text-sm"
                 :typeable="false"
                 bootstrap-styling
                 id="dateOfBirth"
                 format="dd/MM/yyyy"
                 placeholder="dd/mm/yyyy"
                 v-model="subscriber.dateOfBirth"
                 :disabled="readOnlyForm"
               ></datepicker>

            </b-col>
            <b-col cols="12" sm="12" md="12" lg="6">
        <label class="text-gray mb-1" style="font-size: 14px;">Gender<i style="font-size: 10px"
                                                                        class="fas fa-asterisk ml-1 text-red"></i></label>
        <b-form-group
          class="mb-2 text-sm"
          id="input-group-1"
          label-for="input-1">
          <b-form-select
            id="sex"
            :disabled="readOnlyNin || readOnlyForm"
            v-model="subscriber.sex"
            :options="sexList"
            class="form-select noFocus text-sm">
          </b-form-select>
        </b-form-group>
            </b-col>
          </b-row>

          <label class="text-gray mb-1" style="font-size: 14px;">Email<i style="font-size: 10px"
                                                                         class="fas fa-asterisk ml-1 text-red"></i></label>
        <b-form-group>
          <b-form-input
            id="email"
            class="form-text noFocus text-sm"
            type="email"
            maxlength="60"
            v-model="subscriber.email"
            :disabled="readOnlyForm"
            @input="validateEmailAddress(subscriber.email)"
            required>
          </b-form-input>
        </b-form-group>
      <small style="color: red;" v-if="emailErrorResponse">{{ emailErrorResponse }}</small>
        <div>
           <label class="text-gray mb-1" style="font-size: 14px;">Phone<i style="font-size: 10px"
                                                                          class="fas fa-asterisk ml-1 text-red"></i></label>
          <b-input-group class="mb-3" style="border:1px solid #ced4da;border-radius:  2px 0 0 2px;">
              <b-input-group-prepend
                style="background: #f1f1f1;">
              <p class="pt-2 float-end px-2 text-sm" style="color: #505050;">+{{ countryCodeNumber }}</p>
              </b-input-group-prepend>
            <b-input-group-prepend>
              <vue-country-code
                v-if="!readOnlyForm"
                :disabledFetchingCountry="true"
                :enabledFlags="false"
                class="countryCodeStyle text-sm"
                @onSelect="onSelectCountryCode"
                :preferredCountries="['UG']">
              </vue-country-code>
            </b-input-group-prepend>

            <b-form-input
              autocomplete="off"
              @paste.prevent
              @keypress="onlyNumberValidator($event)"
              id="phoneNumber"
              data-test="typeUserPhone"
              required
              v-mask="'#############'"
              maxlength="13"
              @input="validatePhone(phoneNo)"
              v-model="phoneNo"
              :disabled="readOnlyForm"
              type="tel"
              style="color:#505050; height: 32px;"
              class="form-text noFocus border-0 removeOnClickBorder text-sm">
            </b-form-input>
          </b-input-group>

          <small style="color: red" v-if="phoneNumberError" v-html="phoneNumberError"></small>
        </div>

        </span>

      </b-col>

      <b-col cols="6" v-if="showFormDetails">

        <label class="text-gray mb-1" style="font-size: 14px;">Occupation<i style="font-size: 10px"
                                                                            class="fas fa-asterisk ml-1 text-red"></i></label>
        <b-form-group
          class="mb-3">
          <b-row>
            <b-col cols="12">
              <b-form-select
                :disabled="readOnlyForm"
                id="occupation"
                class="form-select  noFocus text-gray text-sm"
                v-model="occupationSelector"
                :options="occupationList"
                @change="checkOccupation"
              >
              </b-form-select>

            </b-col>
            <b-col cols="12" v-if="occupationSelector === 'other'">
              <b-input
                  class="mt-1 noFocus text-gray text-sm"
                v-model="subscriber.occupation"
                placeholder="Specify occupation"
              >

              </b-input>
            </b-col>
          </b-row>
        </b-form-group>

        <div class="mb-3">
          <p>Physical Address</p>
          <hr />
        </div>
        <div>
          <residenceof-partner
            :readOnlyDisableFields="readOnlyForm"
            :villageDataInfo="villageTree"
            ref="subscriber_address_component"
            v-on:residenceInfo="residenceInfo"
            v-on:removePlotStreet="removePlotStreetMain"
          >
          </residenceof-partner>
        </div>


        <span>
          <b-row>
            <b-col cols="12">
               <label class="text-gray" style="font-size: 14px;">
                  Postal Address
                </label>
              <br />

          <b-input-group
            :style="addressColor === 'red' ? 'border: 1px solid red' :''"
            style="border:1px solid #e6e9ec;border-radius:  2px 0 0 2px;
              margin-top: 6px;">
            <b-input-group-prepend
              style="background: #f1f1f1;">
              <p class="pt-2 float-end px-2 text-sm" style="color: #505050;">P.O.BOX </p>
            </b-input-group-prepend>
            <b-input-group-prepend
              class="mr-1 text-sm"
              style="background: #ffffff; width: 35%; border-right: 1px solid #e6e9ec;">
              <b-form-input
                  autocomplete="off"
                  @paste.prevent
                  :disabled="readOnlyForm"
                  :style="addressColor === 'red' ? 'border: 1px solid red' :''"
                  id="phoneNumber"
                  required
                  v-model="boxNumber"
                  @keypress="onlyNumberValidator($event)"
                  v-on:blur="validatePostalAddress()"
                  type="text"
                  style="color:#505050; height: 32px;"
                  class="form-text noFocus border-0 removeOnClickBorder text-sm">
              </b-form-input>

            </b-input-group-prepend>
            <b-form-select
              :disabled="readOnlyForm"
              id="idType"
              style="color: #505050;"
              data-test="typeIdType"
              class="form-select  noFocus border-0 removeOnClickBorder text-gray text-sm"
              v-model="boxStation"
              @change="validatePostalAddress()"
              :options="stationList">
            </b-form-select>
          </b-input-group>


          <b-progress v-if="loaderPostalProgress" :value="100" height="3px" variant="#007ac5"
                      animated="true"></b-progress>
              <small style="color: #afaeae;">[ P.O Box 123 Kampala ]</small>
              <small class="float-end" v-if="postalAddressStatusMsg" :style="'color:'+addressColor"
                     v-html="postalAddressStatusMsg"></small>
          <br />
            </b-col>
          </b-row>


          <b-row class="mt-2" v-if="showPlotAndStreet">
            <b-col cols="6">
                <label class="text-gray" style="font-size: 14px;">Plot</label>
            <b-form-group
              id="input-group-1"
              label-for="input-1">
              <b-form-input
                :disabled="readOnlyForm"
                id="input-1"
                class="form-text noFocus text-uppercase text-sm"
                type="text"
                v-model="subscriber.plotNumber">
              </b-form-input>
            </b-form-group>
            </b-col>
            <b-col cols="6">
                 <label class="text-gray" style="font-size: 14px;">Street</label>
            <b-form-group
              id="input-group-1"
              label-for="input-1">
              <b-form-input
                id="street"
                class="form-text mb-1 noFocus text-uppercase text-sm"
                type="text"
                :disabled="readOnlyForm"
                v-model="subscriber.street">
              </b-form-input>
            </b-form-group>
            </b-col>
          </b-row>

<div v-if="subscriber.nationality !== 'Ugandan'">

            <label class="text-gray" style="font-size: 14px;">Foreign postal address</label>
            <b-form-group>
              <b-form-input
                maxlength="60"
                id="street"
                class="form-text mb-3 noFocus text-uppercase text-sm"
                type="text"
                :disabled="readOnlyForm"
                v-model="subscriber.foreignPostalAddress">
              </b-form-input>
            </b-form-group>
</div>





        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResidenceofPartner from "@/views/home/services/insolvency/winding-up/components/ResidenceofPartner.vue";
import Datepicker from "vuejs-datepicker";
import { api } from "@/app/services/api.service";

export default {
  name: "SubscriberInformation",
  components: { ResidenceofPartner, Datepicker },
  props: {
    index: { default: null },
    subscriberList: {},
    selectedPositionData: {}
  },
  data() {
    return {
      disableNationality: false,
      validateDateOfBirth: "",
      occupationSelector: "",
      occupationList: [],
      nationalityLIst: [],
      otherOccupation: null,
      emailErrorResponse: "",
      villageTree: {},
      readOnlyForm: false,
      showPlotAndStreet: true,
      phoneNumberError:null,
      subscriber: {
        foreignPhysicalAddress: null,
        countryId: null,
        surName: null,
        givenName: null,
        middleName: null,
        dateOfBirth: null,
        email: null,
        phoneNumber: null,
        idType: "",
        idNumber: null,
        nationality: "",
        occupation: "",
        postalAddress: null,
        street: null,
        villageId: null,
        address: null,
        country: null,
        plotNumber: null,
        sex: "",
        foreignPostalAddress: null,
        selectedPositions: null,
        leaving : false
      },
      idTypes: [
        {
          value: "",
          text: "--Select ID Type --"
        },
        {
          value: "PASSPORT",
          text: "Passport"
        },
        {
          value: "ALIEN_ID",
          text: "Alien ID"
        }
      ],
      sexList: [
        { text: "--Select gender at birth--", value: "" },
        { text: "MALE", value: "MALE" },
        { text: "FEMALE", value: "FEMALE" }
      ],
      IDNumberPlaceHolder: "ID Number",
      modalShowVillageLocation: false,
      villageId: null,
      villageList: [],
      countryCodeNumber: null,
      phoneNo: null,
      showFormDetails: false,
      showIfNinIsRequired: true,
      loaderProgress: false,
      ninStatusMsg: null,
      ninColor: null,
      readOnlyNin: false,
      countryList: [],
      countryId: null,
      regionList: [],
      regionId: null,
      districtList: [],
      districtId: null,
      subCountyList: [],
      subCountyId: null,
      parishList: [],
      parishId: null,
      loaderPostalProgress: false,
      postalAddressStatusMsg: "",
      addressColor: null,
      boxNumber: null,
      boxStation: "",
      stationList: null,
      arrayData: null,
      leavingStatuses: [
        { text: 'Alive', value: false },
        { text: 'Dead', value: true },
      ]
    };
  },
  mounted() {
    this.checkNewSubscriberData();
    this.fetchNationality()
    this.checkPosition();
    this.fetchOccupations();
    this.fetchStations();
  },

  methods: {
    ...mapActions(
      [
        "fetchVillageGeneral",
        "fetchRegion",
        "fetchDistricts",
        "fetchSubCounty",
        "fetchParish",
        "fetchVillage",
        "fetchOccupations",
        "fetchStations"
      ]
    ),
    checkIdType(type) {
      this.errorMessage = "";
      this.readOnlyNin = false;
      this.IDNumberPlaceHolder = "ID Number";

      this.subscriber.surName = null;
      this.subscriber.givenName = null;
      this.subscriber.middleName = null;
      this.subscriber.email = null;
      this.subscriber.phoneNumber = null;
      this.subscriber.idNumber = null;
      this.subscriber.foreignPostalAddress = null;
      this.subscriber.sex = "";
      this.showIfNinIsRequired = true;

      if (type === "NATIONAL_ID") {
        //if national id is selected by default he is a ugandan
        this.IDNumberPlaceHolder = "NIN";
        this.readOnlyNin = true;
        this.subscriber.nationality = "Ugandan";
        this.showIfNinIsRequired = false;
      }
      if (type === "PASSPORT") {
        this.IDNumberPlaceHolder = "Passport No";
      }

      if (type === "ALIEN_ID") {
        this.IDNumberPlaceHolder = "Alien ID No";
      }

      if (type === "BIRTH_CERTIFICATE") {
        this.IDNumberPlaceHolder = "Birth Certificate No";
      }
    },
    selectVillage() {
      this.modalShowVillageLocation = true;
    },
    selectedVillage(data) {
      this.subscriber.villageId = data;
    },
    filterDistricts(data) {
      let arrayData = [];
      let obj = {};
      data.forEach(list => {
        obj = {
          value: list.id,
          text: list.name
        };
        arrayData.push(obj);
      });

      return arrayData;
    },
    onSelectCountryCode({ dialCode }) {
      if (dialCode) {
        this.countryCodeNumber = dialCode;
        this.validatePhone(this.phoneNo);
      }
    },
    checkOccupation(data){
      if(data){
        this.subscriber.occupation = (data === 'other') ? "" : data
      }

    },
    validatePhone(data) {
      if (data) {
        //  phone number should allstart with a zero
        if (data.charAt(0) === "0") {
          this.phoneNo = data.substring(1);
        }
        this.phoneNumberError = (this.phoneNo.startsWith("256") && this.countryCodeNumber === '256') ? "Invalid Phone, <b>"+this.countryCodeNumber+"</b> is already provided for you" : ""
        let code = this.countryCodeNumber ? this.countryCodeNumber : "256";
        this.countryCodeNumber = code;
        // this.subscriber.phoneNumber = code + this.phoneNo;
        this.subscriber.phoneNumber = `${"(" + code + ")"}${this.phoneNo}`;
      }
    },
    async validateIdTypes() {
      this.errorMessage = "";
      this.ninStatusMsg = "";
      this.ninColor = "";
      this.showFormDetails = this.subscriber.nationality !== "Ugandan";


      if (this.subscriber.nationality === "Ugandan") {

        ///validate first two characters if they are alphabet
        if (!(/^[a-zA-Z()]+$/.test(this.subscriber.idNumber.slice(0, 2)))) {
          this.ninStatusMsg = "Invalid Nin [NIN starts with letters!]";
          this.ninColor = "red";
          return true;
        }

      }

      if (this.subscriber.idType === "NATIONAL_ID" && this.subscriber.idNumber.length === 14) {
        this.showFormDetails = false;
        this.loaderProgress = true;

        await this.validateNinDataInfo({ nin: this.subscriber.idNumber.toUpperCase() });
      } else if (this.subscriber.idType === "NATIONAL_ID" && this.subscriber.idNumber.length < 14) {
        this.clearNinDetails();
      }

    },
    clearNinDetails() {
      this.subscriber.surName = "";
      this.subscriber.givenName = "";
      this.subscriber.middleName = "";
      this.subscriber.sex = "";
      this.subscriber.dateOfBirth = "";
    },
    async validateNinDataInfo(data) {
      data = { ...data, reference: this.getReference() };
      await api().post("/integration/api/nira", data)
          .then((response) => {
            this.subscriberDataInfo(response.data);
            this.loading = false;
          })
          .catch((error) => {
            if(error &&  error.response) {
              this.loading = false;
              // this.errorToast("Error", error.response.data.message);
              this.errorToast("Error", "We currently cannot verify your identity. Please try again later.");
            }
          });
    },
    subscriberDataInfo(data) {
      if (data) {
        if (data.surname != null && data.givenNames != null) {
          //if nin is selected, the fields are made readonly
          this.readOnlyNin = true;

          this.showFormDetails = true;
          //first display fields when nin is provided
          this.showIfNinIsRequired = true;
          //
          let d = data.dateOfBirth;
          let day = d.substring(0, 2);
          let month = d.substring(5, 3);
          let year = d.substring(10 - 4);

          this.subscriber.surName = data.surname ? data.surname : null;
          this.subscriber.dateOfBirth = year + "-" + month + "-" + day;
          this.subscriber.givenName = data.givenNames ? data.givenNames : null;
          this.subscriber.middleName = data.middleName ? data.middleName : null;
          this.subscriber.sex = data.gender === "F" ? "FEMALE" : "MALE";

          this.subscriber.nationality = data.nationality === "Uganda (UGA)" ? "Ugandan" : "";
        } else {
          // this.errorToast("Error", "NIN doesn't exist")
          this.errorMessage = "NIN doesn't exist";
        }
        this.loaderProgress = false;
      } else {
        // this.errorToast("Error", "NIN doesn't exist")
        this.errorMessage = "NIN doesn't exist";
      }
    },
    actionOnNationality(data) {
      this.subscriber.idNumber = "";
      this.subscriber.idType = "";
      this.readOnlyNin = false;
      this.loaderProgress = false;
      this.clearFormData();
      if (data === "Ugandan") {
        this.readOnlyNin = true;
        this.subscriber.idType = "NATIONAL_ID";
        this.IDNumberPlaceHolder = "NIN";
      }
    },
    clearFormData() {
      this.subscriber.surName = null;
      this.subscriber.givenName = null;
      this.subscriber.middleName = null;
      this.subscriber.email = null;
      this.subscriber.phoneNumber = null;
      this.subscriber.idNumber = null;
      this.subscriber.foreignPostalAddress = null;
      this.subscriber.accountType = "INDIVIDUAL";
      this.subscriber.sex = "";
      this.subscriber.status = "Living";
      this.subscriber.leaving = false;
      this.subscriber.idFile = null;

    },
    checkNewSubscriberData() {
      if (this.subscriberList && this.subscriberList.individual) {
        let data = this.subscriberList.individual;

        this.readOnlyForm = this.subscriberList.readOnly ? this.subscriberList.readOnly : false;

        this.subscriber.nationality = data.nationality ? data.nationality : null;
        this.subscriber.idType = data.idType ? data.idType : null;
        this.subscriber.idNumber = data.idNumber ? data.idNumber : null;
        this.showFormDetails = !!data.idNumber;
        this.subscriber.surName = data.surName ? data.surName : null;
        this.subscriber.givenName = data.givenName ? data.givenName : null;
        this.subscriber.middleName = data.middleName ? data.middleName : null;
        this.subscriber.sex = data.sex ? data.sex : "";
        this.subscriber.dateOfBirth = data.dateOfBirth ? data.dateOfBirth : null;
        this.subscriber.email = data.email ? data.email : null;
        this.subscriber.leaving = data.leaving !== undefined ? data.leaving : false;


        this.subscriber.foreignPostalAddress = data.foreignPostalAddress ? data.foreignPostalAddress : null;
        this.subscriber.street = data.address ? data.address.street : null;
        this.subscriber.plotNumber = data.address ? data.address.plot : null;
        this.subscriber.villageId = data.villageId ? data.villageId : null;
        this.subscriber.address = data.address ? data.address : null;
        this.subscriber.country = data.country ? data.country : null;
        this.subscriber.countryId = data.countryId ? data.countryId : null;
        this.subscriber.foreignPhysicalAddress = data.foreignPhysicalAddress ? data.foreignPhysicalAddress : null;
        this.subscriber.postalAddress = data.address ? data.address.postalAddress : null;


        if (data.phoneNumber && data.phoneNumber.includes("(")) {
          if (data.phoneNumber) {
            let phoneArray = data.phoneNumber.replace("(", "").split(")");
            this.countryCodeNumber = phoneArray[0];
            this.phoneNo = phoneArray[1];
            this.subscriber.phoneNumber = `${"(" + phoneArray[0] + ")"}${phoneArray[1]}`;
          }
        } else {
          this.phoneNo = data.phoneNumber ? data.phoneNumber.substring(3) : "";
          this.countryCodeNumber = data.phoneNumber ? data.phoneNumber.slice(0, 3) : "256";
          this.subscriber.phoneNumber = data.phoneNumber ? `${"(" + data.phoneNumber.slice(0, 3) + ")"}${data.phoneNumber.substring(3)}` : null;
        }


        this.subscriber.occupation = data.occupation ? data.occupation : null

        if(this.subscriber.occupation && (this.occupationList.length > 0) && !this.occupationSelector) {
          this.occupationSelector = data.occupation ? ((this.occupationList.find(obj => obj.value === this.subscriber.occupation) === undefined) ? "other" : this.subscriber.occupation) : null
        }


        if (data.address && data.address.postalAddress && data.address.postalAddress) {
          let boxNumber = data.address.postalAddress.split(" ");
          this.boxNumber = boxNumber[0];
          this.boxStation = data.address.postalAddress.replace(boxNumber[0] + " ", "");
        }

        let addressData = data.address ? data.address.physicalAddress : null;
        let country = data.country ? data.country : null;
        let addrs = {
          foreignPhysicalAddress: data.foreignPhysicalAddress ? data.foreignPhysicalAddress : null,
          countryId: data.countryId ? data.countryId : null
        };

        this.villageTree = { ...addressData, ...addrs, ...country };

      }
    },
    validateEmailAddress(email) {
      const re = /\S+@\S+\.\S+/;
      let emailResp = re.test(email);
      this.emailErrorResponse = emailResp ? "" : "Please provide a valid email";
    },
    checkPosition() {
      if (this.selectedPositionData) {
        this.subscriber.selectedPositions = this.selectedPositionData;
      }
    },
    residenceInfo(data) {
      if(data) {
        this.subscriber.villageId = data.villageId ? data.villageId : null;
        this.subscriber.countryId = data.countryId ? data.countryId : null;
        this.subscriber.foreignPhysicalAddress = data.foreignPhysicalAddress ? data.foreignPhysicalAddress : null;
      }
    },
    removePlotStreetMain(data) {
      if(data) {
        this.showPlotAndStreet = data === "UGANDA";
        if (data !== "UGANDA") {
          this.subscriber.plotNumber = "";
          this.subscriber.street = "";
        }
      }
    },
    validatePostalAddress() {
      if (this.boxStation && this.boxNumber) {

        this.postalAddressStatusMsg = "";
        this.loaderPostalProgress = true;
        this.addressColor = null;

        let obj = {
          boxStation: this.boxStation,
          boxNumber: this.boxNumber
        };
        this.subscriber.postalAddress = this.boxNumber + " " + this.boxStation;
        this.fetchPostalAddressData(obj);
      } else {
        this.addressColor = ""
        this.postalAddressStatusMsg = ""
        this.subscriber.postalAddress = null;
        this.removePostalAddressError();
      }
    },
    async fetchPostalAddressData(brnData) {
      await api().post("/integration/api/eposta/verify/individual", brnData)
        .then((response) => {
          if (response) {
            this.loaderPostalProgress = false;
            this.postalAddressStatusMsg = "Postal address belongs to: <br /><b>" + response.data.data.box_renter + "</b>";
            this.addressColor = "green";


            //  remove postal address when its green
            //save error message when postal address is wrong
            this.removePostalAddressError();
            ///
          }
        })
        .catch((error) => {
          if (error) {
            this.loaderPostalProgress = false;
            this.postalAddressStatusMsg = error.response.data.message + (error.response.data.data ? ", <br /><b>Renter:" + error.response.data.data.box_renter + "</b>" : "");
            this.addressColor = "red";

            //save error message when postal address is wrong
            let errorArray = JSON.parse(sessionStorage.getItem("postalAddressError"));
            if (!errorArray.includes(this.subscriber.idNumber)) {
              errorArray.push(this.subscriber.idNumber);
            }
            sessionStorage.setItem("postalAddressError", JSON.stringify(errorArray));
            this.arrayData = errorArray;
            ///////
          }
        });
    },
    removePostalAddressError() {
      let errorArray = JSON.parse(sessionStorage.getItem("postalAddressError"));
      let index = this.subscriber ? errorArray.indexOf(this.subscriber.idNumber) : -1;
      if (index > -1) {
        errorArray.splice(index, 1);
      }
      sessionStorage.setItem("postalAddressError", JSON.stringify(errorArray));
      this.arrayData = errorArray;
    },
    async fetchNationality() {
      await api().get("configuration/nationality/list")
          .then((response) => {
            if(response.data.data){
              this.addsNationality(response.data.data)
            }
          })
          .catch((error) => {
            if(error) {
              // this.loading = false;
              // this.errorToast("Error", error.response.data.message);
            }
          });
    },
    addsNationality(data) {
      if (data) {
        let obj = {};
        let arr = [{text: "--Select Nationality", value: ""}];
        data.forEach(list => {
          obj = {
            value: list.name,
            text: list.name
          };
          arr.push(obj);
        });
        this.nationalityLIst = arr;
      }
    }


  },
  computed: {
    ...mapGetters(
      [
        "getIDTypes",
        "getDistricts",
        "getCounties",
        "getSubCounties",
        "getParishes",
        "getVillages",
        "getOccupations",
        "getVillageGeneralResults",
        "getNationalityInfo",
        "getNinValidationData",
        "getStationsResults"
      ]),
 /*   addsNationality() {

      let obj = {};
      let arr = [{ text: "--Select Nationality", value: "" }];
      this.getNationalityInfo.forEach(list => {
        obj = {
          value: list,
          text: list
        };
        arr.push(obj);
      });
      return arr;
    }*/
  },
  watch: {
    subscriberList(){
      this.checkNewSubscriberData();
    },
    subscriber: {
      handler(data) {
        if(data) {
          let formData = {...this.subscriber}
          formData.nationality = (data.idType === "national") ? "Ugandan" : data.nationality

          this.$emit("getSubscriberData", {data: formData, index: this.index});
        }
      },
      deep: true
    },
    selectedPositionData: {
      handler(data) {
        if (data) {
          this.checkPosition();
        }
      },
      deep: true
    },
    getNinValidationError(data) {
      this.loaderProgress = false;
      this.errorMessage = data.message;
      this.errorToast("Error", data.message);
    },

    getOccupations(data) {
      if (data) {
        let arr = [{ text: "--Select Occupation", value: "" }];
        data.forEach(list => {
          arr.push({
            text: list.name,
            value: list.name
          });
        });

        this.occupationList = arr.concat([{text: "Other (specify)", value: "other"}])


        if(this.subscriber.occupation && (this.occupationList.length > 0) && !this.occupationSelector) {
          this.occupationSelector = (this.occupationList.length > 0) ? ((this.occupationList.find(obj => obj.value === this.subscriber.occupation) === undefined) ? "other" : this.subscriber.occupation) : null
        }
      }
    },
    getStationsResults(data) {
      if (data) {
        if (data.data.length > 0) {
          let obj = {};
          let arr = [{ text: "--Select Station--", value: "" }];
          data.data.forEach(lst => {
            obj = { text: lst.branch_name, value: lst.branch_name };
            arr.push(obj);
          });
          this.stationList = arr;
        }

      }
    }

  }
};
</script>

<style scoped>
.countryCodeStyle, .countryCodeStyle:hover {
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
}

.titleDesign {
  font-size: 19px;
}

.alertStyle {
  border: 1px solid red;
}


/*
.datePickerStyle:active{
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline:  none 0 !important;
  background: transparent;
}

.datePickerStyle:focus{
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  outline:  none 0 !important;
  background: transparent;
}*/


</style>