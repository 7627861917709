<template>
  <b-col>
    <b-row>
      <b-col cols="12" class="mt-4">

        <b-row>
          <b-col cols="8">
            <table class="min-w-full leading-normal text-gray"
                   style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <th colspan="12" class="px-2 py-1 text-uppercase">Shares Summary</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <th class="t-body text-uppercase">
                  Share Capital :({{getCompanyShareClassification.data.currency}})
                </th>
                <td class="font-bold t-body">
                  {{getCompanyShareClassification.data.shareCapital.toLocaleString()}}
                </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <th class="t-body text-uppercase">
                  Number of Shares
                </th>
                <td class="font-bold t-body">
                  {{getCompanyShareClassification.data.numberOfShares.toLocaleString()}}
                </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <th class="t-body text-uppercase">
                  Number of Un-allotted Shares
                </th>
                <td class="font-bold t-body">
                  {{(getCompanyShareClassification.data.numberOfShares - allottedShares).toLocaleString()}}
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <p class="h5">Share Classification</p>
          </b-col>
        </b-row>
        <table class="min-w-full leading-normal text-gray"
               style="border: solid 1px #dedede;">
          <thead>
            <tr class="bg-gray-pale">
              <th class="px-2 py-1 text-uppercase">Nominal Class</th>
              <th class="px-2 py-1 text-center text-uppercase">Shares</th>
              <th class="px-2 py-1 text-center text-uppercase">Value</th>
            </tr>
          </thead>
          <tbody v-for="(cls, idx) in classifications" :key="idx">
          <tr style="border: solid 1px #dedede;" >
            <td class="px-2 py-1 font-bold">
              <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
              <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
            </td>
            <td class="px-2 py-1 text-center font-bold">
              <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
            </td>
            <td class="px-2 py-1 text-center">
              <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
              <span v-else>NIL</span>
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
            <td class="px-2 py-1 text-center">
              Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
              <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                    style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
            </td>
            <td class="px-2 py-1 text-center">
              <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
            </td>
            <td class="px-2 py-1 text-center">
              <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
            </td>
          </tr>
          </tbody>
        </table>

        <b-row class="mt-4">
          <b-col>
            <p class="h5">Share Holders</p>
          </b-col>
        </b-row>
        <table class="min-w-full leading-normal text-gray"
               style="border: solid 1px #dedede;" v-for="(person, idx) in shareholders" :key="idx">
          <thead>
          <tr class="bg-gray-pale">
            <th class="px-2 py-1 text-uppercase">Name</th>
            <th class="px-2 py-1 text-center float-start text-uppercase">Share Class</th>
            <th class="px-2 py-1 text-center text-uppercase">Number of Shares</th>
            <th class="px-2 py-1 text-center text-uppercase">Paid</th>
            <th class="px-2 py-1 text-center text-uppercase">Not Paid</th>
          </tr>
          </thead>
          <thead>
          <tr>
            <td class="px-2 py-1 font-bold text-blue text-uppercase" colspan="12">
              <span>{{person.name}}</span>

                <b-badge class="bg-red mx-2">{{person.ownerType}}</b-badge>

            </td>
          </tr>
          </thead>
          <tbody style="border: solid 1px #dedede;" v-for="(share, idx2) in person.shares" :key="idx2" >
          <tr >
            <td class="px-2 py-1"></td>
            <td class="px-2 py-1 text-uppercase font-bold">
              <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
              <span v-else>{{share.otherClassName}}</span>
            </td>
            <td class="px-2 py-1 text-center">
                <span v-if="share.subClasses.length === 0">
                  {{share.numberOfShares.toLocaleString()}}
                </span>
            </td>
            <td class="px-2 py-1 text-center">
                <span v-if="share.subClasses.length === 0">
                  {{share.amountPaid.toLocaleString()}}
                </span>
            </td>
            <td class="px-2 py-1 text-center">
                <span v-if="share.subClasses.length === 0">
                  {{share.amountUnPaid.toLocaleString()}}
                </span>
            </td>
          </tr>
          <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
            <td class="px-2 py-1"></td>
            <td class="px-2 py-1 text-capitalize">
              <span>Class {{subClass.nominalClass}}</span>
            </td>
            <td class="px-2 py-1 text-center">
                <span>
                  {{subClass.numberOfShares.toLocaleString()}}
                </span>
            </td>
            <td class="px-2 py-1 text-center">
                <span>
                  {{subClass.amountPaid.toLocaleString()}}
                </span>
            </td>
            <td class="px-2 py-1 text-center">
                <span>
                  {{subClass.amountUnPaid.toLocaleString()}}
                </span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CompanyShares",
  props:{
    brn:String
  },
  data(){
    return{
      classifications:[],
      shareholders:[],
      allottedShares:0
    }
  },
  methods:{
    ...mapActions(["fetchCompanyShareClassification", "fetchCompanyShareholding"])
  },
  mounted() {
    this.fetchCompanyShareClassification(this.brn)
    this.fetchCompanyShareholding(this.brn)
  },
  computed:{
    ...mapGetters(["getCompanyShareClassification", "getCompanyShareholding"])
  },
  watch:{
    getCompanyShareClassification(data){
      this.classifications = data.data.nominalShareClassifications
    },
    getCompanyShareholding(data){
      this.loading = false
      this.shareholders = data.data.content
      data.data.content.forEach(holder =>{
        holder.shares.forEach(share =>{
          this.allottedShares += parseInt(share.numberOfShares)
        })
      })
    }
  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>