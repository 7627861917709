<template>
  <b-row class="my-3">
    <b-col cols="8" class="mx-auto py-5 shadow rounded-md">
      <b-row>
        <b-col cols="6" class="mx-auto">
          <p class="text-center my-3 h5">
            We will automatically load the next Application in 00:<label v-if="timerCount < 10">0</label>{{ timerCount }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="mx-auto">
          <button class="w-full mx-auto bg-blue px-2 py-2 rounded-sm text-white hover:bg-blue-dark"
                  @click="getNext">Get Next Task <i class="fa fa-arrow-right ml-3"></i></button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TaskLoader",
  data() {
    return {
      countdownSeconds: 20,
      timerCount: 0,
      score:0,
      seconds: 1000,
      play:true
    }
  },
  mounted() {
    this.timerCount = this.countdownSeconds
  },
  methods:{
    getNext(){
      this.$emit("nextTask")
    },
    start(){
      this.timerCount = this.countdownSeconds
      //this.play = true
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if(this.play){
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--;
            }, this.seconds);
          }else{
            this.getNext()
          }
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  }
};
</script>

<style scoped>

</style>