<template>
  <div>
    <b-row v-if="task !== null">
      <b-col cols="12">
        <b-row v-if="task.data.reservation !== undefined" class="mx-auto my-2">
          <b-col cols="12 p-0">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Reservation Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1 font-bold text-gray">
                    <span class="">{{ task.data.reservation.reservationNo }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{ task.data.reservation.name }}</span>
                </td>
                <td class="px-2 py-1">
                  <span :class="statusColorCode(task.data.reservation.status)">{{statusDataInfo(task.data.reservation.status)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{ cleanEntityType(task.data.reservation.subType) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-4">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;" v-if="task.data.applicant !== null">
                <td class="px-2 py-1 text-capitalize">{{task.data.applicant.givenName}} {{task.data.applicant.surname}}</td>
                <td class="px-2 py-1">{{task.data.applicant.email}}</td>
                <td class="px-2 py-1">{{task.data.applicant.phoneNumber}}</td>
              </tr>
              <tr style="border: solid 1px #bdd7f1;" v-else>
                <td class="px-2 py-1">Not available</td>
                <td class="px-2 py-1">Not available</td>
                <td class="px-2 py-1">Not available</td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DispensationApplication",
  props:{
    task: {
      type: [Object, null],
      default: null,
    }
  },
  data(){
    return{
      hasComments: false,
      currentRoute: "form",
      selectedId:null,
      margin:"2px",
      hasContent:false,
    }
  },
  methods:{
    cleanEntityType(name){
      if (typeof name !== "string") {
        return null
      }
      let n = name.replaceAll("-", " ").replaceAll("_", " ")
      return n.charAt(0).toLowerCase() + n.slice(1)
    },
    cleanString(name){
      if (typeof name !== "string") {
        return null
      }
      let n = name.replaceAll("_", " ").replaceAll("_", " ")
      return n.charAt(0).toLowerCase() + n.slice(1)
    },
    statusDataInfo(status) {
      if (status.toUpperCase() === "APPROVED") {
        return "Approved"
      } else if (status.toUpperCase() === "RESERVED") {
        return "Reserved"
      } else if (status.toUpperCase() === "QUERIED") {
        return "Issues Raised"
      } else if (status.toUpperCase() === "ASSIGNED") {
        return "Being Processed"
      } else if (status.toUpperCase() === "PENDING") {
        return "Submitted"
      } else if (status.toUpperCase() === "APPEALED") {
        return "Appealed"
      }else if (status.toUpperCase() === "REJECTED") {
        return "Rejected"
      }else if (status.toUpperCase() === "DRAFT") {
        return "Draft"
      } else if (status.toUpperCase() === "AWAITING_PAYMENT_CONFIRMATION") {
        return "Confirming Payment"
      }else if (status.toUpperCase() === "PAYMENT_FAILED") {
        return "Payment Failed"
      }
    },
    statusColorCode(status) {
      if (status.toUpperCase() === "APPROVED" || status.toUpperCase() === "RESERVED") {
        return "text-green"
      } else if (status.toUpperCase() === "QUERIED") {
        return "text-orange"
      } else if (status.toUpperCase() === "ASSIGNED") {
        return "text-blue"
      } else if (status.toUpperCase() === "PENDING") {
        return "text-gray"
      } else if (status.toUpperCase() === "APPEALED" ) {
        return "text-red"
      }else if (status.toUpperCase() === "REJECTED") {
        return "text-red"
      }else if (status.toUpperCase() === "DRAFT") {
        return "text-gray"
      } else if (status.toUpperCase() === "AWAITING_PAYMENT_CONFIRMATION") {
        return "text-purple"
      }else if (status.toUpperCase() === "PAYMENT_FAILED") {
        return "text-red"
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>