<template>
  <div>
    <b-row v-if="task != null">
      <b-col cols="12" class="my-2">

          <b-row>
              <b-col>

                  <b-row v-if="task.brn">
                      <b-col cols="12" class="m-2">
                          <span class="font-bold text-blue h5">{{ task.brn }}</span><br/>
                          <span class="font-bold text-gray h3">{{ task.companyName }}  </span><br/>
                          <div class="flex mt-1">
                              <b-badge class="bg-blue text-capitalize">
                                 Entity Type
                              </b-badge>
                              <b-badge class="mx-1 bg-green text-capitalize">
                                  {{ cleanEntityType(task.entityType) }}
                              </b-badge>
                          </div>
                          <span class="italic text-gray text-xs font-bold" v-if="task.dateOfIncorporation">Registration Date: {{ dateFormat(task.dateOfIncorporation) }}

                </span><br/>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.payment">
                      <b-col cols="12">
                          <PRNViewer  :tracking-number="task.trackingNumber"/>
                      </b-col>
                  </b-row>



<!--                  <b-row class="mt-2" v-if="task.finalReport">-->
<!--                      <b-col cols="12" :class="[task.finalReport.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >-->
<!--                          <label class="text-blue fw-bold text-lg">Final Report</label>-->
<!--                          <label class="float-end" v-if="task.finalReport.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>-->
<!--                          <table class="min-w-full leading-normal mt-1 text-gray"-->
<!--                                 style="border: solid 1px #dedede;">-->
<!--                              <thead>-->
<!--                              <tr :class="[task.finalReport.status === 'APPROVED' ? 'bg-green-pale' : 'bg-gray-pale']">-->
<!--                                  <td colspan="12" class="px-2 py-2 font-bold">Inspection Venue</td>-->
<!--                              </tr>-->
<!--                              </thead>-->
<!--                              <tbody>-->
<!--                              <b-row class="mx-1 py-1">-->
<!--                                  <b-col>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Region"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.physicalAddress.region.name"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="District"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus text-capitalize"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.physicalAddress.district.name.toLowerCase()"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Sub County"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus text-capitalize"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.physicalAddress.subcounty.name"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->
<!--                                  </b-col>-->
<!--                                  <b-col>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Parish"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.physicalAddress.parish.name"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Village"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus text-capitalize"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.physicalAddress.village.name"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Street"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus text-capitalize"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.streetAddress"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->
<!--                                  </b-col>-->
<!--                                  <b-col>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Plot"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus text-capitalize"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.postalAddress"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->
<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Postal Address"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus text-capitalize"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.postalAddress"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                          <PostalAddressValidator v-if="task.finalReport.postalAddress" :box-number="task.finalReport.postalAddress"/>-->
<!--                                      </b-form-group>-->

<!--                                      <b-form-group-->
<!--                                              class="text-sm my-1 flex"-->
<!--                                              id="input-group-1"-->
<!--                                              label="Inspection time"-->
<!--                                              label-for="input-1">-->
<!--                                          <b-form-input-->
<!--                                                  id="companyName"-->
<!--                                                  class="form-text text-sm noFocus text-capitalize"-->
<!--                                                  type="text"-->
<!--                                                  :disabled="true"-->
<!--                                                  :value="task.finalReport.inspectionTime"-->
<!--                                                  required>-->
<!--                                          </b-form-input>-->
<!--                                      </b-form-group>-->

<!--                                  </b-col>-->
<!--                              </b-row>-->
<!--                              </tbody>-->
<!--                          </table>-->
<!--                      </b-col>-->
<!--                  </b-row>-->
<!--                  <b-row v-if="task.finalMeeting" >-->
<!--                      <b-col cols="12" :class="[task.finalMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">-->
<!--                          <label class="text-blue fw-bold text-lg">Final Meeting</label>-->
<!--                          <label class="float-end" v-if="task.finalMeeting.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>-->
<!--                          <b-row>-->


<!--                              <b-col>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Meeting Type"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.meetingType"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Meeting Date"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus text-capitalize"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.meetingDate"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Meeting Time"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus text-capitalize"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.meetingTime"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                              </b-col>-->

<!--                              <b-col>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Meeting Venue"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.meetingVenue"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Proxy Venue"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus text-capitalize"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.proxyVenue"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Proxy Deadline Date"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus text-capitalize"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.proxyDeadlineDate"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                              </b-col>-->

<!--                              <b-col>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Proxy Deadline Time"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.proxyDeadlineTime"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Meeting Caller"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus text-capitalize"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.meetingCallerName"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                                  <b-form-group-->
<!--                                          class="text-sm my-1"-->
<!--                                          id="input-group-1"-->
<!--                                          label="Position"-->
<!--                                          label-for="input-1">-->
<!--                                      <b-form-input-->
<!--                                              id="companyName"-->
<!--                                              class="form-text text-sm noFocus text-capitalize"-->
<!--                                              type="text"-->
<!--                                              :disabled="true"-->
<!--                                              :value="task.finalMeeting.meetingCallerPosition"-->
<!--                                              required>-->
<!--                                      </b-form-input>-->
<!--                                  </b-form-group>-->
<!--                              </b-col>-->


<!--                          </b-row>-->

<!--                          <b-row>-->
<!--                              <b-col>-->
<!--                                  <label>Meeting Attendees</label>-->
<!--                                  <table style="width: 100%">-->
<!--                                      <thead>-->
<!--                                      <tr class="bg-gray-pale border-1 text-black">-->
<!--                                          <td>No.</td>-->
<!--                                          <td>Name</td>-->
<!--                                          <td>Position</td>-->
<!--                                          <td>Phone</td>-->
<!--                                          <td>Email</td>-->
<!--                                      </tr>-->
<!--                                      </thead>-->

<!--                                      <tbody class="border-1">-->
<!--                                      <tr v-for="(atd,idx) in task.finalMeeting.attendees" :key="idx">-->
<!--                                          <td>{{idx+1}}</td>-->
<!--                                          <td>{{ atd.entityBrn  ? atd.name+' on behalf of ('+atd.entityName+')' : atd.name-->

<!--                                              }}</td>-->
<!--                                          <td>{{atd.position}}</td>-->
<!--                                          <td>{{atd.phone}}</td>-->
<!--                                          <td>{{atd.email}}</td>-->
<!--                                      </tr>-->
<!--                                      </tbody>-->
<!--                                  </table>-->
<!--                              </b-col>-->
<!--                          </b-row>-->
<!--                      </b-col>-->
<!--                  </b-row>-->
                  <b-row v-if="task.creditorMeeting"  >

                      <b-col cols="12" :class="[task.creditorMeeting.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Creditors Meeting</label>
                          <label class="float-end" v-if="task.creditorMeeting.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>


                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Type"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingType"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Date"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingDate"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Time"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingTime"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>

                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Venue"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingVenue"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Venue"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.proxyVenue"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Deadline Date"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.proxyDeadlineDate"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>

                              <b-col>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Proxy Deadline Time"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.proxyDeadlineTime"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Meeting Caller"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingCallerName"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                          class="text-sm my-1"
                                          id="input-group-1"
                                          label="Position"
                                          label-for="input-1">
                                      <b-form-input
                                              id="companyName"
                                              class="form-text text-sm noFocus text-capitalize"
                                              type="text"
                                              :disabled="true"
                                              :value="task.creditorMeeting.meetingCallerPosition"
                                              required>
                                      </b-form-input>
                                  </b-form-group>
                              </b-col>


                          </b-row>

                          <b-row>
                              <b-col>
                                  <label>Meeting Attendees</label>
                                  <table style="width: 100%">
                                      <thead>
                                      <tr class="bg-gray-pale border-1 text-black">
                                          <td>No.</td>
                                          <td>Name</td>
                                          <td>Position</td>
                                          <td>Phone</td>
                                          <td>Email</td>
                                      </tr>
                                      </thead>

                                      <tbody class="border-1">
                                      <tr v-for="(atd,idx) in task.creditorMeeting.attendees" :key="idx">
                                          <td>{{idx+1}}</td>
                                          <td>{{ atd.entityBrn  ? atd.name+' on behalf of ('+atd.entityName+')' : atd.name

                                              }}</td>
                                          <td>{{atd.position}}</td>
                                          <td>{{atd.phone}}</td>
                                          <td>{{atd.email}}</td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>


                  </b-row>
                  <b-row v-for="(practitioner,idx) in task.appointments" :key="idx">
                      <b-col cols="12" :class="[practitioner.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Previously Appointed Practitioners </label>
                          <label class="float-end" v-if="practitioner.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>
                                  <p> Name: <b>{{ practitioner.practitioner.surName }} {{ practitioner.practitioner.givenName }}</b></p>
                                  <p> UPN: <b>{{ practitioner.practitioner.no }}</b></p>
                                  <p> Email: <b>{{ practitioner.practitioner.emailAddress }}</b></p>
                              </b-col>
                              <b-col>
                                  <p> Phone: <b>{{ practitioner.practitioner.phoneNumber }}</b></p>
                                  <p> Registration Date: <b>{{ dateFormat(practitioner.practitioner.createdAt) }}</b></p>
                                  <p> Appointment Date: <b>{{ dateFormat(practitioner.practitioner.appointmentDate) }}</b></p>

                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
<!--                  <b-row v-if="task.lastInterimReport">
                      <b-col cols="12" :class="[task.lastInterimReport.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >
                          <label class="text-blue fw-bold text-lg">Latest Interim Report</label>
                          <label class="float-end" v-if="task.lastInterimReport.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <table :class=" task.lastInterimReport.status === 'APPROVED' ? 'min-w-full leading-normal mt-1 text-gray' : 'min-w-full leading-normal mt-1 text-gray' "
                                 style="border: solid 1px #dedede;">
                              <thead>
                              <tr
                                      :class="[task.lastInterimReport.status === 'APPROVED' ? 'bg-green-pale' : 'bg-gray-pale']"
                              >
                                  <td colspan="12" class="px-2 py-2 font-bold">Inspection Venue</td>
                              </tr>
                              </thead>
                              <tbody>
                              <b-row class="mx-1 py-1">
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Region"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.region.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="District"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.district.name.toLowerCase()"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Sub County"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.subcounty.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Parish"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.parish.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Village"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.physicalAddress.village.name"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Street"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.streetAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                  </b-col>
                                  <b-col>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Plot"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>
                                      <b-form-group
                                              class="text-sm my-1"
                                              id="input-group-1"
                                              label="Postal Address"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.postalAddress"
                                                  required>
                                          </b-form-input>
                                          <PostalAddressValidator v-if="task.lastInterimReport.postalAddress" :box-number="task.lastInterimReport.postalAddress"/>
                                      </b-form-group>

                                      <b-form-group
                                              class="text-sm my-1 flex"
                                              id="input-group-1"
                                              label="Inspection time"
                                              label-for="input-1">
                                          <b-form-input
                                                  id="companyName"
                                                  class="form-text text-sm noFocus text-capitalize"
                                                  type="text"
                                                  :disabled="true"
                                                  :value="task.lastInterimReport.inspectionTime"
                                                  required>
                                          </b-form-input>
                                      </b-form-group>


                                  </b-col>
                              </b-row>
                              </tbody>
                          </table>
                      </b-col>
                  </b-row>-->
                  <b-row v-if="task.boardResolution">
                      <b-col cols="12" :class="[task.boardResolution.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Resolution</label>
                          <label class="float-end" v-if="task.boardResolution.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>

                                  <p> Resolution Type: <b>{{ task.boardResolution.resolutionType }}</b></p>
                                  <p> Place Of Meeting: <b>{{ task.boardResolution.placeOfMeeting }}</b></p>
                                  <p>Date Of Meeting:
                                      <b>{{ dateFormat(task.boardResolution.dateOfMeeting) }}</b></p>


                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                  <label>
                                      Signatories
                                      <b-badge class="bg-blue text-capitalize">
                                          {{ task.boardResolution.resolutionSignatories.length }}
                                      </b-badge>
                                  </label>
                                  <div>
                                      <table  style="width: 90%">
                                          <thead>
                                          <th>#</th>
                                          <th>
                                              Name
                                          </th>
                                          </thead>
                                          <tbody>

                                            <tr v-for="(signatory, idx) in task.boardResolution.resolutionSignatories"
                                                :key="idx">
                                                <td>
                                                    {{idx+1}}
                                                </td>
                                                <td>
                                                    {{ signatory.signatoryName }}
                                                </td>
</tr>
                                            </tbody>
                                      </table>
                                  </div>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.declarationOfSolvency">
                      <b-col cols="12" :class="[task.declarationOfSolvency.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Declaration of solvency</label>
                          <label class="float-end" v-if="task.declarationOfSolvency.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>


                                  <p>Date declared :
                                      <b>{{ dateFormat(task.declarationOfSolvency.dateOfDeclaration) }}</b></p>


                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                  <label>
                                      Signatories
                                      <b-badge class="bg-blue text-capitalize">
                                          {{ task.declarationOfSolvency.signatories.length }}
                                      </b-badge>
                                  </label>
                                  <div>
                                      <table  style="width: 90%">
                                          <thead>
                                          <th>#</th>
                                          <th>
                                              Name
                                          </th>
                                          </thead>
                                          <tbody>

                                          <tr v-for="(signatory, idx) in task.declarationOfSolvency.signatories"
                                              :key="idx">
                                              <td>
                                                  {{idx+1}}
                                              </td>
                                              <td>
                                                  {{ signatory.signatoryName }}
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.form34">
                      <b-col cols="12" :class="[task.form34.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">APPOINTMENT OF A GENERAL PROXY</label>
                          <label class="float-end" v-if="task.form34.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col>


                                  <p>Date Of Cessation :
                                      <b>{{ dateFormat(task.form34.dateOfCessation) }}</b></p>


                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                  <label>
                                      Signatory
                                  </label>
                                  <div>
                                      <table  style="width: 90%">
                                          <thead>
                                          <th>#</th>
                                          <th>
                                              Name
                                          </th>
                                          </thead>
                                          <tbody>

                                          <tr>
                                              <td>
                                                  1
                                              </td>
                                              <td>
                                                  {{ task.form34.signatory.signatoryName }}
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>


                  <b-row v-if="task.courtOrder">
                      <b-col cols="12" :class="[task.courtOrder.status ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Court Order details</label>
                          <label class="float-end" v-if="task.courtOrder.status ==='APPROVED'"> <i class="fa fa-lock"></i> </label>
                          <b-row>
                              <b-col cols="2">

                                  Date: <b-form-input disabled v-model="task.courtOrder.courtDate"/>
                              </b-col>
                              <b-col cols="2">
                                  Court Venue: <b-form-input disabled v-model="task.courtOrder.venue"/>
                              </b-col>
                              <b-col>
                                  Details:
                                  <b-textarea disabled v-model="task.courtOrder.description"></b-textarea>



                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                                    <b-row v-if="task.form20.loansAndAdvancesVtoA.length > 0" >
                                        <b-col cols="12" :class="[task.form20.loansAndAdvancesVtoA.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                            <b-row>
                                                <b-col>
                                                    <label class="text-blue fw-bold text-lg">Loans and Advances Schedule  V to A</label>
                                                    <table class="min-w-full leading-normal">
                                                        <thead>
                                                        <tr>
                                                            <th class="px-2 py-1">#</th>
                                                            <th class="px-2 py-1 text-center">Number in share register</th>
                                                            <th class="px-2 py-1 text-center">Name address of shareholders</th>
                                                            <th class="px-2 py-1 text-center">No of shares</th>
                                                            <th class="px-2 py-1 text-center">Amount of call per share capital</th>
                                                            <th class="px-2 py-1 text-center">Total amount due</th>
                                                            <th class="px-2 py-1 text-center">Estimated return</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.loansAndAdvancesVtoA" :key="idx">
                                                            <td class="px-2 py-1">
                                                                {{idx+1}}.
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                            <span>
                                              {{Number(affair.noInShareRegister).toLocaleString()}}
                                            </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                            <span>
                                              {{affair.shareHolderName}}
                                              {{affair.shareHolderAddress}}
                                            </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                            <span>
                                              {{affair.noOfShares}}
                                            </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                            <span>
                                              {{affair.amountPerShare}}
                                            </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                            <span>
                                              {{affair.totalAmountDue}}
                                            </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                            <span>
                                              {{affair.estimatedReturn}}
                                            </span>
                                                            </td>

                                                        </tr>
                                                        </tbody>

                                                    </table>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="task.form20.assetsSecured.length > 0">
                                        <b-col cols="12" :class="[task.form20.assetsSecured.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                            <b-row>
                                                <b-col>
                                                    <table class="min-w-full leading-normal">
                                                        <thead>
                                                        <tr>
                                                            <th class="px-2 py-1">#</th>
                                                            <th class="px-2 py-1">Asset</th>
                                                            <th class="px-2 py-1 text-center">Nature of security</th>
                                                            <th class="px-2 py-1 text-center">Date granted</th>
                                                            <th class="px-2 py-1 text-center">Name of creditor</th>
                                                            <th class="px-2 py-1 text-center">Address and Occupation</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.assetsSecured" :key="idx">
                                                            <td class="px-2 py-1">
                                                                {{idx+1}}.
                                                            </td>
                                                            <td class="px-2 py-1">
                                                                {{affair.particularsOfAsset}}
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.natureOfSecurity}}
                                                  </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{dateFormat(affair.dateGranted)}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.creditorName}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.address}} &nbsp; {{affair.occupation}}
                                                  </span>
                                                            </td>
                                                        </tr>
                                                        </tbody>

                                                    </table>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="task.form20.billOfExchange.length > 0">
                                        <b-col cols="12" :class="[task.form20.billOfExchange.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                            <label class="text-blue fw-bold text-lg">Bills of exchange , promissory notes , etc available as assets </label>
                                            <b-row>
                                                <b-col>
                                                    <table class="min-w-full leading-normal">
                                                        <thead>
                                                        <tr>
                                                            <th class="px-2 py-1">#</th>
                                                            <th class="px-2 py-1 text-center">Name of bill acceptor</th>
                                                            <th class="px-2 py-1 text-center">Amount of Bill or note</th>
                                                            <th class="px-2 py-1 text-center">Date when due</th>
                                                            <th class="px-2 py-1 text-center">Estimated return</th>
                                                            <th class="px-2 py-1 text-center">Particulars of any property held as security for payment of bill or note</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.billOfExchange" :key="idx">
                                                            <td class="px-2 py-1">
                                                                {{idx+1}}.
                                                            </td>
                                                            <td class="px-2 py-1">
                                                                {{affair.nameOfAcceptor}}
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.amount}}
                                                  </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{dateFormat(affair.dateDue)}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.estimatedReturn}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.particulars}}
                                                  </span>
                                                            </td>
                                                        </tr>
                                                        </tbody>

                                                    </table>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="task.form20.billsPayable.length > 0">
                                        <b-col cols="12" :class="[task.form20.billsPayable.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                            <label class="text-blue fw-bold text-lg">Unsecured creditors - Bills payable ,promissory notes , etc </label>
                                            <b-row>
                                                <b-col>
                                                    <table class="min-w-full leading-normal">
                                                        <thead>
                                                        <tr>
                                                            <th class="px-2 py-1">#</th>
                                                            <th class="px-2 py-1 text-center">Name and address of acceptor of bill or note</th>
                                                            <th class="px-2 py-1 text-center">Name and address of holder</th>
                                                            <th class="px-2 py-1 text-center">Date when due</th>
                                                            <th class="px-2 py-1 text-center">Amount of claim</th>
                                                            <th class="px-2 py-1 text-center">Particulars of bill or note</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.billsPayable" :key="idx">
                                                            <td class="px-2 py-1">
                                                                {{idx+1}}.
                                                            </td>
                                                            <td class="px-2 py-1">
                                                                {{affair.nameOfAcceptor}} &nbsp; {{affair.addressOfAcceptor}}
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.nameOfHolder}} &nbsp; {{affair.addressOfHolder}}
                                                  </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{dateFormat(affair.dateDue)}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.amount}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.particulars}}
                                                  </span>
                                                            </td>
                                                        </tr>
                                                        </tbody>

                                                    </table>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="task.form20.preferentialCreditors.length > 0">
                                        <b-col cols="12" :class="[task.form20.preferentialCreditors.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                            <label class="text-blue fw-bold text-lg">Preferential creditors for salaries, wages and otherwise </label>
                                            <b-row>
                                                <b-col>
                                                    <table class="min-w-full leading-normal">
                                                        <thead>
                                                        <tr>
                                                            <th class="px-2 py-1">#</th>
                                                            <th class="px-2 py-1 text-center">Name of creditor</th>
                                                            <th class="px-2 py-1 text-center">Address</th>
                                                            <th class="px-2 py-1 text-center">Nature of claim</th>
                                                            <th class="px-2 py-1 text-center">Total amount claim</th>
                                                            <th class="px-2 py-1 text-center">Amount ranking as preferential</th>
                                                            <th class="px-2 py-1 text-center">Balance not preferential carried to list `E`</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.preferentialCreditors" :key="idx">
                                                            <td class="px-2 py-1">
                                                                {{idx+1}}.
                                                            </td>
                                                            <td class="px-2 py-1">
                                                                {{affair.creditorName}}
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.address}}
                                                  </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.natureOfClaim}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.amountOfClaim}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.particulars}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.amountRankingAsPreferential}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.balanceNotPreferential}}
                                                  </span>
                                                            </td>
                                                        </tr>
                                                        </tbody>

                                                    </table>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="task.form20.debenturesSecured.length > 0">
                                        <b-col cols="12" :class="[task.form20.debenturesSecured.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                            <label class="text-blue fw-bold text-lg">List of holders of debentures secured by a floating charge </label>
                                            <b-row>
                                                <b-col>
                                                    <table class="min-w-full leading-normal">
                                                        <thead>
                                                        <tr>
                                                            <th class="px-2 py-1">#</th>
                                                            <th class="px-2 py-1 text-center">Name and Address of holder </th>
                                                            <th class="px-2 py-1 text-center">Amount</th>
                                                            <th class="px-2 py-1 text-center">Description of assets over which security extends</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.debenturesSecured" :key="idx">
                                                            <td class="px-2 py-1">
                                                                {{idx+1}}.
                                                            </td>
                                                            <td class="px-2 py-1">
                                                                {{affair.holderName}} &nbsp; {{affair.address}}
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.address}}
                                                  </span>
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.amount}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.assetDescription}}
                                                  </span>
                                                            </td>

                                                        </tr>
                                                        </tbody>

                                                    </table>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                    <b-row v-if="task.form20.tradeAccounts.length > 0">
                                        <b-col cols="12" :class="[task.form20.tradeAccounts.length > 0 ==='APPROVED' ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                                            <label class="text-blue fw-bold text-lg">Unsecured creditors - trade accounts </label>
                                            <b-row>
                                                <b-col>
                                                    <table class="min-w-full leading-normal">
                                                        <thead>
                                                        <tr>
                                                            <th class="px-2 py-1">#</th>
                                                            <th class="px-2 py-1 text-center">Name and Address of creditor </th>
                                                            <th class="px-2 py-1 text-center">Amount of the debt</th>
                                                            <th class="px-2 py-1 text-center">Additional information</th>

                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.tradeAccounts" :key="idx">
                                                            <td class="px-2 py-1">
                                                                {{idx+1}}.
                                                            </td>
                                                            <td class="px-2 py-1">
                                                                {{affair.creditorName}} &nbsp; {{affair.address}}
                                                            </td>
                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.amount}}
                                                  </span>
                                                            </td>

                                                            <td class="px-2 py-1 text-center">
                                                  <span>
                                                    {{affair.description}}
                                                  </span>
                                                            </td>

                                                        </tr>
                                                        </tbody>

                                                    </table>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                  <b-row v-if="task.form20.contingentLiabilities.length > 0">
                      <b-col cols="12" :class="[task.form20.contingentLiabilities.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Unsecured creditors - contingent liabilities </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name and Address of creditor </th>
                                          <th class="px-2 py-1 text-center">Nature of liability</th>
                                          <th class="px-2 py-1 text-center">Amount of claim</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.contingentLiabilities" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.creditorName}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.description}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.form20.contingentLiabilities.length > 0">
                      <b-col cols="12" :class="[task.form20.contingentLiabilities.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]">
                          <label class="text-blue fw-bold text-lg">Unsecured creditors - contingent liabilities </label>
                          <b-row>
                              <b-col>
                                  <table class="min-w-full leading-normal">
                                      <thead>
                                      <tr>
                                          <th class="px-2 py-1">#</th>
                                          <th class="px-2 py-1 text-center">Name and Address of creditor </th>
                                          <th class="px-2 py-1 text-center">Nature of liability</th>
                                          <th class="px-2 py-1 text-center">Amount of claim</th>

                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;" v-for="(affair, idx) in task.form20.contingentLiabilities" :key="idx">
                                          <td class="px-2 py-1">
                                              {{idx+1}}.
                                          </td>
                                          <td class="px-2 py-1">
                                              {{affair.creditorName}}
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.description}}
                                </span>
                                          </td>

                                          <td class="px-2 py-1 text-center">
                                <span>
                                  {{affair.amount}}
                                </span>
                                          </td>

                                      </tr>
                                      </tbody>

                                  </table>
                              </b-col>
                          </b-row>
                      </b-col>
                  </b-row>
                  <b-row v-if="task.reasons.length > 0">
                      <b-col cols="12" :class="[task.reasons.length > 0 ? 'bg-inertgrey mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3':'mx-auto border border-header_grey shadow-sm rounded-sm m-2 py-3' ]" >
                          <b-row v-if="task.reasons.length > 0">
                              <label class="text-blue fw-bold text-lg">Reasons for receivership</label>

                              <b-col cols="12"
                                     v-for="(reason, idx) in task.reasons" :key="idx">
                                  <b-row>
                                      <b-col>
                                          <b>{{idx+1}}</b>
                                      </b-col>
                                      <b-col cols="11">
                                          <b-textarea disabled v-model="reason.reason"></b-textarea>
                                      </b-col>
                                  </b-row>
                              </b-col>
                          </b-row>





                      </b-col>
                  </b-row>


              </b-col>
          </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>

import PRNViewer from "@/views/home/commons/PRNViewer.vue";

export default {
  name: "ForeignCessationForms",
  components: {
      PRNViewer,},
  props:{
    task:Object
  },
  data(){
    return{
    }
  },
  mounted() {

  },
  methods: {
      coloredStatus(status){
          if(status === 'APPROVED'){
              return 'texT-green'
          }else if(status === 'REJECTED'){
              return 'text-red'
          }else if(status === 'FILING'){
              return 'text-gray'
          }
      },

  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>