<template>
  <b-row class="my-3">
    <b-col>
      <b-row>
        <b-col>
          <b-modal ref="partnershipDeed" size="xl" title="Partnership Deed" hide-footer>
            <EDMSDocument :file="getEDMSFile"/>
          </b-modal>
        </b-col>
      </b-row>
      <b-row class="mb-2" v-if="amendment.embeddedChanges">
        <b-col>
          <b-badge class="bg-green mx-1">Incoming Amendments <i class="fa fa-arrow-right text-white"></i></b-badge>
          <b-badge class="bg-blue mx-1" v-for="(file,idx) in amendment.embeddedChanges" :key="idx">
            {{filingName(file.type)}}
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2 font-bold">Amendment Details</td>
            </tr>
            </thead>
            <tbody>
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 text-capitalize font-bold">Registration Number</td>
              <td class="px-2 py-1 text-capitalize">{{amendment.brn}}</td>
            </tr>
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 text-capitalize font-bold">Business Name</td>
              <td class="px-2 py-1 text-capitalize">{{amendment.businessName}}</td>
            </tr>
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 text-capitalize font-bold">Sub Type</td>
              <td class="px-2 py-1 text-capitalize">{{cleanEntityType(amendment.subType)}}</td>
            </tr>
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 text-capitalize font-bold">Filing Date</td>
              <td class="px-2 py-1 text-capitalize">{{dateTimeFormat(amendment.createdAt)}}</td>
            </tr>
            <tr style="border: solid 1px #dedede;" v-if="amendment.subType.includes('partnership')">
              <td class="px-2 py-1 text-capitalize font-bold">Partnership Deed</td>
              <td class="px-2 py-2 text-capitalize">
                <span v-if="amendment.edmsId"
                  class="cursor-pointer px-2 py-1 rounded-md bg-blue underline text-white hover:bg-blue-dark"
                      @click="viewPartnershipDeed(amendment.edmsId)">View Partnership Deed</span>
                <span v-else class="font-bold">Not Available</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row v-for="(change, idx) in amendment.embeddedChanges" :key="idx">
        <b-col>
          <BusinessNameChange :data="change.data" v-if="change.type === 'CHANGE_OF_NAME'"/>
          <ChangeInParticulars :data="change.data" :brn="amendment.brn" v-if="change.type === 'CHANGE_OF_PARTICULARS'"/>
          <DeathOfPartners :data="change.data" :brn="amendment.brn" v-if="change.type === 'NOTICE_OF_DEATH_OF_PARTNER'"/>
          <RetirementOfPartners :data="change.data" :brn="amendment.brn" v-if="change.type === 'NOTICE_OF_RETIREMENT_OF_PARTNER'"/>
          <MinorRepudiating :data="change.data" :brn="amendment.brn" v-if="change.type === 'NOTICE_OF_MINOR'"/>
          <NoticeOfCessation :data="change.data" v-if="change.type === 'NOTICE_OF_CESSATION'"/>
          <NoticeOfDissolutionOfPartnership :data="change.data" v-if="change.type === 'NOTICE_OF_DISSOLUTION'"/>
          <NoticeOfVariationInPartnershipTerms :data="change.data" v-if="change.type === 'NOTICE_OF_VARIATION_IN_TERMS_OF_PARTNERSHIP'"/>
          <StatementOfIntentOfConversionToPartnership :data="change.data" v-if="change.type === 'STATEMENT_OF_CONVERSION_PARTNERSHIP'"/>
          <StatementOfIntentOfConversionToLLP :data="change.data" v-if="change.type === 'STATEMENT_OF_CONVERSION_LLP'"/>
          <AmendmentsChangeBeneficialOwners v-if="change.type === 'NOTICE_OF_CHANGE_IN_BENEFICIAL_OWNERSHIP'" :data="{ ...change.data.form,changes:change.data.changes,}" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import BusinessNameChange from "@/views/home/services/amendments/task/components/BusinessNameChange";
import DeathOfPartners from "@/views/home/services/amendments/task/components/DeathOfPartners";
import RetirementOfPartners from "@/views/home/services/amendments/task/components/RetirementOfPartners";
import MinorRepudiating from "@/views/home/services/amendments/task/components/MinorRepudiating";
import NoticeOfCessation from "@/views/home/services/amendments/task/components/NoticeOfCessation";
import NoticeOfDissolutionOfPartnership
  from "@/views/home/services/amendments/task/components/NoticeOfDissolutionOfPartnership";
import NoticeOfVariationInPartnershipTerms
  from "@/views/home/services/amendments/task/components/NoticeOfVariationInPartnershipTerms";
import StatementOfIntentOfConversionToPartnership
  from "@/views/home/services/amendments/task/components/StatementOfIntentOfConversionToPartnership";
import StatementOfIntentOfConversionToLLP
  from "@/views/home/services/amendments/task/components/StatementOfIntentOfConversionToLLP";
import ChangeInParticulars from "@/views/home/services/amendments/task/components/ChangeInParticulars";
import moment from "moment/moment";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument";
import { mapActions, mapGetters } from "vuex";

import AmendmentsChangeBeneficialOwners
    from "@/views/home/services/amendments/task/components/AmendmentsChangeBeneficialOwners.vue";
export default {
  name: "AmendmentDetails",
  components: {
      AmendmentsChangeBeneficialOwners, EDMSDocument, ChangeInParticulars, StatementOfIntentOfConversionToLLP, StatementOfIntentOfConversionToPartnership, NoticeOfVariationInPartnershipTerms, NoticeOfDissolutionOfPartnership, NoticeOfCessation, MinorRepudiating, RetirementOfPartners, DeathOfPartners, BusinessNameChange},
  props:{
    amendment:Object
  },
  methods:{
    ...mapActions(["fetchBusinessNameEDMSFile"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    viewPartnershipDeed(edmsId){
      if(edmsId != null && edmsId !== ""){
        this.$refs.partnershipDeed.show()
        let data = {
          documentHandle: edmsId,
          overlay: ""
        }
        this.fetchBusinessNameEDMSFile(data)
      }else{
        this.errorToast("Deed Error", "No Partnership Deed associated with this Business Name")
      }
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.toLowerCase()
    },
    filingName(name){
      return "#"+name.replaceAll("_","").toLowerCase();
    }
  },
  computed:{
    ...mapGetters(["getEDMSFile"])
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>