<template>
  <b-row class="py-2">
    <b-col>
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Conversion
          </b-badge>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-row v-if="type === 'FILE_FOR_CONVERSION_FROM_PRIVATE_TO_SMC'">
            <b-col>
              <b-row v-if="data.FORM_1" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Form 1</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td class="t-body">Proposed Name</td>
                      <td class="t-body font-bold">{{data.FORM_1.data.proposedName}}</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Single Member</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons :persons="[data.FORM_1.data.singleMember]" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Nominee Director</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons :persons="[data.FORM_1.data.nomineeDirector]" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Alternative Nominee Director</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons :persons="[data.FORM_1.data.alternativeNomineeDirector]" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>

              <b-row v-if="data.VALUATION_OF_SHARES" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Valuation of Shares</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Total Amount Paid In Transferred</td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.totalAmountPaidInTransferred.toLocaleString() }}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.totalAmountPaidInTransferredInWords }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Final Total Transferred</td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.finalTotalTransferred.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.finalTotalTransferredInWords }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Valuation</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1 text-capitalize">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-capitalize">Shares</th>
                      <th class="px-2 py-1 text-center text-capitalize">Value(UGX)</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.VALUATION_OF_SHARES.data.valuation.valuation" :key="idx">
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center font-bold">
                        <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>

              <b-row v-if="data.MULTI_TRANSFER_OF_SHARES" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Transfer of Shares (Multiple)</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Total Amount Paid In Transfer</td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.totalAmountPaidInTransfer.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.totalAmountPaidInTransferredInWords }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Final Total Transferred</td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.finalTotalTransferred.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.finalTotalTransferredInWords }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Transferee Final Total Shares</td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.finalTotalTransferred.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">

                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Valuation Report</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1 text-capitalize">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-capitalize">Shares</th>
                      <th class="px-2 py-1 text-center text-capitalize">Value(UGX)</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.MULTI_TRANSFER_OF_SHARES.data.valuationReport.valuation.valuation" :key="idx">
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center font-bold">
                        <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Transfers</td>
                    </tr>
                    </thead>
                    <tbody>
                    <b-row v-for="(transfer, idx) in data.MULTI_TRANSFER_OF_SHARES.data.transfers" :key="idx" class="border-b border-gray py-3">
                      <b-col cols="11" class="mx-auto">
                        <b-row>
                          <b-col>
                            <p class="text-sm">Shares Transferred: <b-badge class="bg-blue"> <i class="fa fa-reply mr-1"></i>{{transfer.finalTotalTransferred.toLocaleString()}}</b-badge></p>
                          </b-col>
                          <b-col>
                            <p class="text-sm">Transferor Shares: <b-badge class="bg-red"> <i class="fa fa-arrow-down mr-1"></i>{{transfer.transferorFinalTotalShares.toLocaleString()}}</b-badge></p>
                          </b-col>
                          <b-col>
                            <p class="text-sm">Transferee Shares: <b-badge class="bg-green"> <i class="fa fa-arrow-up mr-1"></i>{{transfer.transfereeFinalTotalShares.toLocaleString()}}</b-badge></p>
                          </b-col>
                        </b-row>
                        <b-row class="mt-4">
                          <b-col>
                            <b-col>
                              <table class="min-w-full leading-normal text-gray"
                                     style="border: solid 1px #dedede;" >
                                <thead>
                                <tr class="bg-gray-pale">
                                  <td colspan="12" class="px-2 py-2 font-bold">Shares Transfered Breakdown</td>
                                </tr>
                                </thead>
                                <thead>
                                <tr>
                                  <th class="px-2 py-1">Share Class</th>
                                  <th class="px-2 py-1 text-center">Shares</th>
                                  <th class="px-2 py-1 text-center">Paid</th>
                                </tr>
                                </thead>
                                <tbody v-for="(share, idx) in transfer.sharesTransferred " :key="idx">
                                <tr style="border: solid 1px #dedede;">
                                  <td class="px-2 py-1 text-uppercase font-bold">
                                    <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                                    <span v-else>{{share.otherClassName}}</span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.numberOfShares.toLocaleString()}}
                                  </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.amountPaid.toLocaleString()}}
                                  </span>
                                  </td>
                                </tr>
                                <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                                  <td class="px-2 py-1 text-capitalize">
                                    <span>Class {{subClass.nominalClass}}</span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.numberOfShares.toLocaleString()}}
                                  </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.amountPaid.toLocaleString()}}
                                  </span>
                                  </td>
                                </tr>
                                </tbody>

                              </table>
                            </b-col>
                          </b-col>
                        </b-row>
                        <b-row class="mt-4 mb-2">
                          <b-col>
                            <p class="text-red text-sm">From: <b>{{transfer.transferor.name}}</b> <b-badge class="bg-red font-monospace mx-2">final</b-badge></p>
                          </b-col>
                          <b-col>
                            <p class="text-green text-sm">To: <b>{{transfer.transferee.name}}</b><b-badge class="bg-green font-monospace mx-2">final</b-badge></p>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <table class="min-w-full leading-normal text-red"
                                   style="border: solid 1px #dedede;" >
                              <thead>
                              <tr class="bg-gray-pale">
                                <th class="px-2 py-1">Share Class</th>
                                <th class="px-2 py-1 text-center">Shares</th>
                                <th class="px-2 py-1 text-center">Paid</th>
                              </tr>
                              </thead>
                              <tbody v-for="(share, idx) in transfer.transferorFinalShareholding" :key="idx">
                              <tr style="border: solid 1px #dedede;">
                                <td class="px-2 py-1 text-uppercase font-bold">
                                  <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                                  <span v-else>{{share.otherClassName}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                                <td class="px-2 py-1 text-capitalize">
                                  <span>Class {{subClass.nominalClass}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              </tbody>

                            </table>
                          </b-col>
                          <b-col>
                            <table class="min-w-full leading-normal text-green"
                                   style="border: solid 1px #dedede;" >
                              <thead>
                              <tr class="bg-gray-pale">
                                <th class="px-2 py-1">Share Class</th>
                                <th class="px-2 py-1 text-center">Shares</th>
                                <th class="px-2 py-1 text-center">Paid</th>
                              </tr>
                              </thead>
                              <tbody v-for="(share, idx) in transfer.transfereeFinalShareholding" :key="idx">
                              <tr style="border: solid 1px #dedede;">
                                <td class="px-2 py-1 text-uppercase font-bold">
                                  <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                                  <span v-else>{{share.otherClassName}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                                <td class="px-2 py-1 text-capitalize">
                                  <span>Class {{subClass.nominalClass}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              </tbody>

                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    </tbody>
                  </table>
                </b-col>
              </b-row>

              <b-row v-if="data.FILE_FORM_20" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Form 20</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Effective Date Of Appointment</td>
                      <td class="px-2 py-1">
                        <span v-if="data.FILE_FORM_20.data">{{ dateFormat(data.FILE_FORM_20.data.effectiveDateOfAppointment) }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Appointment of Directors</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons v-if="data.FILE_FORM_20.data" :persons="data.FILE_FORM_20.data.directors" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>

              <b-row v-if="data.MEMARTS.data" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">MemArts</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize" colspan="12">
                        <b-form-textarea class="w-full" rows="4" :value="data.MEMARTS.data.descriptionOfChanges"
                                         disabled></b-form-textarea>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize">Alters Memorandum Of Association</td>
                      <td class="px-2 py-1">
                        <span>{{ data.MEMARTS.data.altersMemorandumOfAssociation }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize">Alters Articles Of Association</td>
                      <td class="px-2 py-1">
                        <span>{{ data.MEMARTS.data.altersArticlesOfAssociation }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Objectives</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;" v-for="(item, idx) in data.MEMARTS.data.objectives" :key="idx">
                      <td class="px-2 py-1 text-capitalize">
                        <b-form-textarea disabled :value="item.objective" class="text-xs" rows="4"></b-form-textarea>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="type === 'FILE_FOR_CONVERSION_FROM_SMC_TO_PRIVATE'">
            <b-col>
                <b-row v-if="data.CONVERSION_FROM_SMC_TO_PRIVATE_METADATA" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Form 4</td>
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="t-body">Proposed Name</td>
                        <td class="t-body font-bold">{{data.CONVERSION_FROM_SMC_TO_PRIVATE_METADATA.data.proposedName}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-lighter">
                      <td colspan="12" class="px-2 py-2 font-bold">Grounds for Conversion</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <th class="t-header">Reason</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;"
                        v-for="(item, idx) in data.CONVERSION_FROM_SMC_TO_PRIVATE_METADATA.data.groundsForConversion" :key="idx">
                      <td class="t-body text-capitalize">
                        {{item.replaceAll("_", " ").toLowerCase()}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-lighter">
                      <td colspan="12" class="px-2 py-2 font-bold">Shareholders</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons :persons="data.CONVERSION_FROM_SMC_TO_PRIVATE_METADATA.data.subscribers" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-lighter">
                      <td colspan="12" class="px-2 py-2 font-bold">Single Member</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons :persons="[data.CONVERSION_FROM_SMC_TO_PRIVATE_METADATA.data.singleMember]" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row v-if="data.DEATH_OF_A_SINGLE_MEMBER" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Form 5</td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-lighter">
                      <td colspan="12" class="px-2 py-2 font-bold">Representative</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons :persons="[data.DEATH_OF_A_SINGLE_MEMBER.data.alternateDirector]" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row v-if="data.VALUATION_OF_SHARES" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Valuation of Shares</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Total Amount Paid In Transferred</td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.totalAmountPaidInTransferred.toLocaleString() }}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.totalAmountPaidInTransferredInWords }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Final Total Transferred</td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.finalTotalTransferred.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.VALUATION_OF_SHARES.data">{{ data.VALUATION_OF_SHARES.data.finalTotalTransferredInWords }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Valuation</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1 text-capitalize">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-capitalize">Shares</th>
                      <th class="px-2 py-1 text-center text-capitalize">Value(UGX)</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.VALUATION_OF_SHARES.data.valuation.valuation" :key="idx">
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center font-bold">
                        <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row v-if="data.MULTI_TRANSFER_OF_SHARES" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Transfer of Shares (Multiple)</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Total Amount Paid In Transfer</td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.totalAmountPaidInTransfer.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.totalAmountPaidInTransferredInWords }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Final Total Transferred</td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.finalTotalTransferred.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.finalTotalTransferredInWords }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Transferee Final Total Shares</td>
                      <td class="px-2 py-1">
                        <span v-if="data.MULTI_TRANSFER_OF_SHARES.data">{{ data.MULTI_TRANSFER_OF_SHARES.data.finalTotalTransferred.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1">

                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Valuation Report</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1 text-capitalize">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-capitalize">Shares</th>
                      <th class="px-2 py-1 text-center text-capitalize">Value(UGX)</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.MULTI_TRANSFER_OF_SHARES.data.valuationReport.valuation.valuation" :key="idx">
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center font-bold">
                        <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Transfers</td>
                    </tr>
                    </thead>
                    <tbody>
                    <b-row v-for="(transfer, idx) in data.MULTI_TRANSFER_OF_SHARES.data.transfers" :key="idx" class="border-b border-gray py-3">
                      <b-col cols="11" class="mx-auto">
                        <b-row>
                          <b-col>
                            <p class="text-sm">Shares Transferred: <b-badge class="bg-blue"> <i class="fa fa-reply mr-1"></i>{{transfer.finalTotalTransferred.toLocaleString()}}</b-badge></p>
                          </b-col>
                          <b-col>
                            <p class="text-sm">Transferor Shares: <b-badge class="bg-red"> <i class="fa fa-arrow-down mr-1"></i>{{transfer.transferorFinalTotalShares.toLocaleString()}}</b-badge></p>
                          </b-col>
                          <b-col>
                            <p class="text-sm">Transferee Shares: <b-badge class="bg-green"> <i class="fa fa-arrow-up mr-1"></i>{{transfer.transfereeFinalTotalShares.toLocaleString()}}</b-badge></p>
                          </b-col>
                        </b-row>
                        <b-row class="mt-4">
                          <b-col>
                            <b-col>
                              <table class="min-w-full leading-normal text-gray"
                                     style="border: solid 1px #dedede;" >
                                <thead>
                                <tr class="bg-gray-pale">
                                  <td colspan="12" class="px-2 py-2 font-bold">Shares Transfered Breakdown</td>
                                </tr>
                                </thead>
                                <thead>
                                <tr>
                                  <th class="px-2 py-1">Share Class</th>
                                  <th class="px-2 py-1 text-center">Shares</th>
                                  <th class="px-2 py-1 text-center">Paid</th>
                                </tr>
                                </thead>
                                <tbody v-for="(share, idx) in transfer.sharesTransferred " :key="idx">
                                <tr style="border: solid 1px #dedede;">
                                  <td class="px-2 py-1 text-uppercase font-bold">
                                    <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                                    <span v-else>{{share.otherClassName}}</span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.numberOfShares.toLocaleString()}}
                                  </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.amountPaid.toLocaleString()}}
                                  </span>
                                  </td>
                                </tr>
                                <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                                  <td class="px-2 py-1 text-capitalize">
                                    <span>Class {{subClass.nominalClass}}</span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.numberOfShares.toLocaleString()}}
                                  </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.amountPaid.toLocaleString()}}
                                  </span>
                                  </td>
                                </tr>
                                </tbody>

                              </table>
                            </b-col>
                          </b-col>
                        </b-row>
                        <b-row class="mt-4 mb-2">
                          <b-col>
                            <p class="text-red text-sm">From: <b>{{transfer.transferor.name}}</b> <b-badge class="bg-red font-monospace mx-2">final</b-badge></p>
                          </b-col>
                          <b-col>
                            <p class="text-green text-sm">To: <b>{{transfer.transferee.name}}</b><b-badge class="bg-green font-monospace mx-2">final</b-badge></p>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <table class="min-w-full leading-normal text-red"
                                   style="border: solid 1px #dedede;" >
                              <thead>
                              <tr class="bg-gray-pale">
                                <th class="px-2 py-1">Share Class</th>
                                <th class="px-2 py-1 text-center">Shares</th>
                                <th class="px-2 py-1 text-center">Paid</th>
                              </tr>
                              </thead>
                              <tbody v-for="(share, idx) in transfer.transferorFinalShareholding" :key="idx">
                              <tr style="border: solid 1px #dedede;">
                                <td class="px-2 py-1 text-uppercase font-bold">
                                  <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                                  <span v-else>{{share.otherClassName}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                                <td class="px-2 py-1 text-capitalize">
                                  <span>Class {{subClass.nominalClass}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              </tbody>

                            </table>
                          </b-col>
                          <b-col>
                            <table class="min-w-full leading-normal text-green"
                                   style="border: solid 1px #dedede;" >
                              <thead>
                              <tr class="bg-gray-pale">
                                <th class="px-2 py-1">Share Class</th>
                                <th class="px-2 py-1 text-center">Shares</th>
                                <th class="px-2 py-1 text-center">Paid</th>
                              </tr>
                              </thead>
                              <tbody v-for="(share, idx) in transfer.transfereeFinalShareholding" :key="idx">
                              <tr style="border: solid 1px #dedede;">
                                <td class="px-2 py-1 text-uppercase font-bold">
                                  <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                                  <span v-else>{{share.otherClassName}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span v-if="share.subClasses.length === 0">
                                    {{share.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                                <td class="px-2 py-1 text-capitalize">
                                  <span>Class {{subClass.nominalClass}}</span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.numberOfShares.toLocaleString()}}
                                  </span>
                                </td>
                                <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.amountPaid.toLocaleString()}}
                                  </span>
                                </td>
                              </tr>
                              </tbody>

                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row v-if="data.FILE_FORM_10" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Form 10</td>
                    </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="t-body">Return Date From</td>
                        <td class="t-body">{{data.FILE_FORM_10.data.returnDateFrom}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Return Date To</td>
                        <td class="t-body">{{data.FILE_FORM_10.data.returnDateTo}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Shares Payable</td>
                        <td>{{data.FILE_FORM_10.data.sharesPayable}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Nominal Amount</td>
                        <td>{{data.FILE_FORM_10.data.nominalAmount}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Amount Paid Due And Payable Per Share</td>
                        <td>{{data.FILE_FORM_10.data.amountPaidDueAndPayablePerShare}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Amount Paid Per Share</td>
                        <td>{{data.FILE_FORM_10.data.amountPaidPerShare}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Shares Payable In Cash</td>
                        <td>{{data.FILE_FORM_10.data.sharesPayableInCash}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Non Cash Shares Allotted</td>
                        <td>{{data.FILE_FORM_10.data.nonCashSharesAllotted}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Amount Paid Due And Payable Per Share For NonCash</td>
                        <td>{{data.FILE_FORM_10.data.amountPaidDueAndPayablePerShareForNonCash}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">AmountvPaid Per Share For NonCash</td>
                        <td>{{data.FILE_FORM_10.data.amountPaidPerShareForNonCash}}</td>
                      </tr>
                      <tr>
                        <td class="t-body">Consideration Description</td>
                        <td>
                          <b-form-textarea disabled :value="data.FILE_FORM_10.data.considerationDescription"></b-form-textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Allotments</td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;"
                         v-for="(person, idx) in data.FILE_FORM_10.data.allotments"
                         :key="idx">
                    <thead>
                    <tr class="bg-gray-pale">
                      <th class="px-2 py-1 text-uppercase">Name</th>
                      <th class="px-2 py-1 text-center float-start text-uppercase">Share Class</th>
                      <th class="px-2 py-1 text-center text-uppercase">Number of Shares</th>
                      <th class="px-2 py-1 text-center text-uppercase">Paid</th>
                      <th class="px-2 py-1 text-center text-uppercase">Not Paid</th>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="px-2 py-1 font-bold text-blue text-uppercase" colspan="12">
                        <span>{{person.shareholder.name}}</span>
                      </td>
                    </tr>
                    </thead>
                    <tbody style="border: solid 1px #dedede;" v-for="(share, idx2) in person.shares" :key="idx2" >
                    <tr >
                      <td class="px-2 py-1"></td>
                      <td class="px-2 py-1 text-uppercase font-bold">
                        <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                        <span v-else>{{share.otherClassName}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                <span v-if="share.subClasses.length === 0">
                  {{share.numberOfShares.toLocaleString()}}
                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                <span v-if="share.subClasses.length === 0">
                  {{share.amountPaid.toLocaleString()}}
                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                <span v-if="share.subClasses.length === 0">
                  {{share.amountUnPaid.toLocaleString()}}
                </span>
                      </td>
                    </tr>
                    <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                      <td class="px-2 py-1"></td>
                      <td class="px-2 py-1 text-capitalize">
                        <span>Class {{subClass.nominalClass}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                <span>
                  {{subClass.numberOfShares.toLocaleString()}}
                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                <span>
                  {{subClass.amountPaid.toLocaleString()}}
                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                <span>
                  {{subClass.amountUnPaid.toLocaleString()}}
                </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
&lt;!&ndash;                  SHARE CAPITAL&ndash;&gt;
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Share capital</td>
                      </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Number of Shares</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.shareCapital.numberOfShares" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Amount Paid</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.shareCapital.amountPaid" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Amount Un Paid</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.shareCapital.amountUnPaid" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Total Nominal Value</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.shareCapital.totalNominalValue" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Rights Attaching To Shares</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.shareCapital.rightsAttachingToShares" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.rights}}
                      </td>
                    </tr>
                    </tbody>
                  </table>

&lt;!&ndash;                  FINAL SHARE CAPITAL&ndash;&gt;
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Final Share capital</td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Number of Shares</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.finalShareCapital.numberOfShares" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Amount Paid</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.finalShareCapital.amountPaid" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Amount Un Paid</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.finalShareCapital.amountUnPaid" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Total Nominal Value</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.finalShareCapital.totalNominalValue" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.value}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Rights Attaching To Shares</td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <td class="t-header">Nominal Class</td>
                      <td class="t-header">Nominal Value</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cls, idx) in data.FILE_FORM_10.data.finalShareCapital.rightsAttachingToShares" :key="idx" style="border: solid 1px #dedede;">
                      <td class="t-body">
                        {{cls.nominalClass}}
                      </td>
                      <td class="t-body">
                        {{cls.rights}}
                      </td>
                    </tr>
                    </tbody>
                  </table>

                </b-col>
              </b-row>
              <b-row v-if="data.FILE_FORM_20" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Form 20</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Effective Date Of Appointment</td>
                      <td class="px-2 py-1">
                        <span v-if="data.FILE_FORM_20.data">{{ dateFormat(data.FILE_FORM_20.data.effectiveDateOfAppointment) }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Appointment of Directors</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="12">
                        <LegalPersons v-if="data.FILE_FORM_20.data" :persons="data.FILE_FORM_20.data.directors" />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row v-if="data.MEMARTS" class="mt-3">
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">MemArts</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize" colspan="12">
                        <b-form-textarea class="w-full" rows="4" :value="data.MEMARTS.data.descriptionOfChanges"
                                         disabled></b-form-textarea>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize">Alters Memorandum Of Association</td>
                      <td class="px-2 py-1">
                        <span>{{ data.MEMARTS.data.altersMemorandumOfAssociation }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize">Alters Articles Of Association</td>
                      <td class="px-2 py-1">
                        <span>{{ data.MEMARTS.data.altersArticlesOfAssociation }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray border border-gray">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Objectives</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;" v-for="(item, idx) in data.MEMARTS.data.objectives" :key="idx">
                      <td class="px-2 py-1 text-capitalize">
                        <b-form-textarea disabled :value="item.objective" class="text-xs" rows="4"></b-form-textarea>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import LegalPersons from "@/views/home/commons/LegalPersons.vue";
export default {
  name: "CompanyConversion",
  components: { LegalPersons },
  props:{
    data:Object,
    brn:String,
    type:String
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>