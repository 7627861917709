

<template>
    <div>
        <b-row>
            <b-col class="mx-auto mt-2">
                <label class="text-gray">Date of Registration</label>
                <datepicker
                        :disabled-dates="{from:new Date()}"
                        placeholder="dd/mm/yyyy"
                        :format="customFormatter"
                        class="noFocus"
                        bootstrap-styling
                        id="dateOfRegistration"
                        required
                        v-model="form.dateOfRegistration">
                </datepicker>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col cols="12">
                <button id="nextBtn" type="button" class="button float-end" @click="onSubmit">
                   <span class="mx-3">
                    Save changes <b-spinner small v-if="loading"></b-spinner>
                   </span>
                </button>
            </b-col>
        </b-row>
    </div>

</template>

<script>
import Datepicker from "vuejs-datepicker";
import {api} from "@/app/services/api.service";
import moment from "moment";
export default {
    name: "ChangeDateOfRegistration",
    components : {
        Datepicker
    },
    props : {
      brn:String,
      oldValue:String
    },
    data(){
        return {
            loading:false,
            form: {
                dateOfRegistration:null,
                brn:null
            }
        }
    },
    mounted() {
     this.form.dateOfRegistration = this.oldValue
    },
    methods : {
        async onSubmit() {
            if (!this.brn){
                this.errorToast("Error", "Please provide a BRN")
            }
            this.form.brn = this.brn
            this.form.dateOfRegistration = moment(this.form.dateOfRegistration).format('YYYY-MM-DD')
            await api().post("incorporation/data-correction/date-of-registration", this.form)
                .then((response) => {
                    console.log(response)
                    this.loading = false
                    this.$emit("done", true);
                })
                .catch((error) => {
                    this.loading = false
                    this.errorToast("Error", error.response.data.message)

                });
        },
        customFormatter(date) {
            return moment(date).format('DD/MM/yyyy');
        }
    }
}
</script>
<style scoped>
.button {
    @apply bg-blue text-white py-2 rounded;
}

.button:hover {
    @apply bg-blue-dark;
}

</style>