import {api} from "@/app/services/api.service";

const state = {
    officer_accounts:[],
    user_accounts:[],
    clients:null,
    error: null
};

const getters = {
    getOfficerAccounts: (state) => state.officer_accounts,
    getRegistrars: (state) => state.officer_accounts,
    getUserAccounts: (state) => state.user_accounts,
    getClients: (state) => state.clients
}

const actions = {
    async fetchUsers({commit}) {
        await api()
            .get("auth/api/users")
            .then(response => {
                commit("setUserListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async fetchRegistrars({commit}, data) {
        await api()
            .get("/auth/api/users/staff?accountStatus=ACTIVE&page=1&perPage="+data.limit+"&permissions="+data.permission)
            .then(response => {
                commit("setRegistrars", response.data);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async searchRegistrars({commit}, data) {
        await api()
            .get("auth/api/users/staff?name="+data.term.toUpperCase()+"&permissions="+data.permission)
            .then(response => {
                commit("setRegistrarSearch", response.data);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async fetchClients({commit}) {
        await api()
          .get("auth/api/users/individual")
          .then(response => {
              commit("setClients", response.data);
          })
          .catch(error => {
              commit("setUserListError", error.response);
          });
    },
    async searchClients({commit}, queryParams) {
        await api()
          .get("auth/api/users/individual?"+queryParams)
          .then(response => {
              commit("setClients", response.data);
          })
          .catch(error => {
              commit("setUserListError", error.response);
          });
    },
    async fetchUsersByPermission({commit}) {
        await api()
            .get("auth/api/users")
            .then(response => {
                commit("setUserListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    },
    async fetchUsersByRole({commit}, data) {
        await api()
            .post("auth/api/users/by-role", data)
            .then(response => {
                commit("setUserListData", response);
            })
            .catch(error => {
                commit("setUserListError", error.response);
            });
    }
};

const mutations = {
    setRegistrars: (state, response) => {
        state.officer_accounts = response.data.content;
    },
    setRegistrarSearch: (state, response) => {
        state.officer_accounts = response.data.content;
    },
    setUserListData: (state, response) => {
        state.user_accounts = response.data;
    },
    setUserListError: (state, error) => {
        state.error = error;
    },
    setClients: (state, response) => {
        state.clients = response.data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};