<template>
  <div>
      <b-row>


          <b-col cols="4">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Attachments</td>
                  </tr>
                  <tr>
                      <th class="px-2 py-1">#</th>
                      <th class="px-2 py-1">File Name</th>
                      <th class="px-2 py-1 float-end">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(file, idx) in task.attachments" :key="idx" style="border: solid 1px #dedede;">
                      <td class="px-2 py-1">{{idx + 1}}</td>
                      <td class="px-2 py-1 text-capitalize">{{cleanName(file.description)}}</td>
                      <td class="px-2 py-1">
                          <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </b-col>


        <b-col cols="8">
          <DataCorrectionAttachments ref="attachmentScreen"/>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import moment from "moment";
import server from "@/app/env/server.json";
import DataCorrectionAttachments from "@/views/home/services/data-correction/task/DataCorrectionAttachments.vue";

export default {
  name: "DataCorrectionFormFiles",
  components: { DataCorrectionAttachments },
  props:{
    task:Object
  },
  data(){
    return{
      attachmentUrl:null
    }
  },
  mounted() {
    this.openFile(this.task.attachments[0])
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    openFile(data){
      if(data){
        let fName = data ? data.fileName.trim() : ""
        this.attachmentUrl = server.IP+"/data-correction/files?attachment="+fName+"&token="+sessionStorage.getItem("access_token").replaceAll("\"", "")
      }
    },
    cleanName(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.toLowerCase()
      }

      return ""
    },
  },
  watch:{
    attachmentUrl:{
      handler(url){
        console.log(url)
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    }
  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>