<template>
  <div>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="flex h-full" style="cursor: pointer;">
                <div :class="[(currentRoute == 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Company Information</div>
                  </div>
                </div>
                <div :class="[(currentRoute == 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Attachments</div>
                  </div>
                </div>
                <div :class="[(currentRoute == 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Messages</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <CompanyFormsEscalated :application="application" v-if="currentRoute === 'forms'"/>
              <CompanyFormFilesEscalated :application="application" v-if="currentRoute === 'attachments'"/>
              <CompanyMessagesEscalated :application="application" v-if="currentRoute === 'messages'"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import CompanyFormsEscalated from "@/views/home/services/incorporation/escalations/tabs/CompanyFormsEscalated";
import CompanyFormFilesEscalated
  from "@/views/home/services/incorporation/escalations/tabs/CompanyFormFilesEscalated";
import CompanyMessagesEscalated
  from "@/views/home/services/incorporation/escalations/tabs/CompanyMessagesEscalated";
export default {
  name: "CompanyFormDetailsEscalated",
  components: {CompanyMessagesEscalated, CompanyFormFilesEscalated, CompanyFormsEscalated},
  props:{
    application:Object
  },
  data(){
    return{
      currentRoute: "forms",
    }
  },
  methods:{
    select(route){
      this.currentRoute = route
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>