<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col>
          <div class="mx-auto mt-2">
            <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-2" v-if="history.length > 0">
        <b-col class="px-2 py-1 bg-headed_grey rounded" >
          <b-row>
            <b-col cols="10" class="mx-2 bg-header_grey rounded px-2 py-1 font-monospace">
              <b-row class="text-sm" v-for="(filing, idx) in history" :key="idx">
                <b-col cols="2">
                  <span class="italic text-blue text-xs float-end mt-3">{{dateFormat(filing.date)}}</span>
                </b-col>
                <b-col cols="9">
                  <b-row>
                    <b-col cols="1">
                      <div style="margin-top: 20px; margin-left: 20px">
                        <span class="circle"></span>
                        <div class="line"></div>
                      </div>
                    </b-col>
                    <b-col cols="11" class="my-3">
                      <p class="text-xs font-bold">
                        {{filing.type.replaceAll("_", " ")}}
                      </p>
                      <p class="text-xs">
                        {{filing.description}}
                      </p>
                      <i class="text-blue text-xs">{{filing.trackingNumber}}</i>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="1">
                  <i class="fa fa-check text-green text-sm mt-5"></i>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-2" v-else>
        <b-col class="px-2 py-1 bg-headed_grey rounded" >
          <b-alert show variant="info" class="text-sm">
            <i class="fa fa-info-circle mr-2"></i> No filing history yet
          </b-alert>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
export default {
  name: "BusinessNameFilingHistory",
  props:{
    brn:String
  },
  data(){
    return{
      userProfile:{},
      loading:true,
      history:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
    }
  },
  mounted(){
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchBusinessNameFilingHistory"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    fetch(){
      this.loading = true
      this.fetchBusinessNameFilingHistory(this.brn)
    },
    statusTranslator(code){
      if(code === "NOTICE_OF_DISSOLUTION"){
        return "Filed for Dissolution"
      }
    }
  },
  computed:{
    ...mapGetters(["getBusinessNameFilingHistory", "getBusinessDetailError"])
  },
  watch:{
    getBusinessNameFilingHistory(data){
      this.loading = false
      this.history = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getBusinessDetailError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }

}
</script>

<style scoped>
.circle {
  position: absolute;
  transform: translate(-50%, -35%);
  width: 25px;
  height: 25px;
  background-color: #157EC2;
  border-radius: 100%;
}
.line {
  position: absolute;
  transform: translate(-50%, -15%);
  width: 2px;
  height: 120px;
  background-color: #157EC2;
}
</style>