<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-details" size="xl" hide-footer ref="escalate-details" title="Escalated File">
        <b-col cols="12">
            <BusinessNameAmendmentEscalationFile />
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Business Amendment Escalations</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <div class="mx-auto">
          <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">Tracking No.</th>
                <th class="t-header">BRN</th>
                <th class="t-header">Type</th>
                <th class="t-header">Submission Date</th>
                <th class="t-header text-center">Action</th>
              </tr>
              </thead>

              <tbody>
              <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in escalations" :key="idx">
                  <td class="t-body text-blue font-bold">{{ submission.filingApplicationDetail.trackingNumber }}</td>
                  <td class="t-body text-blue font-bold">{{ submission.filingApplicationDetail.brn }}</td>
                  <td class="t-body text-capitalize">
                      {{ cleanEntityType(submission.filingApplicationDetail.filingType.toLowerCase()) }}
                  </td>
                  <td class="t-body">{{ dateTimeFormat(submission.filingApplicationDetail.createdAt) }}</td>
                <td class="t-body">
                  <div class="flex">
                    <button @click="view(submission)" class="view-btn w-full">View</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td v-if="escalations.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                  <span class="text-sm">No results found</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
          <b-col cols="1">
            <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
          </b-col>
          <b-col cols="1">
            <b-row>
              <b-col cols="12">
                <p class="paginate-details text-center">
                  <b-badge class="bg-blue text-white">
                    {{currentPage}}/{{pagination.totalPages}}
                  </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" >
            <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
          </b-col>
          <b-col class="9"></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import BusinessNameAmendmentEscalationFile
    from "@/views/home/services/amendments/escalations/BusinessNameAmendmentEscalationFile";

export default {
  name: "BusinessNameAmendmentEscalations",
  components: { BusinessNameAmendmentEscalationFile },
  data(){
    return{
      selectedId:null,
      margin:"2px",
      hasContent:false,
      extension:{},
      loading:true,
      message:null,
      currentPage:1,
      limit:12,
      reservation:null,
      description:null,
      source:{},
      escalateTime:null,
      escalationId:null,
      form:{
        extensionId:null,
        status:null
      },
      escalations:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
        search: "",
        task: {}
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
      ...mapActions(["fetchAmendmentEscalations", "prefillAmendmentEscalationTask"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    fetch(){
      let data = {
        search:this.search,
        status:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchAmendmentEscalations(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    view(submission){
      this.reservation = submission.reservation
      this.description = submission.description
      this.escalateTime = submission.createdAt
      this.escalationId = submission.escalationId
      this.source = submission.fromUser
        //alert(JSON.stringify(submission.filingApplicationDetail))
        this.prefillAmendmentEscalationTask({
            data: submission.filingApplicationDetail, ...submission,
            escalationId: submission.escalationId
        })
      this.$refs["escalate-details"].show()
    },
    onApprove(){
      this.loading = true
      this.form.status = "Approved"
      this.$refs["escalate-details"].hide()
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    clearForm(){
      this.form = {
        description:null,
        status:null
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
    ...mapGetters([ "getAmendmentEscalations",]),
  },
  watch:{
    getAmendmentEscalations(data){
      this.loading = false
      this.escalations = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.icon{
  width: 170px;
}

.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.approve-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.approve-btn:hover{
  @apply bg-green text-white;
}

.reject-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.reject-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>