<template>
  <div class="flex bg-white shadow">
    <div class="w-11/12">

    </div>
    <div class="w-1/12">
      <div class="flex">
        <div class="w-6/12"></div>
        <div class="w-6/12 bg-red hover:bg-red-dark">
          <div class="flex justify-center items-center items-stretch">
            <div class="w-6/12" @click="close">
              <img class="p-2" :src="require('/src/assets/images/ic_close.png')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ActionHeader",
  data(){
    return{

    }
  },
  methods:{
    close(){
      window.close()
    }
  }
}
</script>

<style scoped>

</style>