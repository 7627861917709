<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-add-word" hide-footer ref="addWord" title="Add a Word">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-input
                v-model="form.word"
                type="text"
                placeholder="Word"
                class="mt-2 noFocus"
                required>
            </b-form-input>
            <b-form-input
                v-model="form.description"
                type="text"
                placeholder="Description"
                class="mt-3 noFocus"
                required>
            </b-form-input>


            <b-form-select
                v-model="form.position"
                :options="positionList"
                class="form-select noFocus mt-3">
            </b-form-select>

                <b-form-select
                    v-model="form.dictionaryId"
                    :options="dictionaryList"
                    class="form-select noFocus mt-3">
                </b-form-select>

            <div style="text-align: end;">

                <b-button
                    @click="saveWord"
                    class="bg-blue w-25 mt-4"
                    variant="primary">
                        Save
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>

            </div>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-edit-word" hide-footer ref="editWord" title="Edit Word">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-input
                v-model="eform.word"
                type="text"
                placeholder="Word"
                class="mt-2 noFocus"
                required>
            </b-form-input>
            <b-form-input
                v-model="eform.description"
                type="text"
                placeholder="Description"
                class="mt-3 noFocus"
                required>
            </b-form-input>


                <b-form-select
                    v-model="eform.position"
                    :options="positionList"
                    class="form-select noFocus mt-3">
                </b-form-select>

            <b-form-select
                    v-model="eform.dictionaryId"
                    :options="dictionaryList"
                    class="form-select noFocus mt-3">
                </b-form-select>


            <p class="text-sm mt-3 italic text-blue">
              This action will be logged and your account flagged as one who took this action and also had the permission to do so.
            </p>

            <div style="text-align: end;">
                <b-button
                    @click="updateWord"
                    class="bg-blue mt-3"
                    variant="primary">
                      Save Changes
                      <b-spinner small v-if="loading"></b-spinner>
                </b-button>
            </div>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-delete-word" hide-footer ref="deleteWord" title="Remove Word">
        <b-col cols="12">
          <b-form class="w-full">
            <b-row>
              <b-col cols="12">
                <p class="text-center">
                  Are you sure you want to remove this word <b>{{dform.word.toUpperCase()}}</b> from the dictionary in the URSB database?<br/><br/>
                  Removing this word means that it will no longer be used and considered as a restriction during <b>Name Reservation</b>.
                </p>
                <p class="text-sm mt-3 italic text-red">
                  This action will be logged and your account flagged as one who took this action and also had the permission to do so.
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4"></b-col>
              <b-col cols="4" class="text-center justify-content-center">
                <b-button @click="removeWord" class="w-full mt-3" variant="danger">
                  Yes <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
              <b-col cols="4"></b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>

    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">URSB Dictionaries</span>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="my-4">
        <b-row class="mx-1">
          <b-col cols="2">
            <button
                v-b-modal.modal-add-word
                class="bg-blue w-full text-white px-2 py-2 text-sm rounded-sm hover:bg-blue-dark">
              Add Word
            </button>
          </b-col>
          <b-col cols="2">
            <b-form-select
                v-model="filter.dictionaryId"
                :options="dictionaryList"
                @change="filterWords"
                class="form-select w-full text-sm">
            </b-form-select>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="2">
            <b-form-input
                class="form-text w-full text-sm form-select-sm noFocus"
                type="text"
                placeholder="Search"
                required
                v-on:keyup.enter="searchDictionary(form.search_text)">
            </b-form-input>
          </b-col>
        </b-row>
      </b-col>

    </b-row>

    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>

    <b-row>
      <b-col cols="12">
        <table class="w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">#</th>
            <th class="t-header">Name</th>
            <th class="t-header">Description</th>
            <th class="t-header">Position</th>
            <th class="t-header">Dictionary</th>
            <th v-if="canEditWord()" class="t-header">Edit</th>
            <th v-if="canDeleteWord()" class="t-header">Remove</th>
          </tr>
          </thead>
          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(page, idx) in getWords" :key="idx">
            <td class="t-body">{{idx+1}}</td>
            <td class="t-body font-bold">{{page.word}}</td>
            <td class="t-body">{{page.description}}</td>
            <td class="t-body">{{page.position}}</td>
            <td class="t-body text-green italic">{{page.dictionary.name}}</td>
            <td v-if="canEditWord()" class="t-body">
              <button @click="editWordPrompt(page)" class="bg-blue px-3 py-1 text-white text-sm shadow hover:bg-blue-dark">Edit</button>
            </td>
            <td v-if="canDeleteWord()" class="t-body">
              <button @click="deleteWordPrompt(page)" class="bg-red px-3 py-1 rounded-sm text-white text-sm">De-activate</button>
            </td>
          </tr>
          <tr>
            <td v-if="getWords.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ReservationDictionary",
  data(){
    return{
      loading:true,
      positionList:[
        {text: "--Word Position--", id: null},
        {value: 'Start', text: 'Start'},
        {value: 'Any', text: 'Any'},
        {value: 'End', text: 'End'},

      ],
      filter:{
        dictionaryId:null,
      },
      form:{
        word:null,
        dictionaryId:null,
        description:null,
        position:null,
      },
      eform:{
        word:null,
        dictionaryId:null,
        description:null,
        position:null,
      },
      dform:{
        id:null,
        word:""
      },
      status:{
        approved: "px-3 py-1 bg-green rounded-md text-white text-xs w-10/12 text-center",
        rejected: "px-3 py-1 bg-red rounded-md text-white text-xs w-10/12 text-center"
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      dictionaryList:[],
      selectedId:null
    }
  },
  mounted(){
    this.fetchDictionaries()
    this.fetchWords()
  },
  methods:{
    ...mapActions(["fetchDictionaries", "fetchFilteredWords", "fetchWords", "addWord", "editWord", "deleteWord"]),
    editWordPrompt(data){
      this.selectedId = data.id
      this.eform = {
        word:data.word,
        description: data.description,
        dictionaryId: data.dictionary.id,
        position: data.position

      }
      this.$refs.editWord.show();
    },
    deleteWordPrompt(data){
      this.dform = {
        id:data.id,
        word:data.word
      }
      this.$refs.deleteWord.show();
    },
    saveWord(){
      this.loading = true
      this.addWord(this.form)
    },
    updateWord(){
      this.loading = true
      let data = {
        id: this.selectedId,
        form:this.eform
      }
      this.editWord(data)
    },
    removeWord(){
      this.loading = true
      this.deleteWord(this.dform)
    },
    searchDictionary(data){
      alert(data)
    },
    filterDictionaryInfo(data){
      let dataObj = [{text: "--Filter by Dictionary--", id: null,}];
      let dataJson = {};
      data.forEach(obj =>{
        dataJson = {
          text: obj.name,
          value: obj.id
        };
        dataObj.push(dataJson);
      })
      return dataObj;
    },
    filterWords(){
      this.loading = true
      this.fetchFilteredWords(this.filter.dictionaryId)
    },
    canEditWord(){
      return this.$can('can_edit_dictionary_entry','general')
    },
    canDeleteWord(){
      return this.$can('can_delete_dictionary_entry','general')
    }
  },
  computed:{
    ...mapGetters(
        [
          "getWords",
          "getDictionaries",
          "getWordTransactionError",
          "getWordTransactionSuccess"
        ]
    ),
    getFilter(){
      return this.filter.dictionaryId
    }
  },
  watch:{
    getWords(){
      this.loading = false
    },
    getWordTransactionSuccess(){
      this.$refs.addWord.hide();
      this.$refs.editWord.hide();
      this.$refs.deleteWord.hide();
      this.fetchWords()

      this.form = {
        word:null,
        dictionaryId:null,
        description:null
      }

      this.successToast("Success", "Successfully completed")
    },
    getWordTransactionError(message){
      this.$refs.addWord.hide();
      this.$refs.editWord.hide();
      this.$refs.deleteWord.hide();
      this.loading = false
      this.errorToast("Error", message)
    },
    getFilter(id){
      if(id == "null"){
        this.fetchWords()
      }else{
        this.fetchFilteredWords(id)
      }
    },
    getDictionaries(data){
      if(data){
        this.dictionaryList = this.filterDictionaryInfo(data)
      }
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

</style>