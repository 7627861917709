<template>
  <div>
    <b-row>
      <b-modal id="edmsDocument" size="lg" hide-footer ref="edmsDocument" title="EDMS Document">
        <EdmsIdToDocument :edms-id="selectedEdmsId"></EdmsIdToDocument>
      </b-modal>
    </b-row>
    <b-row v-if="document != null">
        <b-col>
          <b-row class="mt-3">
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Subject Matter"
                label-for="input-1">
                <b-form-textarea
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  :disabled="true"
                  :value="document.subjectMatter"
                  required>
                </b-form-textarea>
              </b-form-group>

            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Origin Account Type"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    v-if="document.user"
                    class="form-text text-sm noFocus text-capitalize font-bold"
                    type="text"
                    :disabled="true"
                    :value="document.user.accountType+ ' ACCOUNT'"
                    required>
                </b-form-input>
                <b-form-input
                    id="companyName"
                    v-else
                    class="form-text text-sm noFocus text-capitalize font-bold"
                    type="text"
                    :disabled="true"
                    value="N/A"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Date of Execution"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="document.filingDate"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'AGREEMENT' || document.documentType === 'MINUTES' || document.documentType === 'OTHERS' || document.documentType === 'CONSTITUTION' || document.documentType === 'RESOLUTION_AS_A_DOCUMENT'">
            <b-col cols="12" class="mt-4">
              <table class="min-w-full leading-normal mt-1 text-gray"
                     style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Parties</td>
                </tr>
                </thead>
                <tbody>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualParties" :corporates="document.entityParties"/>
                </tbody>
              </table>
            </b-col>
          </b-row>

          <b-row v-if=" document.documentType === 'GUARANTEE'">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <span class="h5">Parties</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualParties" :corporates="document.entityParties"/>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="h5">Beneficiary</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="getArray(document.individualBeneficiary)" :corporates="getArray(document.entityBeneficiary)"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'AFFIDAVIT' || document.documentType === 'STATUTORY_DECLARATION'" class="mt-4">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <span class="h5">Deponent's KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="document.entityDeclarantDeponent != null" :person="document.entityDeclarantDeponent" entity-type="CORPORATE"/>
                  <LegalPerson v-if="document.individualDeclarantDeponent != null" :person="document.individualDeclarantDeponent" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'FACILITY_LETTER'">
            <b-col cols="12" class="mt-4">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Collateral Security Description"
                          label-for="input-1">
                        <b-form-textarea
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            :disabled="true"
                            :value="document.collateral"
                            required>
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Amount Secured"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            :disabled="true"
                            :value="document.securedAmount.toLocaleString()"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Borrowers</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualBorrowers" :corporates="document.entityBorrowers"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Lenders</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualLenders" :corporates="document.entityLenders"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'DEED_OF_ASSIGNMENT' || document.documentType === 'DEED_OF_INTER_VIVOS' || document.documentType ===  'DEED_OF_VARIATION' || document.documentType === 'DEED_OF_GIFT' || document.documentType === 'DEED_OF_INDEMNITY' || document.documentType === 'DEED_OF_TRUST'">
            <b-col cols="12" class="mt-4">
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assigners</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualAssigners" :corporates="document.entityAssigners"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assignees</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualAssignees" :corporates="document.entityAssignees"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'INDEPENDENT_ADVISE_FORM'" class="mt-4">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <span class="h5">Advisor KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="document.entityAdviser != null" :person="document.entityAdviser" entity-type="CORPORATE"/>
                  <LegalPerson v-if="document.individualAdviser != null" :person="document.individualAdviser" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Advisee KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="document.entityAdvise != null" :person="document.entityAdvise" entity-type="CORPORATE"/>
                  <LegalPerson v-if="document.individualAdvise != null" :person="document.individualAdvise" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'DEED_POLL'">
            <b-col>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Old Name of Declarant"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        :disabled="true"
                        :value="document.oldName"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="New Name of Declarant"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        :disabled="true"
                        :value="document.newName"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Address of Declarant</span>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="District"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        :disabled="true"
                        :value="document.physicalAddress.district.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Region"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        :disabled="true"
                        :value="document.physicalAddress.region.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Sub county"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        :disabled="true"
                        :value="document.physicalAddress.subcounty.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Parish"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        :disabled="true"
                        :value="document.physicalAddress.parish.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Village"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        :disabled="true"
                        :value="document.physicalAddress.village.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'MORTGAGE_DEED'">
            <b-col cols="12" class="mt-4">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Amount"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            :disabled="true"
                            :value="document.amountSecured.toLocaleString()"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Collateral Security Description"
                          label-for="input-1">
                        <b-form-textarea
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            :disabled="true"
                            :value="document.collateral"
                            required>
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Mortgagors</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagors" :corporates="document.entityMortgagors"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Mortgagees</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="document.individualMortgagees" :corporates="document.entityMortgagees"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'POWER_OF_ATTORNEY'" class="mt-4">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <span class="h5">Resolution(s)</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row v-for="(resolution, idx) in document.resolutions" :key="idx">
                    <b-col class="text-sm">
                      <p>Tracking Number: {{ resolution.trackingNumber }}</p>
                      <p>Document Type: {{ resolution.documentType.replaceAll("_", " ") }}</p>
                      <p>Subject Matter: {{ resolution.subjectMatter }}</p>
                      <p>Filing Date: {{ resolution.filingDate }}</p>
                    </b-col>
                    <b-col class="text-sm">
                      <p class="font-bold">Attachments</p>
                      <p v-for="(attachment, idx) in resolution.attachments" :key="idx"
                         class="cursor-pointer hover:underline" @click="viewResolution(attachment)">
                        <i class="fa fa-file mx-1 text-blue"></i> {{ attachment.fileDescription }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <span class="h5">Donor KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-for="(lp, idx) in document.entityDonor" :key="idx" :person="lp" entity-type="CORPORATE" />
                  <LegalPerson v-for="(lp, idx) in document.individualDonor" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Donee KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-for="(lp, idx) in document.entityDonee" :key="idx" :person="lp" entity-type="CORPORATE" />
                  <LegalPerson v-for="(lp, idx) in document.individualDonee" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="document.documentType === 'REVOCATION_POWER_OF_ATTORNEY'" class="mt-4">
            <b-col cols="12">
              <b-card title="Power of Attorney to Revoke">
                <b-card-body>
                  <b-row class="my-2">
                    <b-col cols="12">
                      <p>Instrument Number: {{document.powerOfAttorney.instrumentNumber}} </p>
                      <p>EDMS Document Copy: <b class="cursor-pointer text-blue"> <i class="fa fa-file mx-2"></i> {{document.powerOfAttorney.edmsId}}</b> </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <span class="h5">Resolution(s)</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-row v-for="(resolution, idx) in document.powerOfAttorney.resolutions" :key="idx">
                            <b-col class="text-sm">
                              <p>Tracking Number: {{ resolution.trackingNumber }}</p>
                              <p>Document Type: {{ resolution.documentType.replaceAll("_", " ") }}</p>
                              <p>Subject Matter: {{ resolution.subjectMatter }}</p>
                              <p>Filing Date: {{ resolution.filingDate }}</p>
                            </b-col>
                            <b-col class="text-sm">
                              <p class="font-bold">Attachments</p>
                              <p v-for="(attachment, idx) in resolution.attachments" :key="idx"
                                 class="cursor-pointer hover:underline" @click="viewResolution(attachment)">
                                <i class="fa fa-file mx-1 text-blue"></i> {{ attachment.fileDescription }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row v-if="document.powerOfAttorney.resolutions.length === 0">
                            <b-col>
                              <b-alert variant="info" show>
                                <i class="mx-2 fa fa-info-circle"></i> No Resolution Included
                              </b-alert>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <b-row class="mt-3">
                        <b-col>
                          <span class="h5">Donor KYC</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <LegalPerson v-for="(lp, idx) in document.powerOfAttorney.entityDonor" :key="idx" :person="lp" entity-type="CORPORATE" />
                          <LegalPerson v-for="(lp, idx) in document.powerOfAttorney.individualDonor" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
                        </b-col>
                      </b-row>

                      <b-row class="mt-2">
                        <b-col>
                          <span class="h5">Donee KYC</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <LegalPerson v-for="(lp, idx) in document.powerOfAttorney.entityDonee" :key="idx" :person="lp" entity-type="CORPORATE" />
                          <LegalPerson v-for="(lp, idx) in document.powerOfAttorney.individualDonee" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
  </div>
</template>

<script>
import moment from "moment";
import LegalPersonsPartner from "@/views/home/commons/LegalPersonsPartner";
import LegalPerson from "@/views/home/services/legal-documents/LegalPerson";
import EdmsIdToDocument from "@/views/home/services/registry/business/document/EdmsIdToDocument.vue";

export default {
  name: "LegalDocumentFormDetailsEscalated",
  components: { EdmsIdToDocument, LegalPerson, LegalPersonsPartner},
  data(){
    return{
      selectedEdmsId:null,
    }
  },
  props:{
    document:Object
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
    selectEDMSDocument(id){
      this.selectedEdmsId = id
      this.$refs.edmsDocument.show()
    },
    getArray(data){
      if(data){
        return [data]
      }
      return []
    }
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>