<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
          <Assignment :id="taskId" type="ci-data-update" permission="can_access_companies_data_update"/>
        </b-modal>

          <b-modal ref="transferApplication" title="Transfer Application" size="md" hide-footer>
              <ReAssignInsolvencyApplication :tracking-number="trackingNumber" />
          </b-modal>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-input
            class="text-gray text-sm noFocus mx-2 my-2"
            style="padding:6px 5px;"
            v-model="search"
            type="text"
            placeholder="Search by TrackingNo,BRN,Name"
            required
            v-on:keyup.enter="fetch">
          </b-form-input>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-form-select
              v-model="statuses"
              v-on:change="fetch"
              class="form-select noFocus text-gray mx-2 my-2 text-sm">
              <b-select-option :value="null">--Filter by Status--</b-select-option>
              <b-select-option value="DRAFT">Draft</b-select-option>
              <b-select-option value="SUBMITTED">In Queue</b-select-option>
              <b-select-option value="ASSIGNED">Assigned</b-select-option>
              <b-select-option value="QUERIED">Queried</b-select-option>
              <b-select-option value="APPROVED">Approved</b-select-option>
              <b-select-option value="ESCALATED">Escalated</b-select-option>
              <b-select-option value="INTERIM_REPORT">Interim report</b-select-option>
              <b-select-option value="INTERIM_REPORT_NOTICE">Interim report notice</b-select-option>
              <b-select-option value="PAYMENT_FAILED">Payment failed</b-select-option>
              <b-select-option value="RESOLUTION">Resolution</b-select-option>
              <b-select-option value="NOTICE">Notice</b-select-option>
              <b-select-option value="PRELIMINARY_REPORT">Preliminary report</b-select-option>
              <b-select-option value="FINAL_NOTICE">Final notice</b-select-option>
              <b-select-option value="FINAL_REPORT">Final report</b-select-option>
              <b-select-option value="PRACTITIONER_VERIFICATION">Practitioner verification</b-select-option>
              <b-select-option value="PAYMENTS">Awaiting payment confirmation</b-select-option>
              <b-select-option value="PRELIMINARY_NOTICE">Preliminary notice</b-select-option>
              <b-select-option value="FINAL_REPORT_NOTICE">Final report notice</b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
        </b-col>
          <b-col cols="2">

          </b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="form-select w-full mt-2 text-xs paginate-details"
                         v-on:change="fetch">
            <b-select-option value="12">12</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto mt-3">
              <p class="paginate-details text-center text-xs font-bold">
                <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                <span v-if="limit > submissions.length ">{{ pagination.totalElements }}</span>
                <span v-else>{{ currentPage * limit }}</span>
                of {{ pagination.totalElements }}
              </p>
            </b-col>
          </b-row>
        </b-col>



      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">

          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">#</th>
              <th class="t-header">Tracking No.</th>
              <th class="t-header">Type</th>
              <th class="t-header text-center">Status</th>
              <th class="t-header text-center">Assignment</th>
              <th class="t-header text-center">Action</th>
              <th class="t-header text-center">Date</th>
            </tr>
            </thead>

            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
              <td class="t-body">{{ (currentPage - 1) * limit + idx + 1 }}</td>
              <td class="t-body text-blue font-bold cursor-pointer hover:underline"
                  @click="showDetails(submission.trackingNumber)">{{ submission.trackingNumber}}
              </td>
              <td class="t-body font-bold">{{cleanUnderscores(submission.type)}}
                  <span v-if="submission.type==='WINDING_UP'">[{{submission.windingUpType}}]</span>
              </td>
              <td class="t-body">
                <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                <span :class="(submission.status.toUpperCase() === 'DRAFT') ? 'text-gray-light' :
                              (submission.status.toUpperCase() === 'PAYMENT_FAILED') ? 'text-red' :
                              (submission.status.toUpperCase() === 'AWAITING_PAYMENT') ? 'text-purple' :
                              (submission.status.toUpperCase() === 'SUBMITTED') ? 'text-gray' :
                              (submission.status.toUpperCase() === 'ASSIGNED')? 'text-blue':
                              (submission.status.toUpperCase() === 'APPROVED') ? 'text-green' :
                              (submission.status.toUpperCase() === 'QUERIED') ? 'text-orange' : ''">
                  <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT'">
                      Confirming Payment
                  </span>
                  <span v-else-if="submission.status.toUpperCase() === 'SUBMITTED'">
                      In Queue
                    </span>
                  <span v-else>
                    {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                  </span>
                </span>
                </p>
              </td>
              <td class="t-body text-center text-capitalize">
                <span class="text-xs" v-if="submission.registrarName">{{ submission.registrarName.toLowerCase()}}</span>
                <span class="text-xs" v-else>Not Assigned</span>
              </td>
              <td class="t-body text-center">
                <button
                  v-if="submission.status.toUpperCase() === 'ESCALATED' && canViewActionWithPermission('can_assign_insolvency_application')"
                  class="bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'SUBMITTED' && canViewActionWithPermission('can_assign_insolvency_application')"
                  class="bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'ASSIGNED' && canViewActionWithPermission('can_assign_insolvency_application')"
                  class="bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-redo-alt mx-1"></i>Assign
                </button>

                  <button
                          v-else-if="submission.status.toUpperCase() === 'DRAFT' && adminCanResumeDraft(submission.applicant.nin)"
                          class="bg-gray text-white px-2 py-1 rounded-sm" @click="resumeDraft(submission)">
                      <i class="fa fa-edit mx-1"></i>Continue
                  </button>

                  <span class="text-green" v-if="submission.status.toUpperCase() === 'APPROVED'">
                      <button
                              v-if="submission.status === 'APPROVED'"
                              @click="issueConfirmation(submission.trackingNumber,idx)"
                              type="button"
                              class="draft-btn mx-1  w-6/12">
                        <i class="fa fa-upload"></i> Issue Confirmation
                          <b-spinner v-if="loadingIndex===idx" small></b-spinner>
                      </button>

                  </span>
                  <span >
                       <button
                               v-if="canViewActionWithPermission('can_remove_appointed_practitioner') && (submission.status !== 'APPROVED' && submission.status !== 'DRAFT')"
                               @click="showTransferForm(submission)"
                               type="button"
                               class="bg-red rounded text-white mx-1 py-1  w-6/12">
                        <i class="fa fa-user-lock"></i> Change Practitioner
                          <b-spinner v-if="loadingIndex===idx" small></b-spinner>
                      </button>
                      <button
                              v-if="canViewActionWithPermission('can_abort_insolvency_application') && (submission.status !== 'APPROVED' && submission.status !== 'DRAFT')"
                              @click="abortApplication(submission)"
                              type="button"
                              class="bg-orange rounded text-white mx-1 my-2  w-6/12">
                        <i class="fa fa-user-alt-slash"></i> Abort
                          <b-spinner v-if="loadingIndex===idx" small></b-spinner>
                      </button>

                  </span>

              </td>
              <td class="t-body text-center">
                {{ dateTimeFormat(submission.createdAt) }}
              </td>
            </tr>
            <tr>
              <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            <tr>
              <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                <p class="text-sm">Failed to load tasks, Try again later.</p>
                <p class="text-sm">{{ errorMessage }}</p>
                <p class="text-sm">
                  <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload
                    <b-spinner v-if="loading" small></b-spinner>
                  </button>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{ currentPage }}/{{ pagination.totalPages }}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1">
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
            Next
          </button>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details" v-on:change="fetch">
            <b-select-option value="12">12</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto">
              <p class="paginate-details text-center text-xs font-bold">
                <span v-if="currentPage === 1">1</span>
                <span v-else>{{ ((currentPage - 1) * limit) + 1 }}</span> - {{ currentPage * limit }} of
                {{ pagination.totalElements }}
              </p>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Assignment from "@/views/home/commons/Assignment.vue";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";
import jwtService from "@/app/services/jwt.service";
import router from "@/router";
import ReAssignInsolvencyApplication
    from "@/views/home/services/insolvency/submissions/ReAssignInsolvencyApplication.vue";

export default {
  name: "AllForeignCessationSubmissions",
  components: {ReAssignInsolvencyApplication, Assignment },
  data() {
    return {
      loading: false,
      loadingMoveTask: false,
      loadingRevertTask: false,
      currentPage: 1,
      limit: 12,
      submissions: [],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      statuses: null,
      taskId: null,
      level:null,
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
      search: "",
      application: {},
      application_prn: null,
      hasError: false,
        errorMessage: null,
        loadingIndex: null,
        trackingNumber: null
    };
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchInsolvency", "fetchAllInsolvency", "fetchInsolvencyStatistics"]),
    fetch() {
      let options = this.statuses;

      if (this.search !== "") {
        this.currentPage = 1;
      }
      let data = {
        status: options,
        search: this.search,
        page: this.currentPage,
        limit: this.limit,
          type:"FOREIGN_COMPANY_DE_REGISTRATION"
      };
      this.loading = true;

        let url = Object.entries(data)
            .filter(([ , value]) => value !== null && value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        url = `?${url}`;
      this.fetchAllInsolvency(url);

    },
    assignPrompt(data) {
      this.taskId = data.id;
      this.$refs.assignSubmissions.show();
    },
    showDetails(trackingNumber) {
        this.$emit("openDetails", trackingNumber);
    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++;
        this.fetch();
      }
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetch();
      } else {
        this.warningToast("Observation", "No more data to show");
      }
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    },

      issueConfirmation(trackingNumber, index) {
          this.loadingIndex = index;
          api().post("/insolvency/process/issue-de-registration-confirmation", {trackingNumber: trackingNumber})
              .then(() => {
                  this.successToast("Success", "Confirmation issued successfully");
                    this.loadingIndex = null;
              })
              .catch((error) => {
                  this.errorToast("Error", error.response.data.message);
                    this.loadingIndex = null;
              });
      },
      adminCanResumeDraft(nin) {
        return jwtService.getUser().idNumber === nin;
      },
      resumeDraft(submission) {
          this.loading = true;
          sessionStorage.setItem("tracking-number", submission.trackingNumber);

             // sessionStorage.setItem('insolvencyType', this.returnWindingType(submission));
              router.push({name:"court-order-winding-up"})
      },
        showTransferForm(submission) {
           this.trackingNumber = submission.trackingNumber;
            this.$refs.transferApplication.show();
        },
      abortApplication(submission){

          this.showConfirmDialog("Are you sure that you want to abort this application?").then(value => {
              if (value) {
                  this.loading = true;
                  api().post("/insolvency/process/abort", {trackingNumber: submission.trackingNumber})
                      .then(() => {
                          this.successToast("Success", "Application aborted successfully");
                          this.fetch();
                      })
                      .catch((error) => {
                          this.errorToast("Error", error.response.data.message);
                      });
                  this.loading = false;
              }
          });


      }
  },
  computed: {
    ...mapGetters([
      "getInsolvencySubmissions",
      "getAssignmentSuccess", "getAssignmentError", "getInsolvencyError"]),
    hasAction() {
      return this.status === null || (this.status !== "QUERIED" && this.status !== "APPROVED");
    }
  },
  watch:{
    getInsolvencySubmissions(data){
      this.loading = false;
      this.submissions = data.content;
      this.pagination = {
        totalPages: data.totalPages,
        totalElements: data.totalElements
      };

    },
    getInsolvencyError(data){
      this.loading = false
      this.errorToast("Error", data.messages)
    }
  }
};
</script>

<style scoped>

.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

</style>