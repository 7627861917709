<template>
  <b-col>
    <b-row>
      <b-col cols="4" class="mt-4">
        <button class="bg-blue px-2 py-1 text-white text-sm rounded-sm" @click="sendDocument('REGISTRATION_CERTIFICATE')">
          <i class="fa fa-reply mx-1"></i> Resend Certificate <b-spinner v-if="resending" small></b-spinner>
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" class="mt-4">
        <table class="w-full leading-normal text-gray" style="border: solid 1px #dedede;">
          <thead class="bg-gray-pale text-sm">
          <tr class="bg-gray-pale">
            <th class="px-2 py-1">#</th>
            <th class="px-2 py-1">Document</th>
            <th class="px-2 py-1 text-center">EDMS</th>
            <th class="px-2 py-1 text-center">Date</th>
            <th class="px-2 py-1 text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="px-2 py-1" colspan="12">
              <b-form-input v-model="search" v-on:change="fetch" placeholder="Search Document Name" class="form-text text-xs"></b-form-input>
              <div class="mx-auto">
                <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
              </div>
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;"
              class="cursor-pointer text-gray"
              v-for="(document, idx) in documents"
              :key="idx">
            <td class="px-2 py-1">
                 <span class="text-xs">
                   {{(currentPage - 1) * limit + idx + 1}}
                 </span>
            </td>
            <td class="px-2 py-1 hover:underline hover:text-blue hover:font-bold">
                 <span class="text-xs text-capitalize" @click="view(document)">
                   {{cleanEntityType(document.documentName.toLowerCase())}}
                 </span>
            </td>
            <td class="px-2 py-1 text-center text-xs">
              <p v-if="document.edmsId">
                <i v-if="document.edmsId === '0'" class="text-gray_title fa fa-check" ></i>
                <i v-else class="text-green fa fa-check-double"></i>
              </p>
              <p v-else>
                <i class="text-red fa fa-times" ></i>
              </p>
            </td>
            <td class="px-2 py-1 text-xs text-center">
              <span>{{dateFormat(document.createdAt)}}</span>
            </td>
            <td class="px-2 py-1 text-xs text-center">
              <i class="fa fa-envelope text-blue" @click="resend(document, idx)"></i>
              <b-spinner class="mx-1" small v-if="resendingDoc && selectedIdx === idx"></b-spinner>
            </td>
          </tr>
          <tr v-if="documents.length === 0">
            <td colspan="12" class="bg-inertgrey text-center py-1">
              <span class="text-xs">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
          <b-col cols="2">
            <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
          </b-col>
          <b-col cols="2">
            <b-row>
              <b-col cols="12">
                <p class="paginate-details text-center">
                  <b-badge class="bg-blue text-white">
                    {{(currentPage)}}/{{pagination.totalPages}}
                  </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2" >
            <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
          </b-col>
          <b-col cols="2"></b-col>
          <b-col cols="4">
            <b-row>
              <b-col class="mx-auto">
                <p class="paginate-details text-center text-xs font-bold">
                  <span>{{((currentPage - 1) * limit)}}</span> -
                  <span v-if="limit > documents.length ">{{pagination.totalElements}}</span>
                  <span v-else>{{currentPage * limit}}</span>
                  of {{pagination.totalElements}}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8" class="mt-4">
        <b-overlay :show="loadingDocument" rounded="sm">
          <b-row>
            <b-col>
              <EDMSDocument class="w-full border border-gray" ref="edmsFile" :file="getEDMSFile"/>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "BusinessNameDocuments",
  components: {EDMSDocument},
  props:{
    brn:String
  },
  data(){
    return{
      loading: false,
      loadingDocument: false,
      resending:false,
      resendingDoc:false,
      selectedIdx:null,
      currentPage:1,
      limit:10,
      documents:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      search:""
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchBusinessNameDocuments", "fetchBusinessNameEDMSFile",
      "sendBusinessNameDocumentToClient", "sendBusinessNameCertificateToClient",
      "resendBusinessNameDocuments","fetchAllBusinessNameEDMSDocuments"]),
    fetch(){
      this.loading = true
      let data = {
        brn:this.brn,
        limit:this.limit,
        page:this.currentPage,
        search:this.search
      }
      this.fetchBusinessNameDocuments(data)
        this.pullBusinessNameEdmsDocuments(this.brn)
    },
      pullBusinessNameEdmsDocuments(brn){
          let data = {
              Keywords:{
                  NewRegistrationNo:brn
              }
          }
          this.loading = true
          this.fetchAllBusinessNameEDMSDocuments(data)
      },
    resend(document, idx){
      console.log(document)
      let data = {
        brn:this.brn,
        type:document.documentName
      }
      this.resendingDoc = true
      this.selectedIdx = idx
      this.resendBusinessNameDocuments(data)
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    view(document){
      if(document.edmsId){
        let data = {
          documentHandle: document.edmsId,
          overlay: ""
        }
        this.loadingDocument = true
        this.fetchBusinessNameEDMSFile(data)
        this.$refs.edmsFile.show();
      }else{
        this.errorToast("Document Error", "This document is not yet in EDMS")
      }
    },
    sendDocument(){
      let data = {
        brn: this.brn
      }
      this.resending = true
      this.sendBusinessNameCertificateToClient(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
  },
  computed:{
    ...mapGetters([
      "getBusinessNameDocuments",
      "getEDMSFile",
      "getSendDocumentSuccess",
      "getSendDocumentError",
      "getDocumentFetcherError",
      "getNetworkStatus",
      "getRegistryError",
      "getResendBusinessDocumentSuccess",
      "getResendBusinessDocumentError"])
  },
  watch:{
    getBusinessNameDocuments(data){
      this.loading = false
      this.documents = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getResendBusinessDocumentSuccess(){
      this.resendingDoc = false
      this.successToast("Success", "Document resent to client")
    },
    getResendBusinessDocumentError(data){
      this.resendingDoc = false
      this.errorToast("Error", data.message)
    },
    getEDMSFile(){
      this.loadingDocument = false
    },
    getNetworkStatus(){
      this.loadingDocument = false
      this.errorToast("Document Error", "Document loading times out")
    },
    getRegistryError(){
      this.loadingDocument = false
      this.errorToast("Error", "Failed to get document")
    },
    getSendDocumentSuccess(){
      this.resending = false
      this.loading = false
      this.successToast("Success", "Document resent")
    },
    getSendDocumentError(){
      this.resending = false
      this.loading = false
      this.errorToast("Error", "Failed to resend document")
    }
  }
}
</script>

<style scoped>
.button {
  @apply text-center rounded-sm bg-blue text-xs text-white border border-blue px-2 py-1;
  font-size: 14px;
}

.button:hover {
  @apply bg-blue-dark;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-xs text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-xs text-grey w-full;
}
</style>