<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Certification">
          <Escalation :task-id="taskId" type="certification" permission="can_view_certification_escalations" />
        </b-modal>
      </b-row>
      <b-row>
        <b-modal id="modal-query" size="lg" hide-footer ref="query-certification" title="Query Certification">
          <b-col cols="12">
            <b-form class="w-full">
              <b-form-textarea
                v-model="form.description"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
              </b-form-textarea>
              <b-row>
                <b-col cols="4">
                  <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                    Query
                    <b-spinner small v-if="loading"></b-spinner>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-modal>
        <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
          <b-row>
            <b-col>
              <p class="text-sm">
                <span class="h6">Are you sure you are ready to take this action?</span>.<br />
                This action can only be reversed by your superiors. Only confirm if you are certain that this is the
                appropriate Final Decision
              </p>
              <p class="mt-3 text-sm italic">
                <b>Note:</b><br />
                All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that
                initiated are tagged to the action.
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="4">
              <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full"
                      @click="cancelConfirmation">
                <i class="fa fa-minus-square text-blue mr-2"></i>Cancel
              </button>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark"
                      @click="yesConfirmation">
                <i class="fa fa-check-circle text-white mr-1"></i> Accept
              </button>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
      <b-row>
        <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
          <b-row>
            <b-col>
              <p class="text-sm">
                <span class="h6">Are you sure you are ready to take this action?</span>.<br />
                This action can only be reversed by your superiors. Only confirm if you are certain that this is the
                appropriate Final Decision
              </p>
              <p class="mt-3 text-sm italic">
                <b>Note:</b><br />
                All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that
                initiated are tagged to the action.

              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="4">
              <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full"
                      @click="cancelConfirmation">
                <i class="fa fa-minus-square text-blue mr-2"></i>Cancel
              </button>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark"
                      @click="yesConfirmation">
                <i class="fa fa-check-circle text-white mr-1"></i> Accept
              </button>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
      <b-row class="mx-1 mt-3">
        <b-col>
          <b-row class="mx-1" v-if="task.queried">
            <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
              <b-row>
                <b-col>
                  <div class="flex">
                    <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                    <span class="text-sm font-bold underline">{{task.queryMessages[0].user.firstName}} {{task.queryMessages[0].user.lastName}}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-sm">{{task.queryMessages[0].description}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span style="font-size:9px">{{dateFormat(task.queryMessages[0].updatedAt)}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Query Response</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span style="font-size: 30px; font-weight: bold">{{ task.entityName }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Application Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Search Reference</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;">
                  <td class="px-2 py-1">
                  <span class="text-gray">
                    {{ task.trackingNumber }}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue text-capitalize">{{ task.status.toLowerCase() }}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{ task.referenceNo }}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{ dateFormat(task.creationDate) }}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12" v-if="task.status !=='DRAFT'" >
              <PRNViewer :tracking-number="task.trackingNumber" />
            </b-col>
            <b-col cols="12"  v-if="task.status === 'PENDING' && task.prn === null">
              <b-alert show variant="info">
                <i class="fa fa-info-circle mx-1"></i> <span>Payment for this Certification is covered because it is from an MDA Account</span>
              </b-alert>
            </b-col>
            <b-col cols="12">
              <b-row v-if="task.user">
                <b-col cols="12">
                  <table class="min-w-full leading-normal mt-1 text-blue-dark" style="border: solid 1px #bdd7f1;">
                    <thead>
                    <tr class="bg-blue-pale">
                      <td colspan="12" class="px-2 py-2">Applicant Details</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1">Name</th>
                      <th class="px-2 py-1">Email</th>
                      <th class="px-2 py-1">Phone</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #bdd7f1;">
                      <td class="px-2 py-1 text-capitalize">{{ task.user.firstName }} {{ task.user.lastName }}</td>
                      <td class="px-2 py-1">{{ task.user.email }}</td>
                      <td class="px-2 py-1">{{ task.user.phoneNumber }}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <div class="flex float-end">
                <button class="btn-escalate" @click="escalatePrompt">
                  <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
                </button>
                <button type="button" class="btn-query" v-b-modal.modal-query>
                  <i class="fa fa-question text-white mr-1"></i> Query
                </button>
                <button type="button" class="btn-approve" @click="onApprove">
                  <i class="fa fa-check-circle text-white mr-1"></i> Approve
                </button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col cols="5">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Document</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">#</th>
                  <th class="px-2 py-1">Document</th>
                  <th class="px-2 py-1 text-center">Select</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" v-for="(document, idx) in task.documentDescription" :key="idx">
                  <td class="px-2 py-1">
                    {{ idx + 1 }}
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize cursor-pointer hover:text-blue hover:underline"
                          @click="view(document)">
                      {{ document.documentName.replaceAll("_", " ") }}
                    </span>
                  </td>
                  <td class="px-2 py-1 text-center">
                    <b-checkbox
                      :value="{ documentName: document.documentName, documentTypeId: document.documentTypeId, edmsId: document.edmsId }"
                      v-model="certifiable" />
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="7">
              <CertificationDocumentViewer ref="documentViewer" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Escalation from "@/views/home/commons/Escalation";
import moment from "moment/moment";
import PRNViewer from "@/views/home/commons/PRNViewer";
import CertificationDocumentViewer from "@/views/home/services/certification/task/CertificationDocumentViewer";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CertificationTask",
  components: { CertificationDocumentViewer, PRNViewer, Escalation },
  data() {
    return {
      margin: "2px",
      currentRoute: "application-details",
      loading: false,
      documentUrl: null,
      messages: [],
      taskId: null,
      certifiable: [],
      form: {
        queueTaskId: null,
        userId: null,
        description: null,
        status: null
      },
      task: {}
    };
  },
  mounted() {
    let id = JSON.parse(sessionStorage.getItem("current_user")).userId;
    this.fetchCertificationTask(id);
  },
  methods: {
    ...mapActions(["fetchCertificationTask", "fetchCertificationTaskMessages", "updateCertificationStatus", "queryCertification", "fetchEdmsFileById"]),
    onApprove() {
      this.form.queueTaskId = this.task.taskId;
      this.form.status = "APPROVED";
      this.action = "final";
      this.$refs.confirm.show();
    },
    onQuery() {
      this.form.queueTaskId = this.task.taskId;
      this.form.status = "QUERIED";
      this.action = "queried";
      if (this.form.description != null) {
        this.$refs.confirm.show();
      } else {
        this.errorToast("Missing Information", "Provide a message for this final decision");
      }
    },
    select(route) {
      this.currentRoute = route;
    },
    yesConfirmation() {
      if (this.action === "final") {
        this.loading = true;
        this.form.userId = JSON.parse(sessionStorage.getItem("current_user")).userId;
        this.form.queueTaskId = this.taskId;
        this.updateCertificationStatus({ ...this.form, ...{ documents: this.certifiable } });
      }

      if (this.action === "queried") {
        this.loading = true;
        this.form.userId = JSON.parse(sessionStorage.getItem("current_user")).userId;
        this.form.queueTaskId = this.taskId;
        let list = [];
        this.certifiable.forEach(document => {
          list.push(document.edmsId);
        });
        this.queryCertification({
          queryMessage: this.form.description,
          queueTaskId: this.form.queueTaskId,
          userId: this.form.userId,
          documentEdmsIds: list
        });
      }

      if (this.action === "escalate") {
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation();
    },
    cancelConfirmation() {
      this.action = null;
      this.$refs.confirm.hide();
    },
    escalatePrompt() {
      this.action = "escalate";
      this.$refs.escalateSubmissions.show();
    },
    cleanEntityType(name) {
      let n = name.replaceAll("-", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    view(document) {
      this.$refs.documentViewer.openFile(document);
    }
  },
  computed: {
    ...mapGetters([
      "getCertificationTask",
      "getCertificationUpdateStatusSuccess",
      "getCertificationUpdateStatusError",
      "getCertificationEscalationActionSuccess",
      "getCertificationEscalationActionError",
      "getCertificationUpdateStatusError",
      "getCertificationQuery",
      "getCertificationQueryError"
    ])
  },
  watch: {
    getCertificationTask(data) {
      this.task = data.data;
      this.taskId = data.data.taskId;
    },
    getCertificationUpdateStatusSuccess() {
      this.loading = false;
      this.successToast("Success", "Certification processed successfully");
      window.location.reload();
    },
    getCertificationQuery() {
      this.loading = false;
      this.successToast("Success", "Certification queried successfully");
      window.location.reload();
    },
    getCertificationQueryError(response) {
      this.loading = false;
      this.errorToast("Error", response.data.message);
    },
    getCertificationUpdateStatusError(response) {
      this.loading = false;
      this.errorToast("Error", response.data.message);
    },
    getCertificationEscalationActionSuccess() {
      this.successToast("Success", "Certification escalated successfully");
      this.$refs.escalateSubmissions.hide();
      window.location.reload();

    },
    getCertificationEscalationActionError() {
      this.errorToast("Error", "Certification escalated failed");
      this.$refs.escalateSubmissions.hide();
    }
  }
};
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.icon {
  width: 170px;
}

th, td {
  font-size: 12px;
}

.btn-escalate {
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover {
  @apply text-blue-dark;
}

.btn-approve {
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query {
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover {
  @apply bg-green border-green text-white;
}

.btn-query:hover {
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover {
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover {
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover {
  @apply bg-green-dark;
}

.btn-query-hover:hover {
  @apply bg-orange-dark;
}
</style>