<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Incorporation">
        <Escalation :task-id="practitioner.id"  type="insolvency" permission="can_view_insolvency_escalations"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.

            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-reject" hide-footer ref="reject-incorporation" title="Reject Submission">
        <b-col cols="12">
          <b-form class="w-full">
              <b-row>
                  <b-col>
                      <b-form-select
                              class="form-select text-sm noFocus text-gray"
                              v-model="StandardGround"
                              :options="responses">
                      </b-form-select>
                  </b-col>
              </b-row>

            <b-form-textarea
                v-model="form.description"
                type="text"
                placeholder="Give description of rejection"
                class="mt-1"
                required>
            </b-form-textarea>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-reject-hover" variant="primary" @click="onReject">
                  Reject
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
              <b-row>
                  <b-col>
                      <b-form-select
                              class="form-select text-sm noFocus text-gray"
                              v-model="StandardGround"
                              :options="responses">
                      </b-form-select>
                  </b-col>
              </b-row>

            <b-form-textarea
                v-model="form.description"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
            </b-form-textarea>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
      <b-row>
          <b-modal id="modal-certificate" hide-footer ref="previewCertificate" size="xl" title="Preview Certificate">
          <certificate-preview :pdf-url="pdfUrl" ref="certPreview" ></certificate-preview>
          </b-modal>
      </b-row>

   <b-row>
      <b-col cols="12" class="mx-auto my-3">
        <b-row class="mx-3">
          <b-col>
            <b-row>
              <b-col cols="2">
                  <img v-if="containsPassportPhoto" :src="dpUrl" class="rounded-full w-100 border-2 border-blue"/>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-3">
                  <b-row>
                      <b-col>
                          <span class="font-bold text-gray h4 mr-2 text-capitalize">{{ practitioner.surName }} {{ practitioner.givenName }} &nbsp; {{ practitioner.middleName }}</span><br/>
                          <span class="text-gray text-sm text-capitalize italic">{{ practitioner.phoneNumber }}</span><br/>
                          <span class="text-gray text-sm text-lowercase italic">{{ practitioner.emailAddress }}</span><br/>

                      </b-col>
                      <b-col>

                         <label class="text-blue fw-bold text-sm float-end">  #{{ cleanUnderscores(practitioner.type ? practitioner.type : "QUEUE") }}</label>
                      </b-col>
                  </b-row>
                  <b-row class="mt-3">
                  <b-col>
                    <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2">Application Details</td>
                      </tr>
                      <tr>
                        <th class="px-2 py-1">Tracking Number</th>
                        <th class="px-2 py-1">Status</th>
                        <th class="px-2 py-1">Application Type</th>
                        <th class="px-2 py-1">Submission Date</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;" >
                        <td class="px-2 py-1">
                          <span class="text-gray">
                            {{practitioner.trackingNumber}}
                          </span>
                        </td>
                        <td class="px-2 py-1">
                          <span class="text-blue text-capitalize" v-if="practitioner.status">{{practitioner.status.toLowerCase()}}</span>
                        </td>
                        <td class="px-2 py-1">
                          <span class="text-gray text-capitalize">
                              {{cleanUnderscores(practitioner.type)}}
                          </span>
                        </td>
                        <td class="px-2 py-1">
                          <span class="text-gray italic">
                           {{practitioner.updatedAt}}
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>

                </b-row>
                <b-row class="my-3">
                  <b-col cols="6"></b-col>
                  <b-col cols="6">
                    <div class="flex float-end">
                      <button class="btn-escalate" @click="escalatePrompt">
                        <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
                      </button>
                      <button type="button" class="btn-reject" v-b-modal.modal-reject>
                        <i class="fa fa-ban text-white mr-1"></i> Reject
                      </button>
                      <button type="button" class="btn-query" v-b-modal.modal-query>
                        <i class="fa fa-question text-white mr-1"></i> Query
                      </button>
                      <button type="button" class="btn-approve" @click="onApprove">
                        <i class="fa fa-check-circle text-white mr-1"></i> Approve
                      </button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <PractitionerAnnualReturnsDetails
                      :data="practitioner"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import server from "../../../../../app/env/server.json"
import Escalation from "@/views/home/commons/Escalation";
import moment from "moment/moment";
import CertificatePreview from "@/views/home/services/insolvency/practitioners/CertificatePreview.vue";
import PractitionerAnnualReturnsDetails
    from "@/views/home/services/registry/practitioners/PractitionerAnnualReturnsDetails.vue";

export default {
  name: "PractitionerAnnualReturnsTask",
  components: {
      PractitionerAnnualReturnsDetails,
      CertificatePreview, Escalation
  },
    props:{
        task:{}
    },
  data(){
    return{
      hasComments: false,
      currentRoute: "name-search",
      selectedId:null,
      margin:"2px",
      hasContent:false,
      nameIdMap:new Map(),
      loading:false,
      isQueryResponse:false,
      message:null,
      taskId:null,
      practitioner:{},
      form:{
        description:null,
          insolvencyProcessId: null,
        status:null
      },
        StandardGround:null,
      action:null,
        dpUrl: "",
        containsPassportPhoto: false,
        pdfUrl: "",
        responses :[
            {
                text:"Not allowed",
                value :"Not allowed"
            }
        ]
    }
  },
  mounted() {
          let type = "ANNUAL_RETURNS"

          this.fetchInsolvencyPractitionerTask(type)
          this.fetchPractitionersQueueCount()
  },
  methods:{
    ...mapActions(["fetchInsolvencyPractitionerTask", "fetchPractitionersQueueCount", "updatePractitionerTaskStatus"]),
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updatePractitionerTaskStatus(this.form)
      }
      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    onApprove(){
        this.form.insolvencyProcessId = this.practitioner.id
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onQuery(){
        this.form.insolvencyProcessId = this.practitioner.id
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    onReject(){
        this.form.insolvencyProcessId = this.practitioner.id
      this.form.status = "REJECTED"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.escalateSubmissions.show();
      //this.warningToast("Feature Update", "Under construction")
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanString(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },

  },
  computed:{
    ...mapGetters(["getPractitionerTask", "getPractitionerCount", "getUpdatedPractitionerTaskStatus","getInsolvencyTaskError"]),
    form1s() {
      let list = [];

      if (this.practitioner.form1AttachmentList !== undefined) {
        list.push(this.practitioner);
      }
      return list;
    }
  },
  watch:{
     // deep watch task prop
    task: {
        handler: function (val) {
            if (val) {
                alert(JSON.stringify(val))
                //this.getPractitionerTask(val)
            }
        },
        deep: true
    },
    getPractitionerTask(data){

      this.practitioner = data
        this.practitioner.attachments.forEach(attachment =>{
            if (attachment.fileDescription === "PASSPORT_PHOTO") {
                this.containsPassportPhoto = true
                this.dpUrl = server.IP+'/insolvency/files?attachment='+attachment.fileName
            }
        });

    },
    getUpdatedPractitionerTaskStatus(){
      this.successToast("Success", "Final Decision Successfully taken")
      window.location.reload()
    },
      getInsolvencyTaskError(error){
          if(error) {
              this.errorToast("Error", error.data.message)
          }
      }
  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-certificate{
    @apply border border-green rounded-sm text-green text-sm px-3 py-1 mx-1 bg-white;
}

.btn-certificate:hover{
    @apply text-green-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-reject{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}
</style>