import {api} from "@/app/services/api.service";

const state = {
    message: null,
    messageError: null,
    searchResults: [],
    filters: {
    },
};

const getters = {
    getSearchResults: (state) => state.searchResults,
    getSearchError: (state) => state.message,
    getFilters: (state) => state.filters,
    getMessageError: (state) => state.messageError,
    getFilterError: (state) => state.message,
};

const actions = {
    async doSearch({commit}, filters) {
        await api().get("/namesearch/search"+filters)
            .then((response) => {
                commit("setSearchResults", response);
            })
            .catch((error) => {
                commit("setSearchError", error.response);
            });
    },
    async doGetFilters({commit},) {
        await api().get("/namesearch/filters")
            .then((response) => {
                commit("setFilters", response);
            })
            .catch((error) => {
                commit("setFiltersError", error.response);
            });
    },

};

const mutations = {
    setSearchResults: (state, response) => {
        state.searchResults = response.data;
    },
    setSearchError: (state, response) => {
        state.messageError = response.data;
    },setFilters: (state, response) => {
        state.filters = response.data;
    },
    setFiltersError: (state, response) => {
        state.message = response.data.message;
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
