<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col>
          <div class="mx-auto mt-2">
            <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-2 mx-2" >
        <b-col cols="8" class="px-2 py-1 rounded" v-if="history.length > 0 && !loading">
          <b-row v-for="(filing, idx) in history" :key="idx" class="border border-gray bg-header_grey rounded-sm my-1 py-2">
            <b-col cols="10">
              <p class="text-sm">Annual returns for the year {{filing.year}}</p>
              <p v-if="filing.filingStatus === 'FILED'" class="text-xs text-gray italic">Filing date: {{dateFormat(filing.dateOfFiling)}}</p>
            </b-col>
            <b-col cols="2">
              <b-badge :class="statusClass(filing.filingStatus)">{{cleanUnderscores(filing.filingStatus)}}</b-badge>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="px-2 py-1 bg-headed_grey rounded" v-else-if="history.length === 0 && !loading">
          <b-alert variant="danger" show>
            <i class="mx-1 fa fa-info-circle"></i> No annual returns information available
          </b-alert>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "AnnualReturnsStatus",
  props:{
    brn:String
  },
  data(){
    return{
      userProfile:{},
      loading:true,
      currentPage:1,
      history:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
    }
  },
  mounted(){
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchRegistryCompanyAnnualReturns"]),
    fetch(){
      this.fetchRegistryCompanyAnnualReturns(this.brn)
    },
    statusClass(status){
      if(status === "FILED"){
        return "bg-green w-full mt-2"
      }
      if(status === "NOT_FILED" || status === "NOT_READY"){
        return "bg-header_grey text-gray w-full mt-2"
      }
      if(status === "IN_PROCESS"){
        return "bg-blue text-xs w-full mt-2"
      }
      if(status === "READY_FOR_FILING"){
        return "bg-orange w-full mt-2"
      }
      if(status === "OVERDUE"){
        return "bg-red w-full mt-2"
      }
    },
    dateFormat(date){
      if(date){
        return moment(date).format("DD/MM/YYYY")
      }
      return "None given"
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
  },
  computed:{
    ...mapGetters(["getCompanyAnnualReturnsStatus", "getBusinessDetailError"])
  },
  watch:{
    getCompanyAnnualReturnsStatus(data){
      this.loading = false
      this.history = data.content
    },
    getBusinessDetailError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }

}
</script>

<style scoped>
.circle {
  position: absolute;
  transform: translate(-50%, -35%);
  width: 25px;
  height: 25px;
  background-color: #157EC2;
  border-radius: 100%;
}
.line {
  position: absolute;
  transform: translate(-50%, -15%);
  width: 2px;
  height: 120px;
  background-color: #157EC2;
}
</style>