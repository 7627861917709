<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col class="py-3">
          <b-row>
            <b-col cols="2">
              <b-form-input v-model="filter.startDate" type="date" class="text-sm"></b-form-input>
            </b-col>
            <b-col cols="1" class="text-center">
              <p class="mt-1">
                - to -
              </p>
            </b-col>
            <b-col cols="2">
              <b-form-input v-model="filter.endDate" type="date" class="text-sm"></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-form-select v-model="filter.groupBy" class="form-select text-sm">
                <b-select-option value="STATUS">By Status</b-select-option>
                <!-- <b-select-option value="HOUR">By Hour</b-select-option> -->
                <b-select-option value="DAY">By Day</b-select-option>
                <b-select-option value="MONTH">By Month</b-select-option>
                <b-select-option value="YEAR">By Year</b-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-form-select v-model="filter.registrarId" class="form-select text-sm">
                <b-select-option :value="null">All Registrars</b-select-option>
                <b-select-option v-for="registrar in registrars" :key="registrar.id" :value="registrar.userId">
                  {{ fullName(registrar) }}
                </b-select-option>
              </b-form-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="w-full grid grid-cols-1 lg:grid-cols-5">
        <div class="count" v-for="(count, idx) in aggregatedData" :key="idx">
          <span class="text-center count-number">{{ count.total.toLocaleString() }}</span>
          <p class="count-text capitalize">{{ count.title }}</p>
        </div>
      </div>
      <b-row>
        <b-col class="pt-4">
          <LineChart v-if="filter.groupBy === 'STATUS'" chart-id="dispensation-activity" :chart-data="chartData"
            :options="chartOptions" />
          <HorizontalBarChart v-else chart-id="dispensation-activity" :chart-data="stackedChartData"
            :options="stackedChartOptions" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { api } from "@/app/services/api.service"
import { mapGetters } from "vuex"
import LineChart from "@/views/home/utils/charts/LineChart"
import HorizontalBarChart from "@/views/home/utils/charts/HorizontalBarChart"
import moment from "moment"

export default {
  name: "DispensationActivity",
  components: { LineChart, HorizontalBarChart },
  data() {
    return {
      apiResponse: null,
      apiResponseError: null,
      registrars: [],
      registrarErrors: null,
      loading: false,
      filter: {
        groupBy: "STATUS",
        registrarId: null,
        startDate: null,
        endDate: null
      },
      chartLabels: [
        "ADDED_PAYMENT",
        "APPROVED",
        "ARCHIVED",
        "ASSIGNED",
        "CANCELED",
        "DELETED",
        "DRAFT",
        "ESCALATED",
        "PENDING",
        "QUERIED"
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        legend: {
          display: false
        }
      },
      stackedChartOptions: {
        maxBarThickness: 8,
        legend: {
          display: true,
          labels: {
            boxWidth: 20,
            fontSize: 11,
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true
          }]
        }
      },
      colors: [
        "#6929c4", "#9f1853", "#198038", "#fa4d56", "#b28600",
        "#8a3800", "#1192e8", "#002d9c", "#009d9a", "#a56eff",
        "#005d5d", "#570408", "#ee538b", "#012749", "#ff832b"
      ]
    }
  },
  mounted() {
    this.filter.startDate = moment().format("YYYY-MM-DD")
    this.filter.endDate = moment().format("YYYY-MM-DD")
    this.fetchRegistrars()
  },
  methods: {
    async fetchReport() {
      await api()
        .get(`/incorporation/dispense/statistics/status-changes${this.queryParams}`)
        .then(response => {
          this.apiResponse = response.data
        })
        .catch(error => {
          this.apiResponseError = error.response
        })
    },
    async fetchRegistrars() {
      await api()
        .get("/auth/api/users/staff?page=1&perPage=100")
        .then(response => {
          this.registrars = response.data.data.content
        })
        .catch(error => {
          this.registrarErrors = error.response
        })
    },
    fullName(registrar) {
      let name = ""

      name += registrar.surName.toUpperCase()
      name += registrar.middleName !== null && registrar.middleName.length > 0 ? " " + registrar.middleName : ""
      name += " " + registrar.givenName.toUpperCase()

      return name
    },
    formatLabelDate(rawDate) {
      return moment(rawDate).isValid ? moment(rawDate).format("Do MMM, YYYY") : ""
    },
    formatLabel(rawLabel, uppercase = false) {
      let label = String(rawLabel)
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, " ")
        .replace(/^ +| +$/g, "")

      return uppercase ? label.toUpperCase() : label.toLowerCase()
    },
    capitalize(string) {
      const str_arr = string.split(' ')
      for (let i = 0; i < str_arr.length; i++) {
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1)
      }
      return str_arr.join(' ')
    },
    chartUnitDatasetData(unit) {
      let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      for (let index = 0; index < this.chartLabels.length; index++) {
        const label = this.chartLabels[index]
        if (unit !== null && label in unit) {
          data[index] = unit[label]
        }
      }
      return data
    }
  },
  computed: {
    ...mapGetters(["getRegistrars"]),
    queryParams() {
      let query = ""

      if (typeof this.filter.groupBy === "string" && this.filter.groupBy.length > 0) {
        query += query.length > 0 ? `&groupBy=${this.filter.groupBy}` : `?groupBy=${this.filter.groupBy}`
      }

      if (typeof this.filter.registrarId === "string" && this.filter.registrarId.length > 0) {
        query += query.length > 0 ? `&registrarId=${this.filter.registrarId}` : `?registrarId=${this.filter.registrarId}`
      }

      if (typeof this.filter.startDate === "string" && this.filter.startDate.length > 0) {
        query += query.length > 0 ? `&startDate=${this.filter.startDate} 00:00:00` : `?startDate=${this.filter.startDate} 00:00:00`
      }

      if (typeof this.filter.endDate === "string" && this.filter.endDate.length > 0) {
        query += query.length > 0 ? `&endDate=${this.filter.endDate} 23:59:59` : `?endDate=${this.filter.endDate} 23:59:59`
      }

      return query
    },
    chartData() {
      if (this.filter.groupBy === "DAY") {
        let datasets = []

        for (const day in this.apiResponse) {
          if (
            typeof this.apiResponse[day] === 'object' &&
            !Array.isArray(this.apiResponse[day]) &&
            this.apiResponse[day] !== null
          ) {
            datasets.push({
              label: moment().date(day).format("Do"),
              data: this.chartUnitDatasetData(this.apiResponse[day]),
              backgroundColor: this.colors[datasets.length],
              borderColor: this.colors[datasets.length],
              fill: false,
              tension: 0
            })
          }
        }

        return {
          labels: this.chartLabels.map((label) => this.capitalize(this.formatLabel(label))),
          datasets: datasets,
        }
      } else if (this.filter.groupBy === "MONTH") {
        let datasets = []

        for (const month in this.apiResponse) {
          if (
            typeof this.apiResponse[month] === 'object' &&
            !Array.isArray(this.apiResponse[month]) &&
            this.apiResponse[month] !== null
          ) {
            datasets.push({
              label: moment().month(month - 1).format("MMMM"),
              data: this.chartUnitDatasetData(this.apiResponse[month]),
              backgroundColor: this.colors[datasets.length],
              borderColor: this.colors[datasets.length],
              fill: false,
              tension: 0
            })
          }
        }

        return {
          labels: this.chartLabels.map((label) => this.capitalize(this.formatLabel(label))),
          datasets: datasets,
        }
      } else if (this.filter.groupBy === "YEAR") {
        let datasets = []

        for (const year in this.apiResponse) {
          if (
            typeof this.apiResponse[year] === 'object' &&
            !Array.isArray(this.apiResponse[year]) &&
            this.apiResponse[year] !== null
          ) {
            datasets.push({
              label: String(year),
              data: this.chartUnitDatasetData(this.apiResponse[year]),
              backgroundColor: this.colors[datasets.length],
              borderColor: this.colors[datasets.length],
              fill: false,
              tension: 0
            })
          }
        }

        return {
          labels: this.chartLabels.map((label) => this.capitalize(this.formatLabel(label))),
          datasets: datasets,
        }
      }

      return {
        labels: this.chartLabels.map((label) => this.capitalize(this.formatLabel(label))),
        datasets: [
          {
            label: "",
            backgroundColor: '#157EC2',
            data: this.chartDatasetData,
            tension: 0
          }
        ]
      }
    },
    stackedChartData() {
      return {
        labels: this.stackedChartLabels,
        datasets: this.stackedChartDatasets,
      }
    },
    chartDatasetData() {
      let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      for (let index = 0; index < this.chartLabels.length; index++) {
        const label = this.chartLabels[index]
        if (this.apiResponse !== null && label in this.apiResponse) {
          data[index] = this.apiResponse[label]
        }
      }
      return data
    },
    aggregatedData() {

      let data = []

      this.chartLabels.forEach((label) => {
        if (this.filter.groupBy === "STATUS") {
          if (this.apiResponse !== null && label in this.apiResponse) {
            data.push({
              title: this.formatLabel(label),
              total: this.apiResponse[label]
            })
          } else {
            data.push({
              title: this.formatLabel(label),
              total: 0
            })
          }
        } else if (this.filter.groupBy === "DAY" || this.filter.groupBy === "MONTH" || this.filter.groupBy === "YEAR") {
          // set a baseline value for label
          let total = 0

          // go through each unit
          for (const unit in this.apiResponse) {
            if (
              typeof this.apiResponse[unit] === 'object' &&
              !Array.isArray(this.apiResponse[unit]) &&
              this.apiResponse[unit] !== null
            ) {
              total += isNaN(this.apiResponse[unit][label]) ? 0 : this.apiResponse[unit][label]
            }
          }

          // push final
          data.push({
            title: this.formatLabel(label),
            total: total
          })
        } else {
          data.push({
            title: this.formatLabel(label),
            total: 0
          })
        }
      })

      return data
    },
    stackedChartLabels() {
      let labels = []

      if (this.filter.groupBy === "DAY") {
        Object.keys(this.apiResponse).reverse().forEach(day => {
          labels.push(moment(day).format("DD-MM-YYYY"))
        })
      }

      if (this.filter.groupBy === "MONTH") {
        Object.keys(this.apiResponse).reverse().forEach(month => {
          labels.push(moment(month).format("MMM, YYYY"))
        })
      }

      if (this.filter.groupBy === "YEAR") {
        for (const year in this.apiResponse) {
          labels.push(String(year))
        }
      }

      return labels
    },
    stackedChartDatasets() {
      let datasets = []

      if (this.filter.groupBy !== "STATUS") {
        // loop through the statuses (m in statuses)
        this.chartLabels.forEach((status, idx) => {
          // initialise dataset
          let data = []

          // loop through the entries (k in apiResponse)
          Object.keys(this.apiResponse).reverse().forEach((entry) => {
            if (Object.hasOwnProperty.call(this.apiResponse[entry], status)) {
              data.push(this.apiResponse[entry][status])
            } else {
              data.push(0)
            }
          })

          //  set data for entry
          datasets.push({
            label: this.capitalize(this.formatLabel(status)),
            backgroundColor: this.colors[idx],
            data: data,
          })
        })
      }

      return datasets
    }
  },
  watch: {
    apiResponseError(data) {
      console.log(data)
    },
    filter: {
      handler() {
        this.fetchReport()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>