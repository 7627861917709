<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Change of Name
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Name Change Details</td>
                </tr>
                </thead>
                <tr>
                  <th class="px-2 py-1">Current Name</th>
                  <th class="px-2 py-1">Desired Name</th>
                  <th class="px-2 py-1">Reservation Number</th>
                </tr>
                <tbody>
                <tr style="border: solid 1px #dedede;">
                  <td class="px-2 py-1 text-capitalize">{{data.oldName.name}}</td>
                  <td class="px-2 py-1 text-capitalize">{{data.newName.name}}</td>
                  <td class="px-2 py-1 text-capitalize">{{data.newName.no}}</td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <b-alert variant="success" show>
                <b-badge class="bg-green">Reservation: {{data.newName.no}}</b-badge>
                <b-badge class="bg-blue mx-1">{{cleanEntityType(data.newName.type)}}</b-badge>
                <p class="text-sm mt-1 text-capitalize">
                  Name: <b>{{data.newName.name}}</b><br/>
                  Entity Category: <b>{{cleanEntityType(data.newName.subType)}}</b><br/>
                  Nature of Business: <b>{{data.newName.isic.description}}</b><br/>
                  ISIC Code: <b>{{data.newName.isic.code}}</b><br/>
                  Reserved on: <b>{{dateFormat(data.newName.createdAt)}}</b><br/>
                  Expires on: <b>{{dateFormat(data.newName.expiresOn)}}</b><br/>
                </p>
              </b-alert>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import moment from "moment/moment";

export default {
  name: "BusinessNameChange",
  props:{
    data:Object,
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>