<template>
  <div>
    <b-row>
      <b-col>
        <b-card :header="badge">
          <b-row>
            <b-col>
                <span class="h6">PARTICULARS OF REPRESENTATIVES</span>
            </b-col>
          </b-row>
          <b-row v-for="(d, idx) in data" :key="idx">
            <b-col>
              <b-row v-if="d.individual">
                <b-col>
                  <b-row>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Given Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.givenName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.individual.middleName"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Middle Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.middleName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Surname"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.surName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="ID Type"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          :disabled="true"
                          :value="d.individual.idType.toLowerCase()"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="ID Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.idNumber"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Nationality"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.nationality"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Email Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.email"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Phone Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.phoneNumber"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Gender (at birth)"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          :disabled="true"
                          :value="d.individual.sex.toLowerCase()"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Date of Birth"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="dateFormat(d.individual.dateOfBirth)"
                          required>
                        </b-form-input>
                        <b-form-invalid-feedback state="true">
                          {{d.individual.age}}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Occupation"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.occupation"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="d.individual.address.physicalAddress">
                      <b-form-group
                        v-if="d.individual.address.physicalAddress.district"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="District"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.physicalAddress.district.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.individual.address.physicalAddress.district"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Region"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.physicalAddress.region.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.individual.address.physicalAddress.subcounty"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Sub county"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.physicalAddress.subcounty.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.individual.address.physicalAddress.parish"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Parish"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.physicalAddress.parish.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.individual.address.physicalAddress.village"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Village"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.physicalAddress.village.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col v-else>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Foreign Address"
                        label-for="input-1">
                        <b-form-textarea
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          rows="4"
                          :disabled="true"
                          :value="d.individual.foreignPhysicalAddress"
                          required>
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        v-if="d.individual.address.postalAddress"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Postal Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.postalAddress"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.individual.address.street"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Street"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.street"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.individual.address.plot"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Plot"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.individual.address.plot"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col>
                  <b-row>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Given Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.givenName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.middleName"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Middle Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.middleName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Surname"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.surName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="ID Type"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          :disabled="true"
                          :value="d.idType.toLowerCase()"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="ID Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.idNumber"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Nationality"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.nationality"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Email Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.email"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Phone Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.phoneNumber"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Gender (at birth)"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          :disabled="true"
                          :value="d.sex.toLowerCase()"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Date of Birth"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="dateFormat(d.dateOfBirth)"
                          required>
                        </b-form-input>
                        <b-form-invalid-feedback state="true">
                          {{d.age}}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Occupation"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.occupation"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="d.address.physicalAddress">
                      <b-form-group
                        v-if="d.address.physicalAddress.district"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="District"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.physicalAddress.district.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.address.physicalAddress.district"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Region"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.physicalAddress.region.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.address.physicalAddress.subcounty"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Sub county"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.physicalAddress.subcounty.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.address.physicalAddress.parish"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Parish"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.physicalAddress.parish.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.address.physicalAddress.village"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Village"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.physicalAddress.village.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col v-else>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Foreign Address"
                        label-for="input-1">
                        <b-form-textarea
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          rows="4"
                          :disabled="true"
                          :value="d.foreignPhysicalAddress"
                          required>
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        v-if="d.address.postalAddress"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Postal Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.postalAddress"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.address.street"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Street"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.street"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="d.address.plot"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Plot"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          :disabled="true"
                          :value="d.address.plot"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Form25RepresentativesResolution",
  props:{
    data:{},
    badge:String
  },
  data(){
    return{
      form:{
        effectiveDateOfAppointment:null
      }
    }
  },
  mounted() {
    this.form.effectiveDateOfAppointment =  this.dateFormat(this.data.effectiveDateOfAppointment)
  },
  methods:{
    ...mapActions(["fetchCompanyDirectors"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getCompanyDirectors"])
  },
  watch:{
    draft(){
      this.checkDraftData()
    },
    form:{
      handler(data){
        this.$emit("directorsChange", {form:data})
      },
      deep:true
    },
  }
}
</script>

<style scoped>

</style>