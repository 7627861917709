<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Data Update">
        <EscalationDataUpdate :application-id="task.applicationId" :level="3" type="ci-data-update"
                              permission="can_view_company_data_update_escalations" />
      </b-modal>
      <b-modal id="modal-assign" hide-footer ref="deEscalateSubmissions" title="De Escalate Data Update">
        <DeEscalationDataUpdate :application-id="task.applicationId" :level="3" type="ci-data-update"
                              permission="can_view_company_data_update_escalations" />
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br />
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the
              appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br />
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that
              initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full"
                    @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel
            </button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept
            </button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-approve-confirm" hide-footer ref="approveConfirm" title="Final Confirmation">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Final Approval</span>.<br />
              This action is NON REVERSIBLE, only confirm if you are certain that the information provided is correct and
              accurate.
            </p>
            <span class="my-3">
            <label class="h6">Company Mandatory Filing Compliance Check</label>
            <b-row v-if="task.applicationResponse.subType.includes('foreign')">
              <b-col>
                <b-checkbox v-model="form.nonCompliance.form24">
                  <span class="px-1 text-sm">Form 24 Non Compliant</span>
                </b-checkbox>
                <b-checkbox v-model="form.nonCompliance.form25">
                  <span class="px-1 text-sm">Form 25 Non Compliant</span>
                </b-checkbox>
                <b-checkbox v-model="form.nonCompliance.form26">
                  <span class="px-1 text-sm">Form 26 Non Compliant</span>
                </b-checkbox>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col>
                <b-checkbox v-if="task.applicationResponse.subType === 'single-member'"
                            v-model="form.nonCompliance.form1">
              <span class="px-1 text-sm">Form 1 Non Compliant</span>
            </b-checkbox>
                <b-checkbox v-model="form.nonCompliance.form18">
                  <span class="px-1 text-sm">Form 18 Non Compliant</span>
                </b-checkbox>
                <b-checkbox v-model="form.nonCompliance.form20">
                  <span class="px-1 text-sm">Form 20 Non Compliant</span>
                </b-checkbox>
              </b-col>
            </b-row>
            <p class="mt-2 text-sm">Years Company has not Filed Annual Returns</p>
            <b-row class="my-2">
              <b-col cols="5">
                <b-form-select class="form-select text-sm"
                               v-model="annualReturnYear"
                               :options="years()">
                </b-form-select>
              </b-col>
              <b-col>
                <i class="fa fa-plus mt-2 hover:text-blue" @click="addAnnualReturnYear"></i>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-for="(year, idx) in annualReturnYears" :key="idx" cols="2">
                <div class="flex">
                  <span>{{ year }}</span>
                  <i class="fa fa-times text-red mx-2 mt-1 hover:text-red-dark"
                     @click="removeAnnualReturnYear(idx)"></i>
                </div>
              </b-col>
            </b-row>
          </span>
              <span>

              <b-row>
                  <b-col>
                      <b-form-group label="Reason for Non Compliance">
                          <b-form-textarea v-model="form.description" rows="3" max-rows="6"
                                           placeholder="Enter Reason for Non Compliance"></b-form-textarea>
                      </b-form-group>
          </b-col>
        </b-row>
          </span>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br />
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that
              initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full"
                    @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel
            </button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button :disabled="form.description===null" class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept
            </button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
                v-model="form.description"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{ form.description.length }}/{{ maxText }}</p>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>

    <b-row class="mx-1">
      <b-col cols="12">
        <b-row class="mx-1">
          <b-col>
            <CompanyDataUpdateMessages :task="task" mode="TOP_MESSAGE" />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-alert variant="success" show>
              <i class="fa fa-info-circle mx-1"></i> This application was fully approved on <b>Classification Level
              3</b>. Go through it once again to ensure that nothing has been left unchecked.
              This is a security mechanism to allow a testimony of more than one so that we have a thorough check of
              every entity before it is updated on the platform
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{ task.applicationResponse.name }} <label v-if="task.applicationResponse.nameOnFile"
                                                         class="mx-1 text-blue">( Name on File: {{ task.applicationResponse.nameOnFile }} )</label>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Update Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;">
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{ task.trackingNo }}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{ task.applicationResponse.brn }}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{ task.status.toLowerCase() }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{ cleanEntityType(task.applicationResponse.subType) }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">Level {{ task.applicationResponse.applicationLevel }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{ dateTimeFormat(task.applicationResponse.submissionDate) }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Summary</td>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1 font-bold">
                      Date of Registration in Uganda
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray">
                   {{dateFormat(task.applicationResponse.registrationDate)}}
                  </span>
                  </td>
              </tr>
              <tr style="border: solid 1px #dedede;" v-if="task.applicationResponse.subType === 'foreign-company'">
                  <td class="px-2 py-1 font-bold">

                      Date of Incorporation in the country of origin
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray">
                   {{dateFormat(task.form24.foreignIncorporationDate)}}
                  </span>
                  </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <td class="px-2 py-1 font-bold">
                  Last filed Annual Returns
                </td>
                <td class="px-2 py-1">
                  <pre class="text-gray">
                   {{ task.lastYearFiled }}
                  </pre>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1 text-capitalize">{{ task.applicationResponse.applicant.givenName }}
                  {{ task.applicationResponse.applicant.surname }}
                </td>
                <td class="px-2 py-1">{{ task.applicationResponse.applicant.email }}</td>
                <td class="px-2 py-1">{{ task.applicationResponse.applicant.phoneNumber }}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="6">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Official Account Details (To be Created)</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1">
                  {{ task.primaryEmail }}
                </td>
                <td class="px-2 py-1">
                  {{ task.primaryPhone }}
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button class="btn-de-escalate" @click="deEscalatePrompt">
                <i class="fa fa-arrow-down text-blue mr-1"></i>De-Escalate
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']"
                       @click="select('forms')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Company Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']"
                       @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Document Mapping</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']"
                       @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col :cols="(currentRoute === 'attachments') ? '12' : '6'">
                <CompanyDataUpdateForms v-if="currentRoute === 'forms'" :task="task" />
                <b-row v-if="currentRoute === 'attachments'">
                  <b-col>
                    <b-row class="my-3">
                      <b-col>
                        <p class="font-bold">Mapped Documents: {{ task.attachments.length }}</p>
                        <table class="min-w-full leading-normal mt-2">
                          <thead>
                          <tr class="border-b border-t border-grey">
                            <th class="t-header">#</th>
                            <th class="t-header">Name</th>
                            <th class="t-header">Classification</th>
                            <th class="t-header text-center">Action</th>
                          </tr>
                          </thead>
                          <thead>
                          </thead>
                          <tbody>
                          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(document, idx) in task.attachments"
                              :key="idx">
                            <td class="t-body">{{ idx + 1 }}</td>
                            <td class="t-body font-bold">{{ cleanUnderscores(document.fileDescription) }}</td>
                            <td class="t-body">
                              <b-form-select v-if="document.name === 'OTHER'" v-model="document.name"
                                             class="w-full text-xs form-select paginate-details"
                                             v-on:change="fetch" :options="classifications">
                              </b-form-select>
                              <b-form-select v-else v-model="document.fileDescription"
                                             class="w-full text-xs form-select paginate-details"
                                             v-on:change="fetch" :options="classifications">
                              </b-form-select>
                            </td>
                            <td class="t-body text-center">
                              <button class="bg-blue text-white px-2 py-1 rounded-sm" @click="openFile(document)">
                                View
                                <b-spinner v-if="fileLoader && selectedDocumentHandle === document.edmsId"
                                           small></b-spinner>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td v-if="task.attachments.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                              <span class="text-sm">No documents found</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                      <b-col class="bg-gray" style="min-height: 540px;">
                        <EDMSDocument v-if="file.Base64FileStream" :file="file" />
                        <CompanyDataUpdateAttachments v-if="attachmentUrl" ref="attachmentScreen" />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <CompanyDataUpdateMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD" />
              </b-col>
              <b-col cols="6" v-if="currentRoute !== 'attachments'">
                <EMDSFileExplorer :brn="task.applicationResponse.brn" :entity-type="task.applicationResponse.type" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import CompanyDataUpdateForms from "@/views/home/services/data-update/task/company/CompanyDataUpdateForms.vue";
import CompanyDataUpdateMessages from "@/views/home/services/data-update/task/company/CompanyDataUpdateMessages.vue";
import EMDSFileExplorer from "@/views/home/services/data-update/components/EMDSFileExplorer.vue";
import EscalationDataUpdate from "@/views/home/commons/EscalationDataUpdate.vue";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument.vue";
import CompanyDataUpdateAttachments
  from "@/views/home/services/data-update/task/company/CompanyDataUpdateAttachments.vue";
import server from "@/app/env/server.json";
import DeEscalationDataUpdate from "@/views/home/commons/DeEscalationDataUpdate.vue";
import {years} from "@/views/home/commons/years";

export default {
  name: "CompanyDataUpdateTaskLevel4",
  components: {
    DeEscalationDataUpdate,
    CompanyDataUpdateAttachments,
    EDMSDocument,
    EscalationDataUpdate,
    EMDSFileExplorer,
    CompanyDataUpdateMessages,
    CompanyDataUpdateForms
  },
  props: {
    task: Object
  },
  data() {
    return {
      margin: "2px",
      currentRoute: "forms",
      fileLoader: false,
      loading: false,
      messages: [],
      taskId: null,
      maxText: 500,
      snapshot: null,
      snapshots: [],
      showFileMapping: false,
      form: {
        applicationId: null,
        description: null,
        nonCompliance: {
          form1: false,
          form18: false,
          form20: false,
          form24: false,
          form25: false,
          form26: false,
          annualReturnsFiled: []
        },
        status: null
      },
      annualReturnYear: null,
      annualReturnYears: [],
      file: {
        extension: "pdf",
        Base64FileStream: null
      },
      attachmentUrl: null,
      loadingDocuments: false,
      selectedDocumentHandle: null,
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey hover:bg-header_grey"
      },
      classifications: [
        { text: "Form 1", value: "SIGNED_COPY_FORM_1" },
        { text: "Form S18", value: "SIGNED_COPY_FORM_S18" },
        { text: "Form A1", value: "SIGNED_COPY_FORM_A1" },
        { text: "Form 18", value: "SIGNED_COPY_FORM_18" },
        { text: "Form 20", value: "SIGNED_COPY_FORM_20" },
        { text: "Form 10", value: "SIGNED_COPY_FORM_10" },
        { text: "Form 19", value: "SIGNED_COPY_FORM_19" },
        { text: "Form 13", value: "SIGNED_COPY_FORM_13" },
        { text: "Form A23", value: "SIGNED_COPY_FORM_A23" },
        { text: "Form 24", value: "SIGNED_COPY_FORM_24" },
        { text: "Form 25", value: "SIGNED_COPY_FORM_25" },
        { text: "Form 26", value: "SIGNED_COPY_FORM_26" },
        { text: "Form 30", value: "SIGNED_COPY_FORM_30" },
        { text: "Form 12", value: "SIGNED_COPY_FORM_12" },
        { text: "Form 11", value: "SIGNED_COPY_FORM_11" },
        { text: "Form 14", value: "SIGNED_COPY_FORM_14" },
        { text: "Form 15", value: "SIGNED_COPY_FORM_15" },
        { text: "Form 17", value: "SIGNED_COPY_FORM_17" },
        { text: "Form 4", value: "SIGNED_COPY_FORM_4" },
        { text: "Form 5", value: "SIGNED_COPY_FORM_5" },
        { text: "Form 21", value: "SIGNED_COPY_FORM_21" },
        { text: "Form 22", value: "SIGNED_COPY_FORM_22" },
        { text: "Form 2", value: "SIGNED_COPY_FORM_2" },
        { text: "Form 3", value: "SIGNED_COPY_FORM_3" },
        { text: "Form 6", value: "SIGNED_COPY_FORM_6" },
        { text: "Form 7", value: "SIGNED_COPY_FORM_7" },
        { text: "Certificate", value: "CERTIFICATE" },
        { text: "Annual Returns", value: "ANNUAL_RETURNS" },
        { text: "Re registration Form 5", value: "SIGNED_COPY_RE_REGISTRATION_FORM_5" },
        { text: "Re registration Form 4", value: "SIGNED_COPY_RE_REGISTRATION_FORM_4" },
        { text: "Memarts", value: "MEMARTS" },
        { text: "Beneficial Ownership Form", value: "BENEFICIAL_OWNERSHIP_FORM" },
        { text: "Statement in Lieu of Prospectus", value: "STATEMENT_IN_LIEU_OF_PROSPECTUS" },
        { text: "Re registration Certificate", value: "REGISTRATION_CERTIFICATE" },
        { text: "Conversion Certificate", value: "CONVERSION_CERTIFICATE" },
        { text: "Name Change Certificate", value: "CHANGE_NAME_CERTIFICATE" },
        { text: "Name Gazette", value: "CHANGE_NAME_GAZETTE" },
        { text: "Resolution", value: "RESOLUTION" },
        { text: "Balance Sheet", value: "BALANCE_SHEET" },
        { text: "Statutory Declaration", value: "STATUTORY_DECLARATION" },
        { text: "Valuation Report", value: "VALUATION_REPORT" },
        { text: "Transfer Form", value: "TRANSFER_FORM" },
        { text: "Call on Shares Notice", value: "CALL_ON_SHARES_NOTICE" },
        { text: "Call on Shares", value: "CALL_ON_SHARES" },
        { text: "Forfeiture Notice", value: "FORFEITURE_NOTICE" },
        { text: "Charge Certificate", value: "CHARGE_CERTIFICATE" },
        { text: "Dispensation Letter", value: "LETTER_REQUESTING_TO_DISPENSE_WITH_THE_WORD_LIMITED" },
        { text: "License to Dispense", value: "LICENSE_TO_DISPENSE_WITH_LIMITED" },
        { text: "Other", value: "OTHER" }
      ]
    };
  },
  methods: {
    years() {
      return years
    },
    ...mapActions(["updateCompanyDataUpdateStatus",
      "fetchAllCompanyEDMSDocuments",
      "fetchCompanyEDMSFile",
      "approveCompanyDataUpdateFinal"]),
    onApprove() {
      this.form.applicationId = this.task.applicationId;
      this.form.status = "APPROVED";
      this.action = "final";
      this.$refs.approveConfirm.show();
    },
    onQuery() {
      this.form.applicationId = this.task.applicationId;
      this.form.status = "QUERIED";
      this.action = "query";
      if (this.form.description != null) {
        this.$refs.confirm.show();
      } else {
        this.errorToast("Missing Information", "Provide a message for this final decision");
      }
    },
    select(route) {
      this.currentRoute = route;
    },
    toggleMappingViewer() {
      this.showFileMapping = !this.showFileMapping;
    },
    yesConfirmation() {
      if (this.action === "final") {
        this.loading = true;
        this.form.nonCompliance.annualReturnsFiled = this.annualReturnYears;
        this.approveCompanyDataUpdateFinal(this.form);
      }
      if (this.action === "query") {
        this.loading = true;
        this.form.nonCompliance = null;
        this.updateCompanyDataUpdateStatus(this.form);
      }
      if (this.action === "escalate") {
        this.$refs.escalateSubmissions.show();
      }
      if (this.action === "de-escalate") {
        this.$refs.deEscalateSubmissions.show();
      }
      this.cancelConfirmation();
    },
    cancelConfirmation() {
      this.action = null;
      this.$refs.confirm.hide();
      this.$refs.approveConfirm.hide();
    },
    escalatePrompt() {
      this.action = "escalate";
      this.$refs.confirm.show();
    },
    deEscalatePrompt() {
      this.action = "de-escalate";
      this.$refs.confirm.show();
    },
    addAnnualReturnYear() {
      if (this.annualReturnYear == null) {
        this.errorToast("Input Error", "Select a year");
      } else if (!this.annualReturnYears.includes(this.annualReturnYear)) {
        this.annualReturnYears.push(this.annualReturnYear);
      } else {
        this.warningToast("Duplication Issue", "Year already added");
      }
    },
    removeAnnualReturnYear(idx) {
      this.annualReturnYears.splice(idx, 1);
    },
    openFile(file) {
      this.file = {
        extension: "pdf",
        Base64FileStream: null
      };
      this.attachmentUrl = null;
      if (file.fileName.includes("pdf")) {
        this.attachmentUrl = server.IP + "/data-update/companies/download?file-name=" + file.fileName;
      } else {
        this.selectedDocumentHandle = file.fileName;
        let data = {
          documentHandle: file.fileName,
          overlay: ""
        };
        this.fileLoader = true;
        this.fetchCompanyEDMSFile(data);
      }
    },
    cleanEntityType(name) {
      let n = name.replaceAll("-", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    dateTimeFormat(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed: {
    ...mapGetters([
      "getDataUpdateStatusSuccess",
      "getDataUpdateCompanyUpdateStatusError",
      "getCompanyDataUpdateTaskError",
      "getAllCompanyEDMSDocuments", "getEDMSFile",
      "getCompanyDataUpdateError"
    ])
  },
  watch: {
    attachmentUrl: {
      handler(url) {
        this.$refs.attachmentScreen.openFile(url);
      },
      deep: true
    },
    getEDMSFile(data) {
      this.fileLoader = false;
      this.file = data;
    },
    getCompanyDataUpdateTaskError(data) {
      this.loading = false;
      this.errorToast("Error", data.messages);
    },
    getDataUpdateStatusSuccess() {
      this.loading = false;
      this.successToast("Success!", "Final Decision Successful");
      window.location.reload();
    },
    getCompanyUpdateStatusSuccessError() {
      this.loading = false;
    },
    getCompanyDataUpdateError(error) {
      this.errorToast("Data Update Error", error.data.message);
    }
  }
};
</script>

<style scoped>

.icon {
  width: 170px;
}

th, td {
  font-size: 12px;
}

.btn-escalate {
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover {
  @apply text-blue-dark;
}

.btn-de-escalate {
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-de-escalate:hover {
  @apply text-red-dark;
}

.btn-approve {
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query {
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel {
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover {
  @apply bg-green border-green text-white;
}

.btn-query:hover {
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover {
  @apply bg-red border-red text-white;
}

.btn-approve-hover {
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover {
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover {
  @apply bg-green-dark;
}

.btn-query-hover:hover {
  @apply bg-orange-dark;
}

</style>