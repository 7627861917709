<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row >
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <b-row v-if="getAnalysisReport.length > 0">
                  <b-col cols="12">
                    <b-row v-for="(option, idx) in getAnalysisReport" :key="idx">
                      <b-col cols="12" class="mt-1">
                        <b-row v-if="idx > 0">
                          <b-col class="mb-5">
                            <hr/>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="6">
                            <span class="text-blue font-bold">Report for {{option.name}}</span>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-row>
                              <b-col class="mt-2">
                                <span class="text-gray text-sm font-bold">Text Similarity</span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="12">
                                <table class="min-w-full leading-normal">
                                  <thead>
                                  <tr class="border-0 bg-white py-2">
                                    <th class="t-header">#</th>
                                    <th class="t-header">Name</th>
                                    <th class="t-header">Score</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(entity, idx) in option.text"
                                      :key="idx">
                                    <td class="t-body">{{(idx+1)}}</td>
                                    <td class="t-body font-bold">{{entity.name}}</td>
                                    <td class="t-body">{{entity.score.text}}%</td>
                                  </tr>
                                  <tr>
                                    <td v-if="option.text.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
                                      <span class="text-sm">No results found</span>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col>
                            <b-row>
                              <b-col class="mt-2">
                                <span class="text-gray text-sm font-bold">Sound Similarity</span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col cols="12">
                                <table class="min-w-full leading-normal">
                                  <thead>
                                  <tr class="border-0 bg-white py-2">
                                    <th class="t-header">#</th>
                                    <th class="t-header">Name</th>
                                    <th class="t-header">Score</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr :class="[(idx%2) ? rows.even : rows.odd]"
                                      v-for="(entity, idx) in option.sound" :key="idx">
                                    <td class="t-body">
                                      {{(idx+1)}}
                                    </td>
                                    <td class="t-body font-bold">
                                      {{entity.name}}
                                    </td>
                                    <td class="t-body">
                                      {{entity.score.sound}}%
                                    </td>
                                  </tr>
                                  <tr>
                                    <td v-if="option.sound.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
                                      <span class="text-sm">No results found</span>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col class="mt-1">
                    <span class="text-sm px-2 py-1 rounded-md text-blue" style="background: #f1f1f1">No similar names available from the analysis report</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "AnalysisReportAppeal",
  props:{reservation:{}},
  data(){
    return{
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  methods:{

  },
  computed:{
    ...mapGetters(["getAnalysisReport", "getAnalysisReportError"]),
  },
  watch:{

  }
}
</script>

<style scoped>
@import "../../../../../../assets/styles/table.css";

th, td{
  font-size: 12px;
}

span{
  font-size: 12px;
}

</style>