<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>

          <b-textarea ref="text" v-model="description"
                      class="mb-2"
                      rows="4"
                      placeholder="Comments">
          </b-textarea>
          <p v-if="description" class="my-2 text-xs">{{ description.length }}/{{ maxText }}</p>
        </b-col>
      </b-row>

      <b-row >
        <b-col>
          <button v-if="type==='reply'" @click="escalate()" class="button py-2">
            Reply <i class="text-white fa fa-check-circle mx-1"></i>
            <b-spinner class="ml-1" small v-if="loadingEscalate"></b-spinner>
          </button>
            <button v-else @click="escalate()" class="button py-2">
                De Escalate <i class="text-white fa fa-check-circle mx-1"></i>
                <b-spinner class="ml-1" small v-if="loadingEscalate"></b-spinner>
            </button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import jwtService from "@/app/services/jwt.service";

export default {
  name: "DeEscalationDataCorrection",
  props: { applicationId: String, type: String, level: Int32Array, permission: String },
  data() {
    return {
      search: null,
      loading: false,
      loadingEscalate: false,
      selectedId: null,
      description: null,
      userProfile: {},
      limit: 15,
      maxText: 500,
      fromUserId: null
    };
  },
  mounted() {
    this.fetch();
    this.userProfile = jwtService.getUser();
    this.fromUserId = this.userProfile.userId;
  },
  methods: {
    ...mapActions(["fetchRegistrars", "searchRegistrars", "escalateDataCorrectionTask", "deEscalateDataCorrectionTask" ,"replyToEscalateDataCorrectionTask"]),
    escalate() {
        let data = {
            comment: this.description,
            id: this.applicationId,
        };
        this.loading = true;
        this.loadingEscalate = true;
          if (this.type === "de-escalate") {
            this.deEscalateDataCorrectionTask(data);
          } else {
            this.replyToEscalateDataCorrectionTask(data);
          }

    }
  },
  computed: {
    ...mapGetters(["getRegistrars", "getCIEscalationDataUpdateSuccess", "getBNEscalationDataUpdateSuccess", "getBNDataUpdateError","getCompanyDataUpdateError"])
  },
  watch: {
    description: {
      handler(data) {
        if (data.length >= this.maxText) {
          this.errorToast("Text Length Error", "This message is very long");
        }
      },
      deep: true
    },
    getRegistrars() {
      this.loading = false;
    },
    getBNEscalationDataUpdateSuccess() {
      this.loadingEscalate = false;
      window.location.reload();
    },
    getCIEscalationDataUpdateSuccess() {
      this.loadingEscalate = false;
      window.location.reload();
    },
    getBNDataUpdateError(data) {
      this.loading = false;
      this.loadingEscalate = false;
      this.errorToast("Data correction Escalation Error", data.message);
    },
      getCompanyDataUpdateError(error){
          this.errorToast("Data correction Escalation Error", error.data.message);
      }
  }
};
</script>

<style scoped>
.button {
  @apply w-full bg-blue mt-1 px-2 py-1 rounded-sm text-white text-xs
}

.button:hover {
  @apply bg-green-dark;
}
</style>