<template>
  <b-row>
    <b-col cols="12">
      <b-row v-if="getCompanyRegistry">
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'companies') ? 'menu-item-active' : 'menu-item']" @click="select('companies')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Companies</div>
                <b-badge class="bg-blue mx-1">{{getCompanyRegistry.totalElements.toLocaleString()}}</b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'business-names') ? 'menu-item-active' : 'menu-item']" @click="select('business-names')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Business Names</div>
                <b-badge class="bg-blue mx-1 mb-1">{{getBusinessNameRegistry.totalElements.toLocaleString()}}</b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'building-societies') ? 'menu-item-active' : 'menu-item']" @click="select('building-societies')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Building Societies</div>
<!--                <b-badge class="bg-blue mx-1">{{getBuildingSocietyRegistry.totalElements.toLocaleString()}}</b-badge>-->
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'companies') ? 'menu-item-active' : 'menu-item']" @click="select('companies')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Companies</div>
                <b-spinner class="mx-1" small></b-spinner>
              </div>
            </div>
            <div :class="[(currentRoute === 'business-names') ? 'menu-item-active' : 'menu-item']" @click="select('business-names')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Business Names</div>
                <b-spinner class="mx-1" small></b-spinner>
              </div>
            </div>
            <div :class="[(currentRoute === 'building-societies') ? 'menu-item-active' : 'menu-item']" @click="select('building-societies')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Building Societies</div>
                <b-spinner class="mx-1" small></b-spinner>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BusinessNameRegistry v-if="currentRoute === 'business-names'"/>
          <CompanyRegistry v-if="currentRoute === 'companies'"/>
          <BuildingSocietyRegistry v-if="currentRoute === 'building-societies'"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import BuildingSocietyRegistry from "@/views/home/services/registry/business/BuildingSocietyRegistry";
import CompanyRegistry from "@/views/home/services/registry/business/CompanyRegistry";
import BusinessNameRegistry from "@/views/home/services/registry/business/BusinessNameRegistry";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "BusinessRegistryBreakdown",
  components: {BuildingSocietyRegistry, CompanyRegistry, BusinessNameRegistry},
  data(){
    return{
      currentRoute: "companies",
      search:"",
      currentPage:1,
      limit:15,
    }
  },
  mounted() {
    localStorage.removeItem("registryEntity")
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchBuildingSocietyRegistry", "fetchCompanyRegistry", "fetchBusinessNameRegistry"]),
    fetch(){
      let data = {
        q:this.search,
        page:this.currentPage,
        limit:this.limit,
        offset:this.limit
      }
      this.fetchBuildingSocietyRegistry(data)
      this.fetchCompanyRegistry(data)
      this.fetchBusinessNameRegistry(data)
    },
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getBusinessNameRegistry", "getCompanyRegistry", "getBuildingSocietyRegistry"])
  }
}
</script>

<style scoped>

</style>