import {api} from "../../../../../app/services/api.service";

const state = {
    business_names:[],
    business_escalations:null,
    business_escalation:null,
    business_details_error:null,
    business_details:null,
    bn_registration:{},
    task_count:{},
    registration_stats:{},
    task_messages:null,
    preview:null,
    error:null,
    success:{},
    successFinalDecision:null,
    business_edms_documents:null,
    all_business_edms_documents:null,
    business_edms_documents_error:null

};

const getters = {
    getBNDataUpdateSubmissions: (state) => state.business_names,
    getBNDataUpdateEscalations: (state) => state.business_escalations,
    getBNDataUpdateEscalation: (state) => state.business_escalation,
    getBNDataUpdateTaskMessages: (state) => state.task_messages,
    getBNDataUpdateStatistics: (state) => state.registration_stats,
    getBNDataUpdateTaskCount: (state) => state.task_count,
    getBNDataUpdatePreview: (state) => state.preview,
    getBNDataUpdateTask: (state) => state.bn_registration,
    getBNDataUpdateTaskError: (state) => state.error,
    getBNDataUpdateSuccess: (state) => state.success,
    getAssignmentDataUpdateSuccess: (state) => state.success,
    getBNEscalationDataUpdateSuccess: (state) => state.success,
    getBNDataUpdateActionSuccess: (state) => state.successFinalDecision,
    getBNDataUpdateAssignmentSuccess: (state) => state.success,
    getBNDataUpdateUpdateStatusSuccess: (state) => state.success,
    getBNDataUpdateUpdateStatusError: (state) => state.error,
    getBNDataUpdateError: (state) => state.error,
    getBNDataUpdateBusinessDetails: (state) => state.business_details,
    getBNDataUpdateBusinessDetailsError: (state) => state.business_details_error,
    getBusinessNameEDMSDocuments: (state) => state.business_edms_documents,
    getAllBusinessNameEDMSDocuments: (state) => state.all_business_edms_documents,
    getBusinessNameEDMSDocumentsError: (state) => state.business_edms_documents_error,
};

const actions = {
    async fetchBusinessNameDataUpdates({commit}, data){
        let path = "/data-update/business?status="+data.statuses+"&page="+data.page+"&perPage="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        if(data.level !== null){
            path += "&level="+data.level
        }
        api().get(path).
        then(response => {
            commit('setBusinessNameDataUpdates', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async fetchBusinessNameDataUpdateEscalations({commit}, data){
        api().get("/data-update/business/escalation?status="+data.status+"&toUserId="+data.userId+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setBNDataUpdateEscalations', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async fetchBusinessNameDataUpdateEscalation({commit}, data){
        api().get("/data-update/business/escalation/details?escalationId="+data.id).
        then(response => {
            commit('setBNDataUpdateEscalation', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async fetchDataUpdateMessages({commit}, id){
        api().get("/data-update/task/status?applicationId="+id).
        then(response => {
            commit('setBNDataUpdateMessages', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async fetchBusinessNameDataUpdateTask({commit}, data){
        api().get("/data-update/business/task/next/"+data.level).
        then(response => {
            commit('setBusinessNameDataUpdate', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async fetchBNDataUpdateTaskCount({commit}){
        api().get("/data-update/task/count").
        then(response => {
            commit('setBNDataUpdateCount', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async fetchBNDataUpdateStatistics({commit}, level){
        let path = "/data-update/business/basic-statistics"
        if(level != null){
            path+="?level="+level
        }
        api().get(path).
        then(response => {
            commit('setBNDataUpdateStats', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async assignBNDataUpdateTask({commit}, data){
        api().post("/data-update/task", data).
        then(response => {
            commit('setBNDataUpdateAssignmentSuccess', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async escalateBNDataUpdateTask({commit}, data){
        api().post("/data-update/business/escalation", data).
        then(response => {
            commit('setBNDataUpdateEscalationSuccess', response.data);
        }).catch(error => {
            commit("setBNDataUpdateError", error.response)
        });
    },
    async updateBNDataUpdateStatus({commit}, data){
        api().post("/data-update/task/status", data).
        then(response => {
            commit('setBNDataUpdateBusinessStatusSuccess', response.data);
        }).catch(error => {
            commit("setBNDataUpdateBusinessStatusUpdateError", error.response)
        });
    },
    async finalBusinessNameDataUpdateApproval({commit}, data){
        api().post("/data-update/task/status", data).
        then(response => {
            commit('setBNDataUpdateBusinessStatusSuccess', response.data);
        }).catch(error => {
            commit("setBNDataUpdateBusinessStatusUpdateError", error.response)
        });
    },
    async fetchBusinessNameDataUpdateDetails({commit}, trackingNo){
        api().get("/data-update/business/by-tracking-no/?trackingNo="+trackingNo).
        then(response => {
            commit('setBNDataUpdateBusinessDetails', response);
        }).catch(error => {
            commit("setBNDataUpdateBusinessDetailsError", error.response)
        });
    },
    async fetchBusinessNameEDMSDocuments({commit}, data){
        let path = "/integration/api/edms/business-name/documents/list?brn="+data.brn+"&page="+data.page+"&perPage="+data.limit
        if(data.documentType){
            path += "&documentType="+data.documentType
        }
        if(data.documentName){
            path += "&documentName="+data.documentName
        }
        api().get(path).
        then(response => {
            commit('setBusinessNameEDMSDocuments', response.data);
        }).catch(error => {
            commit("setBusinessNameEDMSDocumentsError", error.response)
        });
    },
    async fetchAllBusinessNameEDMSDocuments({commit}, data){
        api().post("/integration/api/edms/business-name/files/list", data).
        then(response => {
            commit('setAllBusinessNameDocuments', response.data);
        }).catch(error => {
            commit("setCompanyDataUpdateTaskError", error.response)
        });
    },

};

const mutations = {
    setBusinessNameDataUpdates: (state, response) =>{
        state.business_names = response;
    },
    setBNDataUpdateEscalations: (state, response) =>{
        state.business_escalations = response;
    },
    setBNDataUpdateEscalation: (state, response) =>{
        state.business_escalation = response;
    },
    setBNDataUpdateMessages: (state, response) =>{
        state.task_messages = response;
    },
    setBusinessNameDataUpdate: (state, response) =>{
        state.bn_registration = response.data;
    },
    setBNDataUpdateStats: (state, response) =>{
        state.registration_stats = response;
    },
    setBNDataUpdateCount: (state, response) =>{
        state.task_count = response;
    },
    setBNDataUpdateAssignmentSuccess: (state, response) =>{
        state.success = response;
    },
    setBNDataUpdateEscalationSuccess: (state, response) =>{
        state.success = response;
    },
    setBNDataUpdateError: (state, response) =>{
        state.error = response;
    },
    setBNDataUpdatePreview: (state, response) =>{
        state.preview = response;
    },
    setBNDataUpdateBusinessStatusSuccess: (state, response) =>{
        state.successFinalDecision = response;
    },
    setBNDataUpdateBusinessStatusUpdateError: (state, response) =>{
        state.error = response;
    },
    setBNDataUpdateBusinessDetails:(state, response) =>{
        state.business_details = response.data
    },
    setBNDataUpdateBusinessDetailsError:(state, response) =>{
        state.business_details_error = response.data
    },
    setBusinessNameEDMSDocuments:(state, response) =>{
        state.business_edms_documents = response.data
    },
    setAllBusinessNameDocuments:(state, response) =>{
        state.all_business_edms_documents = response
    },
    setBusinessNameEDMSDocumentsError:(state, response) =>{
        state.business_edms_documents_error = response.data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};