<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <span style="font-size: 30px; font-weight: bold">{{task.entityName}}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2">Certification Details</td>
            </tr>
            <tr>
              <th class="px-2 py-1">Tracking Number</th>
              <th class="px-2 py-1">Status</th>
              <th class="px-2 py-1">Fees</th>
              <th class="px-2 py-1">Payment Method</th>
              <th class="px-2 py-1">Requested By</th>
              <th class="px-2 py-1">Submission Date</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border: solid 1px #dedede;" >
              <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
              </td>
              <td class="px-2 py-1">
                <span class="text-blue text-capitalize">{{task.status.toLowerCase()}}</span>
              </td>
              <td class="px-2 py-1">
                <span class="text-capitalize">{{task.certificationFee.toLocaleString()}}</span>
              </td>
              <td class="px-2 py-1">
                <span class="text-capitalize" v-if="task.paymentMode">{{task.paymentMode.replaceAll("_", " ")}}</span>
              </td>
              <td class="px-2 py-1">
                <span class="text-capitalize">
                  {{task.user.firstName}}
                  {{task.user.lastName}}
                </span>
              </td>
              <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.creationDate)}}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2">Entity Details</td>
            </tr>
            <tr>
              <th class="px-2 py-1">Search Reference</th>
              <th class="px-2 py-1">BRN</th>
              <th class="px-2 py-1">Entity Type</th>
              <th class="px-2 py-1">Sub Entity Type</th>
              <th class="px-2 py-1">Registration Date</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border: solid 1px #dedede;" >
              <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.referenceNo}}
                  </span>
              </td>
              <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.brn}}
                  </span>
              </td>
              <td class="px-2 py-1">
                <span class="text-gray text-capitalize" >{{task.entityType.toLowerCase()}}</span>
              </td>
              <td class="px-2 py-1">
                <span class="text-gray text-capitalize">{{task.entitySubType.toLowerCase()}}</span>
              </td>
              <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(task.dateOfRegistration)}}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>

          <table class="min-w-full leading-normal mt-1 text-blue-dark"
                 v-if="task.user"
                 style="border: solid 1px #bdd7f1;">
            <thead>
            <tr class="bg-blue-pale">
              <td colspan="12" class="px-2 py-2">Applicant Details</td>
            </tr>
            <tr>
              <th class="px-2 py-1">Name</th>
              <th class="px-2 py-1">Email</th>
              <th class="px-2 py-1">Phone</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border: solid 1px #bdd7f1;">
              <td class="px-2 py-1">
                  <span>{{task.user.firstName}} {{task.user.lastName}}</span>
              </td>
              <td class="px-2 py-1">
                  <span>{{task.user.email}}</span>
              </td>
              <td class="px-2 py-1">
                <span>{{task.user.phoneNumber}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12" v-if="task.status !== 'DRAFT'">
          <PRNViewer :tracking-number="task.trackingNumber"/>
        </b-col>
        <b-col cols="12"  v-if="task.status === 'PENDING' && task.prn === null">
          <b-alert show variant="info">
            <i class="fa fa-info-circle mx-1"></i> <span>Payment for this Certification is covered because it is from an MDA Account</span>
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col cols="5">
          <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2">Document</td>
            </tr>
            <tr>
              <th class="px-2 py-1">#</th>
              <th class="px-2 py-1">Document</th>
            </tr>
            </thead>
            <tbody>
            <tr style="border: solid 1px #dedede;" v-for="(document, idx) in task.documentDescription" :key="idx">
              <td class="px-2 py-1">
                {{idx+1}}
              </td>
              <td class="px-2 py-1">
                <span class="text-gray text-capitalize cursor-pointer hover:text-blue hover:underline" @click="view(document)">{{document.documentName}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col cols="7">
          <CertificationDocumentViewer ref="documentViewer"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer";
import CertificationDocumentViewer from "@/views/home/services/certification/task/CertificationDocumentViewer";

export default {
  name: "CertificationDetails",
  components: { CertificationDocumentViewer, PRNViewer },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      task:null,
      currentRoute: "application-details",
    }
  },
  mounted() {
    this.fetchCertificationDetails(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchCertificationDetails"]),
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    view(document){
      this.$refs.documentViewer.openFile(document)
    }
  },
  computed:{
    ...mapGetters(["getCertificationDetails"])
  },
  watch:{
    getCertificationDetails(data){
      this.task = data
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>