<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import {mapActions, mapGetters} from "vuex";
import PetitionCourt from "@/views/home/services/insolvency/winding-up/petition/PetitionCourt.vue";
import InsolvencyFileImport from "@/views/home/services/insolvency/winding-up/components/InsolvencyFileImport.vue";
import {api} from "@/app/services/api.service";

export default {
    name: "CourtOrderPetition",
    components: {
        InsolvencyFileImport,
        PetitionCourt,
        FormWizard,
        TabContent
    },
    data() {
        return {
            applicationStatus: null,
            currentTab: 0,
            natureOfDispute: null,
            loading:false,
            draftData:null,
            trackingNumber:null,
            applicationId:null,
            response:null,
        };
    },

    mounted() {

        let trackingNumber = sessionStorage.getItem("tracking-number")
        if (trackingNumber){
            this.fetchDraftData(trackingNumber)
        }

    },
    methods:{
        ...mapActions(
            [
                "fetchCourtOrderPetitionDraftData",
                "validateInsolvencyAttachments"
            ]
        ),
        fetchDraftData(trackingNumber) {
            this.fetchCourtOrderPetitionDraftData(trackingNumber)
        },
        validateAttachments() {
            this.loading = true
            let data = {trackingNumber: this.trackingNumber}
            this.validateInsolvencyAttachments(data)
        },

        next(trackingNumber) {
            if (trackingNumber) {
                this.fetchDraftData(trackingNumber)
            }
            this.$refs.wizard.nextTab();
        },
        prev() {
            this.$refs.wizard.prevTab();
        },
        setPrn(data) {
            this.prn = data.prn
        },
        responseInfo(data) {
            this.response = data
        },
        async addRegistrarsComment(data) {
            await api().post("insolvency/process/submit-query", data)
                .then((response) => {
                    if (response) {
                        this.loading = false
                        if (response.data) {
                            let data = response.data
                            this.successToast("Success", data.message)

                            let r = this.$router.resolve({
                                name: "company-filing-payment-success"
                            });
                            window.location.assign(r.href);
                            sessionStorage.setItem("FILING_DATA", JSON.stringify({
                                trackingNo: data.data,
                                statusQuery: "QUERIED"
                            }));

                        }
                    }

                })
                .catch((error) => {
                    if (error && error.response) {
                        this.loading = false;
                        this.errorToast("Error", error.response.data.message);
                    }
                });
        },
    },
    computed: {
        ...mapGetters(
            [
                "getCourtOrderPetitionDraftData",
                "getDisputeResolution",
                "getCompleteLegaDocsApplication",
                "getDisputeResolutionsError",
                "getInsolvencyAttachmentValidator",
                "getAttachmentValidatorError"
            ]
        )
    },
    watch: {
        getAttachmentValidatorError(data) {
            if (data) {
                this.loading = false;
                this.errorToast("Error", data.message);
            }
        },
        getCompleteLegaDocsApplication(data) {
            this.loading = false
            let r = this.$router.resolve({
                name: "dispute-resolution-success",
            });
            window.location.assign(r.href);
            sessionStorage.setItem("DISPUTE_RESOLUTION_DATA", JSON.stringify(data))

        },
        getCourtOrderPetitionDraftData(data) {
            if (data && data.data) {
                let draft = data.data
                this.draftData = draft
                this.trackingNumber = draft.trackingNumber
                this.applicationId = draft.id ? draft.id : ""
                this.applicationStatus = draft.status ? draft.status : ""
            }
        },
        getInsolvencyAttachmentValidator(data) {
            if (data) {

                if (this.applicationStatus !== 'QUERIED') {
                    this.loading = false
                    this.next(this.trackingNumber)
                } else {

                    if (!this.response) {
                        this.loading = false
                        this.warningToast("Warning", "Provide a message to registrar")
                        return
                    }

                    this.loading = true
                    let data = {
                        trackingNumber: this.trackingNumber,
                        description: this.response
                    }
                    this.addRegistrarsComment(data)
                }


            }
        },
        getDisputeResolutionsError(error) {
            this.errorToast("Error", error.message)
            this.loading = false
        }
    }


}
</script>

<template>
    <div>
        <div class="bg-white rounded-sm m-2 mt-4 overflow-hidden">
            <b-row>
                <b-col class="m-3">
                    <div>
                        <div class="">

                            <div class="title">File Petition Resolution</div>

                            <b-row class="mt-4 mx-auto">
                                <b-col cols="12">


                                    <p v-if="trackingNumber"
                                       class="mt-2 text-sm text-gray-600 bg-opacity-75 p-2 rounded alertStyle"
                                       style="border: 1px solid #3692cc;">
                                        <a href="#" class="font-medium" style="color: #3692cc;">
                                            <small v-if="trackingNumber"><b>Tracking Number: {{
                                                    trackingNumber
                                                }}</b></small>
                                        </a>
                                    </p>

                                    <b-row>
                                        <b-col :cols="applicationStatus !== 'QUERIED' ? 12 : 9">
                                            <form-wizard
                                                    shape="circle"
                                                    title=""
                                                    stepSize="xs"
                                                    subtitle=""
                                                    :hide-buttons="true"
                                                    :startIndex="currentTab"
                                                    color="#3692cc"
                                                    ref="wizard"
                                                    error-color="#3692cc">
                                                <tab-content title="Start">
                                                    <b-row>
                                                        <b-col>
                                                            <PetitionCourt v-on:nextTab="next"
                                                                           v-on:backTab="prev"></PetitionCourt>
                                                        </b-col>
                                                    </b-row>


                                                </tab-content>


                                                <tab-content title="Attachments" tabindex="6">

                                                    <b-row>

                                                        <b-col cols="5" class="mx-auto">
                                                            <insolvency-file-import></insolvency-file-import>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col cols="12">

                                                            <button id="backBtn" type="button" class="back-button"
                                                                    @click="prev">
                           <span class="mx-3">
                              Back
                           </span>
                                                            </button>


                                                            <button id="nextBtn" type="button" class="button float-end"
                                                                    @click="validateAttachments">
                           <span class="mx-3">
                             {{ applicationStatus === 'QUERIED' ? "Save" : "Save & Continue" }}
                              <b-spinner small v-if="loading"></b-spinner>
                           </span>
                                                            </button>

                                                        </b-col>
                                                    </b-row>

                                                </tab-content>






                                            </form-wizard>
                                        </b-col>

                                    </b-row>

                                </b-col>



                            </b-row>


                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style scoped>
.button {
    @apply bg-blue text-white py-2 rounded;
}

.button:hover {
    @apply bg-blue-dark;
}


.back-button {
    @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
    @apply bg-gray text-white;
}
</style>