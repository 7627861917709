<template>
  <b-row>
    <b-col>
      <p v-if="error" class="text-red text-xs font-bold">{{error}}</p>
      <p v-else class="text-green text-xs">This box belongs to <b>{{responseData.data.box_renter}}</b></p>
    </b-col>
  </b-row>
</template>

<script>
import { api } from "@/app/services/api.service";

export default {
  name: "PostalAddressValidator",
  props:{
    boxNumber:String
  },
  data(){
    return{
      box:null,
      rawBox:null,
      error:null,
      responseData:null
    }
  },
  mounted() {
    if(this.validateBox()){
      let station = this.rawBox.replace(this.box[0], "").trim()
      let data = {
        boxStation: station,
        boxNumber: this.box[0]
      }
      this.verifyBox(data)
    }else {
      this.error = "Invalid Box"
    }
  },
  methods:{
    validateBox(){
      let bn = this.boxNumber.toUpperCase().replace("P.O BOX", "").trim()
      let bn1 = bn.toUpperCase().replace("P.0 BOX", "").trim()
      this.box = bn1.split(" ")
      this.rawBox = bn1

      if(this.box.length > 1){
        return true
      }
      return false
    },
    async verifyBox(data) {
      api().post("/integration/api/eposta/verify/individual", data).then(response => {
        this.responseData = response.data;
      }).catch(error => {
        if(error){
          if(error.toString().includes("500")){
            this.error = "Failed to reach Posta Uganda to verify"
          }else{
            this.error = error.response.data.message
          }
        }
      });
    },
  },
};
</script>

<style scoped>

</style>