 <template>
  <b-row>
      <b-col>
        <b-row class="mx-1 my-2" v-if="mode === 'THREAD'">
          <b-col cols="12" >
            <b-row v-for="(message, idx) in messages" :key="idx">
              <b-col cols="8" class="py-2 rounded-md bg-header_grey my-2 text-gray">
                <b-row>
                  <b-col>
                    <span class="text-sm font-bold text-capitalize">{{message.user.fullName}} <b-badge class="bg-green text-capitalize">{{message.userType.toLowerCase()}}</b-badge></span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="text-sm">{{message.description}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span style="font-size:9px">{{dateFormat(message.createdAt)}} | {{message.statusLabel}} </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="messages.length === 0">
              <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
                <b-row>
                  <b-col>
                    <span class="text-xs font-bold">No messages yet</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="my-2" v-if="mode === 'TOP_MESSAGE'">
          <b-col v-if="messages.length > 0" cols="12" class="py-2 rounded-md border border-gray bg-header_grey my-2 text-gray" >
            <b-row>
              <b-col>
                <b-row>
                  <b-col>
                <span class="text-sm font-bold text-capitalize">{{messages[0].user.fullName.toLowerCase()}}
                  <b-badge class="bg-green text-capitalize">{{messages[0].userType.toLowerCase()}}
                  </b-badge></span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="text-sm">{{messages[0].description}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span style="font-size:9px">{{dateFormat(messages[0].createdAt)}} | {{messages[0].statusLabel}} </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "CompanyDataUpdateMessages",
  props:{
    task:Object,
    mode:String
  },
  data(){
    return{
      loading:true,
      messages:[]
    }
  },
  mounted(){
    let id = this.task.applicationResponse.id
    this.fetchCompanyDataUpdateTaskMessages(id)
  },
  methods:{
    ...mapActions(["fetchCompanyDataUpdateTaskMessages"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    }
  },
  computed:{
    ...mapGetters(["getCompanyDataUpdateTaskMessages", "getCompanyDataUpdateTaskError"])
  },
  watch:{
    getCompanyDataUpdateTaskMessages(data){
      console.log(JSON.stringify(data))
      this.loading = false
      this.messages = data
    }
  }
}
</script>

<style scoped>

</style>