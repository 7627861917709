<template>
    <b-row>
        <b-overlay :show="loading" rounded="sm">
            <b-col cols="12">
                <b-row v-if="hasTransactionDetails">
                    <b-col>
                        <table class="min-w-full leading-normal mt-1 text-blue ura-header">
                            <thead>
                            <tr class="bg-blue-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Payment Details
                                    <b-badge class="bg-blue">OBRS-Billing</b-badge>
                                </td>
                            </tr>
                            <tr>
                                <th class="px-2 py-1">PRN</th>
                                <th class="px-2 py-1">Amount</th>
                                <th class="px-2 py-1">Tax Head</th>
                                <th class="px-2 py-1">Tax Payer Details</th>
                                <th class="px-2 py-1 text-center">Status</th>
                                <th class="px-2 py-1 text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody v-for="(paymentInfo, idx) in transactions" :key="idx">
                            <tr :class="(details && details.prn === paymentInfo.prn ) ? 'font-bold ura-header' :  'ura-header'">
                                <td class="px-2 py-1">{{ paymentInfo.prn }}</td>
                                <td class="px-2 py-1">
                                    <p v-if="uraPayload(paymentInfo.prnResponse)">
                                        <span v-if="uraPayload(paymentInfo.prnResponse).amountPaid">UGX. {{
                                                Number(uraPayload(paymentInfo.prnResponse).amountPaid).toLocaleString()
                                            }}</span>
                                        <span v-else>None</span>
                                    </p>
                                </td>
                                <td class="px-2 py-1">
                                    <p v-if="uraPayload(paymentInfo.prnResponse)">
                    <span v-if="uraPayload(paymentInfo.prnResponse).taxHeadCode">
                      {{ uraPayload(paymentInfo.prnResponse).taxHeadCode }}
                    </span>
                                        <span class="text-capitalize ml-1"
                                              v-if="uraPayload(paymentInfo.prnResponse).taxHeadName">
                        <small>{{ uraPayload(paymentInfo.prnResponse).taxHeadName.toLowerCase() }}
                        </small>
                    </span>
                                        <span v-else>
                      None
                    </span>
                                    </p>
                                </td>
                                <td class="px-2 py-1">
                                    <p v-if="uraPayload(paymentInfo.prnResponse)">
                    <span class="text-capitalize"
                          v-if="uraPayload(paymentInfo.prnResponse).taxPayerName">
                        {{ uraPayload(paymentInfo.prnResponse).taxPayerName.toLowerCase() }}</span>
                                        <span v-else>None</span>
                                    </p>
                                </td>

                                <td class="px-2 py-1 text-center">
                    <span v-if="!uraPayload(paymentInfo.prnResponse)"
                          class="bg-red px-2 py-1 text-xs rounded text-white">
                      Invalid <i class="fa fa-times"></i>
                    </span>
                                    <span v-else-if="uraPayload(paymentInfo.prnResponse).statusCode === 'T'"
                                          class="bg-green px-2 py-1 text-xs rounded text-white">
                      Paid <i class="fa fa-check"></i>
                    </span>
                                    <span v-else-if="uraPayload(paymentInfo.prnResponse).statusCode === 'A'"
                                          class="bg-orange px-2 py-1 text-xs rounded text-white">
                      Available <i class="fa fa-check"></i>
                    </span>
                                    <span v-else-if="uraPayload(paymentInfo.prnResponse).statusCode === 'R'"
                                          class="bg-orange px-2 py-1 text-xs rounded text-white">
                      Received <i class="fa fa-clock"></i>
                    </span>
                                    <span v-else-if="uraPayload(paymentInfo.prnResponse).statusCode === 'D'"
                                          class="bg-red px-2 py-1 text-xs rounded text-white">
                      Dishonoured <i class="fa fa-ban"></i>
                    </span>
                                    <span v-else-if="uraPayload(paymentInfo.prnResponse).statusCode === 'C'"
                                          class="bg-red px-2 py-1 text-xs rounded text-white">
                      Canceled <i class="fa fa-times"></i>
                    </span>
                                    <span v-else-if="uraPayload(paymentInfo.prnResponse).statusCode === 'X'"
                                          class="bg-red px-2 py-1 text-xs rounded text-white">
                      Expired <i class="fa fa-clock"></i>
                    </span>

                                </td>
                                <td class="px-2 py-1 text-center">
                                    <button class="bg-blue px-2 py-1 rounded" @click="view(paymentInfo, idx)">
                                        <i class="fa fa-eye text-white"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table v-if="details" class="min-w-full leading-normal pending-header">
                            <thead>
                            <tr class="bg-gray-pale">
                                <td colspan="11" class="px-2 py-2 font-bold">Bill Information</td>
                                <td colspan="1" class="px-2 py-2">
                                    <button class="float-end bg-red text-white px-1 rounded" @click="close"><i
                                            class="fa fa-times"></i> Close
                                    </button>
                                </td>
                            </tr>
                            </thead>
                            <tbody class="bill-details text-gray">
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Transaction Reference</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details">{{ details.transactionRef }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Invoice No.</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details">{{ details.invoiceNo }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Payment Method</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details.paymentMethod">{{ cleanString(details.paymentMethod) }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Total Amount</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details.grandTotal">{{ details.grandTotal.toLocaleString() }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Sub Total</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details.subTotal">{{ details.subTotal.toLocaleString() }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Tax</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details.totalTax">{{ details.totalTax.toLocaleString() }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Transaction Status</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details">{{ details.status }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">PRN Status</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details">{{ details.prnStatus }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">MSISDN</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details">{{ details.msisdn }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="px-2 py-1">
                                    <span class="font-bold">Transaction Date</span>
                                </td>
                                <td class="px-2 py-1" v-if="details">
                                    <span v-if="details">{{ dateFormat(details.createdDate) }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col>
                        <table :class="(paymentInfo.statusCode === 'T') ? success_table : (paymentInfo.statusCode === 'A' || paymentInfo.statusCode === 'R') ? warning_table : (paymentInfo.statusCode === 'WW') ? pending_table : failure_table">
                            <thead>
                            <tr :class="(paymentInfo.statusCode === 'T') ? 'bg-green-pale' : (paymentInfo.statusCode === 'A' || paymentInfo.statusCode === 'R') ? 'bg-orange-pale' : (paymentInfo.statusCode === 'WW') ? 'bg-header_grey' : 'bg-red-pale'">
                                <td colspan="12" class="px-2 py-2">Payment Details
                                    <b-badge
                                            :class="(paymentInfo.statusCode === 'T') ? 'bg-green' : (paymentInfo.statusCode === 'A' || paymentInfo.statusCode === 'R') ? 'bg-orange' : (paymentInfo.statusCode === 'WW') ? 'bg-gray' : 'bg-red'">
                                        URA
                                    </b-badge>
                                </td>
                            </tr>
                            <tr>
                                <th class="px-2 py-1">PRN</th>
                                <th class="px-2 py-1">Amount</th>
                                <th class="px-2 py-1">Tax Head</th>
                                <th class="px-2 py-1">Tax Payer Details</th>
                                <th class="px-2 py-1">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr :class="(paymentInfo.statusCode === 'T') ? success_border : (paymentInfo.statusCode === 'A' || paymentInfo.statusCode === 'R') ? warning_border : (paymentInfo.statusCode === 'WW') ? pending_border : failure_border">
                                <td class="px-2 py-1">{{ prn }}</td>
                                <td class="px-2 py-1">
                                    <p v-if="paymentInfo.statusCode !== 'WW'">
                                        <span v-if="paymentInfo.amountPaid">UGX. {{ Number(paymentInfo.amountPaid).toLocaleString() }}</span>
                                        <span v-else>None</span>
                                    </p>
                                    <p v-else>
                                        <b-spinner v-if="loading" small></b-spinner>
                                        loading from ura...
                                    </p>
                                </td>
                                <td class="px-2 py-1">
                                    <p v-if="paymentInfo.statusCode !== 'WW'">
              <span v-if="paymentInfo.taxHeadCode">
               {{ paymentInfo.taxHeadCode }}
              </span>
                                        <span class="text-capitalize"
                                              v-if="paymentInfo.taxHeadName">{{ paymentInfo.taxHeadName.toLowerCase() }}</span>

                                        <span v-else>
                 None
              </span>
                                    </p>
                                    <p v-else>
                                        <b-spinner v-if="loading" small></b-spinner>
                                        loading from ura...
                                    </p>
                                </td>
                                <td class="px-2 py-1">
                                    <p v-if="paymentInfo.statusCode !== 'WW'">
                                        <span class="text-capitalize"
                                              v-if="paymentInfo.taxPayerName">{{ paymentInfo.taxPayerName.toLowerCase() }}</span>
                                        <span v-else>None</span>
                                    </p>
                                    <p v-else>
                                        <b-spinner v-if="loading" small></b-spinner>
                                        loading from ura...
                                    </p>
                                </td>
                                <td class="px-2 py-1">
            <span v-if="paymentInfo.statusCode === 'T'" class="bg-green px-2 py-1 text-xs rounded text-white">
              Paid
            </span>
                                    <span v-else-if="paymentInfo.statusCode === 'A'"
                                          class="bg-orange px-2 py-1 text-xs rounded text-white">
              Available
            </span>
                                    <span v-else-if="paymentInfo.statusCode === 'R'"
                                          class="bg-orange px-2 py-1 text-xs rounded text-white">
              Received
            </span>
                                    <span v-else-if="paymentInfo.statusCode === 'D'"
                                          class="bg-red px-2 py-1 text-xs rounded text-white">
              Dishonoured
            </span>
                                    <span v-else-if="paymentInfo.statusCode === 'C'"
                                          class="bg-red px-2 py-1 text-xs rounded text-white">
              Canceled
            </span>
                                    <span v-else-if="paymentInfo.statusCode === 'X'"
                                          class="bg-red px-2 py-1 text-xs rounded text-white">
              Expired
            </span>
                                    <span v-else-if="paymentInfo.statusCode === 'WW'"
                                          class=" px-2 py-1 text-xs text-gray">
              <b-spinner v-if="loading" small></b-spinner> fetching status...
            </span>
                                    <span v-else class="bg-red px-2 py-1 text-xs rounded text-white">
              Invalid
            </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </b-col>
                </b-row>
                <b-row v-if="noPaymentInfo">
                    <b-col class="text-center bg-red mx-3">
                        <p class="text-white text-sm">Missing payment information</p>
                    </b-col>
                </b-row>
            </b-col>
        </b-overlay>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "PRNViewer",
    props: {
        trackingNumber: String,
        prn: String
    },
    data() {
        return {
            paymentInfo: {},
            prnInfo: {},
            transactions: [],
            hasTransactionDetails: true,
            noPaymentInfo: false,
            loading: true,
            details: null,
            selectedIdx: null,
            success_table: "min-w-full leading-normal mt-1 text-green-dark success-header",
            success_border: "success-header",
            warning_table: "min-w-full leading-normal mt-1 text-orange warning-header",
            warning_border: "warning-header",
            failure_table: "min-w-full leading-normal mt-1 text-red failure-header",
            failure_border: "failure-header",
            pending_table: "min-w-full leading-normal mt-1 text-gray pending-header",
            pending_border: "pending-header"
        };
    },
    mounted() {
        this.fetchPaymentDetails(this.trackingNumber);
    },
    methods: {
        ...mapActions(["fetchPaymentDetails", "fetchPRNDetails"]),
        uraPayload(data) {
            if (data) {
                return JSON.parse(data)
            }
            return null
        },
        view(data, idx) {
            this.details = data
            this.selectedIdx = idx
        },
        close() {
            this.details = null
        },
        cleanString(name) {
            if (name) {
                return name.replaceAll("_", " ")
            }
            return ""
        }
    },
    computed: {
        ...mapGetters(["getPRNDetails", "getPaymentDetails", "getPaymentDetailsError"])
    },
    watch: {
        trackingNumber: {
            handle() {
                this.fetchPaymentDetails(this.trackingNumber);
            },
            deep: true
        },
        getPRNDetails(data) {
            this.loading = false
            this.paymentInfo = data
        },
        getPaymentDetails(data) {
            this.loading = false
            this.hasTransactionDetails = true
            this.transactions = data.data
            this.noPaymentInfo = false
        },
        getPaymentDetailsError(data) {
            this.loading = false
            this.hasTransactionDetails = false
            console.log(data)
            if (this.prn) {
                this.loading = true
                this.fetchPRNDetails({prn: this.prn})
            } else {
                this.noPaymentInfo = true
            }
        }
    }
};
</script>

<style scoped>
th, td {
    font-size: 12px;
}

.ura-header {
    @apply border border-blue
}

.bill-details tr {
    @apply border border-gray
}

.success-header {
    border: solid 1px #ccf5ba;
}

.warning-header {
    border: solid 1px #f5d5ba;
}

.failure-header {
    border: solid 1px #f8b0b8;
}

.pending-header {
    border: solid 1px #808080;
}


</style>