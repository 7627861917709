<template>
  <div>

    <b-row>

      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>

          <label class="my-1">Select the practitioner to work on this application.</label>


          <b-row class="flex py-2 px-3 border-b border-header_grey hover:bg-header_grey my-1"
                 v-for="(registrar, idx) in accounts"
                 :key="idx">
              <b-col cols="9">
                  <div class="flex cursor-pointer" @click="assign(registrar)">
                      <b-col class="ml-3">
                          <b-row>
                              <b-col>
                                  <p class="text-sm text-uppercase" v-if="registrar">
                                      <span v-if="registrar.backOfficeName	">{{registrar.backOfficeName	}}</span>
                                  </p>
                              </b-col>
                          </b-row>
                          <b-row>
                              <b-col>
                                  <p class="text-xs text-lowercase text-gray italic" v-if="registrar">{{registrar.backOfficeEmail	}}</p>
                              </b-col>
                          </b-row>
                      </b-col>
                  </div>
              </b-col>
              <b-col cols="3">
                  <button @click="assign(registrar)" class="button">
                      Select <i v-if="selectedId === registrar.individualAccountId" class="text-green fa fa-check-circle mx-1"></i>
                  </button>
              </b-col>
          </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button v-if="selectedId" class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Assign</button>
              <button disabled v-else class="px-3 py-2 bg-gray rounded-sm text-white w-full hover:bg-gray-dark" >
                  <i class="fa fa-question-circle-o text-white mr-1"></i> Assign</button>

          </b-col>
        </b-row>
      </b-modal>

        <b-modal id="modal-decline" hide-footer ref="decline" title="Confirm">
            <b-row>
                <b-col>
                    <p class="text-sm">
                        <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
                        This action can only be reversed by your superiors. Only confirm if you are certain that this is
                        the appropriate Final Decision
                    </p>
                    <p class="mt-3 text-sm italic">
                        <b>Note:</b><br/>
                        All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account
                        that initiated are tagged to the action.
                    </p>
                </b-col>
            </b-row>


            <b-row class="mt-3">
                <b-col cols="4">
                    <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full"
                            @click="cancelConfirmation">
                        <i class="fa fa-minus-square text-blue mr-2"></i>Cancel
                    </button>
                </b-col>
                <b-col cols="4"></b-col>
                <b-col cols="4">
                    <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark"
                            @click="declineAppointment(task.id)">
                        <i class="fa fa-check-circle text-white mr-1"></i> Accept
                    </button>
                </b-col>
            </b-row>
        </b-modal>

    </b-row>
    <b-row class="mx-1" v-if="task">
      <b-col cols="12">

        <b-row class="mt-1">
          <b-col cols="12">
           <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Insolvency  Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Type</th>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Winding Up Type</th>
                <th class="px-2 py-1">Has Assets</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.brn}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize" v-if="task.status">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.entityType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize"> {{task.companyName}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{task.windingUpType}}
                  </span>
                </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{task.hasAsset}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>

          <b-overlay :show="loading" class="text-center py-5">
        <b-row class="my-1">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">
<!--              <button class="btn-escalate" @click="escalatePrompt">-->
<!--                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate-->
<!--              </button>-->
<!--              <button type="button" class="btn-query" v-b-modal.modal-query>-->
<!--                <i class="fa fa-question text-white mr-1"></i> Query-->
<!--              </button>-->
<!--              <button v-if="(task.stage==='FINAL_REPORT_NOTICE') && (task.type === 'WINDING_UP') && (task.hasAsset === 'YES')" type="button" class="btn-date" @click="addDissolutionDate">-->
<!--                <i class="fa fa-calendar text-white mr-1"></i> Add Dissolution Date-->
<!--              </button>-->
<!--              <button v-if="(task.stage==='NOTICE' || task.stage==='GAZETTE_OR_NEWSPAPER_NOTICES'  ) && (task.type === 'WINDING_UP') && (task.hasAsset === 'NO')" type="button" class="btn-date" @click="addDissolutionDate">-->
<!--                <i class="fa fa-calendar text-white mr-1"></i> Add Dissolution Date-->
<!--              </button>-->
<!--              <button v-if="(task.stage==='REPORTS') && (task.type === 'WINDING_UP')" type="button" class="btn-date" @click="addDissolutionDate">-->
<!--                <i class="fa fa-calendar text-white mr-1"></i> Add Dissolution Date-->
<!--              </button>-->
<!--                <button v-if=" (task.type === 'WINDING_UP' ||-->
<!--                 task.type==='FOREIGN_COMPANY_DE_REGISTRATION')"-->
<!--                        type="button" class="btn-reject" @click="onApprove('REJECTED')">-->
<!--                    <i class="fa fa-check-circle text-white mr-1"></i> Decline-->
<!--                </button>-->
                <button type="button" class="btn-reject" @click="onDecline()">
                    <i class="fa fa-check-circle text-white mr-1"></i> Decline
                </button>
                <button type="button" class="btn-approve" @click="onApprove()">
                    <i class="fa fa-check-circle text-white mr-1"></i> Accept
              </button>

                <button class="btn-escalate" @click="escalatePrompt">
                                    <i class="fa fa-arrow-right text-blue mr-1"></i>Next
                                 </button>

            </div>
          </b-col>
        </b-row>
          </b-overlay>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Company Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mx-auto">
                <WindingInsolvencyForms v-if="currentRoute === 'forms'" :task="task"/>
                <InsolvencyFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                <InsolvencyMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </b-col>
    </b-row>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import WindingInsolvencyForms from "@/views/home/services/insolvency/task-queue/WindingInsolvencyForms.vue";
import InsolvencyFormFiles from "@/views/home/services/insolvency/task-queue/InsolvencyFormFiles.vue";
import InsolvencyMessages from "@/views/home/services/insolvency/task-queue/InsolvencyMessages.vue";

import {api} from "@/app/services/api.service";

export default {
  name: "CourtOrderVerificationTask",
  components: {
    InsolvencyMessages,
    InsolvencyFormFiles,
    WindingInsolvencyForms,
  },
  props:{
    task:Object,
    taskType:String
  },
  data(){
    return{
      margin:"2px",
      currentRoute: "forms",
      loading:false,
      messages:[],
      taskId:null,
      action:null,
      maxText:500,
      snapshot:null,
      snapshots:[],
      dateOfDissolution:null,
      form:{
        insolvencyProcessId:null,
        description:null,
        status:null
      },
      edmsDocuments:[],
      loadingDocuments:false,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
        accounts: [],
        selectedId: null
    }
  },
  mounted(){
    this.checkTask()
  },
  methods:{
    ...mapActions(["updateInsolvencyStatus","fetchInsolvencyTask"]),
    checkTask(){
        this.fetchOfficialReceivers()
    },


      fetchOfficialReceivers() {
      api().get("insolvency/official-receivers?pagination=false").then(response => {
          this.loading = false
          this.accounts = response.data.data
      }).catch(error => {
          this.loading = false
          this.errorToast(error.message)
      })


  },
      assign(registrar) {
          this.selectedId = registrar.individualAccountId
      },

    onApprove(){
      this.$refs.confirm.show();
    },
      onDecline(){
          this.$refs.decline.show();
      },
    onQuery(){
      this.form.insolvencyProcessId = this.task.id
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
        this.loading = true
        let payload = {
            "userId": this.selectedId,
            "processId": this.task.id
        }
        api().post("insolvency/process/assign-official-receiver", payload).then(() => {
            this.loading = false
            this.successToast("Success", "Appointment Assigned")
            window.location.reload()
        }).catch(error => {
            this.loading = false
            this.errorToast("Error", error.response.data.message)
        })
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
        this.$refs.decline.hide();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    },
      declineAppointment() {
          this.loading = true
          let appointmentid = this.task.appointments[0].id
          api().post("insolvency/process/decline-practitioner-appointment", {"appointmentId": appointmentid}).then(() => {
              this.loading = false
              this.successToast("Success", "Appointment Declined")
              this.fetchInsolvencyTask(this.taskType)
          }).catch(error => {
              this.loading = false
              this.errorToast("Error", error.response.data.message)
          })
      }
  },
  computed:{
    ...mapGetters([
      "getInsolvencyStatusSuccess",
      "getInsolvencyTaskError",
        "getInsolvencyError"
    ])
  },
  watch:{
    task(data){
      if(data){
        this.checkTask()
      }
    },
    getInsolvencyTaskError(data){
      this.loading = false
      this.errorToast("Error", data.messages)
    },
    getInsolvencyStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      window.location.reload()
    },
      getInsolvencyError(error){
          this.errorToast("Insolvency  Error", error.data.message);
      }
  }
}
</script>

<style scoped>

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-date{
  @apply bg-green-dark rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-reject{
  @apply border border-blue bg-red rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

</style>