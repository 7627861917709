<template>
  <b-row>
    <b-col class="mx-auto">
      <b-form-input class="text-sm mt-1" placeholder="Enter PRN" v-model="form.prn" v-on:keydown.enter="checkPRN" />
      <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
      <small v-if="form.prn">{{ form.prn.length }} digits</small>
      <b-row class="mt-2">
        <b-col v-if="paymentError">
          <p class="text-red my-2 border border-gray rounded-sm p-2 text-xs">
            <span>Error Code: <b>{{ paymentError.errorCode }}</b></span><br />
            <span>
                Message:<br />
                <b-form-textarea class="px-2 py-1 bg-red-light text-xs text-red rounded"
                                 :value="paymentError.errorDesc"></b-form-textarea>
              </span>
          </p>
        </b-col>
        <b-col v-if="paymentInfo.statusCode !== 'WW'" class="text-xs">
          <p class="font-bold font-monospace">URA Response</p>
          <p>PRN: {{ paymentInfo.prn }}</p>
          <p v-if="paymentInfo.amountPaid">Amount: {{ Number(paymentInfo.amountPaid).toLocaleString() }}</p>
          <p v-else>Amount: 0</p>
          <p>Tax Head: {{ paymentInfo.taxHeadCode }}</p>
          <p>Tax Payer: {{ paymentInfo.taxPayerName }}</p>
          <p>Tax Head Desc...: {{ paymentInfo.taxHeadName }}</p>
          <p>Status: {{ paymentInfo.statusDesc }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col v-if="getBillDetails" class="text-xs">
          <p class="font-bold font-monospace">Bill Response</p>
          <p>Tracking Number: {{getBillDetails.data.trackingNumber}}</p>
          <p>Transaction Type: {{getBillDetails.data.transactionType.toUpperCase()}}</p>
          <p>Payment Method: {{getBillDetails.data.paymentMethod}}</p>
          <p v-if="getBillDetails.data.msisdn">Phone Number: {{getBillDetails.data.msisdn}}</p>
          <p>Amount Paid: {{getBillDetails.data.grandTotal.toLocaleString()}}</p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "VerifyPRN",
  data() {
    return {
      form: {
        trackingNumber: null,
        prn: null,
        userId: null
      },
      paymentInfo: {
        statusCode: "WW"
      },
      paymentError: null,
      loading: false,
      showDisclaimer: false,
      loadingSave: false
    };
  },
  methods: {
    ...mapActions(["fetchPRNDetails", "fetchBillDetails"]),
    checkPRN() {
      if(this.form.prn){
        this.loading = true;
        this.showDisclaimer = false;
        this.fetchPRNDetails({ prn: this.form.prn });
        this.fetchBillDetails(this.form.prn)
      }else{
        this.paymentError = null
        this.paymentInfo.statusCode = "WW"
        this.showDisclaimer = false;
      }
    }
  },
  computed: {
    ...mapGetters(["getPRNDetails", "getPRNDetailsError", "getBillDetails"])
  },
  watch: {
    getPRNDetails(data) {
      this.loading = false;
      this.showDisclaimer = true;
      if (data.errorCode.length > 2) {
        this.paymentError = data;
      }
      this.paymentInfo = data;
    },
    getPRNDetailsError(data) {
      this.loading = false;
      this.errorToast("Error", data.data.error);
    }
  }

};
</script>

<style scoped>

</style>