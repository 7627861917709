<template>
  <div class="w-full">
    <b-row>
      <b-col cols="12" lg="2" md="4">
        <b-form-input
            class="text-gray text-sm noFocus mx-2 my-2"
            style="padding:6px 5px;"
            v-model="query.practitionerNumber"
            type="text"
            placeholder="Search by Practitioner No."
            required
            @keyup.enter="fetch">
        </b-form-input>
      </b-col>
    </b-row>
    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>
    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">Tracking No.</th>
            <th class="t-header">Practitioner</th>
            <th class="t-header">Complainant</th>
            <th class="t-header">Complaint</th>
            <th class="t-header text-center">Status</th>
            <th class="t-header text-center">Date</th>
          </tr>
          </thead>

          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(complaint, idx) in complaints" :key="idx">
            <td class="t-body">{{complaint.trackingNumber}}</td>
            <td class="t-body font-bold text-capitalize">
              <b-link class="text-blue hover:text-blue-dark" :href="'/home/registry/practitioner-viewer/'+complaint.uniquePractitionerNumber">
                {{complaint.practitionerName}}
              </b-link>
            </td>
            <td class="t-body text-capitalize">{{complaint.complainantName}} </td>
            <td class="t-body text-capitalize">
              {{complaint.complaint}} <i :id="'complaint-details-'+(idx+1)" href="#" tabindex="0" class="fa fa-info-circle text-blue ml-2 cursor-pointer"></i>
              <b-popover :target="'complaint-details-'+(idx+1)" triggers="focus" offset="1" placement="bottom">
                <template #title>{{complaint.complaint}}</template>
                {{complaint.description}}
              </b-popover>
            </td>
            <td class="t-body text-center text-capitalize">{{complaint.status}}</td>
            <td class="t-body text-center">{{dateFormat(complaint.dateOfApplication)}}</td>
          </tr>
          <tr>
            <td v-if="complaints.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(query.page > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{query.page}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(query.page < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col class="9"></b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "ResolvedPractitionerComplaints",
  mounted() {
    this.loading = true;
    this.fetchPractitionerComplaints(this.query)
  },
  data() {
    return {
      loading: false,
      complaints: [],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      query: {
        page: 1,
        perPage: 20,
        status: 'RESOLVED',
        practitionerNumber: null,
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  methods: {
    ...mapActions([
      "fetchPractitionerComplaints",
    ]),
    fetch(){
      this.loading = true
      this.fetchPractitionerComplaints(this.query)
    },
    next(){
      if(this.query.page < this.pagination.totalPages){
        this.query.page++
        this.fetch()
      }
    },
    previous(){
      if(this.query.page > 1){
        this.query.page--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
  computed: {
    ...mapGetters([
      "getPractitionerComplaint",
      "getPractitionerComplaintError",
      "getPractitionerComplaints",
      "getPractitionerComplaintsError",
    ])
  },
  watch: {
    getPractitionerComplaints(response){
      this.complaints = response.data
      this.loading = false;
    },
  },
  props: {

  },
}
</script>

<style scoped>

</style>