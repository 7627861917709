<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Reservation Extension Applications</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="flex mx-3 mt-3">
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="primary">
          <span class="h3 mx-auto">{{getReservationExtensionStatistics.pending}}</span>
        </div>
        <div class="primary">
          <span class="text-sm mx-auto">Pending</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="positive">
          <span class="h3 mx-auto">{{getReservationExtensionStatistics.approved}}</span>
        </div>
        <div class="positive">
          <span class="text-sm mx-auto">Approved</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="negative">
          <span class="h3 mx-auto">{{getReservationExtensionStatistics.rejected}}</span>
        </div>
        <div class="negative">
          <span class="text-sm mx-auto">Rejected</span>
        </div>
      </div>
    </b-row>
    <b-row class="flex">
      <b-col cols="12">
        <b-row class="pt-3">
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'pending') ? 'menu-item-active' : 'menu-item']" @click="select('pending')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Pending Applications</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'approved') ? 'menu-item-active' : 'menu-item']" @click="select('approved')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Approved Applications</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'rejected') ? 'menu-item-active' : 'menu-item']" @click="select('rejected')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Rejected Applications</div>
                </div>
              </div>

            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <PendingReservationExtensions v-if="currentRoute === 'pending'"/>
            <ApprovedReservationExtensions v-if="currentRoute === 'approved'"/>
            <RejectedReservationExtensions v-if="currentRoute === 'rejected'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import RejectedReservationExtensions from "@/views/home/services/reservation/extensions/RejectedReservationExtensions";
import ApprovedReservationExtensions from "@/views/home/services/reservation/extensions/ApprovedReservationExtensions";
import {mapActions, mapGetters} from "vuex"
import PendingReservationExtensions from "@/views/home/services/reservation/extensions/PendingReservationExtensions";

export default {
  name: "NameReservationExtensions",
  components: {
    PendingReservationExtensions,
    RejectedReservationExtensions, ApprovedReservationExtensions},
  data(){
    return{
      currentRoute: "pending",
    }
  },
  mounted(){
    this.fetchReservationExtensionStats()
  },
  methods:{
    ...mapActions(["fetchReservationExtensionStats"]),
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getReservationExtensionStatistics"])
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";
.primary{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.negative{
  @apply flex text-red-light;
}
.stat-count{
  display: inline;
  width: 200px;
  margin: 1px;
}
</style>