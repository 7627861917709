<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Change Of Particulars
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <BusinessParticulars :brn="brn" badge="Current Particulars"/>
        </b-col>
        <b-col>
          <NewBusinessParticulars :data="data" badge="New Particulars"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import BusinessParticulars from "@/views/home/services/amendments/task/forms/BusinessParticulars";
import NewBusinessParticulars from "@/views/home/services/amendments/task/forms/NewBusinessParticulars";

export default {
  name: "ChangeInParticulars",
  components: { NewBusinessParticulars, BusinessParticulars },
  props:{
    data:Object,
    brn:String
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>

</style>