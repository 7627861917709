<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12">
            <b-row v-if="getAnalysisReport.length > 0">
              <b-col cols="12" v-for="(option, idx) in getAnalysisReport" :key="idx">
                <b-row  >
                  <b-col>
                    <b-row>
                      <b-col class="mt-1">
                        <span class="text-blue text-sm font-bold">{{option.name}}</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col >
                        <span class="text-gray text-sm">Dictionary Flags</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <table class="min-w-full leading-normal">
                          <thead>
                            <tr class="border-0 bg-white py-2">
                              <th class="px-2 py-1">#</th>
                              <th class="px-2 py-1">Word</th>
                              <th class="px-2 py-1">Position</th>
                              <th class="px-2 py-1">Dictionary</th>
                              <th class="px-2 py-1">Reason</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(flag, idx) in option.flags"
                                :key="idx">
                              <td class="px-2 py-1">{{(idx+1)}}</td>
                              <td class="px-2 py-1 font-bold">{{flag.word}}</td>
                              <td class="px-2 py-1">{{flag.position}}</td>
                              <td class="px-2 py-1">{{flag.dictionary.name}}</td>
                              <td class="px-2 py-1">
                                {{flag.description}}
                              </td>
                            </tr>
                            <tr>
                              <td v-if="option.flags.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
                                <span class="text-sm">No results found</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="mt-2">
                        <span class="text-gray text-sm">Rejected Name Flags</span>
                      </b-col>
                    </b-row>
                    <b-row >
                      <b-col>
                        <table class="min-w-full leading-normal">
                          <thead>
                          <tr class="border-0 bg-white py-2">
                            <th class="px-2 py-1">#</th>
                            <th class="px-2 py-1">Name</th>
                            <th class="px-2 py-1">Reason</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr :class="[(idx%2) ? rows.even : rows.odd]"
                              v-for="(flag, idx) in option.rejectedNames" :key="idx">
                            <td class="px-2 py-1">
                              {{(idx+1)}}
                            </td>
                            <td class="px-2 py-1 font-bold">
                              {{flag.name}}
                            </td>
                            <td class="px-2 py-1">
                              {{flag.rejectedReason}}
                            </td>
                          </tr>
                          <tr>
                            <td v-if="option.rejectedNames.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
                              <span class="text-sm">No results found</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" class="mb-5">
                        <hr/>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col class="mt-1">
                <span class="text-sm px-2 py-1 rounded-md text-blue" style="background: #f1f1f1">No flags available from the analysis report</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "SystemFlags",
  data(){
    return{
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  methods:{

  },
  computed:{
    ...mapGetters(["getReservationTask", "getAnalysisReport", "getAnalysisReportError"]),
  },
  watch:{

  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

th, td{
  font-size: 11px;
}

span{
  font-size: 12px;
}

</style>