<template>
  <b-row>
    <b-col cols="12">
      <EDMSDocument v-if="edmsId" :file="file"/>
      <b-row >
        <b-col>
          <b-spinner v-if="loading" small></b-spinner>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument.vue";
export default {
  name: "EdmsIdToDocument",
  components: { EDMSDocument },
  props:{
    edmsId:String
  },
  data(){
    return{
      loading:false,
      file:{
        extension:"pdf",
        Base64FileStream:null
      }
    }
  },
  mounted() {
    if(this.edmsId){
      this.fetch()
    }
  },
  methods:{
    ...mapActions(["fetchCompanyEDMSFile"]),
    fetch(){
      this.loading = true
      this.fetchCompanyEDMSFile(
        {
          documentHandle:this.edmsId,
          overlay:""
        }
      )
    }
  },
  computed:{
    ...mapGetters(["getEDMSFile", "getEntityFileError"])
  },
  watch: {
    edmsId:{
      handler(data){
        if(data){
          this.fetch()
        }
      },
      deep:true
    },
    getEDMSFile(data){
      this.loading = false
      console.log(data)
      //this.file.Base64FileStream = data.Base64FileStream
    },
    getEntityFileError(){
      this.loading = false
      this.errorToast("Document Error", "Failed to load document from EDMS")
    }
  }
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 1000px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>