<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col class="px-4" cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Nature Of Business</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Attachments</div>
              </div>
            </div>
            <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Messages</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row v-if="currentRoute === 'forms'">
            <b-col class="px-4">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale" style="border: solid 1px #dedede;">
                  <td colspan="12" class="px-2 py-2">Nature Of Business</td>
                </tr>
                </thead>
                <tbody v-if="application.isic">
                  <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">
                      <span class="text-gray font-bold">Category</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-gray text-capitalize">{{application.isic.description}}</span>
                    </td>
                  </tr>
                  <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">
                      <span class="text-gray font-bold">ISIC Code</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-gray">{{application.isic.code}}</span>
                    </td>
                  </tr>
                  <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">
                      <span class="text-gray font-bold">Breakdown</span>
                    </td>
                    <td class="px-2 py-1">
                      <span v-if="application.isic" class="mt-2">
                        <p v-for="(item, idx) in application.isic.breakdown" :key="idx" :style="'margin-left: '+(idx * 10)+'px'">
                          - {{item}}
                        </p>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="px-2 py-1">waiting...</td>
                    <td class="px-2 py-1">waiting...</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <ReservationFileDetails :application="application" v-if="currentRoute === 'attachments'"/>
          <ReservationDetailsMessages v-if="currentRoute === 'messages'" :reservation-id="application.id"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import ReservationDetailsMessages from "@/views/home/services/reservation/submissions/ReservationDetailsMessages.vue";
import ReservationFileDetails from "@/views/home/services/reservation/components/ReservationFileDetails.vue";

export default {
  name: "ReservationPopUpDetails",
  components: {ReservationFileDetails, ReservationDetailsMessages},
  props:{
    application: {
      type: Object,
      required: true
    }
  },
  data() {
    return{
      currentRoute: "forms",
    }
  },
  methods: {
    select(route){
      this.currentRoute = route
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>