<template>
  <div>
    <b-row class="my-3">
      <b-col cols="12">
        <b-row v-if="application.data != null">
          <b-col cols="12">
            <b-row class="mt-2">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray"
                       style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Members</td>
                  </tr>
                  </thead>
                  <tbody>
                  <BSIMembers class="p-1" :persons="application.data.membership.members"/>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-3">
                <table class="min-w-full leading-normal mt-1 text-gray"
                       style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Society Address</td>
                  </tr>
                  </thead>
                  <tbody>
                  <b-row class="mx-1 py-1">
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Street"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.address.address.street"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Plot"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.address.address.plot"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Postal Address"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.address.address.postalAddress"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray"
                       style="border: solid 1px #dedede;" v-if="application.data.meetingPlace.meetingPlacePhysicalAddress">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Meeting Place</td>
                  </tr>
                  </thead>
                  <tbody>
                  <b-row class="mx-1 py-1">
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Region"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlacePhysicalAddress.region.name"
                            required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="District"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlacePhysicalAddress.district.name.toLowerCase()"
                            required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Sub County"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlacePhysicalAddress.subcounty.name"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Parish"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlacePhysicalAddress.parish.name"
                            required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Village"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlacePhysicalAddress.village.name"
                            required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Street"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlace.street"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Plot"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlace.plot"
                            required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Postal Address"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.data.meetingPlace.meetingPlace.postalAddress"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="mt-4">
                <table class="min-w-full leading-normal mt-1 text-blue-dark"
                       style="border: solid 1px #bdd7f1;">
                  <thead>
                  <tr class="bg-blue-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1">Email</th>
                    <th class="px-2 py-1">Phone</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #bdd7f1;">
                    <td class="px-2 py-1 text-capitalize">{{application.applicant.givenName}} {{application.applicant.surname}}</td>
                    <td class="px-2 py-1">{{application.applicant.email}}</td>
                    <td class="px-2 py-1">{{application.applicant.phoneNumber}}</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col cols="6" class="mt-4">
                <table class="min-w-full leading-normal mt-1 text-orange-dark"
                       style="border: solid 1px #f1b778">
                  <thead>
                  <tr class="bg-orange-pale font-bold">
                    <td colspan="12" class="px-2 py-2">Official Account Details (To be Created)</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Email</th>
                    <th class="px-2 py-1">Phone</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #f1b778">
                    <td class="px-2 py-1">
                          <span class="text-orange">
                            {{application.data.contact.primaryEmail}}
                          </span>
                    </td>
                    <td class="px-2 py-1">
                          <span class="text-orange">
                            {{application.data.contact.primaryPhone}}
                          </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import BSIMembers from "@/views/home/services/bsi_incorporation/BSIMembers";

export default {
  name: "BuildingSocietyFormDetailsEscalated",
  components: {BSIMembers},
  props:{
    application:Object
  },
  mounted() {
    console.log(JSON.stringify(this.application))
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>