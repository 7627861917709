<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Transmission of Shares
          </b-badge>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-row v-for="(transmission, idx) in data.transmissions" :key="idx" class="border-b border-gray py-3">
            <b-col>
              <b-row>
                <b-col>
                  <p>Deceased Shareholder: <b class="font-bold text-uppercase">{{transmission.transmitter.name}}</b></p>
                </b-col>
                <b-col>
                  <p>Administrator/Executor/Shareholder(s): <br/>
                    <b class="font-bold text-uppercase" v-for="(person, idx) in transmission.transferees" :key="idx">
                      {{idx + 1}}) {{person.transferee.name}}
                    </b>
                  </p>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;" v-for="(transferee, idx) in transmission.transferees" :key="idx">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">{{transferee.transferee.name}}</td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Paid</th>
                        <th class="px-2 py-1 text-center">Not Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in transferee.shares" :key="idx">
                        <tr style="border: solid 1px #dedede;">
                          <td class="px-2 py-1 text-uppercase font-bold">
                            <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                            <span v-else>{{share.otherClassName}}</span>
                          </td>
                          <td class="px-2 py-1 text-center">
                            <span v-if="share.subClasses.length === 0">{{share.numberOfShares.toLocaleString()}}</span>
                          </td>
                          <td class="px-2 py-1 text-center">
                            <span v-if="share.subClasses.length === 0">{{share.numberOfPaidShares.toLocaleString()}}</span>
                          </td>
                          <td class="px-2 py-1 text-center">
                            <span v-if="share.subClasses.length === 0">{{share.numberOfUnPaidShares.toLocaleString()}}</span>
                          </td>
                        </tr>
                        <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                          <td class="px-2 py-1 text-capitalize">
                            <span>Class {{subClass.nominalClass}}</span>
                          </td>
                          <td class="px-2 py-1 text-center">
                            <span>{{subClass.numberOfShares.toLocaleString()}}</span>
                          </td>
                          <td class="px-2 py-1 text-center">
                            <span>{{subClass.numberOfPaidShares.toLocaleString()}}</span>
                          </td>
                          <td class="px-2 py-1 text-center">
                            <span>{{subClass.numberOfUnPaidShares.toLocaleString()}}</span>
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </b-col>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-green"
                           style="border: solid 1px #dedede;" >
                      <thead>
                      <tr class="bg-green-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">{{transmission.transmitter.name.toUpperCase()}} (DECEASED) ShareHolding <b-badge class="bg-green font-monospace">initial</b-badge></td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Paid</th>
                        <th class="px-2 py-1 text-center">Not Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in transmission.initialTransmitterShareholding" :key="idx">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-col>
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-red"
                           style="border: solid 1px #dedede;" >
                      <thead>
                      <tr class="bg-red-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">{{transmission.transmitter.name.toUpperCase()}} (DECEASED) ShareHolding <b-badge class="bg-red font-monospace">final</b-badge></td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Paid</th>
                        <th class="px-2 py-1 text-center">Not Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in transmission.finalTransmitterShareholding " :key="idx">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TransmissionOfShares",
  props:{
    data:Object
  },
};
</script>

<style scoped>

</style>