import { api } from "@/app/services/api.service";

const state = {
  occupations: []

};
export default {
  state,
  getters: {
    getOccupations: state => state.occupations
  },
  mutations: {
    setOccupations: (state, payload) => {
      state.occupations = payload.data;
    }
  },
  actions: {
    async fetchOccupations({ commit }) {
      await api().get("/configuration/occupation/all").then(response => {
        commit("setOccupations", response);
      }).catch(error => {
        commit("setOccupations", error.response);
      });
    }
  }
};