
<template>
  <div>
    <b-col cols="12">
      <div class="mt-5 mb-4">
        <p><b>Attachments</b></p>
      </div>


      <div v-if="errorMessage" class="mt-2 mb-4 text-sm text-gray-600 bg-red-500 bg-opacity-75 p-2 rounded alertStyle">
        <a href="#" class="font-medium text-red">
          {{ errorMessage }}
        </a>
      </div>


      <b-row>
        <b-col cols="12" class="mx-auto">

          <label class="text-gray mb-1" style="font-size: 14px;">Upload Files<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
          <b-form-group>
            <b-form-select
              @change="clearDescription"
              class="form-select noFocus text-gray mb-2 text-sm"
              v-model="fileDescription"
              :options="uploadFileListing">
            </b-form-select>
          </b-form-group>

          <b-input-group>
            <b-form-file
              accept="application/pdf"
              label-size="sm"
              type="file"
              placeholder="Please provide a copy of you id..."
              drop-placeholder="Drop file here..."
              v-model="uploadFile"
              class="form-control noFocus removeOnClickBorder rounded-0 mb-2 text-sm"
              plain></b-form-file>
          </b-input-group>

          <b-form-textarea
            v-if="fileDescription === 'EXTRA_ATTACHMENT'"
            v-model="description"
            type="text"
            placeholder="Add description on this file.."
            rows="3"
            class="mt-1 mb-3 noFocus"
            required>
          </b-form-textarea>

          <b-button
            :disabled="buttonAction"
            size="sm"
            squared
            @click="saveSignedCopyFile"
            class="removeOnCLickBorder noFocus w-full mb-5"
            variant="outline-primary"
          >
            <span>Upload</span>
            <b-spinner class="ml-4" v-if="loadingSignedCopyFile" small/>
          </b-button>


          <p v-if="uploadedAttachments.length > 0"><b>Uploaded Attachments</b></p>

          <b-list-group class="mt-3">
              <span v-for="(list, index) in uploadedAttachments" :key="index">
              <b-list-group-item >
               <span v-b-tooltip.hover.bottom title="Download file" class="fileTitle" style="cursor: pointer;" @click="downloadAttachments(list)">{{list.fileDescription}}</span>
                <b-spinner class="float-end" v-if="loadingDelete === index" small/>
                <i v-b-tooltip.hover.bottom title="Delete file" class="fa fa-times mr-1 float-end text-red" style="cursor: pointer;"  @click="deleteAttachments(list, index)"></i>
              </b-list-group-item>
              </span>
          </b-list-group>

        </b-col>
      </b-row>




    </b-col>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import server from "@/app/env/server.json";
import {api} from "@/app/services/api.service";

export default {
  name: "InsolvencyFileImport",
  /*props:{
    companyDraftData:{}
  },*/
  data(){
    return{
      no:1,
      fileList:[
        {
          file:null,
          fileDescription:"extra attachment",
        }
      ],
      errorMessage:null,
      businessId:null,
      applicationId:null,
      partnershipDeedFile:null,
      signedCopyFile:null,
      loadingFile:false,
      loadingSignedCopyFile:false,
      loadingExtraAttachmentFile:undefined,
      uploadFileListing:{},
      uploadedAttachments:[],
      fileDescription:"",
      description:"",
      uploadFile:null,
      loadingDelete:undefined,
      trackingNo:null,
      companyDraftData:{}
    }
  },
  mounted() {
    this.errorMessage = ""
    this.checkBusinessNumber()
    this.defaultData()
  },
  methods:{
    ...mapActions(
      [
        "fetchWindingDraftData",
          "fetchBankruptcyDraftData"
      ]
    ),
    clearDescription(){
      this.description = ""
    },
    checkBusinessNumber(){
      this.businessId = this.businessNumber

    },
    saveSignedCopyFile(){
      if(!this.pdfValidator(this.uploadFile)){
        this.errorToast("Error", "Invalid file, choose a pdf!")
        this.errorMessage = "Invalid file, choose a pdf!"
        return
      }
      this.loadingSignedCopyFile = true
      this.errorMessage = ""

      const formData = new FormData();
      formData.set("trackingNumber", this.trackingNo);
      formData.set("file", this.uploadFile);
      formData.set("description", this.description ? this.description : this.fileDescription);
      formData.set("purpose", this.description ? this.description : this.fileDescription);
      this.uploadUpdatePortalFile(formData)
    },
    async uploadUpdatePortalFile(data) {
      await api().post("/insolvency/process/add-attachment", data)
        .then((response) => {
          if(response){
            this.loadingSignedCopyFile = false
            this.successToast("Success", response.data.message)
            //fetch draft data
            this.fetchDraftData(this.trackingNo)
          }
        })
        .catch((error) => {
          this.loadingSignedCopyFile = false
          this.errorToast("Error",error.response.data.message)
          this.errorMessage = error.response.data.message
        });
    },
    defaultData(){
      if(this.companyDraftData) {
        let data = this.companyDraftData
        this.trackingNo = data.trackingNumber
        //this.applicationId = data.applicationResponse ? data.applicationResponse.id : null
        this.uploadedAttachments = data.attachments ? data.attachments : []
        let attachmentList = data.requiredAttachments

        if (attachmentList) {
          //upload list
          let arr = [{text: "--Select file to upload--", value: ""}]
          let obj = {}
          attachmentList.forEach(list => {
            obj = {text: list.description, value: list.identifier}
            arr.push(obj)
          })
          this.fileDescription = ""
          this.uploadFileListing = arr.concat([{text:"Add any extra attachment", value:'EXTRA_ATTACHMENT'}])
        }
      }
    },
    downloadAttachments(list){
      let url = server.IP+"/insolvency/files?attachment="+list.fileName
      window.open(
        url, "_blank");
    },
    deleteAttachments(list, index){
      this.loadingDelete = index
      let payload = {
        trackingNumber:this.trackingNo,
          fileDesc:list.fileName
      }
      this.deleteInsolvencyFileAttachment(payload)
    },
    async deleteInsolvencyFileAttachment( data) {
      await api().post("insolvency/process/remove-attachment",data)
        .then(() => {
          this.fetchDraftData(this.trackingNo)
        })
        .catch((error) => {
          this.errorToast(error.response.data.message);
        });
    },
      fetchDraftData(){
        // if route path contains provisional-appointment
        if(this.$route.path.includes("provisional-appointment")){
            this.fetchBankruptcyDraftData(this.trackingNo)
        } else {
            this.fetchWindingDraftData(this.trackingNo)
        }

      },
  },

  computed:{
    ...mapGetters(
      [
        "getNewPartnershipDeedFile",
        "getPartnershipUploadError",
        "getNewSignedCopyFile",
        "getDeleteBusinessFileAttachmentData",
        "getFileDeletionError",
        "getWindingDraftData"
      ]
    ),
    buttonAction(){
      return (!(this.fileDescription && this.uploadFile))
    }
  },
  watch:{
    businessNumber(){
      this.checkBusinessNumber()
    },
    companyDraftData(){
      this.defaultData()
    },
    getFileDeletionError(data){
      if(data){
        this.loadingDelete = undefined
        this.errorToast("Error", data.message)
      }
    },
    getWindingDraftData(data){
      if(data){
        this.trackingNo = data.data.trackingNumber
        this.companyDraftData = data.data
      }
    }


  }
}
</script>

<style scoped>
.alertStyle{
  border: 1px solid red;
}
</style>
