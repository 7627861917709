<template>
  <div>
    <b-row v-if="task.data != null">
      <b-col cols="12" class="my-3">
        <b-row>
          <b-col cols="12" v-if="task.data.subType !== 'foreign-company'">
            <b-row>
              <b-col cols="12">
                <b-row>
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form S18</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Name of Company</td>
                        <td class="px-2 py-1">{{task.data.name}}</td>
                      </tr>
                      <tr style="border: solid 1px #dedede;" v-if="task.data.subType !== 'private-company-limited-by-guarantee-without-shares'">
                        <td class="px-2 py-1 text-capitalize font-bold">Share Capital</td>
                        <td class="px-2 py-1">
                          <span v-if="task.data.formS18.currency">{{task.data.formS18.currency}}.</span>
                          <span v-if="task.data.formS18.proposedShareCapital != null"> {{task.data.formS18.proposedShareCapital.toLocaleString()}}</span>
                          <span v-else>N/A</span>
                        </td>
                      </tr>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Nature of Business</td>
                        <td class="px-2 py-1">
                          {{task.data.formS18.isic.description}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Subscribers</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons class="p-1" :persons="task.data.formS18.subscribers"/>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="task.data.formA1 !== null">
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form A1</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Nominal Share Capital ({{ task.data.formS18.currency }})</td>
                        <td class="px-2 py-1" v-if="task.data.formA1.nominalShareCapital != null">
                          {{task.data.formA1.nominalShareCapital.toLocaleString()}}
                        </td>
                      </tr>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Nominal Number of Shares</td>
                        <td class="px-2 py-1" v-if="task.data.formA1.nominalNumberOfShares != null">
                          {{task.data.formA1.nominalNumberOfShares.toLocaleString()}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Share Classification</td>
                      </tr>
                      <tr>
                        <th class="px-2 py-1">Nominal Class</th>
                        <th class="px-2 py-1">Shares</th>
                        <th class="px-2 py-1 float-end">Value({{task.data.formS18.currency }})</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;" v-for="(cls, idx) in task.data.formA1.nominalShareClassifications" :key="idx">
                        <td class="px-2 py-1 text-blue">
                          <span class="text-capitalize">{{cls.nominalClass.toLowerCase()}}</span>
                          <span class="text-capitalize" v-if="cls.nominalClass === 'OTHER'">/ {{cls.otherClassName.toLowerCase()}}</span>
                        </td>
                        <td class="px-2 py-1">
                          <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 float-end">
                          <span v-if="cls.value">{{cls.value.toLocaleString()}}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Advocate</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize">
                          Position in Company: <b>{{task.data.formA1.advocatePositionInCompany.toLowerCase()}}</b>
                        </td>
                      </tr>
                      <tr style="border: solid 1px #dedede;" v-if="task.data.formA1.advocatePositionInCompany === 'ADVOCATE'">
                        <td class="px-2 py-1 text-capitalize">
                              <span>
                                Advocate Name: <b>{{task.data.formA1.advocateName}}</b>
                              </span>
                        </td>
                      </tr>
                      <LegalPersons class="p-1" :persons="[task.data.formA1.advocate]"/>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="task.data.form1 !== null">
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 1 (SMC)</td>
                      </tr>
                      <tr class="bg-gray-pale border-t border-grey">
                        <td colspan="12" class="px-2 py-2 font-bold text-capitalize">Nominee Director</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons class="p-1" :persons="[task.data.form1.nomineeDirector]"/>
                      </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold text-capitalize">Alternate Director</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons class="p-1" :persons="[task.data.form1.alternativeNomineeDirector]"/>
                      </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold text-capitalize">Single Member Director</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons class="p-1" :persons="[task.data.form1.singleMember]"/>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="task.data.form20 !== null">
                  <b-col cols="12" class="mt-2">
                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 20 (Directors)</td>
                      </tr>
                      </thead>
                      <tbody v-if="task.data.form20.directors.length === 0">
                      <tr>
                        <td colspan="12">
                          None Found
                        </td>
                      </tr>
                      </tbody>
                      <tbody v-else>
                      <LegalPersons class="p-1" :persons="task.data.form20.directors"/>
                      </tbody>

                    </table>
                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 20 (Secretary)</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons class="p-1" :persons="[task.data.form20.secretary]"/>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2" v-if="task.data.form18 !== null">
                  <b-col cols="12" >
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 18 (Address)</td>
                      </tr>
                      </thead>
                      <tbody>
                      <b-row class="mx-1 py-1">
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Region"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.physicalAddress.region.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="District"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.physicalAddress.district.name.toLowerCase()"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Sub County"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.physicalAddress.subcounty.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Parish"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.physicalAddress.parish.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Village"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.physicalAddress.village.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Street"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.street"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Plot"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.plot"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Postal Address"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form18.address.postalAddress"
                                required>
                            </b-form-input>
                            <PostalAddressValidator v-if="task.data.form18.address.postalAddress" :box-number="task.data.form18.address.postalAddress"/>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12" >
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Memorandum & Articles of Association</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Adopted Table</td>
                        <td class="px-2 py-1">
                          {{ task.data.memarts.table.description }}
                        </td>
                      </tr>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Type</td>
                        <td class="px-2 py-1">
                          <span v-if="task.data.memarts.hasAttachment">Modified MemArts</span>
                          <span v-else>Lean MemArts</span>
                        </td>
                      </tr>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">With Modifications</td>
                        <td class="px-2 py-1">
                          <b-badge class="bg-blue text-uppercase">{{task.data.memarts.hasAttachment}}</b-badge>
                        </td>
                      </tr>
                      <tr v-if="task.data.subType.includes('guarantee')">
                        <td class="px-2 py-1 text-capitalize font-bold">Maximum Liability Amount ({{task.data.formS18.currency }})</td>
                        <td class="px-2 py-1">
                          <span v-if="task.data.memarts.maximumContributionPerSubscriber">{{task.data.memarts.maximumContributionPerSubscriber.toLocaleString()}}</span>
                          <span v-else>NIL</span>
                        </td>
                      </tr>
                      </tbody>

                    </table>

                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Subscription</td>
                      </tr>
                      </thead>
                    </table>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;" v-for="(subscription, idx) in task.data.memarts.subscriberShares" :key="idx">
                      <thead>
                      <tr style="border: solid 1px #dedede;">
                        <td colspan="12" class="px-2 py-2 font-bold text-uppercase text-blue">
                          {{subscription.subscriber.name}}
                          <b-badge class="bg-blue">
                            {{ subscription.totalNumberOfShares.toLocaleString() }}
                          </b-badge>
                        </td>
                      </tr>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Paid</th>
                        <th class="px-2 py-1 text-center">Not Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share,idx2) in subscription.shares" :key="idx2">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                              <span v-if="share.subClasses.length === 0">
                                {{share.numberOfShares.toLocaleString()}}
                              </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                              <span v-if="share.subClasses.length === 0">
                                {{share.amountPaid.toLocaleString()}}
                              </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                              <span v-if="share.subClasses.length === 0">
                                {{share.amountUnPaid.toLocaleString()}}
                              </span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                              <span>
                                {{subClass.numberOfShares.toLocaleString()}}
                              </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                              <span>
                                {{subClass.amountPaid.toLocaleString()}}
                              </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                              <span>
                                {{subClass.amountUnPaid.toLocaleString()}}
                              </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" v-else>
            <b-row>
              <b-col cols="12" class="mt-2">
                <b-row>
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 24</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Name of Company</td>
                        <td class="px-2 py-1 text-uppercase">{{task.data.name}}</td>
                      </tr>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Company Number</td>
                        <td class="px-2 py-1">{{task.data.foreignBrn}}</td>
                      </tr>
                      </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Directors</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons class="p-1" :persons="task.data.form24.directors"/>
                      </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Secretary</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons :persons="[task.data.form24.secretary]"/>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 25 (Representatives in the country)</td>
                      </tr>
                      </thead>
                      <tbody>
                      <LegalPersons class="p-1" :persons="task.data.form25.representatives"/>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 13 (Subsisting Charges)</td>
                      </tr>
                      <tr>
                        <th class="px-2 py-1">Date</th>
                        <th class="px-2 py-1">Instrument</th>
                        <th class="px-2 py-1">Particulars</th>
                        <th class="px-2 py-1">Amount Secured</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;" v-for="(detail, idx) in task.data.form13.mortgages" :key="idx">
                        <td class="px-2 py-1">
                          {{dateFormat(detail.dateOfCharge)}}
                        </td>
                        <td class="px-2 py-1 text-capitalize">
                          {{detail.descriptionOfInstrument}}
                        </td>
                        <td class="px-2 py-1 text-capitalize">
                          {{detail.entitleMortgageDescription}}
                        </td>
                        <td class="px-2 py-1">
                          <span v-if="detail.currency">{{detail.currency}}.</span> {{detail.amountSecured.toLocaleString()}}
                        </td>
                      </tr>
                      <tr>
                        <td v-if="task.data.form13.mortgages.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
                          <span class="text-sm">None found</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Form 26</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Country Of Incorporation</td>
                        <td class="px-2 py-1">{{task.data.form26.countryOfIncorporation}}</td>
                      </tr>
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Address In Parent Country</td>
                        <td class="px-2 py-1">{{task.data.form26.addressInCountryOfIncorporation}}</td>
                      </tr>
                      <tr style="border: solid 1px #dedede;" class="bg-gray-pale">
                        <td class="px-2 py-1 text-capitalize px-2 py-2 font-bold" colspan="12">Address In Uganda</td>
                      </tr>
                      <b-row class="mx-1 py-1">
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Region"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.physicalAddress.region.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="District"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.physicalAddress.district.name.toLowerCase()"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Sub County"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.physicalAddress.subcounty.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Parish"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.physicalAddress.parish.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Village"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.physicalAddress.village.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Street"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.street"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Plot"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.plot"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Postal Address"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.data.form26.address.postalAddress"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
              <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Beneficial Owners</td>
                </tr>
              </thead>
              <tbody v-if="task.data.beneficialOwnerForm">
                <tr style="border: solid 1px #dedede;">
                  <td class="t-body font-bold">Country</td>
                  <td class="t-body">
                    {{task.data.beneficialOwnerForm.country.name}}
                  </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                  <td class="t-body font-bold">Physical Address</td>
                  <td class="t-body text-uppercase">
                    <p v-if="task.data.beneficialOwnerForm.village">
                      <span class="mr-1">{{task.data.beneficialOwnerForm.village.region.name}} > </span>
                      <span class="mx-1">{{task.data.beneficialOwnerForm.village.district.name}} > </span>
                      <span class="mx-1">{{task.data.beneficialOwnerForm.village.subcounty.name}} > </span>
                      <span class="mx-1">{{task.data.beneficialOwnerForm.village.parish.name}} > </span>
                      <span class="mx-1">{{task.data.beneficialOwnerForm.village.village.name}}</span>
                    </p>
                    <p v-else>N/A</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
              <tbody v-if="task.data.beneficialOwnerForm">
                <tr>
                  <td colspan="12">
                    <BeneficialOwners :persons="task.data.beneficialOwnerForm.beneficialOwners"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1 text-capitalize">{{task.applicant.givenName}} {{task.applicant.surname}}</td>
                <td class="px-2 py-1">{{task.applicant.email}}</td>
                <td class="px-2 py-1">{{task.applicant.phoneNumber}}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="6">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Official Account Details (To be Created)</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1">
                  {{task.data.primaryEmail}}
                </td>
                <td class="px-2 py-1">
                  {{task.data.primaryPhone}}
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import PostalAddressValidator from "@/views/home/commons/PostalAddressValidator";
import BeneficialOwners from "@/views/home/commons/BeneficialOwners.vue";
import LegalPersons from "@/views/home/commons/LegalPersons.vue";

export default {
  name: "CompanyForms",
  components: { LegalPersons,
    BeneficialOwners,
    PostalAddressValidator},
  props:{
    task:Object
  },
  data(){
    return{
      attachmentUrl:null,
      individualDirectors:[],
      corporateDirectors:[],
      individualSecretary:null,
      corporateSecretary:null,
      adoptionTableText:null
    }
  },
  mounted() {
    this.corporateSecretary = this.task.data.form20.secretary.corporate
    this.individualSecretary = this.task.data.form20.secretary.individual

    let directors = this.task.data.form20.directors
    for(let i =0; i< directors.length; i++){
      let director = directors[i];
      if(director.entityType === "INDIVIDUAL"){
        this.individualDirectors.push(director.individual)
      }else{
        this.corporateDirectors.push(director.corporate)
      }
    }

    if(this.task.data.subType === "private-company-limited-by-shares"){
      this.adoptionTableText = "Table A & B of Companies Act 2012"
    }

    if(this.task.data.subType === "single-member-company"){
      this.adoptionTableText = "Table B & C of Companies Act 2012"
    }

    if(this.task.data.subType === "private-company-limited-by-guarantee-with-shares"){
      this.adoptionTableText = "Table D & C of Companies Act 2012"
    }

    if(this.task.data.subType === "private-company-limited-by-guarantee-without-shares"){
      this.adoptionTableText = "Table B & C of Companies Act 2012"
    }

    if(this.task.data.subType === "public-company"){
      this.adoptionTableText = "Table F & A of Companies Act 2012"
    }

    if(this.task.data.subType === "unlimited-company"){
      this.adoptionTableText = "Table E & A (part 1 excluding regulations 40 & 46 of Companies Act 2012"
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>