<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Incorporation">
        <Escalation :task-id="task.taskId" type="ci" permission="can_view_incorporation_escalations"/>
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.

            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
                v-model="form.reason"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
            </b-form-textarea>
            <p v-if="form.reason" class="my-2 text-xs">{{form.reason.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-form-select class="form-select mt-2" v-model="snapshot">
                  <b-select-option value="null">--Select a Snapshot--</b-select-option>
                  <b-select-option :value="option" v-for="(option, idx) in snapshots" :key="idx" class="text-uppercase">
                    {{option.snapshotType.replaceAll("_", " ")}} | {{dateTimeFormat(option.createdAt)}}
                  </b-select-option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row v-if="snapshot">
              <b-col>
                <p class="border-2 border-blue p-2 rounded text-white bg-blue my-2 text-sm">
                  <span class="h6">Snapshot Initiator</span><br/>
                  Name: {{snapshot.initiator.fullName}}<br/>
                  Email: {{snapshot.initiator.email}} <br/>
                  Phone: {{snapshot.initiator.phoneNumber}}<br/>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="task.lastEscalation">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
            <b-row>
              <b-col>
                <div class="flex">
                  <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                  <span class="text-sm font-bold underline text-capitalize">{{messages[0].messageData.sender.toLowerCase()}}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-sm">{{messages[0].messageData.comment}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span style="font-size:9px">{{dateFormat(messages[0].messageData.createdAt)}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Escalation Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="task.lastQuery">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
            <b-row>
              <b-col>
                <div class="flex">
                  <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                  <span class="text-sm font-bold underline text-capitalize">{{messages[0].messageData.sender.toLowerCase()}}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-sm">{{messages[0].messageData.comment}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span style="font-size:9px">{{dateFormat(messages[0].messageData.createdAt)}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Query Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{task.data.name}}
            </span>
          </b-col>

        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Incorporation fdfd Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.data.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.data.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <PRNViewer :tracking-number="task.data.trackingNo"/>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button type="button" class="btn-cancel"
                      v-if="canViewActionWithPermission('can_cancel_incorporation_reservation')"
                      @click="onCancel">
                <i class="fa fa-times text-white mr-1"></i> Cancel Reservation
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <CompanyFormDetails :task="task"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import CompanyFormDetails from "@/views/home/services/incorporation/task/CompanyFormDetails";
import Escalation from "@/views/home/commons/Escalation";
import PRNViewer from "@/views/home/commons/PRNViewer";

export default {
  name: "CompanyIncorporationTask",
  components: { PRNViewer, Escalation, CompanyFormDetails},
  data(){
    return{
      margin:"2px",
      currentRoute: "application-details",
      loading:false,
      messages:[],
      taskId:null,
      maxText:500,
      task:{
        data:null
      },
      snapshot:null,
      snapshots:[],
      form:{
        companyId:null,
        queueId:null,
        reason:null,
        status:null,
        askUserToCancelReservation:false
      },
    }
  },
  mounted() {
    this.fetchCompanyIncorporationTask()
  },
  methods:{
    ...mapActions(["updateIncorporationStatus", "fetchCompanyIncorporationTask", "fetchCompanyIncorporationTaskSnapshots", "fetchIncorporationTaskMessages"]),
    onApprove(){
      this.form.queueId = this.getIncorporationTask.taskId
      this.form.companyId = this.getIncorporationTask.data.id
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onCancel(){
      this.form.queueId = this.getIncorporationTask.taskId
      this.form.companyId = this.getIncorporationTask.data.id
      this.form.status = "QUERIED"
      this.form.askUserToCancelReservation = true
      this.action = "final"
      this.$refs["query-incorporation"].show();
    },
    onQuery(){
      this.form.queueId = this.getIncorporationTask.taskId
      this.form.companyId = this.getIncorporationTask.data.id
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.reason != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateIncorporationStatus(this.form)
      }

      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY h:mmA")
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters([
      "getIncorporationTask",
      "getCompanyUpdateStatusSuccess",
      "getCompanyUpdateStatusSuccessError",
      "getIncorporationTaskError",
      "getIncorporationTaskMessages",
      "getIncorporationSnapshots"
    ])
  },
  watch:{
    getIncorporationTask(data){
      this.task = data
      this.loading = false
      this.fetchIncorporationTaskMessages(data.taskId)
      this.fetchCompanyIncorporationTaskSnapshots(data.data.trackingNo)
      this.fetchIncorporationTaskCount()
      if(data.data == null){
        this.successToast("Success", "No task yet")
      }
    },
    getIncorporationSnapshots(data){
      this.snapshots = data.data.snapshots
    },
    snapshot:{
      handler(data){
        if(data){
          this.task.data = JSON.parse(data.snapshot)
          console.log(this.task)
        }else{
          this.fetchCompanyIncorporationTask()
        }
      },
      deep:true
    },
    getIncorporationTaskMessages(data){
      this.loading = false
      this.messages = data
    },
    getRegistrationError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data))
    },
    getCompanyUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      window.location.reload()
    },
    getCompanyUpdateStatusSuccessError(){
      this.loading = false
    },
  }
}
</script>

<style scoped>
@import "/../src/assets/styles/menu.css";

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

</style>