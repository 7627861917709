<template>
  <b-row>
    <b-col cols="12" class="py-1">
      <b-row class="mt-2">
        <b-col cols="12" class="text-center">
          <b-avatar class="mx-auto" size="5rem" src="https://picsum.photos/200/300?random=1"></b-avatar>
        </b-col>
      </b-row>

      <b-row class="mt-2 mb-3">
        <b-col cols="12" class="text-center">
          <b-row>
            <b-col cols="12">
              <span class="text-gray text-sm font-bold">
                {{userProfile.givenName}} {{userProfile.surName}}
              </span>
            </b-col>
          </b-row>
          <b-row class="leading-3">
            <b-col cols="12">
              <span class="text-grey text-xs text-capitalize">
                {{roleGetter(userProfile)}}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span class="text-capitalize text-gray text-sm">
                {{userProfile.accountType.toLowerCase()}}
              </span>
              <i v-if="userProfile.enabled" class="fa fa-check text-green text-xs"></i>
              <i v-else class="fa fa-window-close text-red text-xs"></i>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-center">
              <button class="button">Update Avatar</button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="text-center">
              <button class="outline-button" @click="logout">Logout</button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import jwtService from "@/app/services/jwt.service";

export default {
  name: "AccountInfo",
  data(){
    return{
      userProfile:{},
    }
  },
  mounted() {
    this.userProfile = jwtService.getUser()
  },
  methods:{
    roleGetter(){
      let roles = this.userProfile.userRoles
      let roleString = ""
      for(let i = 0; i< roles.length;  i++){
        let parts = roles[i].replaceAll("_", " ").replaceAll("ROLE", "").toLowerCase()

        roleString += parts.toString()
        if(roles.length - 1 > i){
          roleString += ", "
        }
      }
      return roleString
    },
    logout(){
      sessionStorage.clear()
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href);
    },
  }
}
</script>

<style scoped>
.button {
  @apply text-center rounded-sm bg-blue text-white px-5 py-1 font-bold;
  font-size: 12px;
}

.button:hover {
  @apply bg-blue-dark border-blue-dark;
}

.outline-button {
  @apply text-center rounded-sm border border-blue text-blue px-5 py-1 font-bold;
  font-size: 12px;
}

.outline-button:hover {
  @apply text-blue-dark border-blue-dark;
}

.footer{
  position: fixed;
  bottom: 0;
}
</style>