<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Share Valuation
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="10" class="mx-auto">
          <table class="min-w-full leading-normal text-gray"
                 style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2 font-bold">Valuation Report</td>
            </tr>
            </thead>
            <thead>
            <tr>
              <th class="px-2 py-1">Share Class</th>
              <th class="px-2 py-1 text-center">Shares</th>
              <th class="px-2 py-1 text-center">Value</th>
              <th class="px-2 py-1 text-center">Total Nominal Value</th>
            </tr>
            </thead>
            <tbody v-for="(valuation, idx) in data.valuation.valuation" :key="idx">
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 text-uppercase font-bold">
                <span v-if="valuation.nominalClass !== 'OTHER'">{{valuation.nominalClass}}</span>
                <span v-else>{{valuation.otherClassName}}</span>
              </td>
              <td class="px-2 py-1 text-center">
                                  <span v-if="valuation.subClasses.length === 0">
                                    {{valuation.number.toLocaleString()}}
                                  </span>
              </td>
              <td class="px-2 py-1 text-center">
                                  <span v-if="valuation.subClasses.length === 0">
                                    {{valuation.value.toLocaleString()}}
                                  </span>
              </td>
              <td class="px-2 py-1 text-center">
                                  <span v-if="valuation.subClasses.length === 0">
                                    {{valuation.totalNominalValue.toLocaleString()}}
                                  </span>
              </td>
            </tr>
            <tr v-for="(subClass, idx2) in valuation.subClasses" :key="idx2">
              <td class="px-2 py-1 text-capitalize">
                <span>Class {{subClass.nominalClass}}</span>
              </td>
              <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.number.toLocaleString()}}
                                  </span>
              </td>
              <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.value.toLocaleString()}}
                                  </span>
              </td>
              <td class="px-2 py-1 text-center">
                                  <span>
                                    {{subClass.totalNominalValue.toLocaleString()}}
                                  </span>
              </td>
            </tr>
            </tbody>

          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ValuationOfShares",
  props:{
    data:Object
  },
};
</script>

<style scoped>

</style>