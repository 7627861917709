import { render, staticRenderFns } from "./DisputeResolutionEscalationFile.vue?vue&type=template&id=ef147530&scoped=true&"
import script from "./DisputeResolutionEscalationFile.vue?vue&type=script&lang=js&"
export * from "./DisputeResolutionEscalationFile.vue?vue&type=script&lang=js&"
import style0 from "./DisputeResolutionEscalationFile.vue?vue&type=style&index=0&id=ef147530&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef147530",
  null
  
)

export default component.exports