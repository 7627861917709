<template>
  <b-row>
    <b-col>
      <b-card :header="badge">
        <table class="min-w-full leading-normal">
          <thead class="bg-header_grey border border-header_grey">
            <tr>
              <th class="px-2 py-1" colspan="12">Individual Partners</th>
            </tr>
          </thead>
          <thead class="border border-gray">
            <tr>
              <th class="px-2 py-1">#</th>
              <th class="px-2 py-1">Name</th>
              <th class="px-2 py-1">ID Number</th>
              <th class="px-2 py-1">Sex</th>
              <th class="px-2 py-1">Nationality</th>
            </tr>
          </thead>
          <tbody>
            <tr style="border: solid 1px #dedede;" v-for="(partner, idx) in getBusinessPartners.individualPartners" :key="idx">
              <td class="px-2 py-1">
                {{idx+1}}.
              </td>
              <td class="px-2 py-1">
                {{partner.givenName}} {{partner.surName}}
              </td>
              <td class="px-2 py-1 text-capitalize">
                {{partner.idNumber}}
              </td>
              <td class="px-2 py-1 text-capitalize">
                {{partner.sex.toLowerCase()}}
              </td>
              <td class="px-2 py-1 text-capitalize">
                {{partner.nationality}}
              </td>

            </tr>
            <tr>
              <td v-if="getBusinessPartners.individualPartners.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                <span class="text-xs">No results found</span>
              </td>
            </tr>
          </tbody>

        </table>
        <table class="min-w-full leading-normal mt-3">
          <thead class="bg-header_grey border border-header_grey">
          <tr>
            <th class="px-2 py-1" colspan="12">Corporate Partners</th>
          </tr>
          </thead>
          <thead class="border border-gray">
          <tr>
            <th class="px-2 py-1">#</th>
            <th class="px-2 py-1">Name</th>
            <th class="px-2 py-1">Registration Number</th>
          </tr>
          </thead>
          <tbody>
            <tr style="border: solid 1px #dedede;" v-for="(partner, idx) in getBusinessPartners.corporatePartners" :key="idx">
              <td class="px-2 py-1">
                {{idx+1}}.
              </td>
              <td class="px-2 py-1">
                {{partner.registeredName}}
              </td>
              <td class="px-2 py-1 text-capitalize">
                {{partner.brn}}
              </td>
            </tr>
            <tr>
              <td v-if="getBusinessPartners.corporatePartners.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                <span class="text-xs">No results found</span>
              </td>
            </tr>
          </tbody>

        </table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PartnerListing",
  props:{
    data:{},
    brn:String,
    badge:String
  },
  mounted() {
    if(this.brn){
      this.fetchBusinessNamePartners(this.brn)
    }
  },
  methods:{
    ...mapActions(["fetchBusinessNamePartners"])
  },
  computed:{
    ...mapGetters(["getBusinessPartners"])
  }
};
</script>

<style scoped>
th, td, p{
  font-size: 12px;
}
</style>