<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-textarea ref="text" v-model="description"
                      class="mb-2"
                      rows="4"
                      placeholder="Comments">
          </b-textarea>
          <p v-if="description" class="my-2 text-xs">{{ description.length }}/{{ maxText }}</p>
        </b-col>
      </b-row>
      <b-row v-if="level === 4">
        <b-col>
          <b-row>
            <b-col cols="9">
              <b-input-group class="border border-grey rounded-sm">
                <b-form-input
                  class="form-text text-sm noFocus border-0 text-gray"
                  v-model="search"
                  v-on:keyup="onSearch"
                  type="text"
                  placeholder="Search">
                </b-form-input>
                <b-input-group-prepend
                  class="bg-white">
                  <i class="fas fa-search mx-3 text-gray hover:text-blue" style="margin-top: 12px;"
                     @click="onSearch"></i>
                </b-input-group-prepend>
              </b-input-group>
            </b-col>
            <b-col cols="3">
              <b-form-select v-model="limit" class="w-full form-select paginate-details" v-on:change="fetch">
                <b-select-option value="15">15</b-select-option>
                <b-select-option value="20">20</b-select-option>
                <b-select-option value="50">50</b-select-option>
                <b-select-option value="100">100</b-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
                </b-col>
              </b-row>
              <b-row class="flex py-2 px-3 border-b border-header_grey hover:bg-header_grey"
                     v-for="(registrar, idx) in getRegistrars" :key="idx">
                <b-col cols="9">
                  <div class="flex">
                    <b-col class="ml-3">
                      <b-row>
                        <b-col>
                          <p class="text-sm text-uppercase" v-if="registrar">
                            <span v-if="registrar.givenName">{{ registrar.givenName }}</span>
                            <span class="mx-1" v-if="registrar.surName">{{ registrar.surName }}</span>
                            <span class="mx-1" v-if="registrar.middleName">{{ registrar.middleName }}</span>
                          </p>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <p class="text-xs text-lowercase text-gray italic" v-if="registrar">{{ registrar.email }}</p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </div>
                </b-col>
                <b-col cols="3">
                  <button @click="escalate(registrar)" class="button">
                    Select <i class="text-white fa fa-check-circle mx-1"></i>
                    <b-spinner class="ml-1" small v-if="loadingEscalate && selectedId === registrar.id"></b-spinner>
                  </button>
                </b-col>
              </b-row>
              <b-row class="flex py-1 px-2 bg-header_grey"
                     v-if="getRegistrars.length === 0">
                <b-col cols="12" class="text-center">
                  <p class="text-xs">No Account Found</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <button @click="escalate()" class="button py-2">
            Escalate <i class="text-white fa fa-check-circle mx-1"></i>
            <b-spinner class="ml-1" small v-if="loadingEscalate"></b-spinner>
          </button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import jwtService from "@/app/services/jwt.service";

export default {
  name: "DeEscalationDataUpdate",
  props: { applicationId: String, type: String, level: Int32Array, permission: String },
  data() {
    return {
      search: null,
      loading: false,
      loadingEscalate: false,
      selectedId: null,
      description: null,
      userProfile: {},
      limit: 15,
      maxText: 500,
      fromUserId: null
    };
  },
  mounted() {
    this.fetch();
    this.userProfile = jwtService.getUser();
    this.fromUserId = this.userProfile.userId;
  },
  methods: {
    ...mapActions(["fetchRegistrars", "searchRegistrars", "escalateCIDataUpdateTask", "escalateBNDataUpdateTask"]),
    fetch() {
      this.loading = true;
      this.fetchRegistrars({ permission: this.permission, limit: this.limit });
    },
    onSearch() {
      this.loading = true;
      this.searchRegistrars({ term: this.search, permission: this.permission });
    },
    escalate(person) {
      let levelToEscalateTo = 2;
      let toUserId = person.id;

      if (this.description != null) {
        if (this.userProfile.id !== toUserId) {
          if (this.type === "bn-data-update") {
            let data = {
              applicationId: this.applicationId,
              description: this.description,
              level: levelToEscalateTo,
              toUserId: toUserId
            };
            this.loading = true;
            this.loadingEscalate = true;
            this.escalateBNDataUpdateTask(data);
          }
          if (this.type === "ci-data-update") {
            let data = {
              applicationId: this.applicationId,
              description: this.description,
              level: levelToEscalateTo,
              toUserId: toUserId
            };
            this.loading = true;
            this.loadingEscalate = true;
            this.escalateCIDataUpdateTask(data);
          }
        } else {
          this.errorToast("Escalation Error", "You cannot escalate to yourself");
        }
      } else {
        this.errorToast("Escalation Error", "Please provide instructions to the next person");
      }
    }
  },
  computed: {
    ...mapGetters(["getRegistrars", "getCIEscalationDataUpdateSuccess", "getBNEscalationDataUpdateSuccess", "getBNDataUpdateError","getCompanyDataUpdateError"])
  },
  watch: {
    description: {
      handler(data) {
        if (data.length >= this.maxText) {
          this.errorToast("Text Length Error", "This message is very long");
        }
      },
      deep: true
    },
    getRegistrars() {
      this.loading = false;
    },
    getBNEscalationDataUpdateSuccess() {
      this.loadingEscalate = false;
      window.location.reload();
    },
    getCIEscalationDataUpdateSuccess() {
      this.loadingEscalate = false;
      window.location.reload();
    },
    getBNDataUpdateError(data) {
      this.loading = false;
      this.loadingEscalate = false;
      this.errorToast("Data Update Escalation Error", data.message);
    },
      getCompanyDataUpdateError(error){
          this.errorToast("Data Update Escalation Error", error.data.message);
      }
  }
};
</script>

<style scoped>
.button {
  @apply w-full bg-blue mt-1 px-2 py-1 rounded-sm text-white text-xs
}

.button:hover {
  @apply bg-green-dark;
}
</style>