<template>
  <div>

<!--    <button  type="button" @click="downloadPreview"
             class="bg-blue px-2 py-1 my-0 rounded-sm text-center text-white hover:bg-blue-dark mr-2">
      <i class="fa fa-download mr-2"></i> Form 1
    </button>

    <hr class="mt-2 mb-2" />-->
    <b-overlay :show="loading" rounded="sm" class="my-5">
      <iframe v-if="form1File" class="pdf" :src="form1File+'#toolbar=0&navpanes=0&scrollbar=0'"
              height="1000"
              width="100%">
      </iframe>
    </b-overlay>

  </div>
</template>

<script>
import {api} from "@/app/services/api.service";
import {mapGetters} from "vuex";

export default {
  name: "AppointmentNoticePreview",
  data(){
    return{
      form1File:"",
      loading:true
    }
  },
  methods:{
    defaultData(data){
      if(data){
        let trackingNumber = data.trackingNumber
        this.fetchForm1Preview(trackingNumber)
      }
    },
    downloadPreview(){
      let url =   this.form1File
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.download = "form1.pdf";
      a.click();
    },
    async fetchForm1Preview(trackingNumber) {
      await api().get("insolvency/process/preview-appointment-notice?trackingNo="+trackingNumber+"&format=base64")
          .then((response) => {
            if(response) {
              this.loading = false
              let url = response.data.data
              this.form1File  =  url
              this.$emit("urlAppointment",url);
            }
          })
          .catch((error) => {
            if(error) {
              // this.loading = false;
              // this.errorToast("Error", error.response.data.message);
            }
          });
    },
  },
  computed:{
    ...mapGetters(
        [
          "getWindingDraftData"
        ]
    )
  },
  watch:{
    getWindingDraftData(data){
      if(data){
        this.defaultData(data.data)
      }
    }

  }
}
</script>



<style scoped>

</style>