<template>
  <b-row>
    <b-col>
      <b-overlay rounded="sm" :show="loading">
        <b-col>
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <b-form-select class="form-select" v-model="snapshot">
                    <b-select-option value="null">--Select a Snapshot--</b-select-option>
                    <b-select-option :value="option" v-for="(option, idx) in snapshots" :key="idx" class="text-uppercase">
                      {{option.snapshotType.replaceAll("_", " ")}} | {{dateTimeFormat(option.createdAt)}}
                    </b-select-option>
                  </b-form-select>
                </b-col>
              </b-row>
              <b-row v-if="snapshot">
                <b-col>
                  <p class="border-2 border-blue p-2 rounded text-white bg-blue my-2 text-sm">
                    <span class="h6">Snapshot Initiator</span><br/>
                    Name: {{snapshot.initiator.fullName}}<br/>
                    Email: {{snapshot.initiator.email}} <br/>
                    Phone: {{snapshot.initiator.phoneNumber}}<br/>
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="10">
            <span style="font-size: 30px; font-weight: bold">
              {{application.name}}
            </span>
            </b-col>
              <b-col>
                <span v-if="application.registrarName !== undefined && application.registrarName" class="flex flex-column">
                    <label>Assigned to </label>
                    <span class="text-blue text-sm">{{application.registrarName}}</span>
                </span>
              </b-col>

          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Incorporation Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Sub Entity Type</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.trackingNo}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue text-capitalize">{{application.status.toLowerCase().replaceAll("_", " ")}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{cleanEntityType(application.subType)}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <PRNViewer v-if="application.status !== 'DRAFT'" :tracking-number="application.trackingNo" :prn="application.payment.prn"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray"
                     style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Summary</td>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;">
                  <th class="px-2 py-1">Reservation No.</th>
                  <td class="px-2 py-1">
                    <span class="text-blue italic">
                      {{application.reservationNo}}
                    </span>
                  </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                  <th class="px-2 py-1">Nature of Business</th>
                  <td class="px-2 py-1">
                    <ul class="text-blue" v-if="application.isic">
                      <li v-for="(section, idx) in application.isic.breakdown"  :key="idx">
                        <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                      </li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <IncorporationPopupDetails ref="details" :application="application"/>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import PRNViewer from "@/views/home/commons/PRNViewer";
import IncorporationPopupDetails
  from "@/views/home/services/incorporation/submissions/components/IncorporationPopupDetails";

export default {
  name: "IncorporationDetails",
  components: { IncorporationPopupDetails, PRNViewer },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      application:null,
      loading:true,
      snapshot:null,
      snapshots:[],
    }
  },
  mounted() {
    this.fetchIncorporationDetails(this.trackingNumber)
    this.fetchCompanyIncorporationTaskSnapshots(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchIncorporationDetails", "fetchCompanyIncorporationTaskSnapshots"]),
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY h:mmA")
    },
  },
  computed:{
    ...mapGetters(["getIncorporationDetails", "getIncorporationDetailsError", "getIncorporationSnapshots"])
  },
  watch:{
    snapshot:{
      handler(data){
        if(data){
          this.application = JSON.parse(data.snapshot)
        }else{
          this.fetchIncorporationDetails(this.trackingNumber)
          this.fetchCompanyIncorporationTaskSnapshots(this.trackingNumber)
        }
      },
      deep:true
    },
    getIncorporationDetails(data){
      this.loading = false
      this.application = data
    },
    getIncorporationSnapshots(data){
      this.snapshots = data.data.snapshots
    },
    getIncorporationDetailsError(){
      this.loading = false
      this.errorToast("Error", "Failed to get application details")
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>