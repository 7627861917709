<template>
  <div>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="flex h-full" style="cursor: pointer;">
                <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Reservation Information</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Attachments</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Messages</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <DispensationFormsEscalated :application="application" v-if="currentRoute === 'forms'"/>
              <DispensationFormFilesEscalated :application="application" v-if="currentRoute === 'attachments'"/>
              <DispensationMessagesEscalated :application="application" v-if="currentRoute === 'messages'"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import DispensationFormsEscalated
  from "@/views/home/services/dispensation/escalations/tabs/DispensationFormsEscalated.vue";
import DispensationFormFilesEscalated
  from "@/views/home/services/dispensation/escalations/tabs/DispensationFormFilesEscalated.vue";
import DispensationMessagesEscalated
  from "@/views/home/services/dispensation/escalations/tabs/DispensationMessagesEscalated.vue";
export default {
  name: "DispensationFormDetailsEscalated",
  components: {
    DispensationMessagesEscalated,
    DispensationFormFilesEscalated,
    DispensationFormsEscalated,
  },
  props:{
    application:Object
  },
  data(){
    return{
      currentRoute: "forms",
    }
  },
  methods:{
    select(route){
      this.currentRoute = route
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>