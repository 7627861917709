<template>
  <b-row>
    <b-col>
      <b-row v-if="data.embeddedResolutions.length > 0">
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Multiple Resolution Documents
          </b-badge>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Resolution Document
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12" class="py-3">
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Brief Description"
                  label-for="input-1">
                <b-form-textarea
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    rows="5"
                    disabled
                    :value="data.embeddedChanges.APPEND_INFORMATION.data.description"
                    required>
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

      </b-row>

      <b-row v-if="data.embeddedResolutions.length > 0">
        <b-col>
          <b-row v-for="(resolution, idx) in data.embeddedResolutions" :key="idx" class="py-2">
            <b-col>
              <b-row>
                <b-col>
                  <span class="font-bold text-sm">Resolution #{{idx + 1}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Organ"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled
                        :value="resolution.organ.replaceAll('_', ' ')"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Resolution Name"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled
                        :value="resolution.resolutionName.replaceAll('_', ' ')"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Subject"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled
                        :value="resolution.subject"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Venue of Meeting"
                      label-for="input-1">
                    <b-textarea
                        id="companyName"
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled
                        :value="resolution.venue"
                        required>
                    </b-textarea>
                  </b-form-group>

                  <table class="min-w-full leading-normal mt-3">
                    <thead class="bg-header_grey border border-header_grey">
                    <tr>
                      <th class="px-2 py-1" colspan="12">Signatories</th>
                    </tr>
                    </thead>
                    <thead class="border border-gray">
                    <tr>
                      <th class="px-2 py-1">#</th>
                      <th class="px-2 py-1">Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;" v-for="(signatory, idx) in resolution.signatories" :key="idx">
                      <td class="px-2 py-1">
                        {{idx+1}}.
                      </td>
                      <td class="px-2 py-1">
                        <span v-if="signatory.entityType === 'INDIVIDUAL'">{{signatory.individual.givenName}} {{signatory.individual.surName}}</span>
                        <span v-if="signatory.entityType === 'CORPORATE'">{{signatory.corporate.registeredName}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "GeneralResolution",
  props:{
    data:Object
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>