import { api } from "@/app/services/api.service";

const state = {
  districtsResults: null,
  countyResults: null,
  subCountyResults: null,
  parishResults: null,
  villageResultsData: null,
  villageGeneralResults: null,
  newProprietorship: null,
  searchByBrnData: null,
  attachmentsData: null,
  newPartnershipDeedFile: null,
  newSignedCopyFile: null,
  businessDetailsData: null,
  completeSubmissionDetailsData: null,
  countriesResults: null,
  businessDraftDetailsData: null,
  newCompanySignedCopyFile: null,
  districtsResultsError: null,
  form6DataError: null,
  partnershipUploadError: null,
  newCompanyExtraAttachmentFile: null,
  regionResults: null,
  villageDetails: null,
  newQueryCycleDataInfo: null,
  form6DataResponse: null,
  deleteBusinessFileAttachmentData: null,
  fileDeletionError: null,
  villageDetailsError: null,
  completeSubmissionError :null,
  stationsResults: null
};

const getters = {
  getDistrictsResults: (state) => state.districtsResults,
  getCountyResults: (state) => state.countyResults,
  getSubCountyResults: (state) => state.subCountyResults,
  getParishResults: (state) => state.parishResults,
  getVillageResultsData: (state) => state.villageResultsData,
  getNewProprietorship: (state) => state.newProprietorship,
  getAttachmentsData: (state) => state.attachmentsData,
  getNewPartnershipDeedFile: (state) => state.newPartnershipDeedFile,
  getNewSignedCopyFile: (state) => state.newSignedCopyFile,
  getBusinessDetailsData: (state) => state.businessDetailsData,
  getCompleteSubmissionDetailsData: (state) => state.completeSubmissionDetailsData,
  getCompleteSubmissionError: (state) => state.completeSubmissionError,
  getCountriesResults: (state) => state.countriesResults,
  getBusinessDraftDetailsData: (state) => state.businessDraftDetailsData,
  getNewCompanySignedCopyFile: (state) => state.newCompanySignedCopyFile,
  getDistrictsResultsError: (state) => state.districtsResultsError,
  getNewCompanyExtraAttachmentFile: (state) => state.newCompanyExtraAttachmentFile,
  getRegionResults: (state) => state.regionResults,
  getVillageGeneralResults: (state) => state.villageGeneralResults,
  getVillageDetails: (state) => state.villageDetails,
  getVillageDetailsError: (state) => state.villageDetailsError,
  getPartnershipUploadError: (state) => state.partnershipUploadError,
  getDeleteBusinessFileAttachmentData: (state) => state.deleteBusinessFileAttachmentData,
  getNewQueryCycleDataInfo: (state) => state.newQueryCycleDataInfo,
  getSearchByBrnData: (state) => state.searchByBrnData,
  getForm6DataResponse: (state) => state.form6DataResponse,
  getForm6DataError: (state) => state.form6DataError,
  getFileDeletionError: (state) => state.fileDeletionError,
  getStationsResults: (state) => state.stationsResults,
};

const actions = {
  async fetchCountries({ commit }) {
    await api().get("configuration/address/country")
      .then((response) => {
        commit("setCountriesResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchRegion({ commit }, data) {
    await api().get("configuration/address/region?countryId=" + data)
      .then((response) => {
        commit("setRegionResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchDistricts({ commit }, data) {
    await api().get("configuration/address/district?regionId=" + data)
      .then((response) => {
        commit("setDistrictsResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchSubCounty({ commit }, data) {
    await api().get("configuration/address/sub-county?districtId=" + data)
      .then((response) => {
        commit("setSubCountyResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchParish({ commit }, data) {
    await api().get("configuration/address/parish?subCountyId=" + data)
      .then((response) => {
        commit("setParishResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchVillage({ commit }, data) {
    await api().get("configuration/address/village?parishId=" + data)
      .then((response) => {
        commit("setVillageResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchVillageGeneral({ commit }, data) {
    await api().get("configuration/address/village?parishId=" + data)
      .then((response) => {
        commit("setVillageGeneralResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchCounty({ commit }, data) {
    await api().get("business-registration/address/county?districtId=" + data)
      .then((response) => {
        commit("setCountyResults", response);
      })
      .catch((error) => {
        commit("setDistrictsResultsError", error.response);
      });
  },
  async fetchVillageDetails({ commit }, villageId) {
    await api().get("/configuration/address/full-path?villageId=" + villageId)
      .then((response) => {
        commit("setVillageDetails", response);
      })
      .catch((error) => {
        commit("setVillageDetailsError", error.response);
      });
  },
  async fetchStations({ commit }) {
    await api().get("integration/api/eposta/stations")
        .then((response) => {
          commit("setStationsResults", response);
        })
        .catch((error) => {
          commit("setDistrictsResultsError", error.response);
        });
  },
};

const mutations = {
  setDistrictsResults: (state, response) => {
    state.districtsResults = response.data;
  },
  setCountyResults: (state, response) => {
    state.countyResults = response.data;
  },
  setSubCountyResults: (state, response) => {
    state.subCountyResults = response.data;
  },
  setParishResults: (state, response) => {
    state.parishResults = response.data;
  },
  setVillageResults: (state, response) => {
    state.villageResultsData = response.data;
  },
  setVillageGeneralResults: (state, response) => {
    state.villageGeneralResults = response.data;
  },
  setCountriesResults: (state, response) => {
    state.countriesResults = response.data;
  },
  setRegionResults: (state, response) => {
    state.regionResults = response.data;
  },
  setDistrictsResultsError: (state, response) => {
    state.districtsResultsError = response.data;
  },
  setVillageDetailsResultsError: (state, response) => {
    state.districtsResultsError = response.data;
  },
  setVillageDetails: (state, response) => {
    state.villageDetails = response.data;
  },
  setVillageDetailsError: (state, error) => {
    state.villageDetailsError = error
  },
  setStationsResults: (state, response) => {
    state.stationsResults = response.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
