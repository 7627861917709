import {api} from "@/app/services/api.service";

const state = {
    trackResults: null,
    trackError: null
};

const getters = {
    getTrackResults: (state) => state.trackResults,
    getTrackError: (state) => state.trackError,
};

const actions = {
    async doTrackApplication({commit}, filters) {
        await api().get("tracking/track"+filters)
            .then((response) => {
                commit("setTrackResults", response.data);
            })
            .catch((error) => {
                commit("setTrackError", error.response);
            });
    },

};

const mutations = {
    setTrackResults: (state, response) => {
        state.trackResults = response.data;
    },
    setTrackError: (state, response) => {
        state.trackError = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
