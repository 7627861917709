 <template>
  <b-row>
    <b-col>
      <b-row class="mx-1 my-2">
        <b-col cols="12" >
          <b-row v-for="(message, idx) in messages" :key="idx">
            <b-col cols="6" class="py-2 rounded-md bg-header_grey my-2 text-gray">
              <b-row>
                <b-col>
                  <span class="text-sm font-bold text-capitalize">{{message.messageData.sender.toLowerCase()}}</span>
                  <span class="bg-green rounded text-white text-capitalize px-1 ml-1"
                        style="font-size: 8px; padding-bottom: 2px; padding-top: 2px;" >
                      {{message.messageData.senderRole.toLowerCase()}}
                   </span><br/>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-sm">{{message.messageData.comment}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span style="font-size:9px" class="text-blue italic text-capitalize">{{dateFormat(message.messageData.createdAt)}} | {{message.status.toLowerCase()}}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="messages.length === 0">
            <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
              <b-row>
                <b-col>
                  <span class="text-xs font-bold">No messages yet</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "DisputeResolutionMessagesDetails",
  data(){
    return{
      messages:[]
    }
  },
  props:{
    application:Object
  },
  mounted(){
    let id = this.application.data.id
    this.fetchDisputeResolutionStatuses(id)
  },
  methods:{
    ...mapActions(["fetchDisputeResolutionStatuses"])

  },
  computed:{
    ...mapGetters(["getDisputeResolutionStatuses", "getDisputeResolutionTask"])
  },
  watch:{
    getDisputeResolutionStatuses(data){
      this.messages = data
    }
  }
}
</script>

<style scoped>

</style>