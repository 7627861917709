<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Name Change Details</td>
                </tr>
                </thead>
                <tr>
                  <th class="px-2 py-1">Current Name</th>
                  <th class="px-2 py-1">Desired Name</th>
                  <th class="px-2 py-1">Reservation Number</th>
                </tr>
                <tbody>
                <tr style="border: solid 1px #dedede;">
                  <td class="px-2 py-1 text-capitalize">{{data.form.oldName}}</td>
                  <td class="px-2 py-1 text-capitalize">{{data.form.newName}}</td>
                  <td class="px-2 py-1 text-capitalize">{{data.form.reservationNo}}</td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col>
              <b-alert variant="warning" :show="data.hasGazette">
                <i class="mx-1 fa fa-info-circle"></i> The Client has submitted a Gazette. Please check it out to approve this NAME CHANGE request
              </b-alert>
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col>
              <b-alert variant="success" show>
                <b-badge class="bg-green">Reservation: {{data.form.reservationNo}}</b-badge>
                <b-badge class="bg-blue mx-1">{{cleanEntityType(data.form.reservation.type)}}</b-badge>
                <p class="text-sm mt-1 text-capitalize">
                  Name: <b>{{data.form.reservation.name}}</b><br/>
                  Entity Category: <b>{{cleanEntityType(data.form.reservation.subType)}}</b><br/>
                  Nature of Business: <b>{{data.form.reservation.isic.description}}</b><br/>
                  ISIC Code: <b>{{data.form.reservation.isic.code}}</b><br/>
                  Reserved on: <b>{{dateFormat(data.form.reservation.createdAt)}}</b><br/>
                  Expires on: <b>{{dateFormat(data.form.reservation.expiresOn)}}</b><br/>
                </p>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="my-2" >
            <b-col cols="12">
             <b-badge class="cursor-pointer bg-blue mx-1 hover:bg-blue-dark" v-for="(att, idx) in attachments" :key="idx" @click="showLink(idx)">
               #{{att.purpose.toLowerCase()}}
             </b-badge>
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col cols="12">
              <iframe class="pdf" :src="url+'#toolbar=0'">
              </iframe>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import server from "@/app/env/server.json";
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";

export default {
  name: "NameChange",
  props:{
    data:Object,
    attachments:Object
  },
  data(){
    return{
      urls:["https://mag.wcoomd.org/uploads/2018/05/blank.pdf"],
      url:"https://mag.wcoomd.org/uploads/2018/05/blank.pdf"
    }
  },
  mounted() {
    this.urls= []
    for(let i = 0; i < this.attachments.length; i++){
      this.urls.push(server.IP+"/incorporation/companies/downloads?attachment="+this.attachments[i].attachment)
    }
  },
  methods:{
    ...mapActions(["fetchReservationInfo"]),
    showLink(idx){
      this.url = this.urls[idx]
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getReservationNumberResult"])
  },
  watch:{
    attachments:{
      handler(){
        if(this.attachments.length > 0){
          this.urls= []
        }

        for(let i = 0; i < this.attachments.length; i++){
          this.urls.push(server.IP+"/incorporation/companies/downloads?attachment="+this.attachments[i].attachment)
        }
      },
      deep:true
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>