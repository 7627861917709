import {api} from "@/app/services/api.service";

const state = {
    filings:null,
    escalations:[],
    filingStatuses:[],
    filing_task: {},
    filing_task_error: null,
    filing_details: null,
    filing_stats:{},
    filing_count:{},
    success:{},
    successAssignment:{},
    successEscalation:{},
    errorAssignment:{},
    successStatusUpdate:{},
    error:{},
};

const getters = {
    getContinuousFilingSubmissions: (state) => state.filings,
    getContinuousFilingEscalations: (state) => state.escalations,
    getContinuousFilingTask: (state) => state.filing_task,
    getContinuousFilingTaskError: (state) => state.filing_task_error,
    getContinuousFilingDetails: (state) => state.filing_details,
    getContinuousFilingStatistics: (state) => state.filing_stats,
    getContinuousFilingStatuses: (state) => state.filingStatuses,
    getContinuousFilingStatusUpdate: (state) => state.successStatusUpdate,
    getContinuousFilingCount: (state) => state.filing_count,
    getContinuousFilingSuccess:(state) => state.success,
    getContinuousFilingEscalationSuccess:(state) => state.successEscalation,
    getContinuousFilingAssignmentSuccess:(state) => state.successAssignment,
    getContinuousFilingAssignmentError:(state) => state.errorAssignment,
    getContinuousFilingError:(state) => state.error,
    getContinuousFilingEscalationError:(state) => state.error,
};

const actions = {
    async submitContinuousFilingApplicationPayment({ commit }, data) {
        await api().post("incorporation/companies/continuous-filing/add-payment", data)
            .then((response) => {
                commit("setContinuousFiling", response.data);
            })
            .catch((error) => {
                commit("setContinuousFilingTaskError", error.response);
            });
    },
    async fetchContinuousFilingSubmissions({commit}, data){
        let path = "/incorporation/companies/continuous-filing?status="+data.statuses+"&page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setContinuousFilings', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    async fetchContinuousFilingEscalations({commit}, data){
        api().get("/incorporation/companies/continuous-filing/queue/my-escalations?status="+data.status+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setCFEscalations', response.data);
        }).catch(error => {
            commit("setContinuousFilingEscalationError", error.response)
        });
    },
    async fetchContinuousFilingStatuses({commit}, id){
        api().get("/incorporation/companies/continuous-filing/queue/task/"+id+"/thread").
        then(response => {
            commit('setContinuousFilingStatuses', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    async fetchNextContinuousFiling({commit}){
        commit('setContinuousFilingToNull');
        api().get("/incorporation/companies/continuous-filing/queue/task").
        then(response => {
            commit('setContinuousFiling', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    async fetchContinuousFilingDetails({commit}, trackingNumber){
        api().get("/incorporation/companies/continuous-filing/resolutions/"+trackingNumber).
        then(response => {
            commit('setContinuousFilingDetails', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    async fetchContinuousFilingStats({commit}){
        api().get("/incorporation/companies/continuous-filing/status-statistics").
        then(response => {
            commit('setContinuousFilingStats', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    async fetchContinuousFilingCount({commit}){
        api().get("/incorporation/companies/continuous-filing/queue/count").
        then(response => {
            commit('setContinuousFilingCount', response);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    updateContinuousFilingStatus({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/update-status", data).
        then(response => {
            commit('setContinuousFilingStatusUpdated', response.data);
        }).catch(error => {
            commit("setContinuousFilingAssignmentError", error.response)
        });
    },
    queryContinuousFilingChargeStatus({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/query-for-payment", data).
        then(response => {
            commit('setContinuousFilingStatusUpdated', response.data);
        }).catch(error => {
            commit("setContinuousFilingAssignmentError", error.response)
        });
    },
    rejectContinuousFilingChargeStatus({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/query-for-payment", data).
        then(response => {
            commit('setContinuousFilingStatusUpdated', response.data);
        }).catch(error => {
            commit("setContinuousFilingAssignmentError", error.response)
        });
    },
    assignContinuousFilingTask({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/re-assign", data).
        then(response => {
            commit('setContinuousFilingAssignmentAction', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    assignEscalationContinuousFilingTask({commit}, data){
        api().post("/incorporation/companies/continuous-filing/escalation/queue/re-assign", data).
        then(response => {
            commit('setContinuousFilingAssignmentAction', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    escalateContinuousFilingTask({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/escalate", data).
        then(response => {
            commit('setContinuousFilingEscalationAction', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
    reEscalateContinuousFilingTask({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/escalate", data).
        then(response => {
            commit('setContinuousFilingEscalationAction', response.data);
        }).catch(error => {
            commit("setContinuousFilingError", error.response)
        });
    },
};

const mutations = {
    setContinuousFilings: (state, response) =>{
        state.filings = response;
    },
    setCFEscalations: (state, response) =>{
        state.escalations = response;
    },
    setContinuousFiling: (state, response) =>{
        state.filing_task = response;
    },
    setContinuousFilingTaskError: (state, error) =>{
        state.filing_task_error = error;
    },
    setContinuousFilingDetails: (state, response) =>{
        state.filing_details = response;
    },
    setContinuousFilingToNull: (state) =>{
        state.filing = null;
    },
    setContinuousFilingStatuses: (state, response) =>{
        state.filingStatuses = response.data;
    },
    setContinuousFilingStats: (state, response) =>{
        state.filing_stats = response;
    },
    setContinuousFilingCount: (state, response) =>{
        state.filing_count = response.data;
    },
    setContinuousFilingAction: (state, response) =>{
        state.success = response;
    },
    setContinuousFilingAssignmentAction: (state, response) =>{
        state.successAssignment = response;
    },
    setContinuousFilingStatusUpdated: (state, response) =>{
        state.successStatusUpdate = response;
    },
    setContinuousFilingEscalationAction: (state, response) =>{
        state.successEscalation = response;
    },
    setContinuousFilingError: (state, error) =>{
        state.error = error;
    },
    setContinuousFilingEscalationError: (state, error) =>{
        state.error = error;
    },
    setContinuousFilingAssignmentError: (state, error) =>{
        state.errorAssignment = error;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};