<template>
  <div class="mt-5">
    <b-row>
      <b-col cols="10" md="8" sm="10" lg="8" class="mx-auto my-3">
        <b-row>
          <b-col cols="10" class="mx-auto">
            <b-row class="bg-white" v-if="details != null">
              <b-col cols="12">
                <b-row>
                  <b-col cols="6">
                    <p class="text-sm text-gray mb-1"><b>Tracking No:</b> {{details.no}}</p>
                    <p class="text-sm text-gray mb-1"><b>Name:</b> {{details.title}}</p>
                    <p class="text-sm text-gray mb-1"><b>Status:</b> {{details.status}}</p>
                  </b-col>
                  <b-col cols="6">
                    <p class="text-sm text-gray mb-1"><b>Description:</b> <br />{{details.description}}</p>
                  </b-col>
                </b-row>
                <hr class="mb-3 mt-3" />
              </b-col>
            </b-row>
            <b-row v-for="(step,index) in tracking" :key="index">
              <b-col cols="12" class="my-1">
                <b-row>
                  <b-col cols="5">
                    <div v-if="index%2 == 0" class="border border-blue rounded px-1 py-1 text-capitalize">
                      <span :class="statusClass(step.status.toLowerCase())">
                        <b v-if="step.status.toLowerCase() === 'draft'">
                           <i class="fa fa-pen px-1"> {{step.status.toLowerCase()}}</i>
                        </b>
                        <b v-if="step.status.toLowerCase() === 'pending'">
                            <i class="fa fa-fingerprint px-1">Submitted</i>
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'submitted'">
                            {{step.status.toLowerCase()}}
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'queried'">
                            <i class="fa fa-question-circle px-1"> Issues Raised </i>
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'assigned'">
                             <i class="fa fa-edit"> Being processed</i></b>

                        <b v-else-if="step.status.toLowerCase() === 'approved'">
                            <i class="fa fa-check-circle px-2">{{step.status.toLowerCase()}}</i>
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'appealed'">{{step.status.toLowerCase()}}
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'rejected'">{{step.status.toLowerCase()}}</b>
                        <b v-else >{{step.status.toLowerCase()}}</b>
                      </span>
                      <p class="mt-1 text-xs text-gray font-bold">{{step.title}}</p>
                      <p class="mt-1 mb-2 text-xs text-gray italic" style="word-wrap: break-word;">{{step.description}}</p>
                      <div style="text-align: end;" class="text-xs italic text-grey mt-2">{{dateFormat(step.createdAt)}}</div>
                    </div>
                  </b-col>
                  <b-col cols="2" class="flex">
                    <div class="m-0 position-relative mx-auto">
                      <div class="mx-3 dot"></div>
                      <div class="line"></div>
                    </div>
                  </b-col>
                  <b-col cols="5">
                    <div v-if="index%2 != 0" class="border border-blue rounded px-1 py-1 text-capitalize">
                      <span :class="statusClass(step.status.toLowerCase())">
                        <b v-if="step.status.toLowerCase() === 'draft'">{{step.status.toLowerCase()}}</b>
                        <b v-else-if="step.status.toLowerCase() === 'pending'">
                            <i class="fa fa-fingerprint px-1">Submitted</i></b>
                        <b v-else-if="step.status.toLowerCase() === 'submitted'">
                           <i class="fa fa-fingerprint px-1">Submitted</i>
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'queried'">
                         <i class="fa fa-question-circle px-1"> Issues Raised </i>
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'assigned'">
                        <i class="fa fa-edit"> Being processed</i></b>

                        <b v-else-if="step.status.toLowerCase() === 'approved'">
                            <i class="fa fa-check-circle px-2">{{step.status.toLowerCase()}}</i>
                        </b>
                        <b v-else-if="step.status.toLowerCase() === 'appealed'">{{step.status.toLowerCase()}}</b>
                        <b v-else-if="step.status.toLowerCase() === 'rejected'">{{step.status.toLowerCase()}}</b>
                           <b v-else >
                               <i class="fa fa-mouse-pointer px-2">
                                   {{step.status.toLowerCase()}}
                               </i>
                           </b>
                      </span>
                      <p class="mt-1 text-xs text-gray font-bold">{{step.title}}</p>
                      <p class="mt-1 mb-2 text-xs text-gray italic" style="word-wrap: break-word;">{{step.description}}</p>
                      <div style="text-align: end;" class="text-xs italic text-grey mt-2">{{dateFormat(step.createdAt)}}</div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "TrackingTree",
  data(){
    return{
      messageWhenNoItems: "There arent items",
      tracking:[],
      details:null
    }
  },
  mounted() {

  },
  methods:{
    statusClass(status){
      if(status == "pending"){
        return "status-pending"
      }

      if(status == "submitted"){
        return "status-pending"
      }

      if(status == "draft"){
        return "status-pending"
      }

      if(status == "assigned"){
        return "status-assigned"
      }

      if(status == "approved"){
        return "status-approved"
      }

      if(status == "queried"){
        return "status-queried"
      }

      if(status == "rejected"){
        return "status-rejected"
      }

      if(status == "appealed"){
        return "status-rejected"
      }

      if(status == "reserved"){
        return "status-approved"
      }

      if(status == "registered"){
        return "status-approved"
      }

      if(status == "ceased"){
        return "status-rejected"
      }
       else {
           return  "status-pending"
      }

    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    }
  },
  computed:{
    ...mapGetters(
        [
          "getTrackResults"
        ]
    )
  },
  watch:{
    getTrackResults(data){
      if(data){
        this.tracking = data.trackSteps.reverse()
        this.details = data
      }
      // console.log(JSON.stringify(data))
    }
  }
}
</script>

<style scoped>
@import "timeline.css";
.dot {
  height: 19px;
  width: 19px;
  background-color: #157EC2;
  border-radius: 50%;
  /*display: inline-block;*/
}

.status{
  @apply mb-2 rounded-sm px-2 py-1 text-sm;
  font-size: 12px;
}
.status-pending{
  @apply bg-gray text-white status;
}

.status-assigned{
  @apply bg-blue text-white status ;
}

.status-approved{
  @apply bg-green text-white status;
}

.status-queried{
  @apply bg-orange text-white status;
}

.status-rejected{
  @apply bg-red text-white status;
}

.line{
  @apply mx-auto mt-0 pt-0;
  background-color: #ccd5db;
  width: 2px;
  height: 100%;
  top: 0;
}

</style>