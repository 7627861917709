import axios from "axios";
import jwtService from "./jwt.service";
import store from "@/store";
import server from "@/app/env/server.json";
import moment from "moment";

export const api = (ip) => {
  let IP = server.IP;
  if (ip != null) {
    IP = ip;
  }

  let setup = axios.create({
    baseURL: IP,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtService.getToken()
    }
  });
  setup.interceptors.response.use(
    response => response,
    (error) => {
      const response = error.toJSON();
      if (response.status === 504 || response.status === null) {
        store.state.network_status = "We're having difficulty connecting to our servers now at "+moment(new Date()).format("DD/MM/YYYY HH:mm:ss")+". Reload your browser or try again later.";
      }
      throw error;
    });

  return setup;
};

export const authApi = () => {
  return axios.create({
    baseURL: server.IP,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};

export default { authApi, api };