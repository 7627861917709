<template>
  <div class="w-full">
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
        <Assignment :id="taskId" type="dispute" permission="can_view_continuous_filing_tasks" />
      </b-modal>
      <b-modal ref="addPRN" title="Change PRN" size="md" hide-footer>
        <AddPRN :tracking-number="application.trackingNo" />
      </b-modal>
    </b-row>

    <b-row>
      <b-col cols="3">
        <b-form-input
          class="text-gray text-sm noFocus mx-2 my-2"
          style="padding:6px 5px;"
          v-model="search"
          type="text"
          placeholder="Search by Tracking No."
          required
          v-on:keyup.enter="fetch">
        </b-form-input>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <b-form-select
              v-model="statuses"
              v-on:change="fetch"
              class="form-select noFocus text-gray mx-2 my-2 text-sm">
            <b-select-option :value="null">--Filter by Status--</b-select-option>
            <b-select-option value="DRAFT">Draft</b-select-option>
            <b-select-option value="PENDING">In Queue</b-select-option>
            <b-select-option value="ASSIGNED">Assigned</b-select-option>
            <b-select-option value="QUERIED">Queried</b-select-option>
            <b-select-option value="RECEIVED">Received</b-select-option>,,
            <b-select-option value="CLOSED">Approved</b-select-option>
            <b-select-option value="SCHEDULED">Scheduled</b-select-option>
            <b-select-option value="ESCALATED">Escalated</b-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="4"></b-col>
      <b-col cols="1">
        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
          <b-select-option value="12">12</b-select-option>
          <b-select-option value="20">20</b-select-option>
          <b-select-option value="50">50</b-select-option>
          <b-select-option value="100">100</b-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="mx-auto">
            <p class="paginate-details text-center text-xs font-bold">
              <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
              <span v-if="limit > submissions.length ">{{ pagination.totalElements }}</span>
              <span v-else>{{ currentPage * limit }}</span>
              of {{ pagination.totalElements }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="mx-auto">
      <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>

    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">#</th>
            <th class="t-header">Tracking No.</th>
            <th class="t-header">Nature</th>
            <th class="t-header">Type</th>
<!--            <th class="t-header text-center">Cause No.</th>-->
            <th class="t-header text-center">Status</th>
            <th class="t-header text-center">Assignment</th>
            <th class="t-header text-center">Action</th>
            <th class="t-header text-center">Submission Date</th>
          </tr>
          </thead>

          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
            <td class="t-body">{{ (currentPage - 1) * limit + idx + 1 }}</td>
            <td class="t-body">
                <span @click="showDetails(submission.trackingNo)"
                      class="text-blue font-bold cursor-pointer hover:underline">
                  {{ submission.trackingNo }}
                </span>
            </td>
            <td class="t-body">
              <p class="font-bold">{{ cleanString(submission.natureOfDispute) }}</p>

            </td>
            <td class="t-body text-xs">
                <p class="text-blue italic">{{ submission.type }}</p>
            </td>

<!--              <td class="t-body text-xs">
                {{ submission.causeNo }}
            </td>-->



            <td class="t-body text-center">
              <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                <span :class="(submission.status.toUpperCase() === 'DRAFT') ? 'text-gray-light' :
                              (submission.status.toUpperCase() === 'PAYMENT_FAILED') ? 'text-red' :
                              (submission.status.toUpperCase() === 'AWAITING_PAYMENT') ? 'text-purple' :
                              (submission.status.toUpperCase() === 'PENDING') ? 'text-gray' :
                              (submission.status.toUpperCase() === 'ASSIGNED')? 'text-blue':
                              (submission.status.toUpperCase() === 'CLOSED') ? 'text-green' :
                              (submission.status.toUpperCase() === 'RECEIVED') ? 'text-green' :
                              (submission.status.toUpperCase() === 'SCHEDULED') ? 'text-purple' :
                              (submission.status.toUpperCase() === 'QUERIED') ? 'text-orange' : ''">
                    <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT'">
                      Confirming Payment
                    </span>
                    <span v-else-if="submission.status.toUpperCase() === 'PENDING'">
                      In Queue
                    </span>
                     <span v-else-if="submission.status.toUpperCase() === 'CLOSED'">
                      Approved
                    </span>
                    <span v-else>
                      {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                    </span>
                </span>
              </p>
            </td>
            <td class="t-body text-center">
              <span class="text-xs" v-if="submission.registrarName">{{ submission.registrarName }}</span>
              <span class="text-xs" v-else>Not Assigned</span>
            </td>
            <td class="t-body text-center">
              <b-row v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT'">
                <b-col>
                  <button class="w-full bg-blue text-white mx-1 px-2 py-1 rounded-sm" @click="move(submission)">
                    <i class="fa fa-reply mx-1"></i> Move to Queue
                    <b-spinner v-if="loadingMoveTask" small></b-spinner>
                  </button>
                </b-col>
                <b-col v-if="canViewActionWithPermission('can_remove_payment_in_continuous_filing')">
                  <button
                    class="bg-red text-white mx-1 px-2 py-1 rounded-sm" @click="removePayment(submission, idx)">
                    <i class="fa fa-times mx-1"></i> Remove Payment
                    <b-spinner v-if="loadingRevertTask && selectedId === idx" small></b-spinner>
                  </button>
                </b-col>
              </b-row>
              <b-row
                v-else-if="(submission.status.toUpperCase() === 'PAYMENT_FAILED' || submission.status.toUpperCase() === 'DRAFT')">
                <b-col v-if="canViewActionWithPermission('can_add_prn_in_continuous_filing') ">
                  <button
                    class="w-full text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="addPRN(submission)">
                    <i class="fa fa-plus mx-1"></i> Add PRN
                  </button>
                </b-col>
                <b-col v-if="canViewActionWithPermission('can_remove_payment_in_continuous_filing')">
                  <button
                    class="bg-red text-white mx-1 px-2 py-1 rounded-sm" @click="removePayment(submission, idx)">
                    <i class="fa fa-times mx-1"></i> Remove Payment
                    <b-spinner v-if="loadingRevertTask && selectedId === idx" small></b-spinner>
                  </button>
                </b-col>
              </b-row>
              <button
                v-else-if="submission.status.toUpperCase() === 'ESCALATED' && canViewActionWithPermission('can_assign_continuous_filing_application')"
                class="bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                <i class="fa fa-plus mx-1"></i>Assign
              </button>
              <button
                v-else-if="submission.status.toUpperCase() === 'PENDING' && canViewActionWithPermission('can_assign_continuous_filing_application')"
                class="bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                <i class="fa fa-plus mx-1"></i>Assign
              </button>
              <button
                v-else-if="submission.status.toUpperCase() === 'ASSIGNED' && canViewActionWithPermission('can_assign_continuous_filing_application')"
                class="bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                <i class="fa fa-redo-alt mx-1"></i>Assign
              </button>
            </td>
            <td class="t-body text-center">
              {{ dateTimeFormat(submission.submissionDate) }}
            </td>
          </tr>
          <tr>
            <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          <tr>
            <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
              <p class="text-sm">Failed to load tasks, Try again later.</p>
              <p class="text-sm">{{ errorMessage }}</p>
              <p class="text-sm">
                <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload
                  <b-spinner v-if="loading" small></b-spinner>
                </button>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{ currentPage }}/{{ pagination.totalPages }}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1">
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col class="6"></b-col>
      <b-col cols="1">
        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
          <b-select-option value="12">12</b-select-option>
          <b-select-option value="20">20</b-select-option>
          <b-select-option value="50">50</b-select-option>
          <b-select-option value="100">100</b-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="mx-auto">
            <p class="paginate-details text-center text-xs font-bold">
              <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
              <span v-if="limit > submissions.length ">{{ pagination.totalElements }}</span>
              <span v-else>{{ currentPage * limit }}</span>
              of {{ pagination.totalElements }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Assignment from "@/views/home/commons/Assignment";
import AddPRN from "@/views/home/commons/AddPRN";

export default {
  name: "AllDisputeResolutionSubmissions",
  components: { AddPRN, Assignment },
  data() {
    return {
      loading: false,
      loadingMoveTask: false,
      loadingRevertTask: false,
      currentPage: 1,
      limit: 12,
      submissions: [],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      selectedId: null,
      taskId: null,
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey hover:bg-header_grey"
      },
      search: "",
      statuses: null,
      application: {},
      application_prn: null,
      hasError: false,
      errorMessage: null
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions(["submitDisputeResolutionApplicationPayment", "fetchDisputeResolutionSubmissions", "redoTransaction",
      "removePaymentFromCFApplication"]),
    fetch() {
      let options = this.statuses;
      if (this.statuses == null) {
        options = "DRAFT,PENDING,ASSIGNED,QUERIED,ESCALATED,SCHEDULED,CLOSED,RECEIVED,APPROVED";
      }
      let data = {
        search: this.search,
        statuses: options,
        page: this.currentPage,
        limit: this.limit
      };
      this.loading = true;
      this.fetchDisputeResolutionSubmissions(data);
    },
    showDetails(trackingNumber) {
      if (trackingNumber.startsWith("800")) {
        this.warningToast("Unauthorized Action", "Go to Registry to see approved Changes");
      } else {
        this.$emit("openDetails", trackingNumber);
      }
    },
    attachPrn() {
      this.loading.true;
      let data = {
        "prn": this.application_prn,
        "trackingNo": this.application.trackingNo
      };
      this.submitDisputeResolutionApplicationPayment(data);
    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++;
        this.fetch();
      }
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetch();
      } else {
        this.warningToast("Observation", "No more data to show");
      }
    },
    addPRN(application) {
      this.application = application;
      this.$refs.addPRN.show();
    },
    move(application) {
      let data = {
        userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
        prn: application.payment.prn
      };
      this.redoTransaction(data);
    },
    removePayment(application, idx) {
      this.selectedId = idx;
      let data = {
        trackingNo: application.trackingNo
      };
      this.loadingRevertTask = true;
      this.removePaymentFromCFApplication(data);
    },
    dateFormat(date) {
      if (date) {
        return moment(date).format("DD-MM-YYYY");
      }
      return "";
    },
    dateTimeFormat(date) {
      if (date) {
        return moment(date).format("DD-MM-YYYY h:mmA");
      }
      return "";
    },
    assignPrompt(data) {

      this.taskId = data.id;
      this.$refs.assignSubmissions.show();
    },
    cleanEntityType(name) {
      let n = name.replaceAll("-", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    cleanString(name) {
      let n = name.replaceAll("_", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed: {
    ...mapGetters(["getDisputeResolutionSubmissions", "getMoveTask",
      "getDisputeResolutionTask", "getDisputeResolutionTaskError",
      "getMoveTaskError", "getDisputeResolutionError",
      "getIncorporationPaymentRemoved", "getIncorporationPaymentRemovedError"])
  },
  watch: {
    getDisputeResolutionTask() {
      this.loading = false;
      this.application_prn = null;
      this.successToast("Success", "PRN Attachment Successful");
      this.$refs.addPRN.hide();
      this.fetch();
    },
    getDisputeResolutionTaskError(response) {
      this.loading = false;
      this.errorToast("Error", response.data.message);
    },
    getDisputeResolutionSubmissions(response) {
      this.loading = false;
      this.submissions = response.content;
      this.pagination = {
        totalPages: response.totalPages,
        totalElements: response.totalElements
      };
    },
    getDisputeResolutionError(data) {
      this.loading = false;
      this.hasError = true;
      this.errorMessage = data.message;
      this.errorToast("Error", data.message);
    },
    getNetworkStatus(message) {
      this.loading = false;
      this.hasError = true;
      this.errorMessage = message;
      this.errorToast("Network Error", message);
    },
    getMoveTask() {
      this.loadingMoveTask = false;
      this.successToast("Request Success", "Transaction retried");
      this.fetch();
    },
    getMoveTaskError(data) {
      this.loadingMoveTask = false;
      this.errorToast("Error", data.message);
    },
    getIncorporationPaymentRemoved() {
      this.loadingRevertTask = false;
      this.successToast("Success", "Payment details removed and status reverted");
      this.fetch();
    },
    getIncorporationPaymentRemovedError(data) {
      this.loadingRevertTask = false;
      this.errorToast("Success", data.message);
    }
  }
};
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.verified {
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified {
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn {
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs;
}

.assign-btn:hover {
  @apply bg-green text-white;
}

.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>