<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Incorporation">
        <Escalation :task-id="taskId" type="bsi" permission="can_view_building_society_incorporation_escalations"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-a-assign" hide-footer ref="assignSubmissions" title="Assign Incorporation">
        <Assignment :id="escalationId" type="bsi" permission="can_view_building_society_incorporation_tasks"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.reason"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-a-assign" hide-footer ref="replySubmissions" title="Reply to Escalation">
        <AssignmentReply :id="escalationId" :user-id="userId" type="bsi"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row class="mx-3 mb-3s">
      <b-col cols="12">
        <b-row class=" py-2 my-2 shadow-md rounded-sm" style="background: #b0ea75;">
          <b-col cols="10">
            <div class="flex">
              <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
              <span class="text-capitalize font-bold underline hover:text-green">{{source.toLowerCase()}}</span>
            </div>
            <p class="text-gray mt-2" v-if="messages.length > 0">
              {{ messages[0].messageData.comment }}
            </p>
            <span class="italic text-gray" style="font-size: 10px;">{{dateTimeFormat(application.createdAt)}}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row>
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{application.data.name}}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Registration Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Variant</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.data.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{application.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize" v-if="application.data.subType">{{cleanEntityType(application.data.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-capitalize">{{application.data.variant.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1 text-capitalize">{{application.applicant.givenName}} {{application.applicant.surname}}</td>
                <td class="px-2 py-1">{{application.applicant.email}}</td>
                <td class="px-2 py-1">{{application.applicant.phoneNumber}}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col cols="12">
            <PRNViewer :tracking-number="application.data.trackingNo" :prn="application.data.payment.prn"/>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button type="button" class="btn-cancel" @click="onCancel">
                <i class="fa fa-times text-white mr-1"></i> Cancel Reservation
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
              <button class="btn-approve" @click="replyPrompt">
                <i class="fa fa-reply text-white mr-1"></i> Reply
              </button>
              <button class="btn-approve" @click="assignPrompt">
                <i class="fa fa-hand-pointer text-white mr-1"></i> Assign
              </button>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'application-details') ? 'menu-item-active' : 'menu-item']" @click="select('application-details')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Details</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Attachments</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Messages</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <BuildingSocietyFormDetailsEscalated :application="application" v-if="currentRoute === 'application-details'"/>
            <BuildingSocietyFormFilesEscalated :application="application" v-if="currentRoute === 'attachments'"/>
            <BuildingSocietyMessagesEscalated :application="application" v-if="currentRoute === 'messages'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import Escalation from "@/views/home/commons/Escalation";
import BuildingSocietyFormDetailsEscalated
  from "@/views/home/services/bsi_incorporation/escalations/tabs/BuildingSocietyFormDetailsEscalated";
import Assignment from "@/views/home/commons/Assignment";
import AssignmentReply from "@/views/home/commons/AssignmentReply";
import {mapActions, mapGetters} from "vuex";
import BuildingSocietyFormFilesEscalated
  from "@/views/home/services/bsi_incorporation/escalations/tabs/BuildingSocietyFormFilesEscalated";
import BuildingSocietyMessagesEscalated
  from "@/views/home/services/bsi_incorporation/escalations/tabs/BuildingSocietyMessagesEscalated";
import PRNViewer from "@/views/home/commons/PRNViewer";

export default {
  name: "BuildingSocietyEscalationFile",
  components: {
    PRNViewer,
    BuildingSocietyMessagesEscalated,
    BuildingSocietyFormFilesEscalated,
    AssignmentReply, Assignment, BuildingSocietyFormDetailsEscalated, Escalation},
  props:{
    message:String,
    escalationId:String,
    source:String,
    sourceId:String
  },
  data(){
    return{
      margin:"2px",
      currentRoute: "application-details",
      loading:true,
      taskId:null,
      application:{},
      messages:[],
      form:{
        buildingSocietyId:null,
        queueId:null,
        reason:null,
        status:null,
        askUserToCancelReservation:false
      },
      action:null,
      userId:null,
      isShowingThread:false
    }
  },
  mounted() {
    this.fetchBSITaskMessages(this.escalationId)
    this.fetchBSIncorporationEscalation(this.escalationId)
  },
  methods:{
    ...mapActions(["fetchBSITaskMessages", "fetchBSIncorporationEscalation", "updateBSIncorporationStatus"]),
    select(route){
      this.currentRoute = route
    },
    onApprove(){
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onCancel(){
      this.form.status = "QUERIED"
      this.form.askUserToCancelReservation = true
      this.action = "final"
      this.$refs["query-incorporation"].show();
    },
    onQuery(){
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.reason != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    replyPrompt(){
      this.action = "reply"
      this.$refs.confirm.show();
    },
    assignPrompt(){
      this.action = "assign"
      this.$refs.confirm.show();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    yesConfirmation(){
      if(this.action === "reply"){
        this.taskId = this.application.taskId
        this.userId = this.source.id
        this.$refs.replySubmissions.show();
      }
      if(this.action === "final"){
        this.loading = true
        this.updateBSIncorporationStatus(this.form)
      }
      if(this.action === "assign"){
        this.taskId = this.application.taskId
        this.$refs.assignSubmissions.show();
      }

      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
  computed:{
    ...mapGetters(["getBSIncorporationTaskMessages", "getBSIncorporationEscalation", "getBSUpdateStatusSuccess"])
  },
  watch:{
    getBSUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      this.$refs["query-incorporation"].hide();
      window.location.reload()
    },
    getBSIncorporationEscalation(data){
      this.loading = false
      this.application = data.data
      this.form.queueId = data.taskId
      this.form.buildingSocietyId = data.data.id
    },
    getBSIncorporationTaskMessages(data){
      this.loading = false
      this.messages = data
    },
  }
}
</script>

<style scoped>
.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

</style>