<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col>
                    <b-input-group class="border border-grey rounded-sm my-2">
                        <b-form-input
                                class="form-text text-sm noFocus border-0 text-gray"
                                v-model="form.trackingNumber"
                                type="text"
                                disabled
                                placeholder="Tracking Number">
                        </b-form-input>
                    </b-input-group>

                    <label class="text-gray labelStyle mx-1" style="font-size: 14px;">Reason for the change ?</label>
                    <b-form-select
                            class="form-select"
                            v-model="form.reason"
                    >
                        <b-form-select-option value="PRACTITIONER_RESIGNATION">Practitioner resignation</b-form-select-option>
                        <b-form-select-option value="PRACTITIONER_NEGLIGENCE">Practitioner negligence</b-form-select-option>
                        <b-form-select-option value="COURT_ORDER">Court Order</b-form-select-option>
                    </b-form-select>


                    <b-textarea v-model="form.furtherDetails"
                                class="my-2"
                                rows="4"
                                placeholder="Further details for this reason">
                    </b-textarea>

                    <b-textarea v-model="form.venue"
                                class="mb-2"
                                rows="4"
                                placeholder="Venue">
                    </b-textarea>
                    <b-textarea v-model="form.caseNumber"
                                class="mb-2"
                                rows="4"
                                placeholder="Case Number">
                    </b-textarea>

                    <b-input-group class="border border-grey rounded-sm my-2">
                        <b-form-input
                                class="form-text text-sm noFocus border-0 text-gray"
                                v-model="form.issuingCourt"
                                type="text"
                                placeholder="Issuing Court">
                        </b-form-input>
                    </b-input-group>
                    <label class="text-gray labelStyle mx-1" style="font-size: 14px;">Return application to account ?</label>
                    <b-form-select
                            class="form-select"
                    v-model="form.returnToCompanyAccount"
                    >
                        <b-form-select-option :value="true">Yes</b-form-select-option>
                        <b-form-select-option :value="false">No</b-form-select-option>
                    </b-form-select>

                    <label class="text-gray labelStyle mx-1" style="font-size: 14px;">Date of court</label>
                    <datepicker
                            :disabled-dates="{from:new Date()}"
                            placeholder="dd/mm/yyyy"
                            class="noFocus"
                            :typeable="false"
                            bootstrap-styling
                            id="dateOfBirth"
                            format="dd/MM/yyyy"
                            v-model="form.courtDate"
                    ></datepicker>
                    <label class="text-gray labelStyle mx-1" style="font-size: 14px;">Attachment(Court order/letter)</label>
                    <b-input-group>
                        <b-form-file
                                accept="application/pdf"
                                label-size="sm"
                                type="file"
                                placeholder="Please provide a copy of the file "
                                drop-placeholder="Drop file here..."
                                v-model="form.file"
                                class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                                plain></b-form-file>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button
                            @click="transfer()"
                            class="button"
                            :disabled="loadingTransfer"
                            v-if="!loadingTransfer">
                        Confirm removal
                    </b-button>
                    <b-button
                            class="button"
                            :disabled="loadingTransfer"
                            v-if="loadingTransfer">
                        <b-spinner small></b-spinner>
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {api} from "@/app/services/api.service";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
    name: "ReAssignInsolvencyApplication",
    props: {trackingNumber: String},
    components: {
        Datepicker
    },
    data() {
        return {
            search: null,
            phone: null,
            email: null,
            form: {
                trackingNumber: null,
                file: null,
                reason: null,
                issuingCourt: null,
                courtDate: null,
                venue: null,
                furtherDetails: null,
                returnToCompanyAccount: true,
                caseNumber: null
            },
            loading: false,
            loadingTransfer: false,
            selectedId: null,
            description: null,
            userProfile: {},
            limit: 15,
            clients: []
        }
    },
    mounted() {
        this.fetch()
       // this.userProfile = jwtService.getUser()
        this.form.trackingNumber = this.trackingNumber
    },
    methods: {
        ...mapActions(["fetchClients", "searchClients", "transferApplicationOwnership", "transferDataUpdateApplicationOwnership", "transferReservationApplicationOwnership"]),
        fetch() {
            this.loading = true
            this.fetchClients({limit: this.limit})
        },
        onSearch() {
            this.loading = true
            let filters = {
                perPage: this.limit
            }

            if (this.search) {
                filters.name = this.search
            }


            if (this.phone) {
                filters.phone = this.phone
            }

            if (this.email) {
                filters.email = this.email
            }

            this.searchClients(this.objectToQueryParams(filters))
        },
        transfer() {
            let payload = new FormData();

            payload.set('trackingNumber', this.form.trackingNumber)
            payload.set('reason', this.form.reason)
            payload.set('issuingCourt', this.form.issuingCourt)
            payload.set('caseNumber', this.form.caseNumber)
            payload.set('courtDate', moment(this.form.courtDate).format("YYYY-MM-DD"))
            payload.set('venue', this.form.venue)
            payload.set('furtherDetails', this.form.furtherDetails)
            payload.set('returnToCompanyAccount', this.form.returnToCompanyAccount)
            payload.set('file', this.form.file)


            this.loadingTransfer = true;
            api().post("/insolvency/process/resign-practitioner", payload)
                .then(() => {
                    this.successToast("Success", "Practitioner relieved successfully the client can appoint a new practitioner");
                    this.fetch();
                    window.location.reload()
                })
                .catch((error) => {
                    this.errorToast("Error", error.response.data.message);
                    this.loadingTransfer = false;
                });

        }
    }
    ,
    computed: {
        ...
            mapGetters(["getClients", "getTransferDetails", "getTransferError"]),
    }
    ,
    watch: {
        getClients(data) {
            this.loading = false
            this.clients = data.content
        }
        ,
        getTransferDetails() {
            this.loading = false
            this.successToast("Success", "Application transferred to new owner")
            window.location.reload()
        }
        ,
        getTransferError() {
            this.loading = false
            this.errorToast("Failed to transfer")
        }
    }
}
</script>

<style scoped>
.button {
    @apply w-full bg-blue mt-1 px-2 py-1 rounded-sm text-white text-xs
}

.button:hover {
    @apply bg-green-dark;
}
</style>