<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Practitioner Complaints</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="flex">
      <b-col cols="12">
        <b-row class="py-3">
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'pending') ? 'menu-item-active' : 'menu-item']" @click="select('pending')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Pending Practitioner Complaints</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'resolved') ? 'menu-item-active' : 'menu-item']" @click="select('resolved')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Resolved Practitioner Complaints</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'dismissed') ? 'menu-item-active' : 'menu-item']" @click="select('dismissed')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Dismissed Practitioner Complaints</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <PendingPractitionerComplaints v-if="currentRoute === 'pending'" />
            <ResolvedPractitionerComplaints v-if="currentRoute === 'resolved'" />
            <DismissedPractitionerComplaints v-if="currentRoute === 'dismissed'" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PendingPractitionerComplaints from "./PendingPractitionerComplaints";
import ResolvedPractitionerComplaints from "./ResolvedPractitionerComplaints";
import DismissedPractitionerComplaints from "./DismissedPractitionerComplaints";
export default {
  name: "PractitionerComplaints",
  components: {DismissedPractitionerComplaints, ResolvedPractitionerComplaints, PendingPractitionerComplaints},
  data() {
    return {
      currentRoute: "pending",
    }
  },
  methods: {

    select(route){
      this.currentRoute = route
    },
  }
}
</script>

<style scoped>

</style>