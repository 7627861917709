<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-assign" size="xl" hide-footer ref="escalate-details" title="Escalate Registration">
        <BusinessNameEscalationFile :application="application" :source="source"
                                    :escalation-id="escalationId" :message="message"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Business Names Escalations</span>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12">
        <div class="mx-auto">
          <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">Tracking No.</th>
                <th class="t-header">Name</th>
                <th class="t-header">Sub Entity Type</th>
                <th class="t-header text-center">Escalated By</th>
                <th class="t-header text-center">Action</th>
                <th class="t-header text-center">Date</th>
              </tr>
              </thead>
              <tbody>
                <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
                  <td class="t-body text-blue font-bold">{{submission.business.no}}</td>
                  <td class="t-body font-bold">
                    {{submission.business.reservation.name}}
                  </td>
                  <td class="t-body text-capitalize">{{cleanEntityType(submission.business.subType)}}</td>

                  <td class="t-body text-center">
                    <span class="text-blue text-xs">{{submission.fromUser.firstName}} {{submission.fromUser.lastName}}</span>
                  </td>
                  <td class="t-body text-center">
                    <div class="flex">
                      <button @click="view(submission)" class="bg-blue px-2 py-1 rounded-sm text-white w-full">View</button>
                    </div>
                  </td>
                  <td class="t-body text-center">{{dateFormat(submission.business.createdAt)}}</td>
                </tr>
                <tr>
                  <td v-if="submissions.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                    <span class="text-sm">No results found</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col cols="6"></b-col>
      <b-col cols="1">
        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
          <b-select-option value="15">15</b-select-option>
          <b-select-option value="20">20</b-select-option>
          <b-select-option value="50">50</b-select-option>
          <b-select-option value="100">100</b-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="mx-auto">
            <p class="paginate-details text-center text-xs font-bold">
              <span>{{((currentPage - 1) * limit) + 1}}</span> -
              <span v-if="limit > submissions.length">{{pagination.totalElements}}</span>
              <span v-else>{{currentPage * limit}}</span>
              of {{pagination.totalElements}}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex"
import router from "@/router";
import moment from "moment";
import BusinessNameEscalationFile
  from "@/views/home/services/registration/escalations/BusinessNameEscalationFile";
import jwtService from "@/app/services/jwt.service";

export default {
  name: "BusinessNameRegistrationEscalations",
  components: {BusinessNameEscalationFile},
  data(){
    return{
      loading:false,
      currentPage:1,
      limit:6,
      submissions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      assignForm:{
        taskId:null,
        toUserId:null
      },
      deAssignForm:{
        reservationId:null,
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      search:"",
      escalationId:null,
      application:null,
      message:null,
      source:{}
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchBusinessNameEscalations", "fetchUsersByPermission", "assignBNRTask"]),
    open(trackingNumber){
      router.push("/submission/reservation/"+trackingNumber)
    },
    fetch(){
      let user = jwtService.getUser()
      let data = {
        status:"PENDING",
        userId: user.id,
        page:this.currentPage,
        limit:this.limit
      }
      this.loading = true
      this.fetchBusinessNameEscalations(data)
    },
    view(submission){
      this.application = submission.business
      this.source = submission.fromUser
      this.escalationId = submission.escalationId
      this.message = submission.description
      this.$refs["escalate-details"].show()
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getRegistrationEscalations", "getRegistrars", "getAssignmentSuccess", "getAssignmentError",
      "getDeAssignmentSuccess", "getDeAssignmentSuccess", "getReservationError"])
  },
  watch:{
    getRegistrationEscalations(data){
      this.loading = false
      this.submissions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getAssignmentSuccess(){
      this.successToast("Success", "Assignment has been completed")
      this.$refs.assignSubmissions.hide();
      this.fetch()
    },
    getAssignmentError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    },
    getDeAssignmentSuccess(){
      this.successToast("Success", "De-assignment has been completed")
      this.$refs.assignSubmissions.hide();
      this.fetch()
    },
    getDeAssignmentError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.verified{
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified{
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>