<template>

    <b-row>
        <b-col>

           <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Current Beneficial Owners</td>
                </tr>
                </thead>
                <tbody v-if="data">
                <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Country</td>
                    <td class="t-body">
                        {{data.country.name}}
                    </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Physical Address</td>
                    <td class="t-body text-uppercase">
                        <p v-if="data.village">
                            <span class="mr-1">{{data.village.region.name}} > </span>
                            <span class="mx-1">{{data.village.district.name}} > </span>
                            <span class="mx-1">{{data.village.subcounty.name}} > </span>
                            <span class="mx-1">{{data.village.parish.name}} > </span>
                            <span class="mx-1">{{data.village.village.name}}</span>
                        </p>
                        <p v-else>N/A</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                <tbody v-if="data">
                <tr>
                    <td colspan="12">
                        <BeneficialOwners :persons="data.beneficialOwners" :entity-persons="data.entityBeneficialOwners"/>
                    </td>
                </tr>
                </tbody>
            </table>

            <b-row>
                <b-col>
                    <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;" v-if="data.changes.addedBeneficialOwners">
                        <thead>
                        <tr class="bg-green-pale">
                            <td colspan="12" class="px-2 py-2 font-bold">Added Beneficial Owners</td>
                        </tr>
                        </thead>
                        <tbody v-if="data">

                        </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                        <tbody v-if="data">
                        <tr>
                            <td colspan="12">
                                <BeneficialOwners
                                        :persons="data.changes.addedBeneficialOwners.beneficialOwners"
                                        :entity-persons="data.changes.addedBeneficialOwners.entityBeneficialOwners"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>

                <b-col>
                    <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                        <thead>
                        <tr class="bg-red-pale">
                            <td colspan="12" class="px-2 py-2 font-bold">Removed Beneficial Owners</td>
                        </tr>
                        </thead>
                        <tbody v-if="data">


                        </tbody>
                    </table>
                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;" v-if="data.changes.removedBeneficialOwners">
                        <tbody v-if="data">
                        <tr>
                            <td colspan="12">
                                <BeneficialOwners
                                        :persons="data.changes.removedBeneficialOwners.beneficialOwners"
                                        :entity-persons="data.changes.removedBeneficialOwners.entityBeneficialOwners"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>


            <b-row>
                <b-col>

                    <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                        <thead>
                        <tr class="bg-green-pale">
                            <td colspan="12" class="px-2 py-2 font-bold">Particulars Changed</td>
                        </tr>
                        </thead>
                    </table>

                    <table cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 7px;">
                        <tr style="text-align: center; font-weight: bold;">
                            <td
                                    style="border-top: solid 1px #000000;border-left: solid 1px #000000;border-bottom: solid 1px #000000; width: 5%">

                            </td>
                            <td
                                    style="border-top: solid 1px #000000;border-right: solid 1px #000000;border-bottom: solid 1px #000000; width: 25%">
                                Name
                            </td>

                            <td
                                    style="border-top: solid 1px #000000;border-right: solid 1px #000000;border-bottom: solid 1px #000000;width: 10%">
                                Change effected
                            </td>
                            <!--          <td
                                        style="border-top: solid 1px #000000;border-bottom: solid 1px #000000;border-right: solid 1px #000000;border-bottom: solid 1px #000000; width: 30%">
                                        Old value
                                      </td>
                                      <td
                                        style="border-top: solid 1px #000000;border-bottom: solid 1px #000000;border-right: solid 1px #000000;border-bottom: solid 1px #000000; width: 30%">
                                        New value
                                      </td>-->

                        </tr>

                        <tr v-for="(owner, idx) in data.changes.beneficialOwnerChanges" :key="idx">
                            <td v-if="owner.changes.length >0" style="border-left: solid 1px #000000;border-bottom: solid 1px #000000; width: 5%">
                                <p style=" text-transform: uppercase; padding-left: 2px; padding-right: 2px;">

                                </p>
                            </td>
                            <td v-if="owner.changes.length >0" style="border-right: solid 1px #000000;border-bottom: solid 1px #000000; width: 10%">
                                <p style="text-transform: uppercase; padding-left: 2px; padding-right: 2px;">
                                    {{ owner.name}}
                                </p>
                            </td>
                            <td v-if="owner.changes.length >0" style="border-right: 1px solid;border-bottom: solid 1px #000000; width: 85%">


                                <table cellspacing="0" cellpadding="0" style="width: 100%; margin-bottom: 1px;">
                                    <tr v-for="(change, idxx) in owner.changes" :key="idxx">
                                        <td style="border-right: solid 1px #000000;border-bottom: solid 1px #000000;">
                                            <p style=" text-transform: uppercase; padding-left: 2px; padding-right: 2px;">
                                                {{ idxx+1 }}
                                            </p>
                                        </td>
                                        <td style="border-right: solid 1px #000000;border-bottom: solid 1px #000000;">
                                            <p style=" text-transform: uppercase; padding-left: 2px; padding-right: 2px;">
                                                {{ cleanUnderscores(change.type)}} (Old Vs New)
                                            </p>
                                        </td>
                                        <td style="border-right: solid 1px #000000;border-bottom: solid 1px #000000;">
                                            <p style=" text-transform: uppercase; padding-left: 2px; padding-right: 2px;">
                                                {{ change.oldValue }}
                                            </p>
                                        </td>
                                        <td style="border-right: solid 1px #000000;border-bottom: solid 1px #000000;">
                                            <p style=" text-transform: uppercase; padding-left: 2px; padding-right: 2px;">
                                                {{ change.newValue }}
                                            </p>
                                        </td>


                                    </tr>
                                </table>


                            </td>



                        </tr>

                    </table>


                </b-col>
            </b-row>





        </b-col>
    </b-row>

</template>

<script>
import BeneficialOwners from "@/views/home/commons/BeneficialOwners.vue";
export default {
    name: "AmendmentsChangeBeneficialOwners",
    components :{
        BeneficialOwners
    },
    props:{
        data:Object
    },

}
</script>

<style scoped>

</style>