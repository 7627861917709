import {api} from "@/app/services/api.service";

const state = {
  registerData: null,
  registerError: null
};

const getters = {
  getRegistrationData: state => state.registerData,
  getRegisterError: state => state.registerError
};

const actions = {
  async doRegisterUser({commit}, data) {
    await api()
        .post("auth/api/users", data)
        .then(response => {
          commit("setUserRegistrationData", response);
        })
        .catch(error => {
          commit("setRegisterError", error.response);
        });
  }
};

const mutations = {
  setUserRegistrationData: (state, response) => {
    state.registerData = response.data;
  },
  setRegisterError: (state, error) => {
    state.registerError = error.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
