<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmission" title="Escalate Dispensation">
        <Escalation :task-id="getDispensationTask.taskId" type="dispensation" permission="can_view_dispensation_escalations"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.

            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-query" hide-footer ref="query-dispensation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
                v-model="form.reason"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
            </b-form-textarea>
            <p v-if="form.reason" class="my-2 text-xs">{{form.reason.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-modal id="modal-reject" size="lg" hide-footer ref="reject-dispensation" title="Reject Dispensation">
      <b-col cols="12">
        <b-form class="w-full">
          <b-form-group
              class="text-sm my-1"
              id="input-group-1"
              label="Your Custom Message"
              label-for="input-1">
            <b-form-textarea
                v-model="form.reason"
                type="text"
                placeholder="Give a personal description of query"
                class="mt-1"
                required>
            </b-form-textarea>
          </b-form-group>
          <b-row>
            <b-col cols="10"></b-col>
            <b-col cols="2">
              <button type="button" class="float-end btn-reject-hover" @click="onReject">
                Reject <b-spinner v-if="loading" small class="ml-1"></b-spinner>
              </button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-modal>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row class="mx-1" v-if="getDispensationTask.data.lastEscalation && messages.length > 0">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
            <b-row>
              <b-col>
                <div class="flex">
                  <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                  <span class="text-sm font-bold underline text-capitalize">{{messages[0].messageData.sender.toLowerCase()}}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-sm">{{messages[0].messageData.comment}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span style="font-size:9px">{{dateFormat(messages[0].messageData.createdAt)}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Escalation Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="getDispensationTask.data.lastQuery && messages.length > 0">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
            <b-row>
              <b-col>
                <div class="flex">
                  <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                  <span class="text-sm font-bold underline text-capitalize">{{messages[0].messageData.sender.toLowerCase()}}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-sm">{{messages[0].messageData.comment}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span style="font-size:9px">{{dateFormat(messages[0].messageData.createdAt)}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Query Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{getDispensationTask.data.newName}} <span class="line-through uppercase italic text-gray">{{
                dispensationSuffix(getDispensationTask.data.name)
              }}</span>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Dispensation Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">New Name</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Dispensation Type</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{getDispensationTask.data.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{getDispensationTask.data.newName}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{getDispensationTask.data.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(getDispensationTask.data.type)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(getDispensationTask.data.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <PRNViewer :tracking-number="getDispensationTask.data.trackingNo" :prn="getDispensationTask.data.payment.prn"/>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="float-end btn-reject-hover" @click="rejectPrompt">
                Reject <b-spinner v-if="loading" small class="ml-1"></b-spinner>
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <DispensationApplicationDetails :task="getDispensationTask"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import Escalation from "@/views/home/commons/Escalation.vue";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import DispensationApplicationDetails from "@/views/home/services/dispensation/task/DispensationApplicationDetails.vue";

export default {
  name: "DispensationTask",
  components: {DispensationApplicationDetails, PRNViewer, Escalation},
  data(){
    return{
      margin:"2px",
      currentRoute: "dispensation-details",
      loading:false,
      messages:[],
      taskId:null,
      action:null,
      maxText:500,
      task:{
        data:null
      },
      snapshot:null,
      snapshots:[],
      form:{
        dispensationId:null,
        queueId:null,
        reason:null,
        status:null,
      },
    }
  },
  mounted() {
    this.fetchDispensationTask()
  },
  methods:{
    ...mapActions(["updateDispensationTaskStatus", "fetchDispensationTask", "fetchDispensationTaskCount", "fetchDispensationTaskMessages"]),
    onApprove(){
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onReject(){
      this.form.status = "REJECTED"
      this.action = "final"
      if(this.form.reason !== null){
        this.$refs["reject-dispensation"].hide();
        this.$refs.confirm.show();
      } else {
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    onQuery(){
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.reason != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateDispensationTaskStatus(this.form)
      }

      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }

      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.loading = true
      this.$refs.escalateSubmission.show();
      this.$refs["reject-dispensation"].hide();
    },
    rejectPrompt(){
      this.action = "reject"
      this.$refs["reject-dispensation"].show();
    },
    cleanEntityType(name){
      if (typeof name !== "string") {
        return null
      }
      let n = name.replaceAll("-", " ").replaceAll("_", " ")
      return n.charAt(0).toLowerCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY h:mmA")
    },
    dispensationSuffix(name){
      if (typeof name !== "string") {
        return ""
      }

      if (name.toUpperCase().endsWith("LIMITED")) {
        return "limited"
      } else if (name.toUpperCase().endsWith("LTD")) {
        return "ltd"
      } else {
        return ""
      }
    },
  },
  computed:{
    ...mapGetters([
      "getDispensationTask",
      "getDispensationTaskError",
      "getDispensationTaskCount",
      "getDispensationTaskUpdateStatus",
      "getDispensationTaskUpdateStatusError",
      "getDispensationTaskMessages",
    ])
  },
  watch:{
    getDispensationTask(data){
      this.task = data
      this.loading = false
      this.form.dispensationId = this.getDispensationTask.data.id
      this.form.queueId = this.getDispensationTask.taskId
      this.fetchDispensationTaskMessages(this.getDispensationTask.taskId)
      this.fetchDispensationTaskCount()
      if(data == null){
        this.successToast("Success", "No task yet")
      }
    },
    getDispensationTaskError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data))
    },
    getDispensationTaskMessages(data){
      this.loading = false
      this.messages = data
    },
    getDispensationTaskUpdateStatus(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      window.location.reload()
    },
    getDispensationTaskUpdateStatusError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    },
  }
}
</script>

<style scoped>
@import "/src/assets/styles/menu.css";

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-reject{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-reject-hover{
  @apply bg-red border-red rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}

</style>