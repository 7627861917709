import {api} from "@/app/services/api.service";

const state = {
    co_beneficial_owners_registry:null,
    bn_beneficial_owners_registry:null,
    entity_beneficial_owners:null,
    error:null,
};

const getters = {
    getBeneficialOwnerFile: (state) => state.entity_beneficial_owners,
    getBeneficialOwnersCompanyRegistry: (state) => state.co_beneficial_owners_registry,
    getBeneficialOwnersBusinessRegistry: (state) => state.bn_beneficial_owners_registry,
    getBeneficialOwnerError: (state) => state.error,
};

const actions = {
    async fetchCompanyBeneficialOwnerRegistry({commit}, data) {
        let path = "/incorporation/registry/beneficial-owners?page="+data.page+"&pageSize="+data.limit
        if(data.search){
            path+="&search="+data.search
        }
        if(data.subType){
            path+="&subType="+data.subType
        }
        if(data.status){
            path+="&status="+data.status
        }
        if(data.startDate){
            path+="&startDate="+data.startDate
        }
        if(data.endDate){
            path+="&endDate="+data.endDate
        }

        await api()
            .get(path)
            .then(response => {
                commit("setCompanyBORegistry", response.data);
            })
            .catch(error => {
                commit("setBeneficialOwnerError", error.response);
            });
    },
    async fetchBusinessNameBeneficialOwnerRegistry({commit}, data) {
        let path = "/business-registration/beneficial-owners?page="+data.page+"&limit="+data.limit
        if(data.search){
            path+="&search="+data.search
        }
        if(data.subType){
            path+="&subType="+data.subType
        }
        if(data.status){
            path+="&status="+data.status
        }
        if(data.startDate){
            path+="&startDate="+data.startDate
        }
        if(data.endDate){
            path+="&endDate="+data.endDate
        }

        await api()
          .get(path)
          .then(response => {
              commit("setBusinessBORegistry", response.data);
          })
          .catch(error => {
              commit("setBeneficialOwnerError", error.response);
          });
    },
    async fetchCompanyBeneficialOwnersByBRN({commit}, brn) {
        await api()
            .get("/incorporation/beneficial-owners/"+brn)
            .then(response => {
                commit("setBOFile", response.data);
            })
            .catch(error => {
                commit("setBeneficialOwnerError", error.response);
            });
    },async fetchBnBeneficialOwnersByBRN({commit}, brn) {
        await api()
            .get("/business-registration/beneficial-owners/"+brn)
            .then(response => {
                commit("setBOFile", response.data);
            })
            .catch(error => {
                commit("setBeneficialOwnerError", error.response);
            });
    },
};

const mutations = {
    setBOFile: (state, response) => {
        state.entity_beneficial_owners = response;
    },
    setCompanyBORegistry: (state, response) => {
        state.co_beneficial_owners_registry = response.data;
    },
    setBusinessBORegistry: (state, response) => {
        state.bn_beneficial_owners_registry = response;
    },
    setBeneficialOwnerError: (state, response) => {
        state.error = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
