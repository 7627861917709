<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Certification">
          <Escalation :task-id="taskId" type="certification" permission="can_view_certification_escalations"/>
        </b-modal>
      </b-row>
      <b-row class="mx-1 mt-3">
        <b-col>
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Report Request Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Search Reference</th>
                  <th class="px-2 py-1">Applicant</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue text-capitalize">{{task.status.toLowerCase()}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{task.searchReportRequestWrapper.searchRef}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{task.searchReportRequestWrapper.accountHolder}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(task.creationDate)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col cols="12">
              <SearchReportDetails :task="task" :reference="task.searchReportRequestWrapper.searchRef" :queue-task-id="task.id"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Escalation from "@/views/home/commons/Escalation.vue";
import moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";
import SearchReportDetails from "@/views/home/services/search_reports/task/SearchReportDetails.vue";
export default {
  name: "SearchReportTask",
  components: {
    SearchReportDetails,
    Escalation },
  data(){
    return{
      margin:"2px",
      currentRoute: "application-details",
      loading:false,
      documentUrl:null,
      messages:[],
      taskId:null,
      certifiable:[],
      task:{}
    }
  },
  mounted() {
    let id = JSON.parse(sessionStorage.getItem("current_user")).userId
    this.fetchSearchReportTask(id)
  },
  methods:{
    ...mapActions(["fetchSearchReportTask"]),
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
  computed:{
    ...mapGetters(["getSearchReportTask", "getSearchReportTaskError"])
  },
  watch:{
    getSearchReportTask(data){
      this.task = data.data
      this.taskId = data.data.id
    },
    getSearchReportTaskError(response){
      this.loading = false
      this.errorToast("Error", response.data.message)
    },
  }
};
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}
</style>