<template>
    <div class="my-3">

        <b-row>
            <b-col>

                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12">
                                <table class="min-w-full leading-normal mt-1 text-gray"
                                       style="border: solid 1px #dedede;">
                                    <thead>
                                    <tr class="bg-gray-pale">
                                        <td colspan="12" class="px-2 py-2 font-bold">Application Details</td>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="border: solid 1px #dedede;">
                                        <td class="px-2 py-1 text-capitalize font-bold">Type of Application</td>
                                        <td class="px-2 py-1">{{ cleanUnderscores(application.data.type) }}</td>
                                    </tr>

                                    <tr style="border: solid 1px #dedede;">
                                        <td class="px-2 py-1 text-capitalize font-bold">Nature of application</td>
                                        <td class="px-2 py-1">
                                            {{ cleanUnderscores(application.data.natureOfDispute) }}
                                        </td>
                                    </tr>

                                    <tr style="border: solid 1px #dedede;">
                                        <td class="px-2 py-1 text-capitalize font-bold">Subject</td>
                                        <td class="px-2 py-1">
                                            {{ application.data.subject }}
                                        </td>
                                    </tr>
                                    <!-- <tr style="border: solid 1px #dedede;">
                                        <td class="px-2 py-1 text-capitalize font-bold">Cause No.</td>
                                        <td class="px-2 py-1">
                                            {{ application.data.causeNo }}
                                        </td>
                                    </tr>-->

                                    <tr style="border: solid 1px #dedede;">
                                        <td class="px-2 py-1 text-capitalize font-bold">Year</td>
                                        <td class="px-2 py-1">
                                            {{ application.data.year }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </b-col>
                        </b-row>

                    <b-row class="mt-2">
                            <b-col cols="12">
                                <table class="min-w-full leading-normal text-gray"
                                       style="border: solid 1px #dedede;">
                                    <thead>
                                    <tr class="bg-gray-pale">
                                        <td colspan="12" class="px-2 py-2 font-bold">Corporate Subject Entity</td>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr style="border: solid 1px #dedede;">
                                        <td class="px-2 py-1">

                                            <LegalPersons class="p-1"
                                                          :persons="[{entityType:'CORPORATE', corporate: {...application.data.corporateSubjectEntity}}]"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>

                           <b-row class="mt-2" v-if="showComplainants">
                                <b-col cols="12">
                                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                                        <thead>
                                        <tr class="bg-gray-pale">
                                            <td colspan="12" class="px-2 py-2 font-bold">Complainants</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <LegalPersons class="p-1" :persons="application.data.complainants"/>
                                        </tbody>
                                    </table>
                                </b-col>
                            </b-row>

                            <b-row v-if="showRespondents" class="mt-2">
                                <b-col cols="12">

                                    <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                                        <thead>
                                        <tr class="bg-gray-pale">
                                            <td colspan="12" class="px-2 py-2 font-bold">Respondents</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <LegalPersons class="p-1" :persons="application.data.respondents"/>
                                        </tbody>
                                    </table>

                                </b-col>
                            </b-row>
                        <!--


                        <b-row class="mt-2" v-if="application.data.legalDocumentSubjectEntity">
                            <b-col cols="12">
                                <table class="min-w-full leading-normal mt-1 text-gray"
                                       style="border: solid 1px #dedede;">
                                    <thead>
                                    <tr class="bg-gray-pale">
                                        <td colspan="12" class="px-2 py-2 font-bold">Legal document</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <b-row class="mx-1 py-1" v-if="application.data.legalDocumentSubjectEntity">
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Instrument Number"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="instrumentNumber"
                                                        class="form-text text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.legalDocumentSubjectEntity.instrumentNumber"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Document Type"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="documentType"
                                                        class="form-text text-sm noFocus text-capitalize"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.legalDocumentSubjectEntity.documentType.toLowerCase()"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Subject Matter"
                                                    label-for="input-1">
                                                <b-textarea
                                                        id="subjectMatter"
                                                        class="form-text text-sm noFocus text-capitalize"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.legalDocumentSubjectEntity.subjectMatter"
                                                        required>
                                                </b-textarea>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Registration Date"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="registrationDate"
                                                        class="form-text text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.legalDocumentSubjectEntity.registrationDate"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>

                                        </b-col>

                                    </b-row>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>
!-->

                        <b-row class="mt-2" v-if="application.data.addressOfService">
                            <b-col cols="12">
                                <table class="min-w-full leading-normal mt-1 text-gray"
                                       style="border: solid 1px #dedede;">
                                    <thead>
                                    <tr class="bg-gray-pale">
                                        <td colspan="12" class="px-2 py-2 font-bold">Address of service</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <b-row class="mx-1 py-1" v-if="application.data.addressOfService">
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Region"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.addressOfService.village.region.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="District"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus text-capitalize"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.addressOfService.village.district.name.toLowerCase()"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Sub County"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus text-capitalize"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.addressOfService.village.subcounty.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Parish"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.addressOfService.village.parish.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Village"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus text-capitalize"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="application.data.addressOfService.village.village.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Street"
                                                    label-for="input-1">
                                                                                       <b-form-input
                                                                                                  v-if="application.data.addressOfService.address"
                                                                                                  id="companyName"
                                                                                                  class="form-text text-sm noFocus text-capitalize"
                                                                                                  type="text"
                                                                                                  :disabled="true"
                                                                                                  :value="application.data.addressOfService.address.street"
                                                                                                  required>
                                                                                          </b-form-input>&ndash;&gt;
                                            </b-form-group>
                                        </b-col>
                                        <b-col v-if="application.data.addressOfService.address">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Plot"
                                                    label-for="input-1">
                                                &lt;!&ndash;                                          <b-form-input
                                                                                                  v-if="application.data.addressOfService.address"
                                                                                                  id="companyName"
                                                                                                  class="form-text text-sm noFocus text-capitalize"
                                                                                                  type="text"
                                                                                                  :disabled="true"
                                                                                                  :value="application.data.addressOfService.registeredAddress.plot"
                                                                                                  required>
                                                                                          </b-form-input>&ndash;&gt;
                                            </b-form-group>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Postal Address"
                                                    label-for="input-1">
                                                &lt;!&ndash;                                          <b-form-input
                                                                                                  v-if="application.data.addressOfService.address"
                                                                                                  id="companyName"
                                                                                                  class="form-text text-sm noFocus text-capitalize"
                                                                                                  type="text"
                                                                                                  :disabled="true"
                                                                                                  :value="application.data.addressOfService.address.postalAddress"
                                                                                                  required>
                                                                                          </b-form-input>
                                                                                          <PostalAddressValidator v-if="application.data.addressOfService.address.postalAddress" :box-number="application.data.addressOfService.registeredAddress.postalAddress"/>
                                                                                      &ndash;&gt;</b-form-group>
                                        </b-col>
                                    </b-row>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>

                        <b-row v-if="application.data.facts" class="my-1">
                            <b-col>
                                <table class="min-w-full leading-normal mt-1 text-gray"
                                       style="border: solid 1px #dedede;">
                                    <thead>
                                    <tr class="bg-gray-pale">
                                        <td colspan="12" class="px-2 py-2 font-bold">Facts</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="border: solid 1px #dedede;" v-for="(fact,idx) in application.data.facts" :key="idx">
                                        <td class="px-2 py-1">
                                            {{fact.description }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>
                        <!-- prayer -->

                        <b-row v-if="application.data.prayer" class="my-1">
                            <b-col>
                                <table class="min-w-full leading-normal mt-1 text-gray"
                                       style="border: solid 1px #dedede;">
                                    <thead>
                                    <tr class="bg-gray-pale">
                                        <td colspan="12" class="px-2 py-2 font-bold">Prayer/ Relief being sought</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr style="border: solid 1px #dedede;">
                                        <td class="px-2 py-1">
                                            {{application.data.prayer }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-col>
                        </b-row>

                            <b-row class="mt-2" v-if="application.data.officialAccount">
                                <b-col cols="12">
                                    <table class="min-w-full leading-normal mt-1 text-gray"
                                           style="border: solid 1px #dedede;">
                                        <thead>
                                        <tr class="bg-gray-pale">
                                            <td colspan="12" class="px-2 py-2 font-bold">Official Account Details Update
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <b-row class="mx-1 py-1" v-if="application.data.officialAccount">
                                            <b-col>
                                                <b-form-group
                                                        class="text-sm my-1 text-gray"
                                                        id="input-group-1"
                                                        label="Old Email"
                                                        label-for="input-1">
                                                    <b-form-input
                                                            id="instrumentNumber"
                                                            class="form-text text-sm noFocus"
                                                            type="text"
                                                            :disabled="true"
                                                            :value="application.data.officialAccount.oldEmail"
                                                            required>
                                                    </b-form-input>
                                                </b-form-group>
                                                <b-form-group
                                                        class="text-sm my-1"
                                                        id="input-group-1"
                                                        label="Old Phone"
                                                        label-for="input-1">
                                                    <b-form-input
                                                            id="documentType"
                                                            class="form-text text-sm noFocus text-capitalize"
                                                            type="text"
                                                            :disabled="true"
                                                            :value="application.data.officialAccount.oldPhone"
                                                            required>
                                                    </b-form-input>
                                                </b-form-group>

                                            </b-col>
                                            <b-col>
                                                <b-form-group
                                                        class="text-sm my-1"
                                                        id="input-group-1"
                                                        label="New Email"
                                                        label-for="input-1">
                                                    <b-form-input
                                                            id="registrationDate"
                                                            class="form-text text-sm noFocus"
                                                            type="text"
                                                            :disabled="true"
                                                            :value="application.data.officialAccount.newEmail"
                                                            required>
                                                    </b-form-input>
                                                </b-form-group>

                                                <b-form-group
                                                        class="text-sm my-1"
                                                        id="input-group-1"
                                                        label="New Phone"
                                                        label-for="input-1">
                                                    <b-form-input
                                                            id="newEmail"
                                                            class="form-text text-sm noFocus"
                                                            type="text"
                                                            :disabled="true"
                                                            :value="application.data.officialAccount.newPhone"
                                                            required>
                                                    </b-form-input>
                                                </b-form-group>

                                            </b-col>

                                        </b-row>
                                        </tbody>
                                    </table>
                                </b-col>
                            </b-row>

                            <b-row class="mt-2">
                                <b-col cols="12">
                                    <table class="min-w-full leading-normal mt-1 text-blue-dark"
                                           style="border: solid 1px #bdd7f1;">
                                        <thead>
                                        <tr class="bg-blue-pale">
                                            <td colspan="12" class="px-2 py-2 font-bold">
                                                Applicant Details</td>
                                        </tr>
                                        <tr>
                                            <th class="px-2 py-1">Name</th>
                                            <th class="px-2 py-1">Email</th>
                                            <th class="px-2 py-1">Phone</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="border: solid 1px #bdd7f1;">
                                            <td class="px-2 py-1 text-capitalize">{{ application.data.applicant.givenName }}
                                                {{ application.data.applicant.surname }}
                                            </td>
                                            <td class="px-2 py-1">{{ application.data.applicant.email }}</td>
                                            <td class="px-2 py-1">{{ application.data.applicant.phoneNumber }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </b-col>
                            </b-row>
                    </b-col>
                </b-row>


            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";


 import LegalPersons from "@/views/home/commons/LegalPersons.vue";
import {api} from "@/app/services/api.service";
import PostalAddressValidator from "@/views/home/commons/PostalAddressValidator.vue";


export default {
    name: "DisputeResolutionFormDetailsBox",
    components: {
        PostalAddressValidator,
        LegalPersons
    },
    props: {
        application: Object
    },
    data() {
        return {
            attachmentUrl: null,
            individualDirectors: [],
            corporateDirectors: [],
            individualSecretary: null,
            corporateSecretary: null,
            adoptionTableText: null,
            oldContacts: {
                email: null,
                phone: null,
            }
        }
    },
    mounted() {

        //alert(this.application.data.corporateSubjectEntity.brn)
       // this.fetchAccountDetails(this.application.data.corporateSubjectEntity.brn);
    },

    methods: {
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        cleanName(name) {
            if (name) {
                let n = name.replaceAll("_", " ")
                return n.toLowerCase()
            } else {
                return "N/A"
            }
        },
        filingName(name) {
            if (name === "APPEND_INFORMATION") {
                return "#resolution"
            }
            return "#" + name.replaceAll("_", "").toLowerCase();
        },
        async fetchAccountDetails(brn) {
            await api().get("/auth/api/business/get-by-brn/" + brn).then((response) => {
                this.oldContacts = response.data.data
            }).catch(error => {
                this.errorToast("Could Not load account details", error.message)
            });
        }
    },

    computed: {
        showComplainants() {
            return this.application.data.type !== "OFFICIAL_ACCOUNT"
        },
        showRespondents() {
            return this.application.data.type !== "OFFICIAL_ACCOUNT"
        }
    },
}
</script>

<style scoped>
th, td {
    font-size: 12px;
}
</style>