<template>
  <div class="bg-white rounded-sm mt-5">
    <b-row>
      <b-modal id="modal-details" hide-footer ref="extension-details" title="Details">
        <b-col cols="12">
          <b-row>
            <b-col>
              <span class="text-gray text-sm font-bold">
                Reservation Extension Reason
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="rounded-sm bg-header_grey px-2 py-1 mx-2 mb-2">
              <span >
                {{extension.reason}}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <b-row>
                <b-col>
                  <button @click="onApprove()" class="approve-btn">Approve</button>
                </b-col>
                <b-col class="ml-1">
                  <button @click="onReject()" class="reject-btn">Reject</button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-modal>
    </b-row>

    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>

    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">Tracking No.</th>
            <th class="t-header">Name</th>
            <th class="t-header">Entity Type</th>
            <th class="t-header">Sub-Entity Type</th>
            <th class="t-header">Submission Date</th>
            <th class="t-header">Expiry Date</th>
          </tr>
          </thead>

          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in extensions" :key="idx">
            <td class="t-body text-blue font-bold">{{submission.reservation.no}}</td>
            <td class="t-body font-bold">{{submission.reservation.name}}</td>
            <td class="t-body text-capitalize">{{submission.reservation.type}}</td>
            <td class="t-body text-capitalize">{{cleanEntityType(submission.reservation.subType)}}</td>
            <td class="t-body">{{dateFormat(submission.reservation.createdAt)}}</td>
            <td class="t-body">{{dateFormat(submission.reservation.expiresOn)}}</td>
          </tr>
          <tr>
            <td v-if="extensions.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col class="9"></b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";

export default {
  name: "ApprovedReservationExtensions",
  data(){
    return{
      selectedId:null,
      margin:"2px",
      hasContent:false,
      extension:{},
      loading:true,
      message:null,
      currentPage:1,
      limit:6,
      form:{
        extensionId:null,
        status:null
      },
      extensions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchReservationExtensions", "extendReservation"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    fetch(){
      let data = {
        statuses:"Approved",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchReservationExtensions(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    view(submission){
      this.extension = submission
      this.form.extensionId = submission.id
      this.$refs["extension-details"].show()
    },
    onApprove(){
      this.loading = true
      this.form.status = "Approved"
      this.extendReservation(this.form)
      this.$refs["extension-details"].hide()
    },
    onReject(){
      this.loading = true
      this.form.status = "Rejected"
      this.extendReservation(this.form)
      this.$refs["extension-details"].hide()
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    clearForm(){
      this.form = {
        description:null,
        reservationId:null,
        status:null
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
    ...mapGetters([ "getReservationExtensions", "getReservationSuccess", "getReservationError"]),
  },
  watch:{
    getReservationExtensions(data){
      this.loading = false
      this.extensions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getReservationSuccess(){
      this.loading = false
      this.successToast("Action on Extension!", "Done Successfully")
      this.fetch()
      this.clearForm()
    },
    getReservationError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data))
      this.clearForm()
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.icon{
  width: 170px;
}

.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.approve-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.approve-btn:hover{
  @apply bg-green text-white;
}

.reject-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.reject-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>