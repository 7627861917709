import {api} from "@/app/services/api.service";

const state = {
    address:null,
    error:null
};

const getters = {
    getAddress: (state) => state.address,
}

const actions = {
    async fetchAddressByVillageId({commit}, id){
        api().get("/configuration/address/full-path?villageId="+id).
        then(response => {
            commit('setAddress', response.data);
        }).catch(error => {
            commit("setAddressError", error.response)
        });
    },
};

const mutations = {
    setAddress: (state, response) =>{
        state.address = response;
    },
    setAddressError: (state, error) =>{
        state.error = error;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
