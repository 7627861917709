<template>
  <div class="w-full">
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
        <Assignment :id="taskId" :type="assignmentType" permission="can_view_reservation_tasks"/>
      </b-modal>
      <b-modal no-close-on-backdrop ref="addPRN" title="Change PRN" size="md" hide-footer>
        <AddPRN :tracking-number="application.no"/>
      </b-modal>

      <b-modal ref="transferApplication" title="Transfer Application" size="md" hide-footer>
        <TransferTaskOwnership type="reservation"/>
      </b-modal>

    </b-row>
    <b-row>
      <b-col cols="3">
        <b-form-input
            class="text-gray text-sm noFocus mx-2 my-2"
            style="padding:6px 5px;"
            v-model="search"
            type="text"
            placeholder="Search by Tracking No."
            required
            v-on:keyup.enter="fetch">
        </b-form-input>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <b-form-select
              v-model="statuses"
              v-on:change="fetch"
              class="form-select noFocus text-gray mx-2 my-2 text-sm">
            <b-select-option :value="null">--Filter by Status--</b-select-option>
            <b-select-option value="Draft">Draft</b-select-option>
            <b-select-option value="Pending">In Queue</b-select-option>
            <b-select-option value="Assigned">Assigned</b-select-option>
            <b-select-option value="Queried">Queried</b-select-option>
            <b-select-option value="Rejected">Rejected</b-select-option>
            <b-select-option value="Approved">Approved</b-select-option>
            <b-select-option value="Registration">In Registration</b-select-option>
            <b-select-option value="Registered">Registered</b-select-option>
            <b-select-option value="Appealed">Appealed</b-select-option>
            <b-select-option value="Expired">Expired</b-select-option>
            <b-select-option value="Escalation">Escalated</b-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <button v-if="canViewActionWithPermission('can_transfer_reservation_ownership')" class="bg-blue px-2 py-1 mt-2 text-white rounded-sm text-sm" @click="$refs.transferApplication.show()">
          <i class="fa fa-reply"></i>Transfer Ownership</button>
      </b-col>
      <b-col cols="2"></b-col>
      <b-col cols="1">
        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
          <b-select-option value="12">12</b-select-option>
          <b-select-option value="20">20</b-select-option>
          <b-select-option value="50">50</b-select-option>
          <b-select-option value="100">100</b-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="mx-auto">
            <p class="paginate-details text-center text-xs font-bold">
              <span>{{((currentPage - 1) * limit) + 1}}</span> -
              <span v-if="limit > submissions.length">{{pagination.totalElements}}</span>
              <span v-else>{{currentPage * limit}}</span>
              of {{pagination.totalElements}}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>
    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">#</th>
            <th class="t-header">Tracking No.</th>
            <th class="t-header">Name</th>
            <th class="t-header">Entity Type</th>
            <th class="t-header">Sub Entity Type</th>
            <th class="t-header">Purpose</th>
            <th class="t-header text-center">Status</th>
            <th class="t-header text-center">Assignment</th>
            <th class="t-header text-center">Action</th>
            <th class="t-header text-center">Date</th>
          </tr>
          </thead>

          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
            <td class="t-body">{{(currentPage - 1) * limit + idx + 1}}</td>
            <td class="t-body text-blue font-bold cursor-pointer hover:underline" @click="showDetails(submission.no)">{{submission.no}}</td>
            <td class="t-body font-bold">
              <span v-if="submission.nameOptions.length > 0" >
                <p class="text-xs" v-for="(option, idx) in submission.nameOptions" :key="idx">
                  - {{option.name}}
                </p>
              </span>
            </td>
            <td class="t-body text-capitalize">{{cleanEntityType(submission.type)}}</td>
            <td class="t-body text-capitalize">{{cleanEntityType(submission.subType)}}</td>
            <td class="t-body text-capitalize">
              <b-badge class="bg-green w-full" v-if="submission.purpose.includes('NEW')">
                <i class="mx-1 fa fa-tag"></i> NEW
              </b-badge>
              <b-badge class="bg-orange w-full" v-if="submission.purpose.includes('CHANGE')">
                <i class="mx-1 fa fa-pen"></i> CHANGE
              </b-badge>
            </td>
            <td class="t-body">
              <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                <span :class="(submission.status === 'Draft') ? 'text-gray-light'
                : (submission.status === 'Pending') ? 'text-gray'
                : (submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION')? 'text-purple'
                : (submission.status.toUpperCase() === 'PAYMENT_FAILED')? 'text-red'
                : (submission.status.toUpperCase() === 'FAILED')? 'text-red'
                : (submission.status === 'Assigned')? 'text-blue'
                : (submission.status === 'Escalation')? 'text-blue'
                : (submission.status === 'Approved') ? 'text-green'
                : (submission.status === 'Registration') ? 'text-green'
                : (submission.status === 'Queried') ? 'text-orange'
                : (submission.status === 'Expired') ? 'text-red'
                : (submission.status === 'Appealed') ? 'text-red'
                : (submission.status === 'Rejected') ? 'text-red' : ''">
                    <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                      Confirming Payment
                    </span>
                    <span v-else-if="submission.status.toUpperCase() === 'REGISTRATION'">
                      In Registration
                    </span>
                    <span v-else-if="submission.status.toUpperCase() === 'FAILED'">
                      Payment Failed
                    </span>
                    <span v-else-if="submission.status.toUpperCase() === 'PENDING'">
                      In Queue
                    </span>
                    <span v-else-if="submission.status.toUpperCase() === 'ESCALATION'">
                      Escalated
                    </span>
                    <span v-else>
                      {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                    </span>
                </span>
              </p>
            </td>
            <td class="t-body">
              <p class="text-xs px-2 py-1 text-gray text-center">
                <span v-if="submission.assignedTo" class="text-uppercase">
                  {{submission.assignedTo.firstName.toLowerCase()}} {{submission.assignedTo.lastName.toLowerCase()}}
                </span>
                <span v-else-if="submission.escalation" class="text-uppercase">
                  {{submission.escalation.toUser.firstName}}
                  {{submission.escalation.toUser.lastName}}
                </span>
                <span v-else>
                  Not assigned
                </span>
              </p>
            </td>
            <td class="t-body text-center">

              <b-row v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                <b-col>
                  <button
                    class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="move(submission)">
                    <i class="fa fa-reply mx-1"></i> Move to Queue <b-spinner v-if="loadingMoveTask" small></b-spinner>
                  </button>
                </b-col>
                <b-col v-if="canViewActionWithPermission('can_remove_payment_in_reservations')">
                  <button
                    class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="removePayment(submission, idx)">
                    <i class="fa fa-times mx-1"></i> Remove Payment <b-spinner v-if="loadingRemovePayment && selectedId === idx" small></b-spinner>
                  </button>
                </b-col>
              </b-row>

              <b-row v-else-if="submission.status.toUpperCase() === 'REGISTRATION'">
                <b-col v-if="canViewActionWithPermission('can_revert_status_in_reservations')">
                  <button
                    class="text-xs bg-orange text-white px-2 py-1 rounded-sm" @click="revert(submission, idx)">
                    <i class="fa fa-reply mx-1"></i> Revert Status <b-spinner v-if="loadingRevertStatus && selectedId === idx" small></b-spinner>
                  </button>
                </b-col>
                <b-col v-if="canViewActionWithPermission('can_remove_payment_in_reservations')">
                  <button
                    class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="removePayment(submission, idx)">
                    <i class="fa fa-times mx-1"></i> Remove Payment <b-spinner v-if="loadingRemovePayment && selectedId === idx" small></b-spinner>
                  </button>
                </b-col>
              </b-row>

              <b-row v-else-if="submission.status.toUpperCase() === 'FAILED'">
                <b-col v-if="canViewActionWithPermission('can_add_prn_in_reservations')">
                  <button class="text-xs bg-blue text-white px-2 py-1 rounded-sm"
                          @click="addPRN(submission)">
                    <i class="fa fa-plus mx-1"></i> Add PRN
                  </button>
                </b-col>
                <b-col v-if="canViewActionWithPermission('can_remove_payment_in_reservations')">
                  <button class="text-xs bg-red text-white px-2 py-1 rounded-sm"
                          @click="removePayment(submission, idx)">
                    <i class="fa fa-times mx-1"></i> Remove Payment
                    <b-spinner v-if="loadingRemovePayment && selectedId === idx" small></b-spinner>
                  </button>
                </b-col>
              </b-row>

              <button v-else-if="(submission.status.toUpperCase() === 'DRAFT') && canViewActionWithPermission('can_add_prn_in_reservations')"
                class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="addPRN(submission)">
                <i class="fa fa-plus mx-1"></i> Add PRN
              </button>
              <button
                  v-else-if="submission.status.toUpperCase() === 'ESCALATION' && canViewActionWithPermission('can_assign_reservation_application')"
                  class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission, 'Escalation')">
                <i class="fa fa-plus mx-1"></i> Assign
              </button>
              <button
                  v-else-if="submission.status.toUpperCase() === 'ASSIGNED' && canViewActionWithPermission('can_assign_reservation_application')"
                  class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission, 'Assigned')">
                <i class="fa fa-redo-alt mx-1"></i>Assign
              </button>
              <button
                  v-else-if="submission.status.toUpperCase() === 'PENDING' && canViewActionWithPermission('can_assign_reservation_application')"
                  class="text-xs bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission, 'Pending')">
                <i class="fa fa-plus mx-1"></i> Assign
              </button>
              <button
                  v-else-if="submission.status.toUpperCase() === 'EXPIRED' && canViewActionWithPermission('can_revert_status_in_reservations')"
                  class="text-xs bg-orange text-white px-2 py-1 rounded-sm" @click="revert(submission, idx)">
                <i class="fa fa-reply mx-1"></i>
                Revert Status
                <b-spinner v-if="loadingRevertStatus && selectedId === idx" small></b-spinner>
              </button>
            </td>
            <td class="t-body text-center">{{dateTimeFormat(submission.createdAt)}}</td>
          </tr>
          <tr>
            <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          <tr>
            <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
              <p class="text-sm">Failed to load tasks, Try again later.</p>
              <p class="text-sm">{{errorMessage}}</p>
              <p class="text-sm">
                <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload <b-spinner v-if="loading" small></b-spinner></button>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col cols="6"></b-col>
      <b-col cols="1">
        <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
          <b-select-option value="12">12</b-select-option>
          <b-select-option value="20">20</b-select-option>
          <b-select-option value="50">50</b-select-option>
          <b-select-option value="100">100</b-select-option>
        </b-form-select>
      </b-col>
      <b-col cols="2">
        <b-row>
          <b-col class="mx-auto">
            <p class="paginate-details text-center text-xs font-bold">
              <span>{{((currentPage - 1) * limit) + 1}}</span> -
              <span v-if="limit > submissions.length">{{pagination.totalElements}}</span>
              <span v-else>{{currentPage * limit}}</span>
              of {{pagination.totalElements}}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex"
import router from "@/router";
import moment from "moment";
import Assignment from "@/views/home/commons/Assignment";
import AddPRN from "@/views/home/commons/AddPRN";
import TransferTaskOwnership from "@/views/home/commons/TransferTaskOwnership";

export default {
  name: "AllReservationSubmissions",
  components: { TransferTaskOwnership, AddPRN, Assignment },
  data(){
    return{
      count:0,
      loading:false,
      loadingMoveTask: false,
      loadingRevertStatus: false,
      loadingRemovePayment:false,
      selectedId:null,
      currentPage:1,
      limit:12,
      deAssignForm:{
        reservationId:null,
      },
      submissions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      assignmentType:"reservation",
      taskId:null,
      statuses: null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
      search:"",
      application: {},
      hasError:false,
      errorMessage:null,
      reservation_prn: null,
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchReservations", "fetchRegistrars", "assignReservationTask", "redoTransaction",
      "revertReservation", "saveReservation", "removePaymentFromReservationApplication"]),
    open(trackingNumber){
      let route = router.resolve("/submission/reservation/"+trackingNumber)
      window.open(route.href, '_blank');
    },
    attachPrn() {
      this.loading.true
      let data = {
        isicId: this.application.isicId,
        type: this.application.type,
        purpose: this.application.purpose,
        subType: this.application.subType,
        prn: this.reservation_prn,
        brn: this.application.brn,
        trackingNumber: this.application.no,
        nameOptions: this.application.nameOptions,
      }
      this.saveReservation(data)
    },
    fetch(){
      let options = this.statuses
      if(this.statuses === null){
        options = "Pending,Assigned,Rejected,Approved,Queried,Registration,Escalation"
      }
      if(this.search !== ""){
        this.currentPage = 1
      }
      let data = {
        search: this.search,
        statuses:options,
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.errorMessage = null
      this.hasError = false
      this.fetchReservations(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    removePayment(application, idx){
      this.selectedId = idx
      this.loadingRemovePayment = true
      this.removePaymentFromReservationApplication(application.no)
    },
    addPRN(application){
      this.application = application
      this.$refs.addPRN.show()
    },
    move(application){
      let data = {
        userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
        prn: application.prn
      }
      this.redoTransaction(data)
    },
    assignPrompt(data, status){
      if(status === "Escalation"){
        this.assignmentType = "reservation-as"
        this.taskId = data.escalation.escalationId
      }else{
        this.taskId = data.id
      }
      this.loading = true
      this.$refs.assignSubmissions.show();
    },
    showDetails(trackingNumber){
      this.$emit("openDetails", trackingNumber)
    },
    revert(submission, index){
      this.selectedId = index
      this.loadingRevertStatus = true
      this.revertReservation(submission.no)
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    cleanString(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    filterPendingReservation(){

    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY h:mmA")
    },
      canViewActionWithPermission(permission){
          return this.$can(permission,"general") === true
      }
  },
  computed:{
    ...mapGetters(["getReservationTask", "getReservationSubmissions", "getRegistrars", "getReservationAssignmentSuccess",
      "getReservationError", "getMoveTask", "getMoveTaskError", "getReservationAssignmentError",
      "getReservationRevertSuccess", "getReservationFetchError", "getNetworkStatus",
      "getReservationPaymentRemoved", "getReservationPaymentRemovedError"])
      ,

  },
  watch:{
    getReservationTask(){
      this.loading = false
      this.reservation_prn = null;
      this.successToast("Success", "PRN Attachment Successful")
      this.$refs.addPRN.hide();
      this.fetch()
    },
    getReservationSubmissions(data){
      this.loading = false
      this.submissions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
      this.count = data.totalElements
    },
    getReservationAssignmentSuccess(){
      this.successToast("Success", "Assignment has been completed")
      this.$refs.assignSubmissions.hide();
      this.fetch()
    },
    getReservationRevertSuccess(){
      this.selectedId = null
      this.loadingRevertStatus = false
      this.successToast("Success", "Reservation status Reverted")
      this.fetch()
    },
    getNetworkStatus(message){
      this.loading = false
      this.hasError = true
      this.errorMessage = message
    },
    getReservationError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    },
    getReservationFetchError(data){
      this.loading = false
      this.hasError = true
      this.errorMessage = data.message
      this.errorToast("Error", JSON.stringify(data.message))
    },
    getRegistrars(){
      this.loading = false
    },
    getMoveTask(){
      this.loadingMoveTask = false
      this.successToast("Request Success", "Transaction retried")
      this.fetch();
    },
    getMoveTaskError(data){
      this.loadingMoveTask = false
      this.errorToast("Error", data.message)
    },
    getRegistrationError(data) {
      this.loading = false;
      this.errorToast("Error", JSON.stringify(data.message));
    },
    getReservationPaymentRemoved(){
      this.loadingRemovePayment = false
      this.successToast("Success", "Payment details removed and status reverted");
      this.fetch()
    },
    getReservationPaymentRemovedError(data){
      this.loadingRemovePayment = false
      this.errorToast("Error", data.message);
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.verified{
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified{
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>