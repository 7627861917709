<template>
  <b-row>
    <b-col>
      <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
        <thead>
        <tr class="bg-gray-pale">
          <td colspan="12" class="px-2 py-2">Report Request Details</td>
        </tr>
        <tr>
          <th class="px-2 py-1">Tracking Number</th>
          <th class="px-2 py-1">Status</th>
          <th class="px-2 py-1">Search Reference</th>
          <th class="px-2 py-1">Applicant</th>
          <th class="px-2 py-1">Submission Date</th>
        </tr>
        </thead>
        <tbody>
        <tr style="border: solid 1px #dedede;" >
          <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
          </td>
          <td class="px-2 py-1">
            <span class="text-capitalize">{{task.status.toLowerCase()}}</span>
          </td>
          <td class="px-2 py-1">
            <span class="text-gray text-capitalize">{{task.searchRef}}</span>
          </td>
          <td class="px-2 py-1">
            <span class="text-gray italic">
              {{task.accountHolder}}
            </span>
          </td>
          <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(task.dateOfSearch)}}
                  </span>
          </td>
        </tr>
        </tbody>
      </table>
      <table class="min-w-full leading-normal mt-2 text-gray" style="border: solid 1px #dedede;">
        <thead>
        <tr class="bg-gray-pale">
          <td colspan="12" class="px-2 py-2">Entity Details</td>
        </tr>
        <tr>
          <th class="px-2 py-1">BRN</th>
          <th class="px-2 py-1">Name</th>
          <th class="px-2 py-1">Entity Type</th>
          <th class="px-2 py-1">Entity Sub Type</th>
          <th class="px-2 py-1">Date of Registration</th>
        </tr>
        </thead>
        <tbody>
        <tr style="border: solid 1px #dedede;" >
          <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.searchValueOrBrn}}
                  </span>
          </td>
          <td class="px-2 py-1">
            <span class="text-capitalize">{{task.entityName}}</span>
          </td>
          <td class="px-2 py-1">
            <span class="text-gray text-capitalize">{{cleanEntityType(task.entityType.toLowerCase())}}</span>
          </td>
          <td class="px-2 py-1">
            <span class="text-gray text-capitalize">{{cleanEntityType(task.entitySubType)}}</span>
          </td>
          <td class="px-2 py-1">
                  <span class="text-gray">
                    {{dateFormat(task.dateOfRegistration)}}
                  </span>
          </td>
        </tr>
        </tbody>
      </table>
      <PRNViewer :tracking-number="task.trackingNumber" class="mt-2"/>
    </b-col>
  </b-row>
</template>

<script>
import PRNViewer from "@/views/home/commons/PRNViewer.vue";

export default {
  name: "SearchDetails",
  components: { PRNViewer },
  props:{
    task:Object
  },
  mounted() {
    console.log(this.task)
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>