<template>
  <div class="bg-white rounded-sm mx-3 my-3">
    <b-row>
      <b-col cols="12" class="mt-3">
        <b-row class="mx-1">
          <b-col cols="11">
            <span class="title mx-1">Charge</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mx-3 mt-2">
      <b-col cols="12" class="mx-auto bg-header_grey py-3 rounded">
        <span class="cursor-pointer text-blue hover:text-blue-dark hover:underline" @click="back">Back</span> <span class="mx-3 text-gray">Charge / {{brn}}</span>
      </b-col>
    </b-row>
    <b-row class="mx-3 my-2">
      <b-col>
        <b-row>
          <b-col cols="12" class="mt-2">
            <span class="font-bold text-gray h4">{{data.name}}</span><br/>
            <div class="flex my-1">
              <b-badge class="bg-blue text-capitalize">
                {{cleanString(data.typeOfCharge.toLowerCase())}}
              </b-badge>
            </div>
            <span class="text-gray text-xs font-bold" >Registration Date: {{dateFormat(data.dateOfCharge)}}</span><br/>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-alert :variant="statusVariants(data.satisfactionStatus)" show>
              <p>Satisfaction Status: <b>{{cleanString(data.satisfactionStatus)}}</b></p>
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col cols="4">
            <b-form-group label="Type of Charge" class="mb-1">
              <b-form-input :value="data.typeOfCharge" disabled></b-form-input>
            </b-form-group>
            <b-row>
              <b-col class="mx-1 my-2">
                <p class="my-2">
                  <span>Is Property Foreign</span> <i v-if="data.isPropertyForeign" class="fa fa-check-circle text-green"></i>
                  <i v-else class="fa fa-times-circle text-red"></i>
                </p>
                <p class="my-2">
                  <span>Is Mortgage</span> <i v-if="data.mortgage" class="fa fa-check-circle text-green"></i>
                  <i v-else class="fa fa-times-circle text-red"></i>
                </p>
              </b-col>
            </b-row>
            <b-form-group label="Description Of Instrument Of Evidence" class="my-1">
              <b-form-textarea :value="data.descriptionOfInstrumentOfEvidence" disabled rows="3"></b-form-textarea>
            </b-form-group>
            <b-form-group label="Description Of Charge" class="my-1">
              <b-form-textarea :value="data.shortParticularsOrDescriptionOfCharge" disabled rows="3"></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Amount Secured" class="my-1">
              <b-form-input :value="data.amountSecured" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Amount In Words" class="my-1">
              <b-form-textarea :value="data.amountSecuredInWords" disabled></b-form-textarea>
            </b-form-group>
            <b-form-group label="Commission" class="my-1">
              <b-form-input :value="data.commission" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Allowance" class="my-1">
              <b-form-input :value="data.commission" disabled></b-form-input>
            </b-form-group>
            <b-form-group label="Discount" class="my-1">
              <b-form-input :value="data.commission" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">

            <b-form-group label="Date of Charge" class="my-1">
              <b-form-input :value="dateFormat(data.dateOfCharge)" disabled></b-form-input>
            </b-form-group>

            <b-form-group label="Date Of Instrument Of Evidence" class="my-1">
              <b-form-input :value="dateFormat(data.dateOfInstrumentOfEvidence)" disabled></b-form-input>
            </b-form-group>

            <b-form-group  v-if="data.dateOfExtensionDeadline" label="Date Of Extension Deadline" class="my-1">
              <b-form-input :value="dateFormat(data.dateOfExtensionDeadline)" disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="12">
            <b-row>
              <b-col>
                <p class="h4">Entitled Entities</p>
                <b-row>
                  <b-col>
                    <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr>
                        <th class="px-2 py-1">#</th>
                        <th class="px-2 py-1">Name</th>
                        <th class="px-2 py-1">Registration No.</th>
                        <th class="px-2 py-1">Country of Origin</th>
                        <th class="px-2 py-1">Registration Type</th>
                        <th class="px-2 py-1">Description</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;" v-for="(entity, idx) in data.entitledEntities" :key="idx">
                        <td class="px-2 py-1">
                          {{idx + 1}}
                        </td>
                        <td class="px-2 py-1 text-capitalize font-bold">
                          {{entity.name}}
                        </td>
                        <td class="px-2 py-1 font-bold text-blue">
                          {{entity.brn}}
                        </td>
                        <td class="px-2 py-1">
                          {{entity.countryOfOrigin}}
                        </td>
                        <td class="px-2 py-1 text-capitalize">
                          {{cleanString(entity.registrationType)}}
                        </td>
                        <td class="px-2 py-1 text-capitalize">
                          <b-form-textarea class="form-text text-xs" disabled :value="entity.description"></b-form-textarea>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
                <b-row v-if="!data.entitledEntities">
                  <b-col class="bg-header_grey py-1">
                    No entities Found
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group v-if="data.finePrn" label="Fine PRN" class="my-1">
                  <b-form-input :value="data.finePrn" disabled></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "ChargeWrapper",
  data(){
    return{
      data:null,
      brn:null
    }
  },
  mounted() {
    this.data = JSON.parse(localStorage.getItem("charge-data"))
    this.brn = this.data.brn
  },
  methods:{
    back() {
      router.push({name:"charges-registry"})
    },
    cleanString(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    statusVariants(status){
      if(status === "PENDING"){
        return "primary"
      }

      if(status === "IN_REGISTER"){
        return "success"
      }

      if(status === "OFF_REGISTER"){
        return "danger"
      }
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>