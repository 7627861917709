<template>
  <div>
    <b-row class="my-1">
      <b-col cols="12" class="my-1">
        <span v-for="(file, idx) in getBNDataUpdateTask.attachments" :key="idx">
          <label class="mr-2 rounded-sm px-2 py-1 cursor-pointer bg-blue text-white text-capitalize hover:underline" @click="openFile(file)">
            <i class="fa fa-file"></i>
            {{file.fileDescription}}
          </label>
        </span>
      </b-col>
      <b-col cols="12">
        <BusinessNameDataUpdateAttachments ref="attachmentScreen"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import server from "@/app/env/server.json";
import BusinessNameDataUpdateAttachments
  from "@/views/home/services/data-update/task/business/BusinessNameDataUpdateAttachments.vue";

export default {
  name: "BusinessNameDataUpdateFormFiles",
  components: { BusinessNameDataUpdateAttachments },
  data(){
    return{
      attachmentUrl:null
    }
  },
  mounted() {
    this.openFile(this.getBNDataUpdateTask.attachments[0])
  },
  methods:{
    ...mapActions(["fetchAddressByVillageId"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    openFile(data){
      this.attachmentUrl = server.IP+"/data-update/business/attachment/preview?file-name="+data.fileName
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },
  computed:{
    ...mapGetters(["getBNDataUpdateTask", "getAddress"])
  },
  watch:{
    getBNDataUpdateTask(data){
      let file = data.attachments[0]
      this.openFile(file)
    },
    attachmentUrl:{
      handler(url){
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    },
  }
}
</script>

<style scoped>

th, td{
  font-size: 12px;
}

</style>