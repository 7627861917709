<template>
  <b-row>
    <b-col>
      <b-overlay :show="loading" rounded="sm">
        <b-row>
          <b-col>
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Report Request Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Search Reference</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{task.searchReportRequestWrapper.searchRef}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(task.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="min-w-full leading-normal mt-2 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Entity Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">BRN</th>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Entity Type</th>
                <th class="px-2 py-1">Entity Sub Type</th>
                <th class="px-2 py-1">Applicant</th>
                <th class="px-2 py-1">Date of Registration</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.searchReportRequestWrapper.searchValueOrBrn}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-capitalize">{{task.searchReportRequestWrapper.entityName}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{task.searchReportRequestWrapper.entityType}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{task.searchReportRequestWrapper.entitySubType}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{task.searchReportRequestWrapper.accountHolder}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{dateFormat(task.searchReportRequestWrapper.dateOfRegistration)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SearchReportFileDetails",
  props:{
   trackingNumber:String
  },
  data(){
    return{
      loading:true,
      task:null,
    }
  },
  mounted() {
    this.fetchSearchReportTaskDetails(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchSearchReportTaskDetails"])
  },
  computed:{
    ...mapGetters(["getSearchReportDetails", "getSearchReportDetailsError"])
  },
  watch:{
    getSearchReportDetails(data){
      this.loading = false
      this.task = data
    },
    getSearchReportDetailsError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>