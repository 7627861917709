import {api} from "@/app/services/api.service";

const state = {
    error:null,
    success:null,
};

const getters = {
    getEscalationSuccess: (state) => state.success,
    getEscalationError: (state) => state.error,
};

const actions = {
    escalateBNRTask({commit}, data){
        api().post("/business-registration/escalation", data).
        then(response => {
            commit('setEscalateSuccess', response.data);
        }).catch(error => {
            commit("setEscalateError", error.response)
        });
    },
    escalateAmendmentTask({commit}, data){
        api().post("/business-registration/filing/escalation", data).
        then(response => {
            commit('setEscalateSuccess', response.data);
        }).catch(error => {
            commit("setEscalateError", error.response)
        });
    },
    reEscalateAmendmentTask({commit}, data){
        api().post("/business-registration/filing/escalation/re", data).
        then(response => {
            commit('setEscalateSuccess', response.data);
        }).catch(error => {
            commit("setEscalateError", error.response)
        });
    },
    reEscalateBNRTask({commit}, data){
        api().post("/business-registration/escalation/re", data).
        then(response => {
            commit('setEscalateSuccess', response.data);
        }).catch(error => {
            commit("setEscalateError", error.response)
        });
    },
    escalateCITask({commit}, data){
        api().post("/incorporation/companies/queue/escalate", data).
        then(response => {
            commit('setEscalateSuccess', response.data);
        }).catch(error => {
            commit("setEscalateError", error.response)
        });
    },
    escalateCFTask({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/escalate", data).
        then(response => {
            commit('setEscalateSuccess', response.data);
        }).catch(error => {
            commit("setEscalateError", error.response)
        });
    },
    escalateBSITask({commit}, data){
        api().post("/building-societies/societies/queue/escalate", data).
        then(response => {
            commit('setEscalateSuccess', response.data);
        }).catch(error => {
            commit("setEscalateError", error.response)
        });
    },
};

const mutations = {
    setEscalateSuccess: (state, response) =>{
        state.success = response;
    },
    setEscalateError: (state, response) =>{
        state.error = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
