<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12" class="topBar">
          <ProfileTopBar/>
        </b-col>
      </b-row>
      <b-row class="py-3">
        <b-col cols="12" class="overflow-hidden mt-5 mb-3">
          <div class="bg-white rounded-sm ">
            <b-row>
              <b-col cols="6" class="mx-auto">
                <AccountInfo class="py-4"/>
              </b-col>
            </b-row>
            <b-row class="flex">
              <b-col></b-col>
              <b-col cols="5" class="border-r border-grey">
                <AccountManagement class="py-4"/>
              </b-col>
              <b-col cols="5">
                <AccountSecurity class="py-4"/>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>

</template>

<script>
import AccountInfo from "@/views/home/account/AccountInfo";
import AccountManagement from "@/views/home/account/AccountManagement";
import AccountSecurity from "@/views/home/account/AccountSecurity";
import ProfileTopBar from "@/views/home/ProfileTopBar";
export default {
  name: "AccountProfile",
  components: {ProfileTopBar, AccountSecurity, AccountManagement, AccountInfo},
  data(){
    return{

    }
  }
}
</script>

<style scoped>

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  z-index:40
}

</style>