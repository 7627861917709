<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Change of Address
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="6">
          <Form18Resolution badge="Current Address" :data="getCompanyAddress.data"></Form18Resolution>
        </b-col>
        <b-col cols="6">
          <Form18Resolution badge="New Address" :data="data"></Form18Resolution>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Form18Resolution from "@/views/home/services/continuous-filing/task/forms/Form18Resolution";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ChangeOfAddress",
  components: { Form18Resolution },
  props:{
    data:Object,
    brn:String
  },
  mounted() {
    this.fetchCompanyAddress(this.brn)
  },
  methods:{
    ...mapActions(["fetchCompanyAddress"])
  },
  computed:{
    ...mapGetters(["getCompanyAddress"])
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>