<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Surrender of Shares
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-row v-for="(surrender, idx) in data.surrenders" :key="idx" class="border-b border-gray py-3">
            <b-col>
              <b-row>
                <b-col>
                  <p>Shareholder: <b class="font-bold text-uppercase" v-if="surrender.shareholding.shareholder">{{surrender.shareholding.shareholder.name}}</b></p>
                </b-col>
                <b-col>
                  <p>Shares Surrendered: <b-badge class="bg-red"> <i class="fa fa-times mr-1"></i>{{surrender.totalNumberOfShares.toLocaleString()}}</b-badge></p>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;" >
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Shares Surrendered Breakdown</td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Shares Paid</th>
                        <th class="px-2 py-1 text-center">Shares Not Paid</th>
                        <th class="px-2 py-1 text-center">Initial Shares Paid</th>
                        <th class="px-2 py-1 text-center">Initial Shares Not Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in surrender.sharesSurrendered" :key="idx">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.initialNumberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.initialNumberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      </tbody>

                    </table>
                  </b-col>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;" >
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Shares After Surrender <b-badge class="bg-green font-monospace">final</b-badge></td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Shares Paid</th>
                        <th class="px-2 py-1 text-center">Shares Not Paid</th>
                        <th class="px-2 py-1 text-center">Initial Shares Paid</th>
                        <th class="px-2 py-1 text-center">Initial Shares Not Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in surrender.finalSharesAfterSurrender " :key="idx">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.initialNumberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span v-if="share.subClasses.length === 0">{{share.initialNumberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.numberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.initialNumberOfPaidShares.toLocaleString()}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <span>{{subClass.initialNumberOfUnPaidShares.toLocaleString()}}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SurrenderOfShares",
  props:{
    data:Object
  },
};
</script>

<style scoped>

</style>