<template>
  <b-row>
    <b-col>
      <b-overlay :show="loading" rounded="sm"></b-overlay>
      <b-row>
        <b-col>
          <b-row class="mx-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Amendment Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Amendment Type</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.data.trackingNumber}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue text-capitalize">{{application.data.status.toLowerCase()}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray text-capitalize" v-if="application.data.filingType.includes(',')">
                    Multiple Changes
                  </span>
                    <span class="text-gray text-capitalize" v-else>
                    {{cleanEntityType(application.data.filingType.toLowerCase())}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.data.createdAt)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mx-1">
              <b-col cols="12" v-if="application.data.filingType === 'NOTICE_OF_CHANGE_IN_BENEFICIAL_OWNERSHIP' " >
                  <label class="text-blue border-1 rounded-b-sm w-full my-2 text-center ">
                      <span class="text-center">Payment Not Applicable</span>
                  </label>
              </b-col>
            <b-col v-else cols="12" >
              <PRNViewer v-if="application.data.status.toUpperCase() !== 'DRAFT'"
                         :tracking-number="application.data.trackingNumber" :prn="application.data.prn"/>
            </b-col>
          </b-row>
          <b-row class="mx-1 mb-5">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <div class="flex h-full" style="cursor: pointer;">
                    <div :class="[(currentRoute === 'info') ? 'menu-item-active' : 'menu-item']"
                         @click="select('info')">
                      <div class="flex mt-6">
                        <div style="font-size: 12px">Filing Information</div>
                      </div>
                    </div>
                    <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']"
                         @click="select('attachments')">
                      <div class="flex mt-6">
                        <div style="font-size: 12px">Attachments</div>
                      </div>
                    </div>
                    <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']"
                         @click="select('messages')">
                      <div class="flex mt-6">
                        <div style="font-size: 12px">Messages</div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <AmendmentDetails :amendment="application.data" v-if="currentRoute === 'info'"/>
                  <AmendmentFilesDetails :application="application.data" v-if="currentRoute === 'attachments'"/>
                  <AmendmentMessagesDetails :application="application" v-if="currentRoute === 'messages'"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer";
import moment from "moment/moment";
import AmendmentDetails from "@/views/home/services/amendments/task/AmendmentDetails";
import AmendmentFilesDetails from "@/views/home/services/amendments/submissions/components/AmendmentFilesDetails";
import AmendmentMessagesDetails from "@/views/home/services/amendments/submissions/components/AmendmentMessagesDetails";

export default {
  name: "AmendmentFilingDetails",
  components: { AmendmentMessagesDetails, AmendmentFilesDetails, AmendmentDetails, PRNViewer },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      loading: true,
      application:null,
      currentRoute: "info",
      menu:[
        {
          title:"Document Information",
          route:"info",
        },
        {
          title:"Attachment",
          route:"attachments",
        },
      ],
    }
  },
  mounted() {
    this.loading = true
    this.fetchAmendmentDetails(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchAmendmentDetails"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getAmendmentDetails", "getAmendmentDetailsError"])
  },
  watch:{
    getAmendmentDetails(data){
      this.loading = false
      this.application = data
    },
    getAmendmentDetailsError(){
      this.loading = false
    }
  }
};
</script>

<style scoped>
.title{
  font-size: 22px;
}

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}
</style>