<template>
<span>
<b-row>

  <b-col cols="6">
    <label class="text-gray mb-1" style="font-size: 14px;">Country</label>
          <b-form-group>
          <b-form-select
            id="country"
            @change="selectCountry(countryId)"
            class="form-select  noFocus text-gray mb-2"
            v-model="countryId"
            :disabled="true"
            :options="countryList">
          </b-form-select>
          </b-form-group>
    </b-col>


    <b-col cols="6">
      <label class="text-gray mb-1" style="font-size: 14px;">Region</label>
       <b-form-group>
          <b-form-select
            id="region"
            @change="selectRegion(regionId)"
            class="form-select  noFocus text-gray mb-2"
            v-model="regionId"
            :disabled="true"
            :options="regionList">
          </b-form-select>
          </b-form-group>
    </b-col>

   <b-col cols="6">
     <label class="text-gray mb-1" style="font-size: 14px;">District</label>
     <b-form-group>
       <b-form-select
         id="district"
         @change="selectDistrict(districtId)"
         class="form-select  noFocus text-gray mb-2"
         v-model="districtId"
         :disabled="true"
         :options="districtList">
       </b-form-select>
     </b-form-group>
  </b-col>


  <b-col cols="6">
    <label class="text-gray mb-1" style="font-size: 14px;">Sub County</label>
     <b-form-group>
       <b-form-select
         id="subCounty"
         @change="selectSubCounty(subCountyId)"
         class="form-select  noFocus text-gray mb-2"
         v-model="subCountyId"
         :disabled="true"
         :options="subCountyList">
       </b-form-select>
     </b-form-group>
  </b-col>

  <b-col cols="6">
    <label class="text-gray mb-1" style="font-size: 14px;">Parish</label>
      <b-form-group>
        <b-form-select
          id="parish"
          @change="selectParishData(parishId)"
          class="form-select  noFocus text-gray mb-2"
          v-model="parishId"
          :disabled="true"
          :options="parishList">
        </b-form-select>
      </b-form-group>
  </b-col>


  <b-col cols="6">
     <label class="text-gray mb-1" style="font-size: 14px;">Village</label>
      <b-form-group>
        <b-form-select
          id="village"
          @change="selectVillage(villageId)"
          class="form-select  noFocus text-gray"
          v-model="villageId"
          :disabled="true"
          :options="villageList">
        </b-form-select>
      </b-form-group>
  </b-col>
</b-row>



</span>
</template>

<script>
import {mapActions} from "vuex";
import {api} from "@/app/services/api.service";

export default {
  name: "AdminUnits",
  props:{
    index:Number,
    villageDataInfo:{}
  },
  data(){
    return{
      countryList:[],
      countryId:"",
      regionList:[],
      regionId:"",
      districtId:"",
      districtList:[],
      subCountyId:"",
      subCountyList:[],
      parishId:"",
      parishList:[],
      villageId:"",
      villageList:[],
    }
  },
  mounted() {
    this.fetchCountriesAsync();
    this.checkAdminUnitInfo()
  },
  methods:{
    ...mapActions(
      [
        "fetchRegion",
        "fetchCountries",
        "fetchDistricts",
        "fetchCounty",
        "fetchSubCounty",
        "fetchParish",
        "fetchVillage"
      ]
    ),
    async fetchCountriesAsync() {
      await api().get("configuration/address/country")
        .then((response) => {
          let arr = [{value: "", text: "--Select Country--"}]
          this.countryList = arr.concat(this.filterUnitData(response.data))

        })
        .catch((error) => {
          this.$store.commit("setDistrictsResultsError", error.response);
        });
    },
    async fetchRegionAsync(countryId) {
      await api().get("configuration/address/region?countryId=" + countryId)
        .then((response) => {
          let arr = [{value: "", text: "--Select Region--"}]
          this.regionList = arr.concat(this.filterUnitData(response.data))

        })
        .catch((error) => {
          this.$store.commit("setDistrictsResultsError", error.response);
        });
    },
    async fetchDistrictAsync(regionId) {
      await api().get("configuration/address/district?regionId=" + regionId)
        .then((response) => {
          let arr = [{value:"", text: "--Select District--"}]
          this.districtList = arr.concat(this.filterUnitData(response.data))
        })
        .catch((error) => {
          this.$store.commit("setDistrictsResultsError", error.response);
        });
    },
    async fetchSubCountyAsync(districtId) {
      await api().get("configuration/address/sub-county?districtId=" + districtId)
        .then((response) => {
          let arr = [{value:"", text: "--Select Sub County--"}]
          this.subCountyList = arr.concat(this.filterUnitData(response.data))
        })
        .catch((error) => {
          this.$store.commit("setDistrictsResultsError", error.response);
        });
    },
    async fetchVillageAsync(parishId) {
      await api().get("configuration/address/village?parishId=" + parishId)
        .then((response) => {
          let arr = [{value:"", text: "--Select Village--"}]
          this.villageList = arr.concat(this.filterUnitData(response.data))
        })
        .catch((error) => {
          this.$store.commit("setDistrictsResultsError", error.response);
        });
    },
    async fetchParishAsync(subCountyId) {
      await api().get("configuration/address/parish?subCountyId=" + subCountyId)
        .then((response) => {
          let arr = [{value: "", text: "--Select Parish--"}]
          this.parishList = arr.concat(this.filterUnitData(response.data))
        })
        .catch((error) => {
          this.$store.commit("setDistrictsResultsError", error.response);
        });
    },
    async selectCountry(countryId){
      if(countryId) {
        await this.fetchRegionAsync(countryId);
      }
    },
    async selectRegion(regionId){
      await  this.fetchDistrictAsync(regionId)
    },
    async selectDistrict(districtId){
      await this.fetchSubCountyAsync(districtId)
    },
    async selectParishData(parishId){
      await this.fetchVillageAsync(parishId)
    },
    selectVillage(village){
      this.$emit("selectedVillage",village);
    },
    async selectSubCounty(subCountyId){
      await this.fetchParishAsync(subCountyId)
    },
    async checkAdminUnitInfo(){
      if(this.villageDataInfo) {

        let data = this.villageDataInfo

        this.countryId = data.country.id ? data.country.id : ""
        if(data.country.id) {
          await this.selectCountry(data.country.id)
        }

        this.regionId = data.region.id ? data.region.id : ""

        if(data.region.id) {
          await this.selectRegion(data.region.id)
        }

        this.districtId = data.district.id ? data.district.id : ""
        if(data.district.id) {
          await this.selectDistrict(data.district.id)
        }

        let subCountyNo = ""
        if (data.subcounty) {
          subCountyNo = data.subcounty.id ? data.subcounty.id : "";
        } else {
          subCountyNo = data.subCounty.id ? data.subCounty.id : "";
        }
        this.subCountyId = subCountyNo
        if(subCountyNo) {
          await this.selectSubCounty(subCountyNo)
        }

        this.parishId = data.parish.id ? data.parish.id : ""

        if(data.parish.id) {
          await this.selectParishData(data.parish.id)
        }

        this.villageId = data.village.id ? data.village.id : ""
      }
    }

  },
  watch:{
    async villageDataInfo(){
      await this.checkAdminUnitInfo()
    }
  }


}
</script>

<style scoped>

</style>