<template>
  <div>
    <b-row>
      <b-modal id="modal-a-assign" hide-footer ref="assignSubmissions" title="Assign Registration">
        <Assignment :id="escalationId" type="bnr" permission="can_access_bname_reg_tasks"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-a-assign" hide-footer ref="replySubmissions" title="Reply to Escalation">
        <AssignmentReply :id="escalationId" :user-id="userId" type="bnr"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-query" hide-footer ref="query-registration" title="Query Registration">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.description"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-query-hover" variant="primary" @click="onQuery">
                  Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Registration">
        <Escalation :task-id="escalationId" type="bnr-re" permission="can_view_bname_reg_escalations"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row class="mx-3 mb-3s">
      <b-col cols="12">
        <b-row class=" py-2 my-2 shadow-md rounded-sm" style="background: #b0ea75;">
          <b-col cols="10">
            <div class="flex">
              <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
              <span class="text-capitalize font-bold underline hover:text-green">{{source.firstName}} {{source.lastName}}</span>
            </div>
            <p class="text-gray mt-2">
              {{ message }}
            </p>
            <span class="italic text-gray" style="font-size: 10px;">{{dateTimeFormat(application.createdAt)}}</span>
          </b-col>
          <b-col cols="2">
            <button class="bg-green text-white px-1 py-1 rounded-sm m-1 text-xs float-end" v-if="getRegistrationTaskMessages.length > 0" @click="toggleThread()">
              <span v-if="isShowingThread">Close thread...</span>
              <span v-else>See more...</span>
            </button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row>
          <b-col>
            <span style="font-size: 30px; font-weight: bold" v-if="application.reservation">
              {{application.reservation.name}}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Registration Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.no}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue">{{application.status}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(application.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="application.subType.includes('limited')">
          <b-col cols="12">
            <PRNViewer :tracking-number="application.no" :prn="application.prn"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-gray"
                   style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Summary</td>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Date of Commencement</th>
                <td class="px-2 py-1">
                          <span class="text-gray">
                            {{dateFormat(application.dateOfCommencement)}}
                          </span>
                </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Reservation No.</th>
                <td class="px-2 py-1">
                          <span class="text-blue italic" v-if="application.reservation">
                            {{application.reservation.no}}
                          </span>
                </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Nature of Business</th>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.natureOfBusiness.description}}
                  </span>

                </td>
                <td class="px-2 py-1">
                  <ul class="text-blue">
                    <li v-for="(section, idx) in application.natureOfBusiness.breakdown"  :key="idx">
                      <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                    </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
              <button class="btn-approve" @click="replyPrompt">
                <i class="fa fa-reply text-white mr-1"></i> Reply
              </button>
              <button class="btn-approve" @click="assignPrompt">
                <i class="fa fa-hand-pointer text-white mr-1"></i> Assign
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'application-details') ? 'menu-item-active' : 'menu-item']" @click="select('application-details')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Details</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Attachments</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Messages</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <BusinessNameFormDetailsEscalated :application="application" v-if="currentRoute === 'application-details'"/>
            <BusinessNameFormFilesEscalated :application="application" v-if="currentRoute === 'attachments'"/>
            <BusinessNameMessagesEscalated v-if="currentRoute === 'messages'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment"
import Escalation from "@/views/home/commons/Escalation";
import BusinessNameFormDetailsEscalated
  from "@/views/home/services/registration/escalations/tabs/BusinessNameFormDetailsEscalated";
import {mapActions, mapGetters} from "vuex";
import AssignmentReply from "@/views/home/commons/AssignmentReply";
import Assignment from "@/views/home/commons/Assignment";
import BusinessNameFormFilesEscalated
  from "@/views/home/services/registration/escalations/tabs/BusinessNameFormFilesEscalated";
import BusinessNameMessagesEscalated
  from "@/views/home/services/registration/escalations/tabs/BusinessNameMessagesEscalated";
import PRNViewer from "@/views/home/commons/PRNViewer";

export default {
  name: "BusinessNameEscalationFile",
  components: {
    PRNViewer,
    BusinessNameMessagesEscalated,
    BusinessNameFormFilesEscalated, Assignment, AssignmentReply, BusinessNameFormDetailsEscalated, Escalation},
  props:{
    application:Object,
    message:String,
    escalationId:String,
    source:Object
  },
  data(){
    return{
      hasComments: false,
      currentRoute: "application-details",
      loading:false,
      margin:"2px",
      taskId:null,
      form:{
        description:"",
        businessId:null,
        status:null
      },
      menu:[
        {
          title:"Application Details",
          route:"application-details",
        },
        {
          title:"Attachments",
          route:"attachments",
        },
      ],
      action:null,
      userId:null,
      isShowingThread:false
    }
  },
  mounted() {
    this.fetchRegistrationMessages(this.application.businessId)
    this.form.businessId = this.application.businessId
  },
  methods:{
    ...mapActions(["fetchRegistrationMessages", "updateRegistrationStatus"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onApprove(){
      this.form.status = "Approved"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.form.status = "Queried"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    toggleThread(){
      this.isShowingThread = !this.isShowingThread
    },
    replyPrompt(){
      this.action = "reply"
      this.$refs.confirm.show();
    },
    assignPrompt(){
      this.action = "assign"
      this.$refs.confirm.show();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateRegistrationStatus(this.form)
      }
      if(this.action === "reply"){
        this.taskId = this.application.businessId
        this.userId = this.source.id
        this.$refs.replySubmissions.show();
      }

      if(this.action === "assign"){
        this.taskId = this.application.businessId
        this.$refs.assignSubmissions.show();
      }

      if(this.action === "escalate"){
        this.taskId = this.application.businessId
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    select(route){
      this.currentRoute = route
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    getDateTime(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    }
  },
  computed:{
    ...mapGetters(["getRegistrationTaskMessages", "getReservationEscalationSuccess", "getEscalationSuccess", "getEscalationError", "getCompanyUpdateStatusSuccess", "getCompanyUpdateStatusSuccessError"])
  },
  watch:{
    getUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      this.$refs["query-registration"].hide();
      window.location.reload()
    },
    getUpdateStatusSuccessError(data){
      this.loading = false
      this.errorToast("Error!", data.message)
    },
    getReservationEscalationSuccess(){
      this.$refs.replySubmissions.hide();
      this.$refs.assignSubmissions.hide();
      this.$refs.escalateSubmissions.hide();
      window.location.reload()
    },
    getEscalationSuccess(){
      this.$refs.replySubmissions.hide();
      this.$refs.assignSubmissions.hide();
      this.$refs.escalateSubmissions.hide();
      window.location.reload()
    },
    getEscalationError(){
      this.$refs.replySubmissions.hide();
      this.$refs.assignSubmissions.hide();
      this.$refs.escalateSubmissions.hide();
      //window.location.reload()
    }
  }

}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";
.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}


</style>