<template>
  <b-row>
    <b-col>
      <b-overlay rounded="sm" :show="loading">
        <b-row>
          <b-col>
            <b-row class="mx-1">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2">Reservation Details</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Tracking Number</th>
                    <th class="px-2 py-1">Status</th>
                    <th class="px-2 py-1">Sub Entity Type</th>
                    <th class="px-2 py-1">Submission Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="application !== null" style="border: solid 1px #dedede;" >
                    <td class="px-2 py-1">
                      <span class="text-gray">{{application.no}}</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-blue">{{application.status.replaceAll("_", " ")}}</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-gray text-capitalize">{{cleanEntityType(application.subType)}}</span>
                    </td>
                    <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row v-if="application !== null" class="mx-1">
              <b-col cols="12">
                <PRNViewer v-if="application.status !== 'DRAFT'" :tracking-number="application.no" :prn="application.prn"/>
              </b-col>
            </b-row>
            <b-row class="mx-1">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale" style="border: solid 1px #dedede;">
                    <td colspan="12" class="px-2 py-2">Applicant Details</td>
                  </tr>
                  <tr style="border: solid 1px #dedede;">
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1">Email</th>
                    <th class="px-2 py-1">Phone</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="application !== null" class="text-gray" style="border: solid 1px #dedede;" >
                    <td class="px-2 py-1">
                      <span class="text-capitalize">{{application.user.firstName}} {{application.user.lastName}}</span>
                    </td>
                    <td class="px-2 py-1">
                      <span>{{application.user.email}}</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-capitalize">{{application.user.phoneNumber}}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="mx-1 mt-2">
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2">Name Options</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Priority</th>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1 text-center">Analysis</th>
                    <th class="px-2 py-1 text-center">Flags</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" v-for="(option, idx) in getAnalysisReport" :key="idx">
                    <td class="px-2 py-1">
                      <span class="text-blue">Choice {{idx + 1}}</span>
                    </td>
                    <td class="px-2 py-1">
                          <span class="font-bold" v-if="option.flags.length > 0">
                            <NameHighlight :name="option.name" :flags="option.flags" :idx="idx"/>
                          </span>
                      <span v-else class="font-bold">{{option.name}}</span>
                    </td>
                    <td class="px-2 py-2 text-center">
                      <span class="text-blue" v-if="option != null">
                        {{option.nameScore.text}}%
                      </span>
                      <span class="text-gray" v-else>--</span>
                    </td>
                    <td class="px-2 py-1 text-center">
                      <span class="text-blue" v-if="option != null">
                        {{option.flags.length + option.rejectedNames.length}}
                      </span>
                      <span class="text-gray " v-else>--</span>
                    </td>
                  </tr>
                  <tr style="border: solid 1px #f59696;" v-if="analysisReportError != null">
                    <td class="px-2 py-1">
                      <span class="text-red">
                        {{this.analysisReportError.data.message}}
                      </span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-red font-bold" v-for="(name, idx) in JSON.parse(this.analysisReportError.config.data).names" :key="idx">
                        {{name}}
                      </span>
                    </td>
                    <td class="px-2 py-1 text-center">
                      <span class="text-red">0</span>
                    </td>
                    <td class="px-2 py-1 text-center">
                      <span class="text-red">0</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <ReservationPopUpDetails :application="application"/>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer";
import NameHighlight from "@/views/home/services/reservation/components/NameHighlight";
import ReservationPopUpDetails from "@/views/home/services/reservation/components/ReservationPopUpDetails.vue";

export default {
  name: "ReservationDetails",
  components: {ReservationPopUpDetails, NameHighlight, PRNViewer },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      loading:false,
      application:null,
      nameIdMap:new Map(),
      analysisReportError:null,
    }
  },
  mounted() {
    this.fetchReservationDetails(this.trackingNumber)
    this.emptyAnalysisReport()
  },
  methods:{
    ...mapActions(["fetchReservationDetails", "fetchAnalysisReport", "emptyAnalysisReport", ]),
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
  },
  computed:{
    ...mapGetters(["getReservationNumberResult", "getAnalysisReport"])
  },
  watch:{
    getReservationNumberResult(data){
      this.application = data
      this.emptyAnalysisReport()
      this.loading = false
      if(data){
        let size = data.nameOptions.length
        let names = []
        this.hasContent = true
        this.emptyAnalysisReport()
        let nameMap = new Map()
        data.nameOptions.forEach(option => {
          nameMap.set(option.nameIndex.toString(), option.name.trim())
          this.nameIdMap.set(option.name.trim(), option.id)
        })
        for (let i = 0; i < size; i++){
          let key = i + 1
          names[i] = nameMap.get(key.toString())
        }
        this.fetchAnalysisReport(names)
        if(size === 1){
          this.selectedId = data.nameOptions[0].id
        }
      }else{
        this.hasContent = false
        this.successToast("Fetching task", "There are no task yet")
      }
    },
    getAnalysisReport(){

    },
    getAnalysisReportError(data){
      this.analysisReportError = data
    },
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>