<template>
  <b-row>
    <b-col>
      <b-row v-if="task.subType === 'foreign-company'">
        <b-col>
          <b-row class="mt-4">
            <b-col>
              <b-row>
                <b-col>
                  <p class="font-bold">FOREIGN COMPANY DETAILS</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">Name</td>
                      <td class="px-2 py-1 text-capitalize">{{ task.companyName }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">Registration Number</td>
                      <td class="px-2 py-1 text-capitalize">{{ task.brn }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">Incorporation Date</td>
                      <td class="px-2 py-1 text-capitalize">{{ dateFormat(task.incorporationDate) }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">Currency</td>
                      <td class="px-2 py-1 text-capitalize">{{ task.currency }}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col>
              <b-row>
                <b-col>
                  <p class="font-bold">AUDITORS</p>
                </b-col>
              </b-row>
              <b-row v-for="(item, index) in task.metadata" :key="index">
                <b-col>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td class="px-2 py-1 font-bold text-gray text-uppercase" colspan="12">
                        <span>Information for {{ item.year }}</span>
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">company Name At Time Of Filing</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.companyNameAtTimeOfFiling }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">name Of Auditor</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.nameOfAuditor }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">address Of Auditor</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.addressOfAuditor }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">name Of Filler</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.nameOfFiller }}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-row>
            <b-col>
              <div class="flex h-full" style="cursor: pointer;">
                <div :class="[(currentRoute === 'address') ? 'menu-item-active' : 'menu-item']" @click="select('address')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Address</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'membership') ? 'menu-item-active' : 'menu-item']" @click="select('membership')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Membership</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'charges') ? 'menu-item-active' : 'menu-item']" @click="select('charges')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Summary of Share Capital & Debentures</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'directors') ? 'menu-item-active' : 'menu-item']" @click="select('directors')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Particulars of Directors & Secretary</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4" v-if="currentRoute === 'address'">
            <b-col>
              <b-row v-for="(item, index) in task.addresses" :key="index">
                <b-col>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td class="px-2 py-1 font-bold text-gray text-uppercase" colspan="12">
                        <span>Address for {{ item.year }}</span>
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">company Name At Time Of Filing</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.address.companyNameAtTimeOfFiling }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">name Of Auditor</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.address.nameOfAuditor }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">address Of Auditor</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.address.addressOfAuditor }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">annual General Meeting Date</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.address.annualGeneralMeetingDate }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">name Of Filler</td>
                      <td class="px-2 py-1 text-capitalize">{{ item.address.nameOfFiller }}</td>
                    </tr>

                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">registered Address</td>
                      <td class="px-2 py-1 text-capitalize">
                        <AnnualReturnAddressViewer v-if="item.address.registeredAddress" :address="item.address.registeredAddress"/>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">registered Physical Address</td>
                      <td class="px-2 py-1 text-capitalize">
                        <AnnualReturnPhysicalAddressViewer v-if="item.address.registeredPhysicalAddress" :address="item.address.registeredPhysicalAddress"/>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">registered Address Editable</td>
                      <td class="px-2 py-1 font-monospace">{{ item.address.registeredAddressEditable }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">address Of Members Registry</td>
                      <td class="px-2 py-1 text-capitalize">
                        <AnnualReturnAddressViewer v-if="item.address.addressOfMembersRegistry" :address="item.address.addressOfMembersRegistry"/>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">member Registry Physical Address</td>
                      <td class="px-2 py-1 text-capitalize">
                        <AnnualReturnPhysicalAddressViewer v-if="item.address.memberRegistryPhysicalAddress" :address="item.address.memberRegistryPhysicalAddress"/>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">debtor Registry Physical Address</td>
                      <td class="px-2 py-1 text-capitalize">
                        <AnnualReturnPhysicalAddressViewer v-if="item.address.debtorRegistryPhysicalAddress" :address="item.address.debtorRegistryPhysicalAddress"/>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">address Of Debtor Registry</td>
                      <td class="px-2 py-1 text-capitalize">
                        <AnnualReturnAddressViewer v-if="item.address.addressOfDebtorRegistry" :address="item.address.addressOfDebtorRegistry"/>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4" v-if="currentRoute === 'membership'">
            <b-col>
              <b-row v-for="(shareholding, index) in task.shareholding" :key="index">
                <b-col>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td class="px-2 py-1 font-bold text-gray text-uppercase" colspan="12">
                        <span>Share holding for {{ shareholding.year }}</span>
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;" v-for="(person, idx) in shareholding.shareholders" :key="idx">
                    <thead>
                    <tr>
                      <td class="px-2 py-1 font-bold text-blue text-uppercase" colspan="12">
                        <span>{{ person.name }}</span>
                      </td>
                    </tr>
                    </thead>
                    <thead>
                    <tr class="bg-white">
                      <th class="px-2 py-1 text-uppercase"></th>
                      <th class="px-2 py-1 text-center float-start text-uppercase">Share Class</th>
                      <th class="px-2 py-1 text-center text-uppercase">Number of Shares</th>
                    </tr>
                    </thead>
                    <tbody style="border: solid 1px #dedede;" v-for="(share, idx2) in person.shares" :key="idx2">
                    <tr>
                      <td class="px-2 py-1"></td>
                      <td class="px-2 py-1 text-uppercase font-bold">
                        <span>{{ share.className }}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                    <span v-if="share.subClasses == null">
                      {{ share.numberOfShares.toLocaleString() }}
                    </span>
                      </td>
                    </tr>
                    <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                      <td class="px-2 py-1"></td>
                      <td class="px-2 py-1 text-capitalize">
                        <span>Class {{ subClass.className }}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                    <span>
                      {{ subClass.numberOfShares.toLocaleString() }}
                    </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td class="px-2 py-1 font-bold text-gray text-uppercase" colspan="12">
                        <span>Share Transfers for {{ shareholding.year }}</span>
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;" v-for="(person, idx) in shareholding.shareholders" :key="idx">
                    <thead>
                    <tr>
                      <td class="px-2 py-1 font-bold text-blue text-uppercase" colspan="12">
                        <span>{{ person.name }}</span>
                      </td>
                    </tr>
                    </thead>
                    <thead v-if="person.transfers.length > 0">
                    <tr class="bg-white">
                      <th class="px-2 py-1 text-uppercase"></th>
                      <th class="px-2 py-1 text-center float-start text-uppercase">Date of transfer</th>
                      <th class="px-2 py-1 text-center text-uppercase">Shares Transferred</th>
                    </tr>
                    </thead>
                    <thead v-else>
                      <tr class="bg-white">
                        <th class="px-2 py-1 text-uppercase italic" colspan="12">None Transfered</th>
                      </tr>
                    </thead>
                    <tbody style="border: solid 1px #dedede;" v-for="(transfer, idx2) in person.transfers" :key="idx2">
                    <tr>
                      <td class="px-2 py-1"></td>
                      <td class="px-2 py-1 text-uppercase font-bold">
                        <span>{{ transfer.dateOfRegistrationOfTransfer }}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                    <span>
                      {{ transfer.totalNumberOfTransferredShares }}
                    </span>
                      </td>
                    </tr>
                    <tr v-for="(item, idx3) in transfer.breakdowns" :key="idx3">
                      <td colspan="12">
                        <table class="w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                          <thead>
                          <tr class="bg-gray-pale">
                            <th colspan="12">
                              Transfer Details {{ idx3 + 1 }}
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td class="px-2 py-1 font-bold text-capitalize">
                              <span>Class</span>
                            </td>
                            <td class="px-2 py-1 text-center">
                        <span>
                          {{ item.className }}
                        </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="px-2 py-1 font-bold text-capitalize">
                              <span>Shares</span>
                            </td>
                            <td class="px-2 py-1 text-center">
                        <span>
                          {{ item.numberOfSharesTransferred.toLocaleString() }}
                        </span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4" v-if="currentRoute === 'charges'">
            <b-col>
              <b-row v-for="(item, index) in task.sharesAndDebentures" :key="index">
                <b-col>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td class="px-2 py-1 font-bold text-gray text-uppercase" colspan="12">
                        <span>Shares & Debentures for {{ item.year }}</span>
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">total Indebtness</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.totalIndebtness">{{ item.sharesAndDebentures.totalIndebtness.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">nominal Share Capital</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.nominalShareCapital">{{ item.sharesAndDebentures.nominalShareCapital.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">number Of Shares</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.numberOfShares">{{ item.sharesAndDebentures.numberOfShares.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Taken</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.numberOfSharesTaken">{{ item.sharesAndDebentures.numberOfSharesTaken.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">value Of Shares Taken</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.valueOfSharesTaken">{{ item.sharesAndDebentures.valueOfSharesTaken.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Not Taken</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.numberOfSharesNotTaken">{{ item.sharesAndDebentures.numberOfSharesNotTaken.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">value Of Shares Not Taken</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.valueOfSharesNotTaken">{{ item.sharesAndDebentures.valueOfSharesNotTaken.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">discount Not Written Off</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.discountNotWrittenOff">{{ item.sharesAndDebentures.discountNotWrittenOff.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount On Calls Received And Forfeiture</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.amountOnCallsReceivedAndForfeiture">{{ item.sharesAndDebentures.amountOnCallsReceivedAndForfeiture.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount On Un Paid Calls</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.amountOnUnPaidCalls">{{ item.sharesAndDebentures.amountOnUnPaidCalls.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount Considered Paid Non Cash Fully</td>
                      <td class="px-2 py-1">
                        <span v-if="item.sharesAndDebentures.amountConsideredPaidNonCashFully">{{ item.sharesAndDebentures.amountConsideredPaidNonCashFully.toLocaleString() }}</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount Considered Paid Non Cash Partly</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.amountConsideredPaidNonCashPartly }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount As Commission On Debenture</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.amountAsCommissionOnDebenture }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount As Discount Due To Debenture</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.amountAsDiscountDueToDebenture }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">shares Forfeited</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.sharesForfeited }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount On Shares Forfeited</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.amountOnSharesForfeited }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount Warrant To Bearer Out Standing</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.amountWarrantToBearerOutStanding }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">share Warrant Issued To Bearer</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.shareWarrantIssuedToBearer }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount On Share Warrant Issued To Bearer</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.amountOnShareWarrantIssuedToBearer }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">share Warrant Surrendered</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.shareWarrantSurrendered }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">amount On Share Warrant Surrendered</td>
                      <td class="px-2 py-1">{{ item.sharesAndDebentures.amountOnShareWarrantSurrendered }}</td>
                    </tr>

                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 font-bold text-capitalize">Details Breakdown</td>
                      <td>
                        <table class="min-w-full leading-normal text-gray"
                               style="border: solid 1px #dedede;">
                          <tbody class="border-b-2 border-gray "
                                 v-for="(subItem, idx2) in item.sharesAndDebentures.breakdowns" :key="idx2">
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">Class Name</td>
                            <td class="px-2 py-1">{{ subItem.className }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">Number</td>
                            <td class="px-2 py-1">{{ subItem.number.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">Value</td>
                            <td class="px-2 py-1">{{ subItem.value.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">total Nominal Value</td>
                            <td class="px-2 py-1">{{ subItem.totalNominalValue.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Taken</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesTaken.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">value Of Shares Taken</td>
                            <td class="px-2 py-1">{{ subItem.valueOfSharesTaken.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Paid In Cash</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesPaidInCash.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Paid In Cash</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesPaidInCash.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Paid In Non Cash</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesPaidInNonCash.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">value Of Shares Paid In Non Cash</td>
                            <td class="px-2 py-1">{{ subItem.valueOfSharesPaidInNonCash.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Paid At Discount</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesPaidAtDiscount.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">value Of Shares Paid At Discount</td>
                            <td class="px-2 py-1">{{ subItem.valueOfSharesPaidAtDiscount.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Called On</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesCalledOn.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">value Of Shares Called On</td>
                            <td class="px-2 py-1">{{ subItem.valueOfSharesCalledOn.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Forfeited</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesForfeited.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">Value Of Shares Forfeited</td>
                            <td class="px-2 py-1">{{ subItem.valueOfSharesForfeited.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Paid Fully Non Cash</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesPaidFullyNonCash.toLocaleString() }}</td>
                          </tr>
                          <tr style="border: solid 1px #dedede;">
                            <td class="px-2 py-1 font-bold text-capitalize">number Of Shares Paid Partly Non Cash</td>
                            <td class="px-2 py-1">{{ subItem.numberOfSharesPaidPartlyNonCash.toLocaleString() }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-4" v-if="currentRoute === 'directors'">
            <b-col>
              <b-row v-for="(management, index) in task.directorsAndSecretaries" :key="index">
                <b-col>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td class="px-2 py-1 font-bold text-gray text-uppercase" colspan="12">
                        <span>Directors for {{ management.year }}</span>
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-white">
                      <th class="px-2 py-1 text-uppercase">#</th>
                      <th class="px-2 py-1 text-uppercase">Name</th>
                      <th class="px-2 py-1 text-uppercase">Nationality</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;" v-for="(person, idx) in management.directors" :key="idx">
                      <td class="px-2 py-1 text-capitalize">
                        {{ idx + 1 }}.
                      </td>
                      <td class="px-2 py-1 text-capitalize">
                        {{ person.name }}
                      </td>
                      <td class="px-2 py-1 text-uppercase">
                        {{ person.nationality }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td class="px-2 py-1 font-bold text-gray text-uppercase" colspan="12">
                        <span>Secretary for {{ management.year }}</span>
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <table class="min-w-full leading-normal text-gray">
                    <thead>
                    <tr style="border: solid 1px #dedede;" class="bg-white">
                      <th class="px-2 py-1 text-uppercase">Name</th>
                      <th class="px-2 py-1 float-start text-uppercase">Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 text-capitalize">
                        {{ management.secretary.name }}
                      </td>
                      <td class="px-2 py-1 text-uppercase">
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import AnnualReturnAddressViewer from "@/views/home/services/annual-returns/components/AnnualReturnAddressViewer.vue";
import AnnualReturnPhysicalAddressViewer
  from "@/views/home/services/annual-returns/components/AnnualReturnPhysicalAddressViewer.vue";

export default {
  name: "AnnualReturnForms",
  components: { AnnualReturnPhysicalAddressViewer, AnnualReturnAddressViewer },
  props: {
    task: Object
  },
  data(){
    return{
      currentRoute:"address"
    }
  },
  methods:{
    select(route){
      this.currentRoute = route
    },
  }
};
</script>

<style scoped>

th, td {
  font-size: 12px;
}

</style>