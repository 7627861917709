<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Form 20 (Optional Form)
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="10" class="mx-auto">
          <Form20Resolution :data="data" badge="File Directors and Secretary"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Form20Resolution from "@/views/home/services/continuous-filing/task/forms/Form20Resolution";
export default {
  name: "FileForm20",
  components: { Form20Resolution},
  props:{
    data:Object
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>