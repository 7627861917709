<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-textarea v-model="description"
                      class="mb-2"
                      rows="4"
                      placeholder="Comments">
          </b-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9">
          <b-input-group class="border border-grey rounded-sm">
            <b-form-input
              class="form-text text-sm noFocus border-0 text-gray"
              v-model="search"
              v-on:keyup="onSearch"
              type="text"
              placeholder="Search">
            </b-form-input>
            <b-input-group-prepend
              class="bg-white">
              <i class="fas fa-search mx-3 text-gray hover:text-blue" style="margin-top: 12px;" @click="onSearch"></i>
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <b-form-select v-model="limit" class="w-full form-select paginate-details" v-on:change="fetch">
            <b-select-option value="15">15</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
            </b-col>
          </b-row>
          <b-row class="flex py-2 px-3 border-b border-header_grey hover:bg-header_grey" v-for="(registrar, idx) in getRegistrars" :key="idx">
            <b-col cols="9">
              <div class="flex">
                <b-col class="ml-3">
                  <b-row>
                    <b-col>
                      <p class="text-sm text-uppercase"  v-if="registrar">
                        <span v-if="registrar.givenName">{{registrar.givenName}}</span>
                        <span class="mx-1" v-if="registrar.surName">{{registrar.surName}}</span>
                        <span class="mx-1" v-if="registrar.middleName">{{registrar.middleName}}</span>
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p class="text-xs text-lowercase" v-if="registrar">{{registrar.email}}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </div>
            </b-col>
            <b-col cols="3">
              <button @click="assign(registrar)" class="button">
                Select <i class="text-white fa fa-check-circle mx-1"></i>
                <b-spinner class="ml-1" small v-if="loadingAssignment && selectedId == registrar.id"></b-spinner>
              </button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AppealAssignment",
  props:{taskId:String, permission:String},
  data(){
    return{
      search:null,
      loading:false,
      loadingAssignment:false,
      description:null,
      selectedId:null,
      limit:15
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchRegistrars", "searchRegistrars", "assignReservationTask", "updateAppealStatus"]),
    fetch(){
      this.loading = true
      this.fetchRegistrars({permission:this.permission, limit:this.limit})
    },
    onSearch(){
      this.loading = true
      this.searchRegistrars({ term: this.search, permission: this.permission })
    },
    assign(person){
      this.selectedId = person.id
      let data = {
        status:"Valid",
        reservationId:this.taskId,
        description:this.description,
        userId:person.id
      }

      if(this.description != null){
        this.loadingAssignment = true
        this.updateAppealStatus(data)
      }else {
        this.warningToast("Action", "Enter a comment or instruction")
      }
    },
  },
  computed:{
    ...mapGetters(["getRegistrars", "getReservationAssignmentError", "getAssignmentSuccess", "getAppealSuccess", "getAssignmentError"]),
  },
  watch:{
    getRegistrars(){
      this.loading = false
    },
    getAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
    },
    getAppealSuccess(){
      this.loadingAssignment = false
      this.loading = false
    },
    getAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getReservationAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
}
</script>

<style scoped>
.button{
  @apply w-full bg-blue mt-1 px-2 py-1 rounded-sm text-white text-xs
}

.button:hover{
  @apply bg-green-dark;
}
</style>