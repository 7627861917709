<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Registration">
        <EscalationDataUpdate v-if="task" :application-id="task.applicationResponse.id" :level="1" type="bn-data-update" permission="can_view_bname_reg_escalations"/>
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept <b-spinner v-if="loading" small class="ml-1"></b-spinner>
            </button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-registration" title="Query Registration">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.description"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-query-hover" variant="primary" @click="onQuery">
                  Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>

    <b-row class="mx-1">
      <b-col cols="12" v-if="task">
        <b-row class="mx-1">
          <b-col>
            <BusinessNameDataUpdateMessages :id="task.applicationResponse.id" mode="TOP_MESSAGE"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{task.applicationResponse.name}} <label v-if="task.applicationResponse.nameOnFile" class="mx-1 text-blue">( Name on File: {{task.applicationResponse.nameOnFile}} )</label>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Registration Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.no}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-orange font-bold" v-if="queried">Queried</span>
                  <span class="text-gray text-capitalize" v-else>{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.subType)}}</span>
                </td>

                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">Level {{task.applicationResponse.applicationLevel}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{task.applicationResponse.submissionDate}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-gray"
                   style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Summary</td>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Date of Commencement</th>
                <td class="px-2 py-1">
                          <span class="text-gray">
                            {{dateFormat(task.dateOfCommencement)}}
                          </span>
                </td>
              </tr>
              <tr>
                <th class="px-2 py-1">Date of Registration</th>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{dateFormat(task.applicationResponse.registrationDate)}}</span>
                </td>
              </tr>

              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Nature of Business</th>
                <td class="px-2 py-1">
                  <ul class="text-blue">
                    <li v-for="(section, idx) in task.natureOfBusiness.breakdown"  :key="idx">
                      <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                    </li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1 text-capitalize">{{task.user.givenName.toLowerCase()}} {{task.user.surName.toLowerCase()}}</td>
                <td class="px-2 py-1">{{task.user.email}}</td>
                <td class="px-2 py-1">{{task.user.phoneNumber}}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="6" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Official Account Details (To be Created)</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1">
                          <span class="text-blue">
                            {{task.email}}
                          </span>
                </td>
                <td class="px-2 py-1">
                          <span class="text-blue">
                            {{task.phone}}
                          </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
              <button type="button" class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i> Escalate
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'application-details') ? 'menu-item-active' : 'menu-item']" @click="select('application-details')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Details</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Attachments</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Messages</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <BusinessNameDataUpdateFormDetails :task="task" v-if="currentRoute === 'application-details'"/>
            <BusinessNameDataUpdateFormFiles v-if="currentRoute === 'attachments'"/>
            <BusinessNameDataUpdateMessages :id="task.applicationResponse.id" v-if="currentRoute === 'messages'" mode="THREAD"/>
          </b-col>
          <b-col cols="6">
            <EMDSFileExplorer :brn="task.applicationResponse.brn" :entity-type="task.applicationResponse.type"/>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-spinner small class="mx-auto text-center"></b-spinner>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import BusinessNameDataUpdateMessages
  from "@/views/home/services/data-update/task/business/BusinessNameDataUpdateMessages.vue";
import BusinessNameDataUpdateFormDetails
  from "@/views/home/services/data-update/task/business/BusinessNameDataUpdateFormDetails.vue";
import BusinessNameDataUpdateFormFiles
  from "@/views/home/services/data-update/task/business/BusinessNameDataUpdateFormFiles.vue";
import EMDSFileExplorer from "@/views/home/services/data-update/components/EMDSFileExplorer.vue";
import EscalationDataUpdate from "@/views/home/commons/EscalationDataUpdate.vue";

export default {
  name: "BusinessNameDataUpdateTaskLevel1",
  components: {
    EscalationDataUpdate,
    EMDSFileExplorer,
    BusinessNameDataUpdateFormFiles, BusinessNameDataUpdateFormDetails, BusinessNameDataUpdateMessages},
  props:{
    task:Object
  },
  data(){
    return{
      hasComments: false,
      currentRoute: "application-details",
      loading:false,
      margin:"2px",
      queried:false,
      taskId:null,
      isQueryResponse:false,
      messages:[],
      maxText:500,
      form:{
        description:null,
        applicationId:null,
        status:null
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
      menu:[
        {
          title:"Application Details",
          route:"application-details",
        },
        {
          title:"Attachments",
          route:"attachments",
        },
      ],
      edmsDocuments:[],
      loadingDocuments:false,
      action:null
    }
  },

  methods:{
    ...mapActions(["updateBNDataUpdateStatus"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onApprove(){
      this.form.status = "Approved"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.form.status = "Queried"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.form.applicationId = this.task.applicationResponse.id
        this.updateBNDataUpdateStatus(this.form)
      }
      if(this.action === "escalate") {
        this.$refs.escalateSubmissions.show();
      }
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    select(route){
      this.currentRoute = route
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    getDateTime(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters(
        [
          "getBNDataUpdateTask",
          "getBNDataUpdateSuccess",
          "getBNDataUpdateTaskError",
          "getBNDataUpdateError",
          "getBNDataUpdateTaskCount",
          "getBNDataUpdateTaskMessages",
          "getBNDataUpdateActionSuccess",
          "getBNDataUpdateUpdateStatusSuccess",
          "getBNDataUpdateUpdateStatusError"
        ])
  },
  watch:{
    task:{
      handler(){
        this.taskId = this.task.businessId
      },
      deep:true
    },
    getBNDataUpdateActionSuccess(data){
      if(data) {
        this.loading = false
        this.successToast("Success!", data.message)
        this.cancelConfirmation()
        this.$refs["query-registration"].hide();
        window.location.reload()
      }
    },
    getBNDataUpdateUpdateStatusError(data){
      this.loading = false
      this.$refs["query-registration"].hide();
      this.cancelConfirmation()
      this.errorToast("Error", data.data.message)
    }
  }
}
</script>

<style scoped>
.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}
.btn-escalate{
  @apply border border-blue bg-white rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}
</style>