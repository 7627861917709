import {api} from "@/app/services/api.service";

const state = {
    error:null,
    success:null,
};

const getters = {
    getAssignmentSuccess: (state) => state.success,
    getAssignmentError: (state) => state.error,
    getDeAssignmentSuccess: (state) => state.success,

};

const actions = {
    assignBNRTask({commit}, data){
        api().post("/business-registration/escalation/assign", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
    assignNewBNRTask({commit}, data){
        api().post("/business-registration/task", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
    assignAmendmentTask({commit}, data){
        api().post("/business-registration/filing/task", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
    assignAmendmentEscalationTask({commit}, data){
        api().post("business-registration/filing/escalation/assign", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
    assignCITask({commit}, data){
        api().post("/incorporation/companies/queue/re-assign", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
    assignDispensationTask({commit}, data){
        api().post("/incorporation/dispense/queue/re-assign", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
    assignCFTask({commit}, data){
        api().post("/incorporation/companies/continuous-filing/queue/re-assign", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
    assignBSITask({commit}, data){
        api().post("/building-societies/societies/queue/re-assign", data).
        then(response => {
            commit('setAssignSuccess', response.data);
        }).catch(error => {
            commit("setAssignError", error.response)
        });
    },
};

const mutations = {
    setAssignSuccess: (state, response) =>{
        state.success = response;
    },
    setAssignError: (state, response) =>{
        state.error = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
