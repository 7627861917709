<template>

  <div>
    <b-row>
      <b-col cols="12" class="flex">


          <button  type="button" @click="fetchAppointment"
                  class="bg-blue px-2 py-1 my-0 rounded-sm text-center text-white hover:bg-blue-dark mr-2">
              <i class="fa fa-download mr-2"></i>Appointment Notice (Form 12)
          </button>

      </b-col>
      <p>
        <small style="color: #157ec2;">Download Form to Sign and Upload</small>
      </p>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12" class="mx-auto" style="border: 1px solid #ced4da;">

          <b-row class="mt-4" v-if="appointmentShow">
              <b-col cols="12" class="mx-auto" style="border: 1px solid #ced4da;">
                  <AppointmentNoticePreview
                          v-on:urlAppointment="urlAppointment"
                  ></AppointmentNoticePreview>
              </b-col>
          </b-row>


      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppointmentNoticePreview
    from "@/views/home/services/insolvency/winding-up/previews/AppointmentNoticePreview.vue";


export default {
  name: "InsolvencyPreview",
  components: {
      AppointmentNoticePreview
  },
  props: {
    reservationNumber: {
      type: String,
      default: ""
    },
    companyDraftData: {}
  },
  data() {
    return {
      draftData: {},
      officialAccountData: {
        primaryEmail: "",
        primaryPhone: "",
        name: "",
        trackingNo: ""

      },
      forms: {
        bo: false
      },
      download: {
        bo: false,
        account: false
      },
        appointmentUrl:null,
        appointmentShow:true,
    };
  },
  methods: {
    ...mapActions([]),
      fetchAppointment(){
          let url =   this.appointmentUrl
          const a = document.createElement("a");
          a.href = url;
          a.target = "_blank";
          a.download = "form1.pdf";
          a.click();
      },
      urlAppointment(data){
          if(data){
              this.appointmentUrl = data
          }
      },
  },

  computed: {
    ...mapGetters([])
  },
  watch: {

  }


};
</script>

<style scoped>

</style>