<template>
  <b-row class="py-2">
    <b-col>
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Re-registration
          </b-badge>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card class="my-1">
            <b-row v-if="data.RE_REGISTRATION_FORM_2" class="mt-3">
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Form 2</td>
                  </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Company Name</td>
                      <td class="px-2 py-1">
                        <span>{{ data.RE_REGISTRATION_FORM_2.data.companyName }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Registration Number</td>
                      <td class="px-2 py-1">
                        <span>{{ data.RE_REGISTRATION_FORM_2.data.brn }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">Current Company Category</td>
                      <td class="px-2 py-1">
                        <span>{{ cleanEntityType(data.RE_REGISTRATION_FORM_2.data.oldSubType)  }}</span>
                      </td>
                    </tr>
                    <tr class="border-b border-gray-pale">
                      <td class="px-2 py-1 text-capitalize font-bold">New Company Category</td>
                      <td class="px-2 py-1">
                        <span>{{ cleanEntityType(data.RE_REGISTRATION_FORM_2.data.newSubType) }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Statement Of Compliance (SIGNATORY)</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan="12">
                      <LegalPersons :persons="[data.RE_REGISTRATION_FORM_2.data.signatory]" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

            <b-row v-if="data.FORM_6" class="mt-3">
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Form 6</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize font-bold">Company Name</td>
                    <td class="px-2 py-1">
                      <span>{{ data.FORM_6.data.companyName }}</span>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize font-bold">Registration Number</td>
                    <td class="px-2 py-1">
                      <span>{{ data.FORM_6.data.brn }}</span>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize font-bold">Current Company Category</td>
                    <td class="px-2 py-1">
                      <span>{{ cleanEntityType(data.FORM_6.data.oldSubType)  }}</span>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize font-bold">New Company Category</td>
                    <td class="px-2 py-1">
                      <span>{{ cleanEntityType(data.FORM_6.data.newSubType) }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Statement Of Compliance (SIGNATORY)</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan="12">
                      <LegalPersons :persons="[data.FORM_6.data.signatory]" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

            <b-row v-if="data.FORM_3" class="mt-3">
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Form 3</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize font-bold">Registration Number</td>
                    <td class="px-2 py-1">
                      <span>{{ data.FORM_3.data.brn }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Declaration of Compliance (SIGNATORY)</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan="12">
                      <LegalPersons :persons="[data.FORM_3.data.declarant]" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

            <b-row v-if="data.CHANGE_OF_DIRECTORS" class="mt-3">
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Form 20</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize font-bold">Effective Date Of Appointment</td>
                    <td class="px-2 py-1">
                      <span>{{ dateFormat(data.CHANGE_OF_DIRECTORS.data.effectiveDateOfAppointment) }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Appointment of Directors</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan="12">
                      <LegalPersons :persons="data.CHANGE_OF_DIRECTORS.data.currentForm20.directors" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

            <b-row v-if="data.FORM_19" class="mt-3">
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Form 19</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize">Date Of Consent To Appointment</td>
                    <td class="px-2 py-1">
                      <span>{{ dateFormat(data.FORM_19.data.dateOfConsentToAppointment) }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Appointment of Directors</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td colspan="12">
                      <LegalPersons :persons="data.FORM_19.data.directors" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

            <b-row class="mt-3">
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">MemArts</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize" colspan="12">
                      <b-form-textarea class="w-full" rows="4" :value="data.MEMARTS.data.descriptionOfChanges"
                                       disabled></b-form-textarea>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize">Alters Memorandum Of Association</td>
                    <td class="px-2 py-1">
                      <span>{{ data.MEMARTS.data.altersMemorandumOfAssociation }}</span>
                    </td>
                  </tr>
                  <tr class="border-b border-gray-pale">
                    <td class="px-2 py-1 text-capitalize">Alters Articles Of Association</td>
                    <td class="px-2 py-1">
                      <span>{{ data.MEMARTS.data.altersArticlesOfAssociation }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray border border-gray">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Objectives</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" v-for="(item, idx) in data.MEMARTS.data.objectives" :key="idx">
                    <td class="px-2 py-1 text-capitalize">
                      <b-form-textarea disabled :value="item.objective" class="text-xs" rows="4"></b-form-textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import LegalPersons from "@/views/home/commons/LegalPersons.vue";

export default {
  name: "CompanyReregistration",
  components: { LegalPersons},
  props: {
    data: Object,
    brn: String,
    type: String
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>