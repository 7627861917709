<template>
  <div>
    <b-row>
      <b-col>
        <b-card :header="badge">
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-1"
                label="Return Date From"
                label-for="street">
                <b-form-input
                  id="street"
                  class="form-text mb-3 noFocus"
                  type="text"
                  :value="data.returnDateFrom"
                  :disabled="true"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-1"
                label="Return Date To"
                label-for="street">
                <b-form-input
                  id="street"
                  class="form-text mb-3 noFocus"
                  type="text"
                  :value="data.returnDateTo"
                  :disabled="true"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>Nominal Amount: <b>{{data.nominalAmount.toLocaleString()}}</b></p>
              <p>Amount Paid per Share: <b>{{data.amountPaidPerShare.toLocaleString()}}</b></p>
              <p>Non Cash Shares Allotted: <b>{{data.nonCashSharesAllotted.toLocaleString()}}</b></p>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <table class="min-w-full leading-normal text-gray"
                     style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Allotments</td>
                </tr>
                </thead>
              </table>
              <table class="min-w-full leading-normal text-gray"
                     style="border: solid 1px #dedede;" v-for="(allotment, idx) in data.allotments" :key="idx">
                <thead>
                <tr style="border: solid 1px #dedede;">
                  <td colspan="12" class="px-2 py-2 font-bold text-uppercase text-blue">
                    <span v-for="(holder, idx) in allotment.shareholders" :key="idx" class="mr-2">
                      {{holder.name}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Share Class</th>
                  <th class="px-2 py-1 text-center">Shares</th>
                  <th class="px-2 py-1 text-center">Paid</th>
                  <th class="px-2 py-1 text-center">Not Paid</th>
                </tr>
                </thead>
                <tbody v-for="(share,idx2) in allotment.shares" :key="idx2">
                  <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-uppercase font-bold">
                      <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                      <span v-else>{{share.otherClassName}}</span>
                    </td>
                    <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.numberOfShares.toLocaleString()}}
                                </span>
                    </td>
                    <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountPaid.toLocaleString()}}
                                </span>
                    </td>
                    <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountUnPaid.toLocaleString()}}
                                </span>
                    </td>
                  </tr>
                  <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                    <td class="px-2 py-1 text-capitalize">
                      <span>Class {{subClass.nominalClass}}</span>
                    </td>
                    <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.numberOfShares.toLocaleString()}}
                                </span>
                    </td>
                    <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountPaid.toLocaleString()}}
                                </span>
                    </td>
                    <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountUnPaid.toLocaleString()}}
                                </span>
                    </td>
                  </tr>
                </tbody>

              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Form10Resolution",
  props:{
    data:{},
    badge:String
  },
};
</script>

<style scoped>

</style>