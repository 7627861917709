<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Notice by a Minor Repudiating the Relationship of the Partnership
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <PartnerListing :brn="brn" badge="Current Partners"/>
        </b-col>
        <b-col>
          <LegalPersonBusinessAmendments v-if="data.applicants" badge="Repudiating Minor(s)" :persons="partners"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import LegalPersonBusinessAmendments
  from "@/views/home/services/amendments/task/forms/LegalPersonBusinessAmendments";
import PartnerListing from "@/views/home/services/amendments/task/forms/PartnerListing";

export default {
  name: "MinorRepudiating",
  components: { PartnerListing, LegalPersonBusinessAmendments},
  props:{
    data:Object,
    brn:String
  },
  data(){
    return{
      partners:[]
    }
  },
  mounted(){
    for(let i = 0; i < this.data.applicants.length; i++){
      this.partners.push(
        {
          entityType:'INDIVIDUAL',
          individual: this.data.applicants[i],
          corporate:null
        }
      )
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>

</style>