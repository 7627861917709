import { render, staticRenderFns } from "./SubmissionDetails.vue?vue&type=template&id=90fcb372&scoped=true&"
import script from "./SubmissionDetails.vue?vue&type=script&lang=js&"
export * from "./SubmissionDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90fcb372",
  null
  
)

export default component.exports