import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import store from "@/store"
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Notifications from 'vue-notification'
import {abilitiesPlugin} from "@casl/vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/index.css"
import abilities from "@/app/services/abilities";
import moment from "moment/moment";
import axios from "axios";
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(abilitiesPlugin, abilities, {
  useGlobalProperties: true,
});

if (!window.location.pathname.match(/login/)) {
  window.loginReferrer = {
    pathname: location.pathname,
    href: location.href,
  };
}
// stop auth loader
Vue.prototype.$authLoading = false;

axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response.status === 403) {
        sessionStorage.clear();
        window.location.reload();
      } else if (error.response.status === 401) {
        sessionStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
);

(async () => {
  await (function () {
    if (!sessionStorage.length) {
      // allstart auth loader
      if (window.loginReferrer && window.loginReferrer.pathname !== "/") {
        Vue.prototype.$authLoading = true;
      }
      // Ask other tabs for session storage
      localStorage.setItem("getSessionStorage", Date.now());
    }

    window.addEventListener("storage", function (event) {
      // console.log("storage event", event);
      if (event.key === "getSessionStorage") {
        // Some tab asked for the sessionStorage -> send it
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
        localStorage.removeItem("sessionStorage");
      } else if (event.key === "sessionStorage" && !sessionStorage.length) {
        // sessionStorage is empty -> fill it
        // console.log(event.newValue);
        var data = JSON.parse(event.newValue);
        for (let key in data) {
          if (
              key === "access_token" ||
              key === "tokenRefresh" ||
              key === "current_user" ||
              key === "abilities"
          ) {
            sessionStorage.setItem(key, data[key]);
          }
        }
        if (data) {
          let usr = JSON.parse(sessionStorage.getItem("current_user"));
          Vue.prototype.$userProfile = usr;

          if (usr) {
            Vue.prototype.$authLoading = false;
            if (window.loginReferrer) {
              window.location.assign(window.loginReferrer.href);
              window.loginReferrer = null;
            } else {
              window.location.reload();
            }
          }
        }
      } else if (event.key === "clearSessionStorage" && sessionStorage.length) {
        // console.log("logout");
        sessionStorage.clear();
        window.location.reload();
        window.loginReferrer = null;
        Vue.prototype.$authLoading = false;
      }
    });
  })();
})();


Vue.mixin({
  methods: {
    dataURLtoFile(base64String, filename) {
      let bstr = atob(base64String),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: "application/pdf" });
    },
    appendQueryParams(data) {
      return `?${Object.keys(data).map(k=>`${k}=${data[k]}`).join('&')}`
    },
    successToast: function (
        title = "Success",
        text = "Request was processed successfully"
    ) {
      this.$notify({
        group: "foo",
        type: "success",
        title: title,
        text: text,
      });
    },
    errorToast: function (
        title = "Error",
        text = "Something went wrong try again later"
    ) {
      this.$notify({
        group: "foo",
        type: "error",
        title: title,
        text: text,
      });
    },
    warningToast: function (
        title = "Warning",
        text = "It seems something is not right"
    ) {
      this.$notify({
        group: "foo",
        type: "warn",
        title: title,
        text: text,
      });
    },
    filterUnitData(data) {
      let arrayData = [];
      let obj = {};
      data.forEach(list => {
        obj = {
          value: list.id,
          text: list.name
        };
        arrayData.push(obj);
      });

      return arrayData;
    },
    getDocumentType(edmsType){
      console.log(edmsType)
      return "CERTIFICATE"
    },
    cleanUnderscores(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onlyNumberValidator(event) {
      if (!/[+-/*()\d]/.test(event.key)) return event.preventDefault();
    },
    dateFormat(date){
      if(date){
        return moment(date).format("DD/MM/YYYY")
      }
      return ""
    },
    dateTimeFormat(date){
      if(date){
      return moment(date).format("DD-MM-YYYY h:mmA")
      }
      return ""
    },
    dateRangeFormat(date){
      if(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
      }
      return null
    },
    getBusinessNameDocumentClassifications(){
      let types = []
      types.push(
        {
          text:"Business Name Form",
          value:"BUSINESS_APPLICATION_FORM"
        }
      )

      types.push(
        {
          text:"Joint Venture Agreement",
          value:"JOINT_VENTURE_AGREEMENT"
        }
      )

      types.push(
        {
          text:"Certificate of Registration",
          value:"CERTIFICATE"
        }
      )

      types.push(
        {
          text:"Others",
          value:"OTHER"
        }
      )
      return types
    },
    getCompanyDocumentClassifications(){
      let types = []
      types.push(
        {
          text:"Form S18",
          value:"FORM_S18"
        }
      )

      types.push(
        {
          text:"Form S18",
          value:"FORM_S18"
        }
      )

      types.push(
        {
          text:"Form A1",
          value:"FORM_A1"
        }
      )

      types.push(
        {
          text:"Form 1",
          value:"FORM_1"
        }
      )

      types.push(
        {
          text:"Form 18",
          value:"FORM_18"
        }
      )

      types.push(
        {
          text:"Form 20",
          value:"FORM_2O"
        }
      )
      types.push(
        {
          text:"Certificate of Incorporation",
          value:"CERTIFICATE"
        }
      )
      types.push(
        {
          text:"Others",
          value:"OTHER"
        }
      )
      return types
    },
    cleanEntityType(name){
      if(name == null){
        return ""
      }
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    determineLockDown(compliance){
      if(compliance.filingProcess === "DORMANCY" || compliance.filingProcess === "CHANGE_OF_NAME" || compliance.filingProcess === "AMALGAMATION" || compliance.filingProcess === "CONVERSION"){
        return true
      }
      return false
    },
    objectToQueryParams(objectToParse) {
      return Object.keys(objectToParse).map(key => key + '=' + objectToParse[key]).join('&');
    },
    pdfValidator(data) {
      if (data) {
        let fileSections = data.name.split(".");
        let pdfBool = false;
        if ((fileSections[fileSections.length - 1].includes("pdf") || fileSections[fileSections.length - 1].includes("PDF"))  && data.type === "application/pdf") {
          pdfBool = true;
        }
        return pdfBool;
      }
      return false;
    },
    showConfirmDialog(message = "Are you sure of this action ?") {
      this.boxTwo = ''
      return this.$bvModal.msgBoxConfirm(message, {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })

    },

  },
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
