import {api} from "@/app/services/api.service";

const state = {
    business_names:[],
    business_escalations:null,
    business_details_error:null,
    business_details:null,
    bn_registration:{},
    task_count:{},
    registration_stats:{},
    task_messages:null,
    preview:null,
    error:null,
    success:{},
    successFinalDecision:null,

};

const getters = {
    getBNRegistrationSubmissions: (state) => state.business_names,
    getRegistrationEscalations: (state) => state.business_escalations,
    getRegistrationTaskMessages: (state) => state.task_messages,
    getBNRegistrationStatistics: (state) => state.registration_stats,
    getRegistrationTaskCount: (state) => state.task_count,
    getRegistrationPreview: (state) => state.preview,
    getRegistrationTask: (state) => state.bn_registration,
    getRegistrationTaskError: (state) => state.error,
    getRegistrationSuccess: (state) => state.success,
    getRegistrationActionSuccess: (state) => state.successFinalDecision,
    getRegistrationAssignmentSuccess: (state) => state.success,
    getUpdateStatusSuccess: (state) => state.success,
    getUpdateStatusSuccessError: (state) => state.error,
    getRegistrationError: (state) => state.error,
    getBusinessDetails: (state) => state.business_details,
    getBusinessDetailsError: (state) => state.business_details_error,
};

const actions = {
    async addLlpPayment({ commit }, data) {
        await api().post("business-registration/add-payment",  data)
            .then((response) => {
                commit("setBusinessNameRegistration", response);
            })
            .catch((error) => {
                commit("setBusinessDetailsError", error.response);
            });
    },
    async fetchBusinessNameRegistrations({commit}, data){
        let path = "/business-registration?status="+data.statuses+"&page="+data.page+"&per_page="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setBusinessNameRegistrations', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchBusinessNameEscalations({commit}, data){
        api().get("/business-registration/escalation?status="+data.status+"&toUserId="+data.userId+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setEscalations', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchRegistrationMessages({commit}, id){
        api().get("/business-registration/task/status?businessId="+id).
        then(response => {
            commit('setRegistrationMessages', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchBusinessNameRegistration({commit}){
        api().get("/business-registration/task/next").
        then(response => {
            commit('setBusinessNameRegistration', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchRegistrationTaskCount({commit}){
        api().get("/business-registration/task/count").
        then(response => {
            commit('setRegistrationCount', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchRegistrationStatistics({commit}){
        api().get("/business-registration/basic-statistics").
        then(response => {
            commit('setRegistrationStats', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchRegistrationPreview({commit}, name){
        api().get("/business-registration/attachment/preview?file-name="+name).
        then(response => {
            commit('setRegistrationPreview', response);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async assignRegistrationTask({commit}, data){
        api().post("/business-registration/task", data).
        then(response => {
            commit('setAssignmentSuccess', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async updateRegistrationStatus({commit}, data){
        api().post("/business-registration/task/status", data).
        then(response => {
            commit('setUpdateBusinessStatusSuccess', response.data);
        }).catch(error => {
            commit("setBusinessStatusUpdateError", error.response)
        });
    },
    async fetchBusinessNameDetails({commit}, trackingNo){
        api().get("/business-registration/by-tracking-no?trackingNo="+trackingNo).
        then(response => {
            commit('setBusinessDetails', response);
        }).catch(error => {
            commit("setBusinessDetailsError", error.response)
        });
    },

};

const mutations = {
    setBusinessNameRegistrations: (state, response) =>{
        state.business_names = response;
    },
    setEscalations: (state, response) =>{
        state.business_escalations = response;
    },
    setRegistrationMessages: (state, response) =>{
        state.task_messages = response;
    },
    setBusinessNameRegistration: (state, response) =>{
        state.bn_registration = response.data;
    },
    setBuildingSocietyRegistration: (state, response) =>{
        state.bs_registration = response;
    },
    setRegistrationStats: (state, response) =>{
        state.registration_stats = response;
    },
    setRegistrationCount: (state, response) =>{
        state.task_count = response;
    },
    setAssignmentSuccess: (state, response) =>{
        state.success = response;
    },
    setRegistrationError: (state, response) =>{
        state.error = response;
    },

    setRegistrationPreview: (state, response) =>{
        state.preview = response;
    },
    setUpdateBusinessStatusSuccess: (state, response) =>{
        state.successFinalDecision = response;
    },
    setBusinessStatusUpdateError: (state, response) =>{
        state.error = response;
    },
    setBusinessDetails:(state, response) =>{
        state.business_details = response.data
    },
    setBusinessDetailsError:(state, response) =>{
        state.business_details_error = response.data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};