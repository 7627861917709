<template>
  <div>
    <div class="bg-white rounded-sm m-2">
      <b-row>
        <b-col cols="12" class="m-2">
          <b-row>
            <b-col cols="6">
              <span class="text-xs px-2 py-1 text-red">
                <b>{{getBNDataUpdateTaskCount.total}}</b> Total Submissions
              </span>
              <span class="text-xs px-2 py-1 text-blue ml-2">
                <b>{{getBNDataUpdateTaskCount.assigned}}</b> Assigned
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white rounded-sm m-2">
      <b-row class="mx-1 py-2" v-if="showNextLoader">
        <b-col cols="12" >
          <TaskLoader ref="taskLoader" v-on:nextTask="nextTask"/>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <b-row v-if="getBNDataUpdateTask != null">
            <b-col cols="12" class="mx-3 mt-3">
              <b-row>
                <b-col>
                  <span class="bg-blue text-white px-2 py-1 rounded-sm text-xs font-bold">#BusinessNameDataUpdate</span>
                  <span class="bg-green text-white px-2 py-1 mx-1 rounded-sm text-xs font-bold">#DocumentClassificationLevel</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-row v-if="getBNDataUpdateTask == null" class="py-5">
                <b-col cols="12" class="py-5">
                  <b-row>
                    <b-col cols="12" class="text-center justify-content-center">
                      <img :src="require('../../../../../../../assets/images/ic_empty_box.png')" class="icon mx-auto">
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col cols="12" class="text-center">
                      <div class="h5 text-gray mx-auto">There are no Business Name Data Update Applications left @ LEVEL 3 Queue</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="text-center">
                      <div class="text-gray mx-auto">Tasks will be available when clients submit new <b class="text-blue">applications</b> or when <b class="text-orange">queries</b> are responded to.</div>
                    </b-col>
                  </b-row>
                  <b-row class="mt-4">
                    <b-col cols="12" class="text-center">
                      <button class="text-white bg-blue rounded-md px-2 py-2 mx-auto hover:bg-blue-dark" @click="checkForUpdates">Check for Updates</button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-else>
                <BusinessNameDataUpdateTaskLevel3 :task="getBNDataUpdateTask"/>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment"
import TaskLoader from "@/views/home/commons/TaskLoader.vue";
import BusinessNameDataUpdateTaskLevel3
  from "@/views/home/services/data-update/task/business/levels/BusinessNameDataUpdateTaskLevel3.vue";

export default {
  name: "BusinessNameRegistrationTaskQueueLevel3",
  components: {
    BusinessNameDataUpdateTaskLevel3,
    TaskLoader},
  data(){
    return{
      loading:true,
      showNextLoader:true,
      margin:"2px",
    }
  },
  methods:{
    ...mapActions(["fetchBusinessNameDataUpdateTask", "fetchBNDataUpdateTaskCount"]),
    checkForUpdates(){
      this.loading = true
      this.fetchBusinessNameDataUpdateTask({level:2})
      this.fetchBNDataUpdateTaskCount()
    },
    nextTask(){
      this.loading = true
      this.showNextLoader = false
      this.fetchBusinessNameDataUpdateTask({level:2})
      this.fetchBNDataUpdateTaskCount()
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    getDateTime(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    }
  },
  computed:{
    ...mapGetters(["getBNDataUpdateTask", "getBNDataUpdateTaskError", "getBNDataUpdateTaskCount"])
  },
}
</script>

<style scoped>
/*@import "../../../../../assets/styles/menu.css";*/
.icon{
  width: 170px;
}

.btn-escalate{
  @apply border bg-orange rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply bg-orange-dark;
}

.btn-approve{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}
</style>