<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Data Correction">
          <Escalation :task-id="task.id" type="data-correction" permission="can_access_data_correction_escalations"/>
      </b-modal>

        <b-modal id="modal-assign" hide-footer ref="deEscalateSubmissions" title="De Escalate Data correction">
            <DeEscalationDataCorrection :application-id="task.id" :level="1" type="data-escalate"
                                    permission="can_access_data_correction" />
        </b-modal>
    </b-row>


    <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
      <b-row>
        <b-col>
          <p class="text-sm">
            <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
            This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
          </p>
          <p class="mt-3 text-sm italic">
            <b>Note:</b><br/>
            All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
          </p>

            <b-form class="w-full">
                <b-form-textarea
                        v-model="form.comment"
                        type="text"
                        placeholder="Give a  description "
                        class="mt-1"
                        required>
                </b-form-textarea>
                <p v-if="form.comment" class="my-2 text-xs">{{form.comment.length}}/{{maxText}}</p>

            </b-form>

        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="4">
          <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
            <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="4">
          <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
            <i class="fa fa-check-circle text-white mr-1"></i> Accept <b-spinner small v-if="loading"></b-spinner></button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
      <b-col cols="12">
        <b-form class="w-full">
          <b-form-textarea
            v-model="form.description"
            type="text"
            placeholder="Give description of query"
            class="mt-1"
            required>
          </b-form-textarea>
          <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
          <b-row>
            <b-col cols="4">
              <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                Query
                <b-spinner small v-if="loading"></b-spinner>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-modal>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row class="mx-1">
          <b-col>
            <DataCorrectionMessages :task="task" mode="TOP_MESSAGE"/>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-alert variant="success" show>
              <i class="fa fa-info-circle mx-1"></i> This application was fully approved on <b>Verification Level 1</b>. Go through it once again to ensure that nothing has been left unchecked.
              This is a security mechanism to allow a testimony of more than one so that we have a thorough check of every entity before it is updated on the platform
            </b-alert>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Update Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.brn}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">Level {{task.level}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>



          <b-row class="mt-2">
              <b-col cols="12">
                  <PRNViewer :tracking-number="task.trackingNo"/>
              </b-col>
          </b-row>

        <b-row class="my-3">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">

                <button class="btn-escalate" @click="deEscalatePrompt">
                    <i class="fa fa-arrow-down text-blue mr-1"></i>De-Escalate
                </button>

              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Company Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col >
                <DataCorrectionForms v-if="currentRoute === 'forms'" :task="task"/>
                <DataCorrectionFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                <DataCorrectionMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
              </b-col>
<!--              <b-col cols="6">
                <EMDSFileExplorer :brn="task.brn" :entity-type="task.type"/>
              </b-col>-->
            </b-row>

              <b-row class="mt-2">
                  <b-col >
                      <table class="min-w-full leading-normal mt-1 text-blue-dark"
                             style="border: solid 1px #bdd7f1;">
                          <thead>
                          <tr class="bg-blue-pale">
                              <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                          </tr>
                          <tr>
                              <th class="px-2 py-1">Name</th>
                              <th class="px-2 py-1">Email</th>
                              <th class="px-2 py-1">Phone</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr style="border: solid 1px #bdd7f1;">
                              <td class="px-2 py-1 text-capitalize">{{task.applicant.givenName}} </td>
                              <td class="px-2 py-1">{{task.applicant.email}}</td>
                              <td class="px-2 py-1">{{task.applicant.phoneNumber}}</td>
                          </tr>
                          </tbody>
                      </table>
                  </b-col>

              </b-row>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import DataCorrectionForms from "@/views/home/services/data-correction/task/DataCorrectionForms.vue";
import DataCorrectionFormFiles from "@/views/home/services/data-correction/task/DataCorrectionFormFiles.vue";
import DataCorrectionMessages from "@/views/home/services/data-correction/task/DataCorrectionMessages.vue";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import DeEscalationDataCorrection from "@/views/home/commons/DeEscalationDataCorrection.vue";
import Escalation from "@/views/home/commons/Escalation.vue";

export default {
  name: "DataCorrectionTaskLevel2",
  components: {
      Escalation,
      DeEscalationDataCorrection,
      PRNViewer,
     // EMDSFileExplorer,
      DataCorrectionMessages, DataCorrectionFormFiles,
    DataCorrectionForms},
  props:{
    task:Object
  },
  data(){
    return{
      margin:"2px",
      currentRoute: "forms",
      loading:false,
      messages:[],
      taskId:null,
      maxText:500,
      snapshot:null,
      snapshots:[],
      form:{
        applicationId:null,
        description:null,
          comment:null,
        status:null
      },
    }
  },
  methods:{
    ...mapActions(["clearanceDataCorrectionFinal"]),
    onApprove(){
      this.form.applicationId = this.task.id
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.form.applicationId = this.task.applicationId
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.clearanceDataCorrectionFinal(this.form)

      }
      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }

      if (this.action === "de-escalate") {
          this.$refs.deEscalateSubmissions.show();
      }

      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },

      deEscalatePrompt() {
          this.$refs.deEscalateSubmissions.show();
      },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters([
      "getDataUpdateStatusSuccess",
      "getDataUpdateCompanyUpdateStatusError",
      "getDataCorrectionTaskError",
        "getDataCorrectionError"
    ])
  },
  watch:{
    getDataCorrectionTaskError(data){
      this.loading = false
      this.errorToast("Error", data.messages)
    },
    getDataUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      window.location.reload()
    },
    getCompanyUpdateStatusSuccessError(){
      this.loading = false
    },
      getDataCorrectionError(error){
          this.errorToast("Data Update Error", error.data.message);
      }
  }
}
</script>

<style scoped>

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

</style>