import {api} from "@/app/services/api.service";

const state = {
    insolvency:null,
    Insolvency_snapshots:null,
    Insolvency_escalations:null,
    Insolvency_escalation:null,
    Insolvency_details:null,
    payment_removed:null,
    payment_removed_error:null,
    Insolvency_details_error:null,
    preview:null,
    insolvency_task_count:null,
    task_thread:{},
    registration_stats:{},
    insolvencies:null,
    insv_error:null,
    insolvency_success:null,
    windingDraftData: null,
    windingDraftDataError: null,
    courtOrderPetitionDraftData:null
};

const getters = {
    getInsolvencySubmissions: (state) => state.insolvencies,
    getInsolvencyEscalations: (state) => state.Insolvency_escalations,
    getInsolvencyEscalation: (state) => state.Insolvency_escalation,
    getInsolvencyTaskCount: (state) => state.insolvency_task_count,
    getInsolvencyTaskMessages: (state) => state.task_thread,
    getInsolvencyStatistics: (state) => state.registration_stats,
    getInsolvencyTask: (state) => state.insolvency,
    getInsolvencyTaskError: (state) => state.insv_error,
    getInsolvencyStatusSuccess: (state) => state.insolvency_success,
    getAssignmentInsolvencySuccess: (state) => state.insolvency_success,
    getInsolvencyEscalationSuccess: (state) => state.insolvency_escalation,
    getInsolvencyError: (state) => state.insv_error,
    getInsolvencyDetails: (state) => state.Insolvency_details,
    getInsolvencyDetailsError: (state) => state.Insolvency_details_error,
    getWindingDraftData: (state) => state.windingDraftData,
    getWindingDraftDataError: (state) => state.windingDraftDataError,
    getCourtOrderPetitionDraftData: (state) => state.courtOrderPetitionDraftData
};

const actions = {
    async fetchInsolvency({commit}, trackingNumber){
        let path = "insolvency/winding-up/details?tracking-number=" + trackingNumber

        api().get(path).
        then(response => {
            commit('setInsolvency', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    async fetchAllInsolvency({commit}, data){
        let path = "/insolvency/process"+data
        api().get(path).
        then(response => {
            commit('setInsolvencies', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },    async fetchAllInsolvencyVerifications({commit}, data){
        let path = "/insolvency/process/verification-queue"+data
        api().get(path).
        then(response => {
            commit('setInsolvencies', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    async fetchInsolvencyEscalations({commit}, data){
        api().get("/insolvency/escalations?status="+data.status+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setInsolvencyEscalations', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    async fetchInsolvencyEscalation({commit}, data){
        api().get("/insolvency/escalations/details?escalationId="+data.id).
        then(response => {
            commit('setInsolvencyEscalation', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    async fetchInsolvencyTask({commit}, type){
        api().get("/insolvency/task/next?type="+type).
        then(response => {
            commit('setInsolvency', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setInsolvencyTaskError", error.response)
        });
    },    async fetchInsolvencyVerificationTask({commit}){
        api().get("insolvency/process/verification-queue/next").
        then(response => {
            commit('setInsolvency', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setInsolvencyTaskError", error.response)
        });
    },

    async fetchInsolvencyTaskCount({commit}){
        api().get("/insolvency/task/count").
        then(response => {
            commit('setInsolvencyTaskCount', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    async fetchInsolvencyStatistics({commit},application_type){
        let path = "/insolvency/process/basic-statistics?type="+application_type
        api().get(path).
        then(response => {
            commit('setInsolvencyStatistics', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    async fetchInsolvencyTaskMessages({commit}, id){
        api().get("/insolvency/task/status?applicationId="+id).
        then(response => {
            commit('setInsolvencyTaskMessages', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    async updateInsolvencyStatus({commit}, data){
        api().post("/insolvency/task/status", data).
        then(response => {
            commit('setInsolvencyStatusSuccess', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },

    async fetchInsolvencyDetails({commit}, trackingNumber){
        api().get("/insolvency/by-tracking-no/"+trackingNumber).
        then(response => {
            commit('setInsolvencyDetails', response.data);
        }).catch(error => {
            commit("setInsolvencyDetailsError", error.response)
        });
    },

    async fetchAllCompanyEDMSDocuments({commit}, data){
        api().post("/integration/api/edms/company/files/list", data).
        then(response => {
            commit('setAllCompanyDocuments', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setInsolvencyTaskError", error.response)
        });
    },
    assignInsolvencyTask({commit}, data){
        api().post("/insolvency/task", data).
        then(response => {
            commit('setAssignmentInsolvencySuccess', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },
    escalateInsolvencyTask({commit}, data){
        api().post("/insolvency/escalation", data).
        then(response => {
            commit('setInsolvencyEscalationSuccess', response.data);
        }).catch(error => {
            commit("setInsolvencyError", error.response)
        });
    },

    async fetchWindingDraftData({commit}, trackingNumber) {
        await api()
            .get("insolvency/winding-up/details?tracking-number=" + trackingNumber)
            .then(response => {
                commit("setWindingDraftData", response);
            })
            .catch(error => {
                commit("setWindingDraftDataError", error.response);
            });
    },
    async fetchBankruptcyDraftData({commit}, trackingNumber) {
        await api()
            .get("insolvency/bankruptcy/details?trackingNumber=" + trackingNumber)
            .then(response => {
                commit("setWindingDraftData", response);
            })
            .catch(error => {
                commit("setWindingDraftDataError", error.response);
            });
    },
    async fetchPetitionsDraftData({commit}, trackingNumber) {
        await api()
            .get("insolvency/petitions/details?trackingNumber=" + trackingNumber)
            .then(response => {
                commit("setWindingDraftData", response);
            })
            .catch(error => {
                commit("setWindingDraftDataError", error.response);
            });
    },
    async fetchReceivershipDraftData({commit}, trackingNumber) {
        await api()
            .get("insolvency/process/by-tracking-no?trackingNumber=" + trackingNumber)
            .then(response => {
                commit("setWindingDraftData", response);
            })
            .catch(error => {
                commit("setWindingDraftDataError", error.response);
            });
    },
    async fetchAdministrationDraftData({commit}, trackingNumber) {
        await api()
            .get("insolvency/process/by-tracking-no?trackingNumber=" + trackingNumber)
            .then(response => {
                commit("setWindingDraftData", response);
            })
            .catch(error => {
                commit("setWindingDraftDataError", error.response);
            });
    },
};

const mutations = {
    setInsolvencies: (state, response) =>{
        state.insolvencies = response;
    },
    setInsolvencyEscalations: (state, response) =>{
        state.Insolvency_escalations = response;
    },
    setInsolvencyEscalation: (state, response) =>{
        state.Insolvency_escalation = response;
    },
    setInsolvencyTaskCount: (state, response) =>{
        state.insolvency_task_count = response;
    },
    setInsolvencyStatistics: (state, response) =>{
        state.registration_stats = response;
    },
    setInsolvencyTaskMessages: (state, response) =>{
        state.task_thread = response;
    },
    setInsolvency: (state, response) =>{
        state.insolvency = response.data;
    },
    setInsolvencyTaskError: (state, response) =>{
        state.insv_error = response;
    },
    setAssignmentInsolvencySuccess: (state, response) =>{
        state.insolvency_success = response;
    },
    setInsolvencyEscalationSuccess: (state, response) =>{
        state.insolvency_escalation = response;
    },
    setInsolvencyError: (state, response) =>{
        state.insv_error = response;
    },
    setInsolvencyStatusSuccess: (state, response) =>{
        state.insolvency_success = response;
    },
    setInsolvencyDetails:(state, response) =>{
        state.Insolvency_details = response.data
    },
    setInsolvencyDetailsError:(state, response) =>{
        state.Insolvency_details_error = response.data
    },
    setCompanyEDMSDocuments:(state, response) =>{
        state.company_edms_documents = response.data
    },
    setAllCompanyDocuments:(state, response) =>{
        state.all_company_edms_documents = response
    },
    setCompanyEDMSDocumentsError:(state, response) =>{
        state.company_edms_documents_error = response.data
    },
    setWindingDraftData: (state, response) => {
        state.windingDraftData = response.data;
    },
    setWindingDraftDataError : (state,error) => {
        state.windingDraftDataError = error;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};