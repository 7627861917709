<template>
    <div>
        <div class="bg-white rounded-sm m-2">
            <b-row>
                <b-col cols="12" class="m-2">
                    <b-row v-if="getInsolvencyTaskCount !== null">
                        <b-col cols="6">
              <span class="text-xs px-2 py-1 text-red">
                <b>{{getInsolvencyTaskCount.total}}</b> Total Submissions
              </span>
                            <span class="text-xs px-2 py-1 text-blue ml-2">
                <b>{{getInsolvencyTaskCount.assigned}}</b> Assigned
              </span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <div class="bg-white rounded-sm m-2">
            <b-row class="mx-1 py-2" v-if="showNextLoader">
                <b-col cols="12" >
                    <TaskLoader ref="taskLoader" v-on:nextTask="nextTask"/>
                </b-col>
            </b-row>
            <b-row v-else>

                <b-col>


                    <b-row v-if="getInsolvencyTask != null">
                        <b-col cols="12" class="mx-3 mt-3">
                            <b-row>
                                <b-col>
                                    <span class="bg-blue text-white px-2 py-1 rounded-sm text-xs font-bold">#Insolvency</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-row v-if="getInsolvencyTask == null" class="py-5">
                                <b-col cols="12" class="py-5">
                                    <b-row>
                                        <b-col cols="12" class="text-center justify-content-center">
                                            <img :src="require('../../../../../assets/images/ic_empty_box.png')" class="icon mx-auto">
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col cols="12" class="text-center">
                                            <div class="h5 text-gray mx-auto">There are no verification  Applications left in the Queue</div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" class="text-center">
                                            <div class="text-gray mx-auto">Tasks will be available when new applications come in <b class="text-blue">applications</b> or when <b class="text-orange">queries</b> are responded to.</div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-4">
                                        <b-col cols="12" class="text-center">
                                            <button class="text-white bg-blue rounded-md px-2 py-2 mx-auto hover:bg-blue-dark" @click="checkForUpdates">Check for Updates</button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row v-else>
                                <CourtOrderVerificationTask :taskType="taskType" :task="getInsolvencyTask"/>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import TaskLoader from "@/views/home/commons/TaskLoader.vue";
import CourtOrderVerificationTask from "@/views/home/services/insolvency/task-queue/CourtOrderVerificationTask.vue";


export default {
    name: "InsolvencyPetitionTaskQueue",
    components: {CourtOrderVerificationTask, TaskLoader},
    data(){
        return{
            margin:"2px",
            loading:true,
            showNextLoader:true,
            currentRoute: null,
            taskType: "PETITION",
        }
    },
    mounted() {
        this.currentRoute = this.$route.path
        this.nextTask()
    },
    methods:{
        ...mapActions(["fetchInsolvencyVerificationTask", "fetchInsolvencyTaskCount"]),
        checkForUpdates(){
            this.loading = true
            this.fetchVerificationTask()
            //this.fetchInsolvencyTaskCount()
        },
        nextTask(){
            this.loading = true
            this.showNextLoader = false
            this.fetchVerificationTask(this.taskType)
           // this.fetchInsolvencyTaskCount()
        },

        async fetchVerificationTask(){
            this.loading = true
            this.showNextLoader = false
            await this.fetchInsolvencyVerificationTask()
          //   this.loading = true
          // api().get("insolvency/process/verification-queue/next").then((response)=>{
          //     this.loading = false
          //     this.showNextLoader = false
          //
          //     this.$store.commit("setInsolvency", response.data.data)
          // }).catch((error)=>{
          //     this.loading = false
          //     this.showNextLoader = true
          //     this.$store.commit("setInsolvencyTaskError", error)
          // })
        }
    },
    computed:{
        ...mapGetters([
            "getInsolvencyTask",
            "getInsolvencyTaskCount",
            "getInsolvencyError"])
    },

    watch:{
        getInsolvencyTask(data){
            console.log(data)
            // alert(JSON.stringify(data))
            //this.loading = false
            //this.showNextLoader = true
        },


    }
}
</script>

<style scoped>

.icon{
    width: 170px;
}

.btn-escalate{
    @apply border bg-orange rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
    @apply bg-orange-dark;
}

.btn-approve{
    @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query{
    @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject{
    @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover{
    @apply bg-green border-green text-white;
}

.btn-query:hover{
    @apply bg-orange border-orange text-white;
}

.btn-reject:hover{
    @apply bg-red border-red text-white;
}

.btn-approve-hover{
    @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
    @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover{
    @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
    @apply bg-green-dark;
}

.btn-query-hover:hover{
    @apply bg-orange-dark;
}

</style>