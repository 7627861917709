<template>
  <b-row>
    <b-col class="my-3">
      <b-row class="mt-1">
        <b-col>
          <general-resolution class="mt-3" :data="data"/>

          <change-of-address class="mt-3"
                             v-if="data.embeddedChanges.CHANGE_OF_ADDRESS"
                             :data="data.embeddedChanges.CHANGE_OF_ADDRESS.data"
                             :brn="brn"></change-of-address>

          <change-of-address-foreign class="mt-3"
                                     v-if="data.embeddedChanges.CHANGE_OF_ADDRESS_FOREIGN"
                                     :data="data.embeddedChanges.CHANGE_OF_ADDRESS_FOREIGN.data"
                                     :brn="brn"></change-of-address-foreign>

          <change-of-secretary class="mt-3"
                               v-if="data.embeddedChanges.CHANGE_OF_SECRETARIES"
                               :data="data.embeddedChanges.CHANGE_OF_SECRETARIES.data"
                               :brn="brn"></change-of-secretary>

          <change-of-directors class="mt-3"
                               v-if="data.embeddedChanges.CHANGE_OF_DIRECTORS"
                               :data="data.embeddedChanges.CHANGE_OF_DIRECTORS.data"
                               :brn="brn"></change-of-directors>

          <change-of-directors-foreign class="mt-3"
                                       v-if="data.embeddedChanges.CHANGE_OF_DIRECTORS_FOREIGN"
                                       :data="data.embeddedChanges.CHANGE_OF_DIRECTORS_FOREIGN.data"
                                       :brn="brn"></change-of-directors-foreign>

          <change-of-representatives-foreign class="mt-3"
                                             v-if="data.embeddedChanges.CHANGE_OF_REPRESENTATIVES"
                                             :data="data.embeddedChanges.CHANGE_OF_REPRESENTATIVES.data"
                                             :brn="brn"></change-of-representatives-foreign>

          <change-of-secretary-foreign class="mt-3"
                               v-if="data.embeddedChanges.CHANGE_OF_SECRETARY_FOREIGN"
                               :data="data.embeddedChanges.CHANGE_OF_SECRETARY_FOREIGN.data"
                               :brn="brn"></change-of-secretary-foreign>

          <change-of-membership class="mt-3"
                                v-if="data.embeddedChanges.CHANGE_OF_MEMBERSHIP"
                                :data="data.embeddedChanges.CHANGE_OF_MEMBERSHIP.data"
                                :brn="brn"></change-of-membership>

          <change-of-share-capital class="mt-3"
                                   v-if="data.embeddedChanges.CHANGE_SHARE_CAPITAL"
                                   :data="data.embeddedChanges"></change-of-share-capital>

          <TransferOfShares class="mt-3"
                            v-if="data.embeddedChanges.MULTI_TRANSFER_OF_SHARES"
                            :data="data.embeddedChanges.MULTI_TRANSFER_OF_SHARES.data"/>

          <TransmissionOfShares class="mt-3"
                                v-if="data.embeddedChanges.MULTI_TRANSMISSION_OF_SHARES"
                                :data="data.embeddedChanges.MULTI_TRANSMISSION_OF_SHARES.data"/>

          <ValuationOfShares class="mt-3"
                             v-if="data.embeddedChanges.VALUATION_OF_SHARES"
                             :data="data.embeddedChanges.VALUATION_OF_SHARES.data"/>

          <ForfeitureOfShares class="mt-3"
                              v-if="data.embeddedChanges.MULTI_FORFEITURE"
                              :data="data.embeddedChanges.MULTI_FORFEITURE.data"/>

          <SurrenderOfShares class="mt-3"
                             v-if="data.embeddedChanges.MULTI_SURRENDER"
                             :data="data.embeddedChanges.MULTI_SURRENDER.data"/>

          <AmendedMemArts class="mt-3"
                          v-if="data.embeddedChanges.MEMARTS"
                          :data="data.embeddedChanges.MEMARTS.data"/>

          <CallOnShares class="mt-3"
                        v-if="data.embeddedChanges.CALL_ON_SHARES"
                        :data="data.embeddedChanges.CALL_ON_SHARES.data"/>

          <file-form10 class="mt-3"
                       v-if="data.embeddedChanges.FILE_FORM_10"
                       :data="data.embeddedChanges.FILE_FORM_10.data"/>

          <file-form20 class="mt-3"
                       v-if="data.embeddedChanges.FILE_FORM_20"
                       :data="data.embeddedChanges.FILE_FORM_20.data"/>

          <file-form13 class="mt-3"
                       v-if="data.embeddedChanges.FORM_13"
                       :data="data.embeddedChanges.FORM_13.data"/>

          <file-form14 class="mt-3"
                       v-if="data.embeddedChanges.FORM_14"
                       :data="data.embeddedChanges.FORM_14.data"/>

          <file-form15 class="mt-3"
                       v-if="data.embeddedChanges.FORM_15"
                       :data="data.embeddedChanges.FORM_15.data"/>

          <file-extension-or-fine class="mt-3"
                                  v-if="data.embeddedChanges.FILE_EXTENSION_OR_FINE"
                                  :task-id="taskId"
                                  :data="data.embeddedChanges.FILE_EXTENSION_OR_FINE.data" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import GeneralResolution from "@/views/home/services/continuous-filing/task/components/GeneralResolution";
import ChangeOfAddress from "@/views/home/services/continuous-filing/task/components/ChangeOfAddress";
import ChangeOfDirectors from "@/views/home/services/continuous-filing/task/components/ChangeOfDirectors";
import ChangeOfMembership from "@/views/home/services/continuous-filing/task/components/ChangeOfMembership";
import ChangeOfShareCapital from "@/views/home/services/continuous-filing/task/components/ChangeOfShareCapital";
import ChangeOfSecretary from "@/views/home/services/continuous-filing/task/components/ChangeOfSecretary";
import FileForm20 from "@/views/home/services/continuous-filing/task/components/FileForm20";
import FileForm13 from "@/views/home/services/continuous-filing/task/components/FileForm13";
import FileForm14 from "@/views/home/services/continuous-filing/task/components/FileForm14";
import FileForm15 from "@/views/home/services/continuous-filing/task/components/FileForm15";
import FileForm10 from "@/views/home/services/continuous-filing/task/components/FileForm10";
import TransferOfShares from "@/views/home/services/continuous-filing/task/components/TransferOfShares";
import ValuationOfShares from "@/views/home/services/continuous-filing/task/components/ValuationOfShares";
import CallOnShares from "@/views/home/services/continuous-filing/task/components/CallOnShares";
import ForfeitureOfShares from "@/views/home/services/continuous-filing/task/components/ForfeitureOfShares";
import SurrenderOfShares from "@/views/home/services/continuous-filing/task/components/SurrenderOfShares";
import TransmissionOfShares from "@/views/home/services/continuous-filing/task/components/TransmissionOfShares";
import FileExtensionOrFine from "@/views/home/services/continuous-filing/task/components/FileExtensionOrFine";
import ChangeOfAddressForeign from "@/views/home/services/continuous-filing/task/components/ChangeOfAddressForeign.vue";
import ChangeOfDirectorsForeign
  from "@/views/home/services/continuous-filing/task/components/ChangeOfDirectorsForeign.vue";
import ChangeOfRepresentativesForeign
  from "@/views/home/services/continuous-filing/task/components/ChangeOfRepresentativesForeign.vue";
import AmendedMemArts from "@/views/home/services/continuous-filing/task/components/AmendedMemArts.vue";
import ChangeOfSecretaryForeign
  from "@/views/home/services/continuous-filing/task/components/ChangeOfSecretaryForeign.vue";
export default {
  name: "MultiChanges",
  components: {
    ChangeOfSecretaryForeign,
    AmendedMemArts,
    ChangeOfRepresentativesForeign,
    ChangeOfDirectorsForeign,
    ChangeOfAddressForeign, FileExtensionOrFine, TransmissionOfShares, SurrenderOfShares, ForfeitureOfShares,
    CallOnShares, ValuationOfShares, TransferOfShares, FileForm10, FileForm15, FileForm14, FileForm13,
    FileForm20, ChangeOfSecretary, ChangeOfShareCapital, ChangeOfMembership, ChangeOfDirectors,
    ChangeOfAddress,
    GeneralResolution },
  props:{
    data:Object,
    brn:String,
    taskId:String
  },
};
</script>

<style scoped>

</style>