<template>
  <b-row >
    <b-col>

        <b-overlay
            :show="loading"
            rounded="sm"
            spinner-variant="primary"
            opacity="0.6">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <div class="flex h-full" style="cursor: pointer;">
                            <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Application Information</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Attachments</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Messages</div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11" class="mx-auto">
                        <WindingInsolvencyForms v-if="currentRoute === 'forms'" :task="task"/>
                        <InsolvencyFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                        <InsolvencyMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InsolvencyFormFiles from "@/views/home/services/insolvency/task-queue/InsolvencyFormFiles.vue";
import InsolvencyMessages from "@/views/home/services/insolvency/task-queue/InsolvencyMessages.vue";
import WindingInsolvencyForms from "@/views/home/services/insolvency/task-queue/WindingInsolvencyForms.vue";
export default {
    name: "WindingInsolvencyDetails",
    components: {WindingInsolvencyForms, InsolvencyMessages, InsolvencyFormFiles,},
    props: {
        trackingNumber: String,
    },
    data() {
        return {
            margin: "2px",
            currentRoute: "forms",
            loading: false,
            task: null,

        }
    },
    methods: {
        ...mapActions(["fetchWindingDraftData","fetchBankruptcyDraftData"]),
        select(route) {
            this.currentRoute = route
        },
        fetchDraftData(trackingNumber) {
           // if path contains winding-up
            if (this.$route.path.includes("winding-up")) {
                this.fetchWindingDraftData(trackingNumber)
            } else {
                this.fetchBankruptcyDraftData(trackingNumber)
            }
        },


    },
    computed: {
        ...mapGetters(["getWindingDraftData", "getWindingDraftDataError"]),
    },

    mounted() {
        if (this.trackingNumber) {
            this.loading = true
            this.fetchDraftData(this.trackingNumber)
        }
    },

    watch: {

        trackingNumber(data) {
            if (data) {
                this.loading = true
                this.fetchWindingDraftData(data)
            }
        },

        getWindingDraftData(data) {
            if (data) {
                this.loading = false
                this.task = data.data
            }
        },
        getWindingDraftDataError(error) {
            if (error) {
                this.loading = false
                this.errorToast("Error", error.response.data.message)
            }
        }
    },


};
</script>

<style scoped>

th, td, p{
  font-size: 12px;
}

.filing{
  @apply border rounded-sm my-3 text-gray-light;
}

.filing-active{
  @apply border rounded-sm my-3 text-gray;
}

.filing-approved{
  @apply border rounded-sm my-3 text-green;
}

.filing-queried{
  @apply border rounded-sm my-3 text-orange;
}

.filing-rejected{
  @apply border rounded-sm my-3 text-red;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-reject{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}

</style>