<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col class="py-3">
          <b-row>
            <b-col cols="2">
              <b-form-input v-model="filter.startDate" type="date" class="text-sm"></b-form-input>
            </b-col>
            <b-col cols="1" class="text-center">
              <p class="mt-1">
                - to -
              </p>
            </b-col>
            <b-col cols="2">
              <b-form-input v-model="filter.endDate" type="date" class="text-sm"></b-form-input>
            </b-col>
            <b-col cols="2">
              <b-form-select v-model="filter.type"  class="form-select text-sm">
                <b-select-option :value="null">All Categories</b-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="2">
              <b-form-select v-model="filter.registrar"  class="form-select text-sm">
                <b-select-option :value="null">All Registrars</b-select-option>
              </b-form-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="count" cols="2" v-for="(count, idx) in reportData.aggregatedData" :key="idx">
          <span class="text-center count-number">{{count.total.toLocaleString()}}</span>
          <p class="count-text">{{count.title}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <line-chart :data="reportData.graph" :options="options"></line-chart>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import LineChart from "@/views/home/utils/charts/LineChart";
export default {
  name: "BuildingSocietyActivity",
  components:{LineChart},
  data(){
    return{
      filter:{
        type:null,
        registrar:null,
        startDate:null,
        endDate:null
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      },
      reportData:{
        aggregatedData:[
          {
            title:"Approved",
            total:6900
          },
          {
            title:"Queried",
            total:1700
          },
          {
            title:"Escalated",
            total:196
          },
          {
            title:"Pending",
            total:700
          },
          {
            title:"Awaiting Payment",
            total:230
          },
          {
            title:"Draft",
            total:2334
          }
        ],
        graph:[]
      }
    }
  },
};
</script>

<style scoped>

</style>