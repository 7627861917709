import {api} from "@/app/services/api.service";

const state = {
  annual_returns:{},
  annual_returns_escalations:null,
  annual_returns_details_error:null,
  annual_returns_details:null,
  annual_returns_task:null,
  annual_returns_task_count:{},
  annual_returns_stats:{},
  annual_returns_task_messages:null,
  success_escalation:null,
  annual_returns_payment_removed:null,
  error:null,
  success:{},
  annual_returns_success_final_decision:null,

};

const getters = {
  getAnnualReturnsSubmissions: (state) => state.annual_returns,
  getAnnualReturnsEscalations: (state) => state.annual_returns_escalations,
  getAnnualReturnsTaskMessages: (state) => state.annual_returns_task_messages,
  getAnnualReturnsStatistics: (state) => state.annual_returns_stats,
  getAnnualReturnsTaskCount: (state) => state.annual_returns_task_count,
  getAnnualReturnsTask: (state) => state.annual_returns_task,
  getAnnualReturnsTaskError: (state) => state.error,
  getAnnualReturnsAssignmentError: (state) => state.error,
  getAnnualReturnsUpdateStatusSuccess: (state) => state.annual_returns_success_final_decision,
  getAnnualReturnsEscalationSuccess:(state) => state.success_escalation,
  getAnnualReturnsAssignmentSuccess: (state) => state.success,
  getAnnualReturnsUpdateStatusSuccessError: (state) => state.error,
  getAnnualReturnsDetails: (state) => state.annual_returns_details,
  getAnnualReturnPaymentRemoved: (state) => state.annual_returns_payment_removed,
  getAnnualReturnsDetailsError: (state) => state.annual_returns_details_error,
};

const actions = {
  async fetchAnnualReturnsSubmissions({commit}, data){
    let path = "/incorporation/annual-returns/forms?status="+data.status+"&page="+data.page+"&pageSize="+data.limit
    if(data.search !== ""){
      path += "&search="+data.search
    }
    api().get(path).
    then(response => {
      commit('setAnnualReturnsSubmissions', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async fetchAnnualReturnsEscalations({commit}, data){
    api().get("/incorporation/annual-returns/forms/queue/my-escalations?status="+data.statuses+"&page="+data.page+"&perPage="+data.limit).
    then(response => {
      commit('setAnnualReturnsEscalations', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async fetchAnnualReturnsMessages({commit}, id){
    api().get("/incorporation/annual-returns/forms/queue/task/"+id+"/thread").
    then(response => {
      commit('setAnnualReturnsMessages', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async fetchAnnualReturnsTask({commit}){
    api().get("/incorporation/annual-returns/forms/queue/task").
    then(response => {
      commit('setAnnualReturnsTask', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async fetchAnnualReturnsEscalation({commit}, escalationId){
    api().get("/incorporation/annual-returns/forms/queue/task?escalationId="+escalationId).
    then(response => {
      commit('setAnnualReturnsTask', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async fetchAnnualReturnsTaskCount({commit}){
    api().get("/incorporation/annual-returns/forms/queue/count").
    then(response => {
      commit('setAnnualReturnsCount', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async fetchAnnualReturnsStatistics({commit}){
    api().get("/incorporation/annual-returns/forms/status-statistics").
    then(response => {
      commit('setAnnualReturnsStats', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async assignAnnualReturnsTask({commit}, data){
    api().post("/incorporation/annual-returns/forms/queue/re-assign", data).
    then(response => {
      commit('setAnnualReturnsAssignmentSuccess', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async escalateAnnualReturnsTask({commit}, data){
    api().post("/incorporation/annual-returns/forms/queue/escalate", data).
    then(response => {
      commit('setAnnualReturnsAssignmentSuccess', response.data);
    }).catch(error => {
      commit("setAnnualReturnsError", error.response)
    });
  },
  async updateAnnualReturnsStatus({commit}, data){
    api().post("/incorporation/annual-returns/forms/queue/update-status", data).
    then(response => {
      commit('setUpdateAnnualReturnsStatusSuccess', response.data);
    }).catch(error => {
      commit("setAnnualReturnsStatusUpdateError", error.response)
    });
  },
  async removeAnnualReturnsPayment({commit}, data){
    api().post("/incorporation/annual-returns/forms/remove-payment-details", data).
    then(response => {
      commit('setRemoveAnnualReturnsPayment', response.data);
    }).catch(error => {
      commit("setAnnualReturnsStatusUpdateError", error.response)
    });
  },
  async fetchAnnualReturnsDetails({commit}, trackingNo){
    api().get("/incorporation/annual-returns/forms/tracking-no/"+trackingNo).
    then(response => {
      commit('setAnnualReturnsDetails', response);
    }).catch(error => {
      commit("setAnnualReturnsDetailsError", error.response)
    });
  },

};

const mutations = {
  setAnnualReturnsSubmissions: (state, response) =>{
    state.annual_returns = response;
  },
  setAnnualReturnsEscalations: (state, response) =>{
    state.annual_returns_escalations = response;
  },
  setAnnualReturnsMessages: (state, response) =>{
    state.annual_returns_task_messages = response;
  },
  setAnnualReturnsTask: (state, response) =>{
    state.annual_returns_task = response;
  },
  setAnnualReturnsStats: (state, response) =>{
    state.annual_returns_stats = response;
  },
  setAnnualReturnsCount: (state, response) =>{
    state.annual_returns_task_count = response;
  },
  setAnnualReturnsAssignmentSuccess: (state, response) =>{
    state.success = response;
  },
  setAnnualReturnsError: (state, response) =>{
    state.error = response;
  },
  setUpdateAnnualReturnsStatusSuccess: (state, response) =>{
    state.annual_returns_success_final_decision = response;
  },
  setRemoveAnnualReturnsPayment: (state, response) =>{
    state.annual_returns_payment_removed = response;
  },
  setAnnualReturnsStatusUpdateError: (state, response) =>{
    state.error = response;
  },
  setAnnualReturnsDetails:(state, response) =>{
    state.annual_returns_details = response.data
  },
  setAnnualReturnsDetailsError:(state, response) =>{
    state.annual_returns_details_error = response.data
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};