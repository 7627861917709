<template>
  <b-row>
    <b-col>
      <b-overlay rounded="sm" :show="loading">
        <b-row>
          <b-col>
            <b-row class="mt-1">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2">Registration Details</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Tracking Number</th>
                    <th class="px-2 py-1">Status</th>
                    <th class="px-2 py-1">Sub Entity Type</th>
                    <th class="px-2 py-1">Submission Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" >
                    <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.trackingNumber}}
                  </span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-orange font-bold" v-if="application.queried">Queried</span>
                      <span class="text-blue" v-else-if="application.status === 'AWAITING_PAYMENT_CONFIRMATION'">Confirming Payment</span>
                      <span class="text-blue" v-else>{{application.status}}</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-gray text-capitalize">{{cleanEntityType(application.subType)}}</span>
                    </td>
                    <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.applicationResponse.submissionDate)}}
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-2">
                <table class="min-w-full leading-normal mt-1 text-gray"
                       style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Summary</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;">
                    <th class="px-2 py-1">Date of Commencement</th>
                    <td class="px-2 py-1">
                          <span class="text-gray">
                            {{dateFormat(application.dateOfCommencement)}}
                          </span>
                    </td>
                  </tr>
                  <tr style="border: solid 1px #dedede;">
                    <th class="px-2 py-1">Reservation No.</th>
                    <td class="px-2 py-1">
                      <span class="text-blue italic" v-if="application.reservation">
                        {{application.reservation.no}}
                      </span>
                      <span v-else>waiting...</span>
                    </td>
                  </tr>
                  <tr style="border: solid 1px #dedede;">
                    <th class="px-2 py-1">Nature of Business</th>
                    <td class="px-2 py-1">
                      <ul class="text-blue" v-if="application.natureOfBusiness">
                        <li v-for="(section, idx) in application.natureOfBusiness.breakdown"  :key="idx">
                          <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'application-details') ? 'menu-item-active' : 'menu-item']" @click="select('application-details')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Details</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row v-if="currentRoute === 'application-details'">
                  <b-col>
                    <b-row>
                      <b-col cols="12">
                        <b-row>
                          <b-col cols="12" class="mt-4">
                            <table class="min-w-full leading-normal mt-1 text-gray"
                                   style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Partners</td>
                              </tr>
                              </thead>
                              <tbody>
                              <LegalPersonsPartner class="p-1" :individuals="application.individualPartners" :corporates="application.corporatePartners"/>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-3">
                            <table class="min-w-full leading-normal mt-1 text-gray"
                                   style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Business Address</td>
                              </tr>
                              </thead>
                              <tbody v-if="application.physicalAddress">
                                <b-row class="mx-1 py-1">
                                <b-col>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Region"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus"
                                      type="text"
                                      disabled="true"
                                      :value="application.physicalAddress.region.name"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="District"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus text-capitalize"
                                      type="text"
                                      disabled="true"
                                      :value="application.physicalAddress.district.name.toLowerCase()"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Sub County"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus text-capitalize"
                                      type="text"
                                      disabled="true"
                                      :value="application.physicalAddress.subcounty.name"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Parish"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus"
                                      type="text"
                                      disabled="true"
                                      :value="application.physicalAddress.parish.name"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Village"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus text-capitalize"
                                      type="text"
                                      disabled="true"
                                      :value="application.physicalAddress.village.name"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Street"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus text-capitalize"
                                      type="text"
                                      disabled="true"
                                      :value="application.street"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Plot"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus text-capitalize"
                                      type="text"
                                      disabled="true"
                                      :value="application.plot"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                  <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Postal Address"
                                    label-for="input-1">
                                    <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus text-capitalize"
                                      type="text"
                                      disabled="true"
                                      :value="application.postalAddress"
                                      required>
                                    </b-form-input>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                        <b-row v-if="application.beneficialOwnerFormResponse">
                          <b-col>
                            <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Beneficial Owners</td>
                              </tr>
                              </thead>
                              <tbody v-if="application.beneficialOwnerFormResponse">
                              <tr style="border: solid 1px #dedede;">
                                <td class="t-body font-bold">Country</td>
                                <td class="t-body">
                                  {{ application.beneficialOwnerFormResponse.country.name }}
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td class="t-body font-bold">Physical Address</td>
                                <td class="t-body text-uppercase">
                                  <p v-if="application.beneficialOwnerFormResponse.village">
                        <span
                          class="mr-1">{{ application.beneficialOwnerFormResponse.village.region.name }} > </span>
                                    <span
                                      class="mx-1">{{ application.beneficialOwnerFormResponse.village.district.name }} > </span>
                                    <span class="mx-1">{{ application.beneficialOwnerFormResponse.village.subcounty.name }} > </span>
                                    <span
                                      class="mx-1">{{ application.beneficialOwnerFormResponse.village.parish.name }} > </span>
                                    <span
                                      class="mx-1">{{ application.beneficialOwnerFormResponse.village.village.name }}</span>
                                  </p>
                                  <p v-else>N/A</p>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                              <tbody v-if="application.beneficialOwnerFormResponse">
                              <tr>
                                <td colspan="12">
                                  <BeneficialOwners :persons="application.beneficialOwnerFormResponse.beneficialOwners" />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                            <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                              <tbody v-for="(entity, idx) in application.beneficialOwnerFormResponse.entityBeneficialOwners"
                                     :key="idx">
                              <tr class="px-2">
                                <td class="t-body font-bold">
                                  Registration Number
                                </td>
                                <td class="t-body">
                                  {{entity.brn}}
                                </td>
                              </tr>
                              <tr class="px-2">
                                <td class="t-body font-bold">
                                  Entity Name
                                </td>
                                <td class="t-body">
                                  {{entity.companyName}}
                                </td>
                              </tr>
                              <tr class="px-2">
                                <td class="t-body font-bold">
                                  Entity Type
                                </td>
                                <td class="t-body">
                                  {{cleanEntityType(entity.subType)}}
                                </td>
                              </tr>
                              <tr class="px-2">
                                <td class="t-body font-bold">
                                  Percentage Shares
                                </td>
                                <td class="t-body">
                                  {{entity.percentageShares}}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="12">
                                  <BeneficialOwners :persons="entity.entityIndividualBeneficialOwners" />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="6" class="mt-4">
                            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                                   style="border: solid 1px #bdd7f1;">
                              <thead>
                              <tr class="bg-blue-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                              </tr>
                              <tr>
                                <th class="px-2 py-1">Name</th>
                                <th class="px-2 py-1">Email</th>
                                <th class="px-2 py-1">Phone</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr style="border: solid 1px #bdd7f1;">
                                <td class="px-2 py-1 text-capitalize">{{application.user.givenName.toLowerCase()}} {{application.user.surName.toLowerCase()}}</td>
                                <td class="px-2 py-1">{{application.user.email}}</td>
                                <td class="px-2 py-1">{{application.user.phoneNumber}}</td>
                              </tr>
                              </tbody>
                            </table>
                          </b-col>
                          <b-col cols="6" class="mt-4">
                            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                                   style="border: solid 1px #bdd7f1;">
                              <thead>
                              <tr class="bg-blue-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Official Account Details (To be Created)</td>
                              </tr>
                              <tr>
                                <th class="px-2 py-1">Email</th>
                                <th class="px-2 py-1">Phone</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr style="border: solid 1px #bdd7f1;">
                                <td class="px-2 py-1">
                                  <span class="text-blue">
                                    {{application.email}}
                                  </span>
                                </td>
                                <td class="px-2 py-1">
                                  <span class="text-blue">
                                    {{application.phone}}
                                  </span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row v-if="currentRoute === 'attachments'">
                  <b-col>
                    <b-row class="my-3">
                      <b-col cols="4">
                        <b-row>
                          <b-col cols="12">
                            <b-row>
                              <b-col cols="12">
                                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                                  <thead>
                                  <tr class="bg-gray-pale">
                                    <td colspan="12" class="px-2 py-2 font-bold">Attachments</td>
                                  </tr>
                                  <tr>
                                    <th class="px-2 py-1">#</th>
                                    <th class="px-2 py-1">File Name</th>
                                    <th class="px-2 py-1 float-end">Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(file, idx) in application.attachments" :key="idx" style="border: solid 1px #dedede;">
                                    <td class="px-2 py-1">{{idx + 1}}</td>
                                    <td class="px-2 py-1 text-capitalize">{{file.fileDescription}}</td>
                                    <td class="px-2 py-1">
                                      <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="8">
                        <BusinessNameAttachments v-if="application.attachments.length > 0" ref="attachmentScreen"/>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <BusinessNameDataUpdateDetailsMessages v-if="currentRoute === 'messages'" :id="application.applicationResponse.id"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import LegalPersonsPartner from "@/views/home/commons/LegalPersonsPartner";
import BusinessNameAttachments from "@/views/home/services/registration/task/BusinessNameAttachments";
import server from "@/app/env/server.json";
import BeneficialOwners from "@/views/home/commons/BeneficialOwners.vue";
import BusinessNameDataUpdateDetailsMessages
  from "@/views/home/services/data-update/submissions/components/BusinessNameDataUpdateDetailsMessages.vue";

export default {
  name: "BusinessNameDataUpdateDetails",
  components: { BusinessNameDataUpdateDetailsMessages, BeneficialOwners, BusinessNameAttachments, LegalPersonsPartner },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      currentRoute: "application-details",
      application:null,
      loading:true,
      menu:[
        {
          title:"Application Details",
          route:"application-details",
        },
        {
          title:"Attachments",
          route:"attachments",
        },
        {
          title:"Messages",
          route:"messages",
        },
      ],
      attachmentUrl:null
    }
  },
  mounted() {
    this.fetchBusinessNameDataUpdateDetails(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchBusinessNameDataUpdateDetails"]),
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    getDateTime(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    openFile(data){
      this.attachmentUrl = server.IP+"/business-registration/attachment/preview?file-name="+data.fileName
      this.$refs.attachmentScreen.openFile(this.attachmentUrl)
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },
  computed:{
    ...mapGetters(["getBNDataUpdateBusinessDetails", "getBusinessDetailsError"])
  },
  watch:{
    // attachmentUrl:{
    //   handler(url){
    //     this.$refs.attachmentScreen.openFile(url)
    //   },
    //   deep:true
    // },
    getBNDataUpdateBusinessDetails(data){
      this.loading = false
      this.application = data
      this.openFile(data.attachments[0])
    },
    getBusinessDetailsError(){
      this.loading = false
      this.errorToast("Error", "Failed to get application details")
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>