import {api} from "@/app/services/api.service";

const state = {
    loginData: null,
    logoutData: null,
    otpData: null,
    userProfileData: null,
    requestPassword: null,
    newPassword: null,
    setToken: null,
    userLogout: null,
    loginError: null,
};

const getters = {
    getUserLoggedInState: (state) => state.loginData,
    getUserLogoutSuccess: (state) => state.logoutData,
    getOtpSuccess: (state) => state.otpData,
    getUserProfileData: (state) => state.userProfileData,
    getRequestPassword: (state) => state.requestPassword,
    getNewPassword: (state) => state.newPassword,
    getSetToken: (state) => state.setToken,
    getUserLogout: (state) => state.userLogout,
    getLoginError: (state) => state.loginError,
};

const actions = {
    async doLoginUser({commit}, data) {
        delete api().defaults.headers.common['Authorization']
        await api()
            .post("auth/api/auth/signin/backoffice", data)
            .then((response) => {
                commit("setLoginToken", response);
            })
            .catch((error) => {
                sessionStorage.removeItem("token");
                commit("setLoginErrorMessage", error);
            });
    },
    async doOtpVerification({commit}, data) {
        await api()
            .post("auth/api/auth/validate-login-code", data)
            .then((response) => {
                commit("setOtpData", response);
            })
            .catch((error) => {
                commit("setLoginErrorMessage", error);
            });
    },
    async validateOtp({commit}, data) {
        await api()
            .post("auth/api/auth/validate-otp", data)
            .then((response) => {
                commit("setOtpData", response);
            })
            .catch((error) => {
                sessionStorage.removeItem("token");
                commit("setLoginErrorMessage", error);
            });
    },
    async doLogoutUser({commit}, data) {
        await api()
            .post("auth/api/auth/logout/at-login", data)
            .then((response) => {
                commit("setLogoutData", response);
            })
            .catch((error) => {
                commit("setLoginErrorMessage", error);
            });
    },
    async logoutUser({commit}) {
        await api()
            .get("auth/api/auth/logout")
            .then((response) => {
                commit("setLogoutData", response);
            })
            .catch((error) => {
                commit("setLoginErrorMessage", error);
            });
    },
    async fetchUserProfile({commit}, token) {
        await api()
            .get("profile", {
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                commit("setUserProfileData", response);
            })
            .catch((error) => {
                sessionStorage.removeItem("userProfile");
                commit("setLoginErrorMessage", error);
            });
    },
    async requestResetCode({commit}, data) {
        await api()
            .post("/auth/api/auth/staff/reset-password", data)
            .then((response) => {
                commit("setRequestPassword", response);
            })
            .catch((error) => {
                commit("setLoginErrorMessage", error);
            });
    },
    async changePassword({commit}, data) {
        await api()
            .post("/auth/api/auth/confirm-new-password", data)
            .then((response) => {
                commit("setPassword", response);
            })
            .catch((error) => {
                sessionStorage.removeItem("token");
                commit("setLoginErrorMessage", error);
            });
    },
    async checkTokenVerification({commit}, data) {
        await api()
            .get("validate-token?token=" + data)
            .then((response) => {
                commit("setCheckToken", response);
            })
            .catch((error) => {
                sessionStorage.removeItem("token");
                commit("setLoginErrorMessage", error);
            });
    },
    async doLogOutUser({commit}) {
        await api()
            .post("log-out")
            .then((response) => {
                commit("setUserLogout", response);
            })
            .catch((error) => {
                commit("setLoginErrorMessage", error);
            });
    },
};

const mutations = {
    setLoginToken: (state, response) => {
        state.loginData = response.data;
    },
    setOtpData: (state, response) => {
        state.otpData = response.data;
    },
    setLogoutData: (state, response) => {
        state.logoutData = response.data;
    },
    setUserProfileData: (state, response) => {
        let user = response.data;
        sessionStorage.setItem("userProfile", JSON.stringify(user));
        state.userProfileData = user;
    },
    setRequestPassword: (state, response) => {
        state.requestPassword = response.data;
    },
    setPassword: (state, response) => {
        state.newPassword = response.data;
    },
    setCheckToken: (state, response) => {
        state.setToken = response.data;
    },
    setUserLogout: (state, response) => {
        state.userLogout = response.data;
    },

    setLoginErrorMessage: (state, error) => {
        state.loginError = error.response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};