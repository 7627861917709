<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Registration">
        <EscalationDataUpdate v-if="task" :application-id="task.applicationResponse.id" :level="2" type="bn-data-update" permission="can_view_bname_reg_escalations"/>
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept <b-spinner v-if="loading" small class="ml-1"></b-spinner>
            </button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-final-confirm" hide-footer ref="finalConfirm" size="xl" title="Final Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">This is the final step in verification levels of an Entity Undergoing Data Update.</span>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="3">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="3">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept <b-spinner v-if="loading" small class="ml-1"></b-spinner>
            </button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-registration" title="Query Registration">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.description"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-query-hover" variant="primary" @click="onQuery">
                  Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>

    <b-row class="mx-1">
      <b-col cols="12" v-if="task">
        <b-row class="mt-2">
          <b-col>
            <b-alert variant="success" show>
              <i class="fa fa-info-circle mx-1"></i> This application was fully approved on <b>Verification Level 2</b>. Go through it once again to ensure that nothing has been left unchecked.
              This is a security mechanism to allow a testimony of more than one so that we have a thorough check of every entity before it is updated on the platform
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{task.applicationResponse.name}} <label v-if="task.applicationResponse.nameOnFile" class="mx-1 text-blue">( Name on File: {{task.applicationResponse.nameOnFile}} )</label>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Registration Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.no}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-orange font-bold" v-if="queried">Queried</span>
                  <span class="text-gray text-capitalize" v-else>{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">Level {{task.applicationResponse.applicationLevel}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{task.applicationResponse.submissionDate}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
<!--              <button type="button" class="btn-escalate" @click="escalatePrompt">-->
<!--                <i class="fa fa-arrow-up text-blue mr-1"></i> Escalate-->
<!--              </button>-->
<!--              <button type="button" class="btn-query" v-b-modal.modal-query>-->
<!--                <i class="fa fa-question text-white mr-1"></i> Query-->
<!--              </button>-->
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col>
            <b-row>
              <b-col>
                <b-row class="mt-3">
                  <b-col>
                    <p class="font-bold">Total EDMS Documents: {{edmsDocuments.length}}</p>
                  </b-col>
                  <b-col>
                    <button class="float-end bg-blue px-2 py-1 text-white rounded-sm" @click="pullDocuments(task)">
                      Reload
                      <b-spinner class="mx-1" v-if="loadingDocuments" small></b-spinner>
                    </button>
                  </b-col>
                </b-row>
                <table class="min-w-full leading-normal mt-2">
                  <thead>
                  <tr class="border-b border-t border-grey">
                    <th class="t-header">#</th>
                    <th class="t-header">Name</th>
                    <th class="t-header">Classification</th>
                    <th class="t-header text-center">Action</th>
                  </tr>
                  </thead>
                  <thead>
                  <tr>
                    <td colspan="12">
                      <div class="mx-auto">
                        <b-progress value="100" v-if="loadingDocuments"  variant="#007ac5" height="0.2rem" animated></b-progress>
                      </div>
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(document, idx) in edmsDocuments" :key="idx">
                    <td class="t-body">{{idx + 1}}</td>
                    <td class="t-body font-bold">
                      {{document.fileName}}
                      <p class="mt-1 italic" style="font-size: 9px;">{{dateTimeFormat(document.fileDate)}}</p>
                    </td>
                    <td class="t-body">
                      <b-form-select v-model="document.documentKey" class="w-full text-xs form-select paginate-details" v-on:change="fetch">
                        <b-select-option value="BUSINESS_APPLICATION_FORM">Business Name Form</b-select-option>
                        <b-select-option value="JOINT_VENTURE_AGREEMENT">Joint Venture Agreement</b-select-option>
                        <b-select-option value="CERTIFICATE">Certificate</b-select-option>
                        <b-select-option value="OTHER">Other</b-select-option>
                      </b-form-select>
                    </td>
                    <td class="t-body text-center">
                      <button class="bg-blue text-white px-2 py-1 rounded-sm" @click="openFile(document)">View <b-spinner v-if="fileLoader && selectedDocumentHandle === document.edmsId" small></b-spinner></button>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="edmsDocuments.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                      <span class="text-sm">No documents found</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col class="bg-gray" style="min-height: 540px;">
                <EDMSDocument v-if="file.Base64FileStream" :file="file"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import EscalationDataUpdate from "@/views/home/commons/EscalationDataUpdate.vue";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument.vue";

export default {
  name: "BusinessNameDataUpdateTaskLevel3",
  components: {
    EDMSDocument,
    EscalationDataUpdate,
  },
  props:{
    task:Object
  },
  data(){
    return{
      hasComments: false,
      fileLoader:false,
      currentRoute: "application-details",
      loading:false,
      margin:"2px",
      queried:false,
      taskId:null,
      isQueryResponse:false,
      messages:[],
      maxText:500,
      form:{
        description:null,
        applicationId:null,
        documentMapper:[],
        status:null
      },
      menu:[
        {
          title:"Application Details",
          route:"application-details",
        },
        {
          title:"Attachments",
          route:"attachments",
        },
      ],
      file:{
        extension:"pdf",
        Base64FileStream:null
      },
      edmsDocuments:[],
      loadingDocuments:false,
      selectedDocumentHandle:null,
      action:null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
    }
  },
  methods:{
    ...mapActions(["updateBNDataUpdateStatus", "finalBusinessNameDataUpdateApproval", "fetchAllBusinessNameEDMSDocuments", "fetchCompanyEDMSFile"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onApprove(){
      this.action = "final"
      this.form.status = "Approved"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.form.status = "Queried"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    openFile(file) {
      this.selectedDocumentHandle = file.edmsId
      this.file = {
        extension:"pdf",
        Base64FileStream:null
      }
      let data = {
        documentHandle:file.edmsId,
        overlay:""
      }
      this.fileLoader = true
      this.fetchCompanyEDMSFile(data)
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    yesConfirmation(){
      if(this.action === "final"){


        let mappedDocuments = []
        for(let i = 0 ; i < this.edmsDocuments.length; i++){
          let doc = this.edmsDocuments[i]
          if(doc.documentKey != null){
            mappedDocuments.push(doc)
          }
        }

        if(mappedDocuments.length < this.edmsDocuments.length){
          this.errorToast("Error", "Classify all documents before approval")
        }else{
          this.loading = true
          this.form.applicationId = this.task.applicationResponse.id
          let i = 0;
          mappedDocuments.forEach(e => {
            e.name = e.documentKey
            if(e.documentKey === "OTHER"){
              e.documentKey = e.fileName +" "+ e.fileDate +" "+ i
            }
            i++
          })
          this.form.documentMapper = mappedDocuments
          this.updateBNDataUpdateStatus(this.form)
        }
      }
      if(this.action === "escalate") {
        this.$refs.escalateSubmissions.show();
      }
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    select(route){
      this.currentRoute = route
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    getDateTime(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    pullDocuments(task){
      let data = {
        Keywords:{
          NewBusinessNo:task.applicationResponse.brn
        }
      }
      this.loadingDocuments = true
      this.fetchAllBusinessNameEDMSDocuments(data)
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters(
        [
          "getBNDataUpdateTask",
          "getBNDataUpdateSuccess",
          "getBNDataUpdateTaskError",
          "getBNDataUpdateError",
          "getBNDataUpdateTaskCount",
          "getBNDataUpdateTaskMessages",
          "getBNDataUpdateActionSuccess",
          "getBNDataUpdateUpdateStatusSuccess",
          "getBNDataUpdateUpdateStatusError",
          "getAllBusinessNameEDMSDocuments",
          "getEDMSFile"
        ])
  },
  watch:{
    task:{
      handler(){
        this.taskId = this.task.businessId
      },
      deep:true
    },
    getBNDataUpdateTask(task){
      this.pullDocuments(task)
    },
    getEDMSFile(data){
      this.fileLoader = false
      this.file = data
    },
    getBNDataUpdateActionSuccess(data){
      if(data) {
        this.loading = false
        this.successToast("Success!", data.message)
        this.cancelConfirmation()
        this.$refs["query-registration"].hide();
        window.location.reload()
      }
    },
    getBNDataUpdateUpdateStatusError(data){
      this.loading = false
      this.$refs["query-registration"].hide();
      this.cancelConfirmation()
      this.errorToast("Error", data.data.message)
    },
    getAllBusinessNameEDMSDocuments(data){
      this.loadingDocuments = false
      this.edmsDocuments = []
      data.DocumentResults.forEach(document => {
        let item = {
          edmsId: document.documentHandle,
          name: null,
          fileName: document.Name,
          fileDate: document.docDate,
          documentKey:null
        }
        this.edmsDocuments.push(item)
      })
    }
  }
}
</script>

<style scoped>
.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}
.btn-escalate{
  @apply border border-blue bg-white rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}
</style>