<template>
  <b-row >
    <b-col>

        <b-overlay
            :show="loading"
            rounded="sm"
            spinner-variant="primary"
            opacity="0.6">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <div class="flex h-full" style="cursor: pointer;">
                            <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Application Information</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Attachments</div>
                                </div>
                            </div>
                            <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                                <div class="flex mt-6">
                                    <div style="font-size: 14px">Messages</div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="11" class="mx-auto">
                        <ReceivershipForms v-if="currentRoute === 'forms'" :task="task"/>
                        <InsolvencyFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                        <InsolvencyMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InsolvencyFormFiles from "@/views/home/services/insolvency/task-queue/InsolvencyFormFiles.vue";
import InsolvencyMessages from "@/views/home/services/insolvency/task-queue/InsolvencyMessages.vue";
import ReceivershipForms from "@/views/home/services/insolvency/task-queue/ReceivershipForms.vue";
export default {
    name: "ReceivershipApplicationDetails",
    components: {ReceivershipForms, InsolvencyMessages, InsolvencyFormFiles,},
    props: {
        trackingNumber: String,
    },
    data() {
        return {
            margin: "2px",
            currentRoute: "forms",
            loading: false,
            task: null,

        }
    },
    methods: {
        ...mapActions(["fetchReceivershipDraftData"]),
        select(route) {
            this.currentRoute = route
        },


    },
    computed: {
        ...mapGetters(["getWindingDraftData", "getWindingDraftDataError"]),
    },

    mounted() {
        if (this.trackingNumber) {
            this.loading = true
            this.fetchReceivershipDraftData(this.trackingNumber)
        }
    },

    watch: {

        trackingNumber(data) {
            if (data) {
                this.loading = true
                this.fetchReceivershipDraftData(data)
            }
        },

        getWindingDraftData(data) {
            if (data) {
                this.loading = false
                this.task = data.data
            }
        },
        getWindingDraftDataError(error) {
            if (error) {
                this.loading = false
                this.errorToast("Error", error.response.data.message)
            }
        }
    },


};
</script>

<style scoped>

th, td, p{
  font-size: 12px;
}

</style>