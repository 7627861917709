import {api} from "@/app/services/api.service";

const state = {
    dispensation:null,
    dispensationError:null,
    dispensationTask:null,
    dispensationTaskError:null,
    dispensationTaskCount:null,
    dispensationTaskCountError:null,
    dispensationTaskUpdateStatus:null,
    dispensationTaskUpdateStatusError:null,
    dispensationStatistics:null,
    dispensationStatisticsError:null,
    dispensations:null,
    dispensationsError:null,
    dispensationTaskMessages:null,
    dispensationTaskMessagesError:null,
    dispensationEscalation:null,
    dispensationEscalationError:null,
    dispensationEscalations:null,
    dispensationEscalationsError:null,
};

const getters = {
    getDispensationTask: (state) => state.dispensationTask,
    getDispensationTaskError: (state) => state.dispensationTaskError,
    getDispensationTaskCount: (state) => state.dispensationTaskCount,
    getDispensationTaskCountError: (state) => state.dispensationTaskCountError,
    getDispensationTaskUpdateStatus: (state) => state.dispensationTaskUpdateStatus,
    getDispensationTaskUpdateStatusError: (state) => state.dispensationTaskUpdateStatusError,
    getDispensationStatistics: (state) => state.dispensationStatistics,
    getDispensationStatisticsError: (state) => state.dispensationStatisticsError,
    getDispensations: (state) => state.dispensations,
    getDispensationsError: (state) => state.dispensationsError,
    getDispensationTaskMessages: (state) => state.dispensationTaskMessages,
    getDispensationTaskMessagesError: (state) => state.dispensationTaskMessagesError,
    getDispensation: (state) => state.dispensation,
    getDispensationError: (state) => state.dispensationError,
    getDispensationEscalation: (state) => state.dispensationEscalation,
    getDispensationEscalationError: (state) => state.dispensationEscalationError,
    getDispensationEscalations: (state) => state.dispensationEscalations,
    getDispensationEscalationsError: (state) => state.dispensationEscalationsError,
};

const actions = {
    async fetchDispensationTask({commit}){
        await api().get("/incorporation/dispense/queue/task").
        then(response => {
            commit('setDispensationTask', response);
        }).catch(error => {
            commit("setDispensationTaskError", error.response)
        });
    },
    async fetchDispensationTaskCount({commit}){
        await api().get("/incorporation/dispense/queue/count").
        then(response => {
            commit('setDispensationTaskCount', response.data);
        }).catch(error => {
            commit("setDispensationTaskCountError", error.response)
        });
    },
    async updateDispensationTaskStatus({commit}, data){
        await api().post("/incorporation/dispense/queue/update-status", data).
        then(response => {
            commit('setDispensationTaskUpdateStatus', response.data);
        }).catch(error => {
            commit("setDispensationTaskUpdateStatusError", error.response)
        });
    },
    async fetchDispensationStatistics({commit}){
        await api().get("/incorporation/dispense/status-statistics").
        then(response => {
            commit('setDispensationStatistics', response.data);
        }).catch(error => {
            commit("setDispensationStatisticsError", error.response)
        });
    },
    async fetchAllDispensationApplications({commit}, data) {
        await api()
            .get("incorporation/dispense?status="+data.status+"&page="+data.page+"&pageSize="+data.limit)
            .then(response => {
                commit('setDispensations', response.data);
            }).catch(error => {
                commit("setDispensationsError", error.response)
            });
    },
    async fetchDispensationTaskMessages({commit}, taskId){
        await api().get("/incorporation/dispense/queue/task/"+taskId+"/thread").
        then(response => {
            commit('setDispensationTaskMessages', response.data);
        }).catch(error => {
            commit("setDispensationTaskMessagesError", error.response)
        });
    },
    async fetchDispensationByTrackingNumber({commit}, trackingNumber) {
        await api()
            .get("incorporation/dispense/" + trackingNumber)
            .then((response) => {
                commit("setDispensation", response.data);
            })
            .catch((error) => {
                commit("setDispensationError", error.response);
            });
    },
    async fetchDispensationByReservationNumber({commit}, reservationNumber) {
        await api()
            .get("incorporation/dispense/by-reservation-no/" + reservationNumber)
            .then((response) => {
                commit("setDispensation", response.data);
            })
            .catch((error) => {
                commit("setDispensationError", error.response);
            });
    },
    async escalateDispensationTask({commit}, data) {
        await api()
            .post("incorporation/dispense/queue/escalate", data)
            .then((response) => {
                commit("setDispensationEscalation", response.data);
            })
            .catch((error) => {
                commit("setDispensationEscalationError", error.response);
            });
    },
    async fetchDispensationEscalations({commit}, data){
        api().get("/incorporation/dispense/queue/my-escalations?page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setDispensationEscalations', response.data);
        }).catch(error => {
            commit("setDispensationEscalationsError", error.response)
        });
    },
};

const mutations = {
    setDispensationTask:(state, response) =>{
        state.dispensationTask = response.data
    },
    setDispensationTaskError:(state, response) =>{
        state.dispensationTaskError = response.data
    },
    setDispensationTaskCount:(state, response) =>{
        state.dispensationTaskCount = response
    },
    setDispensationTaskCountError:(state, response) =>{
        state.dispensationTaskCountError = response.data
    },
    setDispensationTaskUpdateStatus:(state, response) =>{
        state.dispensationTaskUpdateStatus = response
    },
    setDispensationTaskUpdateStatusError:(state, response) =>{
        state.dispensationTaskUpdateStatusError = response.data
    },
    setDispensationStatistics:(state, response) =>{
        state.dispensationStatistics = response
    },
    setDispensationStatisticsError:(state, response) =>{
        state.dispensationStatisticsError = response.data
    },
    setDispensations:(state, response) => {
        state.dispensations = response
    },
    setDispensationsError:(state, error) => {
        state.dispensationsError = error
    },
    setDispensationTaskMessages: (state, response) =>{
        state.dispensationTaskMessages = response.data;
    },
    setDispensationTaskMessagesError: (state, response) =>{
        state.dispensationTaskMessagesErrors = response.data;
    },
    setDispensation: (state, response) =>{
        state.dispensation = response.data;
    },
    setDispensationError: (state, response) =>{
        state.dispensationError = response.data;
    },
    setDispensationEscalation: (state, response) =>{
        state.dispensationEscalation = response.data;
    },
    setDispensationEscalationError: (state, response) =>{
        state.dispensationEscalationError = response.data;
    },
    setDispensationEscalations: (state, response) =>{
        state.dispensationEscalations = response;
    },
    setDispensationEscalationsError: (state, response) =>{
        state.dispensationEscalationsError = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};