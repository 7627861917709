import { render, staticRenderFns } from "./AdminUnits.vue?vue&type=template&id=5d39731a&scoped=true&"
import script from "./AdminUnits.vue?vue&type=script&lang=js&"
export * from "./AdminUnits.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d39731a",
  null
  
)

export default component.exports