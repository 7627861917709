<template>
  <div>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Data Correction">
          <Escalation :task-id="task.id" type="data-correction" permission="can_access_data_correction_escalations"/>
      </b-modal>

      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm" size="xl">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>

              <div class="my-3">
                  <div v-if="form.status ==='APPROVED'">
<!--                  <span class="my-2" >-->
<!--                      <label class="h6">Regenerate some forms ?</label>-->


<!--                      <b-form-select v-model="regenerateForms" class="w-full form-select">-->
<!--                          <b-select-option value="YES">Yes</b-select-option>-->
<!--                          <b-select-option value="NO">No</b-select-option>-->
<!--                      </b-form-select>-->
<!--                  </span>-->


                    <b-row>
                        <b-col>
                   <span style="font-size: 17px;">Here are the forms the the applicant will regenerate and sign in this data correction  </span>

                            <table class="w-full rounded-sm">
                                <tr class="bg-gray-pale text-sm">
                                    <th class="text-center">Form</th>

                                    <th class="text-center">Payable ?</th>
                                    <th class="text-center">Comment</th>
                                    <th class="text-center">Action</th>
                                </tr>


                    <tr   v-for="(crt,idx) in form.requestedForms" :key="idx">
<td>
<span class="mx-4" style="text-transform: capitalize" >{{ cleanUnderscores(crt.type) }}</span>
</td>
                        <td>

                            <b-form-select v-model="crt.payable" class="w-full form-select mx-1">
                                <b-select-option
                                        :value="true">Yes</b-select-option>
                                <b-select-option :value="false">No</b-select-option>
                            </b-form-select>
                        </td>
<td>
 <b-form-textarea
         v-model="crt.rationale"
         type="text"
         placeholder="Clear instructions to client on this form"
         class="mx-1"
         required>
                    </b-form-textarea>
</td>

                        <td class="text-center">
                            <i title="click to remove document" class="fa fa-trash text-red cursor-pointer mx-1 text-right" @click="removeForm(idx)"></i>
                        </td>

                    </tr>


                                </table>




              <b-form class="w-full mt-2">
                  <b-form-textarea
                          v-model="form.comment"
                          type="text"
                          placeholder="General rationale for this decision"
                          class="mt-1"
                          required>
                  </b-form-textarea>
                  <p v-if="form.comment" class="my-2 text-xs">{{form.comment.length}}/{{maxText}}</p>
              </b-form>
          </b-col>

                        <b-col cols="4">
                <b-row class="my-1" v-for="(clas,idx) in classifications" :key="idx">
                    <b-col cols="8">
                        <div class="flex flex-row" > <span class="text-sm ">{{clas.text}}</span>  </div>
                    </b-col>
                    <b-col>
                        <div class="flex flex-row" >   <b-button @click="addExtraForm(clas.value)" class="bg-blue text-white px-2 py-1 rounded-md p-1"> <i class="fa fa-plus text-xs mx-1" >Add </i> </b-button></div>
                    </b-col>
                </b-row>

            </b-col>
                    </b-row>


              </div>
              </div>




        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
            <b-col cols="2"></b-col>
            <b-col cols="6">

                <div v-if="form.status ==='QUERIED' || form.status ==='REJECTED' " class="flex flex-row">

                    <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark mx-2 text-sm"
                            @click="yesConfirmation">
                        <i class="fa fa-check-circle text-white mr-1"></i> Approve
                    </button>


                </div>
                <div v-else class="flex flex-row">

                <button   class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark mx-2 text-sm" @click="requestDocs">
                      <i class="fa fa-undo text-white mr-1"></i> Request for documents</button>

                  <button  v-if="!task.clearedForApproval"  class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark mx-2 text-sm" @click="yesConfirmation">
                      <i class="fa fa-arrow-up text-white mr-1"></i>  Seek Clearance</button>

                  <button v-if="task.clearedForApproval" class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark mx-2 text-sm" @click="finalApproval">
                      <i class="fa fa-check-circle text-white mr-1"></i> Final Approval </button>


              </div>


            </b-col>
        </b-row>

          </b-col>
        </b-row>
      </b-modal>

      <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.description"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery('QUERIED')">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>

        <b-modal id="modal-reject" hide-footer ref="reject" title="Reject Submission">
            <b-col cols="12">
                <b-form class="w-full">
                    <b-form-textarea
                            v-model="form.description"
                            type="text"
                            placeholder="Give description of your rejection"
                            class="mt-1"
                            required>
                    </b-form-textarea>
                    <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
                    <b-row class="mt-2">
                        <b-col>
                            <b-button class="btn-reject float-end" variant="primary" @click="onQuery('REJECTED')">
                                Reject
                                <b-spinner small v-if="loading"></b-spinner>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-col>
        </b-modal>



    <b-row class="mx-1" v-if="task">
      <b-col cols="12">

        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Corrections Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{ task.brn }}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize" v-if="task.status">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{ cleanEntityType(task.subType) }}</span>
                </td>
                <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">Level {{ task.level }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{ dateTimeFormat(task.createdAt) }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>




          <b-row class="mt-2">
              <b-col cols="12">
                  <PRNViewer :tracking-number="task.trackingNo"/>
              </b-col>
          </b-row>

        <b-row class="my-3">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
                <button type="button" class="btn-reject" v-b-modal.modal-reject>
                <i class="fa fa-exclamation-triangle text-white mr-1"></i> Reject
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Company Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <DataCorrectionForms v-if="currentRoute === 'forms'" :task="task"/>
                <DataCorrectionFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                <DataCorrectionMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
              </b-col>
<!--              <b-col cols="6">
                  <EMDSFileExplorer :brn="task.brn" :entity-type="task.type"/>
              </b-col>-->
            </b-row>

<!--              <b-row class="mt-2">
                  <b-col>
                      <table class="min-w-full leading-normal mt-1 text-blue-dark"
                             style="border: solid 1px #bdd7f1;">
                          <thead>
                          <tr class="bg-blue-pale">
                              <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                          </tr>
                          <tr>
                              <th class="px-2 py-1">Name</th>
                              <th class="px-2 py-1">Email</th>
                              <th class="px-2 py-1">Phone</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr style="border: solid 1px #bdd7f1;">
                              <td class="px-2 py-1 text-capitalize">{{ task.applicant.givenName }}</td>
                              <td class="px-2 py-1">{{ task.applicant.email }}</td>
                              <td class="px-2 py-1">{{ task.applicant.phoneNumber }}</td>
                          </tr>
                          </tbody>
                      </table>
                  </b-col>

              </b-row>-->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import DataCorrectionForms from "@/views/home/services/data-correction/task/DataCorrectionForms.vue";
import DataCorrectionFormFiles from "@/views/home/services/data-correction/task/DataCorrectionFormFiles.vue";
import DataCorrectionMessages from "@/views/home/services/data-correction/task/DataCorrectionMessages.vue";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import Escalation from "@/views/home/commons/Escalation.vue";


export default {
  name: "DataCorrectionTaskLevel1",
  components: {
      Escalation,
      PRNViewer,
    DataCorrectionMessages,
    DataCorrectionFormFiles,
    DataCorrectionForms
  },
  props:{
    task:Object
  },
  data(){
    return{
      margin:"2px",
      currentRoute: "forms",
      loading:false,
      messages:[],
      taskId:null,
      maxText:500,
      snapshot:null,
      snapshots:[],
        action:null,
        regenerateForms: "YES",
      form:{
          id: null,
        description:null,
          comment: null,
          status: null,
          requestedForms: []
      },

      edmsDocuments:[],
      loadingDocuments:false,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
        classifications: [
            { text: "Certificate", value: "CERTIFICATE" },
        ]
    }
  },
  methods:{
    ...mapActions(["updateDataCorrectionStatus","requestDataCorrectionForms","queryOrRejectDataCorrection","approveDataCorrectionFinal"]),
    onApprove(){
        this.form.requestedForms = []
        this.form.requestedForms = this.task.formRequests

        if (this.task.type === "BUSINESS") {
            this.classifications = []
            this.classifications.push(
                {text: "Statement of particulars Form", "value": "STATEMENT_OF_PARTICULARS"},
                {text: "Form 6 ", "value": "FORM_6"},
            )
        } else {

            this.classifications = []
            this.classifications.push(
                {text: "Form 18", value: "FORM_18"},
                {text: "Form 20", value: "FORM_20"},
                {text: "Memarts", value: "MEMARTS"},
            )

            if (this.task.subType === "single-member") {
                this.classifications.push(
                    {text: "Form 1", value: "FORM_1"},
                )
            } else if (this.task.subType === 'foreign-company') {
                this.classifications = []

                this.classifications.push(
                    {text: "Form 24", value: "FORM_24"},
                    {text: "Form 25", value: "FORM_25"},
                    {text: "Form 26", value: "FORM_26"},
                )
            }


        }

        this.form.id = this.task.id
      this.form.status = "APPROVED"
       // this.form.comment = this.form.description

            this.action = "final"

      this.$refs.confirm.show();
    },
    onQuery(status){
        this.form.id = this.task.id
      this.form.status = status
      this.action = "rejectedOrQueried"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateDataCorrectionStatus(this.form)
      }

      if (this.action === "rejectedOrQueried"){
        let payload = {
          id: this.form.id,
          rationale: this.form.description,
          status: this.form.status
        }
        this.queryOrRejectDataCorrection(payload)

      }

      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
      finalApproval(){
            this.form.applicationId = this.task.id
            this.form.status = "APPROVED"
        this.approveDataCorrectionFinal(this.form)
      },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    escalatePrompt(){
         this.action = "escalate"
        this.$refs.escalateSubmissions.show();


    },
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    },
    requestDocs(){
                this.loading = true

        this.requestDataCorrectionForms(this.form)
    },
      addExtraForm(type) {
          //validate if form already exists
          let exists = this.form.requestedForms.find((form) => form.type === type)
          if (exists) {
              this.errorToast("Error", "Form already exists")
              return
          }
          this.form.requestedForms.push({
              type: type,
              rationale: "",
              payable: false
          })
      },
      removeForm(index) {
          this.form.requestedForms.splice(index, 1)
      }
  },
  computed:{
    ...mapGetters([
      "getDataUpdateStatusSuccess",
      "getDataUpdateCompanyUpdateStatusError",
      "getDataCorrectionTaskError",
        "getDataCorrectionError"
    ]),
      state() {
          return this.form.requestedForms.length > 0;
      }
  },
  watch:{
    getDataCorrectionTaskError(data){
      this.loading = false
      this.errorToast("Error", data.messages)
    },
    getDataUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      window.location.reload()
    },
    getCompanyUpdateStatusSuccessError(){
      this.loading = false
    },
      getDataCorrectionError(error){
          this.errorToast("Data Update  Error", error.data.message);
      }
  }
}
</script>

<style scoped>

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-reject{
  @apply border border-red bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-reject:hover{
    @apply bg-red border-red text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

</style>