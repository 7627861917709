import { render, staticRenderFns } from "./NoticeOfCessation.vue?vue&type=template&id=608d4a81&scoped=true&"
import script from "./NoticeOfCessation.vue?vue&type=script&lang=js&"
export * from "./NoticeOfCessation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608d4a81",
  null
  
)

export default component.exports