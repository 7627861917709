<template>
  <div class="bg-white rounded-sm m-2">
    <b-row class="mt-2">
      <b-col cols="12">
        <b-row>
          <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
            <Assignment :id="taskId" type="search-report" permission="can_view_search_report_tasks"/>
          </b-modal>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-input
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="trackingNumber"
              type="text"
              placeholder="Search Tracking Number"
              required
              v-on:keyup.enter="fetch">
            </b-form-input>
          </b-col>
          <b-col cols="3">
            <b-form-input
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="brn"
              type="text"
              placeholder="Search Registration Number"
              required
              v-on:keyup.enter="fetch">
            </b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-select
                v-model="status"
                v-on:change="fetch"
                class="form-select noFocus text-gray mx-2 my-2 text-sm">
                <b-select-option :value="null">--Filter by Status--</b-select-option>
                <b-select-option value="PENDING">In Queue</b-select-option>
                <b-select-option value="ASSIGNED">Assigned</b-select-option>
                <b-select-option value="APPROVED">Approved</b-select-option>
                <b-select-option value="REJECTED">Rejected</b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="mx-auto">
          <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">Tracking No.</th>
                <th class="t-header">BRN</th>
                <th class="t-header">Name</th>
                <th class="t-header">Entity Type</th>
                <th class="t-header">Applicant</th>
                <th class="t-header text-center">Status</th>
                <th class="t-header text-center">Action</th>
                <th class="t-header text-center">Assignment</th>
                <th class="t-header text-center">Reg. Date</th>
              </tr>
              </thead>

              <tbody>
              <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in requests" :key="idx">
                <td class="t-body text-blue font-bold cursor-pointer hover:underline" @click="showDetails(submission.trackingNumber)">
                  {{submission.trackingNumber}}
                </td>
                <td class="t-body font-bold">
                  {{submission.searchValueOrBrn}} <span class="text-blue">({{submission.brnSource}})</span>
                </td>
                <td class="t-body"> {{submission.entityName}}</td>
                <td class="t-body text-capitalize"> {{submission.entityType.toLowerCase()}}</td>
                <td class="t-body text-capitalize"> {{submission.accountHolder.toLowerCase()}}</td>
                <td class="t-body text-capitalize text-center">
                  <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                    <span :class="(submission.status === 'PENDING') ? 'text-gray'
                    : (submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION')? 'text-gray'
                    : (submission.status.toUpperCase() === 'PAYMENT_FAILED')? 'text-red'
                    : (submission.status === 'ASSIGNED') ? 'text-blue'
                    : (submission.status === 'APPROVED') ? 'text-green'
                    : (submission.status === 'QUERIED') ? 'text-orange' : ''">
                        <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                          Confirming Payment
                        </span>
                        <span v-if="submission.status.toUpperCase() === 'PENDING'">
                          In Queue
                        </span>
                        <span v-else>
                          {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                        </span>
                    </span>
                  </p>
                </td>
                <td class="t-body text-center">
                  <button
                    v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'"
                    class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="move(submission)">
                    Move to Queue <b-spinner v-if="loadingMoveTask" small></b-spinner>
                  </button>
                  <button
                    v-else-if="submission.status.toUpperCase() === 'ESCALATED'"
                    class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission, 'Escalation')">
                    <i class="fa fa-reply mx-1"></i>Assign
                  </button>
                  <button
                    v-else-if="submission.status.toUpperCase() === 'ASSIGNED'"
                    class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                    <i class="fa fa-reply mx-1"></i>Assign
                  </button>
                  <button
                    v-else-if="submission.status.toUpperCase() === 'PENDING'"
                    class="text-xs bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                    <i class="fa fa-plus mx-1"></i>Assign
                  </button>
                  <p v-else class="text-xs">No action</p>
                </td>
                <td class="t-body text-center">
                  <p v-if="submission.assignedRegistrar" class="text-xs text-capitalize">
                    {{submission.assignedRegistrar.firstName.toLowerCase()}}
                    {{submission.assignedRegistrar.lastName.toLowerCase()}}
                  </p>
                  <p  v-else-if="submission.status.toUpperCase() === 'ASSIGNED'" class="text-xs">Registrar</p>
                  <p v-else class="text-xs">Not Assigned</p>
                </td>
                <td class="t-body text-center">{{dateFormat(submission.dateOfRegistration)}}</td>
              </tr>
              <tr>
                <td v-if="requests.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                  <span class="text-sm">No results found</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
          <b-col cols="1">
            <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
          </b-col>
          <b-col cols="1">
            <b-row>
              <b-col cols="12">
                <p class="paginate-details text-center">
                  <b-badge class="bg-blue text-white">
                    {{currentPage}}/{{pagination.totalPages}}
                  </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" >
            <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
          </b-col>
          <b-col class="9"></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Assignment from "@/views/home/commons/Assignment.vue";

export default {
  name: "AllSearchReportSubmissions",
  components: { Assignment },
  data(){
    return{
      selectedId:null,
      selectedRequest:null,
      trackingNumber:null,
      margin:"2px",
      searchRef:null,
      hasContent:false,
      loading:false,
      message:null,
      name:null,
      brn:null,
      currentPage:1,
      limit:12,
      application:null,
      status:null,
      taskId:null,
      requests:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchSearchReportRequests"]),
    fetch(){
      let options = ""
      if(this.status == null){
        options = "PENDING,ASSIGNED,APPROVED"
      }else {
        options = this.status
      }
      let data = {
        trackingNumber:this.trackingNumber,
        name:this.name,
        brn:this.brn,
        statuses:options,
        page:this.currentPage,
        limit:this.limit
      }
      this.loading = true
      this.fetchSearchReportRequests(data)
    },
    showDetails(trackingNumber){
      this.$emit("openDetails", trackingNumber)
    },
    view(request){
      this.selectedRequest = request
    },
    move(application){
      let data = {
        userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
        prn: application.prn
      }
      this.redoTransaction(data)
    },
    assignPrompt(data){
      this.loading = true
      this.taskId = data.taskId
      this.$refs.assignSubmissions.show();
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
  },
  computed:{
    ...mapGetters(["getSearchReportRequests"])
  },
  watch:{
    getSearchReportRequests(data){
      this.loading = false
      this.requests = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    }
  }
};
</script>

<style scoped>
.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.approve-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.approve-btn:hover{
  @apply bg-green text-white;
}

.reject-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.reject-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.warning-secondary{
  @apply flex text-flopurple;
}

.negative{
  @apply flex text-red;
}
.stat-count{
  display: inline;
  width: 15%;
}
</style>