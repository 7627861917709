<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Dispensation Further">
        <Escalation :task-id="taskId" type="dispensation" permission="can_view_dispensation_escalations"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-query" hide-footer ref="query-dispensation" title="Query Submission">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
                v-model="form.reason"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
            </b-form-textarea>
            <b-row>
              <b-col cols="4">
                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                  Query
                  <b-spinner small v-if="loading"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-modal id="modal-reject" size="lg" hide-footer ref="reject-dispensation" title="Reject Dispensation">
      <b-col cols="12">
        <b-form class="w-full">
          <b-form-group
              class="text-sm my-1"
              id="input-group-1"
              label="Your Custom Message"
              label-for="input-1">
            <b-form-textarea
                v-model="form.reason"
                type="text"
                placeholder="Give a personal description of query"
                class="mt-1"
                required>
            </b-form-textarea>
          </b-form-group>
          <b-row>
            <b-col cols="10"></b-col>
            <b-col cols="2">
              <button type="button" class="float-end btn-reject-hover" @click="onReject">
                Reject <b-spinner v-if="loading" small class="ml-1"></b-spinner>
              </button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-modal>
    <b-row>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-a-assign" hide-footer ref="assignSubmissions" title="Assign to Registrar">
        <Assignment :id="taskId" type="dispensation" permission="can_view_dispensation_tasks"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-a-reply" hide-footer ref="replySubmissions" title="Reply to Escalation">
        <AssignmentReply :id="taskId" :user-id="sourceId" type="dispensation"/>
      </b-modal>
    </b-row>
    <b-row v-if="messages.length > 0" class="mx-3 mb-3s">
      <b-col cols="12">
        <b-row class=" py-2 my-2 shadow-md rounded-sm" style="background: #b0ea75;">
          <b-col cols="10">
            <div class="flex">
              <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
              <span class="text-capitalize font-bold underline hover:text-green">{{source.toLowerCase()}}</span>
            </div>
            <p class="text-gray mt-2">
              {{ messages[0].messageData.comment }}
            </p>
            <span v-if="application !== null" class="italic text-gray" style="font-size: 10px;">{{dateTimeFormat(application.createdAt)}}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay rounded="sm" :show="loading">
      <b-row v-if="application !== null" class="mx-1">
        <b-col cols="12">
          <b-row>
            <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{application.name}}
            </span>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Dispensation Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Name</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Dispensation Type</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                    <span class="text-gray">{{application.trackingNo}}</span>
                  </td>
                  <td class="px-2 py-1">
                    {{application.newName}} <span class="line-through uppercase italic text-gray">{{ dispensationSuffix(application.name) }}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue text-capitalize">{{application.status.toLowerCase().replaceAll("_", " ")}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{cleanEntityType(application.type)}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>

          <!-- Applicant -->
          <b-row class="mt-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-blue-dark"
                     style="border: solid 1px #bdd7f1;">
                <thead>
                <tr class="bg-blue-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Name</th>
                  <th class="px-2 py-1">Email</th>
                  <th class="px-2 py-1">Phone</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #bdd7f1;" v-if="application.applicant">
                  <td class="px-2 py-1 text-capitalize">{{application.applicant.givenName}} {{application.applicant.surname}}</td>
                  <td class="px-2 py-1">{{application.applicant.email}}</td>
                  <td class="px-2 py-1">{{application.applicant.phoneNumber}}</td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <!-- /Applicant -->

          <!-- PRN Viewer -->
          <b-row class="mt-1">
            <b-col cols="12">
              <PRNViewer :tracking-number="application.trackingNo" :prn="application.payment.prn"/>
            </b-col>
          </b-row>
          <!-- /PRN Viewer -->

          <!-- Action Buttons -->
          <b-row class="my-3">
            <b-col cols="4"></b-col>
            <b-col cols="8">
              <div class="flex float-end">
                <button class="btn-escalate" @click="escalatePrompt">
                  <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
                </button>
                <button type="button" class="btn-query" v-b-modal.modal-query>
                  <i class="fa fa-question text-white mr-1"></i> Query
                </button>
                <button type="button" class="float-end btn-reject-hover" @click="rejectPrompt">
                  <i class="fas fa-ban text-white mr-1"></i> Reject
                </button>
                <button type="button" class="btn-approve" @click="onApprove">
                  <i class="fa fa-check-circle text-white mr-1"></i> Approve
                </button>
                <button class="btn-approve" @click="replyPrompt">
                  <i class="fa fa-reply text-white mr-1"></i> Reply
                </button>
                <button class="btn-approve" @click="assignPrompt">
                  <i class="fa fa-hand-pointer text-white mr-1"></i> Assign
                </button>
              </div>
            </b-col>
          </b-row>
          <!-- /Action Buttons -->

          <!-- Dispensation Details -->
          <b-row class="mt-2">
            <b-col cols="12">
              <DispensationFormDetailsEscalated :application="application"/>
            </b-col>
          </b-row>
          <!-- /Dispensation Details -->
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import moment from "moment";
import Escalation from "@/views/home/commons/Escalation";
import Assignment from "@/views/home/commons/Assignment";
import AssignmentReply from "@/views/home/commons/AssignmentReply";
import {mapActions, mapGetters} from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer";
import DispensationFormDetailsEscalated
  from "@/views/home/services/dispensation/escalations/tabs/DispensationFormDetailsEscalated.vue";

export default {
  name: "DispensationEscalationFile",
  components: {DispensationFormDetailsEscalated, PRNViewer, AssignmentReply, Assignment, Escalation},
  props:{
    message:String,
    trackingNumber:String,
    taskId:String,
    source:String,
    sourceId:String
  },
  data(){
    return{
      margin:"2px",
      currentRoute: "application-details",
      loading:true,
      messages:[],
      application:null,
      paymentInfo:null,
      form:{
        dispensationId:null,
        queueId:null,
        reason:null,
        status:null,
      },
      action:null,
      isShowingThread:false
    }
  },
  mounted() {
    this.fetchDispensationTaskMessages(this.taskId)
    this.fetchDispensationByTrackingNumber(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchDispensationTaskMessages", "fetchDispensationByTrackingNumber", "updateDispensationTaskStatus"]),
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "reply"){
        this.$refs.replySubmissions.show();
      }

      if(this.action === "final"){
        this.loading = true
        this.updateDispensationTaskStatus(this.form)
      }

      if(this.action === "assign"){
        this.$refs.assignSubmissions.show();
      }

      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    onApprove(){
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onCancel(){
      this.form.status = "QUERIED"
      this.action = "final"
      this.$refs["query-dispensation"].show();
    },
    onQuery(){
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.reason != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    onReject(){
      this.form.status = "REJECTED"
      this.action = "final"
      if(this.form.reason !== null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    replyPrompt(){
      this.action = "reply"
      this.$refs.replySubmissions.show();
    },
    assignPrompt(){
      this.action = "assign"
      this.$refs.assignSubmissions.show();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.escalateSubmissions.show();
    },
    rejectPrompt(){
      this.action = "reject"
      this.$refs["reject-dispensation"].show();
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ").replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dispensationSuffix(name){
      if (typeof name !== "string") {
        return ""
      }

      if (name.toUpperCase().endsWith("LIMITED")) {
        return "limited"
      } else if (name.toUpperCase().endsWith("LTD")) {
        return "ltd"
      } else {
        return ""
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
  computed:{
    ...mapGetters(["getDispensationTaskMessages", "getDispensation", "getCompanyUpdateStatusSuccess", "getPRNDetails", "getCompanyUpdateStatusSuccessError"])
  },
  watch:{
    getUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      this.$refs["query-dispensation"].hide();
      window.location.reload()
    },
    getUpdateStatusSuccessError(data){
      this.loading = false
      this.errorToast("Error!", data.message)
    },
    getDispensation(data){
      this.loading = false
      this.application = data
      this.form.queueId = this.taskId
      this.form.dispensationId = data.id
    },
    getDispensationTaskMessages(data){
      this.messages = data
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-reject-hover{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-reject-hover:hover{
  @apply bg-red border-red text-white;
}

</style>