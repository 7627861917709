<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Notice of Death of Partner(s)
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <PartnerListing :brn="brn" badge="Current Partners"/>
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <LegalPersonBusinessAmendments v-if="data.individualPartners || data.corporatePartners" badge="Deceased Partner(s)" :persons="partners"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import PartnerListing from "@/views/home/services/amendments/task/forms/PartnerListing";
import LegalPersonBusinessAmendments from "@/views/home/services/amendments/task/forms/LegalPersonBusinessAmendments";

export default {
  name: "DeathOfPartners",
  components: { LegalPersonBusinessAmendments, PartnerListing },
  props:{
    data:Object,
    brn:String
  },
  data(){
    return{
      partners:[]
    }
  },
  mounted(){
    for(let i = 0; i < this.data.individualPartners.length; i++){
      this.partners.push(
        {
          entityType:'INDIVIDUAL',
          individual: this.data.individualPartners[i],
          corporate:null
        }
      )
    }

    for(let i = 0; i < this.data.corporatePartners.length; i++){
      this.partners.push(
        {
          entityType:'CORPORATE',
          corporate: this.data.corporatePartners[i],
          individual:null
        }
      )
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>

</style>