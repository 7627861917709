<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Dissolution Of Partnership
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="mt-1">
          <span class="text-sm">Dissolution Date</span>
          <b-form-input class="form-text"
            :disabled="true" :value="data.dissolutionDate">
          </b-form-input>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: "NoticeOfDissolutionOfPartnership",
  props:{
    data:Object,
  },
  methods:{
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>

</style>