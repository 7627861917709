import {api} from "@/app/services/api.service";

const state = {
    amendment_tasks:null,
    amendment_task:null,
    amendment_task_error:null,
    amendment_details:null,
    amendment_error:null,
    amendment_messages:null,
    amendment_escalations:null,
    amendment_escalation:null,
    preview:null,
    task_count:{},
    task_thread:{},
    amendment_stats: null,
    amendment_stats_error:null,
    amendment_status_error:null,
    error:null,
    success:null,
    amendment_task_success:null,
    successAssignment:null,
};

const getters = {
    getAmendmentSubmissions: (state) => state.amendment_tasks,
    getAmendmentEscalations: (state) => state.amendment_escalations,
    getAmendmentStatuses: (state) => state.amendment_messages,
    getAmendmentTask: (state) => state.amendment_task,
    getAmendmentTaskError: (state) => state.amendment_task_error,
    getAmendmentDetails: (state) => state.amendment_details,
    getAmendmentStatistics: (state) => state.amendment_stats,
    getAmendmentCount: (state) => state.task_count,
    getAmendmentAssignmentSuccess:(state) => state.successAssignment,
    getUpdatedAmendmentTaskStatus: (state) => state.amendment_task_success,
    getUpdatedAmendmentTaskStatusError: (state) => state.amendment_status_error,
    getAmendmentError: (state) => state.error,
    getAmendmentStatisticsError: (state) => state.amendment_stats_error,
    getAmendmentDetailsError: (state) => state.amendment_error,
};

const actions = {
    async submitAmendmentApplicationPayment({commit}, data) {
        await api()
            .post("/business-registration/filing/complete", data)
            .then(response => {
                commit("setNewAmendmentTask", response.data);
            })
            .catch(error => {
                commit("setAmendmentTaskError", error.response);
            });
    },
    fetchAmendmentSubmissions({commit}, data){
        let path = "/business-registration/filing/?page="+data.page+"&per_page="+data.limit+"&status="+data.statuses
        if(data.search !== ""){
            path+="&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setAmendmentTasks', response.data);
        }).catch(error => {
            commit("setAmendmentError", error.response)
        });
    },
    fetchAmendmentQueueCount({commit}){
        api().get("/business-registration/filing/task/count").
        then(response => {
            commit('setAmendmentTaskCount', response.data);
        }).catch(error => {
            commit("setAmendmentError", error.response)
        });
    },
    fetchAmendmentTask({commit}){
        api().get("/business-registration/filing/task/next").
        then(response => {
            commit('setNewAmendmentTask', response.data);
        }).catch(error => {
            commit("setAmendmentTaskError", error.response)
        });
    },
    prefillAmendmentEscalationTask({commit},data){
            commit('setNewAmendmentTask', data);
    },
    fetchAmendmentDetails({commit}, trackingNumber){
        api().get("/business-registration/filing/filing-details?trackingNumber="+trackingNumber).
        then(response => {
            commit('setAmendmentDetails', response.data);
        }).catch(error => {
            commit("setAmendmentDetailsError", error.response)
        });
    },
    fetchAmendmentStatistics({commit}){
        api().get("/business-registration/filing/basic-statistics").
        then(response => {
            commit('setAmendmentStatistics', response.data);
        }).catch(error => {
            commit("setAmendmentStatisticsError", error.response)
        });
    },
    fetchAmendmentEscalations({commit}, data){
        let path = "/business-registration/filing/escalation?page="+data.page+"&perPage="+data.limit+"&status="+data.status
        if(data.search !== ""){
            path+="&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setAmendmentEscalations', response.data);
        }).catch(error => {
            commit("setAmendmentError", error.response)
        });
    },
    updateAmendmentTaskStatus({commit}, data){
        api().post("/business-registration/filing/task/status", data).
        then(response => {
            commit('setUpdateAmendmentStatusSuccess', response.data);
        }).catch(error => {
            commit("setUpdateAmendmentStatusError", error.response)
        });
    },
    fetchAmendmentStatuses({commit}, id){
        api().get("/business-registration/filing/task/status?filingApplicationId="+id).
        then(response => {
            commit('setAmendmentStatuses', response.data);
        }).catch(error => {
            commit("setAmendmentError", error.response)
        });
    },

};

const mutations = {
    setAmendmentTasks: (state, response) =>{
        state.amendment_tasks = response;
    },
    setNewAmendmentTask: (state, response) =>{
        state.amendment_task = response;
    },
    setAmendmentDetails: (state, response) =>{
        state.amendment_details = response;
    },
    setAmendmentDetailsError: (state, response) =>{
        state.amendment_error = response;
    },
    setUpdateAmendmentStatusSuccess: (state, response) =>{
        state.amendment_task_success = response.data;
    },
    setUpdateAmendmentStatusError: (state, response) =>{
        state.amendment_status_error = response.data;
    },
    setAmendmentStatuses: (state, response) =>{
        state.amendment_messages = response;
    },
    setAmendmentStatistics: (state, response) =>{
        state.amendment_stats = response;
    },
    setAmendmentStatisticsError: (state, response) =>{
        state.amendment_stats_error = response.data;
    },
    setAmendmentEscalations: (state, response) =>{
        state.amendment_escalations = response;
    },
    setAmendmentTaskCount: (state, response) =>{
        state.task_count = response;
    },
    setAmendmentError: (state, response) =>{
        state.error = response;
    },
    setAmendmentTaskError: (state, response) =>{
        state.amendment_task_error = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};