import {api} from "@/app/services/api.service";

const state = {
    legalDocuments:null,
    escalations:[],
    legalDocumentStatuses:[],
    legalDocument:null,
    legal_document_stats:{
        pending:0,
        rejected:0,
        assigned:0,
        total:0,
        approved:0,
        queried:0
    },
    legal_document_count:{},
    success:{},
    successAssignment:{},
    successEscalation:{},
    errorAssignment:{},
    errorStatusUpdate:{},
    error:{},
    legalDocumentDetails:null,
    legalDocumentDetailsError:null,
};

const getters = {
    getLegalDocumentSubmissions: (state) => state.legalDocuments,
    getLegalDocumentDetailsBox: (state) => state.legalDocumentDetails,
    getLegalDocumentDetailsErrorBox: (state) => state.legalDocumentDetailsError,
    getLegalDocumentEscalations: (state) => state.escalations,
    getLegalDocumentTask: (state) => state.legalDocument,
    getLegalDocumentTaskError:(state) => state.error,
    getLegalDocumentStatistics: (state) => state.legal_document_stats,
    getLegalDocumentStatuses: (state) => state.legalDocumentStatuses,
    getLegalDocumentCount: (state) => state.legal_document_count,
    getLegalDocumentSuccess:(state) => state.success,
    getLegalDocumentEscalationSuccess:(state) => state.successEscalation,
    getLegalDocumentAssignmentSuccess:(state) => state.successAssignment,
    getLegalDocumentAssignmentError:(state) => state.errorAssignment,
    getLegalDocumentUpdateStatusError:(state) => state.errorStatusUpdate,
    getLegalDocumentError:(state) => state.error,
    getLegalDocumentEscalationError:(state) => state.error,
};

const actions = {
    async submitLegalDocumentApplicationPayment({ commit },data) {
        await api().post("legal-documents/complete",data).then(response => {
            commit("setLegalDocument", response);
        }).catch(error => {
            commit("setLegalDocumentDetailsError", error.response);
        });

    },
    async fetchLegalDocumentSubmissions({commit}, data){
        let path = "/legal-documents?status="+data.statuses+"&page="+data.page+"&per_page="+data.limit
        if(data.search != ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setLegalDocuments', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    async fetchLegalDocumentEscalations({commit}, data){
        api().get("/legal-documents/escalation?status="+data.status+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setLegalDocumentEscalations', response.data);
        }).catch(error => {
            commit("setLegalDocumentEscalationError", error.response)
        });
    },
    async fetchLegalDocumentStatuses({commit}, id){
        api().get("/legal-documents/task/status?documentId="+id).
        then(response => {
            commit('setLegalDocumentStatuses', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    async fetchLegalDocumentDetailsBox({commit}, trackingNumber){
        api().get("/legal-documents/details?trackingNumber="+trackingNumber).
        then(response => {
            commit('setLegalDocumentDetails', response.data);
        }).catch(error => {
            commit("setLegalDocumentDetailsError", error.response)
        });
    },
    async fetchNextLegalDocument({commit}){
        commit('setLegalDocumentToNull');
        api().get("/legal-documents/task/next").
        then(response => {
            commit('setLegalDocument', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    async fetchLegalDocumentStats({commit}){
        api().get("/legal-documents/basic-statistics").
        then(response => {
            commit('setLegalDocumentStats', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    async fetchLegalDocumentCount({commit}){
        api().get("/legal-documents/task/count").
        then(response => {
            commit('setLegalDocumentCount', response);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    updateLegalDocumentStatus({commit}, data){
        api().post("/legal-documents/task/status", data).
        then(response => {
            console.log("DATA RETURNED")
            commit('setLegalDocumentAction', response.data);
        }).catch(error => {
            commit("setLegalDocumentDecisionError", error.response)
        });
    },
    assignLegalDocument({commit}, data){
        api().post("/legal-documents/task", data).
        then(response => {
            commit('setLegalDocumentAssignmentAction', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    assignEscalationLegalDocumentTask({commit}, data){
        api().post("/legal-documents/escalation/assign", data).
        then(response => {
            commit('setLegalDocumentAssignmentAction', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    escalateLegalDocumentTask({commit}, data){
        api().post("/legal-documents/escalation", data).
        then(response => {
            commit('setLegalDocumentEscalationAction', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
    reEscalateLegalDocumentTask({commit}, data){
        api().post("/legal-documents/escalation/re", data).
        then(response => {
            commit('setLegalDocumentEscalationAction', response.data);
        }).catch(error => {
            commit("setLegalDocumentError", error.response)
        });
    },
};

const mutations = {
    setLegalDocuments: (state, response) =>{
        state.legalDocuments = response;
    },
    setLegalDocumentEscalations: (state, response) =>{
        state.escalations = response;
    },
    setLegalDocument: (state, response) =>{
        state.legalDocument = response.data;
    },
    setLegalDocumentToNull: (state) =>{
        state.legalDocument = null;
    },
    setLegalDocumentStatuses: (state, response) =>{
        state.legalDocumentStatuses = response;
    },
    setLegalDocumentDetails: (state, response) =>{
        state.legalDocumentDetails = response;
    },
    setLegalDocumentDetailsError: (state, response) =>{
        state.legalDocumentDetailsError = response;
    },
    setLegalDocumentStats: (state, response) =>{
        state.legal_document_stats = response;
    },
    setLegalDocumentCount: (state, response) =>{
        state.legal_document_count = response.data;
    },
    setLegalDocumentAction: (state, response) =>{
        state.success = response;
    },
    setLegalDocumentAssignmentAction: (state, response) =>{
        state.successAssignment = response;
    },
    setLegalDocumentEscalationAction: (state, response) =>{
        state.successEscalation = response;
    },
    setLegalDocumentError: (state, error) =>{
        state.error = error;
    },
    setLegalDocumentEscalationError: (state, error) =>{
        state.error = error;
    },
    setLegalDocumentAssignmentError: (state, error) =>{
        state.errorAssignment = error;
    },
    setLegalDocumentDecisionError: (state, error) =>{
        state.errorStatusUpdate = error;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};