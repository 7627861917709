<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-assign" size="xl" hide-footer ref="escalate-details" title="Escalate Incorporation">
        <BuildingSocietyEscalationFile :application="application" :source="source" :source-id="sourceId"
                                     :escalation-id="escalationId" :message="message"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Building Societies Escalations</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <div class="mx-auto">
          <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">Tracking No.</th>
                <th class="t-header">Name</th>
                <th class="t-header">Sub Entity Type</th>
                <th class="t-header">Date</th>
                <th class="t-header text-center">Escalated By</th>
                <th class="t-header text-center">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
                <td class="t-body text-blue font-bold">{{submission.task.data.trackingNo}}</td>
                <td class="t-body font-bold">{{submission.task.data.name}}</td>
                <td class="t-body text-capitalize">
              <span v-if="submission.task.data.subType != null">
                {{cleanEntityType(submission.task.data.subType)}}
              </span>
                </td>
                <td class="t-body">
                  {{dateFormat(submission.task.createdAt)}}
                </td>
                <td class="t-body text-center">
                  <span class="text-blue text-xs">{{submission.source}}</span>
                </td>
                <td class="t-body text-center">
                  <div class="flex">
                    <button @click="view(submission)" class="view-btn w-full">View</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td v-if="submissions.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
                  <span class="text-sm">No results found</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col class="9"></b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex"
import router from "@/router";
import moment from "moment";
import BuildingSocietyEscalationFile
  from "@/views/home/services/bsi_incorporation/escalations/BuildingSocietyEscalationFile";

export default {
  name: "BuildingSocietyIncorporationEscalations",
  components: {BuildingSocietyEscalationFile},
  data(){
    return{
      loading:false,
      currentPage:1,
      limit:6,
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      submissions:[],
      assignForm:{
        taskId:null,
        toUserId:null
      },
      deAssignForm:{
        reservationId:null,
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      search:"",
      escalationId:null,
      application:null,
      message:null,
      source:null,
      sourceId:null
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchBSEscalations"]),
    open(trackingNumber){
      let route = router.resolve("/submission/reservation/"+trackingNumber)
      window.open(route.href, '_blank');
    },
    fetch(){
      let data = {
        status:"ESCALATED",
        page:this.currentPage,
        limit:this.limit
      }
      this.loading = true
      this.fetchBSEscalations(data)
    },
    view(submission){
      this.application = submission.task
      this.source = submission.source
      this.sourceId = submission.sourceId
      this.escalationId = submission.taskId
      this.message = submission.description
      this.$refs["escalate-details"].show()
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    filterPendingReservation(){

    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
  computed:{
    ...mapGetters(["getBSIncorporationEscalations", "getRegistrars", "getBSIncorporationsError", "getAssignmentSuccess", "getAssignmentError",
    "getDeAssignmentSuccess", "getDeAssignmentSuccess"])
  },
  watch:{
    getBSIncorporationEscalations(data){
      this.loading = false
      this.submissions = data.escalations
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getBSIncorporationsError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    },
    getAssignmentSuccess(){
      this.successToast("Success", "Assignment has been completed")
      this.$refs.assignSubmissions.hide();
      this.fetch()
    },
    getAssignmentError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    },
    getDeAssignmentSuccess(){
      this.successToast("Success", "De-assignment has been completed")
      this.$refs.assignSubmissions.hide();
      this.fetch()
    },
    getDeAssignmentError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data.message))
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.verified{
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified{
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>