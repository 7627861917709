<template>
  <div class="mb-10">
    <b-row>
      <b-col>
        <b-card :header="badge">
          <b-row class="my-2">
            <b-col cols="12">
              <b-form-group
                id="input-group-1"
                label="Effective Date of Appointment"
                label-for="street">
                <b-form-input
                  id="street"
                  class="form-text mb-3 noFocus"
                  type="text"
                  v-model="data.effectiveDateOfAppointment"
                  :disabled="true"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <span class="h6">PARTICULARS OF DIRECTORS</span>
            </b-col>
          </b-row>

          <b-row v-for="(d, idx) in data.directors" :key="idx">
            <b-col>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="12" v-if="d.entityType === 'CORPORATE'">
                      <b-row>
                        <b-col>
                          <b-badge class="bg-blue">corporate</b-badge>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-row>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Entity Name"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Registration Number (BRN)"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.brn"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Official Email Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.primaryEmail"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Official Phone Number"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.primaryPhone"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Date of Incorporation"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.dateOfIncorporation"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Company Type"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus text-capitalize"
                                  type="text"
                                  :disabled="true"
                                  :value="cleanEntityType(d.corporate.subType)"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Country of Origin"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.countryOfOrigin"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Address in Country of Origin"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.addressInCountryOfIncorporation"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col v-if="d.corporate.registeredAddress">
                              <b-form-group
                                v-if="d.corporate.registeredAddress.physicalAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="District"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.physicalAddress.district.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.corporate.registeredAddress.physicalAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Region"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.physicalAddress.region.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.corporate.registeredAddress.physicalAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Sub county"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.physicalAddress.subcounty.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.corporate.registeredAddress.physicalAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Parish"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.physicalAddress.parish.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.corporate.registeredAddress.physicalAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Village"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.physicalAddress.village.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col>

                              <b-form-group
                                v-if="d.corporate.registeredAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Postal Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.postalAddress"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.corporate.registeredAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Plot"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.plot"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.corporate.registeredAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Street"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.corporate.registeredAddress.street"
                                  required>
                                </b-form-input>
                              </b-form-group>

                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col cols="12" v-if="d.entityType === 'INDIVIDUAL'">
                      <b-row>
                        <b-col>
                          <b-badge class="bg-orange">individual</b-badge>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-row>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Given Name"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.givenName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.individual.middleName"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Middle Name"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.middleName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Surname"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.surName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="ID Type"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus text-capitalize"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.idType.toLowerCase()"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="ID Number"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.idNumber"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Nationality"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.nationality"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Email Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.email"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Phone Number"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.phoneNumber"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Gender (at birth)"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus text-capitalize"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.sex.toLowerCase()"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Date of Birth"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="dateFormat(d.individual.dateOfBirth)"
                                  required>
                                </b-form-input>
                                <b-form-invalid-feedback state="true">
                                  {{d.individual.age}}
                                </b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Occupation"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.occupation"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col v-if="d.individual.address.physicalAddress">
                              <b-form-group
                                v-if="d.individual.address.physicalAddress.district"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="District"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.physicalAddress.district.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.individual.address.physicalAddress.district"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Region"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.physicalAddress.region.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.individual.address.physicalAddress.subcounty"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Sub county"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.physicalAddress.subcounty.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.individual.address.physicalAddress.parish"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Parish"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.physicalAddress.parish.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.individual.address.physicalAddress.village"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Village"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.physicalAddress.village.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="d.individual.foreignPhysicalAddress">
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Foreign Address"
                                label-for="input-1">
                                <b-form-textarea
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  rows="4"
                                  :disabled="true"
                                  :value="d.individual.foreignPhysicalAddress"
                                  required>
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                v-if="d.individual.address.postalAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Postal Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.postalAddress"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.individual.address.street"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Street"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.street"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="d.individual.address.plot"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Plot"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="d.individual.address.plot"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col>
              <span class="h6">PARTICULARS OF SECRETARY</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="12" v-if="data.secretary.entityType === 'CORPORATE'">
                      <b-row>
                        <b-col>
                          <b-badge class="bg-blue">corporate</b-badge>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-row>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Entity Name"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Registration Number (BRN)"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.brn"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Official Email Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.primaryEmail"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Official Phone Number"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.primaryPhone"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Date of Incorporation"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.dateOfIncorporation"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Company Type"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus text-capitalize"
                                  type="text"
                                  :disabled="true"
                                  :value="cleanEntityType(data.secretary.corporate.subType)"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Country of Origin"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.countryOfOrigin"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Address in Country of Origin"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.addressInCountryOfIncorporation"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-if="data.secretary.corporate.registeredAddress">
                            <b-col>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress.physicalAddress.district"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="District"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.physicalAddress.district.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress.physicalAddress.region"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Region"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.physicalAddress.region.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress.physicalAddress.subcounty"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Sub county"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.physicalAddress.subcounty.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress.physicalAddress.parish"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Parish"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.physicalAddress.parish.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress.physicalAddress.village"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Village"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.physicalAddress.village.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Postal Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.postalAddress"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Plot"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.plot"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.corporate.registeredAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Street"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.corporate.registeredAddress.street"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>

                    <b-col cols="12" v-if="data.secretary.entityType === 'INDIVIDUAL'">
                      <b-row>
                        <b-col>
                          <b-badge class="bg-orange">individual</b-badge>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-row>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Given Name"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.givenName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.individual.middleName"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Middle Name"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.middleName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Surname"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.surName"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="ID Type"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus text-capitalize"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.idType.toLowerCase()"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="ID Number"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.idNumber"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Nationality"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.nationality"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Email Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.email"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Phone Number"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.phoneNumber"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Gender (at birth)"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus text-capitalize"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.sex.toLowerCase()"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Date of Birth"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="dateFormat(data.secretary.individual.dateOfBirth)"
                                  required>
                                </b-form-input>
                                <b-form-invalid-feedback state="true">
                                  {{data.secretary.individual.age}}
                                </b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Occupation"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.occupation"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col  v-if="data.secretary.individual.address.physicalAddress">
                              <b-form-group
                                v-if="data.secretary.individual.address.physicalAddress.district"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="District"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.physicalAddress.district.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.individual.address.physicalAddress.district"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Region"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.physicalAddress.region.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.individual.address.physicalAddress.subcounty"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Sub county"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.physicalAddress.subcounty.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.individual.address.physicalAddress.parish"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Parish"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.physicalAddress.parish.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.individual.address.physicalAddress.village"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Village"
                                label-for="input-1">
                                <b-form-input
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.physicalAddress.village.name"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="data.secretary.individual.foreignPhysicalAddress">
                              <b-form-group
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Foreign Address"
                                label-for="input-1">
                                <b-form-textarea
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  rows="4"
                                  :disabled="true"
                                  :value="data.secretary.individual.foreignPhysicalAddress"
                                  required>
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                v-if="data.secretary.individual.address.postalAddress"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Postal Address"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.postalAddress"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.individual.address.street"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Street"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.street"
                                  required>
                                </b-form-input>
                              </b-form-group>
                              <b-form-group
                                v-if="data.secretary.individual.address.plot"
                                class="text-sm my-1"
                                id="input-group-1"
                                label="Plot"
                                label-for="input-1">
                                <b-form-input
                                  id="companyName"
                                  class="form-text text-sm noFocus"
                                  type="text"
                                  :disabled="true"
                                  :value="data.secretary.individual.address.plot"
                                  required>
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>

import moment from "moment/moment";

export default {
  name: "Form20Resolution",
  props:{
    data:{},
    badge:String
  },
  data(){
    return{
      form:{
        effectiveDateOfAppointment:null
      }
    }
  },
  mounted() {

  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  watch:{

  }
}
</script>

<style scoped>
.button{
  @apply bg-blue px-2 py-1 rounded-sm text-white;
}

.button {
  @apply w-full bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}


.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}
</style>