<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="12">
        <b-row v-if="getRegistrationTask != null">
          <b-col cols="12">
            <b-row>
              <b-col cols="12" class="mt-4">
                <table class="min-w-full leading-normal mt-1 text-gray"
                       style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Partners</td>
                  </tr>
                  </thead>
                  <tbody>
                  <LegalPersonsPartner class="p-1" :individuals="getRegistrationTask.individualPartners"
                                       :corporates="getRegistrationTask.corporatePartners" />
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-3">
                <table class="min-w-full leading-normal mt-1 text-gray"
                       style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Business Address</td>
                  </tr>
                  </thead>
                  <tbody v-if="getRegistrationTask.physicalAddress != null">
                  <b-row class="mx-1 py-1">
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Region"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.physicalAddress.region.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="District"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.physicalAddress.district.name.toLowerCase()"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Sub County"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.physicalAddress.subcounty.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Parish"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.physicalAddress.parish.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Village"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.physicalAddress.village.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Street"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.street"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Plot"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.plot"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Postal Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="getRegistrationTask.postalAddress"
                          required>
                        </b-form-input>
                        <PostalAddressValidator v-if="getRegistrationTask.postalAddress"
                                                :box-number="getRegistrationTask.postalAddress" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row v-if="getRegistrationTask.beneficialOwnerFormResponse">
              <b-col>
                <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Beneficial Owners</td>
                  </tr>
                  </thead>
                  <tbody v-if="getRegistrationTask.beneficialOwnerFormResponse">
                  <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Country</td>
                    <td class="t-body">
                      {{ getRegistrationTask.beneficialOwnerFormResponse.country.name }}
                    </td>
                  </tr>
                  <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Physical Address</td>
                    <td class="t-body text-uppercase">
                      <p v-if="getRegistrationTask.beneficialOwnerFormResponse.village">
                        <span
                          class="mr-1">{{ getRegistrationTask.beneficialOwnerFormResponse.village.region.name }} > </span>
                        <span
                          class="mx-1">{{ getRegistrationTask.beneficialOwnerFormResponse.village.district.name }} > </span>
                        <span class="mx-1">{{ getRegistrationTask.beneficialOwnerFormResponse.village.subcounty.name }} > </span>
                        <span
                          class="mx-1">{{ getRegistrationTask.beneficialOwnerFormResponse.village.parish.name }} > </span>
                        <span
                          class="mx-1">{{ getRegistrationTask.beneficialOwnerFormResponse.village.village.name }}</span>
                      </p>
                      <p v-else>N/A</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                  <tbody v-if="getRegistrationTask.beneficialOwnerFormResponse">
                  <tr>
                    <td colspan="12">
                      <BeneficialOwners :persons="getRegistrationTask.beneficialOwnerFormResponse.beneficialOwners" />
                    </td>
                  </tr>
                  </tbody>
                </table>
                <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                  <tbody v-for="(entity, idx) in getRegistrationTask.beneficialOwnerFormResponse.entityBeneficialOwners"
                         :key="idx">
                  <tr class="px-2">
                    <td class="t-body font-bold">
                      Registration Number
                    </td>
                    <td class="t-body">
                      {{entity.brn}}
                    </td>
                  </tr>
                  <tr class="px-2">
                    <td class="t-body font-bold">
                      Entity Name
                    </td>
                    <td class="t-body">
                      {{entity.companyName}}
                    </td>
                  </tr>
                  <tr class="px-2">
                    <td class="t-body font-bold">
                      Entity Type
                    </td>
                    <td class="t-body">
                      {{cleanEntityType(entity.subType)}}
                    </td>
                  </tr>
                  <tr class="px-2">
                    <td class="t-body font-bold">
                      Percentage Shares
                    </td>
                    <td class="t-body">
                      {{entity.percentageShares}}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="12">
                      <BeneficialOwners :persons="entity.entityIndividualBeneficialOwners" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="mt-4">
                <table class="min-w-full leading-normal mt-1 text-blue-dark"
                       style="border: solid 1px #bdd7f1;">
                  <thead>
                  <tr class="bg-blue-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1">Email</th>
                    <th class="px-2 py-1">Phone</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #bdd7f1;">
                    <td class="px-2 py-1 text-capitalize">{{ getRegistrationTask.user.firstName.toLowerCase() }}
                      {{ getRegistrationTask.user.lastName.toLowerCase() }}
                    </td>
                    <td class="px-2 py-1">{{ getRegistrationTask.user.email }}</td>
                    <td class="px-2 py-1">{{ getRegistrationTask.user.phoneNumber }}</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col cols="6" class="mt-4">
                <table class="min-w-full leading-normal mt-1 text-blue-dark"
                       style="border: solid 1px #bdd7f1;">
                  <thead>
                  <tr class="bg-blue-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Official Account Details (To be Created)</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Email</th>
                    <th class="px-2 py-1">Phone</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #bdd7f1;">
                    <td class="px-2 py-1">
                          <span class="text-blue">
                            {{ getRegistrationTask.email }}
                          </span>
                    </td>
                    <td class="px-2 py-1">
                          <span class="text-blue">
                            {{ getRegistrationTask.phone }}
                          </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import LegalPersonsPartner from "@/views/home/commons/LegalPersonsPartner";
import PostalAddressValidator from "@/views/home/commons/PostalAddressValidator";
import BeneficialOwners from "@/views/home/commons/BeneficialOwners.vue";

export default {
  name: "BusinessNameFormDetails",
  components: { BeneficialOwners, PostalAddressValidator, LegalPersonsPartner },
  data() {
    return {
      attachmentUrl: null
    };
  },
  methods: {
    ...mapActions(["fetchAddressByVillageId"]),
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    cleanName(name) {
      let n = name.replaceAll("_", " ");
      return n.toLowerCase();
    }
  },
  computed: {
    ...mapGetters(["getRegistrationTask", "getAddress"])
  },
  watch: {
    getRegistrationTask(data) {
      let file = data.attachments[0];
      this.openFile(file);
    },
    attachmentUrl: {
      handler(url) {
        this.$refs.attachmentScreen.openFile(url);
      },
      deep: true
    }
  }
};
</script>

<style scoped>

th, td {
  font-size: 12px;
}

</style>