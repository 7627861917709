<template>
  <b-row>
    <b-col class="mx-auto">
      <form>
        <b-form-input class="text-sm my-1 font-bold" :disabled="true" v-model="form.trackingNumber"/>
        <b-form-input class="text-sm mt-1" placeholder="Enter PRN" v-model="form.prn" v-on:keydown.enter="checkPRN"/>
        <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        <p v-if="paymentInfo.statusCode !== 'WW'" class="my-2 border border-gray rounded-sm p-2 text-xs">
          <span>PRN: {{paymentInfo.prn}}</span><br/>
          <span v-if="paymentInfo.amountPaid">Amount: {{Number(paymentInfo.amountPaid).toLocaleString()}}</span>
          <span v-else>Amount: 0</span><br/>
          <span>Tax Head: {{paymentInfo.taxHeadCode}}</span><br/>
          <span>Tax Payer: {{paymentInfo.taxHeadName}}</span><br/>
          <span>Status: {{paymentInfo.statusDesc}}</span><br/>
        </p>
        <button class="mt-3 w-full bg-blue px-2 py-1 rounded-sm text-white" type="button"
                @click="save" v-if="paymentInfo.statusCode === 'T'">
          Save <b-spinner v-if="loadingSave" small></b-spinner>
        </button>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddPRN",
  props:{
    trackingNumber:String
  },
  data(){
    return{
      form:{
        trackingNumber:null,
        prn:null,
        userId:null
      },
      paymentInfo: {
        statusCode:"WW"
      },
      loading: false,
      loadingSave:false
    }
  },
  mounted() {
    this.form.trackingNumber = this.trackingNumber
  },
  methods:{
    ...mapActions(["fetchPRNDetails", "addPRNToBilling"]),
    save(){
      this.loadingSave = true
      this.form.userId = JSON.parse(sessionStorage.getItem("current_user")).userId
      this.addPRNToBilling(this.form)
    },
    checkPRN(){
      this.loading = true
      this.fetchPRNDetails({prn: this.form.prn})
    }
  },
  computed:{
    ...mapGetters(["getPRNDetails", "getPRNDetailsError", "getPRNAdded", "getPRNAddedError"])
  },
  watch:{
    getPRNDetails(data){
      this.loading = false
      this.paymentInfo = data
    },
    getPRNDetailsError(data){
      this.loading = false
      this.errorToast("Error", data.data.error)
    },
    getPRNAdded(){
      this.loadingSave = false
      this.successToast("Success", "PRN added to tracking number successfully")
    },
    getPRNAddedError(data){
      this.loadingSave = false
      this.errorToast("Error", data.data.message)
    }
  }

};
</script>

<style scoped>

</style>