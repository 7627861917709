<template>
    <div>
        <div class="bg-white rounded-sm m-2">
            <b-row>
                <b-col cols="12" class="m-2">
                    <b-row>
                        <b-col cols="6">
              <span class="text-xs px-2 py-1 text-red">
                <b>{{ getDisputeResolutionCount.pending }}</b> Total Submissions
              </span>
                            <span class="text-xs px-2 py-1 text-blue ml-2">
                <b>{{ getDisputeResolutionCount.assigned }}</b> Assigned
              </span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
        <div class="bg-white rounded-sm m-2">
            <b-row>
                <b-col cols="12">
                    <b-row v-if="getDisputeResolutionTask.data == null" class="mx-1 py-5">
                        <b-col cols="12" class="py-5">
                            <b-row>
                                <b-col cols="12" class="text-center justify-content-center">
                                    <img :src="require('../../../../../assets/images/ic_empty_box.png')"
                                         class="icon mx-auto">
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col cols="12" class="text-center">
                                    <div class="h5 text-gray mx-auto">There are no Filings left in the queue</div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" class="text-center">
                                    <div class="text-gray mx-auto">Tasks will be available when clients submit new <b
                                            class="text-blue">applications</b> or when <b
                                            class="text-orange">queries</b> are responded to.
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col cols="12" class="text-center">
                                    <button class="text-white bg-blue rounded-md px-2 py-2 mx-auto hover:bg-blue-dark"
                                            @click="checkForUpdates">Check for Updates
                                    </button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col cols="12">
                            <DisputeResolutionTask/>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import DisputeResolutionTask from "@/views/home/services/dispute-resolution/task/DisputeResolutionTask.vue";

export default {
    name: "DisputeResolutionTaskQueue",
    components: {DisputeResolutionTask},
    data() {
        return {
            margin: "2px",
            loading: true,
            message: null,
        }
    },
    mounted() {
        let path = this.$route.path;

        if (path.includes("official-account-queue-task")) {
            this.fetchNextDisputeResolution("?type=OFFICIAL_ACCOUNT")
        } else if(path.includes("insolvency")){
            this.fetchNextDisputeResolution("?type=INSOLVENCY_PETITION")
        } else {
            this.fetchNextDisputeResolution()
        }

        this.fetchDisputeResolutionCount()
    },
    methods: {
        ...mapActions(["fetchNextDisputeResolution", "fetchDisputeResolutionCount"]),
        checkForUpdates() {
            this.loading = true
            window.location.reload()
        },
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        }
    },
    computed: {
        ...mapGetters(["getDisputeResolutionTask", "getDisputeResolutionCount"]),
    },
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.title {
    font-size: 22px;
}

.icon {
    width: 170px;
}

.btn-escalate {
    @apply border bg-orange rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover {
    @apply bg-orange-dark;
}

.btn-approve {
    @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query {
    @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject {
    @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover {
    @apply bg-green border-green text-white;
}

.btn-query:hover {
    @apply bg-orange border-orange text-white;
}

.btn-reject:hover {
    @apply bg-red border-red text-white;
}

.btn-approve-hover {
    @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover {
    @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover {
    @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover {
    @apply bg-green-dark;
}

.btn-query-hover:hover {
    @apply bg-orange-dark;
}

.btn-reject-hover:hover {
    @apply bg-red-dark;
}
</style>