import {api} from "@/app/services/api.service";

const state = {
    edmsFile: null,
    edmsFileError: null,
    certificationQuery: null,
    certificationQueryError: null,
    certification:{},
    preview:null,
    task_count:{},
    task_thread:{},
    certifications:null,
    certification_escalations:null,
    certification_escalation:null,
    certification_details:null,
    certification_details_error:null,
    escalation_action_success:null,
    escalation_action_error:null,
    assignment_action_success:null,
    assignment_action_error:null,
    certification_resent:null,
    certification_resend_error:null,
    certification_payment_removed:null,
    certification_payment_removed_error:null,
    certification_escalation_details:null,
    certification_escalation_details_error:null,
    error:null,
    updateSuccess:null,
    updateError:null,
    success:{},
    certification_stats:{
        draft:0,
        pending:0,
        approved:0,
        total:0
    },
};

const getters = {
    getEdmsFilePreview: (state) => state.edmsFile,
    getEdmsFilePreviewError: (state) => state.edmsFileError,
    getCertificationQuery: (state) => state.certificationQuery,
    getCertificationQueryError: (state) => state.certificationQueryError,
    getCertificationSubmissions: (state) => state.certifications,
    getCertificationEscalations: (state) => state.certification_escalations,
    getCertificationEscalation: (state) => state.certification_escalation,
    getCertificationResentSuccess: (state) => state.certification_resent,
    getCertificationResendError: (state) => state.certification_resend_error,
    getCertificationPaymentRemovedSuccess: (state) => state.certification_payment_removed,
    getCertificationPaymentRemovedError: (state) => state.certification_payment_removed_error,
    getCertificationTaskCount: (state) => state.task_count,
    getCertificationStatistics: (state) => state.certification_stats,
    getCertificationTaskMessages: (state) => state.task_thread,
    getCertificationTask: (state) => state.certification,
    getCertificationEscalationActionSuccess: (state) => state.escalation_action_success,
    getCertificationEscalationActionError: (state) => state.escalation_action_error,
    getCertificationAssignmentActionSuccess: (state) => state.assignment_action_success,
    getCertificationAssignmentActionError: (state) => state.assignment_action_error,
    getCertificationsError: (state) => state.error,
    getCertificationUpdateStatusSuccess: (state) => state.updateSuccess,
    getCertificationUpdateStatusError: (state) => state.updateError,
    getCertificationDetails: (state) => state.certification_details,
    getCertificationEscalationDetails: (state) => state.certification_escalation_details,
    getCertificationEscalationDetailsError: (state) => state.certification_escalation_details_error,
};

const actions = {
    async fetchCertificationTasks({commit}, data){
        let path = "/record-search/certifications/all?status="+data.status+"&page="+data.page+"&perPage="+data.limit
        if(data.trackingNumber !== null){
            path += "&trackingNumber="+data.trackingNumber
        }
        if(data.brn !== null){
            path += "&brn="+data.brn
        }
        api().get(path).
        then(response => {
            commit('setCertifications', response.data);
        }).catch(error => {
            commit("setCertificationError", error.response)
        });
    },
    async fetchCertificationTask({commit}, id){
        api().get("/record-search/queue/next-task/"+id).
        then(response => {
            commit('setCertification', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setCertificationError", error.response)
        });
    },
    async fetchCertificationEscalations({commit}, data){
        api().get("/record-search/escalations?page="+data.page+"&perPage="+data.limit+"&status=PENDING").
        then(response => {
            commit('setCertificationEscalations', response.data);
        }).catch(error => {
            commit("setCertificationError", error.response)
        });
    },
    async fetchCertificationEscalation({commit}, id){
        api().get("/record-search/escalation/"+id).
        then(response => {
            commit('setCertificationEscalation', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setCertificationError", error.response)
        });
    },
    async fetchCertificationTaskCount({commit}, id){
        api().get("/record-search/queue/tasks/count/"+id).
        then(response => {
            commit('setCertificationTaskCount', response.data);
        }).catch(error => {
            commit("setCertificationError", error.response)
        });
    },
    async fetchCertificationStatistics({commit}){
        api().get("/record-search/search-certification/summary").
        then(response => {
            commit('setCertificationStatistics', response.data);
        }).catch(error => {
            commit("setCertificationError", error.response)
        });
    },
    async fetchCertificationTaskMessages({commit}, id){
        api().get("/record-search/queue/task/"+id+"/thread").
        then(response => {
            commit('setCertificationTaskMessages', response.data);
        }).catch(error => {
            commit("setCertificationError", error.response)
        });
    },
    async updateCertificationStatus({commit}, data){
        api().post("/record-search/queue/tasks/update-status", data).
        then(response => {
            commit('setCertificationUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setCertificationUpdateStatusError", error.response)
        });
    },
    async queryCertification({commit}, data){
        api().post("/record-search/query/certification", data).
        then(response => {
            commit('setCertificationQuery', response.data);
        }).catch(error => {
            commit("setCertificationQueryError", error.response)
        });
    },
    async fetchCertificationDetails({commit}, trackingNumber){
        api().get("/record-search/search-certification/by-tracking-no/"+trackingNumber).
        then(response => {
            commit('setCertificationDetails', response.data);
        }).catch(error => {
            commit("setCertificationDetailsError", error.response)
        });
    },
    async fetchCertificationEscalationDetails({commit}, id){
        api().get("/record-search/escalation/by-id/"+id).
        then(response => {
            commit('setCertificationEscalationDetails', response.data);
        }).catch(error => {
            commit("setCertificationEscalationDetailsError", error.response)
        });
    },
    resendCertificationDocuments({commit}, data){
        api().post("/record-search/certification/resend-copies", data).
        then(response => {
            commit('setCertificationResent', response.data);
        }).catch(error => {
            commit("setCertificationResendError", error.response)
        });
    },
    removeCertificationPayment({commit}, data){
        api().post("/record-search/remove-payment", data).
        then(response => {
            commit("setCertificationPaymentRemoved", response.data);
        }).catch(error => {
            commit("setCertificationPaymentRemovedError", error.response)
        });
    },
    escalateCertificationTask({commit}, data){
        api().post("/record-search/escalate", data).
        then(response => {
            commit('setCertificationEscalationAction', response.data);
        }).catch(error => {
            commit("setCertificationEscalationActionError", error.response)
        });
    },
    reEscalateCertificationTask({commit}, data){
        api().post("/record-search/re-escalate", data).
        then(response => {
            commit('setCertificationEscalationAction', response.data);
        }).catch(error => {
            commit("setCertificationEscalationActionError", error.response)
        });
    },
    assignCertificationTask({commit}, data){
        api().post("/record-search/queue/assign-task", data).
        then(response => {
            commit('setCertificationAssignmentAction', response.data);
        }).catch(error => {
            commit("setCertificationAssignmentActionError", error.response)
        });
    },
    assignEscalatedCertificationTask({commit}, data){
        api().post("/record-search/escalation/assign", data).
        then(response => {
            commit('setCertificationAssignmentAction', response.data);
        }).catch(error => {
            commit("setCertificationAssignmentActionError", error.response)
        });
    },
    async fetchEdmsFileById({commit}, data ) {
        await api()
            .post("/record-search/api/edms/scan-company-files", data)
            .then((response) => {
                commit("setEdmsFile", response);
            })
            .catch((error) => {
                commit("setEdmsFileError", error.response);
            });
    },
};

const mutations = {
    setEdmsFile: (state, response) => {
        state.edmsFile = response.data;
    },
    setEdmsFileError: (state, response) => {
        state.edmsFileError = response.data;
    },
    setCertificationQuery: (state, response) => {
        state.certificationQuery = response.data;
    },
    setCertificationQueryError: (state, response) => {
        state.certificationQueryError = response.data;
    },
    setCertification: (state, response) =>{
        state.certification = response;
    },
    setCertifications: (state, response) =>{
        state.certifications = response.data;
    },
    setCertificationEscalations: (state, response) =>{
        state.certification_escalations = response;
    },
    setCertificationEscalation: (state, response) =>{
        state.certification_escalation = response;
    },
    setCertificationError: (state, response) =>{
        state.error = response;
    },
    setCertificationTaskCount: (state, response) =>{
        state.task_count = response.data;
    },
    setCertificationStatistics: (state, response) =>{
        state.certification_stats = response.data;
    },
    setCertificationTaskError: (state, response) =>{
        state.error = response;
    },
    setCertificationUpdateStatusError: (state, response) =>{
        state.updateError = response;
    },
    setCertificationUpdateStatusSuccess: (state, response) =>{
        state.updateSuccess = response;
    },
    setCertificationTaskMessages: (state, response) =>{
        state.task_thread = response.data;
    },
    setCertificationDetails:(state, response) =>{
        state.certification_details = response.data
    },
    setCertificationDetailsError:(state, response) =>{
        state.certification_details_error = response.data
    },
    setCertificationEscalationDetails:(state, response) =>{
        state.certification_escalation_details = response.data
    },
    setCertificationEscalationDetailsError:(state, response) =>{
        state.certification_escalation_details_error = response.data
    },
    setCertificationEscalationAction:(state, response)=>{
        state.escalation_action_success = response.data
    },
    setCertificationEscalationActionError:(state, response)=>{
        state.escalation_action_error = response.data
    },
    setCertificationAssignmentAction:(state, response)=>{
        state.assignment_action_success = response.data
    },
    setCertificationAssignmentActionError:(state, response)=>{
        state.assignment_action_error = response.data
    },
    setCertificationResent:(state, response)=>{
        state.certification_resent = response
    },
    setCertificationResendError:(state, response)=>{
        state.certification_resend_error = response
    },
    setCertificationPaymentRemoved:(state, response)=>{
        state.certification_payment_removed = response
    },
    setCertificationPaymentRemovedError:(state, response)=>{
        state.certification_payment_removed_error = response
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};