<template>
  <div>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <div class="flex h-full" style="cursor: pointer;">
                <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Company Information</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Attachments</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Messages</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
                <data-correction-forms :task="application" v-if="currentRoute === 'forms'" ></data-correction-forms>
              <DataCorrectionFormFilesDetails :application="application" v-if="currentRoute === 'attachments'"/>
              <DataCorrectionDetailsMessages :task="application" v-if="currentRoute === 'messages'"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import DataCorrectionFormFilesDetails
    from "@/views/home/services/data-correction/submissions/components/DataCorrectionFormFilesDetails.vue";
import DataCorrectionDetailsMessages
    from "@/views/home/services/data-correction/submissions/components/DataCorrectionDetailsMessages.vue";
import DataCorrectionForms from "@/views/home/services/data-correction/task/DataCorrectionForms.vue";
export default {
  name: "DataCorrectionPopupDetails",
  components: {DataCorrectionForms, DataCorrectionDetailsMessages, DataCorrectionFormFilesDetails},
  props:{
    application:Object
  },
  data(){
    return{
      currentRoute: "forms",
    }
  },
  methods:{
    select(route){
      this.currentRoute = route
    },
    reset(data){
      this.application = data
    }
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>