import { render, staticRenderFns } from "./PractitionerAnnualReturnsSubmissions.vue?vue&type=template&id=2bea5e89&scoped=true&"
import script from "./PractitionerAnnualReturnsSubmissions.vue?vue&type=script&lang=js&"
export * from "./PractitionerAnnualReturnsSubmissions.vue?vue&type=script&lang=js&"
import style0 from "./PractitionerAnnualReturnsSubmissions.vue?vue&type=style&index=0&id=2bea5e89&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bea5e89",
  null
  
)

export default component.exports