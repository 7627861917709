import {api} from "@/app/services/api.service";

const state = {
    incorporation:{},
    preview:null,
    task_count:{pendingTasksCount:0,registrarTasksCount:0},
    registration_stats:{},
    task_thread:{},
    incorporations:null,
    bs_incorporation_escalations:null,
    bs_incorporation_escalation:null,
    society_details:null,
    society_details_error:null,
    error:null,
    updateSuccess:null,
    updateError:null,
    success:{},

};

const getters = {
    getBSIncorporationSubmissions: (state) => state.incorporations,
    getBSIncorporationEscalations: (state) => state.bs_incorporation_escalations,
    getBSIncorporationEscalation: (state) => state.bs_incorporation_escalation,
    getBSIncorporationTaskCount: (state) => state.task_count,
    getBSIncorporationStatistics: (state) => state.registration_stats,
    getBSIncorporationTaskMessages: (state) => state.task_thread,
    getBSIncorporationTask: (state) => state.incorporation,
    getBSIncorporationTaskError: (state) => state.error,
    getBSIncorporationsError: (state) => state.error,
    getBSUpdateStatusSuccess: (state) => state.updateSuccess,
    getBSUpdateStatusError: (state) => state.updateError,
    getSocietyDetails: (state) => state.society_details,
    getSocietyDetailsError: (state) => state.society_details_error,

};

const actions = {
    async submitBSIncorporationPayment({ commit }, data) {
        await api().post("building-societies/societies/add-payment", data)
            .then((response) => {
                commit("setBSIncorporation", response);
            })
            .catch((error) => {
                commit("setBSIncorporationTaskError", error.response);
            });
    },
    async fetchBSIncorporations({commit}, data){
        let path = "/building-societies/societies/queue/tasks?status="+data.status+"&page="+data.page+"&perPage="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setBSIncorporations', response.data);
        }).catch(error => {
            commit("setBSIncorporationsError", error.response)
        });
    },
    async fetchAllBSIncorporations({commit}, data){
        let path = "/building-societies/societies?status="+data.status+"&page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setBSIncorporations', response.data);
        }).catch(error => {
            commit("setBSIncorporationsError", error.response)
        });
    },
    async fetchBSEscalations({commit}, data){
        api().get("/building-societies/societies/queue/my-escalations?page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setBSIncorporationEscalations', response.data);
        }).catch(error => {
            commit("setBSIncorporationsError", error.response)
        });
    },
    async fetchQueriedBSIncorporations({commit}, data){
        console.log(JSON.stringify(data))
        api().get("/building-societies/societies/queue/queries-submissions?page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setBSIncorporations', response.data);
        }).catch(error => {
            commit("setBSIncorporationsError", error.response)
        });
    },
    async fetchBSIncorporationTask({commit}){
        api().get("/building-societies/societies/queue/task").
        then(response => {
            commit('setBSIncorporation', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setBSIncorporationTaskError", error.response)
        });
    },
    async fetchBSIncorporationEscalation({commit}, id){
        api().get("/building-societies/societies/queue/task/"+id+"/escalation").
        then(response => {
            commit('setBSIncorporationEscalation', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setBSIncorporationTaskError", error.response)
        });
    },
    async fetchBSIncorporationTaskCount({commit}){
        api().get("/building-societies/societies/queue/count").
        then(response => {
            commit('setBSTaskCount', response.data);
        }).catch(error => {
            commit("setRegistrationCountError", error.response)
        });
    },
    async fetchBSStatistics({commit}){
        api().get("/building-societies/societies/basic-statistics").
        then(response => {
            commit('setBSStatistics', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchBSITaskMessages({commit}, id){
        api().get("/building-societies/societies/queue/task/"+id+"/thread").
        then(response => {
            commit('setBSIncorporationTaskMessages', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async updateBSIncorporationStatus({commit}, data){
        api().post("/building-societies/societies/queue/update-status", data).
        then(response => {
            commit('setBSUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setBSUpdateStatusError", error.response)
        });
    },
    async fetchSocietyDetails({commit}, trackingNumber){
        api().get("/building-societies/societies/get-draft-by-tracking-no/"+trackingNumber).
        then(response => {
            commit('setSocietyDetails', response.data);
        }).catch(error => {
            commit("setSocietyDetailsError", error.response)
        });
    },
};

const mutations = {
    setBSIncorporations: (state, response) =>{
        state.incorporations = response;
    },
    setBSIncorporationEscalations: (state, response) =>{
        state.bs_incorporation_escalations = response;
    },
    setBSIncorporationEscalation: (state, response) =>{
        state.bs_incorporation_escalation = response;
    },
    setBSIncorporationsError: (state, response) =>{
        state.error = response;
    },
    setBSTaskCount: (state, response) =>{
        state.task_count = response;
    },
    setBSStatistics: (state, response) =>{
        state.registration_stats = response;
    },
    setBSIncorporation: (state, response) =>{
        state.incorporation = response;
    },
    setBSIncorporationTaskError: (state, response) =>{
        state.error = response;
    },
    setBSUpdateStatusError: (state, response) =>{
        state.updateError = response;
    },
    setBSUpdateStatusSuccess: (state, response) =>{
        state.updateSuccess = response;
    },
    setBSIncorporationTaskMessages: (state, response) =>{
        state.task_thread = response.data;
    },
    setSocietyDetails:(state, response) =>{
        state.society_details = response.data
    },
    setSocietyDetailsError:(state, response) =>{
        state.society_details_error = response.data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};