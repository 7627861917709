<template>
  <div class="overflow-hidden bg-gray px-2" style="min-height: 540px;">
    <b-row>
      <b-col v-if="file.Base64FileStream !== null" cols="12">
        <div v-if="(file.extension === 'tiff' || file.extension === 'tif') && converted" class="pdf">
          <img v-for="(page, idx) in processedPages" :key="idx" class="pdf" :src="page" alt="preview"/>
        </div>
        <iframe v-if="file.extension === 'pdf'" class="pdf" id="pdf" :src="'data:application/pdf;base64,'+file.Base64FileStream+'#toolbar=0&navpanes=0&scrollbar=0'"></iframe>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import UTIF from "utif";

export default {
  name: "EDMSDocument",
  props:{
    file: { type:Object, default: null }
  },
  data() {
    return {
      converted: false,
      pngData: null,
      processedPages: [],
    }
  },
  mounted() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    this.load(this.file)
  },
  methods:{
    async convertTiffFromBase64(b64Tiff) {
      // Convert base64 to bytes
      const res = await fetch(b64Tiff);
      const blob = await res.blob();

      const file = new File([blob], 'file', { type: 'image/tiff' });
      const bytes = await file.arrayBuffer();

      // Decode image
      const ifds = UTIF.decode(bytes);

      ifds.forEach(rawPage => {
        this.processedPages.push(this.processPage(rawPage, ifds, bytes));
      })
    },
    processPage(page, ifds, bytes) {
      let vsns = ifds;
      let ma = 0;

      if (ifds[0].subIFD) vsns = vsns.concat(ifds[0].subIFD);

      for (let i = 0; i < vsns.length; i += 1) {
        const img = vsns[i];

        if (img.t258 !== null && img.t258.length >= 3) {
          const ar = img.t256 * img.t257;

          if (ar > ma) {
            ma = ar;
            page = img;
          }
        }
      }

      UTIF.decodeImage(bytes, page, ifds);

      const rgba = UTIF.toRGBA8(page);

      // Render image
      const cnv = document.createElement('canvas');
      cnv.width = page.width;
      cnv.height = page.height;

      const ctx = cnv.getContext('2d');
      const imgd = ctx.createImageData(page.width, page.height);

      for (let i = 0; i < rgba.length; i += 1) {
        imgd.data[i] = rgba[i];
      }

      ctx.putImageData(imgd, 0, 0);

      // Get PNG base64
      return cnv.toDataURL();
    },
    load(data){
      if (data.extension === 'tif' || data.extension === 'tiff') {
        this.convertTiffFromBase64('data:image/tiff;base64,'+data.Base64FileStream)
          .then(data => {
            this.converted = true
            this.pngData = data;
          })
          .catch(err => {
            console.log(err);
            this.converted = false
            this.pngData = null;
          })
      } else {
        this.converted = false
        this.pngData = null;
      }
    }
  },
  watch: {
    file: {
      handler(data){
        this.load(data)
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 1000px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>