<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Registration">
        <EscalationDataUpdate v-if="task" :application-id="task.applicationResponse.id" :level="3" type="bn-data-update" permission="can_view_bname_reg_escalations"/>
      </b-modal>
      <b-modal id="modal-assign" hide-footer ref="deEscalateSubmissions" title="De Escalate Data Update">
        <DeEscalationDataUpdate :application-id="task.applicationResponse.id" :level="4" type="bn-data-update" permission="can_view_bname_reg_escalations"/>
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept <b-spinner v-if="loading" small class="ml-1"></b-spinner>
            </button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-registration" title="Query Registration">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.description"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-query-hover" variant="primary" @click="onQuery">
                  Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>

    <b-row class="mx-1">
      <b-col cols="12" v-if="task">
        <b-row class="mx-1">
          <b-col>
            <BusinessNameDataUpdateMessages :id="task.applicationResponse.id" mode="TOP_MESSAGE"/>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-alert variant="success" show>
              <i class="fa fa-info-circle mx-1"></i> This application was fully approved on <b>Classification Level 3</b>. Go through it once again to ensure that nothing has been left unchecked.
              This is a security mechanism to allow a testimony of more than one so that we have a thorough check of every entity before it is updated on the platform
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{task.applicationResponse.name}} <label v-if="task.applicationResponse.nameOnFile" class="mx-1 text-blue">( Name on File: {{task.applicationResponse.nameOnFile}} )</label>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Registration Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNumber}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.no}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-orange font-bold" v-if="queried">Queried</span>
                  <span class="text-gray text-capitalize" v-else>{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">Level {{task.applicationResponse.applicationLevel}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{task.applicationResponse.submissionDate}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-gray"
                   style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Summary</td>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Date of Commencement</th>
                <td class="px-2 py-1">
                          <span class="text-gray">
                            {{dateFormat(task.dateOfCommencement)}}
                          </span>
                </td>
              </tr>
              <tr>
                <th class="px-2 py-1">Date of Registration</th>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{dateFormat(task.applicationResponse.registrationDate)}}</span>
                </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Nature of Business</th>
                <td class="px-2 py-1">
                  <ul class="text-blue">
                    <li v-for="(section, idx) in task.natureOfBusiness.breakdown"  :key="idx">
                      <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                    </li>
                  </ul>
                </td>
              </tr>

              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1 text-capitalize">{{task.user.givenName.toLowerCase()}} {{task.user.surName.toLowerCase()}}</td>
                <td class="px-2 py-1">{{task.user.email}}</td>
                <td class="px-2 py-1">{{task.user.phoneNumber}}</td>
              </tr>
              </tbody>
            </table>
          </b-col>
          <b-col cols="6" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Official Account Details (To be Created)</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;">
                <td class="px-2 py-1">
                          <span class="text-blue">
                            {{task.email}}
                          </span>
                </td>
                <td class="px-2 py-1">
                          <span class="text-blue">
                            {{task.phone}}
                          </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
              <button type="button" class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i> Escalate
              </button>
              <button class="btn-de-escalate" @click="deEscalatePrompt">
                <i class="fa fa-arrow-down text-blue mr-1"></i>De-Escalate
              </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'application-details') ? 'menu-item-active' : 'menu-item']" @click="select('application-details')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Details</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Document Mapping</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col :cols="(currentRoute === 'attachments') ? '12' : '6'">
                <BusinessNameDataUpdateFormDetails :task="task" v-if="currentRoute === 'application-details'"/>
                <b-row v-if="currentRoute === 'attachments'">
                  <b-col>
                    <b-row class="my-3">
                      <b-col>
                        <p class="font-bold">Mapped Documents: {{task.attachments.length}}</p>
                        <table class="min-w-full leading-normal mt-2">
                          <thead>
                          <tr class="border-b border-t border-grey">
                            <th class="t-header">#</th>
                            <th class="t-header">Name</th>
                            <th class="t-header">Classification</th>
                            <th class="t-header text-center">Action</th>
                          </tr>
                          </thead>
                          <thead>
                          </thead>
                          <tbody>
                          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(document, idx) in task.attachments" :key="idx">
                            <td class="t-body">{{idx + 1}}</td>
                            <td class="t-body font-bold">{{cleanUnderscores(document.fileDescription)}}</td>
                            <td class="t-body">
                              <b-form-select v-if="document.name === 'OTHER'" v-model="document.name"
                                             class="w-full text-xs form-select paginate-details"
                                             v-on:change="fetch">
                                <b-select-option value="BUSINESS_APPLICATION_FORM">Business Name Form</b-select-option>
                                <b-select-option value="JOINT_VENTURE_AGREEMENT">Joint Venture Agreement</b-select-option>
                                <b-select-option value="CERTIFICATE">Certificate</b-select-option>
                                <b-select-option value="OTHER">Other</b-select-option>
                              </b-form-select>
                              <b-form-select v-else v-model="document.fileDescription"
                                             class="w-full text-xs form-select paginate-details"
                                             v-on:change="fetch">
                                <b-select-option value="BUSINESS_APPLICATION_FORM">Business Name Form</b-select-option>
                                <b-select-option value="JOINT_VENTURE_AGREEMENT">Joint Venture Agreement</b-select-option>
                                <b-select-option value="CERTIFICATE">Certificate</b-select-option>
                                <b-select-option value="OTHER">Other</b-select-option>
                              </b-form-select>
                            </td>
                            <td class="t-body text-center">
                              <button class="bg-blue text-white px-2 py-1 rounded-sm" @click="openFile(document)">
                                View <b-spinner v-if="fileLoader && selectedDocumentHandle === document.fileName" small></b-spinner>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td v-if="task.attachments.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                              <span class="text-sm">No documents found</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                      <b-col class="bg-gray" style="min-height: 540px;">
                        <EDMSDocument v-if="file.Base64FileStream" :file="file"/>
                        <CompanyDataUpdateAttachments v-if="attachmentUrl" ref="attachmentScreen"/>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <BusinessNameDataUpdateMessages :id="task.applicationResponse.id" v-if="currentRoute === 'messages'" mode="THREAD"/>
              </b-col>
              <b-col cols="6" v-if="currentRoute !== 'attachments'">
                <EMDSFileExplorer :brn="task.applicationResponse.brn" :entity-type="task.applicationResponse.type"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import BusinessNameDataUpdateMessages
  from "@/views/home/services/data-update/task/business/BusinessNameDataUpdateMessages.vue";
import BusinessNameDataUpdateFormDetails
  from "@/views/home/services/data-update/task/business/BusinessNameDataUpdateFormDetails.vue";
import EMDSFileExplorer from "@/views/home/services/data-update/components/EMDSFileExplorer.vue";
import EscalationDataUpdate from "@/views/home/commons/EscalationDataUpdate.vue";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument.vue";
import CompanyDataUpdateAttachments
  from "@/views/home/services/data-update/task/company/CompanyDataUpdateAttachments.vue";
import server from "@/app/env/server.json";
import DeEscalationDataUpdate from "@/views/home/commons/DeEscalationDataUpdate.vue";

export default {
  name: "BusinessNameDataUpdateTaskLevel4",
  components: {
    DeEscalationDataUpdate,
    CompanyDataUpdateAttachments,
    EDMSDocument,
    EscalationDataUpdate,
    EMDSFileExplorer,
    BusinessNameDataUpdateFormDetails,
    BusinessNameDataUpdateMessages},
  props:{
    task:Object
  },
  data(){
    return{
      hasComments: false,
      currentRoute: "application-details",
      fileLoader:false,
      loading:false,
      margin:"2px",
      queried:false,
      taskId:null,
      isQueryResponse:false,
      messages:[],
      maxText:500,
      showFileMapping:false,
      form:{
        description:null,
        applicationId:null,
        status:null
      },
      finalForm:{
        attachments:[],
        applicationId:null,
        description:null,
        status:"Approved"
      },
      attachmentUrl:null,
      menu:[
        {
          title:"Application Details",
          route:"application-details",
        },
        {
          title:"Attachments",
          route:"attachments",
        },
      ],
      edmsDocuments:[],
      selectedDocumentHandle:null,
      loadingDocuments:false,
      action:null,
      file:{
        extension:"pdf",
        Base64FileStream:null
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
    }
  },
  methods:{
    ...mapActions(["updateBNDataUpdateStatus", "finalBusinessNameDataUpdateApproval",
      "fetchAllBusinessNameEDMSDocuments", "fetchBusinessNameEDMSFile"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onApprove(){
      this.action = "final"
      this.form.status = "Approved"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.form.status = "Queried"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    deEscalatePrompt() {
      this.action = "de-escalate";
      this.$refs.confirm.show();
    },
    toggleMappingViewer(){
      this.showFileMapping = !this.showFileMapping
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.form.applicationId = this.task.applicationResponse.id
        this.updateBNDataUpdateStatus(this.form)
      }
      if(this.action === "escalate") {
        this.$refs.escalateSubmissions.show();
      }
      if (this.action === "de-escalate") {
        this.$refs.deEscalateSubmissions.show();
      }
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    openFile(file) {
      this.file = {
        extension:"pdf",
        Base64FileStream:null
      }
      this.attachmentUrl = null
      if(file.fileName.includes("pdf")){
        this.attachmentUrl = server.IP+"/data-update/business/attachment/preview?file-name="+file.fileName
      }else{
        this.selectedDocumentHandle = file.fileName
        let data = {
          documentHandle:file.fileName,
          overlay:""
        }
        this.fileLoader = true
        this.fetchBusinessNameEDMSFile(data)
      }

    },
    select(route){
      this.currentRoute = route
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    getDateTime(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters(
        [
          "getBNDataUpdateTask",
          "getBNDataUpdateSuccess",
          "getBNDataUpdateTaskError",
          "getBNDataUpdateError",
          "getBNDataUpdateTaskCount",
          "getBNDataUpdateTaskMessages",
          "getBNDataUpdateActionSuccess",
          "getBNDataUpdateUpdateStatusSuccess",
          "getBNDataUpdateUpdateStatusError",
          "getAllBusinessNameEDMSDocuments", "getEDMSFile"
        ])
  },
  watch:{
    task:{
      handler(){
        this.taskId = this.task.businessId
      },
      deep:true
    },
    attachmentUrl:{
      handler(url){
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    },
    getEDMSFile(data){
      this.fileLoader = false
      this.file = data
    },
    getBNDataUpdateActionSuccess(data){
      if(data) {
        this.loading = false
        this.successToast("Success!", data.message)
        this.cancelConfirmation()
        this.$refs["query-registration"].hide();
        window.location.reload()
      }
    },
    getBNDataUpdateUpdateStatusError(data){
      this.loading = false
      this.$refs["query-registration"].hide();
      this.cancelConfirmation()
      this.errorToast("Error", data.data.message)
    },
  }
}
</script>

<style scoped>
.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}
.btn-escalate{
  @apply border border-blue bg-white rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-de-escalate {
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-de-escalate:hover {
  @apply text-red-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}
</style>