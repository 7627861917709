 <template>
  <b-row>
      <b-col>
        <b-row class="mx-1 my-2" v-if="mode === 'THREAD'">
          <b-col cols="12" >
            <b-row v-for="(message, idx) in  getBNDataUpdateTaskMessages" :key="idx">
              <b-col cols="8" class="py-2 rounded-md bg-header_grey my-2 text-gray">
                <b-row>
                  <b-col>
                    <span class="text-sm font-bold text-capitalize" v-if="message.user">
                      {{message.user.fullName}}
                      <b-badge class="bg-green text-capitalize" v-if="message.user">
                       {{message.userType}}
                      </b-badge>
                    </span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="text-sm">{{message.description}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span style="font-size:9px" class="text-gray">{{message.createdAt}} | {{message.statusLabel}}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="getBNDataUpdateTaskMessages">
              <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
                <b-row v-if="getBNDataUpdateTaskMessages.length === 0">
                  <b-col>
                    <span class="text-xs font-bold">No messages yet</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
                <b-row>
                  <b-col>
                    <span class="text-xs font-bold">No messages yet</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="my-2" v-if="mode === 'TOP_MESSAGE'">
          <b-col cols="12" v-if="getBNDataUpdateTaskMessages">
            <b-row v-if="getBNDataUpdateTaskMessages.length > 0">
              <b-col cols="12" class="py-2 border border-gray rounded-md bg-header_grey my-2 text-gray">
                <b-row>
                  <b-col>
                    <span class="text-sm font-bold text-capitalize" v-if="getBNDataUpdateTaskMessages[0].user">
                      {{getBNDataUpdateTaskMessages[0].user.fullName}}
                      <b-badge class="bg-green text-capitalize">
                       {{getBNDataUpdateTaskMessages[0].userType}}
                      </b-badge>
                    </span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="text-sm">{{getBNDataUpdateTaskMessages[0].description}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span style="font-size:9px" class="text-gray">{{getBNDataUpdateTaskMessages[0].createdAt}} | {{getBNDataUpdateTaskMessages[0].statusLabel}}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BusinessNameDataUpdateMessages",
  props:{
    id:String,
    mode:String
  },
  mounted(){
    this.fetchDataUpdateMessages(this.id)
  },
  methods:{
    ...mapActions(["fetchDataUpdateMessages"])
  },
  computed:{
    ...mapGetters(["getBNDataUpdateTaskMessages"])
  },
}
</script>

<style scoped>

</style>