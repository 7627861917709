<template>
  <div>
    <b-row>
      <b-modal id="edmsDocument" size="lg" hide-footer ref="edmsDocument" title="EDMS Document">
        <EdmsIdToDocument :edms-id="selectedEdmsId"></EdmsIdToDocument>
      </b-modal>
    </b-row>
    <b-row>
        <b-col>
          <b-row class="mt-3">
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Subject Matter"
                label-for="input-1">
                <b-form-textarea
                  id="companyName"
                  class="form-text text-sm noFocus text-capitalize"
                  type="text"
                  disabled="true"
                  :value="application.subjectMatter"
                  required>
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Origin Account Type"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    v-if="application.user"
                    class="form-text text-sm noFocus text-capitalize font-bold"
                    type="text"
                    disabled="true"
                    :value="application.user.accountType+ ' ACCOUNT'"
                    required>
                </b-form-input>
                <b-form-input
                    id="companyName"
                    v-else
                    class="form-text text-sm noFocus text-capitalize font-bold"
                    type="text"
                    disabled="true"
                    value="Not Indicated"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Date of Execution"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    disabled="true"
                    :value="application.filingDate"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="application.documentType === 'AGREEMENT' || application.documentType === 'CONSTITUTION' || application.documentType === 'MINUTES' || application.documentType === 'OTHERS' || application.documentType === 'RESOLUTION_AS_A_DOCUMENT'">
            <b-col cols="12" class="mt-4">
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Parties</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualParties" :corporates="application.entityParties"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'GUARANTEE'">
            <b-col cols="12">
              <b-row class="mt-3">
                <b-col>
                  <span class="h5">Parties</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualParties" :corporates="application.entityParties"/>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <span class="h5">Beneficiary</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="getArray(application.individualBeneficiary)" :corporates="getArray(application.entityBeneficiary)"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'AFFIDAVIT' || application.documentType === 'STATUTORY_DECLARATION'" class="mt-4">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <span class="h5">Deponent's KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="application.entityDeclarantDeponent != null" :person="application.entityDeclarantDeponent" entity-type="CORPORATE"/>
                  <LegalPerson v-if="application.individualDeclarantDeponent != null" :person="application.individualDeclarantDeponent" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'FACILITY_LETTER'">
            <b-col cols="12" class="mt-4">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Amount Secured"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="application.amountSecured.toLocaleString()"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="8">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Collateral Security Description"
                          label-for="input-1">
                        <b-form-textarea
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.collateral"
                            required>
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Borrowers</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualBorrowers" :corporates="application.entityBorrowers"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Lenders</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualLenders" :corporates="application.entityLenders"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'DEED_OF_ASSIGNMENT' || application.documentType === 'DEED_OF_INTER_VIVOS' || application.documentType ===  'DEED_OF_VARIATION' || application.documentType === 'DEED_OF_GIFT' || application.documentType === 'DEED_OF_INDEMNITY' || application.documentType === 'DEED_OF_TRUST'">
            <b-col cols="12" class="mt-4">
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assigners</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualAssigners" :corporates="application.entityAssigners"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assignees</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualAssignees" :corporates="application.entityAssignees"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'DEED_POLL'">
            <b-col>
              <b-row>
                <b-col cols="4">
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Old Name of Declarant"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="application.oldName"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="New Name of Declarant"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="application.newName"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Address of Declarant</span>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="District"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="application.physicalAddress.district.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Region"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="application.physicalAddress.region.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Sub county"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="application.physicalAddress.subcounty.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Parish"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="application.physicalAddress.parish.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Village"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="application.physicalAddress.village.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'MORTGAGE_DEED'">
            <b-col cols="12" class="mt-4">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Amount"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.amountSecured.toLocaleString()"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Collateral Security Description"
                          label-for="input-1">
                        <b-form-textarea
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.collateral"
                            required>
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Mortgagors</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualMortgagors" :corporates="application.entityMortgagors"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Mortgagees</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualMortgagees" :corporates="application.entityMortgagees"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'POWER_OF_ATTORNEY'" class="mt-4">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <span class="h5">Resolution(s)</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row v-for="(resolution, idx) in application.resolutions" :key="idx">
                    <b-col class="text-sm">
                      <p>Tracking Number: {{ resolution.trackingNumber }}</p>
                      <p>Document Type: {{ resolution.documentType.replaceAll("_", " ") }}</p>
                      <p>Subject Matter: {{ resolution.subjectMatter }}</p>
                      <p>Filing Date: {{ resolution.filingDate }}</p>
                    </b-col>
                    <b-col class="text-sm">
                      <p class="font-bold">Attachments</p>
                      <p v-for="(attachment, idx) in resolution.attachments" :key="idx"
                         class="cursor-pointer hover:underline" @click="viewResolution(attachment)">
                        <i class="fa fa-file mx-1 text-blue"></i> {{ attachment.fileDescription }}
                      </p>
                    </b-col>
                  </b-row>
                  <b-row v-if="application.resolutions.length === 0">

                    <b-col>
                      <b-alert variant="info" show>
                        <i class="mx-2 fa fa-info-circle"></i> No Resolution Included
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <span class="h5">Donor KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-for="(lp, idx) in application.entityDonor" :key="idx" :person="lp" entity-type="CORPORATE"/>
                  <LegalPerson v-for="(lp, idx) in application.individualDonor" :key="idx"  :person="lp" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Donee KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-for="(lp, idx) in application.entityDonee" :key="idx" :person="lp" entity-type="CORPORATE"/>
                  <LegalPerson v-for="(lp, idx) in application.individualDonee" :key="idx" :person="lp" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="application.documentType === 'REVOCATION_POWER_OF_ATTORNEY'" class="mt-4">
            <b-col cols="12">

              <b-card title="Power of Attorney to Revoke">
                <b-card-body>
                  <b-row class="my-2">
                    <b-col cols="12">
                      <p>Instrument Number: {{application.powerOfAttorney.instrumentNumber}} </p>
                      <p>EDMS Document Copy: <b class="cursor-pointer text-blue"> <i class="fa fa-file mx-2"></i> {{application.powerOfAttorney.edmsId}}</b> </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-row>
                        <b-col>
                          <span class="h5">Resolution(s)</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-row v-for="(resolution, idx) in application.powerOfAttorney.resolutions" :key="idx">
                            <b-col class="text-sm">
                              <p>Tracking Number: {{ resolution.trackingNumber }}</p>
                              <p>Document Type: {{ resolution.documentType.replaceAll("_", " ") }}</p>
                              <p>Subject Matter: {{ resolution.subjectMatter }}</p>
                              <p>Filing Date: {{ resolution.filingDate }}</p>
                            </b-col>
                            <b-col class="text-sm">
                              <p class="font-bold">Attachments</p>
                              <p v-for="(attachment, idx) in resolution.attachments" :key="idx"
                                 class="cursor-pointer hover:underline" @click="viewResolution(attachment)">
                                <i class="fa fa-file mx-1 text-blue"></i> {{ attachment.fileDescription }}
                              </p>
                            </b-col>
                          </b-row>
                          <b-row v-if="application.powerOfAttorney.resolutions.length === 0">
                            <b-col>
                              <b-alert variant="info" show>
                                <i class="mx-2 fa fa-info-circle"></i> No Resolution Included
                              </b-alert>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <span class="h5">Donor KYC</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <LegalPerson v-for="(lp, idx) in application.powerOfAttorney.entityDonor" :key="idx" :person="lp" entity-type="CORPORATE"/>
                          <LegalPerson v-for="(lp, idx) in application.powerOfAttorney.individualDonor" :key="idx"  :person="lp" entity-type="INDIVIDUAL"/>
                        </b-col>
                      </b-row>

                      <b-row class="mt-2">
                        <b-col>
                          <span class="h5">Donee KYC</span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <LegalPerson v-for="(lp, idx) in application.powerOfAttorney.entityDonee" :key="idx" :person="lp" entity-type="CORPORATE"/>
                          <LegalPerson v-for="(lp, idx) in application.powerOfAttorney.individualDonee" :key="idx" :person="lp" entity-type="INDIVIDUAL"/>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'SPOUSAL_CONSENT'">
            <b-col cols="12" class="mt-4">

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Consenting Person</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="application.entityConsentingPerson != null" :person="application.entityConsentingPerson" entity-type="CORPORATE"/>
                  <LegalPerson v-if="application.individualConsentingPerson != null" :person="application.individualConsentingPerson" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Mortgagee</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="application.entityMortgagee != null" :person="application.entityMortgagee" entity-type="CORPORATE"/>
                  <LegalPerson v-if="application.individualMortgagee != null" :person="application.individualMortgagee" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'DEBENTURE'">
            <b-col cols="12" class="mt-4">
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assigners</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualAssigners" :corporates="application.entityAssigners"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assignees</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualAssignees" :corporates="application.entityAssignees"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'CHATTEL'">
            <b-col cols="12" class="mt-4">
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assigners</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualAssigners" :corporates="application.entityAssigners"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Assignees</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualAssignees" :corporates="application.entityAssignees"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'DEMAND_PROMISSORY_NOTE'" class="mt-4">
            <b-col cols="12">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Amount"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="0"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Realization Date"
                      label-for="input-1">
                    <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="new Date()"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Borrower KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="application.entityBorrower != null" :person="application.entityBorrower" entity-type="CORPORATE"/>
                  <LegalPerson v-if="application.individualBorrower != null" :person="application.individualBorrower" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Creditor KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="application.entityCreditor != null" :person="application.entityCreditor" entity-type="CORPORATE"/>
                  <LegalPerson v-if="application.individualCreditor != null" :person="application.individualCreditor" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'INDEPENDENT_ADVISE_FORM'" class="mt-4">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <span class="h5">Advisor KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="application.entityAdviser != null" :person="application.entityAdviser" entity-type="CORPORATE"/>
                  <LegalPerson v-if="application.individualAdviser != null" :person="application.individualAdviser" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Advisee KYC</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPerson v-if="application.entityAdvise != null" :person="application.entityAdvise" entity-type="CORPORATE"/>
                  <LegalPerson v-if="application.individualAdvise != null" :person="application.individualAdvise" entity-type="INDIVIDUAL"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'REPORT_OF_DIRECTORS'">
            <b-col cols="12" class="mt-4">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Amount"
                          label-for="input-1">
                        <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.amountSecured.toLocaleString()"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Collateral Security Description"
                          label-for="input-1">
                        <b-form-textarea
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            disabled="true"
                            :value="application.collateral"
                            required>
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Mortgagors</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualMortgagors" :corporates="application.entityMortgagors"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Mortgagees</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualMortgagees" :corporates="application.entityMortgagees"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row v-if="application.documentType === 'WILL'">
            <b-col cols="12" class="mt-4">
              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Executors</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualExecutors" :corporates="application.entityExecutors"/>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <span class="h5">Testators</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <LegalPersonsPartner class="p-1" :individuals="application.individualTestators" :corporates="application.entityTestators"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-col>
      </b-row>
  </div>
</template>

<script>
import moment from "moment";
import LegalPerson from "@/views/home/services/legal-documents/LegalPerson";
import LegalPersonsPartner from "@/views/home/commons/LegalPersonsPartner";
import EdmsIdToDocument from "@/views/home/services/registry/business/document/EdmsIdToDocument.vue";

export default {
  name: "LegalDocumentFormDetailsBox",
  components: { EdmsIdToDocument, LegalPersonsPartner, LegalPerson},
  props:{
    application:null
  },
  data(){
    return{
      corporates:[],
      individuals:[],
      selectedEdmsId:null,
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    selectEDMSDocument(id){
      this.selectedEdmsId = id
      this.$refs.edmsDocument.show()
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
    getArray(data){
      if(data){
        return [data]
      }
      return []
    }
  },
  watch:{

  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>