<template>
  <div class="mt-3">
    <div class="bg-white rounded-sm m-2">
      <b-row>
        <b-col cols="12" class="m-2">
          <b-row>
            <b-col cols="6">
              <span class="text-xs px-2 py-1 text-red">
                <b>{{getAnnualReturnsTaskCount.pendingTasksCount}}</b> Total Submissions
              </span>
              <span class="text-xs px-2 py-1 text-blue ml-2">
                <b>{{getAnnualReturnsTaskCount.registrarTasksCount}}</b> Assigned
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white rounded-sm m-2">
      <b-row v-if="getAnnualReturnsTask.data !== null">
        <b-col cols="12" class="mx-3 mt-3">
          <b-row>
            <b-col>
              <span class="bg-blue text-white px-2 py-1 rounded-sm text-xs font-bold">#AnnualReturns</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row v-if="getAnnualReturnsTask.data === null" class="py-5">
            <b-col cols="12" class="py-5">
              <b-row>
                <b-col cols="12" class="text-center justify-content-center">
                  <img :src="require('../../../../../assets/images/ic_empty_box.png')" class="icon mx-auto">
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="12" class="text-center">
                  <div class="h5 text-gray mx-auto">There are no Annual Applications left in the queue</div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="text-center">
                  <div class="text-gray mx-auto">Tasks will be available when clients submit new <b class="text-blue">applications</b> or when <b class="text-orange">queries</b> are responded to.</div>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="12" class="text-center">
                  <button class="text-white bg-blue rounded-md px-2 py-2 mx-auto hover:bg-blue-dark" @click="checkForUpdates">Check for Updates</button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-else>
            <AnnualReturnsTask :task="getAnnualReturnsTask"/>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AnnualReturnsTask from "@/views/home/services/annual-returns/task/AnnualReturnsTask.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AnnualReturnsTaskQueue",
  components: { AnnualReturnsTask },
  data(){
    return{
      margin:"2px",
      loading:true,
      task:{}
    }
  },
  mounted() {
    this.fetchAnnualReturnsTask()
    this.fetchAnnualReturnsTaskCount()
  },
  methods:{
    ...mapActions(["fetchAnnualReturnsTask", "fetchAnnualReturnsTaskCount"]),
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
  },
  computed:{
    ...mapGetters(["getAnnualReturnsTask", "getAnnualReturnsTaskCount"])
  }
};
</script>

<style scoped>
.icon{
  width: 170px;
}
</style>