<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-details" size="xl" hide-footer ref="escalate-details" title="Escalated File">
        <b-col cols="12">
          <DisputeResolutionEscalationFile :application="application"/>
        </b-col>
      </b-modal>
    </b-row>

    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
            <span class="title">Dispute Resolution Escalations</span>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12">
        <div class="mx-auto">
          <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">Tracking No.</th>
                <th class="t-header">Company Name</th>
                <th class="t-header">Type</th>
                <th class="t-header text-center"> Date</th>
                <th class="t-header text-center">Submission Date</th>
                <th class="t-header text-center">Escalated By</th>
                <th class="t-header text-center">Action</th>
              </tr>
              </thead>

              <tbody>
              <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in escalations" :key="idx">
                <td class="t-body text-blue font-bold">{{submission.task.data.trackingNo}}</td>
                <td class="t-body font-bold">
                    {{submission.task.data}}
                </td>
                <td class="t-body text-xs">
                  <span >
                   {{submission.task.data.type.replaceAll("_", " ")}}
                  </span>

                </td>
                <td class="t-body text-center">
                  {{dateFormat(submission.task.data)}}
                </td>
                <td class="t-body text-center">
                  {{dateTimeFormat(submission.createdAt)}}
                </td>
                <td class="t-body text-center">
                  <span class="text-blue text-xs">{{submission.source}}</span>
                </td>
                <td class="t-body">
                  <div class="flex">
                    <button @click="view(submission)" class="bg-blue px-2 py-1 text-white rounded-sm w-full">View</button>
                  </div>
                </td>
              </tr>
              <tr>
                <td v-if="escalations.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                  <span class="text-sm">No results found</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
          <b-col cols="1">
            <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
          </b-col>
          <b-col cols="1">
            <b-row>
              <b-col cols="12">
                <p class="paginate-details text-center">
                  <b-badge class="bg-blue text-white">
                    {{currentPage}}/{{pagination.totalPages}}
                  </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" >
            <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
          </b-col>
          <b-col cols="6"></b-col>
          <b-col cols="1">
            <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
              <b-select-option value="15">15</b-select-option>
              <b-select-option value="20">20</b-select-option>
              <b-select-option value="50">50</b-select-option>
              <b-select-option value="100">100</b-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="2">
            <b-row>
              <b-col class="mx-auto">
                <p class="paginate-details text-center text-xs font-bold">
                  <span>{{((currentPage - 1) * limit) + 1}}</span> -
                  <span v-if="limit > escalations.length">{{pagination.totalElements}}</span>
                  <span v-else>{{currentPage * limit}}</span>
                  of {{pagination.totalElements}}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import DisputeResolutionEscalationFile
    from "@/views/home/services/dispute-resolution/escalations/DisputeResolutionEscalationFile.vue";

export default {
  name: "DisputeResolutionEscalations",
  components: { DisputeResolutionEscalationFile},
  data(){
    return{
      selectedId:null,
      margin:"2px",
      hasContent:false,
      loading:true,
      message:null,
      currentPage:1,
      limit:15,
      application:null,
      escalations:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchDisputeResolutionEscalations"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    cleanString(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    fetch(){
      let data = {
        status:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchDisputeResolutionEscalations(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    view(submission){
      this.application = submission
      this.$refs["escalate-details"].show()
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    clearForm(){
      this.form = {
        description:null,
        reservationId:null,
        status:null
      }
    },
    dateFormat(date){
      if(date){
        return moment(date).format("DD-MM-YYYY")
      }
      return ""
    }
  },
  computed:{
    ...mapGetters([ "getDisputeResolutionEscalations", "getDisputeResolutionAssignmentSuccess", "getDisputeResolutionEscalationError"]),
  },
  watch:{
    getDisputeResolutionEscalations(data){
      console.log(data)
      this.loading = false
      this.escalations = data.escalations
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getDisputeResolutionAssignmentSuccess(){
      this.$refs["escalate-details"].hide()
      this.fetch()
    },
      getDisputeResolutionEscalationError(error){
        this.errorToast("Failed !",error.message)
      }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.icon{
  width: 170px;
}

.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.approve-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.approve-btn:hover{
  @apply bg-green text-white;
}

.reject-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.reject-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>