<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Amendment">
        <Escalation :task-id="taskId" type="amendment" permission="can_view_bname_amendments_escalations"/>
      </b-modal>
      <b-modal id="modal-approve" hide-footer ref="approve-amendment" title="Approve Amendment">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
                v-if="!hasComments"
                v-model="form.description"
                type="text"
                placeholder="Some comments before approval?"
                class="mt-1"
                required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
            <b-row>
              <b-col>
                <div class="flex my-1">
                  <b-form-checkbox v-model="hasComments"></b-form-checkbox>
                  <span class="ml-1">I have no comments</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-approve-hover" variant="success" @click="onApprove">
                  Approve
                  <b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-amendment" title="Query Amendment">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
                v-model="form.description"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-query-hover" variant="primary" @click="onQuery">
                  Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.

            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row >
      <b-col cols="12">
        <b-row class="mx-3" v-if="getAmendmentTask.escalation != null">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-green">
<!--            <b-row>-->
<!--              <b-col>-->
<!--                <div class="flex">-->
<!--                  <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>-->
<!--                  <span class="text-sm font-bold underline">-->
<!--                    {{getAmendmentTask.escalation.fromUser.firstName}}-->
<!--                    {{getAmendmentTask.escalation.fromUser.lastName}}-->
<!--                  </span>-->
<!--                </div>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--            <b-row>-->
<!--              <b-col>-->
<!--                <span class="text-sm">{{getAmendmentTask.escalation.description}}</span>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--            <b-row>-->
<!--              <b-col>-->
<!--                <span style="font-size:9px">{{dateFormat(getAmendmentTask.escalation.createdAt)}}</span>-->
<!--              </b-col>-->
<!--            </b-row>-->
            <b-row>
              <b-col>
                <span class="bg-green rounded text-white" style="padding: 2px 4px; font-size: 9px;">Escalation Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-3" v-if="isQueryResponse">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
            <b-row>
              <b-col>
                <div class="flex">
                  <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                  <span class="text-sm font-bold underline">{{messages[0].user.firstName}} {{messages[0].user.lastName}}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-sm">{{messages[0].description}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span style="font-size:9px">{{dateFormat(messages[0].dateTime)}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="bg-orange rounded text-white px-2 py-1" style="font-size: 9px;">Query Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Amendment Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Amendment Type</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{getAmendmentTask.data.trackingNumber}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{getAmendmentTask.data.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize" v-if="getAmendmentTask.data.filingType.includes(',')">
                    Multiple Changes
                  </span>
                  <span class="text-gray text-capitalize" v-else>
                    {{cleanEntityType(getAmendmentTask.data.filingType.toLowerCase())}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(getAmendmentTask.data.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1">
            <b-col cols="12" v-if="getAmendmentTask.data.filingType === 'NOTICE_OF_CHANGE_IN_BENEFICIAL_OWNERSHIP' " >
                <label class="text-blue border-1 rounded-b-sm w-full my-2 text-center ">
                    <span class="text-center">Payment Not Applicable</span>
                </label>
            </b-col>
          <b-col v-else cols="12">
            <PRNViewer :tracking-number="getAmendmentTask.data.trackingNumber" :prn="getAmendmentTask.data.prn"/>
          </b-col>
        </b-row>
        <b-row class=" mt-3 mx-1">
          <b-col cols="10"></b-col>
          <b-col cols="2" class="float-end ">
            <b-form-select class="form-select text-sm" v-model="form.approvalAction">
              <b-select-option :value="null">Approval Action</b-select-option>
              <b-select-option value="TO_SOLE">Convert to Sole Proprietorship</b-select-option>
              <b-select-option value="CEASE">Cease Business Name</b-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-1">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i>Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mx-1 mb-5">
          <b-col cols="12">
            <b-row>
              <b-col>
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'info') ? 'menu-item-active' : 'menu-item']"
                       @click="select('info')">
                    <div class="flex mt-6">
                      <div style="font-size: 12px">Filing Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']"
                       @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 12px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']"
                       @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 12px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <AmendmentDetails :amendment="getAmendmentTask.data" v-if="currentRoute === 'info'"/>
                <AmendmentFiles v-if="currentRoute === 'attachments'"/>
                <AmendmentMessages v-if="currentRoute === 'messages'"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import Escalation from "@/views/home/commons/Escalation";
import moment from "moment";
import PRNViewer from "@/views/home/commons/PRNViewer";
import AmendmentDetails from "@/views/home/services/amendments/task/AmendmentDetails";
import AmendmentFiles from "@/views/home/services/amendments/task/AmendmentFiles";
import AmendmentMessages from "@/views/home/services/amendments/task/AmendmentMessages";

export default {
  name: "BusinessNameAmendmentTask",
  components: {
    AmendmentMessages,
    AmendmentFiles,
    AmendmentDetails,
    PRNViewer,
    Escalation},
  data(){
    return{
      hasComments: false,
      currentRoute: "info",
      margin:"2px",
      hasContent:false,
      loading:false,
      isQueryResponse:false,
      message:null,
      taskId:null,
      maxText:500,
      form:{
        description:null,
        filingApplicationId:null,
        approvalAction:null,
        status:null
      },
      report:[],
      analysisReportError:null,
      messages:[],
      menu:[
        {
          title:"Document Information",
          route:"info",
        },
        {
          title:"Attachment",
          route:"attachments",
        },
        {
          title:"Messages",
          route:"messages",
        },
      ],
      action:null
    }
  },
  mounted() {
    this.fetchAmendmentTask()
  },
  methods:{
    ...mapActions(["fetchAmendmentTask", "fetchAmendmentStatuses", "fetchAmendmentQueueCount", "updateAmendmentTaskStatus"]),
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onApprove(){
      this.action = "final"
      this.form.status = "Approved"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.action = "final"
      this.form.status = "Queried"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    onReject(){
      this.action = "final"
      this.form.status = "Rejected"
      this.$refs.confirm.show();
    },
    escalatePrompt(){
      this.loading = true
      this.$refs.escalateSubmissions.show();
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateAmendmentTaskStatus(this.form)
      }
      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    clearForm(){
      this.form = {
        description:null,
        reservationId:null,
        status:null
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
      ...mapGetters(["getAmendmentTask", "getUpdatedAmendmentTaskStatus",
        "getUpdatedAmendmentTaskStatusError", "getAmendmentStatuses", "getAmendmentTaskError"]),
  },
  watch:{
    getAmendmentTask(data){
      this.form.filingApplicationId = data.data.filingApplicationId
      this.taskId = data.data.filingApplicationId
      this.fetchAmendmentQueueCount()
      this.fetchAmendmentStatuses(this.taskId)
    },
    getUpdatedAmendmentTaskStatus(){
      window.location.reload()
    },
    getUpdatedAmendmentTaskStatusError(data){
      this.errorToast("Error", data.message)
    },
    getAmendmentTaskError(data){
      this.errorToast("Error", data.message)
    },
    getAmendmentStatuses(data){
      this.messages = data
      if(this.messages[0].status === "QUERIED"){
        this.isQueryResponse = true
      }
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";
.title{
  font-size: 22px;
}

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue bg-white rounded-sm text-blue text-sm px-3 py-1 mx-1;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}
</style>