<template>
  <b-row>
    <b-col v-if="application !== null">
      <b-overlay rounded="sm" :show="loading">
        <b-col>
          <b-row>
            <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{application.newName}} <span class="line-through uppercase italic text-gray">{{ dispensationSuffix(application.name) }}</span>
            </span>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Dispensation Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Name</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Dispensation Type</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                    <span class="text-gray">{{application.trackingNo}}</span>
                  </td>
                  <td class="px-2 py-1">
                    {{application.newName}} <span class="line-through uppercase italic text-gray">{{ dispensationSuffix(application.name) }}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue text-capitalize">{{application.status.toLowerCase().replaceAll("_", " ")}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{cleanEntityType(application.type)}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>

          <!-- Applicant -->
          <b-row class="mt-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-blue-dark"
                     style="border: solid 1px #bdd7f1;">
                <thead>
                <tr class="bg-blue-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Name</th>
                  <th class="px-2 py-1">Email</th>
                  <th class="px-2 py-1">Phone</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #bdd7f1;" v-if="application.applicant">
                  <td class="px-2 py-1 text-capitalize">{{application.applicant.givenName}} {{application.applicant.surname}}</td>
                  <td class="px-2 py-1">{{application.applicant.email}}</td>
                  <td class="px-2 py-1">{{application.applicant.phoneNumber}}</td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <!-- /Applicant -->

          <b-row class="mt-1">
            <b-col cols="12">
              <PRNViewer v-if="application.status !== 'DRAFT'" :tracking-number="application.trackingNo" :prn="application.payment.prn"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <DispensationPopupDetails ref="details" :application="application"/>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import DispensationPopupDetails
  from "@/views/home/services/dispensation/submissions/components/DispensationPopupDetails.vue";

export default {
  name: "DispensationDetails",
  components: {DispensationPopupDetails, PRNViewer },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      application:null,
      loading:true,
      snapshot:null,
      snapshots:[],
    }
  },
  mounted() {
    this.fetchDispensationByTrackingNumber(this.trackingNumber)
    // this.fetchCompanyIncorporationTaskSnapshots(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchDispensationByTrackingNumber", "fetchCompanyIncorporationTaskSnapshots"]),
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ").replaceAll("_", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY h:mmA")
    },
    dispensationSuffix(name){
      if (typeof name !== "string") {
        return ""
      }

      if (name.toUpperCase().endsWith("LIMITED")) {
        return "limited"
      } else if (name.toUpperCase().endsWith("LTD")) {
        return "ltd"
      } else {
        return ""
      }
    },
  },
  computed:{
    ...mapGetters(["getDispensation", "getDispensationError", "getIncorporationSnapshots"])
  },
  watch:{
    snapshot:{
      handler(data){
        if(data){
          this.application = JSON.parse(data.snapshot)
        }else{
          this.fetchDispensationByTrackingNumber(this.trackingNumber)
          this.fetchCompanyIncorporationTaskSnapshots(this.trackingNumber)
        }
      },
      deep:true
    },
    getDispensation(data){
      this.loading = false
      this.application = data
    },
    getIncorporationSnapshots(data){
      this.snapshots = data.data.snapshots
    },
    getDispensationError(){
      this.loading = false
      this.errorToast("Error", "Failed to get application details")
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>