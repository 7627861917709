<template>
  <b-row>
    <b-col>
      <b-card :header="badge">
        <b-row>
          <b-col>
            <LegalPersonBusinessAmendments badge="Updated Partners" :persons="partners"/>
          </b-col>
        </b-row>

        <table class="min-w-full leading-normal mt-3">
          <thead class="bg-header_grey border border-header_grey">
          <tr>
            <th class="px-2 py-1" colspan="12">Business Address</th>
          </tr>
          </thead>
          <tbody>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Country
            </td>
            <td class="px-2 py-1">
              {{data.physicalAddress.country.name}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              District
            </td>
            <td class="px-2 py-1">
              {{data.physicalAddress.district.name}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Region
            </td>
            <td class="px-2 py-1">
              {{data.physicalAddress.region.name}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Sub County
            </td>
            <td class="px-2 py-1">
              {{data.physicalAddress.subcounty.name}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Parish
            </td>
            <td class="px-2 py-1">
              {{data.physicalAddress.parish.name}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Village
            </td>
            <td class="px-2 py-1">
              {{data.physicalAddress.village.name}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Postal Address
            </td>
            <td class="px-2 py-1">
              {{data.postalAddress}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Plot
            </td>
            <td class="px-2 py-1">
              {{data.plot}}
            </td>
          </tr>
          <tr style="border: solid 1px #dedede;">
            <td class="px-2 py-1 font-bold">
              Street
            </td>
            <td class="px-2 py-1">
              {{data.street}}
            </td>
          </tr>
          </tbody>

        </table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import LegalPersonBusinessAmendments
  from "@/views/home/services/amendments/task/forms/LegalPersonBusinessAmendments";
export default {
  name: "NewBusinessParticulars",
  components: { LegalPersonBusinessAmendments },
  props:{
    data:{},
    badge:String
  },
  data(){
    return{
      partners:[]
    }
  },
  mounted(){
    for(let i = 0; i < this.data.individualPartners.length; i++){
      this.partners.push(
        {
          entityType:'INDIVIDUAL',
          individual:this.data.individualPartners[i],
          corporate:null
        }
      )
    }

    for(let i = 0; i < this.data.corporatePartners.length; i++){
      this.partners.push(
        {
          entityType:'CORPORATE',
          individual: null,
          corporate: this.data.corporatePartners[i]
        }
      )
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
p{
  font-size: 14px;
}
</style>