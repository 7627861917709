import {api} from "@/app/services/api.service";

const state = {
    reservations:[],
    responses:[],
    escalations:[],
    reservationStatuses:[],
    reservation:{},
    reservation_stats:{
        pending:0,
        rejected:0,
        assigned:0,
        total:0,
        approved:0,
        queried:0
    },
    reservation_ext_stats:{
        pending:0,
        approved:0,
        rejected:0
    },
    reservationFinalDecision:null,
    reservationFinalDecisionError:null,
    reservation_extensions:{},
    reservation_extensions_error:null,
    reservation_extensions_menu:null,
    reservation_appeals_menu:null,
    escalations_menu:null,
    reservation_appeals:{},
    reservation_count:{},
    reservation_result: {},
    analysis: {},
    success:{},
    successAssignment:{},
    errorAssignment:null,
    successEscalation:{},
    error:{},
    fetch_error:null,
    analysis_error:{},
    reservationRevert: null,
    payment_removed:null,
    payment_removed_error:null,

};

const getters = {
    getReservationSubmissions: (state) => state.reservations,
    getReservationRevertSuccess: (state) => state.reservationRevert,
    getReservationResponses: (state) => state.responses,
    getReservationEscalations: (state) => state.escalations,
    getReservationEscalationsMenu: (state) => state.escalations_menu,
    getReservationTask: (state) => state.reservation,
    getReservationStatistics: (state) => state.reservation_stats,
    getReservationExtensionStatistics: (state) => state.reservation_ext_stats,
    getReservationExtensions: (state) => state.reservation_extensions,
    getReservationExtensionsError: (state) => state.reservation_extensions_error,
    getReservationExtensionsMenu: (state) => state.reservation_extensions_menu,
    getReservationAppeals: (state) => state.reservation_appeals,
    getReservationAppealsMenu: (state) => state.reservation_appeals_menu,
    getReservationStatuses: (state) => state.reservationStatuses,
    getReservationsCount: (state) => state.reservation_count,
    getAnalysisReport: (state) => state.analysis,
    getReservationSuccess:(state) => state.success,
    getAppealSuccess:(state) => state.success,
    getReservationEscalationSuccess:(state) => state.successEscalation,
    getReservationAssignmentSuccess:(state) => state.successAssignment,
    getReservationActionSuccess:(state) => state.reservationFinalDecision,
    getReservationActionError:(state) => state.reservationFinalDecisionError,
    getReservationAssignmentError:(state) => state.errorAssignment,
    getReservationError:(state) => state.error,
    getReservationFetchError:(state) => state.fetch_error,
    getReservationEscalationError:(state) => state.error,
    getAnalysisReportError:(state) => state.analysis_error,
    getReservationNumberResult: (state) => state.reservation_result,
    getReservationNumberResultError: (state) => state.error,
    getReservationPaymentRemoved: (state) => state.payment_removed,
    getReservationPaymentRemovedError: (state) => state.payment_removed_error,
};

const actions = {
    async saveReservation({commit}, data) {
        await api().post("reservation", data)
            .then((response) => {
                commit("setReservation", response);
            })
            .catch((error) => {
                commit("setReservationError", error.response);
            });
    },
    async fetchReservations({commit}, data){
        let path = "/reservation?status="+data.statuses+"&page="+data.page+"&per_page="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setReservations', response.data);
        }).catch(error => {
            commit("setReservationFetchError", error.response)
        });
    },
    async fetchReservationEscalations({commit}, data){
        api().get("/reservation/escalation?status="+data.status+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setReservationEscalations', response.data);
        }).catch(error => {
            commit("setReservationEscalationError", error.response)
        });
    },
    async fetchReservationEscalationsMenu({commit}, data){
        api().get("/reservation/escalation?status="+data.status+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setEscalationsMenu', response.data);
        }).catch(error => {
            commit("setReservationEscalationError", error.response)
        });
    },
    async revertReservation({commit}, reservationNumber) {
        await api().post("reservation/status/revert?trackingNo="+reservationNumber)
            .then((response) => {
                commit("setReservationReverted", response);
            })
            .catch((error) => {
                commit("setReservationResultsError", error.response);
            });
    },
    async fetchReservationStatuses({commit}, id){
        api().get("/reservation/status?reservationId="+id).
        then(response => {
            commit('setReservationStatuses', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    async fetchNextReservation({commit}){
        commit('setReservationToNull');
        api().get("/reservation/next").
        then(response => {
            commit('setReservation', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    async fetchReservationByTrackingNumber({commit}, trackingNumber){
        commit('setReservationToNull');
        api().get("/reservation/next/"+trackingNumber).
        then(response => {
            commit('setReservation', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    async fetchReservationStats({commit}){
        api().get("/reservation/basic-statistics").
        then(response => {
            commit('setReservationStats', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    async fetchReservationExtensions({commit}, data){
        api().get("/reservation/extension?status="+data.statuses+"&page="+data.page+"&per_page="+data.limit).
        then(response => {
            commit('setReservationExtensions', response.data);
        }).catch(error => {
            commit("setReservationExtensionsError", error.response)
        });
    },
    async fetchReservationAppeals({commit}, data){
        api().get("/reservation/appeal?status=Pending&page="+data.page+"&per_page="+data.limit).
        then(response => {
            commit('setReservationAppeals', response.data);
        }).catch(error => {
            commit("setReservationExtensionsError", error.response)
        });
    },
    async fetchReservationExtensionStats({commit}){
        api().get("/reservation/extension/basic-statistics").
        then(response => {
            commit('setReservationExtStats', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    async fetchReservationCount({commit}){
        api().get("/reservation/count").
        then(response => {
            commit('setReservationCount', response);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    async fetchAnalysisReport({commit}, names){
        api().post("/namesearch/analysis-report", {names:names}).
        then(response => {
            commit('setAnalysisReport', response.data);
        }).catch(error => {
            commit("setAnalysisReportError", error.response)
        });
    },
    emptyAnalysisReport({commit}){
        commit('setAnalysisReport', null);
    },
    updateReservationStatus({commit}, data){
        api().post("/reservation/status", data).
        then(response => {
            commit('setReservationActionSuccess', response.data);
        }).catch(error => {
            commit("setReservationActionError", error.response)
        });
    },
    updateAppealStatus({commit}, data){
        api().post("/reservation/appeal/status", data).
        then(response => {
            commit('setAppealAction', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    extendReservation({commit}, data){
        api().post("/reservation/extension/status", data).
        then(response => {
            commit('setReservationAction', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    assignReservationTask({commit}, data){
        api().post("/reservation/assign", data).
        then(response => {
            commit('setReservationAssignmentAction', response.data);
        }).catch(error => {
            commit("setReservationAssignmentError", error.response)
        });
    },
    assignEscalationReservationTask({commit}, data){
        api().post("/reservation/escalation/assign", data).
        then(response => {
            commit('setReservationAssignmentAction', response.data);
        }).catch(error => {
            commit("setReservationAssignmentError", error.response)
        });
    },
    escalateReservationTask({commit}, data){
        api().post("/reservation/escalation", data).
        then(response => {
            commit('setReservationEscalationAction', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    reEscalateReservationTask({commit}, data){
        api().post("/reservation/escalation/re", data).
        then(response => {
            commit('setReservationEscalationAction', response.data);
        }).catch(error => {
            commit("setReservationError", error.response)
        });
    },
    async fetchReservationNumberDetails({commit}, reservationNumber){
        api().get("/reservation/approved?reservationNo="+reservationNumber).
        then(response => {
            commit('setReservationNumberDetails', response.data.data);
        }).catch(error => {
            commit("setReservationDetailsError", error.response.data)
        });
    },
    async fetchReservationDetails({commit}, reservationNumber){
        api().get("/reservation/by-tracking-no?trackingNo="+reservationNumber).
        then(response => {
            commit('setReservationNumberDetails', response.data);
        }).catch(error => {
            commit("setReservationDetailsError", error.response)
        });
    },
    async fetchReservationInfo({commit}, data) {
        await api().get("/reservation/draft?trackingNumber="+data)
            .then((response) => {
                commit("setReservationDraftInfo", response);
            })
            .catch((error) => {
                commit("setEntityTypeError", error.response);
            });
    },
    async fetchReservationResponses({commit}){
        api().get("/configuration/appeal-message/all").
        then(response => {
            commit('setReservationResponses', response.data);
        }).catch(error => {
            commit("setReservationResponseError", error.response)
        });
    },
    async removePaymentFromReservationApplication({commit}, tNo){
        api().post("/reservation/remove-payment?trackingNo="+tNo).
        then(response => {
            commit('setRemovePaymentDetails', response.data);
        }).catch(error => {
            commit("setRemovePaymentDetailsError", error.response)
        });
    },
};

const mutations = {
    setReservations: (state, response) =>{
        state.reservations = response;
    },
    setReservationResponses: (state, response) =>{
        state.responses = response;
    },
    setReservationResponseError: (state, response) =>{
        state.error = response.data;
    },
    setReservationEscalations: (state, response) =>{
        state.escalations = response;
        state.escalations_menu = response;
    },
    setReservation: (state, response) =>{
        state.reservation = response.data;
    },
    setReservationToNull: (state) =>{
        state.reservation = {};
    },
    setReservationStatuses: (state, response) =>{
        state.reservationStatuses = response;
    },
    setReservationStats: (state, response) =>{
        state.reservation_stats = response;
    },
    setReservationExtStats: (state, response) =>{
        state.reservation_ext_stats = response;
    },
    setReservationExtensions: (state, response) =>{
        state.reservation_extensions = response;
        state.reservation_extensions_menu = response;
    },
    setReservationExtensionsError: (state, response) =>{
        state.reservation_extensions_error = response;
    },
    setReservationAppeals: (state, response) =>{
        state.reservation_appeals = response;
        state.reservation_appeals_menu = response;
    },
    setReservationCount: (state, response) =>{
        state.reservation_count = response.data;
    },
    setReservationAction: (state, response) =>{
        state.success = response;
    },
    setAppealAction: (state, response) =>{
        state.success = response;
    },
    setReservationAssignmentAction: (state, response) =>{
        state.successAssignment = response;
    },
    setReservationActionSuccess: (state, response) =>{
        state.reservationFinalDecision = response;
    },
    setReservationActionError: (state, response) =>{
        state.reservationFinalDecisionError = response;
    },
    setReservationEscalationAction: (state, response) =>{
        state.successEscalation = response;
    },
    setAnalysisReport: (state, response) =>{
        state.analysis = response
    },
    setReservationError: (state, error) =>{
        state.error = error;
    },
    setReservationFetchError: (state, error) =>{
        state.fetch_error = error;
    },
    setReservationEscalationError: (state, error) =>{
        state.error = error;
    },
    setReservationAssignmentError: (state, error) =>{
        state.errorAssignment = error;
    },
    setAnalysisReportError: (state, error) =>{
        state.analysis_error = error;
    },
    setReservationNumberDetails: (state, response) =>{
        state.reservation_result = response;
    },
    setReservationDraftInfo: (state, response) =>{
        state.reservation_result = response;
    },
    setReservationReverted: (state, response) => {
        state.reservationRevert = response.data;
    },
    setRemovePaymentDetails:(state, response) =>{
        state.payment_removed = response.data
    },
    setRemovePaymentDetailsError:(state, response) =>{
        state.payment_removed_error = response.data
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};