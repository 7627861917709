<template>
  <div class="bg-white rounded-sm m-2">

    <p v-if="trackingNo" class="mt-2 text-sm text-gray-600 bg-opacity-75 p-2 rounded alertStyle"
       style="border: 1px solid #3692cc;">
      <a href="#" class="font-medium" style="color: #3692cc;">
        <small v-if="trackingNo"><b>Tracking Number: {{ trackingNo }}</b></small>
      </a>
    </p>

      <form-wizard
              v-if="status==='PROVISIONAL_APPOINTMENT'"
              shape="circle"
              title=""
              stepSize="xs"
              subtitle=""
              color="#3692cc"
              ref="wizard"
              error-color="#e74c3c"
              :hide-buttons="true"
              :startIndex="currentTab">


          <tab-content title="Reasons" tabindex="0">
              <Form20Reasons
                      v-on:backTab="prev"
                      v-on:nextTab="next"
              ></Form20Reasons>
          </tab-content>

          <tab-content title="Appoint practitioner" tabindex="1">

              <appoint-practitioner
                      v-on:nextTab="next"
              ></appoint-practitioner>


          </tab-content>

          <tab-content title="Attachments" tabindex="2">

              <b-row>
                  <b-col cols="8">
                      <insolvency-preview></insolvency-preview>
                  </b-col>
                  <b-col cols="4">
                      <insolvency-file-import></insolvency-file-import>
                  </b-col>
              </b-row>
              <b-row class="mt-3">
                  <b-col cols="12">
                      <button id="backBtn" type="button" class="back-button" @click="prev">
           <span class="mx-3">
              Back
           </span>
                      </button>


                      <button id="nextBtn" type="button" class="button float-end" @click="submitApplication">
           <span class="mx-3">
              Submit
              <b-spinner small v-if="loading"></b-spinner>
           </span>
                      </button>
                  </b-col>
              </b-row>

          </tab-content>


      </form-wizard>

    <form-wizard
            v-else
        shape="circle"
        title=""
        stepSize="xs"
        subtitle=""
        color="#3692cc"
        ref="wizard"
        error-color="#e74c3c"
        :hide-buttons="true"
        :startIndex="currentTab">

        <tab-content title="Petition" tabindex="0">

            <petition-court
                    v-on:backTab="prev"
                    v-on:nextTab="next"
            ></petition-court>

        </tab-content>

        <tab-content title="Court Order" tabindex="1">

            <court-details
                    v-on:backTab="prev"
                    v-on:nextTab="next"
            ></court-details>

        </tab-content>

<!--      <tab-content title="Details" tabindex="2">-->
<!--        <Form20Details-->
<!--            v-on:nextTab="next"-->
<!--        ></Form20Details>-->
<!--      </tab-content>-->
      <tab-content title="Reasons" tabindex="3">
        <Form20Reasons
            v-on:backTab="prev"
            v-on:nextTab="next"
        ></Form20Reasons>
      </tab-content>

<!--      <tab-content title="Court Order Details" tabindex="2">-->

<!--          <court-order-details-->
<!--                  v-on:backTab="prev"-->
<!--                  v-on:nextTab="next"-->
<!--          ></court-order-details>-->

<!--      </tab-content>-->
        <tab-content title="Appoint practitioner" tabindex="4">

            <appoint-practitioner
            v-on:nextTab="next"
            ></appoint-practitioner>

<!--            <b-row class="mb-2">
                <b-col class="flex" cols="4">
                    <label class="my-auto">Appointed Practitioner's Registration Number</label>
                </b-col>
                <b-col cols="8">
                    <b-input-group class="mb-2">
                        <b-form-input @input="searchPractitionerUpn" v-model.trim="upn"
                                      :class="[practitioner && practitionerActiveStatus ? 'focus:border-green border-green':'focus:border-red border-red' ,'form-text noFocus my-0 border-r-0']"></b-form-input>
                        <template #append>
                            <b-input-group-text
                                    :class="[practitioner && practitionerActiveStatus? 'border-green':'border-red', 'bg-white  border-l-0 rounded-l-none']">
                                <i v-if="searchingPrn" style="font-size: 24px;"
                                   class="text-red fa fa-spinner fa-spin fa-fw"></i>
                                &lt;!&ndash;                                            <i v-if="!searchingPrn && (!practitioner || !practitionerActiveStatus)" style="font-size: 24px;" class="text-red fa fa-exclamation-triangle fa-fw"></i>&ndash;&gt;
                                <i v-if="practitioner" style="font-size: 24px;"
                                   class="text-green fa fa-check fa-fw"></i>
                            </b-input-group-text>
                        </template>
                    </b-input-group>
                    <span v-if="practitionerError !== null"
                          class="bg-red px-2 py-1 text-xs rounded-full text-white">{{
                            practitionerError
                        }}</span>
                </b-col>
            </b-row>
            <b-row class="mb-2" v-if="practitionerAvatarUrl !== null">
                <b-col cols="4"></b-col>
                <b-col cols="8">

&lt;!&ndash;                    <b-avatar :src="practitionerAvatarUrl" class="mr-3"></b-avatar>
                    <span class="mr-auto">{{ practitionerFullName }}</span>
                    <span v-if="practitionerActiveStatus"
                          class="bg-green px-2 py-1 text-xs rounded-sm ml-2 text-white">ACTIVE</span>
                    <span v-else class="bg-red px-2 py-1 text-xs rounded-sm ml-2 text-white">INACTIVE</span>
               &ndash;&gt; </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col class="flex" cols="4">
                    <label class="my-auto">Practitioner Role<i style="font-size: 10px"
                                                               class="fas fa-asterisk ml-1 text-red"></i></label>
                </b-col>
                <b-col cols="8">
                    <b-form-group
                            class="mb-2"
                            id="input-group-1"
                            label-for="input-1">
                        <b-form-select
                                class="form-select noFocus"
                                :options="appointeeRolesList"
                                v-model="specialResolution.practitionerRole"
                                :required="!process.specialResolutions.length"
                        >
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>-->

        </tab-content>

      <tab-content title="Attachments" tabindex="5">

        <b-row>
          <b-col cols="8">
            <insolvency-preview></insolvency-preview>
          </b-col>
          <b-col cols="4">
            <insolvency-file-import></insolvency-file-import>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="12">
            <button id="backBtn" type="button" class="back-button" @click="prev">
           <span class="mx-3">
              Back
           </span>
            </button>


            <button id="nextBtn" type="button" class="button float-end" @click="submitApplication">
           <span class="mx-3">
              Submit
              <b-spinner small v-if="loading"></b-spinner>
           </span>
            </button>
          </b-col>
        </b-row>

      </tab-content>


    </form-wizard>


  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
// import CourtOrderDetails from "@/views/home/services/insolvency/winding-up/CourtDetails.vue";
import InsolvencyPreview from "@/views/home/services/insolvency/winding-up/components/InsolvencyPreview.vue";
import InsolvencyFileImport from "@/views/home/services/insolvency/winding-up/components/InsolvencyFileImport.vue";
import Form20Reasons from "@/views/home/services/insolvency/winding-up/components/Form20Reasons.vue";
import {api} from "@/app/services/api.service";
import AppointPractitioner from "@/views/home/services/insolvency/winding-up/components/AppointPractitioner.vue";
import CourtDetails from "@/views/home/services/insolvency/winding-up/CourtDetails.vue";
import PetitionCourt from "@/views/home/services/insolvency/winding-up/petition/PetitionCourt.vue";

export default {
  name: "CourtWindingUp",
  components: {
      PetitionCourt,
      CourtDetails,
      AppointPractitioner,
      Form20Reasons,
      InsolvencyFileImport,
      InsolvencyPreview,
      // CourtOrderDetails,
      FormWizard, TabContent
  },
  data () {
    return {
      currentTab: 0,
      trackingNo: null,
      loading: false,
        status: "Draft",
    }
  },
  mounted() {
    if (sessionStorage.getItem("tracking-number")) {
      this.trackingNo = sessionStorage.getItem("tracking-number");
      this.fetchWindingDraftData(this.trackingNo);
      sessionStorage.removeItem("tracking-number");
    }
  },
  methods: {
    ...mapActions([
        "fetchWindingDraftData"
    ]),
    next(trackingNumber) {
      if(trackingNumber) {
        this.trackingNo = trackingNumber
        this.fetchWindingDraftData(trackingNumber)
        this.$refs.wizard.nextTab();
      }
    },
    moveNext() {
      this.loading = true;
      this.$refs.wizard.nextTab();
    },
    prev() {
      this.$refs.wizard.prevTab();
    },
      async submitApplication(){
          let payload = {
              trackingNumber: this.trackingNo,
          };
              await api().post("/insolvency/process/submit", payload).then(response => {
                     this.loading = false;
                     this.$router.push({"name": "insolvency-success", params: {trackingNumber: response.data.data.trackingNumber}});
                  })
                  .catch(error => {
                     this.errorToast("Error", error.response.data.message);
                  });
          }
  },

  computed: {
    ...mapGetters(
        [
          "getWindingDraftData",
          "getWindingDraftDataError"
        ]
    ),
  },
  watch: {
      getWindingDraftData(data){
          if(data && data.data){
              let  info = data.data
              this.status = info.status
              this.trackingNo = info.trackingNumber ? info.trackingNumber : null
          }
      },

  },
};
</script>

<style scoped>
.custom-checkbox > [type="checkbox"],
.custom-checkbox > label{
  margin-bottom:0px !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox > [type="checkbox"]:not(:checked),
.custom-checkbox > [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.custom-checkbox > [type="checkbox"]:not(:checked) + label,
.custom-checkbox > [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}

.custom-checkbox > [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left:0;
  top: 50%;
  margin-top:-8px;
  width: 17px;
  height: 17px;
  border: 1px solid #157EC2;
  background-color: #157EC2;
  border-radius: 2px;
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label:before{
  content: '';
  position: absolute;
  left:0;
  top: 50%;
  margin-top:-8px;
  width: 17px;
  height: 17px;
  border: 2px solid #157EC2;
  background-color: #FFFFFF;
  border-radius: 2px;
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label:after,
.custom-checkbox > [type="checkbox"]:checked + label:after {
  font-family: 'FontAwesome';
  content: '\f00c';
  position: absolute;
  top: 50%;
  margin-top:-9px;
  font-size: small;
  left: 2px;
  color: #FFFFFF;
  xtransition: all .2s;
}

.custom-checkbox > [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.custom-checkbox > [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.custom-checkbox > [type="checkbox"][data-indeterminate] + label:after,
.custom-checkbox > [type="checkbox"][data-indeterminate] + label:after {
  opacity: 1;
  transform: scale(2);
}

.custom-checkbox > [type="checkbox"]:disabled:not(:checked) + label:before,
.custom-checkbox > [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  background-color: #157EC2;
  border-color: #157EC2;
  cursor: not-allowed;
  opacity: 1;
}

.custom-checkbox > [type="checkbox"]:disabled:checked + label:after {
  color: #FFFFFF; cursor: not-allowed;
}

.custom-checkbox > label:hover:before {
  border: 2px solid #157EC2 !important;
}

.custom-checkbox > [type="checkbox"]:disabled:not(:checked) + label:hover:before,
.custom-checkbox > [type="checkbox"]:disabled:checked + label:hover:before{
  border: 1px solid #157EC2 !important;
}


.button {
  @apply bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}


.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}
</style>