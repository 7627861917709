<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="6"><span class="font-bold">Plot</span></b-col>
        <b-col cols="6">{{address.plot}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><span class="font-bold">Street</span></b-col>
        <b-col cols="6">{{address.street}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><span class="font-bold">Postal Address</span></b-col>
        <b-col cols="6">{{address.postalAddress}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <p class="font-bold">Description</p>
          <b-textarea disabled :value="address.description" class="text-xs"></b-textarea>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "AnnualReturnAddressViewer",
  props:{
    address:Object
  }
};
</script>

<style scoped>

</style>