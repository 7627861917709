import { render, staticRenderFns } from "./DispensationFormFilesEscalated.vue?vue&type=template&id=06df1ad9&scoped=true&"
import script from "./DispensationFormFilesEscalated.vue?vue&type=script&lang=js&"
export * from "./DispensationFormFilesEscalated.vue?vue&type=script&lang=js&"
import style0 from "./DispensationFormFilesEscalated.vue?vue&type=style&index=0&id=06df1ad9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06df1ad9",
  null
  
)

export default component.exports