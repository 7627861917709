<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-details" size="xl" hide-footer ref="searchDetails" title="Search Details">
        <SearchDetails v-if="selectedSearch" :task="selectedSearch.data"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Searches</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="stats !== null" class="flex mx-2 mt-2">
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="waiting">
          <span class="h3 mx-auto">{{stats.draft}}</span>
        </div>
        <div class="waiting">
          <span class="text-sm mx-auto">Draft</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="normal">
          <span class="h3 mx-auto">{{stats.pending}}</span>
        </div>
        <div class="normal">
          <span class="text-sm mx-auto">Pending</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="positive">
          <span class="h3 mx-auto">{{stats.approved}}</span>
        </div>
        <div class="positive">
          <span class="text-sm mx-auto">Approved</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning-secondary">
          <span class="h3 mx-auto">{{stats.total}}</span>
        </div>
        <div class="warning-secondary">
          <span class="text-sm mx-auto">Total</span>
        </div>
      </div>

    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <b-row>
          <b-col cols="3">
            <b-form-input
              class="text-gray text-sm noFocus mx-2 my-2"
              style="padding:6px 5px;"
              v-model="trackingNumber"
              type="text"
              placeholder="Search by Tracking Number"
              required
              v-on:keyup.enter="fetch">
            </b-form-input>
          </b-col>
          <b-col cols="2">
            <b-form-group>
              <b-form-select
                v-model="statuses"
                v-on:change="fetch"
                class="form-select noFocus text-gray mx-2 my-2 text-sm">
                <b-select-option :value="null">--Filter by Status--</b-select-option>
                <b-select-option value="DRAFT">Draft</b-select-option>
                <b-select-option value="PENDING">Pending</b-select-option>
                <b-select-option value="APPROVED">Approved</b-select-option>
                <b-select-option value="AWAITING_SEARCH_RESULTS">Awaiting Search Results</b-select-option>
                <b-select-option value="AWAITING_PAYMENT_CONFIRMATION">Confirming Payment</b-select-option>
                <b-select-option value="PAYMENT_CANCELLED">Payment Canceled</b-select-option>
                <b-select-option value="PAYMENT_FAILED">Payment Failed</b-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="mx-auto">
          <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">Tracking No.</th>
                <th class="t-header">BRN</th>
                <th class="t-header">Name</th>
                <th class="t-header">Entity Type</th>
                <th class="t-header text-center">Applicant</th>
                <th class="t-header text-center">Status</th>
                <th class="t-header text-center">Action</th>
                <th class="t-header text-center">Date</th>
              </tr>
              </thead>

              <tbody>
              <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in searches" :key="idx">
                <td class="t-body text-blue font-bold cursor-pointer hover:underline" @click="showDetails(submission)">{{submission.data.trackingNumber}}</td>
                <td class="t-body">
                  <p class="font-bold">
                    {{submission.data.searchValueOrBrn}}
                    <span class="text-blue">({{submission.data.brnSource}})</span>
                  </p>
                </td>
                <td class="t-body"> {{submission.data.entityName}}</td>
                <td class="t-body text-capitalize"> {{submission.data.entityType.toLowerCase()}}</td>
                <td class="t-body text-capitalize text-center"> {{submission.data.accountHolder.toLowerCase()}}</td>
                <td class="t-body text-capitalize text-center">
                  <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                    <span :class="(submission.data.status === 'PENDING') ? 'text-gray'
                    : (submission.data.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION')? 'text-gray'
                    : (submission.data.status.toUpperCase() === 'PAYMENT_FAILED')? 'text-red'
                    : (submission.data.status === 'ASSIGNED') ? 'text-blue'
                    : (submission.data.status === 'APPROVED') ? 'text-green'
                    : (submission.data.status === 'QUERIED') ? 'text-orange' : ''">
                        <span v-if="submission.data.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                          Confirming Payment
                        </span>
                        <span v-else-if="submission.data.status.toUpperCase() === 'PENDING'">
                          In Queue
                        </span>
                        <span v-else>
                          {{ submission.data.status.toLowerCase().replaceAll("_", " ") }}
                        </span>
                    </span>
                  </p>
                </td>
                <td class="t-body text-center">
                  <button
                    v-if="submission.data.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION' || submission.data.status.toUpperCase() === 'DRAFT'"
                    class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="move(submission)">
                    <i class="fa fa-reply mx-1"></i>
                    Move to Queue <b-spinner v-if="loadingMoveTask" small></b-spinner>
                  </button>
<!--                  <button-->
<!--                    v-if="submission.data.status.toUpperCase() === 'PAYMENT_FAILED'"-->
<!--                    class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="move(submission)">-->
<!--                    Remove Payment <b-spinner v-if="loadingRemovePayment" small></b-spinner>-->
<!--                  </button>-->
                  <p v-else class="text-xs">No action</p>
                </td>
                <td class="t-body text-center">{{submission.data.dateOfSearch}}</td>
              </tr>
              <tr>
                <td v-if="searches.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                  <span class="text-sm">No results found</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
          <b-col cols="1">
            <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
          </b-col>
          <b-col cols="1">
            <b-row>
              <b-col cols="12">
                <p class="paginate-details text-center">
                  <b-badge class="bg-blue text-white">
                    {{currentPage}}/{{pagination.totalPages}}
                  </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" >
            <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
          </b-col>
          <b-col class="9"></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchDetails from "@/views/home/services/search_reports/searches/SearchDetails.vue";

export default {
  name: "SearchesRequests",
  components: { SearchDetails },
  data(){
    return{
      loading:false,
      currentPage:1,
      limit:12,
      statuses:null,
      searches:[],
      search:null,
      trackingNumber:null,
      stats:{
        pending:0,
        draft:0,
        approved:0,
        total:0
      },
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      selectedSearch:null,
      loadingMoveTask:false,
      loadingRemovePayment:false,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchSearches", "fetchSearchesStats"]),
    fetch(){
      let options = this.statuses
      if(this.statuses === null){
        options = "PENDING,APPROVED,DRAFT,AWAITING_PAYMENT_CONFIRMATION,AWAITING_SEARCH_RESULTS,PAYMENT_CANCELLED,PAYMENT_FAILED"
      }
      let data = {
        status:options,
        trackingNumber:this.trackingNumber,
        page:this.currentPage,
        limit:this.limit
      }
      this.loading = true
      this.fetchSearches(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    move(application){
      if(application.data.searchPayments.length === 0){
        this.errorToast("Error", "No payment done yet for this search")
      }else{
        let data = {
          userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
          prn: application.data.searchPayments[0].prn
        }
        this.loadingMoveTask = true
        this.redoTransaction(data)
      }

    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    showDetails(data){
      this.selectedSearch = data
      this.$refs.searchDetails.show()
    }
  },
  computed:{
    ...mapGetters(["getRecordSearchSubmissions", "getRecordSearchStatistics"])
  },
  watch:{
    getRecordSearchSubmissions(data){
      console.log(data)
      this.loading = false
      this.searches = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
      this.fetchSearchesStats()
    },
    getRecordSearchStatistics(data){
      console.log(data)
      this.stats = data
    }
  }
};
</script>

<style scoped>

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.warning-secondary{
  @apply flex text-flopurple;
}

.negative{
  @apply flex text-red;
}
.stat-count{
  display: inline;
  width: 15%;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

</style>