<template>
  <div class="flex">
    <div class="w-full">
      <div class="flex">
        <ActionHeader class="w-full"/>
      </div>
      <div class="flex">
        <div class="w-full">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionHeader from "@/views/home/headers/ActionHeader";
export default {
  name: "SubmissionDetails",
  components: {ActionHeader},
  data(){
    return{
      trackingNumber:null
    }
  },
  mounted() {
    let urlParts = window.location.href.split("/")
    this.trackingNumber = urlParts[urlParts.length - 1]
  }
}
</script>

<style scoped>

</style>