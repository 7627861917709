<template>
    <div>

        <b-row class="mt-3">
            <b-col cols="6">

                <div class="mb-2">
                    <label class="text-gray" style="font-size: 14px;">Company BRN</label>
                    <b-form-group>
                        <b-form-input
                                style="color: #505050;"
                                id="companyName"
                                class="form-text noFocus font-bold text-sm"
                                type="text"
                                :disabled="checkBrn"
                                v-model="form.brn"
                                maxlength="14"
                                @input="validateBrn"
                                required>
                        </b-form-input>
                    </b-form-group>
                    <b-progress v-if="loaderProgress" :value="100" height="3px" variant="#007ac5"
                                animated="true"></b-progress>
                    <div class="mt-3" v-if="brnErrorMessage">
                        <b-alert variant="danger" show>
                            <small class="text-red" style="font-size: 14px;">{{ brnErrorMessage }}</small>
                        </b-alert>
                    </div>
                </div>


                <div class="mb-2">
                    <label class="text-gray" style="font-size: 14px;">Company Name</label>
                    <b-form-group>
                        <b-form-input
                                style="color: #505050;"
                                id="companyName"
                                class="form-text noFocus font-bold text-sm"
                                type="text"
                                :disabled="true"
                                v-model="form.companyName"
                                required>
                        </b-form-input>
                    </b-form-group>
                </div>


                <div class="mb-2">
                    <label class="text-gray" style="font-size: 14px;">Date of Registration<i style="font-size: 10px"

                                                                                             class="fas fa-asterisk ml-1 text-red"></i></label>
                    <datepicker
                            disabled="disabled"
                            placeholder="dd/mm/yyyy"
                            :disabled-dates="{from:new Date()}"
                            class="noFocus text-sm"
                            :typeable="false"
                            bootstrap-styling
                            id="dateOfBirth"
                            format="dd/MM/yyyy"
                            v-model="form.dateOfIncorporation"
                    ></datepicker>
                    <b-form-group>
                        <label class="text-gray"
                               style="font-size: 14px;">
                            Country of Incorporation
                            <i style="font-size: 10px"
                               class="fas fa-asterisk ml-1 text-red"></i></label>

                        <b-form-input
                                style="color: #505050;"
                                id="companyName"
                                class="form-text noFocus font-bold text-sm"
                                type="text"
                                :disabled="true"
                                v-model="form.countryOfIncorporation"
                                required>
                        </b-form-input>
                    </b-form-group>



                </div>


            </b-col>

            <b-col cols="6">

                <div class="mb-2">
                    <label class="text-gray" style="font-size: 14px;">Entity Type</label>
                    <b-form-group>
                        <b-form-input
                                style="color: #505050;"
                                id="companyName"
                                class="form-text mb-1 noFocus text-sm"
                                type="text"
                                :disabled="true"
                                v-model="form.entityType"
                                required>
                        </b-form-input>
                    </b-form-group>
                </div>

                <div class="mb-2">
                    <label for="applicationStatus" class="text-gray" style="font-size: 14px;">Type of Application
                        <i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-select disabled class="form-select noFocus text-gray text-sm" v-model="form.type">
                        <b-select-option v-for="(nature,idx) in disputeType" :value="nature.value" :key="idx">
                            {{ nature.text }}
                        </b-select-option>
                    </b-select>
                </div>


                <div class="mb-2">
                    <label for="applicationStatus" class="text-gray" style="font-size: 14px;">Nature of Application
                        <i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-select class="form-select noFocus text-gray text-sm" v-model="form.natureOfApplicant"
                              @change="toggleNature">
                        <b-select-option disabled value="">Select Nature of Dispute</b-select-option>
                        <b-select-option v-for="(nature,idx) in natureOfDispute" :value="nature.value" :key="idx">
                            {{ nature.text }}
                        </b-select-option>
                    </b-select>
                </div>

            </b-col>

        </b-row>


        <b-row class="my-2 mb-5">
            <b-col>
                <label for="applicationStatus" class="text-gray" style="font-size: 14px;">Nature Of Applicant
                    Description
                    <i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                <b-form-textarea
                        maxlength="500"
                        id="facts"
                        class="form-text noFocus"
                        type="text"
                        v-model="form.natureOfApplicantDescription"
                        required>
                </b-form-textarea>
            </b-col>
            <b-col>
                <label for="applicationStatus" class="text-gray" style="font-size: 14px;">Subject
                    <i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                <b-form-textarea
                        maxlength="500"
                        id="facts"
                        class="form-text noFocus"
                        type="text"
                        v-model="form.subject"
                        required>
                </b-form-textarea>
            </b-col>
        </b-row>

        <b-row class="my-2 mb-5">

            <b-col>


                        <div class="mb-2">
                            <b-form-group class="text-gray" label="Is this entity the complainant ?" v-slot="{ ariaDescribedby }">

                                <b-form-radio-group
                                        id="radio-group-1"
                                        v-model="form.subjectEntityIsComplainant"
                                        :options="complianceOptions"
                                        :aria-describedby="ariaDescribedby"
                                        name="radio-options"
                                ></b-form-radio-group>
                            </b-form-group>
<!--                          <input
                              v-model="form.subjectEntityIsComplainant"
                              style="outline: none 0 !important; box-shadow: none;"
                              class="form-check-input me-1" type="checkbox" :value="false" id="subjectEntityIsComplainant">
                          <label class="form-check-label cursor-pointer text-gray ml-3" for="subjectEntityIsComplainant">Is subject Entity the complainant?</label>
                      -->  </div>


            </b-col>
        </b-row>

        <b-row >
            <b-col cols="12">

                <b-row>


                    <b-col cols="12" class="">

                        <div class="mb-4">
                            <p>{{ form.subjectEntityIsComplainant ? "Representatives" : "Complainants"}} </p>
                            <hr/>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <table style="width: 100%; color: #505050;" class="mt-1 table">
                            <thead>
                            <tr class="border-b border-t border-grey text-sm">
                                <th style="width: 5%;">#</th>
                                <th style=" width:25%;">Name</th>
                                <th style=" width:20%;">ID Number / BRN</th>
                                <th>Position</th>
                                <th class="float-end">
                                    <div class="mr-4">Action</div>
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </b-col>
                </b-row>
                <div class="accordion" role="tablist">
                    <b-card v-for="(complainant, index) in form.complainants" :key="index" no-body class="mb-1">
                        <b-card-header>
                            <table v-b-toggle="'accordion-'+index" style="width: 100%;">
                                <tbody>

                                <tr class="text-sm" v-if="complainant.entityType === 'INDIVIDUAL'">
                                    <td style="width: 5%;">{{ index + 1 }}</td>
                                    <td style=" width:25%;">
                                        <div style="width: 200px; overflow-wrap: break-word;">
                        <span
                                style="text-transform: capitalize"> {{
                                complainant.individual ? complainant.individual.surName : ""
                            }} </span>
                                            <span
                                                    style="text-transform: capitalize">{{
                                                    complainant.individual ? complainant.individual.givenName : ""
                                                }} </span>
                                            <span
                                                    style="text-transform: capitalize">{{
                                                    complainant.individual ? complainant.individual.middleName : ""
                                                }}</span>
                                        </div>
                                    </td>
                                    <td style=" width:20%;">
                                        {{ complainant.individual ? complainant.individual.idNumber : "" }}
                                    </td>
                                    <td style="width:30%;">
                                        {{ complainant.individual ? complainant.position : "" }}

                                    </td>
                                    <td class="mr-5 float-end">

                    <span class="when-opened">
                       <font-awesome-icon icon="chevron-down"/>
                   </span>
                                        <i class="fa fa-times ml-3" v-if="index > 0" @click="deleteComplainant(index)"
                                           style="font-size: 20px; color: red;"></i>
                                    </td>
                                </tr>


                                <tr class="text-sm" v-if="complainant.entityType === 'CORPORATE'">
                                    <td style="width: 5%;">{{ index + 1 }}</td>
                                    <td style=" width:25%;">
                                        <div style="width: 200px; overflow-wrap: break-word;">
                        <span
                                style="text-transform: capitalize"> {{
                                complainant.corporate ? complainant.corporate.registeredName : ""
                            }} </span>
                                        </div>
                                    </td>
                                    <td style=" width:20%;">{{
                                            complainant.corporate ? complainant.corporate.brn : ""
                                        }}
                                    </td>
                                    <td style="width:30%;">
                                        {{ complainant.position ? complainant.position : "" }}

                                    </td>
                                    <td class="mr-5 float-end">

                    <span class="when-opened">
                       <font-awesome-icon icon="chevron-down"/>
                   </span>

                                        <i class="fa fa-times ml-3" v-if="index > 0" @click="deleteComplainant(index)"
                                           style="font-size: 20px; color: red;"></i>

                                    </td>
                                </tr>


                                </tbody>
                            </table>
                        </b-card-header>

                        <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                            <b-row class="mx-auto">

                                <b-col cols="12" sm="12" md="12" lg="6" class="pt-3 pb-3">
                                    <b-form-group>
                                        <b-form-select
                                                id="entityType"
                                                class="form-select noFocus text-gray text-sm"
                                                v-model="complainant.entityType"
                                                :index="index"
                                                :options="entityIndividualTypeList">
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" class="pt-3 pb-3">

                                    <div>
                                        <p>Select Position <i style="font-size: 10px"
                                                              class="fas fa-asterisk ml-1 text-red"></i></p>
                                        <div class="mb-3 mt-2">
                                            <label style="cursor: pointer;" class="inline-flex items-center text-sm">
                                                <b-form-group>
                                                    <b-form-select
                                                            id="entityType"
                                                            class="form-select noFocus text-gray text-sm"
                                                            v-model="complainant.position"
                                                            :index="index"
                                                            :options="positionList">
                                                    </b-form-select>
                                                </b-form-group>
                                            </label>


                                        </div>
                                    </div>

                                </b-col>

                                <b-col v-if="complainant.entityType === 'INDIVIDUAL'" class="mx-auto mb-4" cols="12">

                                    <SubscriberInformation
                                            :subscriberList="complainant"

                                            ref="subscribe_component"
                                            getNinData="getSubscriberNinData"
                                            :index="index"
                                            :key="index"
                                            v-on:getSubscriberData="getComplainantData"/>
                                </b-col>

                                <b-col v-if="complainant.entityType === 'CORPORATE'" class="mx-auto mb-4" cols="12">
                                    <entity-information
                                            :subscriberList="complainant"
                                            :index="index"
                                            :key="index"
                                            v-on:corporateData="corporateDataComplainant">
                                    </entity-information>
                                </b-col>


                            </b-row>
                        </b-collapse>
                    </b-card>
                </div>


                <b-row>
                    <b-col>

                        <button type="button"
                                class="button-add text-white text-sm mt-2 noFocus"
                                @click="addComplainant">
                   <span class="mx-3">
                      Add complainant
                   </span>
                        </button>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>


        <!--    <b-row v-if="showRespondents && !(form.subjectEntityIsComplainant && (form.type === 'INSOLVENCY_PETITION'))">-->
        <b-row v-if="form.subjectEntityIsComplainant">
            <b-col cols="12">

                <b-row>

                    <b-col cols="12" class="mt-5">

                        <div class="mb-4">
                            <p>Respondents</p>
                            <hr/>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <table style="width: 100%; color: #505050;" class="mt-1 table">
                            <thead>
                            <tr class="border-b border-t border-grey text-sm">
                                <th style="width: 5%;">#</th>
                                <th style=" width:25%;">Name</th>
                                <th style=" width:20%;">ID Number / BRN</th>
                                <th>Position</th>
                                <th class="float-end">
                                    <div class="mr-4">Action</div>
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </b-col>
                </b-row>
                <div class="accordion1" role="tablist">
                    <b-card v-for="(respondent, index) in form.respondents" :key="index" no-body class="mb-1">
                        <b-card-header>
                            <table v-b-toggle="'accordion1-'+index" style="width: 100%;">
                                <tbody>

                                <tr class="text-sm" v-if="respondent.entityType === 'INDIVIDUAL'">
                                    <td style="width: 5%;">{{ index + 1 }}</td>
                                    <td style=" width:25%;">
                                        <div style="width: 200px; overflow-wrap: break-word;">
                        <span
                                style="text-transform: capitalize"> {{
                                respondent.individual ? respondent.individual.surName : ""
                            }} </span>
                                            <span
                                                    style="text-transform: capitalize">{{
                                                    respondent.individual ? respondent.individual.givenName : ""
                                                }} </span>
                                            <span
                                                    style="text-transform: capitalize">{{
                                                    respondent.individual ? respondent.individual.middleName : ""
                                                }}</span>
                                        </div>
                                    </td>
                                    <td style=" width:20%;">
                                        {{ respondent.individual ? respondent.individual.idNumber : "" }}
                                    </td>
                                    <td style="width:30%;">
                                        {{ respondent.individual ? respondent.position : "" }}

                                    </td>
                                    <td class="mr-5 float-end">

                    <span class="when-opened">
                       <font-awesome-icon icon="chevron-down"/>
                   </span>
                                        <i class="fa fa-times ml-3" v-if="index > 0" @click="deleteRespondent(index)"
                                           style="font-size: 20px; color: red;"></i>
                                    </td>
                                </tr>


                                <tr class="text-sm" v-if="respondent.entityType === 'CORPORATE'">
                                    <td style="width: 5%;">{{ index + 1 }}</td>
                                    <td style=" width:25%;">
                                        <div style="width: 200px; overflow-wrap: break-word;">
                        <span
                                style="text-transform: capitalize"> {{
                                respondent.corporate ? respondent.corporate.registeredName : ""
                            }} </span>
                                        </div>
                                    </td>
                                    <td style=" width:20%;">{{
                                            respondent.corporate ? respondent.corporate.brn : ""
                                        }}
                                    </td>
                                    <td style="width:30%;">
                                        {{ respondent.position ? respondent.position : "" }}

                                    </td>
                                    <td class="mr-5 float-end">

                    <span class="when-opened">
                       <font-awesome-icon icon="chevron-down"/>
                   </span>

                                        <i class="fa fa-times ml-3" v-if="index > 0" @click="deleteRespondent(index)"
                                           style="font-size: 20px; color: red;"></i>

                                    </td>
                                </tr>


                                </tbody>
                            </table>
                        </b-card-header>

                        <b-collapse :id="'accordion1-'+index" accordion="my-accordion1" role="tabpanel">
                            <b-row class="mx-auto">

                                <b-col cols="12" sm="12" md="12" lg="6" class="pt-3 pb-3">
                                    <b-form-group>
                                        <b-form-select
                                                id="entityType"
                                                class="form-select noFocus text-gray text-sm"
                                                v-model="respondent.entityType"
                                                :index="index"
                                                :options="entityIndividualTypeList">
                                        </b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" class="pt-3 pb-3">

                                    <div>
                                        <p>Select Position <i style="font-size: 10px"
                                                              class="fas fa-asterisk ml-1 text-red"></i></p>
                                        <div class="mb-3 mt-2">
                                            <label style="cursor: pointer;" class="inline-flex items-center text-sm">

                                                <b-form-group>
                                                    <b-form-select
                                                            id="entityType"
                                                            class="form-select noFocus text-gray text-sm"
                                                            v-model="respondent.position"
                                                            :index="index"
                                                            :options="positionList">
                                                    </b-form-select>
                                                </b-form-group>

                                            </label>


                                        </div>
                                    </div>

                                </b-col>

                                <b-col v-if="respondent.entityType === 'INDIVIDUAL'" class="mx-auto mb-4" cols="12">

                                    <SubscriberInformation
                                            :subscriberList="respondent"

                                            ref="subscribe_component"
                                            getNinData="getSubscriberNinData"
                                            :index="index"
                                            :key="index"
                                            v-on:getSubscriberData="getRespondentData"/>
                                </b-col>

                                <b-col v-if="respondent.entityType === 'CORPORATE'" class="mx-auto mb-4" cols="12">
                                    <entity-information
                                            :subscriberList="respondent"
                                            :index="index"
                                            :key="index"
                                            v-on:corporateData="corporateDataRespondent">
                                    </entity-information>
                                </b-col>


                            </b-row>
                        </b-collapse>
                    </b-card>
                </div>


                <b-row>
                    <b-col>
                        <button type="button"
                                class="button-add text-white text-sm mt-2 noFocus"
                                @click="addRespondent">
                   <span class="mx-3">
                     Add Respondent
                   </span>
                        </button>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>


        <b-row class="mt-3">
            <b-col cols="12">
                <button id="nextBtn" type="button" class="button float-end" @click="onSubmit">
                   <span class="mx-3">
                    Save & Continue <b-spinner small v-if="loading"></b-spinner>
                   </span>
                </button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SubscriberInformation from "@/views/home/services/insolvency/winding-up/components/SubscriberInformation.vue";

import EntityInformation from "@/views/home/services/insolvency/winding-up/components/EntityInformation.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import {api} from "@/app/services/api.service";

export default {
    name: "PetitionCourt",
    components: {
        EntityInformation,
        SubscriberInformation,
        Datepicker,
    },
    data() {
        return {
            loading: false,
            showPlotAndStreet: true,
            loaderProgress: false,
            brnErrorMessage: null,
            positionList: [
                {
                    value: "",
                    text: "--Select position--"
                },

                {
                    value: "CREDITOR",
                    text: "Creditor"
                },
                {
                    value: "DIRECTOR",
                    text: "Director"
                },
                {
                    value: "SHAREHOLDER",
                    text: "Shareholder"
                }, {
                    value: "ADVOCATE",
                    text: "Advocate"
                },
                {
                    value: "LEGAL_REPRESENTATIVE",
                    text: "Legal representative"
                }
            ],
            entityIndividualTypeList: [
                {
                    value: "",
                    text: "--Select Subscriber type--"
                },
                {
                    value: "INDIVIDUAL",
                    text: "Individual"
                },
                {
                    value: "CORPORATE",
                    text: "Entity"
                }

            ],
            countryCodeNumber: null,
            phoneNo: null,
            confirmPhoneNo: null,
            emailErrorResponse: "",
            phoneNumberError: null,
            checkBrn: false,
            form: {
                trackingNumber: null,
                brn: null,
                entityType: null,
                companyName: null,
                countryOfIncorporation: null,
                dateOfIncorporation: null,
                natureOfApplicant: null,
                natureOfApplicantDescription: null,
                subject: null,
                complainants: [
                    {
                        entityType: "INDIVIDUAL",
                        individual: null,
                        corporate: null
                    }
                ],
                respondents: [
                    {

                        entityType: "INDIVIDUAL",
                        individual: null,
                        corporate: null
                    }
                ],
                subjectEntityIsComplainant: false
            },
            complianceOptions: [
                {text: 'Yes', value: true},
                {text: 'No', value: false},
            ],
            contactChangeType: null,
            contactChanges: [
                {text: "Phone number", value: "PHONE"},
                {text: "Email", value: "EMAIL"},
                {text: "Phone and Email", value: "EMAIL_AND_PHONE"},
            ],
            disputeType:
                [
                    {text: "Insolvency Petition", value: "INSOLVENCY_PETITION"},
                ],
            natureOfDispute: [
                {text: "Creditor", value: "CREDITOR"},
                {text: "Shareholders", value: "SHAREHOLDERS"},
                {text: "Other", value: "OTHER"},
            ],
            petitionNatureOfDispute: [

                {text: "Oppression", value: "OPPRESSION"},
                {text: "Mismanagement", value: "MISMANAGEMENT"},
                {text: "Shareholding", value: "SHAREHOLDING"},
                {text: "Directorship", value: "DIRECTORSHIP"},
                {text: "Other", value: "OTHER"}],
            complaintNatureOfDispute: [
                {text: "Rectification", value: "RECTIFICATION"},
                {text: "Name Similarity", value: "NAME_SIMILARITY"},
            ],
            accountNatureOfDispute: [

            ],
            insolvencyNatureOfDispute: [
                {text: "Creditor", value: "CREDITOR"},
                {text: "Directorship", value: "DIRECTORSHIP"},
                {text: "Shareholding", value: "SHAREHOLDING"},
            ],
            // facts: [{ description: null, number: 0 }],
            oldContacts: null
        };

    },
    mounted() {
        this.form.type = "INSOLVENCY_PETITION"
        // let data = this.$userProfile
        // if (data && data.brn) {
        //
        //     this.checkBrn = true
        //     this.form.brn = data.brn
        //     // this.fetchCompanyDetails(data.brn)
        //     this.fetchBrnCompanyNameInfo(data.brn)
        // } else {
        //     this.checkBrn = false
        // }

    },


    methods: {
        ...mapActions(
            [

            ]
        ),

        async validateBrn() {
            if (this.form.brn.length === 14) {
                this.loaderProgress = true
                this.brnErrorMessage = null
                await this.fetchBrnCompanyNameInfo(this.form.brn);
            }
        },
        async fetchBrnCompanyNameInfo(brnData) {
            await api().get("/incorporation/companies/continuous-filing/basic-info-by-brn/" + brnData)
                .then((response) => {
                    if (response) {
                        let draft = response.data.data

                        if (draft.type !== 'company') {
                            return
                        }


                        this.draft = draft
                        this.form.companyName = draft.registeredName
                        this.form.dateOfIncorporation = draft.dateOfIncorporation
                        this.form.entityType = draft.subType === "foreign-company" ? "FOREIGN" : "LOCAL"
                        this.form.countryOfIncorporation = draft.subType === "foreign-company" ? draft.countryOfOrigin : "Uganda"
                        this.loaderProgress = false
                        this.brnErrorMessage = null

                    }

                })
                .catch((error) => {
                    if (error && error.response) {
                        this.loaderProgress = false;
                        this.brnErrorMessage = error.response.data.message
                    }
                });
        },
        residenceInfo(data) {
            this.form.addressOfService.villageId = data.villageId ? data.villageId : null;
        },

        addComplainant() {
            let data = {
                entityType: "INDIVIDUAL",
                individual: null,
                corporate: null,
                position: null


            };

            if (this.form.complainants === null) {
                this.form.complainants = []
            }

            this.form.complainants.push(data);

        },
        addRespondent() {
            let data = {
                entityType: "INDIVIDUAL",
                individual: null,
                corporate: null
            };

            if (this.form.respondents === null) {
                this.form.respondents = []
            }

            this.form.respondents.push(data);

        },
        deleteComplainant(index) {
            this.form.complainants.splice(index, 1);
        },
        deleteRespondent(index) {
            this.form.respondents.splice(index, 1);
        },
        getComplainantData(data) {
            if (data) {
                this.updateComplainantsData(data.data, data.index);
            }
        },
        getRespondentData(data) {
            if (data) {
                this.updateRespondentsData(data.data, data.index);
            }
        },
        corporateDataComplainant(data) {
            if (data) {
                this.corporateInfoComplainant(data.data, data.index);
            }
        },
        corporateInfoComplainant(data, index) {

            this.form.complainants[index].corporate = data; // this.corporateCheckData(data)
            this.form.complainants[index].entityType = "CORPORATE";
            this.form.complainants[index].individual = null;
            this.form.complainants[index].position = "DIRECTOR";
            this.form.complainants[index].descriptionOfPosition = "";

            this.form.complainants[index].formIndex = (index + 1);
        },
        corporateDataRespondent(data) {
            if (data) {
                this.corporateInfoRespondent(data.data, data.index);
            }
        },
        corporateInfoRespondent(data, index) {
            this.form.respondents[index].corporate = data; // this.corporateCheckData(data)
            this.form.respondents[index].entityType = "CORPORATE";
            this.form.respondents[index].individual = null;
            this.form.respondents[index].position = "DIRECTOR";
            this.form.respondents[index].descriptionOfPosition = "";

            this.form.respondents[index].formIndex = (index + 1);
        },
        updateComplainantsData(data, index) {

            this.form.complainants[index].individual = data; //  this.individualCheckData(data);
            this.form.complainants[index].entityType = "INDIVIDUAL";
            this.form.complainants[index].position = "DIRECTOR";
            this.form.complainants[index].descriptionOfPosition = null;
            this.form.complainants[index].corporate = null;
            this.form.complainants[index].formIndex = (index + 1);
        },
        updateRespondentsData(data, index) {

            this.form.respondents[index].individual = data; //  this.individualCheckData(data);
            this.form.respondents[index].entityType = "INDIVIDUAL";
            this.form.respondents[index].position = "DIRECTOR";
            this.form.respondents[index].descriptionOfPosition = null;
            this.form.respondents[index].corporate = null;
            this.form.respondents[index].formIndex = (index + 1);
        },
        onSubmit() {
            this.loading = true;
            if (this.form.legalDocumentSubjectEntity) {
                this.form.legalDocumentSubjectEntity.registrationDate = moment(this.form.legalDocumentSubjectEntity.registrationDate).format("yyyy-MM-DD");
            }



            if (!this.checkBrn) {
                this.form.respondents = []
            }

            this.saveCourtOrderPetition(this.form)

        },
        async saveCourtOrderPetition(data) {
            await api().post("insolvency/petitions", data)
                .then((response) => {
                    if (response) {
                        let trackingNo = response.data.data
                        sessionStorage.setItem("tracking-number", trackingNo)
                        this.$emit("nextTab", trackingNo);
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        this.loading = false;
                        this.errorToast("Error", error.response.data.message);
                    }
                });
        },
        toggleNature() {

        },
        onSelectCountryCode({dialCode}) {
            if (dialCode) {
                this.countryCodeNumber = dialCode;
                this.validatePhone(this.phoneNo);
            }

        },
        validatePhone(data) {
            if (data) {
                //  phone number should allstart with a zero
                if (data.charAt(0) === "0") {
                    this.phoneNo = data.substring(1);
                }

                this.phoneNumberError = (this.phoneNo.startsWith("256") && this.countryCodeNumber === "256") ? "Invalid Phone, <b>" + this.countryCodeNumber + "</b> is already provided for you" : "";

                let code = this.countryCodeNumber ? this.countryCodeNumber : "256";
                this.countryCodeNumber = code;
                // this.form.primaryPhone = code+this.phoneNo
                this.form.officialAccount.newPhone = `${"(" + code + ")"}${this.phoneNo}`;
            }
        },
        validateEmailAddress(email) {
            const re = /\S+@\S+\.\S+/;
            let emailResp = re.test(email);
            this.emailErrorResponse = emailResp ? "" : "Please provide a valid email";
        },


    },

    computed: {
        ...mapGetters(
            [
                "getWindingDraftData"
            ]
        ),



    },

    watch: {
        getWindingDraftData(data) {
            if (data && data.data) {
                let draft = data.data

                this.form.trackingNumber = draft.trackingNumber
                this.form.brn = draft.brn
                this.form.entityType = draft.entityType
                this.form.companyName = draft.companyName
                this.form.dateOfIncorporation = draft.dateOfIncorporation
                this.form.natureOfApplicant = draft.petition.natureOfApplicant
                this.form.natureOfApplicantDescription = draft.petition.natureOfApplicantDescription
                this.form.subjectEntityIsComplainant = draft.petition.subjectEntityIsComplainant
                this.form.subject = draft.petition.subject
                this.form.countryOfIncorporation	 = draft.countryOfIncorporation

                if (draft.stage === "AWAITING_AMENDMENT") {
                    this.form.respondents = draft.petition.respondents.length > 0 ? draft.petition.respondents : draft.amendments[0].respondents;
                    this.form.complainants = draft.petition.complainants > 0 ? draft.petition.complainants : draft.amendments[0].complainants;
                } else {
                    this.form.respondents = draft.petition.respondents;
                    this.form.complainants = draft.petition.complainants;
                }


            }
        },
        getDisputeResolutionsError(data) {
            if (data) {
                this.errorToast("Error", data.message);
                this.loading = false;
            }
        }
    }
};
</script>


<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.button-add{
    @apply bg-green text-white py-1 rounded;
}

.button-add:hover{
    @apply bg-green-dark;
}

.button-add-blue{
    @apply bg-blue text-white py-1 rounded;
}

.button-add-blue:hover{
    @apply bg-blue-dark;
}


.button {
    @apply bg-blue text-white py-2 rounded;
}

.button:hover {
    @apply bg-blue-dark;
}


</style>