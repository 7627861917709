<template>
  <b-row>
    <b-col cols="12">
      <b-card :header="badge">
        <b-row class="mx-1 border-b border-gray_title py-1" v-for="(person, idx) in persons" :key="idx">
        <b-col cols="12" v-if="person.entityType === 'CORPORATE'">
          <b-row class="mt-2">
            <b-col cols="5">
              <span class="text-sm">{{person.corporate.registeredName}}</span><br/>
              <b-badge :class="(person.entityType === 'CORPORATE') ? 'bg-blue' : 'bg-orange'">#{{person.entityType.toLowerCase()}}</b-badge>
            </b-col>
            <b-col cols="3" class="text-sm">
              <span class="mx-1">Date:</span>
              <span>{{dateFormat(person.corporate.date)}}</span>
            </b-col>
            <b-col cols="4" class="float-end">
              <button class="float-end mx-auto bg-blue px-2 py-1 text-sm rounded-sm text-white"
                      @click="showDetails(idx)">
                <p v-if="selectedId === idx">
                  Show Less
                </p>
                <p v-else>
                  View Details
                </p>
              </button>
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="selectedId === idx">
            <b-col>
              <b-row>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Entity Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Registration Number (BRN)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.brn"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.primaryEmail"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.primaryPhone"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Incorporation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.dateOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Company Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="cleanEntityType(person.corporate.subType)"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.countryOfOrigin"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Address in Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.addressInCountryOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="person.corporate.registeredPhysicalAddress">
                  <b-form-group
                    v-if="person.corporate.registeredPhysicalAddress.district"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="District"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredPhysicalAddress.district.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredPhysicalAddress.region"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Region"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredPhysicalAddress.region.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredPhysicalAddress.subcounty"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Sub county"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredPhysicalAddress.subcounty.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredPhysicalAddress.parish"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Parish"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredPhysicalAddress.parish.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col v-else>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Foreign Address"
                    label-for="input-1">
                    <b-form-textarea
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      rows="4"
                      :value="person.corporate.foreignAddress"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    v-if="person.corporate.registeredPhysicalAddress.village"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Village"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredPhysicalAddress.village.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredAddress.postalAddress"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredAddress.plot"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredAddress.street"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" v-if="person.entityType === 'INDIVIDUAL'">
          <b-row class="mt-2">
            <b-col cols="5">
              <span class="text-sm">{{person.individual.givenName}} {{person.individual.surName}}</span><br/>
              <b-badge :class="(person.entityType === 'CORPORATE') ? 'bg-blue' : 'bg-orange'">#{{person.entityType.toLowerCase()}}</b-badge>
            </b-col>
            <b-col cols="3" class="text-sm">
              <span class="mx-1">Date:</span>
              <span>{{dateFormat(person.individual.date)}}</span>
            </b-col>
            <b-col cols="4" class="float-end">
              <button class="float-end mx-auto bg-blue px-2 py-1 text-sm rounded-sm text-white" @click="showDetails(idx)">
                <p v-if="selectedId === idx">
                  Show Less
                </p>
                <p v-else>
                  View Details
                </p>
              </button>
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="selectedId === idx">
            <b-col>
              <b-row>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Given Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.givenName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.middleName"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Middle Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.middleName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Surname"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.surName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.individual.idType.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.idNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Nationality"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.nationality"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.email"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.phoneNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Gender (at birth)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.individual.sex.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Birth"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="dateFormat(person.individual.dateOfBirth)"
                      required>
                    </b-form-input>
                    <b-form-invalid-feedback state="true">
                      {{person.individual.age}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Occupation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.occupation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col v-if="person.individual.physicalAddress">
                  <b-form-group
                    v-if="person.individual.physicalAddress.district"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="District"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.district.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.physicalAddress.district"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Region"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.region.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.physicalAddress.subcounty"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Sub county"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.subcounty.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.physicalAddress.parish"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Parish"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.parish.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.physicalAddress.village"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Village"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.village.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col v-else>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Foreign Address"
                    label-for="input-1">
                    <b-form-textarea
                      class="form-text text-sm noFocus"
                      type="text"
                      rows="4"
                      disabled="true"
                      :value="person.individual.foreignAddress"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    v-if="person.individual.postalAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.postalAddress"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.street"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.street"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.plot"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.plot"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";

export default {
  name: "LegalPersonBusinessAmendments",
  props:{
    persons:[],
    badge:String
  },
  data(){
    return{
      selectedId:null
    }
  },
  methods:{
    showDetails(id){
      if(this.selectedId != null){
        this.selectedId = null
      }else{
        this.selectedId = id
      }
    },
    dateFormat(date){
      return moment(date).format("DD/MM/YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  }
}
</script>

<style scoped>

</style>