<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Form A23
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{data}}
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "FileFormA23",
  props:{
    data:Object,
    brn:String
  },
};
</script>

<style scoped>

</style>