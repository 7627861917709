import {api} from "@/app/services/api.service";

const state = {
    document_registry:null,
    document_details:null,
    edms_file:null,
    error:null
};

const getters = {
    getLegalDocumentRegistry: (state) => state.document_registry,
    getLegalDocumentDetails: (state) => state.document_details,
    getDocumentRegistryError: (state) => state.error,
    getLegalDocumentEDMSFile: (state) => state.edms_file,
    getLegalDocumentFile: (state) => state.edms_file,
};

const actions = {
    async fetchLegalDocumentsRegistry({commit}, data) {
        await api()
            .get("/legal-documents/registry?page="+data.page+"&per_page="+data.limit+"&search="+data.search+"&type="+data.type)
            .then(response => {
                commit("setDocumentRegistry", response.data);
            })
            .catch(error => {
                commit("setDocumentRegistryError", error.response);
            });
    },
    async fetchLegalDocumentDetails({commit}, id) {
        await api()
          .get("/legal-documents/registry/details?instrumentNumber="+id)
          .then(response => {
              commit("setDocumentDetails", response.data);
          })
          .catch(error => {
              commit("setDocumentRegistryError", error.response);
          });
    },
    async fetchLegalDocumentEDMSFile({commit}, data) {
        await api()
          .post("/integration/api/edms/company/files/all", data)
          .then(response => {
              commit("setEDMSFile", response.data);
          })
          .catch(error => {
              commit("setRegistryError", error.response);
          });
    },
    async fetchLegalDocumentFile({commit}, instrumentNumber) {
        await api()
          .get("/legal-documents/registry/attachment?instrumentNumber="+instrumentNumber)
          .then(response => {
              commit("setEDMSFile", response.data);
          })
          .catch(error => {
              commit("setDocumentRegistryError", error.response);
          });
    },
};

const mutations = {
    setEDMSFile: (state, response) => {
        state.edms_file = response;
    },
    setDocumentRegistry: (state, response) => {
        state.error = null;
        state.document_registry = response;
    },
    setDocumentDetails: (state, response) => {
        state.document_details = response;
    },
    setDocumentRegistryError: (state, response) => {
        state.error = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
