<template>
  <b-row class="shadow-sm bg-header_grey">
    <b-col cols="4" class="my-3">
      <div class="flex back-btn" @click="back">
        <i class="fa fa-angle-left text-gray hover:text-blue"  style="font-size: 30px"></i>
        <router-link class="w-11/12 ml-1" :to="{name:'Home'}">
          <img class="logo" :src="require('/src/assets/images/banner.png')" />
        </router-link>
      </div>
    </b-col>
    <b-col cols="6"></b-col>
    <b-col cols="2">
      <b-row >
        <div class="flex  mt-2">
          <b-avatar variant="info" src="https://picsum.photos/200/300?random=1"></b-avatar>
          <b-row class="ml-1 mt-1">
            <b-col cols="12">
              <div style="text-align: left;">
                <p class="text-gray text-xs">
                  <b>{{userProfile.givenName}} {{userProfile.surName}}</b><br/>
                  <small>{{userProfile.email}}</small>
                </p>
              </div>
              <b-row>
                <div class="text-gray text-xs"></div>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import jwtService from "@/app/services/jwt.service";

export default {
  name: "ProfileTopBar",
  data(){
    return{
      userProfile:{},
    }
  },
  mounted() {
    this.userProfile = jwtService.getUser()
  },
  methods:{
    back(){
      let r = this.$router.resolve({
        name: "Home",
      });
      window.location.assign(r.href);
    },
  }
}
</script>

<style scoped>
.back-btn:hover i{
  @apply text-blue;
}

.logo{
  width: 85px;
  margin-top: 4px;
}

.account-menu-spacing{
  width: 200px
}
</style>