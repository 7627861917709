import {api} from "@/app/services/api.service";

const state = {
    company_documents:null,
    business_name_documents:null,
    building_society_documents:null,
    documents_error:null,
    resend_company_document:null,
    resend_company_document_error:null,
    resend_business_document:null,
    resend_business_document_error:null
};

const getters = {
    getCompanyDocuments: (state) => state.company_documents,
    getResendCompanyDocumentSuccess: (state) => state.resend_company_document,
    getResendCompanyDocumentError: (state) => state.resend_company_document_error,
    getResendBusinessDocumentSuccess: (state) => state.resend_business_document,
    getResendBusinessDocumentError: (state) => state.resend_business_document_error,
    getBusinessNameDocuments: (state) => state.business_name_documents,
    getBuildingSocietyDocument: (state) => state.building_society_documents,
    getDocumentFetcherError: (state) => state.documents_error,

};

const actions = {
    async fetchBusinessNameDocuments({commit}, data) {
        let path = "/business-registration/attachment/"+data.brn+"?page="+data.page+"&per_page="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        await api()
            .get(path)
            .then(response => {
                commit("setBusinessNameDocument", response.data);
            })
            .catch(error => {
                commit("setDocumentFetchError", error.response);
            });
    },
    async fetchCompanyDocuments({commit}, data) {
        let path = "/incorporation/companies/continuous-filing/get-registered-documents/"+data.brn+"?page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        await api()
          .get(path)
          .then(response => {
              commit("setCompanyDocument", response.data);
          })
          .catch(error => {
              commit("setDocumentFetchError", error.response);
          });
    },
    async resendCompanyDocuments({commit}, data) {
        await api()
          .post("/incorporation/companies/continuous-filing/resend-client-document", data)
          .then(response => {
              commit("setResendCompanyDocument", response.data);
          })
          .catch(error => {
              commit("setResendCompanyDocumentError", error.response);
          });
    },
    async resendBusinessNameDocuments({commit}, data) {
        await api()
          .post("/business-registration/attachment/sync-item?brn="+data.brn+"&sync-item="+data.type)
          .then(response => {
              commit("setResendBusinessDocument", response.data);
          })
          .catch(error => {
              commit("setResendCompanyDocumentError", error.response);
          });
    },
    async fetchBuildingSocietyDocuments({commit}, data) {
        let path = "/building-society/societies/get-registered-documents/"+data.brn+"?page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        await api()
          .get(path)
          .then(response => {
              commit("setBuildingSocietyDocument", response.data);
          })
          .catch(error => {
              commit("setDocumentFetchError", error.response);
          });
    },
};

const mutations = {
    setCompanyDocument: (state, response) => {
        state.company_documents = response.data;
    },
    setResendCompanyDocument: (state, response) => {
        state.resend_company_document = response.data;
    },
    setResendCompanyDocumentError: (state, response) => {
        state.resend_company_document_error = response.data;
    },
    setResendBusinessDocument: (state, response) => {
        state.resend_business_document = response.data;
    },
    setResendBusinessDocumentError: (state, response) => {
        state.resend_business_document_error = response.data;
    },
    setBusinessNameDocument: (state, response) => {
        state.business_name_documents = response;
    },
    setBuildingSocietyDocument: (state, response) => {
        state.building_society_documents = response;
    },
    setDocumentFetchError: (state, response) => {
        state.documents_error = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
