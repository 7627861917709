import {api} from "@/app/services/api.service";

const state = {
    company_registry:null,
    business_name_registry:null,
    building_society_registry:null,
    entity_file:null,
    edms_file:null,
    error:null,
    edms_error:null
};

const getters = {
    getEntityFile: (state) => state.entity_file,
    getEntityFileError: (state) => state.edms_error,
    getEDMSFile: (state) => state.edms_file,
    getCompanyRegistry: (state) => state.company_registry,
    getBuildingSocietyRegistry: (state) => state.building_society_registry,
    getBusinessNameRegistry: (state) => state.business_name_registry,
    getRegistryError: (state) => state.error,
};

const actions = {
    async fetchCompanyRegistry({commit}, data) {
        let path = "/incorporation/companies/registry?page="+data.page+"&pageSize="+data.limit
        if(data.search){
            path+="&search="+data.search
        }
        if(data.subType){
            path+="&subType="+data.subType
        }
        if(data.status){
            path+="&status="+data.status
        }
        if(data.startDate){
            path+="&startDate="+data.startDate
        }
        if(data.endDate){
            path+="&endDate="+data.endDate
        }

        await api()
            .get(path)
            .then(response => {
                commit("setCompanyRegistry", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchBusinessNameRegistry({commit}, data) {
        let path = "/business-registration/registry?page="+data.page+"&limit="+data.limit+"&offset="+data.offset
        if(data.search){
            path+="&search="+data.search
        }
        if(data.subType){
            path+="&subType="+data.subType
        }
        if(data.status){
            path+="&status="+data.status
        }
        if(data.startDate){
            path+="&startDate="+data.startDate
        }
        if(data.endDate){
            path+="&endDate="+data.endDate
        }

        await api()
          .get(path)
          .then(response => {
              commit("setBusinessNameRegistry", response.data);
          })
          .catch(error => {
              commit("setRegistryError", error.response);
          });
    },
    async fetchBuildingSocietyRegistry({commit}, data) {
        let path = "/building-societies/societies/registry?page="+data.page+"&pageSize="+data.limit
        if(data.search){
            path+="&search="+data.search
        }
        if(data.subType){
            path+="&subType="+data.subType
        }
        if(data.status){
            path+="&status="+data.status
        }
        if(data.startDate){
            path+="&startDate="+data.startDate
        }
        if(data.endDate){
            path+="&endDate="+data.endDate
        }

        await api()
          .get(path)
          .then(response => {
              commit("setBuildingSocietyRegistry", response.data);
          })
          .catch(error => {
              commit("setRegistryError", error.response);
          });
    },
    async fetchCompanyEDMSFile({commit}, data) {
        await api()
            .post("/integration/api/edms/company/files/all", data)
            .then(response => {
                commit("setEDMSFile", response.data);
            })
            .catch(error => {
                commit("setEDMSError", error.response);
            });
    },
    async fetchBusinessNameEDMSFile({commit}, data) {
        await api()
          .post("/integration/api/edms/business-name/files/all", data)
            .then(response => {
                commit("setEDMSFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
    async fetchSocietyEDMSFile({commit}, data) {
        await api()
            .post("/integration/api/edms/company/files/all", data)
            .then(response => {
                commit("setEDMSFile", response.data);
            })
            .catch(error => {
                commit("setRegistryError", error.response);
            });
    },
};

const mutations = {
    setEDMSFile: (state, response) => {
        state.edms_file = response;
    },
    setEntityFile: (state, response) => {
        state.error = null;
        state.entity_file = response;
    },
    setCompanyRegistry: (state, response) => {
        state.error = null;
        state.company_registry = response.data;
    },
    setCompanySearchResults: (state, response) => {
        state.error = null;
        state.company_registry = response;
    },
    setBusinessNameSearchResults: (state, response) => {
        state.error = null;
        state.business_name_registry = response;
    },
    setBuildingSocietySearchResults: (state, response) => {
        state.error = null;
        state.building_society_registry = response;
    },
    setBusinessNameRegistry: (state, response) => {
        state.error = null;
        state.business_name_registry = response;
    },
    setBuildingSocietyRegistry: (state, response) => {
        state.error = null;
        state.building_society_registry = response.data;
    },
    setRegistryError: (state, response) => {
        state.error = response.data;
    },
    setEDMSError: (state, response) => {
        state.edms_error = response.data;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
