<template>
  <div class="my-3">


    <b-row>
      <b-col>
        <DisputeResolutionFormDetails ></DisputeResolutionFormDetails>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import DisputeResolutionFormDetails
    from "@/views/home/services/dispute-resolution/task/DisputeResolutionFormDetails.vue";




export default {
  name: "DisputeResolutionFormDetailsEscalated",
  components: {DisputeResolutionFormDetails},
  props:{
    task:Object
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanName(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.toLowerCase()
      }else{
        return "N/A"
      }
    },
    filingName(name){
      if(name === "APPEND_INFORMATION"){
        return "#resolution"
      }
      return "#"+name.replaceAll("_","").toLowerCase();
    }
  },
  watch:{
    task:{
      handler(data){
        console.log(data)
      },
      deep:true
    }
  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>