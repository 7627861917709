import {api} from "@/app/services/api.service";

const state = {
    prnDetails:null,
    prnAdded:null,
    prnDetailsError:null,
    prnAddedError:null,
    billDetails:null,
    generalTransaction:null,
    generalTransactionError:null,
    paymentDetails:null,
    paymentDetailsError:null,
    error:null
};

const getters = {
    getPRNDetails: (state) => state.prnDetails,
    getPRNDetailsError: (state) => state.prnDetailsError,
    getBillDetails: (state) => state.billDetails,
    getPRNAdded: (state) => state.prnAdded,
    getPRNAddedError: (state) => state.prnAddedError,
    getPaymentDetails: (state) => state.paymentDetails,
    getPaymentDetailsError: (state) => state.paymentDetailsError,
    getMoveTask: (state) => state.generalTransaction,
    getMoveTaskError: (state) => state.generalTransactionError,
}

const actions = {
    async fetchPRNDetails({commit}, prn){
        api().post("/integration/api/ura/check_prn", prn).
        then(response => {
            commit('setPRNDetails', response.data);
        }).catch(error => {
            commit("setPRNError", error.response)
        });
    },
    async fetchPaymentDetails({commit}, trackingNumber){
        api().get("/billing/transaction/by-tracking-number?trackingNumber="+trackingNumber).
        then(response => {
            commit('setPaymentDetails', response.data);
        }).catch(error => {
            commit("setPaymentDetailsError", error.response)
        });
    },
    async fetchBillDetails({commit}, prn){
        api().get("/billing/transaction/by-prn?prn="+prn).
        then(response => {
            commit('setBillDetails', response.data);
        }).catch(error => {
            commit("setPRNError", error.response)
        });
    },
    async redoTransaction({ commit }, data) {
        api().post("/billing/transaction/synchronize", data).then(response => {
            commit("setGeneralTransaction", response.data);
        }).catch(error => {
            commit("setGeneralTransactionError", error.response);
        });
    },
    clearPRNDetails({commit}){
        commit('unsetPRNDetails', null);
    },
    async addPRNToBilling({ commit }, data) {
        api().post("/billing/transaction/add-tracking-number", data).then(response => {
            commit("setPRNAdded", response.data);
        }).catch(error => {
            commit("setPRNAddedError", error.response);
        });
    },
};

const mutations = {
    setPRNDetails: (state, response) =>{
        state.prnDetails = response;
    },
    setBillDetails: (state, response) =>{
        state.billDetails = response;
    },
    unsetPRNDetails: (state, response) =>{
        state.prnDetails = response;
    },
    setPRNError: (state, error) =>{
        state.prnDetailsError = error;
    },
    setGeneralTransaction: (state, response) => {
        state.generalTransaction = response;
    },
    setGeneralTransactionError: (state, error) => {
        state.generalTransactionError = error.data;
    },
    setPaymentDetails: (state, response) =>{
        state.paymentDetails = response;
    },
    setPaymentDetailsError: (state, response) =>{
        state.paymentDetailsError = response;
    },
    setPRNAdded: (state, response) =>{
        state.prnAdded = response;
    },
    setPRNAddedError: (state, response) =>{
        state.prnAddedError = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
