<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal id="modal-query" hide-footer ref="query-filing" title="Query Charge">
          <b-col cols="12">
            <b-form class="w-full">
              <b-form-textarea
                v-model="form.reason"
                type="text"
                placeholder="Give description of query"
                class="mt-1"
                required>
              </b-form-textarea>
              <b-row>
                <b-col cols="4">
                  <button type="button" class="btn-query-hover" variant="primary" @click="onQuery">
                    Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-modal>
      </b-row>
      <b-row>
        <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
          <b-row>
            <b-col>
              <p class="text-sm">
                <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
                This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
              </p>
              <p class="mt-3 text-sm italic">
                <b>Note:</b><br/>
                All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.

              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="4">
              <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
                <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
                <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
      <b-row>
        <b-col cols="12" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            File for Extension or Fine
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="mt-1">
            <b-col cols="12" class="mx-auto">
              <b-row>
                <b-col>
                  <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;" v-if="data">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2 font-bold">Extensions / Fines</td>
                    </tr>
                    </thead>
                    <tr>
                      <th class="px-2 py-1">Charge</th>
                      <th class="px-2 py-1">Type of Charge</th>
                      <th class="px-2 py-1">Amount Secured</th>
                      <th class="px-2 py-1">Property Particulars</th>
                      <th class="px-2 py-1">Fine PRN</th>
                      <th class="px-2 py-1">Commission</th>
                      <th class="px-2 py-1">Allowance</th>
                      <th class="px-2 py-1">Discount</th>
                      <th class="px-2 py-1">Date of Application</th>
                      <th class="px-2 py-1">Date of Instrument of Evidence</th>
                      <th class="px-2 py-1">Entitled Entities</th>
                    </tr>
                    <tbody>
                    <tr style="border: solid 1px #dedede;" v-for="(item, idx) in data.charges" :key="idx">
                      <td class="px-2 py-1 font-bold">{{item.chargeFormType.replaceAll("_", " ")}}</td>
                      <td class="px-2 py-1 text-blue">{{item.charge.typeOfCharge}}</td>
                      <td class="px-2 py-1 text-capitalize">{{item.charge.amountSecured.toLocaleString()}}</td>
                      <td class="px-2 py-1 text-capitalize">{{item.charge.shortParticularsOrDescriptionOfCharge}}</td>
                      <td class="px-2 py-1 text-capitalize">
                        <span v-if="item.finePrn">
                          {{item.finePrn}}
                        </span>
                        <span v-else>NIL</span>
                      </td>
                      <td class="px-2 py-1 text-capitalize">
                        <span v-if="item.charge.commission">
                          {{item.charge.commission.toLocaleString()}}
                        </span>
                        <span v-else>NIL</span>
                      </td>
                      <td class="px-2 py-1 text-capitalize">
                        <span v-if="item.charge.allowance">
                          {{item.charge.allowance.toLocaleString()}}
                        </span>
                        <span v-else>NIL</span>
                      </td>
                      <td class="px-2 py-1 text-capitalize">
                        <span v-if="item.charge.discount">
                          {{item.charge.discount.toLocaleString()}}
                        </span>
                        <span v-else>NIL</span>
                      </td>
                      <td class="px-2 py-1 text-capitalize">{{dateFormat(item.charge.dateOfCharge)}}</td>
                      <td class="px-2 py-1 text-capitalize">{{dateFormat(item.charge.dateOfInstrumentOfEvidence)}}</td>
                      <td class="px-2 py-1 font-monospace">
                        <button class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-green-dark" @click="view(item.charge.entitledEntities)">
                          View <span v-if="item.charge.entitledEntities"
                                     class="cursor-pointer bg-white rounded-full px-1 text-blue">
                       {{item.charge.entitledEntities.length}}</span>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-modal ref="entitledEntities" size="lg" title="Entitled Entities" hide-footer>
                <b-row>
                  <b-col>
                    <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      </thead>
                      <tr>
                        <th class="px-2 py-1">Name</th>
                        <th class="px-2 py-1">Description </th>
                        <th class="px-2 py-1">Address</th>
                      </tr>
                      <tbody>
                      <tr style="border: solid 1px #dedede;" v-for="(entity, idx) in entities" :key="idx">
                        <td class="px-2 py-1 text-capitalize">{{entity.name}}</td>
                        <td class="px-2 py-1 text-capitalize">{{entity.description}}</td>
                        <td class="px-2 py-1 text-capitalize">{{entity.address}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="2" v-if="data.requestForAnExtension">
              <button class="btn-query" @click="onQuery()">
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
            </b-col>
            <b-col cols="2" v-if="data.requestForAnExtension">
              <button class="btn-reject" @click="onReject()">
                <i class="fa fa-ban text-white mr-1"></i> Reject
              </button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FileExtensionOrFine",
  props:{
    data:Object,
    taskId:String
  },
  data(){
    return{
      entities:[],
      loading:false,
      action:null,
      form:{
        taskId:null
      }
    }
  },
  methods:{
    ...mapActions(["updateContinuousFilingStatus", "queryContinuousFilingChargeStatus", "rejectContinuousFilingChargeStatus"]),
    view(entities){
      this.entities = entities
      this.$refs.entitledEntities.show()
    },
    onQuery(){
      this.form.taskId = this.taskId
      this.action = "query"
      this.$refs.confirm.show();
    },
    onReject(){
      this.form.taskId = this.taskId
      this.action = "reject"
      this.$refs.confirm.show();
    },
    yesConfirmation(){
      if(this.action === "query"){
        this.queryContinuousFilingChargeStatus(this.form)
      }
      if(this.action === "reject"){
        this.rejectContinuousFilingChargeStatus(this.form)
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
  },
  computed:{
    ...mapGetters(["getContinuousFilingStatusUpdate"])
  },
  watch:{
    getContinuousFilingStatusUpdate(){
      window.location.reload()
    }
  }
};
</script>

<style scoped>

th, td{
  font-size: 12px;
}

.btn-query{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-reject{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}

</style>