<template>
  <div class="w-full">
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
        <Assignment :id="taskId" type="certification" permission="can_view_certification_tasks"/>
      </b-modal>
      <b-modal hide-footer size="lg" ref="documentDetails" title="Certification Documents">
        <b-row>
          <b-col>
            <p v-for="(document, idx) in documents" :key="idx" class="text-uppercase py-2">
              <i class="fa fa-file text-blue mx-2" ></i> {{document.documentName}}
            </p>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal ref="transferApplication" title="Transfer Application" size="md" hide-footer>
        <TransferTaskOwnership type="certification"/>
      </b-modal>
    </b-row>

    <b-row>
      <b-col cols="3">
        <b-form-input
          class="text-gray text-sm noFocus mx-2 my-2"
          style="padding:6px 5px;"
          v-model="trackingNumber"
          type="text"
          placeholder="Search Tracking Number"
          required
          v-on:keyup.enter="fetch">
        </b-form-input>
      </b-col>
<!--      <b-col cols="3">-->
<!--        <b-form-input-->
<!--          class="text-gray text-sm noFocus mx-2 my-2"-->
<!--          style="padding:6px 5px;"-->
<!--          v-model="brn"-->
<!--          type="text"-->
<!--          placeholder="Search Registration Number"-->
<!--          required-->
<!--          v-on:keyup.enter="fetch">-->
<!--        </b-form-input>-->
<!--      </b-col>-->
      <b-col cols="2">
        <b-form-group>
          <b-form-select
            v-model="statuses"
            v-on:change="fetch"
            class="form-select noFocus text-gray mx-2 my-2 text-sm">
            <b-select-option :value="null">--Filter by Status--</b-select-option>
            <b-select-option value="DRAFT">Draft</b-select-option>
            <b-select-option value="PENDING">In Queue</b-select-option>
            <b-select-option value="ASSIGNED">Assigned</b-select-option>
            <b-select-option value="QUERIED">Queried</b-select-option>
            <b-select-option value="APPROVED">Approved</b-select-option>
            <b-select-option value="ESCALATED">Escalated</b-select-option>
            <b-select-option value="AWAITING_PAYMENT_CONFIRMATION">Confirming Payment</b-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="2">
      </b-col>
    </b-row>
    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>
    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">Tracking No.</th>
            <th class="t-header">BRN</th>
            <th class="t-header">Name</th>
            <th class="t-header">Documents</th>
            <th class="t-header text-center">Status</th>
            <th class="t-header text-center">Action</th>
            <th class="t-header text-center">Assignment</th>
            <th class="t-header text-center">Date</th>
          </tr>
          </thead>

          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
            <td class="t-body text-blue font-bold cursor-pointer hover:underline" @click="showDetails(submission.trackingNumber)">{{submission.trackingNumber}}</td>
            <td class="t-body font-bold">{{submission.brn}}</td>
            <td class="t-body font-bold">{{submission.entityName}}</td>
            <td class="t-body">
              <span class="cursor-pointer text-blue hover:underline"
                    :id="'document-details-'+idx"
                    @click="showDocumentDetails(submission.documentDescription)">{{submission.documentDescription.length}} Document(s)</span>
              <b-tooltip :target="'document-details-'+idx" triggers="hover">
                <p v-for="(document, idx) in submission.documentDescription" :key="idx" class="text-uppercase">
                  - {{document.documentName}}
                </p>
              </b-tooltip>
            </td>
            <td class="t-body">
              <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                <span :class="(submission.status === 'PENDING') ? 'text-gray'
                : (submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION')? 'text-gray'
                : (submission.status.toUpperCase() === 'PAYMENT_FAILED')? 'text-red'
                : (submission.status === 'ASSIGNED') ? 'text-blue'
                : (submission.status === 'APPROVED') ? 'text-green'
                : (submission.status === 'QUERIED') ? 'text-orange' : ''">
                    <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                      Confirming Payment
                    </span>
                    <span v-else-if="submission.status.toUpperCase() === 'PENDING'">
                      In Queue
                    </span>
                    <span v-else>
                      {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                    </span>
                </span>
              </p>
            </td>

            <td class="t-body text-center">
              <b-row v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                <b-col>
                  <button
                    class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="move(submission, idx)">
                    Move to Queue <b-spinner v-if="loadingMoveTask && selectedIdx === idx" small></b-spinner>
                  </button>
                </b-col>
                <b-col>
                  <button
                    class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="removePayment(submission.trackingNumber, idx)">
                    Remove Payment <b-spinner v-if="loadingRemovePayment && selectedIdx === idx" small></b-spinner>
                  </button>
                </b-col>
              </b-row>
              <button
                v-else-if="submission.status.toUpperCase() === 'APPROVED'"
                class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="resend(submission.trackingNumber, idx)">
                Resend <i class="fa fa-mail-bulk mx-1"></i> <b-spinner v-if="loadingResend && selectedIdx === idx" small></b-spinner>
              </button>
              <button
                v-else-if="submission.status.toUpperCase() === 'ESCALATED'"
                class="text-xs bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission, 'Escalation')">
                <i class="fa fa-reply mx-1"></i>Assign
              </button>
              <button
                v-else-if="submission.status.toUpperCase() === 'ASSIGNED'"
                class="text-xs bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                <i class="fa fa-reply mx-1"></i>Assign
              </button>
              <button
                v-else-if="submission.status.toUpperCase() === 'PENDING'"
                class="text-xs bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                <i class="fa fa-plus mx-1"></i>Assign
              </button>
              <p v-else class="text-xs">No action</p>
            </td>
            <td class="t-body text-center">
              <span v-if="submission.assignedTo && !submission.assignedTo.includes('null')" class="text-uppercase">{{submission.assignedTo}}</span>
              <span v-else>Not Assigned</span>
            </td>
            <td class="t-body text-center">
              {{dateTimeFormat(submission.creationDate)}}
            </td>
          </tr>
          <tr>
            <td v-if="submissions.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col cols="8"></b-col>
      <b-col cols="1">
        <b-row>
          <b-col>
            <p class="paginate-details text-center text-xs font-bold">{{currentPage * limit}} of {{pagination.totalElements}}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Assignment from "@/views/home/commons/Assignment";
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import TransferTaskOwnership from "@/views/home/commons/TransferTaskOwnership";
export default {
  name: "AllCertificationSubmissions",
  components: {TransferTaskOwnership, Assignment },
  data(){
    return{
      count:0,
      loading:false,
      currentPage:1,
      limit:12,
      submissions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      documents:[],
      statuses: null,
      taskId:null,
      selectedIdx:null,
      loadingMoveTask:false,
      loadingRemovePayment:false,
      loadingResend:false,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      trackingNumber:null,
      brn:null,
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["redoTransaction", "fetchCertificationTasks", "resendCertificationDocuments", "removeCertificationPayment"]),
    fetch(){
      let options = this.statuses
      if(this.statuses === null){
        options = "DRAFT,PENDING,APPROVED,QUERIED,ESCALATED,AWAITING_PAYMENT_CONFIRMATION"
      }
      let data = {
        trackingNumber: this.trackingNumber,
        brn: this.brn,
        status:options,
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchCertificationTasks(data)

    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    assignPrompt(data){
      this.loading = true
      console.log(data)
      this.taskId = data.taskId
      this.$refs.assignSubmissions.show();
    },
    move(application, idx){
      this.selectedIdx = idx
      let data = {
        userId: JSON.parse(sessionStorage.getItem("current_user")).userId,
        prn: application.prn
      }
      this.loadingMoveTask = true
      this.redoTransaction(data)
    },
    removePayment(trackingNumber, idx){
      this.selectedIdx = idx
      let data = {
        trackingNumber:trackingNumber
      }
      this.loadingRemovePayment = true
      this.removeCertificationPayment(data)
    },
    resend(trackingNumber, idx){
      this.selectedIdx = idx
      let data = {
        trackingNumber:trackingNumber
      }
      this.loadingResend = true
      this.resendCertificationDocuments(data)
    },
    showDetails(trackingNumber){
      this.$emit("openDetails", trackingNumber)
    },
    showDocumentDetails(data){
      this.documents = data
      this.$refs.documentDetails.show()
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY h:mmA")
    }
  },
  computed:{
    ...mapGetters(["getCertificationSubmissions", "getCertificationResentSuccess", "getCertificationResendError",
    "getCertificationPaymentRemovedSuccess", "getCertificationPaymentRemovedError", "getMoveTask", "getMoveTaskError"])
  },
  watch:{
    getCertificationSubmissions(data){
      this.loading = false
      this.submissions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getMoveTask(){
      this.loadingMoveTask = false
      this.successToast("Success", "task move initiated")
    },
    getMoveTaskError(data){
      this.loadingMoveTask = false
      this.errorToast("Error", data.message)
    },
    getCertificationResentSuccess(){
      this.loadingResend = false
      this.successToast("Success", "documents resent")
    },
    getCertificationResendError(){
      this.loadingResend = false
      this.errorToast("Error", "documents not resent")
    },
    getCertificationPaymentRemovedSuccess(){
      this.loadingRemovePayment = false
      this.successToast("Success", "payment removed")
    },
    getCertificationPaymentRemovedError(){
      this.loadingRemovePayment = false
      this.errorToast("Error", "payment removal failed")
    }
  }
};
</script>

<style scoped>
.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>