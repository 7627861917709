<template>
  <div class="bg-white rounded-sm mx-3 my-3">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <b-col>
            <span class="title">Beneficial Owner Registry File</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mx-3 mt-2">
      <b-col cols="12" class="mx-auto bg-header_grey py-3 rounded">
        <span class="cursor-pointer text-blue hover:text-blue-dark hover:underline" @click="back">Back</span> <span class="mx-3 text-gray">Beneficial Owners / {{brn}}</span>
      </b-col>
    </b-row>
    <b-row class="mx-3">
      <b-col cols="12" class="mx-auto my-3 shadow-sm rounded pt-2 pb-4 border border-blue">
        <b-row>
          <b-col>
            <b-overlay :show="loading" rounded="sm">
              <b-row v-if="data">
                <b-col cols="12" class="mt-3">
                  <span class="font-bold text-blue h5">{{data.brn}}</span><br/>
                  <span class="font-bold text-gray h3">{{data.name}}</span><br/>
                  <div class="flex mt-1">
                    <b-badge class="bg-blue text-capitalize">
                      {{ data.entityType ? cleanEntityType(data.entityType) : "Business name"}}
                    </b-badge>
                    <b-badge class="mx-1 bg-green text-capitalize">
                      {{ data.subEntityType ? cleanEntityType(data.subEntityType) : cleanEntityType(data.subType)}}
                    </b-badge>
                  </div>
                  <span class="italic text-gray text-xs font-bold" v-if="data.registrationDate">Registration Date: {{dateFormat(data.registrationDate)}}</span><br/>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="12" class="my-3 blink_me">
                  <b-badge class="bg-gray_loading text-gray_loading my-1 w-2/12">name</b-badge><br/>
                  <b-badge class="bg-gray_loading text-gray_loading py-3 w-4/12">name</b-badge>
                  <div class="flex mt-1">
                    <b-badge class="bg-gray_loading">
                      loading...
                    </b-badge>
                    <b-badge class="mx-1 bg-gray_loading">
                      loading...
                    </b-badge>
                  </div>
                  <b-badge class="bg-gray_loading my-1 text-gray_loading w-6/12">name</b-badge>
                  <br/>
                </b-col>
              </b-row>

              <b-row v-if="getBeneficialOwnerFile">
                <b-col>
                  <b-row class="mt-3">
                    <b-col>
                      <b-alert variant="info" show>
                        <p class="font-bold">Presented by</p>
                        <p>
                          Name: {{getBeneficialOwnerFile.data.presentedBy.presenterName}}<br/>
                          Position: {{getBeneficialOwnerFile.data.presentedBy.presenterPosition}}<br/>
                        </p>
                      </b-alert>
                    </b-col>
                    <b-col>
                      <b-alert variant="primary" show>
                        <p class="h6 font-bold">Address of Beneficial Owner Store</p>
                        <span v-if="getBeneficialOwnerFile.data.village">
                        {{getBeneficialOwnerFile.data.village.country.name}} >
                        {{getBeneficialOwnerFile.data.village.region.name}} >
                        {{getBeneficialOwnerFile.data.village.district.name}} >
                        {{getBeneficialOwnerFile.data.village.subcounty.name}} >
                        {{getBeneficialOwnerFile.data.village.parish.name}} >
                        {{getBeneficialOwnerFile.data.village.village.name}}
                      </span>
                        <span v-else>None provided</span>
                      </b-alert>
                    </b-col>
                  </b-row>

                  <b-row class="mt-3">
                    <b-col>
                      <p class="h6 mt-2">Beneficial Owners</p>
                      <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                        <tbody>
                        <tr>
                          <td colspan="12">
                            <BeneficialOwners :persons="getBeneficialOwnerFile.data.beneficialOwners"/>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col class="bg-gray-pale h2 w-full"></b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import BeneficialOwners from "@/views/home/commons/BeneficialOwners.vue";

export default {
  name: "EntityBeneficialOwnersWrapper",
  components: { BeneficialOwners },
  data(){
    return{
      data:null,
      brn:null,
      loading:false,
    }
  },
  mounted() {
    this.brn = this.$route.params.id
    this.data = JSON.parse(localStorage.getItem("bo-data"))
    if(this.data.entityType){
      this.loading = true
      this.fetchCompanyBeneficialOwnersByBRN(this.brn)
    } else {
        this.loading = true
        this.fetchBnBeneficialOwnersByBRN(this.brn)
    }
  },
  methods:{
    ...mapActions(["fetchCompanyBeneficialOwnersByBRN","fetchBnBeneficialOwnersByBRN"]),
    back() {
      router.push({name:"beneficial-owners-registry"})
    }
  },
  computed:{
    ...mapGetters(["getBeneficialOwnerFile", "getBeneficialOwnerError"])
  },
  watch:{
    getBeneficialOwnerFile(){
      this.loading = false
    },
    getBeneficialOwnerError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
};
</script>

<style scoped>

</style>