import { render, staticRenderFns } from "./Form18Resolution.vue?vue&type=template&id=770a1333&scoped=true&"
import script from "./Form18Resolution.vue?vue&type=script&lang=js&"
export * from "./Form18Resolution.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "770a1333",
  null
  
)

export default component.exports