<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Transfer of Shares
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-row v-for="(transfer, idx) in data.transfers" :key="idx" class="border-b border-gray py-3">
            <b-col>
              <b-row>
                <b-col>
                  <p>Shares Transferred: <b-badge class="bg-blue"> <i class="fa fa-reply mr-1"></i>{{transfer.finalTotalTransferred.toLocaleString()}}</b-badge></p>
                </b-col>
                <b-col>
                  <p>Transferor Shares: <b-badge class="bg-red"> <i class="fa fa-arrow-down mr-1"></i>{{transfer.transferorFinalTotalShares.toLocaleString()}}</b-badge></p>
                </b-col>
                <b-col>
                  <p>Transferee Shares: <b-badge class="bg-green"> <i class="fa fa-arrow-up mr-1"></i>{{transfer.transfereeFinalTotalShares.toLocaleString()}}</b-badge></p>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;" >
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Shares Transfered Breakdown</td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in transfer.sharesTransferred " :key="idx">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.numberOfShares.toLocaleString()}}
                                </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountPaid.toLocaleString()}}
                                </span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.numberOfShares.toLocaleString()}}
                                </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountPaid.toLocaleString()}}
                                </span>
                        </td>
                      </tr>
                      </tbody>

                    </table>
                  </b-col>
                </b-col>
              </b-row>
              <b-row class="mt-4 mb-2">
                <b-col>
                  <p class="text-red">From: <b>{{transfer.transferor.name}}</b> <b-badge class="bg-red font-monospace mx-2">final</b-badge></p>
                </b-col>
                <b-col>
                  <p class="text-green">To: <b>{{transfer.transferee.name}}</b><b-badge class="bg-green font-monospace mx-2">final</b-badge></p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <table class="min-w-full leading-normal text-red"
                         style="border: solid 1px #dedede;" >
                    <thead>
                      <tr class="bg-gray-pale">
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Paid</th>
                      </tr>
                    </thead>
                    <tbody v-for="(share, idx) in transfer.transferorFinalShareholding" :key="idx">
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 text-uppercase font-bold">
                        <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                        <span v-else>{{share.otherClassName}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.numberOfShares.toLocaleString()}}
                                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountPaid.toLocaleString()}}
                                </span>
                      </td>
                    </tr>
                    <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                      <td class="px-2 py-1 text-capitalize">
                        <span>Class {{subClass.nominalClass}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.numberOfShares.toLocaleString()}}
                                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountPaid.toLocaleString()}}
                                </span>
                      </td>
                    </tr>
                    </tbody>

                  </table>
                </b-col>
                <b-col>
                  <table class="min-w-full leading-normal text-green"
                         style="border: solid 1px #dedede;" >
                    <thead>
                    <tr class="bg-gray-pale">
                      <th class="px-2 py-1">Share Class</th>
                      <th class="px-2 py-1 text-center">Shares</th>
                      <th class="px-2 py-1 text-center">Paid</th>
                    </tr>
                    </thead>
                    <tbody v-for="(share, idx) in transfer.transfereeFinalShareholding" :key="idx">
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 text-uppercase font-bold">
                        <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                        <span v-else>{{share.otherClassName}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.numberOfShares.toLocaleString()}}
                                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountPaid.toLocaleString()}}
                                </span>
                      </td>
                    </tr>
                    <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                      <td class="px-2 py-1 text-capitalize">
                        <span>Class {{subClass.nominalClass}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.numberOfShares.toLocaleString()}}
                                </span>
                      </td>
                      <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountPaid.toLocaleString()}}
                                </span>
                      </td>
                    </tr>
                    </tbody>

                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TransferOfShares",
  props:{
    data:Object
  },
};
</script>

<style scoped>

</style>