<template>
  <b-row>
    <b-col>

      <b-row>
        <b-col cols="12" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Form 14 (Charge)
          </b-badge>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="12" class="mx-auto">

          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;" v-if="data">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Charges</td>
                </tr>
                </thead>
                <tr>
                  <th class="px-2 py-1">Type of Charge</th>
                  <th class="px-2 py-1">Amount Secured</th>
                  <th class="px-2 py-1">Property Particulars</th>
                  <th class="px-2 py-1">Commission</th>
                  <th class="px-2 py-1">Allowance</th>
                  <th class="px-2 py-1">Discount</th>
                  <th class="px-2 py-1">Date of Application</th>
                  <th class="px-2 py-1">Date of Deed</th>
                  <th class="px-2 py-1">Entitled Entities</th>
                </tr>
                <tbody>
                <tr style="border: solid 1px #dedede;" v-for="(charge, idx) in data.charges" :key="idx">
                  <td class="px-2 py-1 text-blue">{{charge.typeOfCharge}}</td>
                  <td class="px-2 py-1 text-capitalize">
                    <span v-if="charge.currency">{{charge.currency}}. </span>
                    {{charge.amountSecured.toLocaleString()}}
                  </td>
                  <td class="px-2 py-1 text-capitalize">{{charge.shortParticularsOrDescriptionOfCharge}}</td>
                  <td class="px-2 py-1 text-capitalize">{{charge.commission.toLocaleString()}}</td>
                  <td class="px-2 py-1 text-capitalize">{{charge.allowance.toLocaleString()}}</td>
                  <td class="px-2 py-1 text-capitalize">{{charge.discount.toLocaleString()}}</td>
                  <td class="px-2 py-1 text-capitalize">{{dateFormat(charge.debentureApplicationDate)}}</td>
                  <td class="px-2 py-1 text-capitalize">{{dateFormat(charge.debentureDeedDate)}}</td>
                  <td class="px-2 py-1 font-monospace">
                    <button class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-green-dark" @click="view(charge.entitledEntities)">
                      View <span v-if="charge.entitledEntities"
                                 class="cursor-pointer bg-white rounded-full px-1 text-blue">
                       {{charge.entitledEntities.length}}</span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="10">
            </b-col>
            <b-col cols="2">
              <b-form-group label="Extension Deadline">
                <b-form-input
                  class="form-text"
                  :value="data.extensionDeadline"
                  disabled="true"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-modal ref="entitledEntities" size="lg" title="Entitled Entities" hide-footer>
            <b-row>
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  </thead>
                  <tr>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1">Description </th>
                    <th class="px-2 py-1">Address</th>
                  </tr>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" v-for="(entity, idx) in entities" :key="idx">
                    <td class="px-2 py-1 text-capitalize">{{entity.name}}</td>
                    <td class="px-2 py-1 text-capitalize">{{entity.description}}</td>
                    <td class="px-2 py-1 text-capitalize">
                      <span v-if="entity.registeredPhysicalAddress">
                        {{entity.registeredPhysicalAddress.country.name}} ->
                        {{entity.registeredPhysicalAddress.region.name}} ->
                        {{entity.registeredPhysicalAddress.district.name}} ->
                        {{entity.registeredPhysicalAddress.subcounty.name}} ->
                        {{entity.registeredPhysicalAddress.parish.name}} ->
                        {{entity.registeredPhysicalAddress.village.name}}
                      </span>
                      <span v-else>
                        {{entity.foreignPostalAddress}}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-modal>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "FileForm14",
  props:{
    data:Object
  },
  data(){
    return{
      entities:[],
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    view(entities){
      this.entities = entities
      this.$refs.entitledEntities.show()
    },
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

</style>