<template>
  <div class="bg-white rounded-sm mx-2 my-3">
    <b-row>
      <b-col cols="12" class="overflow-hidden mb-2">
        <!-- <b-row>
          <b-col>
            <div class="flex h-full" style="cursor: pointer;">
              <div v-for="(report, idx) in reportAreas" :class="[(currentRoute === report.route) ? 'menu-item-active' : 'menu-item']" :key="idx"
                   @click="select(report.route)">
                <div class="flex mt-6">
                  <div style="font-size: 14px">{{report.title}}</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col>
            <ActivityReport />
            <!-- <BillingReport v-if="currentRoute === 'billing-report'"/>
            <RegistryReport v-if="currentRoute === 'registry-report'"/>
            <UserReport v-if="currentRoute === 'users-report'"/>
            <BenchmarksReport v-if="currentRoute === 'benchmarks-report'"/> -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ActivityReport from "@/views/home/services/reports/activity/ActivityReport"
// import BillingReport from "@/views/home/services/reports/billing/BillingReport"
// import RegistryReport from "@/views/home/services/reports/registry/RegistryReport"
// import UserReport from "@/views/home/services/reports/users/UserReport"
// import BenchmarksReport from "@/views/home/services/reports/benchmarks/BenchmarksReport"
export default {
  name: "Reports",
  components: { ActivityReport },
  // components: { BenchmarksReport, UserReport, RegistryReport, BillingReport, ActivityReport },
  data() {
    return {
      currentRoute: "activity-report",
      reportAreas: [
        {
          route: "activity-report",
          title: "Activity Report"
        },
        {
          route: "registry-report",
          title: "Registry Report"
        },
        {
          route: "billing-report",
          title: "Billing Report"
        },
        {
          route: "users-report",
          title: "Users Report"
        },
        {
          route: "benchmarks-report",
          title: "Benchmarks Report"
        }

      ]
    }
  },
  methods: {
    select(route) {
      this.currentRoute = route
    },
  }
}
</script>

<style scoped>
@import "../../../../assets/styles/menu.css";
</style>