<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="mx-auto mt-2">
          <b-row class="mx-1">
            <b-col>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Country"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    :disabled="true"
                    :value="getAddress.data.country.name"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Region"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    :disabled="true"
                    :value="getAddress.data.region.name"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="District"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="getAddress.data.district.name"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Sub County"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="getAddress.data.subcounty.name"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Parish"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    :disabled="true"
                    :value="getAddress.data.parish.name"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Village"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="getAddress.data.village.name"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Street"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="file.street"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Plot"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="file.plot"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Postal Address"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="file.postalAddress"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BuildingSocietyDetails",
  props:{
    file:Object
  },
  mounted() {
    this.fetchAddressByVillageId(this.file.address.villageId)
  },
  methods:{
    ...mapActions(["fetchAddressByVillageId"])
  },
  computed:{
    ...mapGetters(["getAddress"])
  }
}
</script>

<style scoped>

</style>