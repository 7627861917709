<template>
  <div class="overflow-hidden bg-gray p-2">
    <b-row class="my-2">
      <b-overlay :show="loading" rounded="sm">
        <b-col cols="12">
          <EDMSDocument :file="getEdmsFilePreview"/>
        </b-col>
      </b-overlay>
    </b-row>
  </div>
</template>

<script>
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CertificationDocumentViewer",
  components: { EDMSDocument },
  data(){
    return{
      loading:false,
      edmsId: null
    }
  },
  methods:{
    ...mapActions(["fetchEdmsFileById"]),
    openFile(document){
      let data = {
        documentHandle: document.edmsId,
        overlay: ""
      }
      this.loading = true
      this.fetchEdmsFileById(data)
    },
  },
  computed:{
    ...mapGetters(["getEdmsFilePreview"])
  },
  watch:{
    getEdmsFilePreview(){
      this.loading = false
    }
  }
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
  top: 0 !important;
}
</style>