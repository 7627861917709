<template>
  <div>
    <b-row class="my-1">
      <b-col>
        <b-row class="mt-1">
          <b-col>
            <span style="font-size: 30px; font-weight: bold" v-if="task">
              {{task.companyName}}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Application Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Years Filing</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <p class="text-gray text-capitalize" v-for="(year, idx) in task.years" :key="idx">- {{year}}</p>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <PRNViewer :tracking-number="task.trackingNo"/>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-row>
              <b-col>
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Filing Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <AnnualReturnForms :task="task" v-if="currentRoute === 'forms'"/>
                <AnnualReturnFormFiles :task="task"  v-if="currentRoute === 'attachments'"/>
                <AnnualReturnMessages :task-id="task.taskId" v-if="currentRoute === 'messages'"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import AnnualReturnForms from "@/views/home/services/annual-returns/task/AnnualReturnForms.vue";
import AnnualReturnFormFiles from "@/views/home/services/annual-returns/task/AnnualReturnFormFiles.vue";
import AnnualReturnMessages from "@/views/home/services/annual-returns/task/AnnualReturnMessages.vue";

export default {
  name: "AnnualReturnsDetails",
  components: { AnnualReturnMessages, AnnualReturnFormFiles, AnnualReturnForms, PRNViewer},
  props:{trackingNumber:String},
  data(){
    return{
      margin:"2px",
      currentRoute: "forms",
      loading:false,
      messages:[],
      taskId:null,
      task:null,
      maxText:500,
      snapshot:null,
      snapshots:[],
      form:{
        companyId:null,
        queueId:null,
        reason:null,
        status:null,
        askUserToCancelReservation:false
      },
    }
  },
  mounted() {
    this.fetchAnnualReturnsDetails(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchAnnualReturnsDetails"]),
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getAnnualReturnsDetails","getAnnualReturnsDetailsError"])
  },
  watch:{
    getAnnualReturnsDetails(data){
      this.loading = false
      this.task = data.data
    },
    getAnnualReturnsDetailsError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
};
</script>

<style scoped>

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

</style>