import {api} from "@/app/services/api.service";

const state = {
    company_dataupdate:null,
    incorporation_snapshots:null,
    incorporation_escalations:null,
    incorporation_escalation:null,
    incorporation_details:null,
    payment_removed:null,
    payment_removed_error:null,
    incorporation_details_error:null,
    preview:null,
    task_count:null,
    task_thread:{},
    registration_stats:{},
    incorporations:null,
    error:null,
    success:null,
    transferOwner:null,
    transferOwnerError:null,
    company_edms_documents:null,
    all_company_edms_documents:null,
    company_edms_documents_error:null
};

const getters = {
    getCompanyDataUpdateSubmissions: (state) => state.incorporations,
    getCompanyDataUpdateEscalations: (state) => state.incorporation_escalations,
    getCompanyDataUpdateEscalation: (state) => state.incorporation_escalation,
    getCompanyDataUpdateTaskCount: (state) => state.task_count,
    getCompanyDataUpdateTaskMessages: (state) => state.task_thread,
    getCompanyDataUpdateStatistics: (state) => state.registration_stats,
    getCompanyDataUpdateTask: (state) => state.company_dataupdate,
    getCompanyDataUpdateTaskError: (state) => state.error,
    getDataUpdateStatusSuccess: (state) => state.success,
    getAssignmentDataUpdateSuccess: (state) => state.success,
    getCIEscalationDataUpdateSuccess: (state) => state.success,
    getDataUpdateCompanyUpdateStatusError: (state) => state.error,
    getCompanyDataUpdateError: (state) => state.error,
    getCompanyDataUpdateDetails: (state) => state.incorporation_details,
    getCompanyDataUpdateDetailsError: (state) => state.incorporation_details_error,
    getCompanyEDMSDocuments: (state) => state.company_edms_documents,
    getAllCompanyEDMSDocuments: (state) => state.all_company_edms_documents,
    getCompanyEDMSDocumentsError: (state) => state.company_edms_documents_error,
};

const actions = {
    async fetchCompanyDataUpdates({commit}, data){
        let path = "/data-update/companies/queue/tasks?status="+data.status+"&page="+data.page+"&perPage="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setCompanyDataUpdates', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchAllCompanyDataUpdates({commit}, data){
        let path = "/data-update/companies?status="+data.status+"&page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        if(data.level !== null){
            path += "&level="+data.level
        }
        api().get(path).
        then(response => {
            commit('setCompanyDataUpdates', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchCompanyDataUpdateEscalations({commit}, data){
        api().get("/data-update/companies/escalations?status="+data.status+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setCompanyDataUpdateEscalations', response.data);
        }).catch(error => {
            commit("setCompanyDataUpdateError", error.response)
        });
    },
    async fetchCompanyDataUpdateEscalation({commit}, data){
        api().get("/data-update/companies/escalations/details?escalationId="+data.id).
        then(response => {
            commit('setCompanyDataUpdateEscalation', response.data);
        }).catch(error => {
            commit("setCompanyDataUpdateError", error.response)
        });
    },
    async fetchCompanyDataUpdateTask({commit}, data){
        api().get("/data-update/companies/task/next/"+data.level).
        then(response => {
            commit('setCompanyDataUpdate', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setCompanyDataUpdateTaskError", error.response)
        });
    },
    async fetchCompanyDataUpdateTaskSnapshots({commit}, trackingNumber){
        api().get("/data-update/companies/application-snapshot/"+trackingNumber).
        then(response => {
            commit('setCompanyRegistrationSnapshot', response.data);
        }).catch(error => {
            commit("setIncorporationTaskError", error.response)
        });
    },
    async fetchCompanyDataUpdateTaskCount({commit}){
        api().get("/data-update/companies/task/count").
        then(response => {
            commit('setDataUpdateTaskCount', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchCompanyDataUpdateStatistics({commit}, level){
        let path = "/data-update/companies/basic-statistics"
        if(level != null){
            path += "?level="+level
        }
        api().get(path).
        then(response => {
            commit('setCompanyDataUpdateStatistics', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchCompanyDataUpdateTaskMessages({commit}, id){
        api().get("/data-update/task/status?applicationId="+id).
        then(response => {
            commit('setCompanyDataUpdateTaskMessages', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async updateCompanyDataUpdateStatus({commit}, data){
        api().post("/data-update/task/status", data).
        then(response => {
            commit('setDataUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async approveCompanyDataUpdateFinal({commit}, data){
        api().post("/data-update/companies/task/approve-with-compliance-check", data).
        then(response => {
            commit('setDataUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchCompanyDataUpdateDetails({commit}, trackingNumber){
        api().get("/data-update/companies/by-tracking-no/"+trackingNumber).
        then(response => {
            commit('setCompanyDataUpdateDetails', response.data);
        }).catch(error => {
            commit("setCompanyDataUpdateDetailsError", error.response)
        });
    },
    async fetchCompanyEDMSDocuments({commit}, data){
        let path = "/integration/api/edms/company/documents/list?brn="+data.brn+"&page="+data.page+"&perPage="+data.limit
        if(data.documentType){
            path += "&documentType="+data.documentType
        }
        if(data.documentName){
            path += "&documentName="+data.documentName
        }
        api().get(path).
        then(response => {
            commit('setCompanyEDMSDocuments', response.data);
        }).catch(error => {
            commit("setCompanyEDMSDocumentsError", error.response)
        });
    },
    async fetchAllCompanyEDMSDocuments({commit}, data){
        api().post("/integration/api/edms/company/files/list", data).
        then(response => {
            commit('setAllCompanyDocuments', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setCompanyDataUpdateTaskError", error.response)
        });
    },
    assignCIDataUpdateTask({commit}, data){
        api().post("/data-update/task", data).
        then(response => {
            commit('setAssignmentDataUpdateSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    escalateCIDataUpdateTask({commit}, data){
        api().post("/data-update/companies/escalations", data).
        then(response => {
            commit('setCIDataUpdateEscalationSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async transferDataUpdateApplicationOwnership({commit}, data){
        api().post("/data-update/transfer-of-ownership", data).
        then(response => {
            commit('setTransferApplicationOwner', response.data);
        }).catch(error => {
            commit("setTransferApplicationOwnerError", error.response)
        });
    },
};

const mutations = {
    setCompanyDataUpdates: (state, response) =>{
        state.incorporations = response;
    },
    setCompanyDataUpdateEscalations: (state, response) =>{
        state.incorporation_escalations = response;
    },
    setCompanyDataUpdateEscalation: (state, response) =>{
        state.incorporation_escalation = response;
    },
    setCompanyDataUpdateError: (state, response) =>{
        state.error = response;
    },
    setDataUpdateTaskCount: (state, response) =>{
        state.task_count = response;
    },
    setCompanyDataUpdateStatistics: (state, response) =>{
        state.registration_stats = response;
    },
    setCompanyDataUpdateTaskMessages: (state, response) =>{
        state.task_thread = response;
    },
    setCompanyDataUpdate: (state, response) =>{
        state.company_dataupdate = response.data;
    },
    setCompanyDataUpdateTaskError: (state, response) =>{
        state.error = response;
    },
    setAssignmentDataUpdateSuccess: (state, response) =>{
        state.success = response;
    },
    setCIDataUpdateEscalationSuccess: (state, response) =>{
        state.success = response;
    },
    setDataUpdateError: (state, response) =>{
        state.error = response;
    },
    setDataUpdateStatusSuccess: (state, response) =>{
        state.success = response;
    },
    setCompanyDataUpdateDetails:(state, response) =>{
        state.incorporation_details = response.data
    },
    setCompanyDataUpdateDetailsError:(state, response) =>{
        state.incorporation_details_error = response.data
    },
    setCompanyEDMSDocuments:(state, response) =>{
        state.company_edms_documents = response.data
    },
    setAllCompanyDocuments:(state, response) =>{
        state.all_company_edms_documents = response
    },
    setCompanyEDMSDocumentsError:(state, response) =>{
        state.company_edms_documents_error = response.data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};