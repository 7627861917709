<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal title="Application Details" ref="applicationDetails" size="xl" hide-footer>
        <AnnualReturnsDetails :tracking-number="trackingNumber"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Annual Returns Applications</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="flex mx-2 mt-2">
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="pre-waiting">
          <span class="h3 mx-auto">{{stats.draft}}</span>
        </div>
        <div class="pre-waiting">
          <span class="text-sm mx-auto">Drafts</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="waiting">
          <span class="h3 mx-auto">{{stats.pending}}</span>
        </div>
        <div class="waiting">
          <span class="text-sm mx-auto">In Queue</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="normal">
          <span class="h3 mx-auto">{{stats.assigned}}</span>
        </div>
        <div class="normal">
          <span class="text-sm mx-auto">Assigned</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning">
          <span class="h3 mx-auto">{{stats.queried}}</span>
        </div>
        <div class="warning">
          <span class="text-sm mx-auto">Queried</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="positive">
          <span class="h3 mx-auto">{{stats.approved}}</span>
        </div>
        <div class="positive">
          <span class="text-sm mx-auto">Approved</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="negative">
          <span class="h3 mx-auto">{{stats.escalated}}</span>
        </div>
        <div class="negative">
          <span class="text-sm mx-auto">Escalated</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning-secondary">
          <span class="h3 mx-auto">{{stats.total}}</span>
        </div>
        <div class="warning-secondary">
          <span class="text-sm mx-auto">Total</span>
        </div>
      </div>

    </b-row>
    <b-row class="flex">
      <b-col cols="12">
        <b-row class="pt-3">
          <b-col>
            <AllAnnualReturnsSubmissions v-on:openDetails="openDetails"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AllAnnualReturnsSubmissions
  from "@/views/home/services/annual-returns/submissions/AllAnnualReturnsSubmissions.vue";
import { mapActions, mapGetters } from "vuex";
import AnnualReturnsDetails from "@/views/home/services/annual-returns/submissions/AnnualReturnsDetails.vue";

export default {
  name: "AnnualReturnsApplications",
  components: { AnnualReturnsDetails, AllAnnualReturnsSubmissions },
  data(){
    return{
      currentRoute: "pending",
      trackingNumber:null,
      stats:{
        draft: 0,
        pending: 0,
        queried: 0,
        assigned: 0,
        escalated: 0,
        rejected: 0,
        approved: 0,
        total: 0
      }
    }
  },
  mounted() {
    this.fetchAnnualReturnsStatistics()
  },
  methods:{
    ...mapActions(["fetchAnnualReturnsStatistics"]),
    select(route){
      this.currentRoute = route
    },
    canViewMenuItem(permission) {
      return this.$can(permission, "general");
    },
    openDetails(trackingNumber){
      this.trackingNumber = trackingNumber
      this.$refs.applicationDetails.show()
    }
  },
  computed:{
    ...mapGetters(["getAnnualReturnsStatistics"])
  },
  watch:{
    getAnnualReturnsStatistics(data){
      this.stats = data
    }
  }
};
</script>

<style scoped>
.pre-waiting{
  @apply flex text-gray_title;
}

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.warning-secondary{
  @apply flex text-flopurple;
}

.negative{
  @apply flex text-red;
}
.stat-count{
  display: inline;
  width: 12%;
}
</style>