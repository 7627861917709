import {api} from "@/app/services/api.service";

const state = {
  error:null,
  success:null,
  searches:null,
  searches_stats:{
    draft:0,
    pending:0,
    approved:0,
    total:0
  },
  search_report_requests:null,
  searchCreated:null,
  searchCreationError:null,
  recordSearchApplications:null,
  recordSearchApplicationsError:null,
  recordSearchUpdate:null,
  recordSearchUpdateError:null,
  searchResults:null,
  searchResultsError:null,
  searchStatus:null,
  searchStatusError:null,
  generatedReport:null,
  generateReportError:null,
  search_report_task:null,
  search_report_details:null,
  search_report_task_error:null,
  search_report_details_error:null,
  assigned_search_report_task:null,
  assign_search_report_task_error:null,
  search_report_stats:null,
  search_report_stats_error:null,
};

const getters = {
  getSearchReportTask: (state) => state.search_report_task,
  getSearchReportStats: (state) => state.search_report_stats,
  getSearchReportStatsError: (state) => state.search_report_stats_error,
  getSearchReportDetails: (state) => state.search_report_details,
  getSearchReportTaskError: (state) => state.search_report_task_error,
  getSearchReportDetailsError: (state) => state.search_report_details_error,
  getRecordSearchUpdate: (state) => state.recordSearchUpdate,
  getRecordSearchUpdateError: (state) => state.recordSearchUpdateError,
  getRecordSearchSubmissions: (state) => state.searches,
  getRecordSearchStatistics: (state) => state.searches_stats,
  getSearchReportRequests: (state) => state.search_report_requests,
  getRecordSearchSuccess: (state) => state.searchCreated,
  getRecordSearchError: (state) => state.searchCreationError,
  getGeneratedReport: (state) => state.generatedReport,
  getGenerateReportError: (state) => state.generateReportError,
  getRecordSearchResults: (state) => state.searchResults,
  getRecordSearchResultsError: (state) => state.searchResultsError,
  getRecordSearchStatus: (state) => state.searchStatus,
  getRecordSearchStatusError: (state) => state.searchStatusError,
  getSearchReportAssignmentSuccess: (state) => state.assigned_search_report_task,
  getSearchReportAssignmentError: (state) => state.assign_search_report_task_error,
};

const actions = {
  async fetchSearches({commit}, data) {
    let path = "/record-search/search-application/all?status="+data.status+"&page="+data.page+"&perPage="+data.limit
    if(data.trackingNumber){
      path += "&trackingNumber="+data.trackingNumber
    }
    await api()
      .get(path)
      .then((response) => {
        commit("setSearches", response.data);
      })
      .catch((error) => {
        commit("setSearchesError", error.response);
      });
  },
  async fetchSearchReportStats({commit}) {
    await api()
      .get("/record-search/report/stats")
      .then((response) => {
        commit("setSearchReportStats", response.data);
      })
      .catch((error) => {
        commit("setSearchReportStatsError", error.response);
      });
  },
  async fetchSearchesStats({commit}) {
    await api()
      .get("/record-search/search-record/summary")
      .then((response) => {
        commit("setSearchesStats", response.data);
      })
      .catch((error) => {
        commit("setSearchesError", error.response);
      });
  },
  async fetchSearchReportRequests({commit}, data) {
    let path = "/record-search/search-report-request/list/all?statuses="+data.statuses

    if(data.trackingNumber){
      path += "&trackingNumber="+data.trackingNumber
    }
    if(data.brn){
      path += "&brn="+data.brn
    }
    if(data.name){
      path += "&name="+data.name
    }
    path += "&page="+data.page+"&perPage="+data.limit
    await api()
      .get(path)
      .then((response) => {
        commit("setSearchReportRequests", response.data);
      })
      .catch((error) => {
        commit("setSearchesError", error.response);
      });
  },
  async fetchSearchReportTask({commit}, id){
    api().get("/record-search/report/queue/next-task/"+id).
    then(response => {
      commit('setSearchReportTask', response.data);
    }).catch(error => {
      console.log(JSON.stringify(error.response))
      commit("setSearchReportTaskError", error.response)
    });
  },
  async fetchSearchReportTaskDetails({commit}, trackingNumber){
    api().get("/record-search/report/queue/task/by-tracking-number/"+trackingNumber).
    then(response => {
      commit('setSearchReportDetails', response.data);
    }).catch(error => {
      console.log(JSON.stringify(error.response))
      commit("setSearchReportDetailsError", error.response)
    });
  },
  async updateSearchReportStatus({commit}, data) {
    await api().post("/record-search/report/queue/tasks/update-status", data)
      .then((response) => {
        commit("setRecordSearchUpdate", response);
      })
      .catch((error) => {
        commit("setRecordSearchUpdateError", error.response);
      });
  },
  async assignSearchReportTask({commit}, data) {
    await api().post("/record-search/report/queue/assign-task", data)
      .then((response) => {
        commit("setSearchReportAssignmentSuccess", response);
      })
      .catch((error) => {
        commit("setSearchReportAssignmentError", error.response);
      });
  },
  async generateSearchReportRequest({commit}, data) {
    await api().post("/record-search/search-report-request/update-search-report", data)
      .then((response) => {
        commit("setGeneratedReport", response);
      })
      .catch((error) => {
        commit("setGenerateReportError", error.response);
      });
  },
  async fetchRecordSearchDetails({commit}, searchReference ) {
    await api().get("/record-search/search-record/query/no-expiry/"+searchReference)
      .then((response) => {
        commit("setSearchResults", response);
      })
      .catch((error) => {
        commit("setSearchRecordResultsError", error.response);
      });
  },
  async fetchRecordSearchStatus({commit}, searchReference ) {
    await api().get("/record-search/search-record/by-ref/"+searchReference)
      .then((response) => {
        commit("setSearchStatus", response);
      })
      .catch((error) => {
        commit("setSearchRecordStatusError", error.response);
      });
  },
  // async fetchEdmsFileById({commit}, data ) {
  //   await api()
  //     .post("/record-search/api/edms/scan-company-files", data)
  //     .then((response) => {
  //       commit("setEdmsFile", response);
  //     })
  //     .catch((error) => {
  //       commit("setEdmsFileError", error.response);
  //     });
  // },
};

const mutations = {
  setSearches: (state, response) => {
    state.searches = response.data;
  },
  setSearchesStats: (state, response) => {
    state.searches_stats = response.data;
  },
  setSearchReportStats: (state, response) => {
    state.search_report_stats = response.data;
  },
  setSearchReportStatsError: (state, response) => {
    state.search_report_stats_error = response.data;
  },
  setSearchReportTask: (state, response) => {
    state.search_report_task = response;
  },
  setSearchReportDetails: (state, response) => {
    state.search_report_details = response.data;
  },
  setSearchReportTaskError: (state, response) => {
    state.search_report_task_error = response.data;
  },
  setSearchReportDetailsError: (state, response) => {
    state.search_report_details_error = response.data;
  },
  setSearchReportRequests: (state, response) => {
    state.search_report_requests = response.data;
  },
  setSearchResults: (state, response) => {
    state.searchResults = response.data;
  },

  setRecordSearchUpdate: (state, response) => {
    state.recordSearchUpdate = response.data;
  },
  setGeneratedReport: (state, response) => {
    state.generatedReport = response.data;
  },
  setGenerateReportError: (state, response) => {
    state.generateReportError = response.data;
  },
  setRecordSearchUpdateError: (state, response) => {
    state.recordSearchUpdateError = response;
  },
  setSearchStatus: (state, response) => {
    state.searchStatus = response.data;
  },
  setSearchReportAssignmentSuccess: (state, response) => {
    state.assigned_search_report_task = response.data;
  },
  setSearchReportAssignmentError: (state, response) => {
    state.assign_search_report_task_error = response.data;
  },
  setSearchRecordStatusError: (state, response) => {
    state.searchStatusError = response.data;
  },
  setSearchRecordResultsError: (state, response) => {
    state.searchResultsError = response.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};