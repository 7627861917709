<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col >
          <b-row>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Start Date"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  disabled="true"
                  :value="dateFormat(form.startDate)"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Reason"
                label-for="input-1">
                <b-form-textarea
                  id="companyName"
                  class="form-text text-sm noFocus"
                  type="text"
                  rows="5"
                  disabled="true"
                  :value="form.reason"
                  required>
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-row class="my-2">
            <b-col cols="12">
              <iframe class="pdf" :src="url+'#toolbar=0'">
              </iframe>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>
import server from "@/app/env/server.json";
import moment from "moment/moment";

export default {
  name: "Dormancy",
  props:{
    form:Object,
    attachments:Object
  },
  data(){
    return{
      url:"https://mag.wcoomd.org/uploads/2018/05/blank.pdf"
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
  watch:{
    attachments:{
      handler(){
        this.url = server.IP+"/incorporation/companies/downloads?attachment="+this.attachments[0].attachment
      },
      deep:true
    }
  }
};
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>