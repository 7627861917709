<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="6"><span class="font-bold">Country</span></b-col>
        <b-col cols="6">{{address.country.name}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><span class="font-bold">Region</span></b-col>
        <b-col cols="6">{{address.region.name}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><span class="font-bold">District</span></b-col>
        <b-col cols="6">{{address.district.name}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><span class="font-bold">Sub County</span></b-col>
        <b-col cols="6">{{address.subcounty.name}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><span class="font-bold">Parish</span></b-col>
        <b-col cols="6">{{address.parish.name}}</b-col>
      </b-row>
      <b-row>
        <b-col cols="6"><span class="font-bold">Village</span></b-col>
        <b-col cols="6">{{address.village.name}}</b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "AnnualReturnPhysicalAddressViewer",
  props:{
    address:Object
  }
};
</script>

<style scoped>

</style>