<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Reservation">
          <Escalation :task-id="taskId" type="reservation" permission="can_view_reservation_escalations"/>
        </b-modal>
        <b-modal id="modal-query" size="lg" hide-footer ref="query-reservation" title="Query Reservation">
          <b-row>
            <b-col>
              <b-form-select
                class="form-select text-sm noFocus text-gray"
                v-model="finalMessage.generated"
                :options="responses">
              </b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form class="w-full" >
                <b-form-group
                  v-if="finalMessage.generated"
                  class="text-sm my-2 w-full"
                  id="input-group-1"
                  label="Generated Message"
                  label-for="input-1">
                  <b-form-textarea
                    v-model="finalMessage.generated"
                    type="text"
                    class="mt-1 font-monospace"
                    disabled="true"
                    required>
                  </b-form-textarea>
                </b-form-group>
                <b-col>
                  <p class="text-sm text-gray my-2" v-if="similarNames.length > 0">
                    Similar Names:
                  </p>
                  <p class="text-sm text-gray font-monospace" v-if="similarNames.length > 0">
                    <label v-for="name in similarNames" :key="name" class="border border-blue rounded-sm bg-header_grey px-2 py-1 mr-2 mb-1 text-xs">
                      {{name}}
                    </label>
                  </p>
                </b-col>
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Your Custom Message"
                  label-for="input-1">
                  <b-form-textarea
                    v-model="finalMessage.custom"
                    type="text"
                    placeholder="Give a personal description of query"
                    class="mt-1"
                    required>
                  </b-form-textarea>
                </b-form-group>
                <b-row class="mt-2">
                  <b-col>
                    <b-alert variant="warning" :show="form.description != null">
                      {{form.description}}
                    </b-alert>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col>
                    <table class="w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Name</th>
                        <th class="px-2 py-1 text-center"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;" v-for="(option, idx) in getAnalysisReport" :key="idx">
                        <td class="px-2 py-1">
                          <span class="font-bold" v-if="option.flags.length > 0">
                            {{option.name}}
                          </span>
                          <span v-else class="font-bold">{{option.name}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                          <button type="button"
                                  class="float-end bg-blue text-white px-2 rounded-sm hover:bg-blue-dark"
                                  @click="copy(option.name)">
                            <i class="fa fa-copy"></i> Copy
                          </button>
                        </td>
                      </tr>
                      <tr style="border: solid 1px #f59696;" v-if="analysisReportError != null">
                        <td class="px-2 py-1">
                  <span class="text-red">
                    {{this.analysisReportError.data.message}}
                  </span>
                        </td>
                        <td class="px-2 py-1">
                  <span class="text-red font-bold" v-for="(name, idx) in JSON.parse(this.analysisReportError.config.data).names" :key="idx">
                    {{name}}
                  </span>
                        </td>
                        <td class="px-2 py-1">
                          <span class="text-red">0</span>
                        </td><td class="px-2 py-1">
                        <span class="text-red">0</span>
                      </td><td class="px-2 py-1">
                        <span class="text-red">0</span>
                      </td><td class="px-2 py-1 text-center">
                        <span class="text-red">N/A</span>
                      </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="10"></b-col>
                  <b-col cols="2">
                    <button type="button" class="float-end btn-query-hover" variant="primary" @click="onQuery">
                      Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <PickableNameSearch ref="searcher" v-on:pickedNames="pickedNames"/>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal id="modal-reject" size="lg" hide-footer ref="reject-reservation" title="Reject Reservation">
          <b-col cols="12">
            <b-row>
              <b-col>
                <b-form-select
                  class="form-select text-sm noFocus text-gray" v-model="finalMessage.generated" :options="responses">
                </b-form-select>
              </b-col>
            </b-row>
            <b-form-group
              v-if="finalMessage.generated"
              class="text-sm my-2 w-full"
              id="input-group-1"
              label="Generated Message"
              label-for="input-1">
              <b-form-textarea
                v-model="finalMessage.generated"
                type="text"
                class="mt-1 font-monospace"
                disabled="true"
                required>
              </b-form-textarea>
            </b-form-group>
            <b-form class="w-full">
              <b-col>
                <p class="text-sm text-gray my-2 font-monospace" v-if="similarNames.length > 0">
                  Similar Names:
                </p>
                <p class="text-sm text-gray font-monospace" v-if="similarNames.length > 0">
                  <label v-for="name in similarNames" :key="name" class="border border-blue rounded-sm bg-header_grey px-2 py-1 mr-2 mb-1">
                    {{name}}
                  </label>
                </p>
              </b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Your Custom Message"
                label-for="input-1">
                <b-form-textarea
                  v-model="finalMessage.custom"
                  type="text"
                  placeholder="Give a personal description of query"
                  class="mt-1"
                  required>
                </b-form-textarea>
              </b-form-group>
              <b-row class="mt-2">
                <b-col>
                  <b-alert variant="warning" :show="form.description != null">
                    {{form.description}}
                  </b-alert>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <table class="w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                    <thead>
                    <tr>
                      <th class="px-2 py-1">Name</th>
                      <th class="px-2 py-1 text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;" v-for="(option, idx) in getAnalysisReport" :key="idx">
                      <td class="px-2 py-1">
                          <span class="font-bold" v-if="option.flags.length > 0">
                           {{option.name}}
                          </span>
                        <span v-else class="font-bold">{{option.name}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <button type="button"
                                class="float-end bg-blue text-white px-2 rounded-sm hover:bg-blue-dark"
                                @click="copy(option.name)">
                          <i class="fa fa-copy"></i> Copy
                        </button>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #f59696;" v-if="analysisReportError != null">
                      <td class="px-2 py-1">
                  <span class="text-red">
                    {{this.analysisReportError.data.message}}
                  </span>
                      </td>
                      <td class="px-2 py-1">
                        <span class="text-red font-bold" v-for="(name, idx) in JSON.parse(this.analysisReportError.config.data).names" :key="idx">
                          {{name}}
                        </span>
                      </td>
                      <td class="px-2 py-1">
                        <span class="text-red">0</span>
                      </td><td class="px-2 py-1">
                      <span class="text-red">0</span>
                    </td><td class="px-2 py-1">
                      <span class="text-red">0</span>
                    </td><td class="px-2 py-1 text-center">
                      <span class="text-red">N/A</span>
                    </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="10"></b-col>
                <b-col cols="2">
                  <button type="button" class="float-end btn-reject-hover" variant="danger" @click="onReject">
                    Reject <b-spinner v-if="loading" small class="ml-1"></b-spinner>
                  </button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <PickableNameSearch ref="searcher" v-on:pickedNames="pickedNames"/>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-modal>
        <b-modal id="modal-select-name-error" hide-footer ref="select-name-error" title="Error!" title-class="text-reds">
          <b-col cols="12">
            <p class="text-center">
              <i class="fa fa-exclamation-triangle mb-4 text-red" style="font-size: 30px;"></i>
            </p>
            <p class="text-sm mb-2 text-center">
              <span class="text-red"> Please select one name among the options given under <b>Name Options</b> above before you continue with this action.</span><br/><br/>
              You can do so by selecting the checkbox (on the far right of the table) that is in line with name you want to proceed with under the column <i>Select</i>
            </p>
          </b-col>
        </b-modal>
        <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
          <b-row>
            <b-col>
              <p class="text-sm">
                <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
                This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
              </p>
              <p class="mt-3 text-sm italic">
                <b>Note:</b><br/>
                All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.

              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="4">
              <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
                <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
                <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
            </b-col>
          </b-row>
        </b-modal>
      </b-row>
      <b-row >
        <b-col cols="12">
          <b-row class="mx-1 mt-2">
            <b-col>
              <b-alert variant="info" show>
                <i class="fa fa-info-circle mx-2"></i>
                <span>This is reservation is made with the sole purpose of <b>{{cleanString(getReservationTask.reservation.purpose)}}</b>
                  <i v-if="getReservationTask.reservation.purpose.includes('CHANGE')" class="font-bold"> from {{getReservationTask.reservation.user.fullName.toUpperCase()}}</i>
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row class="mx-3" v-if="getReservationTask.reservation.appeal != null">
            <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm border-1 border-red-pale" >
              <div class="flex">
                <i class="fa fa-comment-alt text-red-pale mr-1 mt-1"></i>
                <span class="text-capitalize text-sm font-bold underline hover:text-red-pale">
                {{getReservationTask.byUser.firstName}}
                {{getReservationTask.byUser.lastName}}
              </span>
              </div>
              <p class="text-gray text-sm mt-2">
                {{getReservationTask.reservation.appeal.officerMessage}}
              </p>
              <span class="italic text-gray" style="font-size: 10px;">{{dateFormat(getReservationTask.reservation.appeal.updatedAt)}}</span>
              <b-row>
                <b-col>
                  <span class="bg-red-pale rounded text-white" style="padding: 2px 4px; font-size: 9px;">Appeal</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mx-3" v-if="getReservationTask.reservation.escalation != null">
            <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-green">
              <b-row>
                <b-col>
                  <div class="flex">
                    <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
                    <span class="text-sm font-bold underline">
                    {{getReservationTask.reservation.escalation.fromUser.firstName}}
                    {{getReservationTask.reservation.escalation.fromUser.lastName}}
                  </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-sm">{{getReservationTask.reservation.escalation.description}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span style="font-size:9px">{{dateFormat(getReservationTask.reservation.escalation.createdAt)}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="bg-green rounded text-white" style="padding: 2px 4px; font-size: 9px;">Escalation Response</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mx-3" v-if="isQueryResponse">
            <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
              <b-row>
                <b-col>
                  <div class="flex">
                    <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                    <span class="text-sm font-bold underline">{{messages[0].user.firstName}} {{messages[0].user.lastName}}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-sm">{{messages[0].description}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span style="font-size:9px">{{dateFormat(messages[0].dateTime)}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Query Response</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mx-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Reservation Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Sub Entity Type</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                  <span class="text-gray">
                    {{getReservationTask.reservation.no}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue">{{getReservationTask.reservation.status}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{cleanEntityType(getReservationTask.reservation.subType)}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(getReservationTask.reservation.createdAt)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>

            </b-col>
          </b-row>
          <b-row class="mx-1 mt-2">
            <b-col cols="12">
              <PRNViewer :tracking-number="getReservationTask.reservation.no" :prn="getReservationTask.reservation.prn"/>
            </b-col>
          </b-row>
          <b-row class="mx-1 mt-2">
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Name Options</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Priority</th>
                  <th class="px-2 py-1">Name</th>
                  <th class="px-2 py-1">Analysis</th>
                  <th class="px-2 py-1">Flags</th>
                  <th class="px-2 py-1 text-center">Select</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" v-for="(option, idx) in getAnalysisReport" :key="idx">
                  <td class="px-2 py-1">
                    <span class="text-blue">Choice {{idx + 1}}</span>
                  </td>
                  <td class="px-2 py-1">
                          <span class="font-bold" v-if="option.flags.length > 0">
                            <NameHighlight :name="option.name" :flags="option.flags" :idx="idx"/>
                          </span>
                    <span v-else class="font-bold">{{option.name}}</span>
                  </td>
                  <td class="px-2 py-2">
                  <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer" @click="select('analysis-report')" v-if="option != null">
                    {{option.nameScore.text}}%
                  </span>
                    <span class="text-gray" v-else>--</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer" @click="select('flags')" v-if="option != null">
                    {{option.flags.length + option.rejectedNames.length}}
                  </span>
                    <span class="text-gray " v-else>--</span>
                  </td>
                  <td class="px-2 py-1 text-center">
                    <b-form-checkbox v-model="selectedId" :value="nameIdMap.get(option.name)"></b-form-checkbox>
                  </td>
                </tr>
                <tr style="border: solid 1px #f59696;" v-if="analysisReportError != null">
                  <td class="px-2 py-1">
                  <span class="text-red">
                    {{this.analysisReportError.data.message}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-red font-bold" v-for="(name, idx) in JSON.parse(this.analysisReportError.config.data).names" :key="idx">
                    {{name}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-red">0</span>
                  </td><td class="px-2 py-1">
                  <span class="text-red">0</span>
                </td><td class="px-2 py-1">
                  <span class="text-red">0</span>
                </td><td class="px-2 py-1 text-center">
                  <span class="text-red">N/A</span>
                </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="my-3 mx-1">
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <div class="flex float-end">
                <button class="btn-escalate" @click="escalatePrompt">
                  <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
                </button>
                <button class="btn-reject" v-b-modal.modal-reject>
                  <i class="fa fa-ban text-white mr-1"></i>Reject
                </button>
                <button class="btn-query" v-b-modal.modal-query>
                  <i class="fa fa-question text-white mr-1"></i> Query
                </button>
                <button class="btn-approve" v-if="analysisReportError == null" @click="showApprove">
                  <i class="fa fa-check-circle text-white mr-1"></i>Approve
                </button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mx-1">
            <b-col cols="6">
              <b-row >
                <b-col cols="12">
                  <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2">Nature of Business</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1">ISIC Code</th>
                      <th class="px-2 py-1">Breakdown</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1">{{getReservationTask.reservation.isic.code}}</td>
                      <td class="px-2 py-1">
                        <ul class="text-blue">
                          <li v-for="(section, idx) in getReservationTask.reservation.isic.breakdown"  :key="idx">
                            <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <table class="min-w-full leading-normal mt-1 text-blue-dark" style="border: solid 1px #bdd7f1;">
                    <thead>
                    <tr class="bg-blue-pale">
                      <td colspan="12" class="px-2 py-2">Applicant Details</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1">Name</th>
                      <th class="px-2 py-1">Email</th>
                      <th class="px-2 py-1">Phone</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #bdd7f1;">
                      <td class="px-2 py-1 text-capitalize">{{getReservationTask.reservation.user.fullName.toLowerCase()}}</td>
                      <td class="px-2 py-1">{{getReservationTask.reservation.user.email}}</td>
                      <td class="px-2 py-1">{{getReservationTask.reservation.user.phoneNumber}}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mx-1 mb-5">
            <b-col cols="12">
              <b-row>
                <b-col>
                  <div class="flex h-full" style="cursor: pointer;">
                    <div :class="[(currentRoute === item.route) ? 'menu-item-active' : 'menu-item']" v-for="(item, idx) in menu"
                         :key="idx" @click="select(item.route)">
                      <div class="flex mt-6">
                        <div style="font-size: 12px">{{item.title}}</div>
                        <b-badge v-if="item.count" class="mx-1 bg-blue" style="margin-bottom: 2px;">{{item.count}}</b-badge>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <ReservationNameSearch v-if="currentRoute === 'name-search'"/>
                  <AnalysisReport v-if="currentRoute === 'analysis-report'"/>
                  <SystemFlags v-if="currentRoute === 'flags'"/>
                  <ReservationFiles v-if="currentRoute === 'attachments'"/>
                  <ReservationMessages v-if="currentRoute === 'messages'"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import AnalysisReport from "@/views/home/services/reservation/task/AnalysisReport";
import SystemFlags from "@/views/home/services/reservation/task/SystemFlags";
import NameHighlight from "@/views/home/services/reservation/components/NameHighlight";
import ReservationMessages from "@/views/home/services/reservation/task/ReservationMessages";
import Escalation from "@/views/home/commons/Escalation";
import moment from "moment";
import ReservationNameSearch from "@/views/home/services/reservation/task/ReservationNameSearch";
import PRNViewer from "@/views/home/commons/PRNViewer";
import PickableNameSearch from "@/views/home/services/reservation/task/PickableNameSearch";
import ReservationFiles from "@/views/home/services/reservation/task/ReservationFiles";

export default {
  name: "NameReservationTask",
  props: {
    trackingNumber: {
      type: String,
      default: null
    }
  },
  components: {
    ReservationFiles,
    PickableNameSearch,
    PRNViewer,
    ReservationNameSearch,
    Escalation,
    ReservationMessages,
    NameHighlight, SystemFlags, AnalysisReport},
  data(){
    return{
      hasComments: false,
      currentRoute: "analysis-report",
      selectedId:null,
      margin:"2px",
      hasContent:false,
      nameIdMap:new Map(),
      loading:false,
      isQueryResponse:false,
      maxText:500,
      message:null,
      taskId:null,
      responses:[],
      reasons:[],
      finalMessage:{
        generated:null,
        custom:null
      },
      form:{
        description:null,
        reservationId:null,
        status:null
      },
      report:[],
      analysisReportError:null,
      messages:[],
      similarNames:[],
      canAppeal:false,
      menu:[
        {
          title:"Analysis Report",
          route:"analysis-report",
        },
        {
          title:"System Flags",
          route:"flags",
          count:0
        },
        {
          title:"Name Search",
          route:"name-search",
        },
        {
          title:"Attachments",
          route:"attachments",
        },
        {
          title:"Messages",
          route:"messages",
        },
      ],
      action:null
    }
  },
  mounted() {
    this.emptyAnalysisReport()
    // this.fetchNextReservation()
    this.fetchReservationResponses()
  },
  methods:{
    ...mapActions(["fetchNextReservation", "fetchReservationStatuses", "fetchReservationCount",
      "fetchAnalysisReport", "updateReservationStatus", "emptyAnalysisReport", "fetchReservationResponses"]),
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    cleanString(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    showApprove(){
      if(this.selectedId == null && this.analysisReportError == null){
        this.$refs["select-name-error"].show();
      }else{
        this.onApprove()
      }
    },
    onApprove(){
      this.action = "final"
      this.form.status = "Approved"
      this.form.nameOptionId = this.selectedId
      this.$refs.confirm.show();
    },
    onQuery(){
      this.action = "final"
      this.form.status = "Queried"
      if(this.form.description){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    onReject(){
      this.action = "final"
      this.form.status = "Rejected"
      this.form.canAppeal = this.canAppeal;
      if(this.form.description){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    escalatePrompt(){
      this.loading = true
      this.$refs.escalateSubmissions.show();
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateReservationStatus(this.form)
      }
      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    pickedNames(names){
      this.similarNames = names
    },
    copy(text){
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copied');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      this.$refs.searcher.searchThis(text)
    },
    clearForm(){
      this.form = {
        description:null,
        reservationId:null,
        status:null
      }
    },
    dateFormat(date){
      if(date){
        return moment(date).format("DD-MM-YYYY")
      }
      return ""
    }
  },
  computed:{
    ...mapGetters([
      "getReservationAssignmentSuccess",
      "getReservationTask",
      "getReservationStatuses",
      "getReservationEscalationSuccess",
      "getAnalysisReport",
      "getAnalysisReportError",
      "getReservationSuccess",
      "getReservationError",
      "getReservationsCount",
      "getReservationEscalationSuccess",
      "getReservationActionSuccess",
      "getReservationActionError",
      "getReservationResponses"]),
  },
  watch: {
    finalMessage:{
      handler(){
        this.form.description = ""
        if(this.finalMessage.generated != null){
          this.form.description += this.finalMessage.generated +" "
          for(let i = 0; i <this.reasons.length; i++){
            if(this.reasons[i].message === this.finalMessage.generated){
              this.canAppeal =  this.reasons[i].appealStatus
            }
          }
        }
        if(this.similarNames.length > 0){
          this.form.description += "Similar names include the following; "+this.similarNames.toString().replace(",", ", ")+". "
        }
        if(this.finalMessage.custom != null){
          this.form.description += this.finalMessage.custom
        }
      },
      deep:true
    },
    similarNames:{
      handler(){
        this.form.description = ""
        if(this.finalMessage.generated != null){
          this.form.description += this.finalMessage.generated +" "
        }
        if(this.similarNames.length > 0){
          this.form.description += "Similar names include the following; "+this.similarNames.toString().replace(",", ", ")+". "
        }
        if(this.finalMessage.custom != null){
          this.form.description += this.finalMessage.custom
        }
      },
      deep:true
    },
    getReservationTask: {
      handler(response) {
        this.emptyAnalysisReport()
        let data = response.reservation
        this.loading = false
        this.fetchReservationCount()
        this.fetchReservationStatuses(data.id)
        if(data){
          this.taskId = data.id
          this.form.reservationId = data.id
          let size = data.nameOptions.length
          let names = []
          this.hasContent = true
          this.emptyAnalysisReport()
          let nameMap = new Map()
          data.nameOptions.forEach(option => {
            nameMap.set(option.nameIndex.toString(), option.name.trim())
            this.nameIdMap.set(option.name.trim(), option.id)
          })
          for (let i = 0; i < size; i++){
            let key = i + 1
            names[i] = nameMap.get(key.toString())
          }
          this.fetchAnalysisReport(names)
          if(size === 1){
            this.selectedId = data.nameOptions[0].id
          }
        }else{
          this.hasContent = false
          this.successToast("Fetching task", "There are no task yet")
        }
      },
      deep: true,
      immediate: true,
    },
    getReservationStatuses(data){
      for(let i = 0; i<data.length; i++){
        let message = data[i]
        this.messages.push(message)
      }

      if(this.messages.length > 1){
        if(this.messages[this.messages.length - 1].status === "Queried"){
          this.isQueryResponse = true
        }
      }
    },
    getReservationSuccess(){
      this.loading = false
      this.successToast("Reservation Action!", "Completed Task Successfully")
      this.clearForm()
      this.$refs["reject-reservation"].hide();
      this.$refs["query-reservation"].hide();
    },
    getReservationAssignmentSuccess(){
      this.loading = false
    },
    getReservationActionSuccess(){
      this.loading = false
      this.successToast("Reservation Action!", "Completed Task Successfully")
      this.clearForm()
      this.$refs["reject-reservation"].hide();
      this.$refs["query-reservation"].hide();
    },
    getReservationActionError(e){
      this.loading = false
      this.errorToast("Error!", "Final Decision Failed: " + e.data.message)
      // this.clearForm()
      this.$refs["reject-reservation"].hide();
      this.$refs["query-reservation"].hide();
    },
    getReservationEscalationSuccess(){
      this.loading = false
      this.successToast("Escalation Action!", "Task Escalated Successfully")
      this.clearForm()
      this.$refs.escalateSubmissions.hide();
    },
    getReservationResponses(data){
      this.reasons = data
      this.responses = []
      this.responses.push(
        {
          value:null,
          text:"--Select a Reason --"
        }
      )
      for (let i= 0; i< this.reasons.length; i++){
        this.responses.push(
          {
            value:this.reasons[i].message,
            text:this.reasons[i].reason
          }
        )
      }
    },
    getReservationError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data))
      this.clearForm()
    },
    getAnalysisReport(data){
      for(let i = 0; i<data.length; i++){
        this.menu[2].count += (data[i].flags.length + data[i].rejectedNames.length)
      }
    },
    getAnalysisReportError(data){
      this.analysisReportError = data
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";
.title{
  font-size: 22px;
}

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue bg-white rounded-sm text-blue text-sm px-3 py-1 mx-1;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}
</style>