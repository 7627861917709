import { render, staticRenderFns } from "./CertificationTaskQueue.vue?vue&type=template&id=934220bc&scoped=true&"
import script from "./CertificationTaskQueue.vue?vue&type=script&lang=js&"
export * from "./CertificationTaskQueue.vue?vue&type=script&lang=js&"
import style0 from "./CertificationTaskQueue.vue?vue&type=style&index=0&id=934220bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "934220bc",
  null
  
)

export default component.exports