<template>
  <b-row class="shadow-sm bg-header_grey">
   <b-col>
     <b-row>
       <b-col cols="4" class="my-3">
         <div class="flex">
           <i @click="toggleMenu" :class="(menu_switch) ? 'fa fa-bars menu-active' : 'fa fa-bars menu'"></i>
           <router-link class="w-11/12 ml-3" :to="{name:'profile'}">
             <img class="logo" :src="require('/src/assets/images/banner.png')"/>
           </router-link>
         </div>
       </b-col>
       <b-col cols="4"></b-col>
       <b-col cols="2">
           <span class="flex flex-row justify-between  float-end" >
               <i class="fa fa-search ura-btn mx-2 cursor-pointer" @click="showTrackingPopUp" title="Click to track an application" ></i>
               <i class="fa fa-money-check-alt ura-btn cursor-pointer" @click="showURAPopUp" title="Click to verify a PRN"></i>
           </span>

       </b-col>
       <b-col cols="2">
         <b-row >
           <b-dropdown class="noFocus" size="lg" variant="link" toggle-class="text-decoration-none" no-caret id="dropdown-1">
             <template #button-content>
               <div class="flex">
                 <i class="fa fa-user text-xl mt-1 text-blue rounded-full bg-header_grey"></i>
                 <b-row class="ml-1 mt-1">
                   <b-col cols="12">
                     <div style="text-align: left;">
                       <p class="text-gray text-xs">
                         <b class="break-words">{{userProfile.givenName}} {{userProfile.surName}}</b><br/>
                         <small class="break-words">{{userProfile.email}}</small>
                       </p>
                     </div>
                     <b-row>
                       <div class="text-gray text-xs"></div>
                     </b-row>
                   </b-col>
                 </b-row>
               </div>
             </template>

             <b-dropdown-item @click="profile">
               <div class="account-menu-spacing">Manage Account</div>
             </b-dropdown-item>
             <b-dropdown-item @click="settings">
               <div class="account-menu-spacing">Settings</div>
             </b-dropdown-item>
             <b-dropdown-divider></b-dropdown-divider>
             <b-dropdown-item @click="logout">
               <div class="account-menu-spacing">Logout</div>
             </b-dropdown-item>
           </b-dropdown>
         </b-row>
       </b-col>
     </b-row>
     <b-row>
       <b-col>
         <b-modal title="PRN Verification" ref="verifyPRN" hide-footer>
           <VerifyPRN/>
         </b-modal>

           <b-modal title="Track Application status" ref="trackApplication" size="lg" hide-footer>
               <Tracking></Tracking>
           </b-modal>


       </b-col>
     </b-row>
   </b-col>
  </b-row>
</template>

<script>
import jwtService from "@/app/services/jwt.service";
import {mapActions, mapGetters} from "vuex";
import VerifyPRN from "@/views/home/commons/VerifyPRN";
import Tracking from "@/views/home/services/tracking/Tracking.vue";

export default {
  name: "HomeTopBar",
  components: {Tracking, VerifyPRN },
  data(){
    return{
      menu_switch:true,
      userProfile:{},
    }
  },
  mounted() {
    this.userProfile = jwtService.getUser()
  },
  methods:{
    ...mapActions(["logoutUser"]),
    toggleMenu(){
      this.menu_switch = !this.menu_switch
      this.$emit('toggle', this.menu_switch)
    },
    logout(){
      this.logoutUser()
    },
    profile(){
      let r = this.$router.resolve({
        name: "profile",
      });
      window.location.assign(r.href);
    },
    settings(){
      alert("Coming soon")
    },
    showURAPopUp(){
      this.$refs.verifyPRN.show()
    },

      showTrackingPopUp(){
          this.$refs.trackApplication.show()
      }
  },
  computed:{
    ...mapGetters(["getUserLogoutSuccess"])
  },
  watch:{
    getUserLogoutSuccess(){
      sessionStorage.clear()
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href);
    },
  }
}
</script>

<style scoped>

.menu{
  @apply text-gray mt-1;
  font-size: 20px;
  cursor: pointer;
}

.menu:hover{
  @apply text-blue;
  font-size: 19px;
}

.menu-active{
  @apply text-blue mt-1;
  font-size: 20px;
  cursor: pointer;
}

.logo{
  width: 85px;
  margin-top: 2px;
}

.ura-btn{
  @apply mt-5 text-blue;
  width: 25px;
  font-size: 20px;
}

.ura-btn:hover{
  @apply text-blue-dark;
}

.account-menu-spacing{
  width: 200px
}
</style>