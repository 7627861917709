<template>
  <b-row>
    <b-col>
      <b-overlay :show="loading" rounded="sm">
        <b-row>
          <b-col>
            <b-row class="mx-1">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2">Document Details</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Tracking Number</th>
                    <th class="px-2 py-1">Status</th>
                    <th class="px-2 py-1">Document Type</th>
                    <th class="px-2 py-1">Submission Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" >
                    <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.trackingNumber}}
                  </span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-blue text-capitalize">{{application.status.toLowerCase()}}</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-gray text-capitalize">{{cleanEntityType(application.documentType.toLowerCase())}}</span>
                    </td>
                    <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="mx-1" v-if="application.user">
              <b-col cols="12">
                <b-row>
                  <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-blue-dark" style="border: solid 1px #bdd7f1;">
                      <thead>
                      <tr class="bg-blue-pale">
                        <td colspan="12" class="px-2 py-2">Applicant Details</td>
                      </tr>
                      <tr>
                        <th class="px-2 py-1">Name</th>
                        <th class="px-2 py-1">Email</th>
                        <th class="px-2 py-1">Phone</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #bdd7f1;">
                        <td class="px-2 py-1 text-capitalize">{{application.user.firstName}} {{application.user.lastName}}</td>
                        <td class="px-2 py-1">{{application.user.email}}</td>
                        <td class="px-2 py-1">{{application.user.phoneNumber}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mx-1 mt-2">
              <b-col cols="12">
                <PRNViewer v-if="application.status !== 'DRAFT'" :tracking-number="application.trackingNumber" :prn="application.prn"/>
              </b-col>
            </b-row>
            <b-row class="mx-1 mb-5">
              <b-col cols="12">
                <b-row>
                  <b-col>
                    <div class="flex h-full" style="cursor: pointer;">
                      <div :class="[(currentRoute === item.route) ? 'menu-item-active' : 'menu-item']" v-for="(item, idx) in menu" :key="idx" @click="select(item.route)">
                        <div class="flex mt-6">
                          <div style="font-size: 12px">{{item.title}}</div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <LegalDocumentFormDetailsBox :application="application" v-if="currentRoute === 'info'"/>
                    <LegalDocumentFormFilesBox :application="application" v-if="currentRoute === 'attachments'"/>
                    <LegalDocumentMessagesDetailsBox :document-id="application.documentId" v-if="currentRoute === 'messages'"/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer";
import LegalDocumentFormDetailsBox
  from "@/views/home/services/legal-documents/submissions/components/LegalDocumentFormDetailsBox";
import LegalDocumentFormFilesBox
  from "@/views/home/services/legal-documents/submissions/components/LegalDocumentFormFilesBox";
import LegalDocumentMessagesDetailsBox
  from "@/views/home/services/legal-documents/submissions/components/LegalDocumentMessagesDetailsBox";

export default {
  name: "LegalDocumentDetailsBox",
  components: { LegalDocumentMessagesDetailsBox, LegalDocumentFormFilesBox, LegalDocumentFormDetailsBox, PRNViewer },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      loading:false,
      application:null,
      currentRoute: "info",
      menu:[
        {
          title:"Document Information",
          route:"info",
        },
        {
          title:"Attachment",
          route:"attachments",
        },
        {
          title:"Messages",
          route:"messages",
        },
      ],
    }
  },
  mounted() {
    this.loading = true
    this.fetchLegalDocumentDetailsBox(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchLegalDocumentDetailsBox"]),
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
  },
  computed:{
    ...mapGetters(["getLegalDocumentDetailsBox", "getLegalDocumentDetailsErrorBox"])
  },
  watch:{
    getLegalDocumentDetailsBox(data){
      this.loading = false
      this.application = data
    },
    getLegalDocumentDetailsErrorBox(){
      this.loading = false
      this.errorToast("Error", "Failed to get application details")
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>