<template>
  <div>
    <div class="bg-white rounded-sm m-2">
      <b-row>
        <b-col cols="12" class="m-2">
          <b-row>
            <b-col cols="6">
              <span class="text-xs px-2 py-1 text-red">
                <b>{{getLegalDocumentCount.total}}</b> Total Submissions
              </span>
              <span class="text-xs px-2 py-1 text-blue ml-2">
                <b>{{ getLegalDocumentCount.assigned }}</b> Assigned
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white rounded-sm m-2">
      <b-row class="mx-1 py-2" v-if="getLegalDocumentTask != null">
        <b-col cols="6" >
          <b-row>
            <b-col>
              <span class="bg-blue text-white px-2 py-1 rounded-sm text-xs font-bold">#LegalDocumentRegistration</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6"></b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row v-if="getLegalDocumentTask == null" class="mx-1 py-5">
            <b-col cols="12" class="py-5">
              <b-row>
                <b-col cols="12" class="text-center justify-content-center">
                  <img :src="require('../../../../../assets/images/ic_empty_box.png')" class="icon mx-auto">
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="12" class="text-center">
                  <div class="h5 text-gray mx-auto">There are no Legal Document Registrations left in the queue</div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="text-center">
                  <div class="text-gray mx-auto">Tasks will be available when clients submit new <b class="text-blue">applications</b> or when <b class="text-orange">queries</b> are responded to.</div>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="12" class="text-center">
                  <button class="text-white bg-blue rounded-md px-2 py-2 mx-auto hover:bg-blue-dark" @click="checkForUpdates">Check for Updates</button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <LegalDocumentTask/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import LegalDocumentTask from "@/views/home/services/legal-documents/task/LegalDocumentTask";

export default {
  name: "LegalDocumentTaskQueue",
  components: {LegalDocumentTask},
  data(){
    return{
      margin:"2px",
      loading:true,
      message:null,
    }
  },
  mounted() {
    this.fetchNextLegalDocument()
  },
  methods:{
    ...mapActions(["fetchNextLegalDocument", "fetchLegalDocumentCount"]),
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
    ...mapGetters(["getLegalDocumentTask", "getLegalDocumentCount"]),
  },
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";
.title{
  font-size: 22px;
}

.icon{
  width: 170px;
}

.btn-escalate{
  @apply border bg-orange rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply bg-orange-dark;
}

.btn-approve{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}
</style>