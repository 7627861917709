<template>
  <div class="bg-white rounded-sm mx-2 mt-3">
    <b-row>
      <b-modal id="modal-details" size="xl" hide-footer ref="escalate-details" title="Escalated File">
        <b-col cols="12">
          <AnnualReturnsEscalatedFile v-if="application" :tracking-number="application.trackingNo"/>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Annual Returns Escalations</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12">
        <div class="mx-auto">
          <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
        </div>
        <b-row>
          <b-col cols="12">
            <table class="min-w-full leading-normal">
              <thead>
              <tr class="border-b border-t border-grey">
                <th class="t-header">Tracking No.</th>
                <th class="t-header">BRN</th>
                <th class="t-header">Entity Name</th>
                <th class="t-header">Years</th>
                <th class="t-header">Registrar</th>
                <th class="t-header text-center">Date</th>
                <th class="t-header text-center">Action</th>
              </tr>
              </thead>

              <tbody>
              <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in escalations" :key="idx">
                <td class="t-body text-blue font-bold">{{ submission.task.data.trackingNo }}</td>
                <td class="t-body font-bold"> {{ submission.task.data.brn }}</td>
                <td class="t-body text-uppercase"> {{ submission.task.data.companyName }}</td>
                <td class="t-body text-uppercase">
                  <p v-for="(year, idx) in submission.task.data.years" :key="idx">
                   - {{year}}
                  </p>
                </td>
                <td class="t-body text-capitalize">{{ submission.task.data.registrarName}}</td>
                <td class="t-body text-center">
                  {{ dateTimeFormat(submission.task.data.createdAt) }}
                </td>
                <td class="t-body text-center">
                  <button @click="view(submission.task)" class="view-btn">View</button>
                </td>
              </tr>
              <tr>
                <td v-if="escalations.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                  <span class="text-sm">No results found</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="pagination.totalPages > 1">
          <b-col cols="1">
            <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
          </b-col>
          <b-col cols="1">
            <b-row>
              <b-col cols="12">
                <p class="paginate-details text-center">
                  <b-badge class="bg-blue text-white">
                    {{ currentPage }}/{{ pagination.totalPages }}
                  </b-badge>
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1">
            <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next
            </button>
          </b-col>
          <b-col class="9"></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AnnualReturnsEscalatedFile
  from "@/views/home/services/annual-returns/escalations/AnnualReturnsEscalatedFile.vue";

export default {
  name: "AnnualReturnsEscalations",
  components: {AnnualReturnsEscalatedFile},
  data() {
    return {
      selectedId: null,
      margin: "2px",
      hasContent: false,
      loading: false,
      message: null,
      currentPage: 1,
      limit: 12,
      application: null,
      escalations: [],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      }
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions(["fetchAnnualReturnsEscalations"]),
    fetch() {
      let data = {
        statuses: "PENDING",
        page: this.currentPage,
        limit: this.limit
      };
      this.loading = true;
      this.fetchAnnualReturnsEscalations(data);
    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++;
        this.fetch();
      }
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetch();
      } else {
        this.warningToast("Observation", "No more data to show");
      }
    },
    view(submission) {
      this.application = submission.data;
      this.$refs["escalate-details"].show();
    }
  },
  computed:{
    ...mapGetters(["getAnnualReturnsEscalations"])
  },
  watch:{
    getAnnualReturnsEscalations(data){
      this.loading = false
      this.escalations = data.escalations
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    }
  }
};
</script>

<style scoped>

.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.verified{
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified{
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

</style>