<template>

    <b-row>
        <b-col>

            <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Beneficial Owners</td>
                </tr>
                </thead>
                <tbody v-if="data">
                <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Country</td>
                    <td class="t-body">
                        {{data.country.name}}
                    </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Physical Address</td>
                    <td class="t-body text-uppercase">
                        <p v-if="data.village">
                            <span class="mr-1">{{data.village.region.name}} > </span>
                            <span class="mx-1">{{data.village.district.name}} > </span>
                            <span class="mx-1">{{data.village.subcounty.name}} > </span>
                            <span class="mx-1">{{data.village.parish.name}} > </span>
                            <span class="mx-1">{{data.village.village.name}}</span>
                        </p>
                        <p v-else>N/A</p>
                    </td>
                </tr>
                </tbody>
            </table>
            <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                <tbody v-if="data">
                <tr>
                    <td colspan="12">
                        <BeneficialOwners :persons="data.beneficialOwners"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </b-col>
    </b-row>

</template>

<script>
import BeneficialOwners from "@/views/home/commons/BeneficialOwners.vue";
export default {
    name: "ContinousFilingBeneficialOwners",
    components :{
        BeneficialOwners
    },
    props:{
        data:Object
    },

}
</script>

<style scoped>

</style>