<template>
  <div class="overflow-hidden bg-gray p-2">
    <b-row class="my-2">
      <b-col cols="12">
        <iframe class="pdf" :src="url+'#toolbar=0'">
        </iframe>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "DisputeResolutionAttachments",
  data(){
    return{
      loading:false,
      url: null
    }
  },
  methods:{
    openFile(url){
      this.url = url+"&token="+sessionStorage.getItem("access_token").replaceAll("\"", "")
    }
  },
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>