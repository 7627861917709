import {api} from "@/app/services/api.service";

const state = {
    incorporation:{},
    incorporation_snapshots:null,
    incorporation_escalations:null,
    incorporation_escalation:null,
    incorporation_details:null,
    payment_removed:null,
    payment_removed_error:null,
    incorporation_details_error:null,
    preview:null,
    update_status_success:null,
    task_count:{},
    task_thread:{},
    registration_stats:{},
    incorporations:null,
    error:null,
    success:null,
    transferOwner:null,
    transferOwnerError:null,
};

const getters = {
    getCompanyIncorporationSubmissions: (state) => state.incorporations,
    getIncorporationEscalations: (state) => state.incorporation_escalations,
    getIncorporationEscalation: (state) => state.incorporation_escalation,
    getIncorporationTaskCount: (state) => state.task_count,
    getIncorporationTaskMessages: (state) => state.task_thread,
    getIncorporationStatistics: (state) => state.registration_stats,
    getIncorporationTask: (state) => state.incorporation,
    getIncorporationSnapshots: (state) => state.incorporation_snapshots,
    getIncorporationTaskError: (state) => state.error,
    getCompanyUpdateStatusSuccess: (state) => state.update_status_success,
    getCompanyUpdateStatusSuccessError: (state) => state.error,
    getIncorporationsError: (state) => state.error,
    getIncorporationPaymentRemoved: (state) => state.payment_removed,
    getIncorporationPaymentRemovedError: (state) => state.payment_removed_error,
    getIncorporationDetails: (state) => state.incorporation_details,
    getIncorporationDetailsError: (state) => state.incorporation_details_error,
    getTransferDetails: (state) => state.transferOwner,
    getTransferError: (state) => state.transferOwnerError,
};

const actions = {
    async submitCompanyIncorporationPayment({commit}, data) {
        await api().post("incorporation/companies/add-payment", data)
            .then((response) => {
                commit("setCompanyRegistration", response.data);
            })
            .catch((error) => {
                commit("setRegistrationError", error.response);
            });
    },
    async fetchCompanyIncorporations({commit}, data){
        let path = "/incorporation/companies/queue/tasks?status="+data.status+"&page="+data.page+"&perPage="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setCompanyIncorporations', response.data);
        }).catch(error => {
            commit("setIncorporationsError", error.response)
        });
    },
    async fetchAllCompanyIncorporations({commit}, data){
        let path = "/incorporation/companies?status="+data.status+"&page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setCompanyIncorporations', response.data);
        }).catch(error => {
            commit("setIncorporationsError", error.response)
        });
    },
    async fetchCompanyEscalations({commit}, data){
        api().get("/incorporation/companies/queue/my-escalations?page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setCompanyIncorporationEscalations', response.data);
        }).catch(error => {
            commit("setIncorporationsError", error.response)
        });
    },
    async fetchQueriedCompanyIncorporations({commit}, data){
        console.log(JSON.stringify(data))
        api().get("/incorporation/companies/queue/queries-submissions?status="+data.statuses+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setCompanyIncorporations', response.data);
        }).catch(error => {
            commit("setIncorporationsError", error.response)
        });
    },
    async fetchCompanyIncorporationTask({commit}){
        api().get("/incorporation/companies/queue/task").
        then(response => {
            commit('setCompanyRegistration', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setIncorporationTaskError", error.response)
        });
    },
    async fetchCompanyIncorporationTaskSnapshots({commit}, trackingNumber){
        api().get("/incorporation/companies/application-snapshot/"+trackingNumber).
        then(response => {
            commit('setCompanyRegistrationSnapshot', response.data);
        }).catch(error => {
            commit("setIncorporationTaskError", error.response)
        });
    },
    async fetchIncorporationTaskCount({commit}){
        api().get("/incorporation/companies/queue/count").
        then(response => {
            commit('setTaskCount', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchCompanyStatistics({commit}){
        api().get("/incorporation/companies/status-statistics").
        then(response => {
            commit('setIncorporationStatistics', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchIncorporationTaskMessages({commit}, id){
        api().get("/incorporation/companies/queue/task/"+id+"/thread").
        then(response => {
            commit('setIncorporationTaskMessages', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchIncorporationEscalation({commit}, id){
        api().get("/incorporation/companies/queue/task/"+id+"/escalation").
        then(response => {
            commit('setIncorporationEscalation', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async updateIncorporationStatus({commit}, data){
        api().post("/incorporation/companies/queue/update-status", data).
        then(response => {
            commit('setUpdateCIStatusSuccess', response.data);
        }).catch(error => {
            commit("setRegistrationError", error.response)
        });
    },
    async fetchIncorporationDetails({commit}, trackingNumber){
        api().get("/incorporation/companies/incorporation-draft-by-tracking-no/"+trackingNumber).
        then(response => {
            commit('setIncorporationDetails', response.data);
        }).catch(error => {
            commit("setIncorporationDetailsError", error.response)
        });
    },
    async removePaymentFromApplication({commit}, data){
        api().post("/incorporation/companies/remove-payment-details", data).
        then(response => {
            commit('setRemovePaymentDetails', response.data);
        }).catch(error => {
            commit("setRemovePaymentDetailsError", error.response)
        });
    },
    async removePaymentFromCFApplication({commit}, data){
        api().post("/incorporation/companies/continuous-filing/remove-payment-details", data).
        then(response => {
            commit('setRemovePaymentDetails', response.data);
        }).catch(error => {
            commit("setRemovePaymentDetailsError", error.response)
        });
    },
    async transferApplicationOwnership({commit}, data){
        api().post("/incorporation/companies/application-ownership-transfers", data).
        then(response => {
            commit('setTransferApplicationOwner', response.data);
        }).catch(error => {
            commit("setTransferApplicationOwnerError", error.response)
        });
    },
    async transferReservationApplicationOwnership({commit}, data){
        api().post("/reservation/application-ownership-transfers", data).
        then(response => {
            commit('setTransferApplicationOwner', response.data);
        }).catch(error => {
            commit("setTransferApplicationOwnerError", error.response)
        });
    },
};

const mutations = {
    setCompanyIncorporations: (state, response) =>{
        state.incorporations = response;
    },
    setCompanyIncorporationEscalations: (state, response) =>{
        state.incorporation_escalations = response;
    },
    setIncorporationEscalation: (state, response) =>{
        state.incorporation_escalation = response;
    },
    setIncorporationsError: (state, response) =>{
        state.error = response;
    },
    setTaskCount: (state, response) =>{
        state.task_count = response;
    },
    setIncorporationStatistics: (state, response) =>{
        state.registration_stats = response;
    },
    setIncorporationTaskMessages: (state, response) =>{
        state.task_thread = response.data;
    },
    setCompanyRegistration: (state, response) =>{
        state.incorporation = response;
    },
    setCompanyRegistrationSnapshot: (state, response) =>{
        state.incorporation_snapshots = response;
    },
    setIncorporationTaskError: (state, response) =>{
        state.error = response;
    },
    setAssignmentSuccess: (state, response) =>{
        state.success = response;
    },
    setRegistrationError: (state, response) =>{
        state.error = response;
    },
    setUpdateCIStatusSuccess: (state, response) =>{
        state.update_status_success = response;
    },
    setIncorporationDetails:(state, response) =>{
        state.incorporation_details = response.data
    },
    setRemovePaymentDetails:(state, response) =>{
        state.payment_removed = response.data
    },
    setRemovePaymentDetailsError:(state, response) =>{
        state.payment_removed_error = response.data
    },
    setIncorporationDetailsError:(state, response) =>{
        state.incorporation_details_error = response.data
    },
    setTransferApplicationOwner:(state, response) =>{
        state.transferOwner = response.data
    },
    setTransferApplicationOwnerError:(state, response) =>{
        state.transferOwnerError = response.data
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};