<template>
  <b-row>
    <b-col>
      <b-overlay rounded="sm" :show="loading">
        <b-col>
          <b-row v-if="application !== null">
            <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{application.name}}
            </span>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Registration Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Sub Entity Type</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="application !== null" style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.trackingNo}}
                  </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue text-capitalize">{{application.status.toLowerCase().replaceAll("_", " ")}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{cleanEntityType(application.subType)}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <b-col cols="12" v-if="application !== null">
                <PRNViewer v-if="application.status !== 'DRAFT'" :tracking-number="application.trackingNo" :prn="application.payment.prn"/>
              </b-col>
              <b-col cols="12" v-else>
                <PRNViewer :prn="null"/>
              </b-col>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <div class="flex h-full" style="cursor: pointer;">
                <div :class="[(currentRoute === 'application-details') ? 'menu-item-active' : 'menu-item']" @click="select('application-details')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Details</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Attachments</div>
                  </div>
                </div>
                <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                  <div class="flex mt-6">
                    <div style="font-size: 14px">Messages</div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <SocietyFormDetails :application="application" v-if="currentRoute === 'application-details'"/>
              <SocietyFormFiles :application="application" v-if="currentRoute === 'attachments'"/>
              <SocietyMessages :application="application" v-if="currentRoute === 'messages'"/>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import SocietyFormDetails
  from "@/views/home/services/bsi_incorporation/submissions/components/SocietyFormDetails.vue";
import SocietyFormFiles
  from "@/views/home/services/bsi_incorporation/submissions/components/SocietyFormFiles.vue";
import SocietyMessages
  from "@/views/home/services/bsi_incorporation/submissions/components/SocietyMessages.vue";

export default {
  name: "SocietyIncorporationDetails",
  components: {
    SocietyMessages,
    SocietyFormFiles,
    SocietyFormDetails,
    PRNViewer
  },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      application: null,
      currentRoute: "application-details",
      loading:true,
    }
  },
  mounted() {
    this.fetchSocietyDetails(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchSocietyDetails"]),
    select(route){
      this.currentRoute = route
    },
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
  },
  computed:{
    ...mapGetters(["getSocietyDetails", "getSocietyDetailsError"])
  },
  watch:{
    getSocietyDetails(data){
      this.loading = false
      this.application = data
    },
    getSocietyDetailsError(error){
      this.loading = false
      this.errorToast("Error", JSON.stringify(error))
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>