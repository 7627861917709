<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal size="xl" ref="edmsDocument" e title="EDMS Document" hide-footer>
          <b-overlay :show="fileLoader" rounded="sm">
            <EDMSDocument :file="file"/>
          </b-overlay>
        </b-modal>
      </b-row>
      <b-row>
        <b-col v-if="file.Base64FileStream" class="mt-2">
          <b-row class="my-1">
            <b-col cols="3">
              <button class="bg-gray rounded text-sm text-white w-full px-2 py-1 hover:bg-gray-dark " @click="openFullScreen">
                <i class="fa fa-expand mx-1"></i> Expand
              </button>
            </b-col>
            <b-col cols="3">
              <button class="bg-gray rounded text-sm text-white w-full px-2 py-1 hover:bg-gray-dark " @click="closeViewer">
                <i class="fa fa-times mx-1"></i> Close
              </button>
            </b-col>
          </b-row>
          <EDMSDocument :file="file"/>
        </b-col>
        <b-col v-else cols="12" class="my-2">
          <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2 font-bold">
                <b-row>
                  <b-col>
                    <p class="mt-3">
                      EDMS Documents Explorer
                    </p>
                  </b-col>
                  <b-col>
                    <b-form-input
                      class="text-gray text-sm noFocus mx-2 my-2"
                      style="padding:6px 5px;"
                      v-model="documentName"
                      type="text"
                      placeholder="Search by Name"
                      required
                      v-on:keyup.enter="fetch">
                    </b-form-input>
                  </b-col>
                  <b-col>
                    <b-row class="mt-2">
                      <b-col cols="12" v-if="entityType.toLowerCase() === 'company'">
                        <b-form-group>
                          <b-form-select
                            v-model="documentType"
                            v-on:change="fetch"
                            class="form-select noFocus text-gray  mb-2 text-sm">
                            <b-select-option :value="null">--Filter by Type--</b-select-option>
                            <b-select-option value="CERTIFICATE">Certificate</b-select-option>
                            <b-select-option value="FORMS18">Form S18</b-select-option>
                            <b-select-option value="FORMA1">Form A1</b-select-option>
                            <b-select-option value="FORM18">Form 18</b-select-option>
                            <b-select-option value="FORM10">Form 10</b-select-option>
                            <b-select-option value="MEMARTS">Memarts</b-select-option>
                            <b-select-option value="OTHER">Others</b-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" v-if="entityType.toLowerCase()  === 'business'">
                        <b-form-group>
                          <b-form-select
                            v-model="documentType"
                            v-on:change="fetch"
                            class="form-select noFocus text-gray mb-2 text-sm">
                            <b-select-option :value="null">--Filter by Type--</b-select-option>
                            <b-select-option value="CERTIFICATE">Certificate</b-select-option>
                            <b-select-option value="BUSINESSNAME">Registration Form</b-select-option>
                            <b-select-option value="OTHER">Others</b-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </td>
            </tr>
            <tr>
              <th class="px-2 py-1">#</th>
              <th class="px-2 py-1">File Name</th>
              <th class="px-2 py-1 text-center">Action</th>
            </tr>
            <tr>
              <td colspan="12">
                <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(file, idx) in documents" :key="idx" style="border: solid 1px #dedede;">
              <td class="px-2 py-1">{{(currentPage - 1) * limit + idx + 1}}</td>
              <td class="px-2 py-1 text-capitalize">
                {{ file.Name }}
              <p class="mt-1 italic" style="font-size: 9px;">{{dateTimeFormat(file.docDate)}}</p>
              </td>
              <td class="px-2 py-1 text-center">
                <button class="bg-gray rounded text-white w-full px-2 py-1 hover:bg-gray-dark " @click="openFile(file)">
                  Show <b-spinner v-if="fileLoader && selectedDocumentHandle === file.documentHandle" small></b-spinner>
                </button>
              </td>
            </tr>
            <tr>
              <td v-if="documents.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No documents found</span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="2" class="text-center">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1" class="text-center">
          <p class="paginate-details text-center">
            <b-badge class="bg-blue text-white">
              {{ currentPage }}/{{ pagination.totalPages }}
            </b-badge>
          </p>
        </b-col>
        <b-col cols="2" class="text-center">
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next
          </button>
        </b-col>
        <b-col cols="2"></b-col>
        <b-col cols="2">
          <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details"
                         v-on:change="fetch">
            <b-select-option value="15">15</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col class="mx-auto">
              <p class="paginate-details text-center text-xs font-bold">
                <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                <span v-if="limit > documents.length">{{ pagination.totalElements }}</span>
                <span v-else>{{ currentPage * limit }}</span>
                of {{ pagination.totalElements }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument.vue";

export default {
  name: "EMDSFileExplorer",
  components: { EDMSDocument },
  props: {
    brn: String,
    entityType: String
  },
  data() {
    return {
      loading: false,
      fileLoader:false,
      documents: [],
      currentPage: 1,
      limit: 15,
      documentType: null,
      documentName: null,
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      selectedDocumentHandle:null,
      file:{
        extension:"pdf",
        Base64FileStream:null
      }
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions(["fetchBusinessNameEDMSDocuments", "fetchCompanyEDMSDocuments", "fetchCompanyEDMSFile"]),
    fetch() {
      let data = {
        brn: this.brn,
        documentType: this.documentType,
        documentName: this.documentName,
        page: this.currentPage,
        limit: this.limit
      };
      this.loading = true;
      if (this.entityType.toLowerCase() === "business") {
        this.fetchBusinessNameEDMSDocuments(data);
      }

      if (this.entityType.toLowerCase() === "company") {
        this.fetchCompanyEDMSDocuments(data);
      }
    },
    openFile(file) {
      this.selectedDocumentHandle = file.documentHandle
      let data = {
        documentHandle:file.documentHandle,
        overlay:""
      }
      this.fileLoader = true
      this.fetchCompanyEDMSFile(data)
    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++;
        this.fetch();
      }
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetch();
      } else {
        this.warningToast("Observation", "No more data to show");
      }
    },
    openFullScreen(){
      this.$refs.edmsDocument.show();
    },
    closeViewer(){
      this.file.Base64FileStream = null
    }
  },
  computed: {
    ...mapGetters([
      "getBusinessNameEDMSDocuments",
      "getBusinessNameEDMSDocumentsError",
      "getCompanyEDMSDocuments",
      "getCompanyEDMSDocumentsError",
      "getEDMSFile"])
  },
  watch: {
    getBusinessNameEDMSDocuments(data) {
      this.loading = false;
      this.documents = data.content;
      this.pagination = {
        totalPages: data.totalPages,
        totalElements: data.numberOfElements
      };
    },
    getCompanyEDMSDocuments(data) {
      console.log(data)
      this.loading = false;
      this.documents = data.content;
      this.pagination = {
        totalPages: data.totalPages,
        totalElements: data.numberOfElements
      };
    },
    getBusinessNameEDMSDocumentsError(data) {
      this.loading = false;
      this.errorToast("Error", data.message);
    },
    getCompanyEDMSDocumentsError(data) {
      this.loading = false;
      this.errorToast("Error", data.message);
    },
    getEDMSFile(data){
      this.fileLoader = false
      this.file = data
    },
  }
};
</script>

<style scoped>
th, td {
  font-size: 12px;
}

.view-btn {
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover {
  @apply bg-blue text-white;
}

.verified {
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified {
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn {
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs;
}

.assign-btn:hover {
  @apply bg-green text-white;
}

.de-assign-btn {
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs;
}

.de-assign-btn:hover {
  @apply bg-red text-white;
}

.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>