import {api} from "../../../../../app/services/api.service"
const state = {
    words:[],
    dictionaries:[],
    error:null,
    success:{}
};

const getters = {
    getDictionaries: (state) => state.dictionaries,
    getWords: (state) => state.words,
    getWordTransactionSuccess:(state) => state.success,
    getWordTransactionError:(state) => state.error
};

const actions = {
    async fetchDictionaries({commit}){
        api().get("/namesearch/dictionary").
        then(response => {
            commit('setDictionaries', response.data);
        }).catch(error => {
            commit("setDictionaryError", error.response)
        });
    },
    async fetchWords({commit}){
        api().get("/namesearch/dictionary/word").
        then(response => {
            commit('setWords', response.data);
        }).catch(error => {
            commit("setDictionaryError", error.response)
        });
    },
    async fetchFilteredWords({commit},id){
        api().get("/namesearch/dictionary/word?dictionaryId="+id).
        then(response => {
            commit('setWords', response.data);
        }).catch(error => {
            commit("setDictionaryError", error.response)
        });
    },
    async addWord({commit}, data){
        api().post("/namesearch/dictionary/word", data).
        then(response => {
            commit('setAddWordSuccess', response.data);
        }).catch(error => {
            commit("setDictionaryError", error.response)
        });
    },
    async editWord({commit}, data){
        api().put("/namesearch/dictionary/word?wordId="+data.id, data.form).
        then(response => {
            commit('setEditWordSuccess', response.data);
        }).catch(error => {
            commit("setDictionaryError", error.response)
        });
    },
    async deleteWord({commit}, data){
        api().delete("/namesearch/dictionary/word?wordId="+data.id, data).
        then(response => {
            commit('setDeleteWordSuccess', response.data);
        }).catch(error => {
            commit("setDictionaryError", error.message)
        });
    },
};

const mutations = {
    setDictionaries: (state, response) =>{
        state.dictionaries = response;
    },
    setWords: (state, response) =>{
        state.words = response;
    },
    setAddWordSuccess: (state, response) =>{
        state.success = response;
    },
    setEditWordSuccess: (state, response) =>{
        state.success = response;
    },
    setDeleteWordSuccess: (state, response) =>{
        state.success = response;
    },
    setDictionaryError: (state, error) =>{
        state.error = error;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
