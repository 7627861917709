<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12" class="mt-2">
          <b-row>
            <b-col cols="3">
              <b-form-input
                class="text-gray text-sm noFocus ml-1"
                style="margin-top: 1px; padding:6px 5px;"
                v-model="search"
                type="text"
                placeholder="Search by Name or BRN"
                required
                v-on:keyup.enter="fetch">
              </b-form-input>
            </b-col>
            <b-col cols="3">
              <b-form-select
                class="form-select text-sm noFocus text-gray"
                v-model="subType"
                v-on:change="fetch">
                <b-select-option :value="null">--Filter by Type--</b-select-option>
                <b-select-option value="private-company-limited-by-shares">Private Limited by Shares</b-select-option>
                <b-select-option value="single-member-company">Single Member Company</b-select-option>
                <b-select-option value="private-company-limited-by-guarantee-with-shares">Private Limited by Guarantee with Share capital</b-select-option>
                <b-select-option value="private-company-limited-by-guarantee-without-shares">Private Limited by Guarantee without Share capital</b-select-option>
                <b-select-option value="public-company">Public Company</b-select-option>
                <b-select-option value="unlimited-company">Unlimited Company</b-select-option>
                <b-select-option value="foreign-company">Foreign Company</b-select-option>

              </b-form-select>
            </b-col>
            <b-col cols="2">
              <b-form-select
                class="form-select text-sm noFocus text-gray"
                v-model="status"
                v-on:change="fetch">
                <b-select-option :value="null">--Filter by Status--</b-select-option>
                <b-select-option value="Registered">Registered</b-select-option>
                <b-select-option value="Compliant">Compliant</b-select-option>
                <b-select-option value="Dormant">Dormant</b-select-option>
                <b-select-option value="Insolvent">Insolvent</b-select-option>
                <b-select-option value="In_Liquidation">In Liquidation</b-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="1"></b-col>
            <b-col cols="1">
              <b-form-select v-model="limit" class="w-full text-xs paginate-details mt-1" v-on:change="fetch">
                <b-select-option value="15">15</b-select-option>
                <b-select-option value="20">20</b-select-option>
                <b-select-option value="50">50</b-select-option>
                <b-select-option value="100">100</b-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="2">
              <b-row>
                <b-col class="mx-auto">
                  <p class="paginate-details text-center text-xs font-bold">
                    <span>{{((currentPage - 1) * limit) + 1}}</span> -
                    <span v-if="limit > beneficialOwners.length ">{{pagination.totalElements}}</span>
                    <span v-else>{{currentPage * limit}}</span>
                    of {{pagination.totalElements}}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="3">
              <b-form-input
                class="text-gray text-sm noFocus ml-1"
                style="margin-top: 1px; padding:6px 5px;"
                v-model="startDate"
                type="date"
                required
                v-on:change="fetch">
              </b-form-input>
            </b-col>
            <b-col cols="3">
              <b-form-input
                class="text-gray text-sm noFocus"
                style="margin-top: 1px; padding:6px 5px;"
                v-model="endDate"
                type="date"
                required
                v-on:change="fetch">
              </b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">#</th>
              <th class="t-header">BRN</th>
              <th class="t-header">Name</th>
              <th class="t-header">Sub Type</th>
              <th class="t-header text-center">Reg. Date</th>
              <th class="t-header text-center">Status</th>
              <th class="t-header text-center">Action</th>
            </tr>
            </thead>

            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(entity, idx) in beneficialOwners" :key="idx">
              <td class="t-body">{{(currentPage - 1) * limit + idx + 1}}</td>
              <td class="t-body text-blue font-bold" >
                <span class="hover:text-blue-dark cursor-pointer underline" @click="viewFile(entity)">{{entity.brn}}</span>
              </td>
              <td class="t-body font-bold">
                {{entity.name}}
              </td>
              <td class="t-body text-capitalize">
                {{cleanEntityType(entity.subType)}}
              </td>
              <td class="t-body text-center">
                {{dateFormat(entity.registrationDate)}}
              </td>
              <td class="t-body text-center">
                <span :class="getStatusStyling(entity.status)">{{entity.status}}</span>
              </td>
              <td class="text-center">
                <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs hover:bg-blue-dark" @click="viewFile(entity)">View</button>
              </td>
            </tr>
            <tr>
              <td v-if="beneficialOwners.length === 0" colspan="8" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            <tr>
              <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                <p class="text-sm">Failed to load tasks, Try again later.</p>
                <p class="text-sm">{{errorMessage}}</p>
                <p class="text-sm">
                  <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload <b-spinner v-if="loading" small></b-spinner></button>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{(currentPage)}}/{{pagination.totalPages}}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" >
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="w-full text-xs form-select border-0 paginate-details" v-on:change="fetch">
            <b-select-option value="15">15</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto">
              <p class="paginate-details text-center text-xs font-bold">
                <span>{{((currentPage - 1) * limit) + 1}}</span> -
                <span v-if="limit > beneficialOwners.length ">{{pagination.totalElements}}</span>
                <span v-else>{{currentPage * limit}}</span>
                of {{pagination.totalElements}}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "BusinessNameBeneficialOwnerRegistry",
  data(){
    return{
      loading:false,
      currentPage:1,
      limit:15,
      beneficialOwners:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
      search:"",
      startAmountSecured:null,
      endAmountSecured:null,
      startDate:null,
      endDate:null,
      status:null,
      hasError:false,
      errorMessage:null
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchBusinessNameBeneficialOwnerRegistry"]),
    fetch(){
      let data = {
        search:this.search,
        startDate:this.dateRangeFormat(this.startDate),
        endDate:this.dateRangeFormat(this.endDate),
        status:this.status,
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.hasError = false
      this.fetchBusinessNameBeneficialOwnerRegistry(data)
    },
    viewFile(charge){
      localStorage.setItem("bo-data", JSON.stringify(charge))
      router.push("/home/registry/beneficial-owners/file-viewer/"+charge.brn)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    getStatusStyling(status){
      if(status === "Registered" || status === "Amalgamated" || status === "Compliant"){
        return "text-green"
      }else if(status === "In_Liquidation"){
        return "text-green"
      }else{
        return "text-red"
      }
    },
    cleanString(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getBeneficialOwnersBusinessRegistry", "getBeneficialOwnerError"])
  },
  watch:{
    getBeneficialOwnersBusinessRegistry(data){
      this.loading = false
      this.beneficialOwners = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getBeneficialOwnerError(data){
      this.loading = false
      this.errorToast("Error", "Failed to load data "+ data.message)
    }
  }
};
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.verified{
  @apply text-xs font-bold;
  color: green;
  font-style: italic;
}

.unverified{
  @apply text-xs font-bold;
  color: #b80404;
  font-style: italic;
}

.assign-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.assign-btn:hover{
  @apply bg-green text-white;
}

.de-assign-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.de-assign-btn:hover{
  @apply bg-red text-white;
}

.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}
</style>