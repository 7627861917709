<template>
  <b-row>
    <b-col cols="12">
      <b-row class="py-1 px-2" v-for="(person, idx) in persons" :key="idx">
        <b-col class="border-b-2 border-grey">
          <b-row>
            <b-col cols="12 py-1">
              <b-row class="mt-1" v-if="person.entityType === 'CORPORATE'">
                <b-col>
                  <p class="text-sm">
                    {{ person.corporate.registeredName }}
                  </p>
                  <b-badge class="bg-blue">
                    Corporate
                  </b-badge>
                </b-col>
                <b-col>
                  <span class="text-sm">
                    {{ person.corporate.brn }}
                  </span>
                </b-col>
                <b-col>
                  <span class="text-sm">
                    {{ dateFormat(person.corporate.dateOfIncorporation) }}
                  </span>
                </b-col>
                <b-col cols="2">
                  <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs float-end" @click="showMore(idx)">
                    <span v-if="showDetails && selectedIdx === idx">
                      Show Less
                    </span>
                    <span v-else>
                      Show Details
                    </span>
                  </button>
                </b-col>
              </b-row>
              <b-row class="mt-1" v-if="person.entityType === 'INDIVIDUAL'">
                <b-col>
                  <p class="text-sm">
                    {{ person.individual.givenName }}
                    {{ person.individual.surName }}
                  </p>
                  <b-badge class="bg-orange">
                    Individual
                  </b-badge>
                </b-col>
                <b-col>
                  <span class="text-sm">
                    {{ person.individual.idNumber }}
                  </span>
                </b-col>
                <b-col>
                  <span class="text-sm">
                    {{ dateFormat(person.individual.dateOfBirth) }}
                  </span>
                </b-col>
                <b-col cols="2">
                  <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs float-end" @click="showMore(idx)">
                    <span v-if="showDetails && selectedIdx === idx">
                      Show Less
                    </span>
                    <span v-else>
                      Show Details
                    </span>
                  </button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="showDetails && selectedIdx === idx">
            <b-col cols="12" v-if="person.entityType === 'CORPORATE'">
              <b-row>
                <b-col cols="6" sm="6" md="6" lg="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Entity Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Registration Number (BRN)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.brn"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.primaryEmail"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.primaryPhone"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6" sm="6" md="6" lg="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Incorporation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.dateOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Company Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="cleanEntityType(person.corporate.subType)"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.countryOfOrigin"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Address in Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.addressInCountryOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6" sm="6" md="6" lg="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      rows="4"
                      disabled="true"
                      :value="person.corporate.registeredAddress.postalAddress"
                      required>
                    </b-form-input>
                    <PostalAddressValidator v-if="person.corporate.registeredAddress.postalAddress"
                                            :box-number="person.corporate.registeredAddress.postalAddress" />
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredAddress.plot"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      rows="4"
                      disabled="true"
                      :value="person.corporate.registeredAddress.plot"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredAddress.street"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      rows="4"
                      disabled="true"
                      :value="person.corporate.registeredAddress.street"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="person.entityType === 'INDIVIDUAL'">
              <b-row>
                <b-col cols="6" sm="6" md="4" lg="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Given Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.givenName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.middleName"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Middle Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.middleName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Surname"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.surName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.individual.idType.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.idNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Nationality"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.nationality"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6" sm="6" md="4" lg="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.email"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.phoneNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.sex"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Gender (at birth)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.individual.sex.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Birth"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="dateFormat(person.individual.dateOfBirth)"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Occupation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.occupation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6" sm="6" md="4" lg="4" v-if="person.individual.physicalAddress">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="District"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.district.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Region"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.region.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Sub county"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.subcounty.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Parish"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.parish.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Village"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.physicalAddress.village.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6" sm="6" md="4" lg="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.postalAddress"
                      required>
                    </b-form-input>
                    <PostalAddressValidator v-if="person.individual.postalAddress"
                                            :box-number="person.individual.postalAddress" />
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.street"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.plotNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import PostalAddressValidator from "@/views/home/commons/PostalAddressValidator";

export default {
  name: "LegalPersonsPartner",
  components: { PostalAddressValidator },
  props: {
    corporates: [],
    individuals: []
  },
  data() {
    return {
      persons: [],
      showDetails: false,
      selectedIdx: null
    };
  },
  mounted() {
    for (let i = 0; i < this.individuals.length; i++) {
      this.persons.push(
        {
          entityType: "INDIVIDUAL",
          individual: this.individuals[i]
        }
      );
    }

    for (let i = 0; i < this.corporates.length; i++) {
      this.persons.push(
        {
          entityType: "CORPORATE",
          corporate: this.corporates[i]
        }
      );
    }
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    cleanEntityType(name) {
      let n = name.replaceAll("-", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    showMore(idx) {
      if (this.selectedIdx === idx) {
        this.selectedIdx = null;
        this.showDetails = false;
      } else {
        this.selectedIdx = idx;
        this.showDetails = true;
      }
    }
  }
};
</script>

<style scoped>

</style>