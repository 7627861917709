<template>
  <b-col cols="8" class="mx-auto">
    <div class="w-full shadow-md py-5 border text-center" style="background-color: #ffffff;">
      <span class="h1 text-blue">OBRS Backoffice</span>
      <b-col cols="12" md="8" sm="11" class="mx-auto pt-3">
        <div style="text-align: center;" class="mt-3 mb-3">
          <b-avatar
              style="background: #1e8957; color: #ffffff; font-size: 35px"
              size="48"
          >
            <i class="fas fa-check iconTextSize"></i>
          </b-avatar>
        </div>
        <p class="text-center" style="font-size: 26px;">Account Password Changed!</p>
        <p class="text-center mb-5 mt-3" style="font-size: 15px;">
          You have successfully changed your OBRS Backoffice Account password.
          In case of any inquiries reach out to us over our support lines.
        </p>
        <!--            <div style="text-align: center;" class="mb-3"> <b-button style="width: 60%; height: 40px;" variant="outline-success">Success</b-button></div>-->
      </b-col>
      <div class="mt-3 max-w-2xl text-gray-500 lg:mx-auto"
           style="text-align: center; margin-top: 5%; color: #909090;">
        You can go back to
        <router-link
            style="color: #242f7e"
            class="text-decoration-none"
            :to="{ name: 'login' }">
          Login
        </router-link>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "ConfirmPasswordSuccess",
}
</script>

<style scoped>

</style>