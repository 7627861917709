import {api} from "@/app/services/api.service";

const state = {
    company_data_correction:null,
    data_correction_snapshots:null,
    data_correction_escalations:null,
    data_correction_escalation:null,
    data_correction_details:null,
    payment_removed:null,
    payment_removed_error:null,
    data_correction_details_error:null,
    data_correction_preview:null,
    data_correction_task_count:null,
    data_correction_task_thread:{},
    data_correction_registration_stats:{},
    data_corrections:null,
    data_correction_error:null,
    success:null,
};

const getters = {
    getDataCorrectionSubmissions: (state) => state.data_corrections,
    getDataCorrectionEscalations: (state) => state.data_correction_escalations,
    getDataCorrectionEscalation: (state) => state.data_correction_escalation,
    getDataCorrectionTaskCount: (state) => state.data_correction_task_count,
    getDataCorrectionTaskMessages: (state) => state.data_correction_task_thread,
    getDataCorrectionStatistics: (state) => state.data_correction_registration_stats,
    getDataCorrectionTask: (state) => state.company_data_correction,
    getDataCorrectionTaskError: (state) => state.data_correction_error,
    getDataUpdateStatusSuccess: (state) => state.data_correction_success,
    getAssignmentDataUpdateSuccess: (state) => state.data_correction_success,
    getCIEscalationDataUpdateSuccess: (state) => state.data_correction_success,
    getDataUpdateCompanyUpdateStatusError: (state) => state.data_correction_error,
    getDataCorrectionError: (state) => state.data_correction_error,
    getDataCorrectionDetails: (state) => state.data_correction_details,
    getDataCorrectionDetailsError: (state) => state.data_correction_details_error,
};

const actions = {
    async fetchDataCorrections({commit}, data){
        let path = "/data-correction/queue/tasks?status="+data.status+"&page="+data.page+"&perPage="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        api().get(path).
        then(response => {
            commit('setDataCorrections', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchAllDataCorrections({commit}, data){
        let path = "/data-correction/applications?status="+data.status+"&page="+data.page+"&pageSize="+data.limit
        if(data.search !== ""){
            path += "&search="+data.search
        }
        if(data.level !== null){
            path += "&level="+data.level
        }
        api().get(path).
        then(response => {
            commit('setDataCorrections', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchDataCorrectionEscalations({commit}, data){
        api().get("/data-correction/queue/my-escalations?status="+data.status+"&page="+data.page+"&perPage="+data.limit).
        then(response => {
            commit('setDataCorrectionEscalations', response.data);
        }).catch(error => {
            commit("setDataCorrectionError", error.response)
        });
    },
    async fetchDataCorrectionEscalation({commit}, data){
        api().get("/data-correction/escalations/details?escalationId="+data.id).
        then(response => {
            commit('setDataCorrectionEscalation', response.data);
        }).catch(error => {
            commit("setDataCorrectionError", error.response)
        });
    },
    async fetchDataCorrectionTask({commit}, data){
        api().get("/data-correction/queue/next?level="+data.level).
        then(response => {
            commit('setDataCorrection', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setDataCorrectionTaskError", error.response)
        });
    },

    async fetchDataCorrectionTaskCount({commit}){
        api().get("/data-correction/queue/count").
        then(response => {
            commit('setDataUpdateTaskCount', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchDataCorrectionStatistics({commit}, level){
        let path = "/data-correction/queue/basic-statistics"
        if(level != null){
            path += "?level="+level
        }
        api().get(path).
        then(response => {
            commit('setDataCorrectionStatistics', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchDataCorrectionTaskMessages({commit}, id){
        api().get("/data-correction/queue/task/"+id+"/thread").
        then(response => {
            commit('setDataCorrectionTaskMessages', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async updateDataCorrectionStatus({commit}, data){
        api().post("/data-correction/queue/task/"+data.id+"/escalate-level", data).
        then(response => {
            commit('setDataUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    }, async queryOrRejectDataCorrection({commit}, data){
        api().post("/data-correction/queue/task/"+data.id, data).
        then(response => {
            commit('setDataUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },  async requestDataCorrectionForms({commit}, data){
        api().post("/data-correction/queue/task/"+data.id+"/request-for-forms", data).
        then(response => {
            commit('setDataUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async approveDataCorrectionFinal({commit}, data){
        api().post("/data-correction/queue/task/"+data.applicationId+"/effect", data).
        then(response => {
            commit('setDataUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },   async clearanceDataCorrectionFinal({commit}, data){
        api().post("/data-correction/queue/task/"+data.applicationId+"/clear-for-effecting", data).
        then(response => {
            commit('setDataUpdateStatusSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    async fetchDataCorrectionDetails({commit}, trackingNumber){
        api().get("/data-correction/applications/"+trackingNumber).
        then(response => {
            commit('setDataCorrectionDetails', response.data);
        }).catch(error => {
            commit("setDataCorrectionDetailsError", error.response)
        });
    },
    async fetchAllCompanyEDMSDocuments({commit}, data){
        api().post("/integration/api/edms/company/files/list", data).
        then(response => {
            commit('setAllCompanyDocuments', response.data);
        }).catch(error => {
            console.log(JSON.stringify(error.response))
            commit("setDataCorrectionTaskError", error.response)
        });
    },
    assignDataCorrectionTask({commit}, data){
        api().post("/data-correction/queue/assign", data).
        then(response => {
            commit('setAssignmentDataUpdateSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },
    escalateDataCorrectionTask({commit}, data){
        api().post("/data-correction/queue/task/"+data.taskId+"/escalate", data).
        then(response => {
            commit('setCIDataUpdateEscalationSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },  deEscalateDataCorrectionTask({commit}, data){
        api().post("/data-correction/queue/task/"+data.id+"/de-escalate-level", data).
        then(response => {
            commit('setCIDataUpdateEscalationSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },  replyToEscalateDataCorrectionTask({commit}, data){
        api().post("/data-correction/queue/task/"+data.id+"/reply-to-escalation", data).
        then(response => {
            commit('setCIDataUpdateEscalationSuccess', response.data);
        }).catch(error => {
            commit("setDataUpdateError", error.response)
        });
    },

};

const mutations = {
    setDataCorrections: (state, response) =>{
        state.data_corrections = response;
    },
    setDataCorrectionEscalations: (state, response) =>{
        state.data_correction_escalations = response;
    },
    setDataCorrectionEscalation: (state, response) =>{
        state.data_correction_escalation = response;
    },
    setDataCorrectionError: (state, response) =>{
        state.data_correction_error = response;
    },
    setDataUpdateTaskCount: (state, response) =>{
        state.data_correction_task_count = response;
    },
    setDataCorrectionStatistics: (state, response) =>{
        state.data_correction_registration_stats = response;
    },
    setDataCorrectionTaskMessages: (state, response) =>{
        state.data_correction_task_thread = response;
    },
    setDataCorrection: (state, response) =>{
        state.company_data_correction = response.data;
    },
    setDataCorrectionTaskError: (state, response) =>{
        state.data_correction_error = response;
    },
    setAssignmentDataUpdateSuccess: (state, response) =>{
        state.data_correction_success = response;
    },
    setCIDataUpdateEscalationSuccess: (state, response) =>{
        state.data_correction_success = response;
    },
    setDataUpdateError: (state, response) =>{
        state.data_correction_error = response;
    },
    setDataUpdateStatusSuccess: (state, response) =>{
        state.data_correction_success = response;
    },
    setDataCorrectionDetails:(state, response) =>{
        state.data_correction_details = response.data
    },
    setDataCorrectionDetailsError:(state, response) =>{
        state.data_correction_details_error = response.data
    },
    setCompanyEDMSDocuments:(state, response) =>{
        state.company_edms_documents = response.data
    },
    setAllCompanyDocuments:(state, response) =>{
        state.all_company_edms_documents = response
    },
    setCompanyEDMSDocumentsError:(state, response) =>{
        state.company_edms_documents_error = response.data
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};