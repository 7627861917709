<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Statement of Intent of Conversion to General Partnership
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="8">
          <b-alert variant="success" show>
            <i class="fa fa-file-archive mr-2"></i>
            <span>
              The business name has decided to convert to a General Partnership with immediate effect.
              This though will cause a couple of major changes and below are key considerations. <br/>

              Note:<br/>
              If this is a sole proprietorship, ensure that a Change in Particulars has been filed and it updates the partners to more than 1.<br/>
              If this is a limited liability partnership the name of this business name will lose the prefix <b>LLP</b> that's at the end.
            </span>
          </b-alert>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";

export default {
  name: "StatementOfIntentOfConversionToPartnership",
  props:{
    data:Object,
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>

</style>