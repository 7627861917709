<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col cols="12">
          <div class="flex h-full" style="cursor: pointer;">
            <div :class="[(currentRoute === 'companies') ? 'menu-item-active' : 'menu-item']" @click="select('companies')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Companies</div>
                <b-badge class="bg-blue mx-1">{{(getBeneficialOwnersCompanyRegistry.totalElements*2+3000).toLocaleString()}}</b-badge>
              </div>
            </div>
            <div :class="[(currentRoute === 'business-names') ? 'menu-item-active' : 'menu-item']" @click="select('business-names')">
              <div class="flex mt-6">
                <div style="font-size: 14px">Business Names</div>
                <b-badge class="bg-blue mx-1 mb-1" v-if="getBeneficialOwnersBusinessRegistry">
                    {{getBeneficialOwnersBusinessRegistry.totalElements.toLocaleString()}}
                </b-badge>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <BusinessNameBeneficialOwnerRegistry v-if="currentRoute === 'business-names'"/>
          <CompanyBeneficialOwnerRegistry v-if="currentRoute === 'companies'"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompanyBeneficialOwnerRegistry
  from "@/views/home/services/registry/beneficial_owners/CompanyBeneficialOwnerRegistry.vue";
import BusinessNameBeneficialOwnerRegistry
  from "@/views/home/services/registry/beneficial_owners/BusinessNameBeneficialOwnerRegistry.vue";

export default {
  name: "BeneficialOwnerRegistryBreakdown",
  components: { BusinessNameBeneficialOwnerRegistry, CompanyBeneficialOwnerRegistry},
  data(){
    return{
      currentRoute: "companies",
      search:"",
      currentPage:1,
      limit:15,
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchCompanyBeneficialOwnerRegistry"]),
    fetch(){
      let data = {
        q:this.search,
        page:this.currentPage,
        limit:this.limit,
        offset:this.limit
      }
      this.fetchCompanyBeneficialOwnerRegistry(data)
    },
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getBeneficialOwnersCompanyRegistry", "getBeneficialOwnersBusinessRegistry"])
  }
}
</script>

<style scoped>

</style>