<template>
  <div>
      <b-row>
        <b-col cols="12" class="my-3">
          <b-row v-if="task != null">
            <b-col cols="6" class="my-1" v-for="(file, idx) in task.attachments" :key="idx">
              <span  class="mr-2 rounded-sm px-2 py-1 cursor-pointer bg-blue text-white text-capitalize hover:underline" @click="openFile(file)">
                  <i class="fa fa-file"></i>
                  {{cleanName(file.fileDescription)}}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <CompanyDataUpdateAttachments ref="attachmentScreen"/>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import moment from "moment";
import server from "@/app/env/server.json";
import CompanyDataUpdateAttachments
  from "@/views/home/services/data-update/task/company/CompanyDataUpdateAttachments.vue";

export default {
  name: "CompanyDataUpdateFormFiles",
  components: { CompanyDataUpdateAttachments },
  props:{
    task:Object
  },
  data(){
    return{
      attachmentUrl:null
    }
  },
  mounted() {
    this.openFile(this.task.attachments[0])
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    openFile(data){
      if(data){
        let fName = data ? data.fileName.trim() : ""
        this.attachmentUrl = server.IP+"/data-update/companies/download?file-name="+fName
      }
    },
    cleanName(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.toLowerCase()
      }

      return ""
    },
  },
  watch:{
    attachmentUrl:{
      handler(url){
        console.log(url)
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    }
  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>