import {api} from "@/app/services/api.service";

const state = {
  companyIncorporationStatusChanges: null,
  companyIncorporationStatusChangesError: null,
}

const getters = {
  getCompanyIncorporationStatusChanges: (state) => state.companyIncorporationStatusChanges,
  getCompanyIncorporationStatusChangesError: (state) => state.companyIncorporationStatusChangesError,
}

const actions = {
  fetchCompanyIncorporationStatusChanges({ commit }, queryParams) {
    api()
      .get(`/incorporation/companies/statistics/status-changes${queryParams}`)
      .then(response => {
        commit('setCompanyIncorporationStatusChanges', response.data);
      })
      .catch(error => {
        commit("setCompanyIncorporationStatusChangesError", error.response)
      });
  }
}

const mutations = {
  setCompanyIncorporationStatusChanges: (state, response) => {
      state.companyIncorporationStatusChanges = response;
  },
  setCompanyIncorporationStatusChangesError: (state, response) => {
      state.companyIncorporationStatusChangesError = response;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
};