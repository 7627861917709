<template>
  <b-row>
    <b-col class="mx-auto">
      <b-row>
        <b-col cols="12">
          <iframe class="pdf" :src="url"></iframe>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import server from "@/app/env/server.json";
export default {
  name: "RegisteredLegalDocument",
  props:{
    instrumentNumber:String
  },
  data(){
    return{
      url:""
    }
  },
  mounted() {
    this.url = server.IP + '/legal-documents/registry/attachment?instrumentNumber='+ this.instrumentNumber
  },
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 1000px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>