<template>
  <b-row>
    <b-col cols="12">
      <b-row class="mx-1 py-1" v-for="(person, idx) in persons" :key="idx">
        <b-col cols="12">
          <b-row  class="py-1 border-b border-gray">
            <b-col>
              <span class="text-xs font-bold text-capitalize">{{person.givenName}} {{person.middleName}} {{person.surName}}</span>
            </b-col>
            <b-col v-if="person.parentBrn">
              <span class="text-xs font-bold text-capitalize mr-2">Parent:</span>
              <span class="text-xs font-bold text-capitalize">{{person.parentBrn}}</span>
              <span class="text-xs font-bold text-capitalize">{{person.parentName}}</span>
            </b-col>
            <b-col>
              <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs float-end" @click="show(idx)">
                <span v-if="selectedIdx === idx">Hide Details</span>
                <span v-else>Show Details</span>
              </button>
            </b-col>
          </b-row>
          <b-row v-if="showDetails && selectedIdx === idx">
            <b-col cols="12">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Given Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.givenName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.middleName"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Middle Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.middleName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Surname"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.surName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.idType"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.idType.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.idNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Nationality"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.nationality"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.email"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.phoneNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.sex"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Gender (at birth)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.sex.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Birth"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="dateFormat(person.dateOfBirth)"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Occupation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.occupation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4" v-if="person.village">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="District"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.village.district.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Region"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.village.region.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Sub county"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.village.subcounty.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Parish"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.village.parish.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Village"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.village.village.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.postalAddress"
                      required>
                    </b-form-input>
                    <PostalAddressValidator v-if="person.postalAddress" :box-number="person.postalAddress"/>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.street"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.plot"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    v-if="person.parentBrn"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Parent Brn"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="person.parentBrn"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Percentage Shares"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="person.percentageShares"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Percentage Voting Rights"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="person.percentageVotingRights"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Has Voting Rights"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="person.hasVotingRights"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Has Right To Appoint"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="person.hasRightToAppoint"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
      <b-row class="mx-1 py-1" v-for="(entity, idx) in entityPersons" :key="idx">
        <b-col>
          <b-row>
            <b-col>
              <span class="text-xs font-bold text-capitalize">{{entity.companyName}}</span>
            </b-col>
            <b-col>
              <span class="text-xs font-bold text-capitalize">{{entity.brn}}</span>
            </b-col>
            <b-col>
              <span class="text-xs font-bold text-capitalize">{{cleanEntityType(entity.subType)}}</span>
            </b-col>
            <b-col>
              <span class="text-xs font-bold text-blue text-capitalize">%age Shares: {{entity.percentageShares}}</span>
            </b-col>
          </b-row>
          <b-row class="mx-1 py-1"
                 v-for="(person, idx2) in entity.entityIndividualBeneficialOwners" :key="idx2">
            <b-col cols="12">
              <b-row  class="py-1 border-b border-gray">
                <b-col>
                  <span class="text-xs font-bold text-capitalize">> {{person.givenName}} {{person.middleName}} {{person.surName}}</span>
                </b-col>
                <b-col v-if="person.parentBrn">
                  <span class="text-xs font-bold text-capitalize mr-2">Parent:</span>
                  <span class="text-xs font-bold text-capitalize">{{person.parentBrn}}</span>
                  <span class="text-xs font-bold text-capitalize">{{person.parentName}}</span>
                </b-col>
                <b-col>
                  <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs float-end" @click="show(idx2)">
                    <span v-if="selectedIdx === idx">Hide Details</span>
                    <span v-else>Show Details</span>
                  </button>
                </b-col>
              </b-row>
              <b-row v-if="showDetails && selectedIdx === idx2">
                <b-col cols="12">
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Given Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.givenName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="person.middleName"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Middle Name"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.middleName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Surname"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.surName"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="person.idType"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="ID Type"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="person.idType.toLowerCase()"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="ID Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.idNumber"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Nationality"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.nationality"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Email Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.email"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Phone Number"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.phoneNumber"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        v-if="person.sex"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Gender (at birth)"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus text-capitalize"
                          type="text"
                          disabled="true"
                          :value="person.sex.toLowerCase()"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Date of Birth"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="dateFormat(person.dateOfBirth)"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Occupation"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.occupation"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4" v-if="person.village">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="District"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.village.district.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Region"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.village.region.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Sub county"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.village.subcounty.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Parish"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.village.parish.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Village"
                        label-for="input-1">
                        <b-form-input
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.village.village.name"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Postal Address"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.postalAddress"
                          required>
                        </b-form-input>
                        <PostalAddressValidator v-if="person.postalAddress" :box-number="person.postalAddress"/>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Street"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.street"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Plot"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled="true"
                          :value="person.plot"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                        v-if="person.parentBrn"
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Parent Brn"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled
                          :value="person.parentBrn"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Percentage Shares"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled
                          :value="person.percentageShares"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Percentage Voting Rights"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled
                          :value="person.percentageVotingRights"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Has Voting Rights"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled
                          :value="person.hasVotingRights"
                          required>
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="text-sm my-1"
                        id="input-group-1"
                        label="Has Right To Appoint"
                        label-for="input-1">
                        <b-form-input
                          id="companyName"
                          class="form-text text-sm noFocus"
                          type="text"
                          disabled
                          :value="person.hasRightToAppoint"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import PostalAddressValidator from "@/views/home/commons/PostalAddressValidator";

export default {
  name: "BeneficialOwners",
  components: { PostalAddressValidator },
  props:{
    persons:[],
    entityPersons:[]
  },
  data(){
    return{
      showDetails:false,
      selectedIdx:null
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD/MM/YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    show(idx){
      if(this.selectedIdx === idx){
        this.selectedIdx = null
        this.showDetails = false
      }else{
        this.selectedIdx = idx
        this.showDetails = true
      }
    }
  }
}
</script>

<style scoped>

</style>