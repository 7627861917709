 <template>
  <b-row>
      <b-col>
        <b-row class="mx-1 my-2">
          <b-overlay rounded="sm" :show="loading">
            <b-col cols="12" >
              <b-row v-for="(message, idx) in messages" :key="idx">
                <b-col cols="6" class="py-2 rounded-md bg-header_grey my-2 text-gray">
                  <b-row>
                    <b-col>
                      <span class="text-sm font-bold text-capitalize">{{message.messageData.sender.toLowerCase()}} <b-badge class="bg-green text-capitalize">{{message.messageData.senderRole.toLowerCase()}}</b-badge></span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span class="text-sm">{{message.messageData.comment}}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <span style="font-size:9px">{{dateFormat(message.messageData.createdAt)}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-if="messages.length == 0">
                <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
                  <b-row>
                    <b-col>
                      <span class="text-xs font-bold">No messages yet</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-overlay>
        </b-row>
      </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "CompanyMessagesEscalated",
  props:{
    application:Object
  },
  data(){
    return{
      loading:true,
      messages:[]
    }
  },
  mounted(){
    let id = this.application.taskId
    this.fetchIncorporationTaskMessages(id)
  },
  methods:{
    ...mapActions(["fetchIncorporationTaskMessages"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    }
  },
  computed:{
    ...mapGetters(["getIncorporationTaskMessages", "getRegistrationError", "getIncorporationTask"])
  },
  watch:{
    getIncorporationTaskMessages(data){
      this.loading = false
      this.messages = data
    }
  }
}
</script>

<style scoped>

</style>