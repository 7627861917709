<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
          <Assignment :id="taskId" type="bn-data-update" permission="can_access_business_names_data_update"/>
        </b-modal>
      </b-row>

      <b-row>
        <b-col cols="3">
          <b-form-input
            class="text-gray text-sm noFocus my-2"
            style="padding:6px 5px;"
            v-model="search"
            type="text"
            placeholder="Search by Tracking No."
            required
            v-on:keyup.enter="fetch">
          </b-form-input>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-form-select
              v-model="statuses"
              v-on:change="fetch"
              class="form-select noFocus text-gray my-2 text-sm">
              <b-select-option :value="null">--Filter by Status--</b-select-option>
              <b-select-option value="DRAFT">Draft</b-select-option>
              <b-select-option value="SUBMITTED">In Queue</b-select-option>
              <b-select-option value="ASSIGNED">Assigned</b-select-option>
              <b-select-option value="QUERIED">Queried</b-select-option>
              <b-select-option value="APPROVED">Approved</b-select-option>
              <b-select-option value="ESCALATED">Escalated</b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-select v-model="level" class="form-select noFocus text-gray text-sm my-2"
                         v-on:change="fetch">
            <b-select-option :value="null">--Filter by Level--</b-select-option>
            <b-select-option value="1">1</b-select-option>
            <b-select-option value="2">2</b-select-option>
            <b-select-option value="3">3</b-select-option>
            <b-select-option value="4">4</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2"></b-col>
        <b-col cols="1" class="text-center">
          <b-form-select v-model="limit" class="w-full text-xs form-select mt-2 paginate-details" v-on:change="fetch">
            <b-select-option value="12">12</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto mt-3">
              <p class="paginate-details text-center text-xs font-bold">
                <span>{{((currentPage - 1) * limit) + 1}}</span> -
                <span v-if="limit > submissions.length ">{{pagination.totalElements}}</span>
                <span v-else>{{currentPage * limit}}</span>
                of {{pagination.totalElements}}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">
          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">#</th>
              <th class="t-header">Tracking No.</th>
              <th class="t-header">BRN</th>
              <th class="t-header">Name</th>
              <th class="t-header">Sub Entity Type</th>
              <th class="t-header text-center">Level</th>
              <th class="t-header text-center">Status</th>
              <th class="t-header text-center">Assignment</th>
              <th class="t-header text-center">Action</th>
              <th class="t-header text-center">Date</th>
            </tr>
            </thead>
            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
              <td class="t-body">{{(currentPage - 1) * limit + idx + 1}}</td>
              <td class="t-body text-blue font-bold cursor-pointer hover:underline" @click="showDetails(submission.trackingNumber)">{{submission.trackingNumber}}</td>
              <td class="t-body font-bold">{{submission.applicationResponse.brn}}</td>
              <td class="t-body font-bold">{{submission.applicationResponse.name}}</td>
              <td class="t-body text-capitalize">{{cleanEntityType(submission.subType)}}</td>
              <td class="t-body text-center">Level {{submission.applicationResponse.applicationLevel}}</td>
              <td class="t-body">
                <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                  <span :class="
                  (submission.status.toUpperCase() === 'DRAFT') ? 'text-gray-light' :
                  (submission.status.toUpperCase() === 'SUBMITTED') ? 'text-gray' :
                  (submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION')? 'text-gray':
                  (submission.status.toUpperCase() === 'PAYMENT_FAILED')? 'text-red':
                  (submission.status.toUpperCase() === 'ASSIGNED')? 'text-blue':
                  (submission.status.toUpperCase() === 'APPROVED') ? 'text-green' :
                  (submission.status.toUpperCase() === 'QUERIED') ? 'text-orange' : ''">
                    <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT_CONFIRMATION'">
                      Confirming Payment
                    </span>
                    <span v-else-if="submission.status.toUpperCase() === 'SUBMITTED'">
                      In Queue
                    </span>
                    <span v-else>
                      {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                    </span>
                  </span>
                </p>
              </td>
              <td class="t-body  text-center">
                <p class="text-xs px-2 py-1 text-capitalize text-center">
                  <span v-if="submission.applicationResponse.currentRegistrarName">
                     {{submission.applicationResponse.currentRegistrarName.toLowerCase()}}
                  </span>
                  <span v-else>
                    Not assigned
                  </span>
                </p>
              </td>
              <td class="t-body  text-center">
                <button
                  v-if="submission.status.toUpperCase() === 'ESCALATED' && canViewActionWithPermission('can_assign_business_data_update_application')"
                  class="bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'ASSIGNED' && canViewActionWithPermission('can_assign_business_data_update_application')"
                  class="bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-redo-alt mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'SUBMITTED' && canViewActionWithPermission('can_assign_business_data_update_application')"
                  class="bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
              </td>
              <td class="t-body  text-center">{{dateTimeFormat(submission.createdAt)}}</td>
            </tr>
            <tr>
              <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            <tr>
              <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                <p class="text-sm">Failed to load tasks, Try again later.</p>
                <p class="text-sm">{{errorMessage}}</p>
                <p class="text-sm">
                  <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload <b-spinner v-if="loading" small></b-spinner></button>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{currentPage}}/{{pagination.totalPages}}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1" >
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details" v-on:change="fetch">
            <b-select-option value="12">12</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto">
              <p class="paginate-details text-center text-xs font-bold">
                <span>{{((currentPage - 1) * limit) + 1}}</span> -
                <span v-if="limit > submissions.length">{{pagination.totalElements}}</span>
                <span v-else>{{currentPage * limit}}</span>
                of {{pagination.totalElements}}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Assignment from "@/views/home/commons/Assignment.vue";
import moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AllBusinessNameDataUpdateSubmissions",
  components: { Assignment },
  data(){
    return{
      loading:false,
      currentPage:1,
      limit:12,
      submissions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      statuses:null,
      taskId:null,
      level:null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
      search:"",
      hasError:false,
      errorMessage:null,
      application:{},
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchBusinessNameDataUpdates", "fetchBNDataUpdateStatistics"]),
    fetch(){
      let options = this.statuses
      if(this.statuses === null){
        options = "DRAFT,SUBMITTED,ASSIGNED,QUERIED,APPROVED,ESCALATED"
      }
      if(this.search !== ""){
        this.currentPage = 1
      }
      let data = {
        search: this.search,
        statuses: options,
        level:this.level,
        page:this.currentPage,
        limit:this.limit
      }
      this.loading = true
      this.fetchBusinessNameDataUpdates(data)

      if(this.level){
        this.fetchBNDataUpdateStatistics(this.level)
      }{
        this.fetchBNDataUpdateStatistics()
      }
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    assignPrompt(data){
      this.taskId =  data.applicationResponse.id;
      this.$refs.assignSubmissions.show();
    },
    showDetails(trackingNumber){
      if(trackingNumber.startsWith("800")){
        this.warningToast("Unauthorized Action", "Go to Registry to see approved Entity")
      }else{
        this.$emit("openDetails", trackingNumber)
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true
    }
  },
  computed:{
    ...mapGetters(["getBNDataUpdateSubmissions", "getBNDataUpdateTaskError", "getRegistrars", "getAssignmentSuccess", "getAssignmentError",
      "getDeAssignmentSuccess", "getDeAssignmentSuccess", "getReservationError", "getBusinessDetailsError", "getMoveTask", "getMoveTaskError"])
  },
  watch:{
    getBNDataUpdateSubmissions(data){
      this.loading = false
      this.submissions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getBNDataUpdateTaskError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
};
</script>

<style scoped>

.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

</style>