<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Call On Shares
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">

        <b-col cols="10" class="mx-auto">
          <table class="min-w-full leading-normal text-gray"
                 style="border: solid 1px #dedede;" v-for="(notice, idx) in data.notices" :key="idx">
            <thead>
              <tr class="bg-gray-pale">
                <td class="px-2 py-1 text-uppercase font-bold" colspan="12">
                  <span>{{notice.shareholding.shareholder.name}}</span>
                </td>
              </tr>
            </thead>
            <thead>
              <tr>
                <td class="px-2 py-1 text-capitalize font-bold">
                  <span>Start Date</span>
                </td>
                <td class="px-2 py-1 text-uppercase">
                  <b-form-input class="form-text" :disabled="true" :value="notice.startDate"></b-form-input>
                </td>
              </tr>
              <tr>
                <td class="px-2 py-1 text-capitalize font-bold">
                  <span>Time limit</span>
                </td>
                <td class="px-2 py-1 text-uppercase">
                  <b-form-input class="form-text" :disabled="true" :value="notice.timeLimit"></b-form-input>
                </td>
              </tr>
              <tr>
                <td class="px-2 py-1 text-capitalize font-bold">
                  <span>Time Measure</span>
                </td>
                <td class="px-2 py-1 text-uppercase">
                  <b-form-input class="form-text" :disabled="true" :value="notice.timeLimitUnit"></b-form-input>
                </td>
              </tr>
              <tr>
                <td class="px-2 py-1 text-capitalize font-bold">
                  <span>Reason</span>
                </td>
                <td class="px-2 py-1 text-uppercase">
                  <b-form-input class="form-text" :disabled="true" :value="notice.reason"></b-form-input>
                </td>
              </tr>
            </thead>
            <thead>
              <tr>
                <th class="px-2 py-1">Share Class</th>
                <th class="px-2 py-1 text-center">Shares</th>
                <th class="px-2 py-1 text-center">Value</th>
                <th class="px-2 py-1 text-center">Total Nominal Value</th>
              </tr>
            </thead>
            <tbody v-for="(share, idx) in notice.shareholding.shares" :key="idx">
            <tr style="border: solid 1px #dedede;">
              <td class="px-2 py-1 text-uppercase font-bold">
                <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                <span v-else>{{share.otherClassName}}</span>
              </td>
              <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.numberOfShares.toLocaleString()}}
                                </span>
              </td>
              <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountPaid.toLocaleString()}}
                                </span>
              </td>
              <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountUnPaid.toLocaleString()}}
                                </span>
              </td>
            </tr>
            <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
              <td class="px-2 py-1 text-capitalize">
                <span>Class {{subClass.nominalClass}}</span>
              </td>
              <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.numberOfShares.toLocaleString()}}
                                </span>
              </td>
              <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountPaid.toLocaleString()}}
                                </span>
              </td>
              <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountUnPaid.toLocaleString()}}
                                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CallOnShares",
  props:{
    data:Object
  },
};
</script>

<style scoped>

</style>