<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
            <b-row>
              <b-col>
                <p class="text-sm">
                  <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
                  This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
                </p>
                <p class="mt-3 text-sm italic">
                  <b>Note:</b><br/>
                  All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
                </p>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="4">
                <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
                  <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
              </b-col>
              <b-col cols="4"></b-col>
              <b-col cols="4">
                <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
                  <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
              </b-col>
            </b-row>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row v-if="loading">
            <b-col class="mx-auto">
              <b-row class="py-5">
                <b-col class="text-gray text-center">
                  <span v-if="searchStatus === 'PENDING' || searchStatus === 'APPROVED'">Generating the File....</span>
                  <b-spinner v-if="loading" small></b-spinner>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col class="mx-auto">
              <b-row v-if="searchData !== null">
                <b-col>
                  <b-row>
                    <b-col cols="6"></b-col>
                    <b-col cols="6">
                      <div class="flex float-end">
                        <button type="button" class="btn-approve" @click="onGenerate">
                          <i class="fa fa-file text-white mr-1"></i> Generate New Report
                          <b-spinner small v-if="generating"></b-spinner>
                        </button>
                        <button type="button" class="btn-approve" @click="onApprove">
                          <i class="fa fa-check-circle text-white mr-1"></i> Approve
                          <b-spinner small v-if="approving"></b-spinner>
                        </button>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <div class="flex h-full" style="cursor: pointer;">
                            <div :class="[(currentRoute === 'report-table') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('report-table')">
                              <div class="flex mt-6">
                                <div style="font-size: 12px">Details</div>
                              </div>
                            </div>
                            <div :class="[(currentRoute === 'report-document') ? 'menu-item-active' : 'menu-item']"
                                 @click="select('report-document')">
                              <div class="flex mt-6">
                                <div style="font-size: 12px">Preview</div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" v-if="currentRoute === 'report-table'">
                          <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                              <td colspan="12" class="px-2 py-2 font-bold">Search Results</td>
                            </tr>
                            </thead>
                            <tbody>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Entity Name</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" disabled :value="searchData.name" ></b-form-input>
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Registration Number</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" disabled :value="searchData.brn" ></b-form-input>
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Entity Type</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" v-model="generateForm.entityType" ></b-form-input>
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Entity Sub Type</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" v-model="generateForm.entitySubType" ></b-form-input>
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Date of Registration</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" v-model="generateForm.dateOfIncorporation" ></b-form-input>
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">TIN</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input type="number" class="text-xs" v-model="generateForm.tin" ></b-form-input>
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Address</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" v-model="generateForm.entityAddress" ></b-form-input>
                                </td>
                              </tr>
                              <tr style="border: solid 1px #dedede;">
                                <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Annual Returns</td>
                                <td colspan="6"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" v-model="generateForm.annualReturns" ></b-form-input>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                              <td colspan="4" class="px-2 py-2 font-bold">Directors</td>
                              <td colspan="4" class="px-2 py-2 font-bold"></td>
                              <td colspan="4" class="px-2 py-2 font-bold float-end">
                                <button class="bg-gray px-2 py-1 rounded-sm hover:bg-gray-dark" @click="addDirector"> <i class="fa fa-plus text-white mx-1"></i></button>
                              </td>
                            </tr>
                            </thead>
                            <tbody>
                              <tr style="border: solid 1px #dedede;" v-for="(person, idx) in generateForm.directors" :key="idx">
                                <td colspan="4"  class="px-2 py-1 text-capitalize font-bold">Director {{idx + 1}}</td>
                                <td colspan="4"  class="px-2 py-1 text-capitalize">
                                  <b-form-input class="text-xs" v-model="person.name" ></b-form-input>
                                </td>
                                <td colspan="4" class="float-end">
                                  <button class="bg-red m-1 px-2 py-1 rounded-sm hover:bg-red-dark" @click="removeDirector(idx)">
                                    <i class="fa fa-times text-white mx-1"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                              <td colspan="12" class="px-2 py-2 font-bold">Secretary</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="border: solid 1px #dedede;" v-for="(person, idx) in generateForm.secretary" :key="idx">
                              <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Secretary</td>
                              <td colspan="6"  class="px-2 py-1 text-capitalize">
                                <b-form-input class="text-xs" v-model="person.name" ></b-form-input>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                              <td colspan="3" class="px-2 py-2 font-bold">Share Holders</td>
                              <td colspan="6" class="px-2 py-2 font-bold"></td>
                              <td colspan="3" class="px-2 py-2 font-bold float-end">
                                <button class="bg-gray px-2 py-1 rounded-sm hover:bg-gray-dark" @click="addShareholder">
                                  <i class="fa fa-plus text-white mx-1"></i>
                                </button>
                              </td>
                            </tr>
                            </thead>
                            <thead>
                            <tr>
                              <td colspan="3" class="px-2 py-2 font-bold">#</td>
                              <td colspan="3" class="px-2 py-2 font-bold">Name</td>
                              <td colspan="3" class="px-2 py-2 font-bold">Number of Shares</td>
                              <td colspan="3" class="px-2 py-2 font-bold"></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="border: solid 1px #dedede;" v-for="(person, idx) in generateForm.shareholders" :key="idx">
                              <td colspan="3"  class="px-2 py-1 text-capitalize font-bold">Shareholder {{idx + 1}}</td>
                              <td colspan="3"  class="px-2 py-1 text-capitalize">
                                <b-form-input class="text-xs" v-model="person.name" ></b-form-input>
                              </td>
                              <td colspan="3"  class="px-2 py-1 text-capitalize">
                                <b-form-input type="number"  class="text-xs" v-model="person.shares" ></b-form-input>
                              </td>
                              <td colspan="3" class="float-end">
                                <button class="bg-red m-1 px-2 py-1 rounded-sm hover:bg-red-dark" @click="removeShareholder(idx)">
                                  <i class="fa fa-times text-white mx-1"></i>
                                </button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                              <td colspan="12" class="px-2 py-2 font-bold">Shares</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="border: solid 1px #dedede;">
                              <td colspan="6" class="px-2 py-1 text-capitalize font-bold">Share Capital</td>
                              <td colspan="6"  class="px-2 py-1 text-capitalize">
                                <b-form-input type="number" v-model="generateForm.shares.share_capital" class="text-xs"></b-form-input>
                              </td>
                            </tr>
                            <tr style="border: solid 1px #dedede;">
                              <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">Number of Shares</td>
                              <td colspan="6"  class="px-2 py-1 text-capitalize">
                                <b-form-input type="number" v-model="generateForm.shares.share_number" class="text-xs" :value="searchData.shares.noOfShares.toLocaleString()" ></b-form-input>
                              </td>
                            </tr>
                            <tr style="border: solid 1px #dedede;">
                              <td colspan="6"  class="px-2 py-1 text-capitalize font-bold">amountPaidPerShare</td>
                              <td colspan="6"  class="px-2 py-1 text-capitalize">
                                <b-form-input type="number" v-model="generateForm.shares.share_cost" class="text-xs" :value="searchData.shares.amountPaidPerShare ? searchData.shares.amountPaidPerShare.toLocaleString() :'' " ></b-form-input>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </b-col>
                        <b-col cols="12" v-if="currentRoute === 'report-document'">
                          <iframe class="pdf my-2" v-if="getGeneratedReport.data"
                                  :src="'data:application/pdf;base64,'+getGeneratedReport.data+'#toolbar=0&navpanes=0&scrollbar=0'">
                          </iframe>
                          <p v-else class="bg-gray text-white p-5 text-center">
                            <label class="font-bold">Generate search report document</label>
                          </p>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "SearchReportDetails",
  props: {
    reference:String,
    queueTaskId:String
  },
  data() {
    return {
      searchedDocument: null,
      currentRoute:"report-table",
      userProfile: {},
      loading:false,
      statusMessage: null,
      searchStatus: null,
      searchRequestId: null,
      generating:false,
      approving: false,
      searchData: null,
      status:null,
      action:null,
      generateForm:{
        searchRecordId : null,
        entity_type : null,
        entity_sub_type : null,
        date_of_incorporation : null,
        tin:null,
        entity_address : null,
        annual_returns : null,
        shares:{
          share_capital: null,
          share_number : null,
          share_cost : null
        },
        shareholders: [],
        directors: [],
        members: [],
        secretary: []
      }
    };
  },
  mounted() {
    this.userProfile = JSON.parse(sessionStorage.getItem("current_user"));
    if (this.reference != null) {
      this.loading = true;
      this.fetchRecordSearchStatus(this.reference);
    }
  },
  methods: {
    ...mapActions([
      "fetchRecordSearchDetails", "fetchRecordSearchStatus", "updateSearchReportStatus", "generateSearchReportRequest"]),
    select(route){
      this.currentRoute = route
    },
    prepareGenerateForm(){
      this.generateForm.searchRecordId = this.searchData.searchRecordId
      this.generateForm.entityType = this.searchData.type
      this.generateForm.entitySubType = this.searchData.subType
      this.generateForm.dateOfIncorporation = this.searchData.dateOfRegistration
      this.generateForm.entityAddress = this.searchData.address
      this.generateForm.annualReturns = this.searchData.annualReturns
      this.generateForm.tin = this.searchData.tin
      //DIRECTORS
      for(let j = 0;  j < this.searchData.directors.length; j++){
        let director = this.searchData.directors[j]
        this.generateForm.directors.push(
          {
            name: director.actualName
          }
        )
      }
      //SECRETARY
      this.generateForm.secretary = this.searchData.secretary
      if(this.searchData.secretary.length === 0){
        this.generateForm.secretary.push({name:null})
      }

      //SHAREHOLDERS
      for(let i = 0;  i< this.searchData.shareholders.length; i++){
        let shareholder = this.searchData.shareholders[i]
        this.generateForm.shareholders.push(
          {
            name: shareholder.actualName,
            shares: shareholder.noOfShares
          }
        )
      }
      //SHARES
      this.generateForm.shares.share_capital = this.searchData.shares.shareCapital
      this.generateForm.shares.share_number = this.searchData.shares.noOfShares
      this.generateForm.shares.share_cost = this.searchData.shares.amountPaidPerShare
    },
    cleanEntityType(name) {
      if (name === undefined) {
        return null;
      }
      let n = name.replaceAll("-", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    cleanString(name) {
      let n = name.replaceAll("_", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    onGenerate() {
      this.generating = true
      this.generateSearchReportRequest(this.generateForm)
    },
    approve() {
      this.approving = true
      let file = this.getRecordSearchResults.data.reportFile
      if(this.getGeneratedReport){
        file = this.getGeneratedReport.data
      }
      let payload = new FormData
      payload.set("queueTaskId", this.queueTaskId)
      payload.set("searchRecordId", this.generateForm.searchRecordId)
      payload.set("userId", this.userProfile.id)
      payload.set("status", this.status)
      payload.set("description", "")
      payload.set("searchReport", this.dataURLtoFile(file, this.searchData.searchRef + "_SEARCH_REPORT.pdf"))
      this.updateSearchReportStatus(payload)
    },
    addDirector(){
      this.generateForm.directors.push({ name:null})
    },
    addShareholder(){
      this.generateForm.shareholders.push(
        { name:null, noOfShares:null,
        }
      )
    },
    removeDirector(idx){
      this.generateForm.directors.splice(idx, 1)
    },
    removeShareholder(idx){
      this.generateForm.shareholders.splice(idx, 1)
    },
    onApprove(){
      this.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.approve()
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
  },
  computed: {
    ...mapGetters(
        ["getRecordSearchResults", "getRecordSearchSuccess", "getRecordSearchError", "getRecordSearchUpdate",
          "getRecordSearchUpdateError", "getRecordSearchResultsError", "getRecordSearchStatus",
          "getRecordSearchStatusError", "getGeneratedReport", "getGenerateReportError"]
    ),
  },
  watch: {
    getEDMSFile() {
      this.loading = false;
      this.loadingDocument = false;
    },
    getGeneratedReport(){
      this.generating = false
      this.successToast("Success", "File generated")
      this.select("report-document")
    },
    getGenerateReportError(data){
      this.generating = false
      this.errorToast("Error", data.message)
    },
    getRecordSearchUpdate(response) {
      this.successToast("Success", response.data.message)
      this.uploading = false;
      this.approving = false;
      window.location.reload()
    },
    getRecordSearchUpdateError(data) {
      this.uploading = false;
      this.approving = false;
      this.errorToast("Download Error", data.message);
    },
    getRecordSearchError(data) {
      this.loading = false;
      this.loadingDocument = false;
      this.statusMessage = null;
      this.errorToast("Search Error", "Failed to create search request. " + data.message);
    },
    getRecordSearchResults(data) {
      this.loading = false;
      this.searchData = data.data;
      this.prepareGenerateForm()
      this.statusMessage = null;
    },
    getRecordSearchResultsError(data) {
      if (data.status === 400) {
        this.statusMessage = "Please perform payment for the Search Request you have made. Search Reference: " + this.reference + ".";
        this.loading = true;
        this.fetchRecordSearchDetails(this.reference);
      } else {
        this.loading = false;
        this.statusMessage = "Failed to get search results. " + data.message;
      }
    },
    getRecordSearchStatus(data) {
      this.searchStatus = data.data.status;
      if (data.data.status === "AWAITING_PAYMENT_CONFIRMATION") {
        this.statusMessage = "Waiting for Search Request payment for reference " + this.reference + " to be processed. Check your phone for a USSD Prompt to enter a PIN. Please do not close your browser or turn off your internet.";
        router.replace({ name: "record-search", query: { ref: this.reference, name: this.searchName } });
        this.fetchRecordSearchStatus(this.reference);
      }
      if (data.data.status === "AWAITING_SEARCH_RESULTS") {
        this.loading = true;
        this.statusMessage = "Waiting for Search Results for to be compiled. Please do not close your browser or turn off your internet.";
        this.fetchRecordSearchDetails(this.reference);
      }
      if (data.data.status === "APPROVED") {
        this.loading = true;
        this.statusMessage = "Waiting for Search Results for to be compiled. Please do not close your browser or turn off your internet.";
        this.fetchRecordSearchDetails(this.reference);
      }
    },
    getRecordSearchStatusError() {
      this.fetchRecordSearchStatus(this.reference);
    },
    getRecordSearchPayment() {
      this.certifying = false;
      this.fetchRecordSearchStatus(this.reference);
    },
    getRecordSearchPaymentError(data) {
      this.errorToast("Payment Error!", data.message);
    },
  }
};
</script>

<style scoped>

th, td {
  font-size: 12px;
}

.icon{
  width: 170px;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.pdf {
  width: 100%;
  height: 1000px;
  overflow-x: auto;
  border: 1px solid #b1b1b1;
}

.search-button {
  @apply border-0 removeOnCLickBorder px-5;
  height: 43px;
  color: #ffffff;
  background: #157ec2;
  border-radius: 0 25px 25px 0;
}

.search-button:hover {
  background: #0467a8;
}

.button {
  @apply text-center rounded-sm bg-blue text-xs text-white border border-blue px-2 py-1;
  font-size: 14px;
}

.button:hover {
  @apply bg-blue-dark;
}

.rounded-search {
  border-radius: 25px 0 0 25px;
}

.search-input {
  border-radius: 0 0 0 0;
  height: 43px;
}

.removeOnCLickBorder:focus {
  outline: none 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.removeOnCLickBorder {
  box-shadow: none !important
}


i {
  height: 10px;
  margin-top: 4px;
}

@media only screen and (max-width: 880px) {
  .search-button {
    @apply px-2;
    height: 33px;
    font-size: 11px;
    border-radius: 0 25px 25px 0;
  }

  .search-input {
    height: 33px;
    font-size: 12px;
  }

  i {
    height: 10px;
    margin-top: 0;
  }

}

@media only screen and (max-width: 440px) {
  .search-button {
    font-size: 10px;
  }
}
</style>