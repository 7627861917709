<template>
  <b-row>
    <b-col>
      <b-overlay rounded="sm" :show="!loading">
        <b-col>
          <b-row>
            <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{application.name}}
            </span>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2">Details</td>
                </tr>
                <tr>
                  <th class="px-2 py-1">Tracking Number</th>
                  <th class="px-2 py-1">Registration Number</th>
                  <th class="px-2 py-1">Status</th>
                  <th class="px-2 py-1">Sub Entity Type</th>
                  <th class="px-2 py-1">Submission Date</th>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1">
                    <span class="text-gray">
                      {{application.trackingNo}}
                    </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray">
                      {{application.brn}}
                    </span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{application.status.toLowerCase().replaceAll("_", " ")}}</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-gray text-capitalize">{{cleanEntityType(application.subType)}}</span>
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.submissionDate)}}
                  </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
         

            <b-row v-if="application !== null" class="mT-1">
                <b-col cols="12">
                    <PRNViewer v-if="application.status !== 'DRAFT'" :tracking-number="application.trackingNo" :prn="application.prn"/>
                </b-col>
            </b-row>

            <b-row class="mt-2">
                <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-blue-dark"
                           style="border: solid 1px #bdd7f1;">
                        <thead>
                        <tr class="bg-gray-pale">
                            <td colspan="12" class="px-2 py-2 font-bold">
                                Applicant Details</td>
                        </tr>

                        </thead>
                        <tbody>
                        <tr style="border: solid 1px #bdd7f1;">
                            <td class="px-2 py-1 text-capitalize">

                                <LegalPersons class="p-1"
                                              :persons="[{entityType:'INDIVIDUAL', individual: {...application.representative}}]"/>


                            </td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>

        </b-col>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PRNViewer from "@/views/home/commons/PRNViewer.vue";
import LegalPersons from "@/views/home/commons/LegalPersons.vue";

export default {
  name: "CompanyReinstatementDetails",
  components: {LegalPersons, PRNViewer},
  props:{
     application:{}
  },
  data(){
    return{
      loading:true,
      snapshot:null,
      snapshots:[],
    }
  },
  mounted() {
  },
  methods:{
    ...mapActions(["fetchCompanyDataUpdateDetails", "fetchCompanyIncorporationTaskSnapshots"]),
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },

  },
  computed:{
    ...mapGetters(["getCompanyDataUpdateDetails", "getCompanyDataUpdateDetailsError", "getIncorporationSnapshots"])
  },
  watch:{
    getCompanyDataUpdateDetails(data){
      this.loading = false
      this.application = data
    },
    getIncorporationDetailsError(){
      this.loading = false
      this.errorToast("Error", "Failed to get application details")
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>