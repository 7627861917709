<template>
  <div>
    <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
      <b-row>
        <b-col>
          <p class="text-sm">
            <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
            This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
          </p>
          <p class="mt-3 text-sm italic">
            <b>Note:</b><br/>
            All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="4">
          <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
            <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="4">
          <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
            <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-approve-confirm" hide-footer ref="approveConfirm" title="Final Confirmation">
      <b-row>
        <b-col>
          <p class="text-sm">
            <span class="h6">Final Approval</span>.<br/>
            This action is NON REVERSIBLE, only confirm if you are certain that the information provided is correct and accurate.
          </p>

          <p class="mt-3 text-sm italic">
            <b>Note:</b><br/>
            All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="4">
          <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
            <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="4">
          <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
            <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
      <b-col cols="12">
        <b-form class="w-full">
          <b-form-textarea
            v-model="form.description"
            type="text"
            placeholder="Give description of query"
            class="mt-1"
            required>
          </b-form-textarea>
          <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
          <b-row>
            <b-col cols="4">
              <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                Query
                <b-spinner small v-if="loading"></b-spinner>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-modal>
      <b-modal id="modal-assign" hide-footer ref="deEscalateSubmissions" title="De Escalate Data correction">
          <DeEscalationDataCorrection :application-id="task.id" :level="1" type="reply"
                                      permission="can_access_data_correction" />
      </b-modal>
      <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
          <Assignment :id="task.id" type="data-correction" permission="can_access_data_correction"/>
      </b-modal>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row class="mx-1">
          <b-col>
            <DataCorrectionMessages :task="task" mode="TOP_MESSAGE"/>
          </b-col>
        </b-row>
  
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Data correction Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">

                    {{task.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.brn}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">Level {{task.level}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">
                <button class="btn-escalate" @click="deEscalatePrompt">
                    <i class="fa fa-arrow-down text-blue mr-1"></i>Reply
                </button>
              <button type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-fingerprint text-white mr-1"></i> Assign
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="flex h-full" style="cursor: pointer;">
                  <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']" @click="select('forms')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Company Information</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Attachments</div>
                    </div>
                  </div>
                  <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                    <div class="flex mt-6">
                      <div style="font-size: 14px">Messages</div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
<b-col>
                <data-correction-forms :task="task" v-if="currentRoute === 'forms'" ></data-correction-forms>
                <DataCorrectionFormFilesDetails :application="task" v-if="currentRoute === 'attachments'"/>
                <DataCorrectionDetailsMessages :task="task" v-if="currentRoute === 'messages'"/>
          </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import server from "@/app/env/server.json";
import {years} from "@/views/home/commons/years";
import DataCorrectionForms from "@/views/home/services/data-correction/task/DataCorrectionForms.vue";
import DataCorrectionMessages from "@/views/home/services/data-correction/task/DataCorrectionMessages.vue";
import DataCorrectionFormFilesDetails
    from "@/views/home/services/data-correction/submissions/components/DataCorrectionFormFilesDetails.vue";
import DataCorrectionDetailsMessages
    from "@/views/home/services/data-correction/submissions/components/DataCorrectionDetailsMessages.vue";
import DeEscalationDataCorrection from "@/views/home/commons/DeEscalationDataCorrection.vue";
import Assignment from "@/views/home/commons/Assignment.vue";

export default {
  name: "DataCorrectionEscalationFile",
  components: {
      Assignment,
      DeEscalationDataCorrection,
      DataCorrectionDetailsMessages, DataCorrectionFormFilesDetails,
      DataCorrectionMessages,
      DataCorrectionForms,
  },
  props:{
    escalationId:String
  },
  data(){
    return{
      margin:"2px",
      currentRoute: "forms",
      fileLoader:false,
      loading:false,
      messages:[],
      taskId:null,
      maxText:500,
      snapshot:null,
      snapshots:[],
      showFileMapping:false,
      form:{
        applicationId:null,
        description:null,
        nonCompliance:{
          form1:false,
          form18:false,
          form20:false,
          annualReturnsFiled: []
        },
        status:null,
      },
      task:null,
      annualReturnYear:null,
      annualReturnYears:[],
      file:{
        extension:"pdf",
        Base64FileStream:null
      },
      attachmentUrl:null,
      loadingDocuments:false,
      selectedDocumentHandle:null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetchDataCorrectionDetails(this.escalationId)
  },
  methods:{
    ...mapActions(["queryOrRejectDataCorrection", "fetchDataCorrectionDetails",
       ]),
    years() {
      return years
    },
      deEscalatePrompt() {
          this.$refs.deEscalateSubmissions.show();
      },
      onApprove(){
        this.$refs.assignSubmissions.show();
      },

    onQuery(){
      this.form.applicationId = this.task.id
      this.form.status = "QUERIED"
      this.action = "query"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "query"){
        this.loading = true
          let payload = {
              id: this.form.applicationId,
              rationale: this.form.description,
              status: this.form.status
          }
        this.queryOrRejectDataCorrection(payload)
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    openFile(file) {
      this.file = {
        extension:"pdf",
        Base64FileStream:null
      }
      this.attachmentUrl = null
      if(file.fileName.includes("pdf")){
        this.attachmentUrl = server.IP+"/data-correction/files?attachment="+file.fileName
      }else{
        this.selectedDocumentHandle = file.fileName
        let data = {
          documentHandle:file.fileName,
          overlay:""
        }
        this.fileLoader = true
        this.fetchCompanyEDMSFile(data)
      }
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters([
    "getDataCorrectionDetails",
        "getDataUpdateStatusSuccess",
        "getDataUpdateCompanyUpdateStatusError",
        "getDataCorrectionTaskError",
        "getDataCorrectionError"

    ])
  },
  watch:{
    attachmentUrl:{
      handler(url){
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    },
      getDataCorrectionDetails(data){
          this.loading = false
          this.task = data

      },
      getIncorporationDetailsError(){
          this.loading = false
          this.errorToast("Error", "Failed to get application details")
      },
      getDataCorrectionTaskError(data){
          this.loading = false
          this.errorToast("Error", data.messages)
      },
      getDataUpdateStatusSuccess(){
          this.loading = false
          this.successToast("Success!", "Final Decision Successful")
          window.location.reload()
      },
      getCompanyUpdateStatusSuccessError(){
          this.loading = false
      },
      getDataCorrectionError(error){
          this.errorToast("Data Update  Error", error.data.message);
      }
  }
}
</script>

<style scoped>

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

</style>