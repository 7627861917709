<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Practitioner Annual Return Applications</span>
        </b-row>
      </b-col>
    </b-row>


                <b-row class="flex mx-3 my-3">
                    <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
                        <div class="pre-waiting">
                            <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.DRAFT}}</span>
                        </div>
                        <div class="pre-waiting">
                            <span class="text-sm mx-auto">Drafts</span>
                        </div>
                    </div>
                    <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
                        <div class="waiting">
                            <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.PENDING}}</span>
                        </div>
                        <div class="waiting">
                            <span class="text-sm mx-auto">In Queue</span>
                        </div>
                    </div>
                    <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
                        <div class="normal">
                            <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.ASSIGNED}}</span>
                        </div>
                        <div class="normal">
                            <span class="text-sm mx-auto">Assigned</span>
                        </div>
                    </div>
                    <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
                        <div class="off-normal">
                            <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.ESCALATED}}</span>
                        </div>
                        <div class="off-normal">
                            <span class="text-sm mx-auto">Escalated</span>
                        </div>
                    </div>
                    <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
                        <div class="warning">
                            <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.QUERIED}}</span>
                        </div>
                        <div class="warning">
                            <span class="text-sm mx-auto">Queried</span>
                        </div>
                    </div>
                    <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
                        <div class="positive">
                            <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.APPROVED}}</span>
                        </div>
                        <div class="positive">
                            <span class="text-sm mx-auto">Approved</span>
                        </div>
                    </div>
                    <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
                        <div class="all">
                            <span class="h3 mx-auto">{{getInsolvencyStatistics.total}}</span>
                        </div>
                        <div class="all">
                            <span class="text-sm mx-auto">Total</span>
                        </div>
                    </div>
                </b-row>




        <b-row>
          <b-col>
            <PractitionerAnnualReturnsSubmissions v-if="currentRoute === 'pending'"/>
          </b-col>
        </b-row>


  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PractitionerAnnualReturnsSubmissions
    from "@/views/home/services/insolvency/annual-returns/submissions/PractitionerAnnualReturnsSubmissions.vue";

export default {
  name: "PractitionerAnnualReturnsApplications",
  components: {PractitionerAnnualReturnsSubmissions},
  data(){
    return{
      currentRoute: "pending",
    }
  },
  mounted(){
    this.fetchInsolvencyStatistics("ANNUAL_RETURNS")
  },
  methods:{
    ...mapActions(["fetchInsolvencyStatistics"]),
    select(route){
      this.currentRoute = route
    },
  },
  computed:{
    ...mapGetters(["getInsolvencyStatistics"])
  }
}
</script>

<style scoped>
.pre-waiting{
    @apply flex text-gray_title;
}

.off-normal{
    @apply flex text-blue-light;
}

.waiting{
    @apply flex text-gray;
}

.normal{
    @apply flex text-blue;
}

.positive{
    @apply flex text-green;
}

.warning{
    @apply flex text-orange;
}

.danger{
    @apply flex text-red;
}

.all{
    @apply flex text-flopurple;
}

.stat-count{
    display: inline;
    width: 12%;
    @apply my-1;
}
</style>