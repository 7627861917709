<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-approve" hide-footer ref="approveReservation" title="Approve Reservation">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.description"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-approve-hover" variant="primary" @click="onApprove">
                  Approve
                  <b-spinner v-if="loadingAction" small class="ml-1"></b-spinner>
                </button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-query" size="lg" hide-footer ref="queryReservation" title="Query Reservation">
        <b-row>
          <b-col>
            <b-form-select
              class="form-select text-sm noFocus text-gray"
              v-model="finalMessage.generated"
              :options="responses">
            </b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form class="w-full" >
              <b-form-group
                v-if="finalMessage.generated"
                class="text-sm my-2 w-full"
                id="input-group-1"
                label="Generated Message"
                label-for="input-1">
                <b-form-textarea
                  v-model="finalMessage.generated"
                  type="text"
                  class="mt-1 font-monospace"
                  disabled="true"
                  required>
                </b-form-textarea>
              </b-form-group>
              <b-col>
                <p class="text-sm text-gray my-2" v-if="similarNames.length > 0">
                  Similar Names:
                </p>
                <p class="text-sm text-gray font-monospace" v-if="similarNames.length > 0">
                  <label v-for="name in similarNames" :key="name" class="border border-blue rounded-sm bg-header_grey px-2 py-1 mr-2 mb-1 text-xs">
                    {{name}}
                  </label>
                </p>
              </b-col>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Your Custom Message"
                label-for="input-1">
                <b-form-textarea
                  v-model="finalMessage.custom"
                  type="text"
                  placeholder="Give a personal description of query"
                  class="mt-1"
                  required>
                </b-form-textarea>
              </b-form-group>
              <b-row class="mt-2">
                <b-col>
                  <b-alert variant="warning" :show="form.description != null">
                    {{form.description}}
                  </b-alert>
                </b-col>
              </b-row>

              <b-row class="mt-2">
                <b-col>
                  <table class="w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                    <thead>
                    <tr>
                      <th class="px-2 py-1">Name</th>
                      <th class="px-2 py-1 text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;" v-for="(option, idx) in getAnalysisReport" :key="idx">
                      <td class="px-2 py-1">
                          <span class="font-bold" v-if="option.flags.length > 0">
                            {{option.name}}
                          </span>
                        <span v-else class="font-bold">{{option.name}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <button type="button"
                                class="float-end bg-blue text-white px-2 rounded-sm hover:bg-blue-dark"
                                @click="copy(option.name)">
                          <i class="fa fa-copy"></i> Copy
                        </button>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #f59696;" v-if="analysisReportError != null">
                      <td class="px-2 py-1">
                  <span class="text-red">
                    {{this.analysisReportError.data.message}}
                  </span>
                      </td>
                      <td class="px-2 py-1">
                  <span class="text-red font-bold" v-for="(name, idx) in JSON.parse(this.analysisReportError.config.data).names" :key="idx">
                    {{name}}
                  </span>
                      </td>
                      <td class="px-2 py-1">
                        <span class="text-red">0</span>
                      </td><td class="px-2 py-1">
                      <span class="text-red">0</span>
                    </td><td class="px-2 py-1">
                      <span class="text-red">0</span>
                    </td><td class="px-2 py-1 text-center">
                      <span class="text-red">N/A</span>
                    </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="10"></b-col>
                <b-col cols="2">
                  <button type="button" class="float-end btn-query-hover" variant="primary" @click="onQuery">
                    Query<b-spinner v-if="loadingAction" small class="ml-1"></b-spinner></button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <PickableNameSearch ref="searcher" v-on:pickedNames="pickedNames"/>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-reject" size="lg" hide-footer ref="rejectReservation" title="Reject Reservation">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-form-select
                class="form-select text-sm noFocus text-gray" v-model="finalMessage.generated">
                <b-select-option :value="null">-- Select Rejection Reason --</b-select-option>
                <b-select-option
                  value="Please select other names. Your name option(s) have similar names to those already registered in our registry.">
                  Similar names
                </b-select-option>
                <b-select-option
                  value="We have noticed some malpractice in the information provided in this application">Reservation
                  Mal-Practice
                </b-select-option>
                <b-select-option
                  value="Your payment has an issue. Make sure that your PRN is credited with the right amount of money required by URSB.">
                  Payment Issues
                </b-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-form-group
            v-if="finalMessage.generated"
            class="text-sm my-2 w-full"
            id="input-group-1"
            label="Generated Message"
            label-for="input-1">
            <b-form-textarea
              v-model="finalMessage.generated"
              type="text"
              class="mt-1 font-monospace"
              disabled="true"
              required>
            </b-form-textarea>
          </b-form-group>
          <b-form class="w-full">
            <b-col>
              <p class="text-sm text-gray my-2 font-monospace" v-if="similarNames.length > 0">
                Similar Names:
              </p>
              <p class="text-sm text-gray font-monospace" v-if="similarNames.length > 0">
                <label v-for="name in similarNames" :key="name"
                       class="border border-blue rounded-sm bg-header_grey px-2 py-1 mr-2 mb-1">
                  {{ name }}
                </label>
              </p>
            </b-col>
            <b-form-group
              class="text-sm my-1"
              id="input-group-1"
              label="Your Custom Message"
              label-for="input-1">
              <b-form-textarea
                v-model="finalMessage.custom"
                type="text"
                placeholder="Give a personal description of query"
                class="mt-1"
                required>
              </b-form-textarea>
            </b-form-group>
            <b-row class="mt-2">
              <b-col>
                <b-alert variant="warning" :show="form.description != null">
                  {{ form.description }}
                </b-alert>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <table class="w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1 text-center"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" v-for="(option, idx) in orderOfNameOptions" :key="idx">
                    <td class="px-2 py-1">
                          <span class="font-bold" v-if="option.flags.length > 0">
                            <NameHighlight :name="option.name" :flags="option.flags" :idx="idx" />
                          </span>
                      <span v-else class="font-bold">{{ option.name }}</span>
                    </td>
                    <td class="px-2 py-1 text-center">
                      <button type="button"
                              class="float-end bg-blue text-white px-2 rounded-sm hover:bg-blue-dark"
                              @click="copy(option.name)">
                        <i class="fa fa-copy"></i> Copy
                      </button>
                    </td>
                  </tr>
                  <tr style="border: solid 1px #f59696;" v-if="analysisReportError != null">
                    <td class="px-2 py-1">
                  <span class="text-red">
                    {{ this.analysisReportError.data.message }}
                  </span>
                    </td>
                    <td class="px-2 py-1">
                  <span class="text-red font-bold"
                        v-for="(name, idx) in JSON.parse(this.analysisReportError.config.data).names" :key="idx">
                    {{ name }}
                  </span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-red">0</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-red">0</span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-red">0</span>
                    </td>
                    <td class="px-2 py-1 text-center">
                      <span class="text-red">N/A</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10"></b-col>
              <b-col cols="2">
                <button type="button" class="float-end btn-reject-hover" variant="danger" @click="onReject">
                  Reject
                  <b-spinner v-if="loadingAction" small class="ml-1"></b-spinner>
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <PickableNameSearch ref="searcher" v-on:pickedNames="pickedNames" />
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-select-name-error" hide-footer ref="select-name-error" title="Error!" title-class="text-reds">
        <b-col cols="12">
          <p class="text-center">
            <i class="fa fa-exclamation-triangle mb-4 text-red" style="font-size: 30px;"></i>
          </p>
          <p class="text-sm mb-2 text-center">
            <span class="text-red"> Please select one name among the options given under <b>Name Options</b> above before you continue with this action.</span><br /><br />
            You can do so by selecting the checkbox (on the far right of the table) that is in line with name you want
            to proceed with under the column <i>Select</i>
          </p>
        </b-col>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-a-assign" hide-footer ref="assignSubmissions" title="Assign Reservation">
        <Assignment :id="escalationId" type="reservation-as" permission="can_view_reservation_tasks"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-a-assign" hide-footer ref="replySubmissions" title="Reply to Escalation">
        <AssignmentReply :id="escalationId" :user-id="userId" type="reservation" />
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-e-assign" hide-footer ref="escalateSubmissions" title="Escalate Reservation">
        <Escalation :task-id="escalationId" type="reservation-re" permission="can_view_reservation_escalations"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br />
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the
              appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br />
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that
              initiated are tagged to the action.

            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full"
                    @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel
            </button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark"
                    @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept
            </button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row v-if="reservation != null">
      <b-col :cols="(isShowingThread) ? '9' : '12'">
        <b-row class="mx-3 mb-3">
          <b-col cols="12">
            <b-row class=" py-2 my-2 shadow-md rounded-sm" style="background: #b0ea75;">
              <b-col cols="10">
                <div class="flex">
                  <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
                  <span
                    class="text-capitalize font-bold underline hover:text-green">{{ source.firstName }} {{ source.lastName }}</span>
                </div>
                <p class="text-gray mt-2">
                  {{ message }}
                </p>
                <span class="italic text-gray" style="font-size: 10px;">{{ dateFormat(reservation.updatedAt) }}</span>
              </b-col>
              <b-col cols="2">
                <button class="bg-green text-white px-1 py-1 rounded-sm m-1 text-xs float-end"
                        v-if="getReservationStatuses.length > 0" @click="toggleThread()">
                  <span v-if="isShowingThread">Close thread...</span>
                  <span v-else>See more...</span>
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col cols="12" >
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Reservation Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;">
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{ reservation.no }}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue">{{ reservation.status }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{ cleanEntityType(reservation.subType) }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{ dateFormat(reservation.createdAt) }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>

          </b-col>
          <b-col cols="12">
            <PRNViewer :tracking-number="reservation.no" :prn="reservation.prn" />
          </b-col>
        </b-row>
        <b-row class="mx-auto mt-4">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Name Options</td>
              </tr>
              <tr>
                <th class="px-2 py-1">#</th>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Text Analysis</th>
                <th class="px-2 py-1">Sound Analysis</th>
                <th class="px-2 py-1">Flags</th>
                <th class="px-2 py-1 text-center">Select</th>
              </tr>
              </thead>
              <tbody v-if="orderOfNameOptions.length > 0">
                <tr style="border: solid 1px #dedede;" v-for="(option, idx) in orderOfNameOptions" :key="idx">
                  <td class="px-2 py-1">{{ idx + 1 }}</td>
                  <td class="px-2 py-1">
                    <span class="font-bold" v-if="option.flags.length > 0">
                      <NameHighlight :name="option.name" :flags="option.flags" :idx="idx"/>
                    </span>
                    <span class="font-bold">{{ option.name }}</span>
                  </td>
                  <td class="px-2 py-2">
                    <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer"
                          @click="select('analysis-report')" v-if="option != null">{{ option.nameScore.text }}%</span>
                    <span class="text-gray" v-else>--</span>
                  </td>
                  <td class="px-2 py-2">
                    <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer"
                          @click="select('analysis-report')" v-if="option != null">{{ option.nameScore.sound }}%</span>
                    <span class="text-gray " v-else>--</span>
                  </td>
                  <td class="px-2 py-1">
                    <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer"
                          @click="select('flags')"
                          v-if="option != null">{{ option.flags.length + option.rejectedNames.length }}</span>
                    <span class="text-gray " v-else>--</span>
                  </td>
                  <td class="px-2 py-1 text-center">
                    <b-form-checkbox v-model="selectedId" :value="reservation.nameOptions[idx].id"></b-form-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2 mx-1">
          <b-col cols="4"></b-col>
          <b-col cols="8">
            <div class="flex float-end">
              <button class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
              </button>
              <button class="btn-reject" v-b-modal.modal-reject>
                <i class="fa fa-ban text-white mr-1"></i>Reject
              </button>
              <button class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button class="btn-approve" v-if="analysisReportError == null" @click="showApprove">
                <i class="fa fa-check-circle text-white mr-1"></i>Approve
              </button>
              <button class="btn-approve" @click="replyPrompt">
                <i class="fa fa-reply text-white mr-1"></i> Reply
              </button>
              <button class="btn-approve" @click="assignPrompt">
                <i class="fa fa-hand-pointer text-white mr-1"></i> Assign
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute == item.route) ? 'menu-item-active' : 'menu-item']"
                   v-for="(item, idx) in menu" :key="idx" @click="select(item.route)">
                <div class="flex mt-6">
                  <div style="font-size: 14px">{{ item.title }}</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col>
            <NameReservationFormAppeal :reservation="reservation" v-if="currentRoute === 'form'" />
            <AnalysisReportAppeal :reservation="reservation" v-if="currentRoute === 'analysis-report'" />
            <SystemFlagsAppeal :reservation="reservation" v-if="currentRoute === 'flags'" />
            <ReservationFilesEscalated :application="reservation" v-if="currentRoute === 'attachments'"/>
          </b-col>
        </b-row>
      </b-col>
      <b-col :cols="(isShowingThread) ? '3':''">
        <b-row v-if="isShowingThread">
          <b-col cols="12" class="bg-header_grey rounded mt-2 py-2">
            <b-row v-for="(message,idx) in getReservationStatuses" :key="idx">
              <b-col cols="11" class="mx-auto bg-green-pale rounded my-1"
                     v-if="message.description != '' && message.description != null">
                <b-row>
                  <b-col>
                    <span class="text-xs font-bold text-gray" v-if="message.user">{{ message.user.firstName
                      }} {{ message.user.lastName }}</span>
                    <span class="bg-green rounded text-white text-capitalize px-1 ml-1"
                          style="font-size: 8px; padding-bottom: 2px; padding-top: 2px;" v-if="message.user">
                      {{ (message.user.accountType == "INDIVIDUAL") ? "Applicant" : "URSB" }}
                    </span><br />
                    <span class="text-xs text-green-dark">{{ message.description }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="italic text-gray" style="font-size: 8px;">{{ dateFormat(message.createdAt) }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NameHighlight from "@/views/home/services/reservation/components/NameHighlight";
import NameReservationFormAppeal from "@/views/home/services/reservation/appeals/tabs/NameReservationFormAppeal";
import AnalysisReportAppeal from "@/views/home/services/reservation/appeals/tabs/AnalysisReportAppeal";
import SystemFlagsAppeal from "@/views/home/services/reservation/appeals/tabs/SystemFlagsAppeal";
import moment from "moment";
import Assignment from "@/views/home/commons/Assignment";
import Escalation from "@/views/home/commons/Escalation";
import AssignmentReply from "@/views/home/commons/AssignmentReply";
import PRNViewer from "@/views/home/commons/PRNViewer";
import PickableNameSearch from "@/views/home/services/reservation/task/PickableNameSearch";
import ReservationFilesEscalated from "@/views/home/services/reservation/escalations/ReservationFilesEscalated";

export default {
  name: "ReservationEscalationFile",
  components: {
    ReservationFilesEscalated,
    PickableNameSearch,
    PRNViewer,
    AssignmentReply,
    Escalation,
    Assignment,
    SystemFlagsAppeal,
    AnalysisReportAppeal,
    NameReservationFormAppeal, NameHighlight
  },
  props: { reservation: {}, escalationId: String, message: String, time: String, source: Object },
  data() {
    return {
      hasComments: false,
      currentRoute: "form",
      selectedId: null,
      margin: "2px",
      hasContent: false,
      loading: false,
      loadingAction: false,
      report: [],
      responses: [],
      reasons: [],
      orderOfNameOptions:[],
      canAppeal: false,
      finalMessage: {
        generated: null,
        custom: null
      },
      similarNames: [],
      form: {
        description: null,
        reservationId: null,
        status: null
      },
      menu: [
        {
          title: "Reservation Details",
          route: "form"
        },
        {
          title: "Analysis Report",
          route: "analysis-report"
        },
        {
          title: "System Flags",
          route: "flags"
        }
      ],
      taskId: null,
      userId: null,
      analysisReportError: null,
      isShowingThread: false
    };
  },
  mounted() {
    this.fetchReservationStatuses(this.reservation.id);
    this.form.reservationId = this.reservation.id;
    this.fetchReservationResponses();
  },
  methods: {
    ...mapActions(["fetchAnalysisReport", "updateReservationStatus", "emptyAnalysisReport", "fetchReservationStatuses", "fetchPRNDetails", "fetchReservationResponses"]),
    select(route) {
      this.currentRoute = route;
    },
    toggleThread() {
      this.isShowingThread = !this.isShowingThread;
    },
    cleanEntityType(name) {
      let n = name.replaceAll("-", " ");
      return n.charAt(0).toUpperCase() + n.slice(1);
    },
    showApprove() {
      if (this.selectedId == null) {
        this.$refs["select-name-error"].show();
      } else {
        this.onApprove();
      }
    },
    onApprove() {
      this.action = "final";
      this.form.status = "Approved";
      this.form.nameOptionId = this.selectedId;
      this.$refs.confirm.show();
    },
    onQuery() {
      this.action = "final";
      this.form.status = "Queried";
      if (this.form.description != null) {
        this.$refs.confirm.show();
      } else {
        this.errorToast("Missing Information", "Provide a message for this final decision");
      }
    },
    onReject() {
      this.action = "final";
      this.form.status = "Rejected";
      if (this.form.description) {
        this.$refs.confirm.show();
      } else {
        this.errorToast("Missing Information", "Provide a message for this final decision");
      }
    },
    yesConfirmation() {
      if (this.action === "final") {
        this.loadingAction = true;
        this.updateReservationStatus(this.form);
      }
      if (this.action === "escalate") {
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation();
    },
    cancelConfirmation() {
      this.action = null;
      this.$refs.confirm.hide();
    },
    replyPrompt() {
      this.loading = true;
      this.taskId = this.reservation.id;
      this.userId = this.source.id;
      this.$refs.replySubmissions.show();
    },
    assignPrompt() {
      this.loading = true;
      this.taskId = this.reservation.id;
      this.$refs.assignSubmissions.show();
    },
    escalatePrompt(data) {
      this.loading = true;
      this.reservationId = data.id;
      this.$refs.escalateSubmissions.show();
    },
    pickedNames(names) {
      this.similarNames = names;
    },
    copy(text){
      navigator.clipboard.writeText(text).then(function() {
        console.log('Async: Copied');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
      this.$refs.searcher.searchThis(text)
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  },
  computed: {
    ...mapGetters([
      "getAnalysisReport",
      "getAnalysisReportError",
      "getReservationError",
      "getReservationSuccess",
      "getReservationAssignmentSuccess",
      "getReservationAssignmentError",
      "getReservationActionSuccess",
      "getReservationActionError",
      "getReservationStatuses",
      "getAnalysisReportError",
      "getReservationResponses"])
  },
  watch: {
    finalMessage: {
      handler() {
        this.form.description = "";
        if (this.finalMessage.generated != null) {
          this.form.description += this.finalMessage.generated + " ";
          for (let i = 0; i < this.reasons.length; i++) {
            if (this.reasons[i].message === this.finalMessage.generated) {
              this.canAppeal = this.reasons[i].appealStatus;
            } else {
              this.canAppeal = false;
            }
          }
        }
        if (this.similarNames.length > 0) {
          this.form.description += "Similar names include the following; " + this.similarNames.toString().replace(",", ", ") + ". ";
        }
        if (this.finalMessage.custom != null) {
          this.form.description += this.finalMessage.custom;
        }
      },
      deep: true
    },
    similarNames: {
      handler() {
        this.form.description = "";
        if (this.finalMessage.generated != null) {
          this.form.description += this.finalMessage.generated + " ";
        }
        if (this.similarNames.length > 0) {
          this.form.description += "Similar names include the following; " + this.similarNames.toString().replace(",", ", ") + ". ";
        }
        if (this.finalMessage.custom != null) {
          this.form.description += this.finalMessage.custom;
        }
      },
      deep: true
    },
    getReservationAssignmentSuccess() {
      this.loading = false
    },
    getReservationResponses(data) {
      this.reasons = data;
      this.responses = [];
      this.responses.push(
        {
          value: null,
          text: "--Select a Reason --"
        }
      );
      for (let i = 0; i < this.reasons.length; i++) {
        this.responses.push(
          {
            value: this.reasons[i].message,
            text: this.reasons[i].reason
          }
        );
      }
    },
    getAnalysisReportError(data) {
      this.analysisReportError = data;
    },
    getAnalysisReport(data) {
      for(let i = 0; i < this.reservation.nameOptions.length; i++){
        let option = this.reservation.nameOptions[i]
        for(let j = 0; j < data.length; j++){
          if(option.name.trim() === data[j].name.trim()){
            this.orderOfNameOptions[option.nameIndex - 1] = data[j]
          }
        }
      }
    },
    getReservationSuccess() {
      this.loadingAction = false;
      this.loadingAction = false
      this.successToast("Reservation Action!", "Completed Task Successfully");
      this.clearForm();
      this.$refs.approveReservation.hide();
      this.$refs.rejectReservation.hide();
      this.$refs.queryReservation.hide();
      window.location.reload();
    },
    getReservationAssignmentError(data) {
      this.loading = false;
      this.errorToast("Error!", data.message);
      window.location.reload();
    },
    getReservationError(data) {
      this.loading = false;
      this.loadingAction = false
      this.errorToast("Error!", data.message);
      window.location.reload();
    },
    getReservationActionSuccess(){
      this.loading = false
      this.loadingAction = false
      this.successToast("Reservation Action!", "Completed Task Successfully")
      this.$refs.rejectReservation.hide();
      this.$refs.queryReservation.hide();
      window.location.reload();
    },
    getReservationActionError(e){
      this.loading = false
      this.loadingAction = false
      this.errorToast("Error!", "Final Decision Failed: " + e.data.message)
      this.$refs.rejectReservation.hide();
      this.$refs.queryReservation.hide();
      window.location.reload();
    },
  }
};
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.title {
  font-size: 22px;
}

.icon {
  width: 170px;
}

th, td {
  font-size: 12px;
}

.btn-escalate {
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover {
  @apply text-blue-dark;
}

.btn-approve {
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover {
  @apply bg-green border-green text-white;
}

.btn-approve-hover {
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query {
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject {
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-query:hover {
  @apply bg-orange border-orange text-white;
}

.btn-reject:hover {
  @apply bg-red border-red text-white;
}

.btn-query-hover {
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover {
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover {
  @apply bg-green-dark;
}

.btn-query-hover:hover {
  @apply bg-orange-dark;
}

.btn-reject-hover:hover {
  @apply bg-red-dark;
}
</style>