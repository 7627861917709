<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Registration">
        <Escalation :task-id="taskId" type="bnr" permission="can_view_bname_reg_escalations"/>
      </b-modal>
      <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
        <b-row>
          <b-col>
            <p class="text-sm">
              <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
              This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
            </p>
            <p class="mt-3 text-sm italic">
              <b>Note:</b><br/>
              All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col cols="4">
            <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
              <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
          </b-col>
          <b-col cols="4"></b-col>
          <b-col cols="4">
            <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
              <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal id="modal-query" hide-footer ref="query-registration" title="Query Registration">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
              v-model="form.description"
              type="text"
              placeholder="Give description of query"
              class="mt-1"
              required>
            </b-form-textarea>
            <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
            <b-row>
              <b-col cols="4">
                <button type="button" class="btn-query-hover" variant="primary" @click="onQuery">
                  Query<b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row class="mx-1" v-if="getRegistrationTask.escalation != null">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-green">
            <b-row>
              <b-col>
                <div class="flex">
                  <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
                  <span class="text-sm font-bold underline">
                    {{getRegistrationTask.escalation.fromUser.firstName}}
                    {{getRegistrationTask.escalation.fromUser.lastName}}
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-sm">{{getRegistrationTask.escalation.description}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span style="font-size:9px">{{dateFormat(getRegistrationTask.escalation.createdAt)}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="bg-green rounded text-white" style="padding: 2px 4px; font-size: 9px;">Escalation Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-1" v-if="isQueryResponse">
          <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
            <b-row>
              <b-col>
                <div class="flex">
                  <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                  <span class="text-sm font-bold underline">{{messages[0].user.firstName}} {{messages[0].user.lastName}}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-sm">{{messages[0].description}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span style="font-size:9px">{{dateFormat(messages[0].dateTime)}}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Query Response</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{getRegistrationTask.reservation.name}}
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Registration Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{getRegistrationTask.no}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-orange font-bold" v-if="queried">Queried</span>
                  <span class="text-blue" v-else>{{getRegistrationTask.status}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(getRegistrationTask.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(getRegistrationTask.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="getRegistrationTask.subType.includes('limited')">
          <b-col cols="12">
            <PRNViewer :tracking-number="getRegistrationTask.no" :prn="getRegistrationTask.paymentResponses[0].prn"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-4">
            <table class="min-w-full leading-normal mt-1 text-gray"
                   style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2 font-bold">Summary</td>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Date of Commencement</th>
                <td class="px-2 py-1">
                          <span class="text-gray">
                            {{dateFormat(getRegistrationTask.dateOfCommencement)}}
                          </span>
                </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Reservation No.</th>
                <td class="px-2 py-1">
                          <span class="text-blue italic">
                            {{getRegistrationTask.reservation.no}}
                          </span>
                </td>
              </tr>
              <tr style="border: solid 1px #dedede;">
                <th class="px-2 py-1">Nature of Business</th>
                <td class="px-2 py-1">
                  <ul class="text-blue">
                    <li v-for="(section, idx) in getRegistrationTask.natureOfBusiness.breakdown"  :key="idx">
                      <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                    </li>
                  </ul>
                </td>
              </tr>

              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <div class="flex float-end">
              <button type="button" class="btn-escalate" @click="escalatePrompt">
                <i class="fa fa-arrow-up text-blue mr-1"></i> Escalate
              </button>
              <button @click="form.askUserToCancelReservation = true" type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-times text-white mr-1"></i> Cancel Registration
              </button>
              <button @click="form.askUserToCancelReservation = false" type="button" class="btn-query" v-b-modal.modal-query>
                <i class="fa fa-question text-white mr-1"></i> Query
              </button>
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'application-details') ? 'menu-item-active' : 'menu-item']" @click="select('application-details')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Details</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Attachments</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']" @click="select('messages')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Messages</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <BusinessNameFormDetails v-if="currentRoute === 'application-details'"/>
            <BusinessNameFormFiles v-if="currentRoute === 'attachments'"/>
            <BusinessNameMessages :id="getRegistrationTask.businessId" v-if="currentRoute === 'messages'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment"
import BusinessNameFormDetails from "@/views/home/services/registration/task/BusinessNameFormDetails";
import Escalation from "@/views/home/commons/Escalation";
import BusinessNameMessages from "@/views/home/services/registration/task/BusinessNameMessages";
import BusinessNameFormFiles from "@/views/home/services/registration/task/BusinessNameFormFiles";
import PRNViewer from "@/views/home/commons/PRNViewer";

export default {
  name: "BusinessNameRegistrationTask",
  components: { PRNViewer, BusinessNameFormFiles, BusinessNameMessages, Escalation, BusinessNameFormDetails},
  data(){
    return{
      hasComments: false,
      currentRoute: "application-details",
      loading:true,
      margin:"2px",
      queried:false,
      taskId:null,
      isQueryResponse:false,
      messages:[],
      maxText:500,
      form:{
        askUserToCancelReservation:false,
        description:null,
        businessId:null,
        status:null
      },
      menu:[
        {
          title:"Application Details",
          route:"application-details",
        },
        {
          title:"Attachments",
          route:"attachments",
        },
      ],
      action:null
    }
  },
  mounted() {
    this.fetchBusinessNameRegistration()
    this.fetchRegistrationTaskCount()
  },
  methods:{
    ...mapActions(["fetchBusinessNameRegistration", "updateRegistrationStatus", "fetchRegistrationTaskCount",
      "fetchReservationNumberDetails", "fetchRegistrationMessages"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onApprove(){
      this.form.status = "Approved"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onCancel(){
      this.form.status = "Queried"
      this.form.askUserToCancelReservation = true
      this.action = "final"
      this.$refs["query-registration"].show();
    },
    onQuery(){
      this.form.status = "Queried"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    yesConfirmation(){
      if(this.action === "final"){
        this.loading = true
        this.updateRegistrationStatus(this.form)
      }
      if(this.action === "escalate") {
        this.$refs.escalateSubmissions.show();
      }
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    select(route){
      this.currentRoute = route
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    getDateTime(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters(
        [
          "getRegistrationTask",
          "getRegistrationSuccess",
          "getRegistrationTaskError",
          "getRegistrationError",
          "getRegistrationTaskCount",
          "getReservationNumberResult",
          "getCompanyUpdateStatusSuccess",
          "getCompanyUpdateStatusSuccessError",
          "getRegistrationTaskMessages",
          "getRegistrationActionSuccess"
        ])
  },
  watch:{
    getRegistrationTask(data){
      this.loading = false
      if(data != null){
        this.taskId = data.businessId
        this.form.businessId = data.businessId
      }
      this.fetchRegistrationTaskCount()
      this.fetchRegistrationMessages(data.businessId)
    },
    getRegistrationTaskMessages(data){
      let messages = data
      if(messages[0].status === "Queried"){
        this.isQueryResponse = true
      }
    },
    getRegistrationActionSuccess(data){
      if(data) {
        this.loading = false
        this.successToast("Success!", data.message)
        this.cancelConfirmation()
        this.$refs["query-registration"].hide();
        window.location.reload()
      }
    },
    getUpdateStatusSuccessError(data){
      this.loading = false
      this.$refs["query-registration"].hide();
      this.cancelConfirmation()
      this.errorToast("Error", data.data.message)
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";
.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}
.btn-escalate{
  @apply border border-blue bg-white rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}
</style>