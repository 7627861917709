<template>
  <b-row>
    <b-col cols="12">
      <b-row class="mx-1 py-1" v-for="(person, idx) in persons" :key="idx">
        <b-col cols="12">
          <b-row  class="py-1 border-b border-gray">
            <b-col style="text-transform: uppercase">
              <span class="text-xs font-bold text-capitalize" v-if="person.entityType === 'INDIVIDUAL'">
                {{person.individual.givenName}} {{person.individual.middleName}} {{person.individual.surName}}
              <p>
                <b-badge class="bg-red" v-if="person.individual.leaving">
                  Deceased
                </b-badge>
                <b-badge class="bg-green" v-else>
                  Alive
                </b-badge>
              </p>
              </span>
              <span class="text-xs font-bold text-capitalize" v-if="person.entityType === 'CORPORATE'">{{person.corporate.registeredName}}</span>
              <span class="text-xs font-bold" v-if="person.entityType === 'JOINT'">{{person.jointShareholder.length}} Person(s)</span>
              <span class="text-xs font-bold" v-if="person.entityType === 'STOCK_EXCHANGE'">{{person.name}}</span>
            </b-col>
            <b-col class="text-xs">
              <b-badge :class="(person.entityType === 'CORPORATE') ? 'bg-blue' : (person.entityType === 'INDIVIDUAL') ? 'bg-orange' : 'bg-green'">

                <label v-if="person.entityType === 'CORPORATE'">
                    Corporate Entity
                </label>

                  <label v-if="person.entityType === 'INDIVIDUAL'">
                      Individual
                  </label>
                  <label v-if="person.entityType === 'JOINT'">
                      Individual
                  </label>
                  <label v-if="person.entityType === 'STOCK_EXCHANGE'">
                     Securities Exchange
                  </label>
              </b-badge>

            </b-col>
            <b-col>
              <button class="bg-blue text-white rounded-sm px-2 py-1 text-xs float-end" @click="show(idx)">
                <span v-if="selectedIdx === idx">Hide Details</span>
                <span v-else>Show Details</span>
              </button>
            </b-col>
          </b-row>
          <b-row v-if="showDetails && selectedIdx === idx">
            <b-col cols="12" v-if="person.entityType === 'CORPORATE'">
              <b-row>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Entity Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Registration Number (BRN)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.brn"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.primaryEmail"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.primaryPhone"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Incorporation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.dateOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Company Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="cleanEntityType(person.corporate.subType)"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.countryOfOrigin"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Address in Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.addressInCountryOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    v-if="person.corporate.registeredAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredAddress.postalAddress"
                      required>
                    </b-form-input>
                    <PostalAddressValidator v-if="person.corporate.registeredAddress.postalAddress" :box-number="person.corporate.registeredAddress.postalAddress"/>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredAddress.plot"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.corporate.registeredAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.corporate.registeredAddress.street"
                      required>
                    </b-form-input>
                  </b-form-group>

                </b-col>
                <b-col>
                  <b-form-group
                    v-if="person.corporate.registeredAddress"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Description"
                    label-for="input-1">
                    <b-form-textarea
                      class="form-text text-sm noFocus"
                      type="text"
                      rows="4"
                      disabled="true"
                      :value="person.corporate.registeredAddress.description"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="person.entityType === 'INDIVIDUAL'">
              <b-row>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Given Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.givenName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.middleName"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Middle Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.middleName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Surname"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.surName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.idType"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.individual.idType.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.idNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Nationality"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.nationality"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.email"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.phoneNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.individual.sex"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Gender (at birth)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.individual.sex.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Birth"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="dateFormat(person.individual.dateOfBirth)"
                      required>
                    </b-form-input>
                    <b-form-invalid-feedback state="true">
                      {{person.individual.age}}
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Occupation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.occupation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col v-if="person.individual.address.physicalAddress">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="District"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.physicalAddress.district.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Region"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.physicalAddress.region.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Sub county"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.physicalAddress.subcounty.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Parish"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.physicalAddress.parish.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Village"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.physicalAddress.village.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.postalAddress"
                      required>
                    </b-form-input>
                    <PostalAddressValidator v-if="person.individual.address.postalAddress" :box-number="person.individual.address.postalAddress"/>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.street"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.individual.address.plot"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Description"
                    label-for="input-1">
                    <b-form-textarea
                      class="form-text text-sm noFocus"
                      type="text"
                      rows="4"
                      disabled="true"
                      :value="person.individual.address.description"
                      required>
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="person.entityType === 'JOINT'">
              <b-row>
                <b-col>
                  <LegalPersons class="p-1" :persons="person.jointShareholder"/>
                </b-col>
              </b-row>
            </b-col>
              <b-col cols="12" v-if="person.entityType === 'STOCK_EXCHANGE'">
                  <b-row>
                      <b-col>
                          <b-form-group
                                  class="text-sm my-1"
                                  id="input-group-1"
                                  label="Entity Name"
                                  label-for="input-1">
                              <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus"
                                      type="text"
                                      disabled="true"
                                      :value="person.name"
                                      required>
                              </b-form-input>
                          </b-form-group>
                          <b-form-group
                                  class="text-sm my-1"
                                  id="input-group-1"
                                  label="Link"
                                  label-for="input-1">
                              <b-form-input
                                      id="link"
                                      class="form-text text-sm noFocus text-blue"
                                      type="text"
                                      disabled="true"
                                      :value="person.stockExchange.link"
                                      required>
                              </b-form-input>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
          </b-row>
        </b-col>

      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import PostalAddressValidator from "@/views/home/commons/PostalAddressValidator";

export default {
  name: "LegalPersons",
  components: { PostalAddressValidator },
  props:{
    persons:[]
  },
  data(){
    return{
      showDetails:false,
      selectedIdx:null
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD/MM/YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    show(idx){
      if(this.selectedIdx === idx){
        this.selectedIdx = null
        this.showDetails = false
      }else{
        this.selectedIdx = idx
        this.showDetails = true
      }
    }
  }
}
</script>

<style scoped>

</style>