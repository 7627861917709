<template>
  <b-row>
    <b-col cols="12" class="py-2">
      <b-row>
        <b-col cols="12" class="text-center">
          <span class="text-grey">Security & Access</span>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="10" class="mx-auto">
          <b-form class="w-full">
            <b-input-group>
              <b-form-input
                  v-model="form.oldPassword"
                  :type="showOldPassword ? 'text' : 'password'"
                  placeholder="Old Password"
                  class="mt-2 text-xs noFocus"
                  required>
              </b-form-input>
              <b-input-group-append>
                <i class="fa fa-eye mt-3 ml-1 text-gray hover:text-blue" style="cursor: pointer;" @click="showOldPassword = !showOldPassword"></i>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input
                  v-model="form.newPassword"
                  :type="showNewPassword ? 'text' : 'password'"
                  placeholder="New Password"
                  class="mt-2 text-xs noFocus"
                  required>
              </b-form-input>
              <b-input-group-append>
                <i class="fa fa-eye mt-3 ml-1 text-gray hover:text-blue" style="cursor: pointer;" @click="showNewPassword = !showNewPassword"></i>
              </b-input-group-append>
            </b-input-group>
            <b-input-group>
              <b-form-input
                  v-model="form.confirmPassword"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  placeholder="Confirm Password"
                  class="mt-2 text-xs noFocus"
                  required>
              </b-form-input>
              <b-input-group-append>
                <i class="fa fa-eye mt-3 ml-1 text-gray hover:text-blue" style="cursor: pointer;" @click="showConfirmPassword = !showConfirmPassword"></i>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="text-center">
          <button class="button">Change Password</button>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="10" class="mx-auto">
          <span>Account Role(s)</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="10" class="mx-auto">
          <span class="text-capitalize text-gray text-sm hover:text-blue" v-for="(role, idx) in userProfile.userRoles" :key="idx">
            - {{getRole(role)}}<br/>
          </span>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import jwtService from "@/app/services/jwt.service";

export default {
  name: "AccountSecurity",
  data(){
    return{
      userProfile:{},
      form:{
        oldPassword:null,
        newPassword:null,
        confirmPassword:null
      },
      showOldPassword:false,
      showNewPassword:false,
      showConfirmPassword:false
    }
  },
  mounted() {
    this.userProfile = jwtService.getUser()
  },
  methods:{
    getRole(role){
      return role.replaceAll("_", " ").toLowerCase()
    }
  }
}
</script>

<style scoped>
.button {
  @apply text-center rounded-sm bg-blue text-white px-5 py-1 font-bold;
  font-size: 12px;
}

.button:hover {
  @apply bg-blue-dark border-blue-dark;
}
</style>