<template>
<span>
<b-row>
  <b-col cols="12" sm="12" md="12" :lg="showForeignAddressBox ? 6 : 12">
    <label class="text-gray mb-1" style="font-size: 14px;">Country<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
       <b-form-group>
          <b-form-select
              id="country"
              :disabled="countryReadOnly || readOnlyDisableFields"
              class="form-select  noFocus text-gray mb-2 text-sm"
              v-model="countryId"
              @change="selectCountry(countryId)"
          >
            <option v-for="(country, idx) in countryList" :key="idx"
                    :value="{ text: country.text, value:country.value}">
            {{ country.text }}
          </option>
          </b-form-select>
        </b-form-group>
    </b-col>
  <b-col cols="12" sm="12" md="12" lg="12" v-if="!showForeignAddressBox">
     <label class="text-gray mb-1" style="font-size: 14px;">Physical Address <i style="font-size: 10px"
                                                                                class="fas fa-asterisk ml-1 text-red"></i></label>
      <b-form-textarea
          id="foreignPhysicalAddress"
          :disabled="readOnlyDisableFields"
          v-model="form.foreignPhysicalAddress"
          type="text"
          placeholder="Add your physical address."
          rows="4"
          class="mt-1 mb-3 noFocus text-sm"
          required>
          </b-form-textarea>

  </b-col>
  <b-col cols="12" sm="12" md="12" lg="6" v-if="showForeignAddressBox">
      <label class="text-gray mb-1" style="font-size: 14px;">Region<i style="font-size: 10px"
                                                                      class="fas fa-asterisk ml-1 text-red"></i></label>
       <b-form-group>
          <b-form-select
              id="region"
              :disabled="!countryId.value || readOnlyDisableFields"
              @change="selectRegion(regionId)"
              class="form-select  noFocus text-gray mb-2 text-sm"
              v-model="regionId"
              :options="regionList">
          </b-form-select>
          </b-form-group>
    </b-col>
  <b-col cols="12" sm="12" md="12" lg="6" v-if="showForeignAddressBox">
     <label class="text-gray mb-1" style="font-size: 14px;">District<i style="font-size: 10px"
                                                                       class="fas fa-asterisk ml-1 text-red"></i></label>
     <b-form-group>
       <b-form-select
           id="district"
           :disabled="!regionId || readOnlyDisableFields"
           @change="selectDistrict(districtId)"
           class="form-select  noFocus text-gray mb-2 text-sm"
           v-model="districtId"
           :options="districtList">
       </b-form-select>
     </b-form-group>
  </b-col>
  <b-col cols="12" sm="12" md="12" lg="6" v-if="showForeignAddressBox">
    <label class="text-gray mb-1" style="font-size: 14px;">Sub County<i style="font-size: 10px"
                                                                        class="fas fa-asterisk ml-1 text-red"></i></label>
     <b-form-group>
       <b-form-select
           id="subCounty"
           :disabled="!districtId || readOnlyDisableFields"
           @change="selectSubCounty(subCountyId)"
           class="form-select  noFocus text-gray mb-2 text-sm"
           v-model="subCountyId"
           :options="subCountyList">
       </b-form-select>
     </b-form-group>
  </b-col>
  <b-col cols="12" sm="12" md="12" lg="6" v-if="showForeignAddressBox">
    <label class="text-gray mb-1" style="font-size: 14px;">Parish<i style="font-size: 10px"
                                                                    class="fas fa-asterisk ml-1 text-red"></i></label>
      <b-form-group>
        <b-form-select
            id="parish"
            :disabled="!subCountyId || readOnlyDisableFields"
            @change="selectParishData(parishId)"
            class="form-select  noFocus text-gray mb-2 text-sm"
            v-model="parishId"
            :options="parishList">
        </b-form-select>
      </b-form-group>
  </b-col>
  <b-col cols="12" sm="12" md="12" lg="6" v-if="showForeignAddressBox">
     <label class="text-gray mb-1" style="font-size: 14px;">Village<i style="font-size: 10px"
                                                                      class="fas fa-asterisk ml-1 text-red"></i></label>
      <b-form-group>
        <b-form-select
            id="village"
            :disabled="!parishId || readOnlyDisableFields"
            @change="selectVillage(villageId)"
            class="form-select  noFocus text-gray text-sm"
            v-model="villageId"
            :options="villageList">
        </b-form-select>
      </b-form-group>
  </b-col>
</b-row>
</span>
</template>

<script>
import {mapActions} from "vuex";
import {api} from "@/app/services/api.service";

export default {
  name: "ResidenceofPartner",
  props: {
    index: Number,
    villageDataInfo: {},
    readOnlyDisableFields: Boolean,
    defaultCountryUganda: String
  },
  data() {
    return {
      countryList: [],
      countryId: {"text": "--Select Country--", "value": ""},
      regionList: [],
      regionId: "",
      districtId: "",
      districtList: [],
      subCountyId: "",
      subCountyList: [],
      parishId: "",
      parishList: [],
      villageId: "",
      villageList: [],
      physicalAddress: null,
      showForeignAddressBox: true,
      countryReadOnly: false,
      form: {
        villageId: null,
        countryId: null,
        foreignPhysicalAddress: null
      }
    }
  },
  mounted() {
    this.fetchCountriesAsync();
    this.checkAdminUnitInfo()
    //this makes uganda a default country
    if (this.defaultCountryUganda) {
      this.countryReadOnly = true
    }
  },
  methods: {
    ...mapActions(
        [
          "fetchRegion",
          "fetchCountries",
          "fetchDistricts",
          "fetchCounty",
          "fetchSubCounty",
          "fetchParish",
          "fetchVillage"
        ]
    ),
    async fetchCountriesAsync() {
      await api().get("configuration/address/country")
          .then((response) => {
            let arr = [{value: "", text: "--Select Country--"}]
            this.countryList = arr.concat(this.filterUnitData(response.data))

            //this loads only when and entity is registered in uganda
            if (this.defaultCountryUganda) {
              response.data.forEach(lst => {
                if (lst.name.toUpperCase() === "UGANDA") {
                  this.countryId = {text: lst.name.toUpperCase(), value: lst.id}
                  this.selectCountry({text: lst.name, value: lst.id})
                }
              })
            }

          })
          .catch((error) => {
            this.$store.commit("setDistrictsResultsError", error.response);
          });
    },
    async fetchRegionAsync(countryId) {
      await api().get("configuration/address/region?countryId=" + countryId)
          .then((response) => {
            let arr = [{value: "", text: "--Select Region--"}]
            this.regionList = arr.concat(this.filterUnitData(response.data))

          })
          .catch((error) => {
            this.$store.commit("setDistrictsResultsError", error.response);
          });
    },
    async fetchDistrictAsync(regionId) {
      await api().get("configuration/address/district?regionId=" + regionId)
          .then((response) => {
            let arr = [{value: "", text: "--Select District--"}]
            this.districtList = arr.concat(this.filterUnitData(response.data))
          })
          .catch((error) => {
            this.$store.commit("setDistrictsResultsError", error.response);
          });
    },
    async fetchSubCountyAsync(districtId) {
      await api().get("configuration/address/sub-county?districtId=" + districtId)
          .then((response) => {
            let arr = [{value: "", text: "--Select Sub County--"}]
            this.subCountyList = arr.concat(this.filterUnitData(response.data))
          })
          .catch((error) => {
            this.$store.commit("setDistrictsResultsError", error.response);
          });
    },
    async fetchVillageAsync(parishId) {
      await api().get("configuration/address/village?parishId=" + parishId)
          .then((response) => {
            let arr = [{value: "", text: "--Select Village--"}]
            this.villageList = arr.concat(this.filterUnitData(response.data))
          })
          .catch((error) => {
            this.$store.commit("setDistrictsResultsError", error.response);
          });
    },
    async fetchParishAsync(subCountyId) {
      await api().get("configuration/address/parish?subCountyId=" + subCountyId)
          .then((response) => {
            let arr = [{value: "", text: "--Select Parish--"}]
            this.parishList = arr.concat(this.filterUnitData(response.data))
          })
          .catch((error) => {
            this.$store.commit("setDistrictsResultsError", error.response);
          });
    },
    async selectCountry(countryId) {
      if(countryId){
        if(countryId.value) {
          this.form.countryId = countryId.value
        }
        if (countryId.value && countryId.text.toUpperCase() === 'UGANDA') {
        this.regionId = ""
        this.districtId = ""
        this.subCountyId = ""
        this.parishId = ""
        this.villageId = ""

          await this.fetchRegionAsync(countryId.value);
          this.showForeignAddressBox = true
          this.form.foreignPhysicalAddress = ""
        } else {
          this.showForeignAddressBox = false
          this.form.villageId = ""
        }
      }

    },
    async selectRegion(regionId) {
      this.districtId = ""
      this.subCountyId = ""
      this.parishId = ""
      this.villageId = ""
      await this.fetchDistrictAsync(regionId)
    },
    async selectDistrict(districtId) {
      this.subCountyId = ""
      this.parishId = ""
      this.villageId = ""
      await this.fetchSubCountyAsync(districtId)
    },
    async selectParishData(parishId) {
      this.villageId = ""
      await this.fetchVillageAsync(parishId)
    },
    selectVillage(village) {
      this.form.villageId = village
      // this.$emit("selectedVillage",village);
    },
    async selectSubCounty(subCountyId) {
      this.parishId = ""
      this.villageId = ""
      await this.fetchParishAsync(subCountyId)
    },
    async checkAdminUnitInfo() {
      if (this.villageDataInfo && Object.keys(this.villageDataInfo).length > 0) {

        let data = this.villageDataInfo
        //this works for foreign address
        if (data.foreignPhysicalAddress && Object.keys(data.length > 0)) {
          this.showForeignAddressBox = false
          this.countryId = {text: data ? data.name ? data.name.toUpperCase() : null : null, value: data.id ? data.id : null}
          this.form.countryId = data.id ? data.id : null
          this.form.foreignPhysicalAddress = data.foreignPhysicalAddress ? data.foreignPhysicalAddress : null

        }else {
          //this works for local country
          if (data.country && data.country.id && data.country.name){
            this.countryId = {text: data.country.name.toUpperCase(), value: data.country.id}
          }
          if(data.countryId && data.name){
            this.countryId = {text: data.name.toUpperCase(), value: data.countryId}
          }

          if (data.country && data.country.id && data.country.name) {
            await this.selectCountry({text: data.country.name, value: data.country.id})
          }

          if (data.name && data.countryId) {
            await this.selectCountry({text: data.name, value: data.countryId})
          }


          this.regionId = (data.region && data.region.id) ? data.region.id : ""

          if (data.region && data.region.id) {
            await this.selectRegion(data.region.id)
          }

          this.districtId = (data.district && data.district.id) ? data.district.id : ""
          if (data.district && data.district.id) {
            await this.selectDistrict(data.district.id)
          }

          let subCountyNo = ""
          if (data.subcounty) {
            subCountyNo = (data.subcounty && data.subcounty.id) ? data.subcounty.id : "";
          } else {
            subCountyNo = (data.subCounty && data.subCounty.id) ? data.subCounty.id : "";
          }
          this.subCountyId = subCountyNo
          if (subCountyNo) {
            await this.selectSubCounty(subCountyNo)
          }

          this.parishId = (data.parish && data.parish.id) ? data.parish.id : ""

          if (data.parish && data.parish.id) {
            await this.selectParishData(data.parish.id)
          }

          this.villageId = (data.village && data.village.id) ? data.village.id : null
          this.form.villageId = (data.village && data.village.id) ? data.village.id : null
        }


      }
    }

  },
  watch: {
    villageDataInfo: {
      handler(data) {
        if (data) {
          this.checkAdminUnitInfo()
        }
      },
      deep: true
    },
    countryId() {
      if(this.countryId && this.countryId.text) {
        this.$emit("removePlotStreet", this.countryId.text);
      }
    },
    form: {
      handler(data) {
        if(data){
          this.$emit("residenceInfo", data);
        }

      },
      deep: true,
    },

  }


}
</script>

<style scoped>

</style>