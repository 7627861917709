<script>
import {api} from "@/app/services/api.service";
import AnnualReturnsMoreDetails from "@/views/home/services/registry/practitioners/AnnualReturnsMoreDetails.vue";


export default {
  name: "PractitionerAnnualReturnsDetails",
  components: {AnnualReturnsMoreDetails},
  props:{
    upn:String
  },
  data(){
    return{
      annulReturns:null,
      annualReturnsInfo:null,
      annualReturnsModal:false
    }
  },
  mounted() {
    this.fetchPractitionerAnnualReturnsInfo()
  },
  methods:{
    async fetchPractitionerAnnualReturnsInfo() {
      await api().get("insolvency/practitioner-annual-returns/by-upn?upn="+this.upn)
          .then((response) => {
            if(response && response.data){
              this.annulReturns = response.data.content
            }
          })
          .catch((error) => {
            if(error) {
              // this.loading = false;
            }
          });

    },
    showMoreAnnualReturnsDetails(annual){
     this.annualReturnsInfo = annual
      this.annualReturnsModal = true
    }
  }
}
</script>

<template>
<div>

  <b-modal v-model="annualReturnsModal" size="xl" hide-footer title="Annual Returns Details">
    <AnnualReturnsMoreDetails :annualReturnsInfo="annualReturnsInfo"></AnnualReturnsMoreDetails>
  </b-modal>


  <div style="width: 80%">
    <table  v-for="(annual, indx) in annulReturns" :key="'j'+indx" class="min-w-full leading-normal mb-1 text-gray border">
      <thead>

      <tr style="background-color: #f6f6f6;">
        <td colspan="12" class="px-2 py-2">
          <div class="flex justify-content-between">
            <div>
              <div>
                <span>Annual returns for the Year {{annual.year ? annual.year : ""}}</span>
                <span class="text-white px-3 rounded-md ml-4 text-sm" :class="annual.status === 'FILING' ? 'bg-green':'bg-red'">{{annual.status}}</span>
              </div>
              <div class="text-xs">
                <b>Name:</b> {{annual.name}}, <b>UPN:</b> {{annual.upn}}, <b>Start Date:</b> {{annual.startDate}}, <b>End Date:</b> {{annual.endDate}}
              </div>
            </div>

              <div>
                <button
                    type="button"
                    class="button-add-blue text-white text-xs noFocus"
                    @click="showMoreAnnualReturnsDetails(annual)">
                                 <span class="mx-3 py-0">
                                  <i class="fa fa-bars mr-1"></i> More Details
                                 </span>
                </button>
              </div>


          </div>
        </td>
      </tr>

      <!--    <tr>
            <td class="p-4">

              <b-row>
                <b-col cols="6">
                  <div class="mb-2">
                    <label class="text-gray text-xs">Name</label>
                    <b-form-group>
                      <b-form-input
                          style="color: #505050;"
                          id="companyName"
                          class="form-text noFocus text-xs"
                          type="text"
                          :disabled="true"
                          v-model="annual.name"
                          required>
                      </b-form-input>
                    </b-form-group>
                  </div>

                  <div class="mb-2">
                    <label class="text-gray text-xs">UPN</label>
                    <b-form-group>
                      <b-form-input
                          style="color: #505050;"
                          id="companyName"
                          class="form-text noFocus text-xs"
                          type="text"
                          :disabled="true"
                          v-model="annual.upn"
                          required>
                      </b-form-input>
                    </b-form-group>
                  </div>


                </b-col>

                <b-col cols="6">


                  <div class="mb-2">
                    <label class="text-gray text-xs">Status</label>
                    <b-form-group>
                      <b-form-input
                          style="color: #505050;"
                          id="companyName"
                          class="form-text noFocus text-xs"
                          type="text"
                          :disabled="true"
                          v-model="annual.status"
                          required>
                      </b-form-input>
                    </b-form-group>
                  </div>

                  <b-row>
                    <b-col cols="6">
                      <div class="mb-2">
                        <label class="text-gray text-xs">Start date</label>
                        <b-form-group>
                          <b-form-input
                              style="color: #505050;"
                              id="companyName"
                              class="form-text noFocus text-xs"
                              type="text"
                              :disabled="true"
                              v-model="annual.startDate"
                              required>
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div class="mb-2">
                        <label class="text-gray text-xs">End date</label>
                        <b-form-group>
                          <b-form-input
                              style="color: #505050;"
                              id="companyName"
                              class="form-text noFocus text-xs"
                              type="text"
                              :disabled="true"
                              v-model="annual.endDate"
                              required>
                          </b-form-input>
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>





                </b-col>
              </b-row>




            </td>
          </tr>-->

      </thead>
    </table>
  </div>




</div>
</template>

<style scoped>

</style>