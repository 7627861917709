<template>
  <div>
    <b-row v-if="task != null">
      <b-col cols="12" class="my-2">
        <b-row>
          <b-modal ref="entitledEntities" size="lg" title="Entitled Entities" hide-footer>
            <b-row>
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  </thead>
                  <tr>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1">Registration Number </th>
                      <th class="px-2 py-1">Email</th>
                      <th class="px-2 py-1">Phone</th>
                  </tr>
                  <tbody>
                  <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1 text-capitalize">{{ task.applicant.givenName }}</td>
                      <td class="px-2 py-1 text-capitalize">{{ task.applicant.nin }}</td>
                    <td class="px-2 py-1 text-capitalize">
                        {{ task.applicant.email }}
                    </td>
                      <td class="px-2 py-1 text-capitalize">
                          {{ task.applicant.phoneNumber }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-modal>
        </b-row>
        <b-row>

            <b-row>
                <b-col>
                    <b-badge class="bg-green mx-1">Incoming corrections <i class="fa fa-arrow-right text-white"></i></b-badge>
                    <span v-for="(correction,idx) in task.corrections" class="bg-blue text-white px-2 py-1 rounded-sm text-xs font-bold mx-1" :key="idx">#{{ cleanUnderscores(correction.type) }}</span>
                </b-col>
            </b-row>

          <b-col cols="12">
            <b-row>
              <b-col cols="12" class="mt-2">
                <b-row v-if="task.embeddedCorrections.NAME_CORRECTION !== undefined" >
                    <div>
                        <p class="h5 text-blue mt-2">NAME CORRECTION</p>
                        <hr/>
                    </div>
                  <b-col cols="6">



                    <table
                            v-if="task.embeddedCorrections.NAME_CORRECTION !== undefined"

                            class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                          <td colspan="12" class="px-2 py-2 font-bold">Current Name on file</td>

                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                          <td class="px-2 py-1 text-capitalize font-bold">
                              {{ task.embeddedCorrections.NAME_CORRECTION.data.registeredName }}
                          </td>

                      </tr>

                      </tbody>
                    </table>


                  </b-col>
                    <b-col cols="6">
                        <table
                                v-if="task.embeddedCorrections.NAME_CORRECTION !== undefined"

                                class="min-w-full leading-normal mt-1 text-gray"
                                style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Corrected Name</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="border: solid 1px #dedede;">

                                <td class="px-2 py-1 text-uppercase">
                                    {{ task.embeddedCorrections.NAME_CORRECTION.data.correctedName }}
                                </td>
                            </tr>

                            </tbody>
                        </table>


                    </b-col>

                </b-row>



                  <b-row v-if="task.embeddedCorrections.DIRECTORSHIP_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">DIRECTORSHIP CORRECTION</p>
                              <hr/>
                          </div>

                          <table
                                  class="min-w-full leading-normal mt-1 text-gray"
                                  style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                  <td class="px-2 py-2 font-bold">
                                      <span>Added Directors</span>
                                  </td>

                                  <td class="px-2 py-2 font-bold">
                                      <span>Removed Directors</span>
                                  </td>

                              </tr>


                              </thead>

                              <tbody>


                              <tr v-if="task.embeddedCorrections.DIRECTORSHIP_CORRECTION.data">
                                  <td>
                                      <table class="min-w-full leading-normal text-gray"
                                             style="border: solid 1px #dedede;" >
                                          <thead class="bg-gray-pale">
                                          <th>#</th>
                                          <th>Name</th>

                                          </thead>

                                          <tbody>
                                          <tr v-for="(tsk,idd) in task.embeddedCorrections.DIRECTORSHIP_CORRECTION.data.added"
                                              :key="idd" class="border border-blue">
                                              <td>{{ idd + 1 }}</td>

                                              <td>

                                                          <span style="text-transform: uppercase">
                                                                <LegalPersons class="p-1"
                                                                              :persons="[{...tsk}]"/>

                                                      </span>

                                              </td>

                                          </tr>
                                          </tbody>
                                      </table>
                                  </td>

                                  <td>


                                      <table class="min-w-full leading-normal text-gray"
                                             style="border: solid 1px #dedede;"  >
                                          <thead >
                                          <tr  class="bg-gray-pale">
                                              <th class="px-2 py-1 text-uppercase">#</th>
                                              <th class="px-2 py-1 text-uppercase">Name</th>
                                              <th class="px-2 py-1 text-uppercase">Type</th>
                                              <th class="px-2 py-1 text-uppercase">Number</th>
                                              <th class="px-2 py-1 text-center text-uppercase">Status</th>
                                          </tr>
                                          </thead>
                                          <thead >
                                          <tr v-for="(person,idx) in task.embeddedCorrections.DIRECTORSHIP_CORRECTION.data.deleted"
                                              :key="idx">
                                              <td class="px-2 py-1 font-bold  text-uppercase" ><span>{{ idx+1 }}</span></td>
                                              <td class="px-2 py-1 font-bold  text-uppercase" ><span>{{ person.name }}</span></td>
                                              <td class="px-2 py-1 font-bold text-green text-uppercase" ><span>{{ person.entityType }}</span></td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase" ><span>{{ person.entityType=== 'INDIVIDUAL' ? person.idNumber : person.brn }}</span></td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase" >
                                      <span>
                                           <b-badge class="bg-red mx-2"> Removed </b-badge>

                                      </span>



                                              </td>

                                          </tr>
                                          </thead>

                                      </table>
                                  </td>
                              </tr>

                              </tbody>
                          </table>

                      </b-col>
                  </b-row>

                  <b-row v-if="task.embeddedCorrections.PARTNERSHIP_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">PARTNERSHIP CORRECTION</p>
                              <hr/>
                          </div>

                          <table
                                  class="min-w-full leading-normal mt-1 text-gray"
                                  style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                  <td class="px-2 py-2 font-bold">
                                      <span>Added Partner</span>
                                  </td>

                                  <td class="px-2 py-2 font-bold">
                                      <span>Removed Partners</span>
                                  </td>

                              </tr>


                              </thead>

                              <tbody>


                              <tr v-if="task.embeddedCorrections.PARTNERSHIP_CORRECTION.data">
                                  <td>
                                      <table>
                                          <thead class="bg-gray-pale">
                                          <th>#</th>
                                          <th>Name</th>

                                          </thead>

                                          <tbody>
                                          <tr v-for="(tsk,idd) in task.embeddedCorrections.PARTNERSHIP_CORRECTION.data.added"
                                              :key="idd" class="border border-blue">
                                              <td>{{ idd + 1 }}</td>

                                              <td>

                                                          <span style="text-transform: uppercase">
                                                                <LegalPersons class="p-1"
                                                                              :persons="[{...tsk}]"/>

                                                      </span>

                                              </td>

                                          </tr>
                                          </tbody>
                                      </table>
                                  </td>

                                  <td>

                                      <table class="min-w-full leading-normal text-gray"
                                             style="border: solid 1px #dedede;"  >
                                          <thead >
                                          <tr  class="bg-gray-pale">
                                              <th class="px-2 py-1 text-uppercase">#</th>
                                              <th class="px-2 py-1 text-uppercase">Name</th>
                                              <th class="px-2 py-1 text-uppercase">Type</th>
                                              <th class="px-2 py-1 text-uppercase">Number</th>
                                              <th class="px-2 py-1 text-center text-uppercase">Status</th>
                                          </tr>
                                          </thead>
                                          <thead >
                                          <tr v-for="(person,idx) in task.embeddedCorrections.PARTNERSHIP_CORRECTION.data.deleted"
                                              :key="idx">
                                              <td class="px-2 py-1 font-bold  text-uppercase" ><span>{{ idx+1 }}</span></td>
                                              <td class="px-2 py-1 font-bold  text-uppercase" ><span>{{ person.name }}</span></td>
                                              <td class="px-2 py-1 font-bold text-green text-uppercase" ><span>{{ person.entityType }}</span></td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase" ><span>{{ person.entityType=== 'INDIVIDUAL' ? person.idNumber : person.brn }}</span></td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase" >
                                      <span>
                                           <b-badge class="bg-red mx-2"> Removed </b-badge>

                                      </span>



                                              </td>

                                          </tr>
                                          </thead>

                                      </table>
                                  </td>
                              </tr>

                              </tbody>
                          </table>

                      </b-col>
                  </b-row>


                  <b-row v-if="task.embeddedCorrections.SECRETARY_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">SECRETARY CORRECTION</p>
                              <hr/>
                          </div>

                          <table
                                  class="min-w-full leading-normal mt-1 text-gray"
                                  style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                  <td class="px-2 py-2 font-bold">
                                      <span>New   Secretary</span>
                                  </td>

                                  <td class="px-2 py-2 font-bold">
                                      <span>Old Secretary</span>
                                  </td>

                              </tr>


                              </thead>

                              <tbody>
                              <tr><td colspan="2">
                                  <b-row>

                                      <b-col cols="5">



                                          <b-row v-if="task.embeddedCorrections.SECRETARY_CORRECTION.data.addition"
                                                  >

                                              <LegalPersons class="p-1" :persons="[{...task.embeddedCorrections.SECRETARY_CORRECTION.data.addition}]"/>
                                          </b-row>

                                      </b-col>
                                      <b-col>
                                          <b-row
                                                 >

                                                          <span class="mx-2" style="text-transform: uppercase">
                                                          {{
                                                                  task.embeddedCorrections.SECRETARY_CORRECTION.data.deletion.name
                                                              }}   -      {{task.embeddedCorrections.SECRETARY_CORRECTION.data.deletion.entityType=== 'INDIVIDUAL' ? task.embeddedCorrections.SECRETARY_CORRECTION.data.deletion.idNumber : task.embeddedCorrections.SECRETARY_CORRECTION.data.deletion.brn}}
                                                      </span>

                                          </b-row>
                                      </b-col>
                                  </b-row>
                              </td></tr>
                              </tbody>
                          </table>

                      </b-col>
                  </b-row>

                  <b-row v-if="task.embeddedCorrections.MEMBERSHIP_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">MEMBERSHIP/SHAREHOLDING  CORRECTION</p>
                              <hr/>
                          </div>

                          <table
                                  class="min-w-full leading-normal mt-1 text-gray"
                                  style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                  <td class="px-2 py-2 font-bold">
                                      <span>Added Members</span>
                                  </td>

                                  <td class="px-2 py-2 font-bold">
                                      <span>Removed Members</span>
                                  </td>

                              </tr>


                              </thead>

                              <tbody>
                              <tr><td colspan="2">
                              <b-row>

<b-col cols="5">



                                          <b-row v-for="(tsk,idd) in task.embeddedCorrections.MEMBERSHIP_CORRECTION.data.added"
                                              :key="idd" >

 <LegalPersons class="p-1" :persons="[{...tsk}]"/>
                                          </b-row>

</b-col>
<b-col>


    <table class="min-w-full leading-normal text-gray"
           style="border: solid 1px #dedede;"  >
        <thead >
        <tr  class="bg-gray-pale">
            <th class="px-2 py-1 text-uppercase">#</th>
            <th class="px-2 py-1 text-uppercase">Name</th>
            <th class="px-2 py-1 text-uppercase">Type</th>
            <th class="px-2 py-1 text-uppercase">Number</th>
            <th class="px-2 py-1 text-center text-uppercase">Status</th>
        </tr>
        </thead>
        <thead >
        <tr v-for="(person,idx) in task.embeddedCorrections.MEMBERSHIP_CORRECTION.data.deleted"
            :key="idx">
            <td class="px-2 py-1 font-bold text-blue text-uppercase" ><span>{{ idx+1 }}</span></td>
            <td class="px-2 py-1 font-bold text-blue text-uppercase" ><span>{{ person.name }}</span></td>
            <td class="px-2 py-1 font-bold text-blue text-uppercase" ><span>{{ person.entityType }}</span></td>
            <td class="px-2 py-1 font-bold text-blue text-uppercase" ><span>{{ person.entityType=== 'INDIVIDUAL' ? person.idNumber : person.brn }}</span></td>
            <td class="px-2 py-1 font-bold text-blue text-uppercase" >
                                      <span>
                                           <b-badge class="bg-red mx-2"> Removed </b-badge>

                                      </span>



            </td>

        </tr>
        </thead>

    </table>

</b-col>
                              </b-row>
                              </td></tr>
                              </tbody>
                          </table>

                      </b-col>
                  </b-row>



                  <b-row  v-if="task.embeddedCorrections.KYC_CORRECTION !== undefined">
                      <b-col>
                          <div>
                    <p class="h5 text-blue mt-2">Particulars CORRECTION</p>
                              <hr/>
                          </div>

                          <table
                                  class="min-w-full leading-normal mt-1 text-gray"
                                  style="border: solid 1px #dedede;">
                              <thead>
                              <tr class="bg-gray-pale">
                                  <td colspan="12" class="px-2 py-2 font-bold">
                                      <b-row>
<!--                                          <b-col cols="2">Summary</b-col>-->
                                          <b-col cols="5">Current Details</b-col>
                                          <b-col cols="5">Corrected particulars</b-col>
                                      </b-row>
                                  </td>

                              </tr>



                              </thead>

                              <tbody>



                              <tr v-for="(pr,idx) in task.embeddedCorrections.KYC_CORRECTION.data.corrections" :key="idx">
                                 <td>
                                     <b-row>

                                         <b-col cols="4">
                                     <span v-if="pr.individual"><LegalPersons class="p-1" :persons="[{entityType:pr.entityType,position:pr.position,individual: pr.individual.registeredKYC}]"/></span>
                                     <span v-if="pr.corporate"><LegalPersons class="p-1" :persons="[{entityType:pr.entityType,position:pr.position,individual: pr.corporate.registeredKYC}]"/></span>
                                         </b-col>

                                         <b-col cols="8">

                                             <table>
                                                 <thead class="bg-gray-pale">
                                                 <th>#</th>
                                                 <th>Particular changed</th>
                                                 <th>Current value</th>
                                                 <th>Corrected value</th>
                                                 </thead>

                                                 <tbody>
                                                    <tr v-for="(tsk,idd) in pr.corrections" :key="idd" class="border border-blue">
                                                        <td>{{idd+1}}</td>
                                                        <td><span style="text-transform: capitalize">{{ cleanUnderscores(tsk.type) }}</span></td>
                                                        <td>{{ tsk.registered }}</td>
                                                        <td>{{ tsk.corrected }}</td>
                                                    </tr>
                                                 </tbody>
                                             </table>

                                         </b-col>

<!--                                         <b-col cols="5">-->
<!--                                       <span v-if="pr.individual"><LegalPersons class="p-1" :persons="[{entityType:pr.entityType,position:pr.position,individual: pr.individual.correctedKYC}]"/></span>-->
<!--                                      <span v-if="pr.corporate"><LegalPersons class="p-1" :persons="[{entityType:pr.entityType,position:pr.position,individual: pr.corporate.correctedKYC}]"/></span>-->
<!--                                         </b-col>-->
                                     </b-row>
                                  </td>
                              </tr>

                              </tbody>
                          </table>

                      </b-col>
                  </b-row>


                  <b-row  v-if="task.embeddedCorrections.DATE_OF_INCORPORATION_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">DATE OF INCORPORATION/REGISTRATION CORRECTION</p>

                          </div>
                          <table


                                  class="min-w-full leading-normal mt-1 text-gray"
                                  style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                          <td colspan="12" class="px-2 py-2 font-bold">Current Date</td>
                          <td></td>
                          <td colspan="12" class="px-2 py-2 font-bold">Corrected Date</td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #dedede;">
                          <td class="px-2 py-1 text-capitalize font-bold">
                              {{ task.embeddedCorrections.DATE_OF_INCORPORATION_CORRECTION.data.registeredDate }}
                          </td>
                          <td></td>
                          <td class="px-2 py-1 text-uppercase">
                              {{ task.embeddedCorrections.DATE_OF_INCORPORATION_CORRECTION.data.correctedDate }}
                          </td>
                      </tr>

                      </tbody>
                          </table>

                  </b-col>
                </b-row>


                  <b-row v-if="task.embeddedCorrections.CONTRIBUTION_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2"> MAXIMUM LIABILITY CONTRIBUTION CORRECTION</p>
                              <hr/>
                          </div>

                          <b-row v-if="task.embeddedCorrections.CONTRIBUTION_CORRECTION !== undefined">
                              <b-col cols="6">
                                  <table class="min-w-full leading-normal text-gray"
                                         style="border: solid 1px #dedede;">
                                      <thead>
                                      <tr class="bg-gray-pale">
                                          <th colspan="12" class="px-2 py-1 text-uppercase">Current contribution Summary
                                          </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Total contribution
                                          </th>
                                          <td class="font-bold t-body text-red">
                                              {{
                                                  task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.registeredTotalContribution.toLocaleString()
                                              }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Registered Partner Liability
                                          </th>
                                          <td class="font-bold t-body text-red">
                                              {{
                                                  task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.registeredPartnerLiability.toLocaleString()
                                              }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Working Total Contribution
                                          </th>
                                          <td class="font-bold t-body text-red">
                                              {{
                                                  task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.workingTotalContribution
                                              }}
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </b-col>

                              <b-col cols="6">
                                  <table class="min-w-full leading-normal text-gray"
                                         style="border: solid 1px #dedede;">
                                      <thead>
                                      <tr class="bg-gray-pale">
                                          <th colspan="12" class="px-2 py-1 text-uppercase">Corrected contribution
                                              Summary
                                          </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Total contribution
                                          </th>
                                          <td class="font-bold t-body text-green">
                                              {{
                                                  task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.correctedTotalContribution.toLocaleString()
                                              }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Registered Partner Liability
                                          </th>
                                          <td class="font-bold t-body text-green">
                                              {{
                                                  task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.correctedPartnerLiability.toLocaleString()
                                              }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Working Total Contribution
                                          </th>
                                          <td class="font-bold t-body text-green">
                                              {{
                                                  task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.workingTotalContribution
                                              }}
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </b-col>


                          </b-row>
                          <b-row>

                                  <b-col cols="6" class="mt-2">
                                      <b-row class="mt-4">
                                          <b-col>
                                              <p class="h5">Current contribution</p>
                                          </b-col>
                                      </b-row>
                                      <table class="min-w-full leading-normal text-gray"
                                             style="border: solid 1px #dedede;" >
                                          <thead >
                                          <tr class="bg-gray-pale">
                                              <th style="width: 25%" class="px-2 py-1 text-uppercase">Name</th>
                                              <th class="px-2 py-1 text-uppercase">Type</th>
                                              <th class="px-2 py-1 text-center float-start text-uppercase">Form Of Contribution</th>
                                              <th class="px-2 py-1 text-center text-uppercase">Monetary Contribution</th>
                                              <th class="px-2 py-1 text-center text-uppercase">Status</th>

                                          </tr>
                                          </thead>
                                          <thead v-for="(person, idx) in task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.contributions"
                                                 :key="idx" >
                                          <tr v-if="!person.newPartner" >
                                              <td style="width: 25%" class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <span>{{ person.name }}</span>
                                              </td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <b-badge class="bg-red mx-2">{{ person.entityType }}</b-badge>
                                              </td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <span>{{ person.registeredFormOfContribution }}</span>

                                              </td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <span>{{ person.registeredMonetaryContribution.toLocaleString() }}</span>

                                              </td>

                                              <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <b-badge class="bg-red mx-2" v-if="person.deleted">Removed</b-badge>
                                                  <b-badge class="bg-green mx-2" v-else>Stays</b-badge>

                                              </td>
                                          </tr>
                                          </thead>
                                      </table>
                                  </b-col>
                                  <b-col cols="6" class="mt-2">
                                      <b-row class="mt-4">
                                          <b-col>
                                              <p class="h5">Corrected contributions</p>
                                          </b-col>
                                      </b-row>
                                      <table class="min-w-full leading-normal text-gray"
                                             style="border: solid 1px #dedede;"
                                             >
                                          <thead>
                                          <tr class="bg-gray-pale">
                                              <th class="px-2 py-1 text-uppercase">Name</th>
                                              <th class="px-2 py-1 text-uppercase">Type</th>
                                              <th class="px-2 py-1 text-center float-start text-uppercase">Form Of Contribution</th>
                                              <th class="px-2 py-1 text-center text-uppercase">Monetary Contribution</th>
                                              <th class="px-2 py-1 text-center text-uppercase">Status</th>

                                          </tr>
                                          </thead>
                                          <thead>
                                          <tr v-for="(person, idx) in task.embeddedCorrections.CONTRIBUTION_CORRECTION.data.contributions" :key="idx" >
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase" style="width: 20%">
                                                  <span>{{ person.name }}</span>
                                              </td>  <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                              <b-badge class="bg-red mx-2">{{ person.entityType }}</b-badge>
                                              </td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <span>{{ person.correctedFormOfContribution }}</span>

                                              </td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <span>{{ person.correctedMonetaryContribution.toLocaleString() }}</span>

                                              </td>
                                              <td class="px-2 py-1 font-bold text-blue text-uppercase">
                                                  <b-badge class="bg-red mx-2" v-if="person.newPartner">New</b-badge>
                                                  <b-badge class="bg-green mx-2" v-else>Stayed</b-badge>

                                              </td>
                                          </tr>
                                          </thead>
                                      </table>
                                  </b-col>
                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.embeddedCorrections.SHARE_CAPITAL_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">SHARE CAPITAL CORRECTION</p>
                              <hr/>
                          </div>

                          <b-row v-if="task.embeddedCorrections.SHARE_CAPITAL_CORRECTION !== undefined">
                              <b-col cols="6">
                                  <table class="min-w-full leading-normal text-gray"
                                         style="border: solid 1px #dedede;">
                                      <thead>
                                      <tr class="bg-gray-pale">
                                          <th colspan="12" class="px-2 py-1 text-uppercase">Current Share capital Summary
                                          </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Share Capital
                                          </th>
                                          <td class="font-bold t-body text-red">
                                              {{
                                                  task.embeddedCorrections.SHARE_CAPITAL_CORRECTION.data.registeredShareCapital.toLocaleString()
                                              }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Currency
                                          </th>
                                          <td class="font-bold t-body text-red">
                                              {{
                                                  task.embeddedCorrections.SHARE_CAPITAL_CORRECTION.data.registeredCurrency
                                              }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Equivalence in UGX of
                                          </th>
                                          <td class="font-bold t-body text-red">
                                              {{
                                                  task.embeddedCorrections.SHARE_CAPITAL_CORRECTION.data.registeredShareCapitalUGXEquivalence ? task.embeddedCorrections.SHARE_CAPITAL_CORRECTION.data.registeredShareCapitalUGXEquivalence.toLocaleString() : ""
                                              }}
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </b-col>

                              <b-col cols="6">
                                  <table class="min-w-full leading-normal text-gray"
                                         style="border: solid 1px #dedede;">
                                      <thead>
                                      <tr class="bg-gray-pale">
                                          <th colspan="12" class="px-2 py-1 text-uppercase">Corrected Share capital
                                              Summary
                                          </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Share Capital
                                          </th>
                                          <td class="font-bold t-body text-green">
                                              {{
                                                  task.embeddedCorrections.SHARE_CAPITAL_CORRECTION.data.correctedShareCapital.toLocaleString()
                                              }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Currency
                                          </th>
                                          <td class="font-bold t-body text-green">
                                              {{ task.embeddedCorrections.SHARE_CAPITAL_CORRECTION.data.correctedCurrency }}
                                          </td>
                                      </tr>
                                      <tr style="border: solid 1px #dedede;">
                                          <th class="t-body text-uppercase">
                                              Equivalence in UGX of
                                          </th>
                                          <td class="font-bold t-body text-green">
                                              {{
                                                  task.embeddedCorrections.SHARE_CAPITAL_CORRECTION.data.correctedShareCapitalUGXEquivalence.toLocaleString()
                                              }}
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </b-col>


                          </b-row>
                      </b-col>
                  </b-row>

                  <b-row v-if="task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION !== undefined">
                      <b-col>

                          <div>
                              <p class="h5 text-blue my-2">SHARE CLASSIFICATION CORRECTION</p>
                              <hr/>
                          </div>
                          <b-row>
                              <b-col>

                                  <b-row>
                                      <b-col cols="6">
                                          <table class="min-w-full leading-normal text-gray"
                                                 style="border: solid 1px #dedede;">
                                              <thead>
                                              <tr class="bg-gray-pale">
                                                  <th colspan="12" class="px-2 py-1 text-uppercase">Current Shares Summary</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              <tr style="border: solid 1px #dedede;">
                                                  <th class="t-body text-uppercase">
                                                      Share Capital
                                                  </th>
                                                  <td class="font-bold t-body text-red">
                                                      {{ task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION.data.workingShareCapital.toLocaleString() }}
                                                  </td>
                                              </tr>
                                              <tr style="border: solid 1px #dedede;">
                                                  <th class="t-body text-uppercase">
                                                      Number of Shares
                                                  </th>
                                                  <td class="font-bold t-body text-red">
                                                      {{ task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION.data.registeredShareNumber.toLocaleString() }}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </b-col>


                                      <b-col cols="6">
                                          <table class="min-w-full leading-normal text-gray"
                                                 style="border: solid 1px #dedede;">
                                              <thead>
                                              <tr class="bg-gray-pale">
                                                  <th colspan="12" class="px-2 py-1 text-uppercase">Correction Shares Summary</th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              <tr style="border: solid 1px #dedede;">
                                                  <th class="t-body text-uppercase">
                                                      Share Capital
                                                  </th>
                                                  <td class="font-bold t-body text-green">
                                                      {{ task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION.data.workingShareCapital.toLocaleString() }}
                                                  </td>
                                              </tr>
                                              <tr style="border: solid 1px #dedede;">
                                                  <th class="t-body text-uppercase">
                                                      Number of Shares
                                                  </th>
                                                  <td class="font-bold t-body text-green">
                                                      {{ task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION.data.correctedShareNumber.toLocaleString() }}
                                                  </td>
                                              </tr>
                                              </tbody>
                                          </table>
                                      </b-col>

                                  </b-row>


<!--                                  <table class="min-w-full leading-normal text-gray my-2"-->
<!--                                         style="border: solid 1px #dedede;">-->
<!--                                      <thead>-->
<!--                                      <tr class="bg-gray-pale">-->
<!--                                          <th colspan="12" class="px-2 py-1 text-uppercase">Shares allocated</th>-->
<!--                                      </tr>-->
<!--                                      </thead>-->
<!--                                      <tbody>-->
<!--                                      <tr style="border: solid 1px #dedede;">-->
<!--                                          <th class="t-body text-uppercase">-->
<!--                                              Allotted Share Class-->
<!--                                          </th>-->
<!--                                          <td class="font-bold t-body">-->
<!--                                              Number of Shares-->
<!--                                          </td>-->
<!--                                      </tr>-->

<!--                                      <tr style="border: solid 1px #dedede;" v-for="(shr,idx) in task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION.data.shareCapitalBreakdown.numberOfShares" :key="idx" >-->
<!--                                          <th class="t-body text-uppercase">-->
<!--                                              {{shr.nominalClass}}-->
<!--                                          </th>-->
<!--                                          <td class="font-bold t-body">-->
<!--                                              <label class="text-blue"> {{ shr.value.toLocaleString() }} </label>-->
<!--                                          </td>-->
<!--                                      </tr>-->
<!--                                      </tbody>-->
<!--                                  </table>-->
                              </b-col>
                          </b-row>
                          <b-row>

                              <b-col>

                                  <b-row class="mt-4">
                                      <b-col>
                                          <p class="h5"> Classification Correction Details</p>
                                      </b-col>
                                  </b-row>
                                  <table class="min-w-full leading-normal text-gray"
                                         style="border: solid 1px #dedede;">
                                      <thead>
                                      <tr class="bg-gray-pale">
                                          <th class="px-2 py-1 text-uppercase">Nominal Class</th>
                                          <th class="px-2 py-1 text-center text-uppercase text-right">
                                              From <i class="fa fa-hand-point-right mx-4"></i> To
                                          </th>

                                      </tr>
                                      </thead>
                                      <tbody v-for="(cls, idx) in task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION.data.classifications"
                                             :key="idx">
                                      <tr>
                                          <td>
                                              <span class="mx-2">{{ cls.correctedNominalClass }}</span>
                                          </td>

                                          <td>
                                              <table width="100%">
                                                  <tr style="border: solid 1px #dedede;"
                                                      v-for="(clsx, idxx) in cls.changes" :key="idxx">
                                                      <td class="px-2 py-1 font-bold">
                                                          <span class="text-uppercase"
                                                          >{{ cleanUnderscores(clsx.type) }}</span>
                                                      </td>
                                                      <td class="px-2 py-1 text-center font-bold">
                                              <span class="text-red ">
                                                  {{ clsx.registered }}
                                              </span>
                                                      </td>
                                                      <td class="px-2 py-1 text-center">
                                                            <span class="text-green">
                                                                {{ clsx.corrected }}
                                                            </span>
                                                      </td>
                                                  </tr>
                                              </table>
                                          </td>

                                      </tr>

                                      <!--                              <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses"
                                                                        :key="idx">
                                                                        <td class="px-2 py-1 text-center">
                                                                            Class <span
                                                                                class="text-uppercase">{{ subClass.nominalClass.toLowerCase() }}</span>
                                                                            <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                                                                                  style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                                                                        </td>
                                                                        <td class="px-2 py-1 text-center">
                                                                            <span v-if="subClass.number">{{ subClass.number.toLocaleString() }}</span>
                                                                        </td>
                                                                        <td class="px-2 py-1 text-center">
                                                                            <span v-if="subClass.value">{{ subClass.value.toLocaleString() }}</span>
                                                                        </td>
                                                                    </tr>-->
                                      </tbody>
                                  </table>

                              </b-col>

                              <b-col>

                                  <b-row class="mt-4">
                                      <b-col>
                                          <p class="h5">Corrected Share Classification</p>
                                      </b-col>
                                  </b-row>
                                  <table class="min-w-full leading-normal text-gray"
                                         style="border: solid 1px #dedede;">
                                      <thead>
                                      <tr class="bg-gray-pale">
                                          <th class="px-2 py-1 text-uppercase">Nominal Class</th>
                                          <th class="px-2 py-1 text-center text-uppercase">Shares</th>
                                          <th class="px-2 py-1 text-center text-uppercase">Value</th>
                                      </tr>
                                      </thead>
                                      <tbody v-for="(cls, idx) in task.embeddedCorrections.SHARE_CLASSIFICATION_CORRECTION.data.classifications"
                                             :key="idx">
                                      <tr style="border: solid 1px #dedede;">
                                          <td class="px-2 py-1 font-bold">
                                                            <span class="text-uppercase"
                                                                  v-if="cls.correctedNominalClass !== 'OTHER'">{{
                                                                    cls.correctedNominalClass.toLowerCase()
                                                                }}</span>
                                              <span class="text-uppercase"
                                                    v-else>{{ cls.correctedOtherClassName.toLowerCase() }}</span>
                                          </td>
                                          <td class="px-2 py-1 text-center font-bold">
                                              <span v-if="cls.correctedNumber">{{
                                                      cls.correctedNumber.toLocaleString()
                                                  }}</span>
                                          </td>
                                          <td class="px-2 py-1 text-center">
                                                            <span v-if="cls.correctedValue && (!cls.correctedSubClasses)">{{
                                                                    cls.correctedValue.toLocaleString()
                                                                }}</span>
                                              <span v-else>NIL</span>
                                          </td>
                                      </tr>
                                      <!--                              <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses"
                                                                        :key="idx">
                                                                        <td class="px-2 py-1 text-center">
                                                                            Class <span
                                                                                class="text-uppercase">{{ subClass.nominalClass.toLowerCase() }}</span>
                                                                            <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                                                                                  style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                                                                        </td>
                                                                        <td class="px-2 py-1 text-center">
                                                                            <span v-if="subClass.number">{{ subClass.number.toLocaleString() }}</span>
                                                                        </td>
                                                                        <td class="px-2 py-1 text-center">
                                                                            <span v-if="subClass.value">{{ subClass.value.toLocaleString() }}</span>
                                                                        </td>
                                                                    </tr>-->
                                      </tbody>
                                  </table>

                              </b-col>
                          </b-row>

                      </b-col>
                  </b-row>


                  <b-row v-if="task.embeddedCorrections.SHAREHOLDING_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">SHAREHOLDING CORRECTION</p>
                              <hr/>
                          </div>
                  <b-row v-if="task.embeddedCorrections.SHAREHOLDING_CORRECTION !== undefined">
                      <b-col cols="6" class="mt-2">
                          <b-row class="mt-4">
                              <b-col>
                                  <p class="h5">Current Shareholders</p>
                              </b-col>
                          </b-row>
                          <table class="min-w-full leading-normal text-gray"
                                 style="border: solid 1px #dedede;" v-for="(person, idx) in task.embeddedCorrections.SHAREHOLDING_CORRECTION.data.shareholdings"
                                 :key="idx" >
                              <thead v-if="!person.isNewPosition">
                              <tr  class="bg-gray-pale">
                                  <th style="width: 50%" class="px-2 py-1 text-uppercase">Name</th>
                                  <th style="width: 35%" class="px-2 py-1 text-uppercase">Type</th>
<!--                                  <th class="px-2 py-1 text-center float-start text-uppercase">Share Class</th>-->
<!--                                  <th class="px-2 py-1 text-center text-uppercase">Number of Shares</th>-->
<!--                                  <th class="px-2 py-1 text-center text-uppercase">Paid</th>-->
<!--                                  <th class="px-2 py-1 text-center text-uppercase">Paid</th>-->
                                  <th style="width: 15%" class="px-2 py-1 text-center text-uppercase">Status</th>
                              </tr>
                              </thead>
                              <thead v-if="!person.isNewPosition">
                              <tr>
                                  <td style="width: 50%" class="px-2 py-1 font-bold text-blue text-uppercase" >
                                      <span>
                                          {{ person.name }}


                                      </span>


                                  </td>
                                  <td style="width: 35%" class="px-2 py-1 font-bold text-blue text-uppercase" >
                                      <span>
                                           <b-badge class="bg-red mx-2">{{ person.registeredOwnerType }}</b-badge>

                                      </span>



                                  </td>
                                  <td style="width: 15%">
                                      <b-badge class="bg-red mx-2" v-if="person.isDeletedPosition">Removed</b-badge>
                                      <b-badge class="bg-green mx-2" v-else>Stays</b-badge>
                                  </td>
                              </tr>
                              </thead>
<!--                              <tbody  style="border: solid 1px #dedede;" v-for="(share, idx2) in person.shares"-->
<!--                                     :key="idx2">-->
<!--                              <tr v-if="!person.isNewPosition">-->
<!--                                  <td class="px-2 py-1"></td>-->
<!--                                  <td class="px-2 py-1 text-uppercase font-bold">-->
<!--                                      <span v-if="share.nominalClass !== 'OTHER'">{{ share.nominalClass }}</span>-->
<!--                                      <span v-else>{{ share.otherClassName }}</span>-->
<!--                                  </td>-->
<!--                                  <td class="px-2 py-1 text-center">-->
<!--                <span v-if="!share.subClasses || !person.isNewPosition">-->
<!--                  {{ share.registeredNumberOfShares.toLocaleString() }}-->
<!--                </span>-->
<!--                                  </td>-->
<!--                                  <td class="px-2 py-1 text-center">-->
<!--                <span v-if="!share.subClasses">-->
<!--                  {{ share.registeredNumberOfPaidShares.toLocaleString() }}-->
<!--                </span>-->
<!--                                  </td>-->
<!--                                  <td class="px-2 py-1 text-center">-->
<!--                <span v-if="!share.subClasses">-->
<!--                  {{ share.registeredNumberOfUnPaidShares.toLocaleString() }}-->
<!--                </span>-->
<!--                                  </td>-->
<!--                                  <td></td>-->
<!--                              </tr >-->
<!--                              <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">-->
<!--                                  <td class="px-2 py-1"></td>-->
<!--                                  <td class="px-2 py-1 text-capitalize">-->
<!--                                      <span>Class {{ subClass.nominalClass }}</span>-->
<!--                                  </td>-->
<!--                                  <td class="px-2 py-1 text-center">-->
<!--                <span>-->
<!--                  {{ subClass.registeredNumberOfShares.toLocaleString() }}-->
<!--                </span>-->
<!--                                  </td>-->
<!--                                  <td class="px-2 py-1 text-center">-->
<!--                <span>-->
<!--                  {{ subClass.registeredNumberOfPaidShares.toLocaleString() }}-->
<!--                </span>-->
<!--                                  </td>-->
<!--                                  <td class="px-2 py-1 text-center">-->
<!--                <span>-->
<!--                  {{ subClass.registeredNumberOfUnPaidShares.toLocaleString() }}-->
<!--                </span>-->
<!--                                  </td>-->
<!--                              </tr>-->
<!--                              </tbody>-->
                          </table>
                      </b-col>
                      <b-col cols="6" class="mt-2">
                          <b-row class="mt-4">
                              <b-col>
                                  <p class="h5">Corrected Share Holders</p>
                              </b-col>
                          </b-row>
                          <table class="min-w-full leading-normal text-gray"
                                 style="border: solid 1px #dedede;" v-for="(person, idx) in task.embeddedCorrections.SHAREHOLDING_CORRECTION.data.shareholdings"
                                 :key="idx">
                              <thead>
                              <tr v-if="!person.isDeletedPosition" class="bg-gray-pale">
                                  <th class="px-2 py-1 text-uppercase">Name</th>
                                  <th class="px-2 py-1 text-center float-start text-uppercase">Share Class</th>
                                  <th class="px-2 py-1 text-center text-uppercase">Number of Shares</th>
                                  <th class="px-2 py-1 text-center text-uppercase">Paid</th>
                                  <th class="px-2 py-1 text-center text-uppercase">Not Paid</th>
                              </tr>
                              </thead>
                              <thead v-if="!person.isDeletedPosition" >
                              <tr>
                                  <td class="px-2 py-1 font-bold text-blue text-uppercase" colspan="12">
                                      <span>{{ person.name }} </span>


                                      <b-badge class="bg-green mx-2">{{ person.correctedOwnerType }}</b-badge>

                                  </td>
                              </tr>
                              </thead>
                              <tbody style="border: solid 1px #dedede;" v-for="(share, idx2) in person.shares"
                                     :key="idx2">
                              <tr>
                                  <td class="px-2 py-1"></td>
                                  <td class="px-2 py-1 text-uppercase font-bold">
                                      <span v-if="share.nominalClass !== 'OTHER'">{{ share.nominalClass }}</span>
                                      <span v-else>{{ share.otherClassName }}</span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                <span v-if="!share.subClasses" class="text-green">
                  {{ share.correctedNumberOfShares.toLocaleString() }}
                </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                <span v-if="!share.subClasses" class="text-green">
                  {{ share.correctedNumberOfPaidShares.toLocaleString() }}
                </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                <span v-if="!share.subClasses" class="text-green">
                  {{ share.correctedNumberOfUnPaidShares.toLocaleString() }}
                </span>
                                  </td>
                              </tr>
                              <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                                  <td class="px-2 py-1"></td>
                                  <td class="px-2 py-1 text-capitalize">
                                      <span class="text-green">Class {{ subClass.nominalClass }}</span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                <span class="text-green">
                  {{ subClass.correctedNumberOfShares.toLocaleString() }}
                </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                <span class="text-green">
                  {{ subClass.correctedNumberOfPaidShares.toLocaleString() }}
                </span>
                                  </td>
                                  <td class="px-2 py-1 text-center">
                <span class="text-green">
                  {{ subClass.correctedNumberOfUnPaidShares.toLocaleString() }}
                </span>
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </b-col>
                  </b-row>
                          </b-col>
                    </b-row>


                  <b-row v-if="task.embeddedCorrections.ADDRESS_CORRECTION !== undefined">
                      <b-col>
                          <div>
                              <p class="h5 text-blue mt-2">ADDRESS CORRECTION</p>
                              <hr/>
                          </div>
                <b-row class="mt-2"
                       v-if="task.embeddedCorrections.ADDRESS_CORRECTION !== undefined"
                >
                    <b-col cols="6">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Address Correction </td>
                      </tr>
                      </thead>
                      <tbody>

                      <tr style="border: solid 1px #dedede;" class="bg-gray-pale">
                        <td class="px-2 py-1 text-capitalize px-2 py-2 font-bold" colspan="12">Address In Uganda</td>
                      </tr>
                      <b-row class="mx-1 py-1">
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Region"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus"
                                type="text"
                                :disabled="true"
                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredPhysicalAddress.region.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="District"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredPhysicalAddress.district.name.toLowerCase()"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Sub County"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredPhysicalAddress.subcounty.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Parish"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus"
                                type="text"
                                :disabled="true"
                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredPhysicalAddress.parish.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Village"
                              label-for="input-1">
                            <b-form-input
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredPhysicalAddress.village.name"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Street"
                              label-for="input-1">
                            <b-form-input
                                    v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredAddress"
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                    :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredAddress.street"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Plot"
                              label-for="input-1">
                            <b-form-input
                                    v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredAddress"
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                    :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredAddress.plot"
                                required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                              class="text-sm my-1"
                              id="input-group-1"
                              label="Postal Address"
                              label-for="input-1">
                            <b-form-input
                                    v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredAddress"
                                id="companyName"
                                class="form-text text-sm noFocus text-capitalize"
                                type="text"
                                :disabled="true"
                                    :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.registeredAddress.postalAddress"
                                required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      </tbody>
                    </table>
                  </b-col>

                    <b-col cols="6">
                        <table class="min-w-full leading-normal mt-1 text-gray"
                               style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                                <td colspan="12" class="px-2 py-2 font-bold">Address Correction</td>
                            </tr>
                            </thead>
                            <tbody>
                            <!--                      <tr style="border: solid 1px #dedede;">
                                                    <td class="px-2 py-1 text-capitalize font-bold">Country Of Incorporation</td>
                                                    <td class="px-2 py-1">{{task.form26.countryOfIncorporation}}</td>
                                                  </tr>-->
                            <!--                      <tr style="border: solid 1px #dedede;">-->
                            <!--                        <td class="px-2 py-1 text-capitalize font-bold">Address In Parent Country</td>-->
                            <!--                        <td class="px-2 py-1">{{task.form26.addressInCountryOfIncorporation}}</td>-->
                            <!--                      </tr>-->
                            <tr style="border: solid 1px #dedede;" class="bg-gray-pale">
                                <td class="px-2 py-1 text-capitalize px-2 py-2 font-bold" colspan="12">Corrected Address
                                    In Uganda
                                </td>
                            </tr>
                            <b-row class="mx-1 py-1">
                                <b-col v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedPhysicalAddress">
                                    <b-form-group

                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="Region"
                                            label-for="input-1">
                                        <b-form-input
                                                id="companyName"
                                                class="form-text text-sm noFocus"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedPhysicalAddress.region.name"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="District"
                                            label-for="input-1">
                                        <b-form-input
                                                id="companyName"
                                                class="form-text text-sm noFocus text-capitalize"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedPhysicalAddress.district.name.toLowerCase()"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="Sub County"
                                            label-for="input-1">
                                        <b-form-input
                                                id="companyName"
                                                class="form-text text-sm noFocus text-capitalize"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedPhysicalAddress.subcounty.name"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                </b-col >
                                <b-col v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedPhysicalAddress">
                                    <b-form-group
                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="Parish"
                                            label-for="input-1">
                                        <b-form-input
                                                id="companyName"
                                                class="form-text text-sm noFocus"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedPhysicalAddress.parish.name"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="Village"
                                            label-for="input-1">
                                        <b-form-input
                                                id="companyName"
                                                class="form-text text-sm noFocus text-capitalize"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedPhysicalAddress.village.name"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="Street"
                                            label-for="input-1">
                                        <b-form-input
                                                v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedAddress"
                                                id="companyName"
                                                class="form-text text-sm noFocus text-capitalize"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedAddress.street"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                </b-col   >
                                <b-col v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedAddress" >
                                    <b-form-group
                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="Plot"
                                            label-for="input-1">
                                        <b-form-input
                                                v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedAddress"
                                                id="companyName"
                                                class="form-text text-sm noFocus text-capitalize"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedAddress.plot"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                            class="text-sm my-1"
                                            id="input-group-1"
                                            label="Postal Address"
                                            label-for="input-1">
                                        <b-form-input
                                                v-if="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedAddress"
                                                id="companyName"
                                                class="form-text text-sm noFocus text-capitalize"
                                                type="text"
                                                :disabled="true"
                                                :value="task.embeddedCorrections.ADDRESS_CORRECTION.data.correctedAddress.postalAddress"
                                                required>
                                        </b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                      </b-col>
                  </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-blue-dark"
                   style="border: solid 1px #bdd7f1;">
              <thead>
              <tr class="bg-blue-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Representative Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Email</th>
                <th class="px-2 py-1">Phone</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #bdd7f1;" v-if="task.representative.individual">
                  <td class="px-2 py-1 text-capitalize">{{ task.representative.individual.givenName }}</td>
                      <td class="px-2 py-1 text-capitalize">{{ task.representative.individual.surname }}

                </td>
                      <td class="px-2 py-1">{{ task.representative.individual.email }}</td>
                      <td class="px-2 py-1">{{ task.representative.individual.phone }}</td>
              </tr>
              <tr style="border: solid 1px #bdd7f1;" v-else>
                <td class="px-2 py-1">
                    {{ task.representative.corporate.registeredName }}
                </td>
                <td class="px-2 py-1">
                    {{ task.representative.corporate.phone }}
                </td>
                <td class="px-2 py-1">
                    {{ task.representative.corporate.email }}

                </td>
              </tr>
              </tbody>
            </table>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import LegalPersons from "@/views/home/commons/LegalPersons.vue";

export default {
  name: "DataCorrectionForms",
    components: { LegalPersons,
    },
  props:{
    task:Object
  },
  data(){
    return{
      attachmentUrl:null,
      individualDirectors:[],
      corporateDirectors:[],
      individualSecretary:null,
      corporateSecretary:null,
      adoptionTableText:null,
      entitledEntities:[]
    }
  },
  mounted() {

  },
  methods:{
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },

  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>