import { render, staticRenderFns } from "./CompanyReinstatementDetails.vue?vue&type=template&id=1c191668&scoped=true&"
import script from "./CompanyReinstatementDetails.vue?vue&type=script&lang=js&"
export * from "./CompanyReinstatementDetails.vue?vue&type=script&lang=js&"
import style0 from "./CompanyReinstatementDetails.vue?vue&type=style&index=0&id=1c191668&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c191668",
  null
  
)

export default component.exports