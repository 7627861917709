<template>
  <b-row class="mx-1 -mt-1">
    <b-col cols="2" class="mt-1">
      <b-row v-for="(section, idx) in sections" :key="idx" class="my-1">
        <b-col :class="[(currentRoute === section.route) ? 'pill-menu-item-active' : 'pill-menu-item']"
          @click="select(section.route)">
          <span>{{ section.title }}</span>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="10" class="border border-gray my-2">
      <AnnualReturnsActivity v-if="currentRoute === 'annual-returns'" />
      <CompanyIncorporationActivity v-if="currentRoute === 'co-incorporation'" />
      <ContinuousFilingActivity v-if="currentRoute === 'cf-filings'" />
      <DispensationActivity v-if="currentRoute === 'dispensation'" />
      <ReservationActivity v-if="currentRoute === 'reservations'" />
      <BusinessNameRegistrationActivity v-if="currentRoute === 'bn-registrations'" />
      <BuildingSocietyActivity v-if="currentRoute === 'bsi-registrations'" />
      <AmendmentsActivity v-if="currentRoute === 'amendments'" />
      <LegalDocumentsActivity v-if="currentRoute === 'legal-documents'" />
    </b-col>
  </b-row>
</template>

<script>
import ReservationActivity from "@/views/home/services/reports/activity/ReservationActivity"
import AnnualReturnsActivity from "@/views/home/services/reports/activity/AnnualReturnsActivity"
import CompanyIncorporationActivity from "@/views/home/services/reports/activity/CompanyIncorporationActivity"
import ContinuousFilingActivity from "@/views/home/services/reports/activity/ContinuousFilingActivity"
import BusinessNameRegistrationActivity from "@/views/home/services/reports/activity/BusinessNameRegistrationActivity"
import BuildingSocietyActivity from "@/views/home/services/reports/activity/BuildingSocietyActivity"
import AmendmentsActivity from "@/views/home/services/reports/activity/AmendmentsActivity"
import LegalDocumentsActivity from "@/views/home/services/reports/activity/LegalDocumentsActivity"
import DispensationActivity from "@/views/home/services/reports/activity/DispensationActivity"
export default {
  name: "ActivityReport",
  components: { AnnualReturnsActivity, LegalDocumentsActivity, DispensationActivity, AmendmentsActivity, BuildingSocietyActivity, BusinessNameRegistrationActivity, ContinuousFilingActivity, CompanyIncorporationActivity, ReservationActivity },
  data() {
    return {
      currentRoute: "reservations",
      sections: [
        {
          route: "co-incorporation",
          title: "Incorporation"
        },
        {
          route: "cf-filings",
          title: "Continuous Filing"
        },
        {
          route: "annual-returns",
          title: "Annual Returns"
        },
        {
          route: "dispensation",
          title: "Dispensation"
        },
        // {
        //   route: "reservations",
        //   title: "Reservations"
        // },
        // {
        //   route: "bn-registrations",
        //   title: "Registrations"
        // },
        // {
        //   route: "bsi-registrations",
        //   title: "Building Society"
        // },
        // {
        //   route: "amendments",
        //   title: "Amendments"
        // },
        // {
        //   route: "legal-documents",
        //   title: "Legal Documents"
        // },
      ]
    }
  },
  mounted() {
    this.currentRoute = "co-incorporation"
  },
  methods: {
    select(route) {
      this.currentRoute = route
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";
</style>