<template>
  <b-row>
    <b-col cols="12" class="overflow-hidden mt-2">
      <router-view></router-view>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "CompanyDataUpdateTab"
}
</script>

<style scoped>

.height-full{
  height: 100vh;
}
</style>