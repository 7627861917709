import {api} from "@/app/services/api.service"

const IP = "";

const state = {
    rolesList: null,
    permissionList: null,
    rolesAndPermissionList: [
        {
            "uid": "d76e451e-5084-498b-8bba-842747beb8e9",
            "name": "Overall Administrator",
            "description": "Direct And Supervisor",
            "user_uid": "6fce78a6-50f6-4898-98cc-d3ba02ffada3",
            "rolePermissions": [{
                "id": 47,
                "permission": "payments-receive-mm",
                "role_uid": "d76e451e-5084-498b-8bba-842747beb8e9",
                "value": "Yes"
            }, {
                "id": 37,
                "permission": "payments-receive",
                "role_uid": "d76e451e-5084-498b-8bba-842747beb8e9",
                "value": "Yes"
            }]
        }
    ],
    rolesAndPermissionEdit: null,
    rolesAndPermissionUpdate: null,
    revokeRoll: null,
    rolesError: null,
};

const getters = {
    getRolesList: state => state.rolesList,
    getNewRolesAndPermission: state => state.newRolesAndPermission,
    getPermissionList: state => state.permissionList,
    getRolesAndPermissionEdit: state => state.rolesAndPermissionEdit,
    getRolesAndPermissionUpdate: state => state.rolesAndPermissionUpdate,
    getRevokeRoll: state => state.revokeRoll,
    getRolesError: state => state.rolesError,
};

const actions = {
    async saveNewRole({commit}, data) {
        await api()
            .post(IP + "auth/api/roles", data)
            .then(response => {
                commit("setNewRoles", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },
    async updateRoleAndPermission({commit}, data) {
        let role_uid = data.role_uid
        let formData = data.formData
        await api()
            .post(IP + "edit-role?role_uid=" + role_uid, formData)
            .then(response => {
                commit("setUpdateRoleAndPermissions", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },
    async fetchPermissions({commit}) {
        await api()
            .get("auth/api/permissions")
            .then(response => {
                commit("setPermissionList", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    }, async fetchRoles({commit}) {
        await api()
            .get(IP + "auth/api/roles")
            .then(response => {
                commit("setRolesList", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },

    async fetchRoleDataEdit({commit}, data) {
        await api()
            .get(IP + "role?role_uid=" + data)
            .then(response => {
                commit("setRolesAndPermissionEdit", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },

    async doRevertRole({commit}, data) {
        await api()
            .post(IP + "revoke-role?role_uid=" + data.role_uid + "&user_uid=" + data.user_uid)
            .then(response => {
                commit("setRevokeRole", response);
            })
            .catch(error => {
                commit("setRolesError", error);
            });
    },


};

const mutations = {
    setPermissionList: (state, response) => {
        state.permissionList = response.data;
    },
    setNewRoleAndPermissions: (state, response) => {
        state.newRolesAndPermission = response.data;
    },
    setRolesList: (state, response) => {
        state.rolesList = response.data;
    },
    setRolesAndPermissionEdit: (state, response) => {
        state.rolesAndPermissionEdit = response.data;
    },
    setUpdateRoleAndPermissions: (state, response) => {
        state.rolesAndPermissionUpdate = response.data;
    },
    setRevokeRole: (state, response) => {
        state.revokeRoll = response.data;
    },
    setRolesError: (state, error) => {
        state.rolesError = error.response.data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
