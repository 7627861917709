<template>
  <div>
    <b-row>
      <b-col cols="12" class="mt-1 mb-3">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2">Nature of Business</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">ISIC Code</th>
                    <th class="px-2 py-1">Breakdown</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">{{reservation.isic.code}}</td>
                    <td class="px-2 py-1">
                      <ul class="text-blue">
                        <li v-for="(section, idx) in reservation.isic.breakdown"  :key="idx">
                          <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mt-4">
                <table class="min-w-full leading-normal mt-1 text-blue-dark" style="border: solid 1px #bdd7f1;">
                  <thead>
                    <tr class="bg-blue-pale">
                      <td colspan="12" class="px-2 py-2">Applicant Details</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1">Name</th>
                      <th class="px-2 py-1">Email</th>
                      <th class="px-2 py-1">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #bdd7f1;">
                    <td class="px-2 py-1 text-capitalize">{{reservation.user.fullName.toLowerCase()}}</td>
                    <td class="px-2 py-1">{{reservation.user.email}}</td>
                    <td class="px-2 py-1">{{reservation.user.phoneNumber}}</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "NameReservationFormAppeal",
  props:{reservation:{}},
  data(){
    return{
      selectedId:null,
      margin:"2px",
      hasContent:false,
      loading:true,
      message:null,
      form:{
        description:null,
        reservationId:null,
        status:null
      }
    }
  },
  methods:{
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getAnalysisReport", "getAnalysisReportError"]),
  },
  watch:{

  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>