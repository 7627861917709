<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-col>
        <b-row>
          <b-modal title="Create Request" ref="createRequest" hide-footer size="lg">
            <b-form>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Registration Number"
                label-for="input-1">
                <b-form-input
                  class="form-text text-sm noFocus"
                  type="text"
                  :value="form.brn"
                  required>
                </b-form-input>
              </b-form-group>
              <b-row v-if="form.brn">
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Entity Name"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="form.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Entity Type"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="form.type"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Entity Sub Type"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="form.subType"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Registration"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled
                      :value="form.dateOfRegistration"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                class="text-sm my-1"
                id="input-group-1"
                label="Reason/Description for Correction"
                label-for="input-1">
                <b-form-textarea
                  class="form-text text-sm noFocus"
                  type="text"
                  rows="6"
                  :value="form.description"
                  required>
                </b-form-textarea>
              </b-form-group>
              <button class="float-end bg-blue text-white rounded-sm px-2 py-1 mt-2" @click="saveRequest">
                <i class="fa fa-save mx-1"></i>Save Request
              </button>
            </b-form>
          </b-modal>

          <b-modal title="Data Correction Request" ref="correctionRequest" hide-footer size="xl">
            <DataCorrectionRequest :tracking-number="trackingNumber"/>
          </b-modal>
          <b-modal title="Data Correction" ref="dataCorrections" hide-footer size="xl">

          </b-modal>
        </b-row>
        <b-row>
          <b-col cols="12" class="mx-3 mt-3">
            <b-row>
              <span class="title">Data Correction Requests</span>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="flex mx-3 mt-3">
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
            <div class="pre-waiting">
              <span class="h3 mx-auto">0</span>
            </div>
            <div class="pre-waiting">
              <span class="text-sm mx-auto">Drafts</span>
            </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
            <div class="waiting">
              <span class="h3 mx-auto">0</span>
            </div>
            <div class="waiting">
              <span class="text-sm mx-auto">Pending</span>
            </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
            <div class="warning">
              <span class="h3 mx-auto">0</span>
            </div>
            <div class="warning">
              <span class="text-sm mx-auto">Queried</span>
            </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
            <div class="positive">
              <span class="h3 mx-auto">0</span>
            </div>
            <div class="positive">
              <span class="text-sm mx-auto">Approved</span>
            </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
            <div class="all">
              <span class="h3 mx-auto">0</span>
            </div>
            <div class="all">
              <span class="text-sm mx-auto">Total</span>
            </div>
          </div>
        </b-row>
        <b-row class="flex">
          <b-col cols="12" class="pt-3">
            <b-row class="mx-auto">
              <b-col cols="3">
                <b-form-input
                  class="text-gray text-sm noFocus my-2"
                  style="padding:6px 5px;"
                  v-model="search"
                  type="text"
                  placeholder="Search by Tracking No."
                  required
                  v-on:keyup.enter="fetch">
                </b-form-input>
              </b-col>
              <b-col cols="2">
                <b-form-group>
                  <b-form-select
                    v-model="statuses"
                    v-on:change="fetch"
                    class="form-select noFocus text-gray my-2 text-sm">
                    <b-select-option :value="null">--Filter by Status--</b-select-option>
                    <b-select-option value="DRAFT">Draft</b-select-option>
                    <b-select-option value="PENDING">Pending</b-select-option>
                    <b-select-option value="QUERIED">Queried</b-select-option>
                    <b-select-option value="APPROVED">Approved</b-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <button class="bg-blue text-white rounded-sm px-2 py-1 mt-2" @click="create"><i class="fa fa-plus mx-1"></i>Create a Request</button>
              </b-col>
              <b-col cols="2"></b-col>
              <b-col cols="1" class="text-center">
                <b-form-select v-model="limit" class="w-full text-xs form-select mt-2 paginate-details" v-on:change="fetch">
                  <b-select-option value="12">12</b-select-option>
                  <b-select-option value="20">20</b-select-option>
                  <b-select-option value="50">50</b-select-option>
                  <b-select-option value="100">100</b-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="2">
                <b-row>
                  <b-col class="mx-auto mt-3">
                    <p class="paginate-details text-center text-xs font-bold">
                      <span>{{((currentPage - 1) * limit) + 1}}</span> -
                      <span v-if="limit > submissions.length ">{{pagination.totalElements}}</span>
                      <span v-else>{{currentPage * limit}}</span>
                      of {{pagination.totalElements}}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="mx-auto">
              <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
            </div>
            <b-row >
              <b-col cols="12">
                <table class="min-w-full leading-normal">
                  <thead>
                  <tr class="border-b border-t border-grey">
                    <th class="t-header">#</th>
                    <th class="t-header">Tracking No.</th>
                    <th class="t-header">BRN</th>
                    <th class="t-header">Name</th>
                    <th class="t-header">Entity Type</th>
                    <th class="t-header">Sub Entity Type</th>
                    <th class="t-header text-center">Status</th>
                    <th class="t-header text-center">Applicant</th>
                    <th class="t-header text-center">Action</th>
                    <th class="t-header text-center">Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
                    <td class="t-body">{{(currentPage - 1) * limit + idx + 1}}</td>
                    <td class="t-body text-blue font-bold cursor-pointer hover:underline" @click="view(submission)">{{submission.trackingNumber}}</td>
                    <td class="t-body font-bold">{{submission.brn}}</td>
                    <td class="t-body font-bold">{{submission.name}}</td>
                    <td class="t-body text-capitalize">{{cleanEntityType(submission.type)}}</td>
                    <td class="t-body text-capitalize">{{cleanEntityType(submission.subType)}}</td>
                    <td class="t-body">
                      <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                        <span :class="
                        (submission.status.toUpperCase() === 'DRAFT') ? 'text-gray-light' :
                        (submission.status.toUpperCase() === 'PENDING') ? 'text-gray' :
                        (submission.status.toUpperCase() === 'APPROVED') ? 'text-green' :
                        (submission.status.toUpperCase() === 'QUERIED') ? 'text-orange' : ''">
                          {{ submission.status.toLowerCase() }}
                        </span>
                      </p>
                    </td>
                    <td class="t-body  text-center">
                      <p class="text-xs px-2 py-1 text-capitalize text-center">
                        <span >
                          {{submission.applicant.fullName}}
                        </span>
                      </p>
                    </td>
                    <td class="t-body  text-center">
                      <button
                        v-if="submission.status.toUpperCase() === 'APPROVED'"
                        class="bg-blue text-white px-2 py-1 rounded-sm" @click="correctionPrompt(submission)">
                        <i class="fa fa-pencil-ruler mx-1"></i> File Corrections
                      </button>
                      <span v-else>No Action</span>
                    </td>
                    <td class="t-body  text-center">{{dateTimeFormat(submission.createdAt)}}</td>
                  </tr>
                  <tr>
                    <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                      <span class="text-sm">No results found</span>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                      <p class="text-sm">Failed to load tasks, Try again later.</p>
                      <p class="text-sm">{{errorMessage}}</p>
                      <p class="text-sm">
                        <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload <b-spinner v-if="loading" small></b-spinner></button>
                      </p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row class="mx-1" v-if="pagination.totalPages > 1">
              <b-col cols="1">
                <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
              </b-col>
              <b-col cols="1">
                <b-row>
                  <b-col cols="12">
                    <p class="paginate-details text-center">
                      <b-badge class="bg-blue text-white">
                        {{currentPage}}/{{pagination.totalPages}}
                      </b-badge>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="1" >
                <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
              </b-col>
              <b-col cols="6"></b-col>
              <b-col cols="1">
                <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details" v-on:change="fetch">
                  <b-select-option value="12">12</b-select-option>
                  <b-select-option value="20">20</b-select-option>
                  <b-select-option value="50">50</b-select-option>
                  <b-select-option value="100">100</b-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="2">
                <b-row>
                  <b-col class="mx-auto">
                    <p class="paginate-details text-center text-xs font-bold">
                      <span>{{((currentPage - 1) * limit) + 1}}</span> -
                      <span v-if="limit > submissions.length">{{pagination.totalElements}}</span>
                      <span v-else>{{currentPage * limit}}</span>
                      of {{pagination.totalElements}}
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import DataCorrectionRequest from "@/views/home/services/data-correction/components/DataCorrectionRequest.vue";

export default {
  name: "DataCorrectionRequests",
  components: { DataCorrectionRequest },
  data(){
    return{
      loading:false,
      trackingNumber:null,
      currentPage:1,
      limit:12,
      submissions:[
        {
          trackingNumber:"G230416-0001",
          name:"ABC - SMC LIMITED",
          brn: "80012341230918",
          type: "company",
          subType: "single-member",
          dateOfRegistration: "10-12-1998",
          description: "wrong name entered",
          applicant:{
            fullName:"John Doe"
          },
          status:"PENDING",
          createdAt:"2023-04-16T20:04:12Z"
        },
        {
          trackingNumber:"G230416-0001",
          name:"MUKOKO - SMC LIMITED",
          brn: "80012341230915",
          type: "company",
          subType: "single-member",
          dateOfRegistration: "10-12-1998",
          description: "wrong name entered",
          applicant:{
            fullName:"John Doe"
          },
          status:"APPROVED",
          createdAt:"2023-04-16T20:04:12Z"
        }
      ],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      form:{
        name:null,
        brn:null,
        type:null,
        subType:null,
        dateOfRegistration:null,
        description:null
      },
      statuses:null,
      taskId:null,
      level:null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
      search:"",
      hasError:false,
      errorMessage:null,
      application:{},
    }
  },
  methods:{
    fetch(){

    },
    view(data){
      this.trackingNumber = data.trackingNumber
      this.$refs.correctionRequest.show()
      console.log(data)
    },
    create(){
      this.$refs.createRequest.show()
    },
    saveRequest(){

    },
    correctionPrompt(data){
      this.$refs.dataCorrections.show()
      console.log(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true
    }
  },

};
</script>

<style scoped>
.pre-waiting{
  @apply flex text-gray_title;
}

.off-normal{
  @apply flex text-blue-light;
}

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.all{
  @apply flex text-flopurple;
}

.stat-count{
  display: inline;
  width: 12%;
  @apply my-1;
}
</style>