<template>
  <div>
    <b-row class="my-3">
      <b-col cols="4">
        <b-row v-if="getRegistrationTask != null">
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Attachments</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">#</th>
                    <th class="px-2 py-1">File Name</th>
                    <th class="px-2 py-1 float-end">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(file, idx) in getRegistrationTask.attachments" :key="idx" style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">{{idx + 1}}</td>
                    <td class="px-2 py-1 text-capitalize">{{file.fileDescription}}</td>
                    <td class="px-2 py-1">
                      <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8">
        <BusinessNameAttachments ref="attachmentScreen"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import server from "@/app/env/server.json";
import BusinessNameAttachments from "@/views/home/services/registration/task/BusinessNameAttachments";

export default {
  name: "BusinessNameFormFiles",
  components: {BusinessNameAttachments},
  data(){
    return{
      attachmentUrl:null
    }
  },
  mounted() {
    this.openFile(this.getRegistrationTask.attachments[0])
  },
  methods:{
    ...mapActions(["fetchAddressByVillageId"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    openFile(data){
      this.attachmentUrl = server.IP+"/business-registration/attachment/preview?file-name="+data.fileName
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },
  computed:{
    ...mapGetters(["getRegistrationTask", "getAddress"])
  },
  watch:{
    getRegistrationTask(data){
      let file = data.attachments[0]
      this.openFile(file)
    },
    attachmentUrl:{
      handler(url){
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    },
  }
}
</script>

<style scoped>

th, td{
  font-size: 12px;
}

</style>