<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Change of Share Capital
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Name Change Details</td>
                </tr>
                </thead>
                <tr>
                  <th class="px-2 py-1">Current Share Capital</th>
                  <th class="px-2 py-1">New Share Capital</th>
                  <th class="px-2 py-1">Purpose</th>
                </tr>
                <tbody>
                <tr style="border: solid 1px #dedede;">
                  <td class="px-2 py-1 text-capitalize">{{data.CHANGE_SHARE_CAPITAL.data.oldShareCapital.toLocaleString()}}</td>
                  <td class="px-2 py-1 text-capitalize">{{data.CHANGE_SHARE_CAPITAL.data.shareCapital.toLocaleString()}}</td>
                  <td class="px-2 py-1 text-capitalize">{{data.CHANGE_SHARE_CAPITAL.data.purpose}}</td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-2" v-if="data.CHANGE_SHARE_CAPITAL.data.changeValueOfShares">
            <b-col>
              <p class="font-bold text-sm">Change in Value of Shares</p>
              <b-row>
                <b-col>
                  <p class="my-2 text-sm">Number of Shares: {{data.RE_VALUE.data.currentNumberOfShares}}</p>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <th colspan="12" class="px-2 py-1 text-uppercase">CURRENT CLASSIFICATION</th>
                    </tr>
                    <tr class="border-b border-gray-light">
                      <th class="px-2 py-1 text-uppercase">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-uppercase">Shares</th>
                      <th class="px-2 py-1 text-center text-uppercase">Value(UGX)</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.RE_VALUE.data.current" :key="idx">
                    <tr style="border: solid 1px #dedede;" >
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center font-bold">
                        <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <th colspan="12" class="px-2 py-1 text-uppercase">UPDATED CLASSIFICATION</th>
                    </tr>
                    <tr class="border-b border-gray-light">
                      <th class="px-2 py-1 text-uppercase">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-uppercase">Shares</th>
                      <th class="px-2 py-1 text-center text-uppercase">Value(UGX)</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.RE_VALUE.data.classifications" :key="idx">
                    <tr style="border: solid 1px #dedede;" >
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center font-bold">
                        <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-2"  v-if="data.CHANGE_SHARE_CAPITAL.data.changeNumberOfShares">
            <b-col>
              <p class="font-bold text-sm">Change in Number of Shares</p>
              <b-row>
                <b-col>
                  <p class="my-2 text-sm">Current Number of Shares: {{data.INCREASE_NUMBER_OF_SHARES.data.currentNumberOfShares}}</p>
                  <p class="my-2 text-sm">New Number of Shares: {{data.INCREASE_NUMBER_OF_SHARES.data.newNumberOfShares}}</p>

                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <th colspan="12" class="px-2 py-1 text-uppercase">CURRENT CLASSIFICATION</th>
                    </tr>
                    <tr class="border-b border-gray-light">
                      <th class="px-2 py-1 text-uppercase">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-uppercase">Shares</th>
                      <th class="px-2 py-1 text-center text-uppercase">Value(UGX)</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.INCREASE_NUMBER_OF_SHARES.data.current" :key="idx">
                    <tr style="border: solid 1px #dedede;" >
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center font-bold">
                        <span v-if="cls.number">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value && cls.subClasses.length === 0">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="min-w-full leading-normal text-gray"
                         style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <th colspan="12" class="px-2 py-1 text-uppercase">UPDATED CLASSIFICATION</th>
                    </tr>
                    <tr class="border-b border-gray-light">
                      <th class="px-2 py-1 text-uppercase">Nominal Class</th>
                      <th class="px-2 py-1 text-center text-uppercase">Initial Shares</th>
                      <th class="px-2 py-1 text-center text-uppercase">Shares</th>
                      <th class="px-2 py-1 text-center text-uppercase">Initial Value</th>
                      <th class="px-2 py-1 text-center text-uppercase">Value</th>
                    </tr>
                    </thead>
                    <tbody v-for="(cls, idx) in data.INCREASE_NUMBER_OF_SHARES.data.classifications" :key="idx">
                    <tr style="border: solid 1px #dedede;" >
                      <td class="px-2 py-1 font-bold">
                        <span class="text-uppercase" v-if="cls.nominalClass !== 'OTHER'">{{cls.nominalClass.toLowerCase()}}</span>
                        <span class="text-uppercase" v-else>{{cls.otherClassName.toLowerCase()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.initialNumber != null">{{cls.initialNumber.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.number != null">{{cls.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.initialValue != null && cls.subClasses === null">{{cls.initialValue.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="cls.value != null && cls.subClasses === null">{{cls.value.toLocaleString()}}</span>
                        <span v-else>NIL</span>
                      </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;" v-for="(subClass, idx) in cls.subClasses" :key="idx">
                      <td class="px-2 py-1 text-center">
                        Class  <span class="text-uppercase">{{subClass.nominalClass.toLowerCase()}}</span>
                        <span class="font-monospace text-blue bg-blue-pale px-2 rounded mx-2 text-xs"
                              style="margin-top: 2px; margin-bottom: 2px;">sub-class</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.number">{{subClass.number.toLocaleString()}}</span>
                      </td>
                      <td class="px-2 py-1 text-center">
                        <span v-if="subClass.value">{{subClass.value.toLocaleString()}}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ChangeOfShareCapital",
  props:{
    data:Object
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>