<template>
  <div class="overflow-hidden bg-gray p-2">
    <b-row class="my-2">
      <b-col cols="12">
        <iframe class="pdf" :src="url+'#toolbar=0'">
        </iframe>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "BuildingSocietyAttachmentsEscalated",
  data(){
    return{
      loading:false,
      url: "http://www.punchtronic.gr/pdf.js-pages/web/viewer.html?file=/RetailImages/Catalogs/null.pdf"
    }
  },
  methods:{
    openFile(url){
      this.url = url
    }
  },
}
</script>

<style scoped>
.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>