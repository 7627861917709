import { render, staticRenderFns } from "./ChangeOfAddressForeign.vue?vue&type=template&id=6759850a&scoped=true&"
import script from "./ChangeOfAddressForeign.vue?vue&type=script&lang=js&"
export * from "./ChangeOfAddressForeign.vue?vue&type=script&lang=js&"
import style0 from "./ChangeOfAddressForeign.vue?vue&type=style&index=0&id=6759850a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6759850a",
  null
  
)

export default component.exports