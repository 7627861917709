<template>
  <b-col cols="8" class="mx-auto">
    <b-row>
      <b-col>
        <div class="w-full shadow-md px-3 py-5 border text-center" style="background-color: #ffffff;">
          <b-row>
            <b-col>
              <span class="h1 text-blue">OBRS Backoffice</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <i class="fa fa-user-lock text-lg text-gray"></i>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col>
              <b-alert :show="errorMessage != null" variant="danger" >
                <i class="fa fa-times-circle"></i>
                <span class="text-sm">
                  {{ errorMessage }}
                </span>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-center">
                <p class="text-gray lg:mx-auto" style="color: #909090;">
                  ---- Set Your Password----<br />
                  You can now enter your new password below.
                </p>
              </div>
              <form @submit.prevent="setPasswordAction" class="mt-8">
                <div class="rounded-md shadow-sm -space-y-px">
                  <div style="color: #909090;" class="flex flex-wrap border border-grey items-stretch w-full relative  bg-white items-center rounded mb-1 ">
                    <div class="flex justify-center w-10 h-9">
                      <span
                          class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray-600">
                        <i class="fas fa-key"></i>
                      </span>
                    </div>
                    <input
                        v-model="form.newPassword"
                        :type="show1 ? 'text' : 'password'"
                        required="required"
                        :class="loginTextInput"
                        placeholder="New Password"/>

                    <div class="flex  justify-center w-10 h-9">
                      <span
                          class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray-600">
                        <i :class="show1 ? 'fas fa-eye' : 'fas fa-eye-slash'" @click="show1 = !show1"
                           style="cursor: pointer"></i>
                      </span>
                    </div>
                  </div>
                  <div style="color: #909090;" class="flex flex-wrap border border-grey items-stretch w-full relative  bg-white items-center rounded mb-1 ">
                    <div class="flex justify-center w-10 h-9">
                      <span
                          class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray-600">
                        <i class="fas fa-key"></i>
                      </span>
                    </div>
                    <input
                        v-model="form.confirmPassword"
                        :type="show1 ? 'text' : 'password'"
                        required="required"
                        :class="loginTextInput"
                        placeholder="Confirm Password"/>

                    <div class="flex  justify-center w-10 h-9">
                      <span
                          class="flex items-center leading-normal bg-white px-2 border-0 rounded-r-none text-gray-600">
                        <i :class="show1 ? 'fas fa-eye' : 'fas fa-eye-slash'" @click="show1 = !show1"
                           style="cursor: pointer"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                      class="noFocus w-full mt-3 justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-dark"
                      type="submit">
                    Set Password
                    <b-spinner v-if="loading" small class="ml-2"></b-spinner>
                  </button>
                </div>
              </form>

              <div class="mt-4 max-w-2xl text-gray text-sm lg:mx-auto text-center text-gray">
                Already have an account?
                <router-link
                    style="color: #242f7e"
                    class="text-decoration-none ml-3"
                    :to="{ name: 'login' }">
                  Login
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import router from "@/router";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ConfirmPassword",
  data() {
    return {
      form: {
        confirmPassword: null,
        newPassword: null,
        passwordToken: null,
      },
      show1: false,
      hasError: false,
      errorMessage: null,
      loading: false,
      loginTextInput:"flex-shrink flex-grow flex-auto leading-normal h-6  flex-1 self-center relative  font-roboto sm:text-sm outline-none",
    }
  },
  mounted() {
    this.form.passwordToken = this.$route.query.token;
    if(this.form.passwordToken == null){
      router.push({name:"login"})
    }
  },
  methods:{
    ...mapActions(["changePassword"]),
    setPasswordAction(){
      this.loading = true
      this.changePassword(this.form)
    }
  },
  computed:{
    ...mapGetters(
        [
          "getNewPassword",
          "getLoginError"
        ]
    )
  },
  watch:{
    getNewPassword(data){
      if(data){
        this.successToast("Success", data.message)
        sessionStorage.clear()
        router.push({name:'success-confirm-password'})
      }
    },
    getLoginError(error){
      if(error){
        this.errorToast("Error", error.message)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>