<template>
  <div class="overflow-hidden p-2">
    <b-row class="my-2">
      <b-col cols="4">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Attachments</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">#</th>
                    <th class="px-2 py-1">File Name</th>
                    <th class="px-2 py-1 float-end">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(file, idx) in application.data.attachments" :key="idx" style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">{{idx + 1}}</td>
                    <td class="px-2 py-1 text-capitalize">{{cleanName(file.purpose)}}</td>
                    <td class="px-2 py-1">
                      <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8">
        <DisputeResolutionAttachments v-if="application.data.attachments.length > 0" ref="attachmentScreen" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DisputeResolutionAttachments
    from "@/views/home/services/dispute-resolution/task/DisputeResolutionAttachments.vue";
import moment from "moment/moment";
import server from "@/app/env/server.json";

export default {
  name: "DisputeResolutionFilesDetails",
  components: { DisputeResolutionAttachments },
  props:{
    application:Object
  },
  data(){
    return{
      attachmentUrl:null
    }
  },
  mounted() {
    this.openFile(this.application.data.attachments[0])
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    openFile(data){
      let fName = data ? data.fileName.trim() : ""
      this.attachmentUrl = server.IP+"/dispute-resolution/disputes/downloads?attachment="+fName
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },
  computed:{
    ...mapGetters(["getDisputeResolutionTask"]),
  },
  watch:{
    attachmentUrl:{
      handler(url){
        this.$refs.attachmentScreen.openFile(url)
      },
      deep:true
    }
  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>