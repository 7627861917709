<template>
    <div class="bg-white rounded-sm mx-2 my-3">

        <b-row>
            <b-modal title="Correct Name of Entity" ref="correctNameDialog" size="md" hide-footer>
                <ChangeName :old-value="entity.name" :brn="brn" v-on:done="closeDialogs"></ChangeName>
            </b-modal>
        </b-row>

        <b-row>
            <b-modal title="Correct Date of Registration Entity" ref="correctDateOfRegistrationDialog" size="md" hide-footer>
                <ChangeDateOfRegistration :old-value="dateFormat(entity.registrationDate)" :brn="brn" v-on:done="closeDialogs"></ChangeDateOfRegistration>
            </b-modal>
        </b-row>

        <b-row>
            <b-col cols="12" class="mx-3 mt-3">
                <b-row>
                    <b-col>
                        <span class="title">Registry File</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="mx-3 mt-2">
            <b-col cols="12" class="mx-auto bg-header_grey py-3 rounded">
                <span class="cursor-pointer text-blue hover:text-blue-dark hover:underline" @click="back">Back</span>
                <span class="mx-3 text-gray">Business / {{ brn }}</span>
            </b-col>
        </b-row>
        <b-row class="mx-3">
            <b-col cols="12" class="mx-auto my-3 shadow-sm rounded pt-2 pb-4 border border-blue">
                <b-row>
                    <b-col>
                        <b-row v-if="entity">
                            <b-col cols="12" class="mt-3">
                                <span class="font-bold text-blue h5">{{ entity.brn }}</span><br/>
                                <span class="font-bold text-gray h3">{{ entity.name }}  </span><br/>
                                <div class="flex mt-1">
                                    <b-badge class="bg-blue text-capitalize">
                                        {{ cleanEntityType(type) }}
                                    </b-badge>
                                    <b-badge class="mx-1 bg-green text-capitalize">
                                        {{ cleanEntityType(entity.subType) }}
                                    </b-badge>
                                </div>
                                <span class="italic text-gray text-xs font-bold" v-if="entity.registrationDate">Registration Date: {{ dateFormat(entity.registrationDate) }}

                </span><br/>
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col cols="12" class="my-3 blink_me">
                                <b-badge class="bg-gray_loading text-gray_loading my-1 w-2/12">name</b-badge>
                                <br/>
                                <b-badge class="bg-gray_loading text-gray_loading py-3 w-4/12">name</b-badge>
                                <div class="flex mt-1">
                                    <b-badge class="bg-gray_loading">
                                        loading...
                                    </b-badge>
                                    <b-badge class="mx-1 bg-gray_loading">
                                        loading...
                                    </b-badge>
                                </div>
                                <b-badge class="bg-gray_loading my-1 text-gray_loading w-6/12">name</b-badge>
                                <br/>
                            </b-col>
                        </b-row>
                        <b-row v-if="entity">
                            <b-col>
                                <CompanyFile :company="entity"
                                             v-if="type === 'company' && !entity.subType.includes('foreign')"/>
                                <ForeignCompanyFile :company="entity"
                                                    v-if="type ===  'company' && entity.subType.includes('foreign')"/>
                                <BusinessNameFile :business="entity" v-if="type === 'business'"/>
                                <BuildingSocietyFile :society="entity" v-if="type === 'building-society'"/>
                            </b-col>
                        </b-row>
                        <b-row v-else>
                            <b-col class="bg-gray_loading py-5 blink_me">

                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import CompanyFile from "@/views/home/services/registry/business/files/CompanyFile";
import BusinessNameFile from "@/views/home/services/registry/business/files/BusinessNameFile";
import BuildingSocietyFile from "@/views/home/services/registry/business/files/BuildingSocietyFile";
import ForeignCompanyFile from "@/views/home/services/registry/business/files/ForeignCompanyFile";
import router from "@/router";
import ChangeName from "@/views/home/services/registry/corrections/ChangeName.vue";
import ChangeDateOfRegistration from "@/views/home/services/registry/corrections/ChangeDateOfRegistration.vue";

export default {
    name: "EntityFileWrapper",
    components: {
        ChangeDateOfRegistration,
        ChangeName, ForeignCompanyFile, BuildingSocietyFile, BusinessNameFile, CompanyFile
    },
    data() {
        return {
            brn: null,
            type: "",
            loading: false,
            entity: null
        }
    },
    mounted() {
        this.brn = this.$route.params.id
        this.entity = JSON.parse(localStorage.getItem("registryEntity"))
        this.type = this.entity.type
    },
    methods: {
        cleanEntityType(name) {
            if (name) {
                let n = name.replaceAll("-", " ")
                if (name === "business") {
                    n += " Name"
                }
                return n.charAt(0).toUpperCase() + n.slice(1)
            }
            return ""
        },
        back() {
            router.push({name: "business-registry"})
        },
        openCorrectNameDialog() {
            this.$refs.correctNameDialog.show();
        },
        openCorrectDatOfRegistrationDialog() {
            this.$refs.correctDateOfRegistrationDialog.show();
        },
        closeDialogs(data) {
            console.log(data)
            this.successToast("Success", "Record updated successfully")
            this.$refs.correctNameDialog.hide()
            this.$refs.correctDateOfRegistrationDialog.hide()
           router.push({name:"business-registry"})
        }
    },
}
</script>

<style scoped>
.blink_me {
    animation: blinker 3s linear infinite;
}

@keyframes blinker {
    80% {
        opacity: 0.3;
    }
}
</style>