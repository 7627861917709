<template>
  <div>
    <b-row>
      <b-col>
        <b-card :header="badge">
          <b-row class="my-3">
            <b-col cols="12">
              <b-form-group
                id="input-group-1"
                label="Effective Date of Appointment"
                label-for="street">
                <b-form-input
                  id="street"
                  class="form-text mb-3 noFocus"
                  type="text"
                  v-model="form.effectiveDateOfAppointment"
                  :disabled="true"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span class="h6">PARTICULARS OF SECRETARY</span>
              <b-row>
                <b-col cols="12" v-if="data.entityType === 'CORPORATE'">
                  <b-row>
                    <b-col>
                      <b-badge class="bg-blue">corporate</b-badge>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Entity Name"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredName"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Registration Number (BRN)"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.brn"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Official Email Address"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.primaryEmail"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Official Phone Number"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.primaryPhone"
                              required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Date of Incorporation"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.dateOfIncorporation"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Company Type"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus text-capitalize"
                              type="text"
                              disabled="true"
                              :value="cleanEntityType(data.corporate.subType)"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Country of Origin"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.countryOfOrigin"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Address in Country of Origin"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.addressInCountryOfIncorporation"
                              required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group
                            v-if="data.corporate.registeredPhysicalAddress.district"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="District"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredPhysicalAddress.district.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.corporate.registeredPhysicalAddress.region"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Region"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredPhysicalAddress.region.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.corporate.registeredPhysicalAddress.subcounty"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Sub county"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredPhysicalAddress.subcounty.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.corporate.registeredPhysicalAddress.parish"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Parish"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredPhysicalAddress.parish.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            v-if="data.corporate.registeredPhysicalAddress.village"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Village"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredPhysicalAddress.village.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.corporate.registeredAddress"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Postal Address"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredAddress.postalAddress"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.corporate.registeredAddress"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Plot"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredAddress.plot"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.corporate.registeredAddress"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Street"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.corporate.registeredAddress.street"
                              required>
                            </b-form-input>
                          </b-form-group>

                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" v-if="data.entityType === 'INDIVIDUAL'">
                  <b-row>
                    <b-col>
                      <b-badge class="bg-orange">individual</b-badge>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Given Name"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.givenName"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.individual.middleName"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Middle Name"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.middleName"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Surname"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.surName"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="ID Type"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus text-capitalize"
                              type="text"
                              disabled="true"
                              :value="data.individual.idType.toLowerCase()"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="ID Number"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.idNumber"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Nationality"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.nationality"
                              required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Email Address"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.email"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Phone Number"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.phoneNumber"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Gender (at birth)"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus text-capitalize"
                              type="text"
                              disabled="true"
                              :value="data.individual.sex.toLowerCase()"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Date of Birth"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="dateFormat(data.individual.dateOfBirth)"
                              required>
                            </b-form-input>
                            <b-form-invalid-feedback state="true">
                              {{data.individual.age}}
                            </b-form-invalid-feedback>
                          </b-form-group>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Occupation"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.occupation"
                              required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="data.individual.address.physicalAddress">
                          <b-form-group
                            v-if="data.individual.address.physicalAddress.district"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="District"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.physicalAddress.district.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.individual.address.physicalAddress.district"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Region"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.physicalAddress.region.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.individual.address.physicalAddress.subcounty"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Sub county"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.physicalAddress.subcounty.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.individual.address.physicalAddress.parish"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Parish"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.physicalAddress.parish.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.individual.address.physicalAddress.village"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Village"
                            label-for="input-1">
                            <b-form-input
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.physicalAddress.village.name"
                              required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col v-else>
                          <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Foreign Address"
                            label-for="input-1">
                            <b-form-textarea
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              rows="4"
                              disabled="true"
                              :value="data.individual.foreignPhysicalAddress"
                              required>
                            </b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            v-if="data.individual.address.postalAddress"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Postal Address"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.postalAddress"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.individual.address.street"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Street"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.street"
                              required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group
                            v-if="data.individual.address.plot"
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Plot"
                            label-for="input-1">
                            <b-form-input
                              id="companyName"
                              class="form-text text-sm noFocus"
                              type="text"
                              disabled="true"
                              :value="data.individual.address.plot"
                              required>
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";

export default {
  name: "Form20SecretaryResolution",
  props:{
    data:{},
    badge:String
  },
  data(){
    return{
      form:{
        effectiveDateOfAppointment:null
      }
    }
  },
  mounted() {
    this.form.effectiveDateOfAppointment =  this.dateFormat(this.data.effectiveDateOfAppointment)
  },
  methods:{
    ...mapActions(["fetchCompanySecretary"]),
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
  computed:{
    ...mapGetters(["getCompanySecretary"])
  },
  watch:{

  }
}
</script>

<style scoped>

</style>