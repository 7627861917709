<template>
  <div>
    <b-row>
      <b-modal id="edmsDocument" size="lg" hide-footer ref="edmsDocument" title="EDMS Document">
        <EdmsIdToDocument :edms-id="selectedEdmsId"></EdmsIdToDocument>
      </b-modal>
    </b-row>
    <b-row v-if="getLegalDocumentTask != null">
      <b-col>
        <b-row class="mt-3">
          <b-col>
            <b-form-group
              class="text-sm my-1"
              id="input-group-1"
              label="Subject Matter"
              label-for="input-1">
              <b-form-textarea
                id="companyName"
                class="form-text text-sm noFocus text-capitalize"
                type="text"
                disabled="true"
                :value="getLegalDocumentTask.subjectMatter"
                required>
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group
              class="text-sm my-1"
              id="input-group-1"
              label="Origin Account Type"
              label-for="input-1">
              <b-form-input
                id="companyName"
                v-if="getLegalDocumentTask.user"
                class="form-text text-sm noFocus text-capitalize font-bold"
                type="text"
                disabled="true"
                :value="getLegalDocumentTask.user.accountType+ ' ACCOUNT'"
                required>
              </b-form-input>
              <b-form-input
                id="companyName"
                v-else
                class="form-text text-sm noFocus text-capitalize font-bold"
                type="text"
                disabled="true"
                value="Not Indicated"
                required>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              class="text-sm my-1"
              id="input-group-1"
              label="Date of Execution"
              label-for="input-1">
              <b-form-input
                id="companyName"
                class="form-text text-sm noFocus text-capitalize"
                type="text"
                disabled="true"
                :value="getLegalDocumentTask.filingDate"
                required>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="getLegalDocumentTask.documentType === 'AGREEMENT' || getLegalDocumentTask.documentType === 'CONSTITUTION' || getLegalDocumentTask.documentType === 'MINUTES' || getLegalDocumentTask.documentType === 'OTHERS' || getLegalDocumentTask.documentType === 'RESOLUTION_AS_A_DOCUMENT'">
          <b-col cols="12" class="mt-4">
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Parties</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualParties"
                                     :corporates="getLegalDocumentTask.entityParties" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'GUARANTEE'">
          <b-col cols="12">
            <b-row class="mt-3">
              <b-col>
                <span class="h5">Parties</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualParties"
                                     :corporates="getLegalDocumentTask.entityParties" />
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <span class="h5">Beneficiary</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getArray(getLegalDocumentTask.individualBeneficiary)"
                                     :corporates="getArray(getLegalDocumentTask.entityBeneficiary)" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row
          v-if="getLegalDocumentTask.documentType === 'AFFIDAVIT' || getLegalDocumentTask.documentType === 'STATUTORY_DECLARATION'"
          class="mt-4">
          <b-col cols="12">
            <b-row>
              <b-col>
                <span class="h5">Deponent's KYC</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-if="getLegalDocumentTask.entityDeclarantDeponent != null"
                             :person="getLegalDocumentTask.entityDeclarantDeponent" entity-type="CORPORATE" />
                <LegalPerson v-if="getLegalDocumentTask.individualDeclarantDeponent != null"
                             :person="getLegalDocumentTask.individualDeclarantDeponent" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'FACILITY_LETTER'">
          <b-col cols="12" class="mt-4">
            <b-row>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Amount Secured"
                      label-for="input-1">
                      <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="getLegalDocumentTask.amountSecured.toLocaleString()"
                        required>
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="8">
                    <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Collateral Security Description"
                      label-for="input-1">
                      <b-form-textarea
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="getLegalDocumentTask.collateral"
                        required>
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>

                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Borrowers</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualBorrowers"
                                     :corporates="getLegalDocumentTask.entityBorrowers" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Lenders</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualLenders"
                                     :corporates="getLegalDocumentTask.entityLenders" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row
          v-if="getLegalDocumentTask.documentType === 'DEED_OF_ASSIGNMENT' || getLegalDocumentTask.documentType === 'DEED_OF_INTER_VIVOS' || getLegalDocumentTask.documentType ===  'DEED_OF_VARIATION' || getLegalDocumentTask.documentType === 'DEED_OF_GIFT' || getLegalDocumentTask.documentType === 'DEED_OF_INDEMNITY' || getLegalDocumentTask.documentType === 'DEED_OF_TRUST'">
          <b-col cols="12" class="mt-4">
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Assigners</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualAssigners"
                                     :corporates="getLegalDocumentTask.entityAssigners" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Assignees</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualAssignees"
                                     :corporates="getLegalDocumentTask.entityAssignees" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'DEED_POLL'">
          <b-col>
            <b-row>
              <b-col cols="4">
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Old Name of Declarant"
                  label-for="input-1">
                  <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    disabled="true"
                    :value="getLegalDocumentTask.oldName"
                    required>
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="New Name of Declarant"
                  label-for="input-1">
                  <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    disabled="true"
                    :value="getLegalDocumentTask.newName"
                    required>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Address of Declarant</span>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="getLegalDocumentTask.physicalAddress">
              <b-col>
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="District"
                  label-for="input-1">
                  <b-form-input
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="getLegalDocumentTask.physicalAddress.district.name"
                    required>
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Region"
                  label-for="input-1">
                  <b-form-input
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="getLegalDocumentTask.physicalAddress.region.name"
                    required>
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Sub county"
                  label-for="input-1">
                  <b-form-input
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="getLegalDocumentTask.physicalAddress.subcounty.name"
                    required>
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Parish"
                  label-for="input-1">
                  <b-form-input
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="getLegalDocumentTask.physicalAddress.parish.name"
                    required>
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Village"
                  label-for="input-1">
                  <b-form-input
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="getLegalDocumentTask.physicalAddress.village.name"
                    required>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="6">
                <b-alert variant="success" show>
                  <i class="fa fa-info-circle mx-1"></i> <span>No address given by applicant</span>
                </b-alert>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'MORTGAGE_DEED'">
          <b-col cols="12" class="mt-4">
            <b-row>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Amount"
                      label-for="input-1">
                      <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="getLegalDocumentTask.amountSecured.toLocaleString()"
                        required>
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Collateral Security Description"
                      label-for="input-1">
                      <b-form-textarea
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="getLegalDocumentTask.collateral"
                        required>
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Mortgagors</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualMortgagors"
                                     :corporates="getLegalDocumentTask.entityMortgagors" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Mortgagees</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualMortgagees"
                                     :corporates="getLegalDocumentTask.entityMortgagees" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'POWER_OF_ATTORNEY'" class="mt-4">
          <b-col cols="12">
            <b-row>
              <b-col>
                <span class="h5">Resolution(s)</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-row v-for="(resolution, idx) in getLegalDocumentTask.resolutions" :key="idx">
                  <b-col class="text-sm">
                    <p>Tracking Number: {{ resolution.trackingNumber }}</p>
                    <p>Document Type: {{ resolution.documentType.replaceAll("_", " ") }}</p>
                    <p>Subject Matter: {{ resolution.subjectMatter }}</p>
                    <p>Filing Date: {{ resolution.filingDate }}</p>
                  </b-col>
                  <b-col class="text-sm">
                    <p class="font-bold">Attachments</p>
                    <p v-for="(attachment, idx) in resolution.attachments" :key="idx"
                       class="cursor-pointer hover:underline" @click="viewResolution(attachment)">
                      <i class="fa fa-file mx-1 text-blue"></i> {{ attachment.fileDescription }}
                    </p>
                  </b-col>
                </b-row>
                <b-row v-if="getLegalDocumentTask.resolutions.length === 0">
                  <b-col>
                    <b-alert variant="info" show>
                      <i class="mx-2 fa fa-info-circle"></i> No Resolution Included
                    </b-alert>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <span class="h5">Donor KYC</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.entityDonor" :key="idx" :person="lp" entity-type="CORPORATE" />
                <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.individualDonor" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Donee KYC</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.entityDonee" :key="idx" :person="lp" entity-type="CORPORATE" />
                <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.individualDonee" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'REVOCATION_POWER_OF_ATTORNEY'" class="mt-4">
          <b-col cols="12">
            <b-card title="Power of Attorney to Revoke">
              <b-card-body>
                <b-row class="my-2">
                  <b-col cols="12">
                    <p>Instrument Number: {{getLegalDocumentTask.powerOfAttorney.instrumentNumber}} </p>
                    <p>EDMS Document Copy:
                      <b class="cursor-pointer text-blue" @click="selectEDMSDocument(getLegalDocumentTask.powerOfAttorney.edmsId)">
                        <i class="fa fa-file mx-2"></i> {{getLegalDocumentTask.powerOfAttorney.edmsId}}
                      </b>
                    </p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-row>
                      <b-col>
                        <span class="h5">Resolution(s)</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-row v-for="(resolution, idx) in getLegalDocumentTask.powerOfAttorney.resolutions" :key="idx">
                          <b-col class="text-sm">
                            <p>Tracking Number: {{ resolution.trackingNumber }}</p>
                            <p>Document Type: {{ resolution.documentType.replaceAll("_", " ") }}</p>
                            <p>Subject Matter: {{ resolution.subjectMatter }}</p>
                            <p>Filing Date: {{ resolution.filingDate }}</p>
                          </b-col>
                          <b-col class="text-sm">
                            <p class="font-bold">Attachments</p>
                            <p v-for="(attachment, idx) in resolution.attachments" :key="idx"
                               class="cursor-pointer hover:underline" @click="viewResolution(attachment)">
                              <i class="fa fa-file mx-1 text-blue"></i> {{ attachment.fileDescription }}
                            </p>
                          </b-col>
                        </b-row>
                        <b-row v-if="getLegalDocumentTask.powerOfAttorney.resolutions.length === 0">
                          <b-col>
                            <b-alert variant="info" show>
                              <i class="mx-2 fa fa-info-circle"></i> No Resolution Included
                            </b-alert>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <b-row class="mt-3">
                      <b-col>
                        <span class="h5">Donor KYC</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.powerOfAttorney.entityDonor" :key="idx" :person="lp" entity-type="CORPORATE" />
                        <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.powerOfAttorney.individualDonor" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
                      </b-col>
                    </b-row>

                    <b-row class="mt-2">
                      <b-col>
                        <span class="h5">Donee KYC</span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.powerOfAttorney.entityDonee" :key="idx" :person="lp" entity-type="CORPORATE" />
                        <LegalPerson v-for="(lp, idx) in getLegalDocumentTask.powerOfAttorney.individualDonee" :key="idx" :person="lp" entity-type="INDIVIDUAL" />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'SPOUSAL_CONSENT'">
          <b-col cols="12" class="mt-4">

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Consenting Person</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-if="getLegalDocumentTask.entityConsentingPerson != null"
                             :person="getLegalDocumentTask.entityConsentingPerson" entity-type="CORPORATE" />
                <LegalPerson v-if="getLegalDocumentTask.individualConsentingPerson != null"
                             :person="getLegalDocumentTask.individualConsentingPerson" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Mortgagee</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-if="getLegalDocumentTask.entityMortgagee != null"
                             :person="getLegalDocumentTask.entityMortgagee" entity-type="CORPORATE" />
                <LegalPerson v-if="getLegalDocumentTask.individualMortgagee != null"
                             :person="getLegalDocumentTask.individualMortgagee" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'DEBENTURE'">
          <b-col cols="12" class="mt-4">
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Assigners</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualAssigners"
                                     :corporates="getLegalDocumentTask.entityAssigners" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Assignees</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualAssignees"
                                     :corporates="getLegalDocumentTask.entityAssignees" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'CHATTEL'">
          <b-col cols="12" class="mt-4">
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Assigners</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualAssigners"
                                     :corporates="getLegalDocumentTask.entityAssigners" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Assignees</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualAssignees"
                                     :corporates="getLegalDocumentTask.entityAssignees" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'DEMAND_PROMISSORY_NOTE'" class="mt-4">
          <b-col cols="12">
            <b-row>
              <b-col cols="4">
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Amount"
                  label-for="input-1">
                  <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    disabled="true"
                    :value="0"
                    required>
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Realization Date"
                  label-for="input-1">
                  <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    disabled="true"
                    :value="new Date()"
                    required>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Borrower KYC</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-if="getLegalDocumentTask.entityBorrower != null"
                             :person="getLegalDocumentTask.entityBorrower" entity-type="CORPORATE" />
                <LegalPerson v-if="getLegalDocumentTask.individualBorrower != null"
                             :person="getLegalDocumentTask.individualBorrower" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Creditor KYC</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-if="getLegalDocumentTask.entityCreditor != null"
                             :person="getLegalDocumentTask.entityCreditor" entity-type="CORPORATE" />
                <LegalPerson v-if="getLegalDocumentTask.individualCreditor != null"
                             :person="getLegalDocumentTask.individualCreditor" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'INDEPENDENT_ADVISE_FORM'" class="mt-4">
          <b-col cols="12">
            <b-row>
              <b-col>
                <span class="h5">Advisor KYC</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-if="getLegalDocumentTask.entityAdviser != null"
                             :person="getLegalDocumentTask.entityAdviser" entity-type="CORPORATE" />
                <LegalPerson v-if="getLegalDocumentTask.individualAdviser != null"
                             :person="getLegalDocumentTask.individualAdviser" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Advisee KYC</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPerson v-if="getLegalDocumentTask.entityAdvise != null"
                             :person="getLegalDocumentTask.entityAdvise" entity-type="CORPORATE" />
                <LegalPerson v-if="getLegalDocumentTask.individualAdvise != null"
                             :person="getLegalDocumentTask.individualAdvise" entity-type="INDIVIDUAL" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'REPORT_OF_DIRECTORS'">
          <b-col cols="12" class="mt-4">
            <b-row>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Amount"
                      label-for="input-1">
                      <b-form-input
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="getLegalDocumentTask.amountSecured.toLocaleString()"
                        required>
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Collateral Security Description"
                      label-for="input-1">
                      <b-form-textarea
                        id="companyName"
                        class="form-text text-sm noFocus text-capitalize"
                        type="text"
                        disabled="true"
                        :value="getLegalDocumentTask.collateral"
                        required>
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Mortgagors</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualMortgagors"
                                     :corporates="getLegalDocumentTask.entityMortgagors" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Mortgagees</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualMortgagees"
                                     :corporates="getLegalDocumentTask.entityMortgagees" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="getLegalDocumentTask.documentType === 'WILL'">
          <b-col cols="12" class="mt-4">
            <b-row class="mt-2">
              <b-col>
                <span class="h5">Executors</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualExecutors"
                                     :corporates="getLegalDocumentTask.entityExecutors" />
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                <span class="h5">Testators</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <LegalPersonsPartner class="p-1" :individuals="getLegalDocumentTask.individualTestators"
                                     :corporates="getLegalDocumentTask.entityTestators" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-modal size="xl" title="Resolution Preview" hide-footer ref="resolutionPreview">
            <iframe v-if="resUrl" class="pdf" :src="resUrl+'#toolbar=0'">
            </iframe>
          </b-modal>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import LegalPerson from "@/views/home/services/legal-documents/LegalPerson";
import LegalPersonsPartner from "@/views/home/commons/LegalPersonsPartner";
import server from "@/app/env/server.json";
import EdmsIdToDocument from "@/views/home/services/registry/business/document/EdmsIdToDocument.vue";

export default {
  name: "LegalDocumentFormDetails",
  components: { EdmsIdToDocument, LegalPersonsPartner, LegalPerson },
  data() {
    return {
      corporates: [],
      individuals: [],
      resUrl:null,
      selectedEdmsId:null
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    cleanName(name) {
      let n = name.replaceAll("_", " ");
      return n.toLowerCase();
    },
    selectEDMSDocument(id){
      this.selectedEdmsId = id
      this.$refs.edmsDocument.show()
    },
    viewResolution(attachment) {
      this.$refs.resolutionPreview.show()
      this.resUrl = server.IP+"/legal-documents/attachments/preview?file-name="+attachment.fileName
    },
    getArray(data) {
      if (data) {
        return [data];
      }
      return [];
    }
  },
  computed: {
    ...mapGetters(["getLegalDocumentTask"])
  },
  watch: {}
};
</script>

<style scoped>
th, td {
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}

</style>