<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col>
                    <b-input-group class="border border-grey rounded-sm my-2">
                        <b-form-input
                                class="form-text text-sm noFocus border-0 text-gray"
                                v-model="form.trackingNumber"
                                type="text"
                                placeholder="Tracking Number">
                        </b-form-input>
                    </b-input-group>
                    <b-textarea v-model="form.justification"
                                class="mb-2"
                                rows="4"
                                placeholder="Justification">
                    </b-textarea>
                    <b-input-group>
                        <b-form-file
                                accept="application/pdf"
                                label-size="sm"
                                type="file"
                                placeholder="Please provide a copy of you id..."
                                drop-placeholder="Drop file here..."
                                v-model="form.file"
                                class="form-control noFocus removeOnClickBorder rounded-0 mb-2"
                                plain></b-form-file>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="9">
                    <b-input-group class="border border-grey rounded-sm">
                        <b-form-input
                                class="form-text text-sm noFocus border-0 text-gray"
                                v-model="search"
                                v-on:keyup="onSearch"
                                type="text"
                                placeholder="Search name">
                        </b-form-input>
                        <b-input-group-prepend
                                class="bg-white">
                            <i class="fas fa-search mx-3 text-gray hover:text-blue" style="margin-top: 12px;"
                               @click="onSearch"></i>
                        </b-input-group-prepend>
                    </b-input-group>
                </b-col>
                <b-col cols="3">
                    <b-form-select v-model="limit" class="w-full form-select paginate-details" v-on:change="fetch">
                        <b-select-option value="15">15</b-select-option>
                        <b-select-option value="20">20</b-select-option>
                        <b-select-option value="50">50</b-select-option>
                        <b-select-option value="100">100</b-select-option>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col cols="6">
                    <b-input-group class="border border-grey rounded-sm">
                        <b-form-input
                                class="form-text text-sm noFocus border-0 text-gray"
                                v-model="phone"
                                v-on:keyup="onSearch"
                                type="text"
                                placeholder="Phone number 2567XXXXXXX">
                        </b-form-input>
                        <b-input-group-prepend
                                class="bg-white">
                            <i class="fas fa-phone mx-3 text-gray hover:text-blue" style="margin-top: 12px;"
                               @click="onSearch"></i>
                        </b-input-group-prepend>
                    </b-input-group>
                </b-col>
                <b-col cols="6">
                    <b-input-group class="border border-grey rounded-sm">
                        <b-form-input
                                class="form-text text-sm noFocus border-0 text-gray"
                                v-model="email"
                                v-on:keyup="onSearch"
                                type="text"
                                placeholder="email  eg. xx@gmail.com">
                        </b-form-input>
                        <b-input-group-prepend
                                class="bg-white">
                            <i class="fas fa-evelope mx-3 text-gray hover:text-blue" style="margin-top: 12px;"
                               @click="onSearch"></i>
                        </b-input-group-prepend>
                    </b-input-group>
                </b-col>

            </b-row>
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-row>
                        <b-col>
                            <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem"
                                        animated></b-progress>
                        </b-col>
                    </b-row>
                    <b-row class="flex py-2 px-3 border-b border-header_grey hover:bg-header_grey"
                           v-for="(client, idx) in clients" :key="idx">
                        <b-col cols="9">
                            <div class="flex">
                                <b-col class="ml-3">
                                    <b-row>
                                        <b-col>
                                            <p class="text-sm text-uppercase" v-if="client">
                                                <span v-if="client.givenName">{{ client.givenName }}</span>
                                                <span class="mx-1" v-if="client.surName">{{ client.surName }}</span>
                                                <span class="mx-1"
                                                      v-if="client.middleName">{{ client.middleName }}</span>
                                            </p>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <p class="text-xs text-lowercase text-gray italic" v-if="client">
                                                {{ client.email }}</p>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </div>
                        </b-col>
                        <b-col cols="3">
                            <button @click="transfer(client)" class="button">
                                Select <i class="text-white fa fa-check-circle mx-1"></i>
                                <b-spinner class="ml-1" small
                                           v-if="loadingTransfer && selectedId === client.id"></b-spinner>
                            </button>
                        </b-col>
                    </b-row>
                    <b-row class="flex py-1 px-2 bg-header_grey"
                           v-if="clients.length === 0">
                        <b-col cols="12" class="text-center">
                            <p class="text-xs">No Account Found</p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import jwtService from "@/app/services/jwt.service";

export default {
    name: "TransferTaskOwnership",
    props: {type: String},
    data() {
        return {
            search: null,
            phone: null,
            email: null,
            form: {
                trackingNumber: null,
                file: null,
                justification: null,
                supportingDocumentDescription: null
            },
            loading: false,
            loadingTransfer: false,
            selectedId: null,
            description: null,
            userProfile: {},
            limit: 15,
            clients: []
        }
    },
    mounted() {
        this.fetch()
        this.userProfile = jwtService.getUser()
    },
    methods: {
        ...mapActions(["fetchClients", "searchClients", "transferApplicationOwnership","transferDataUpdateApplicationOwnership","transferReservationApplicationOwnership"]),
        fetch() {
            this.loading = true
            this.fetchClients({limit: this.limit})
        },
        onSearch() {
            this.loading = true
            let filters = {
                perPage:this.limit
            }

            if (this.search) {
                filters.name = this.search
            }


            if (this.phone) {
                filters.phone = this.phone
            }

            if (this.email) {
                filters.email = this.email
            }

            this.searchClients(this.objectToQueryParams(filters))
        },
        transfer(person) {
            this.selectedId = person.id
            if (this.form.justification != null) {
                if (this.type === "reservation") {

                    let payload = new FormData();

                    payload.set('trackingNo', this.form.trackingNumber)
                    payload.set('reason', this.form.justification)
                    payload.set('newOwnerId', this.selectedId)
                    payload.set('supportingDocument', this.form.file)
                    //payload.set('supportingDocumentDescription', this.form.justification)


                    this.transferReservationApplicationOwnership(payload)

                    console.log("")
                }

                if (this.type === "bnr") {
                    console.log("")
                }

                if (this.type === "ci") {
                    let payload = new FormData();

                    payload.set('trackingNo', this.form.trackingNumber)
                    payload.set('reason', this.form.justification)
                    payload.set('newOwnerId', this.selectedId)
                    payload.set('supportingDocument', this.form.file)
                    payload.set('supportingDocumentDescription', this.form.justification)


                this.transferApplicationOwnership(payload)
            }
                if (this.type === "data-update") {
                    let payload = new FormData();

                    payload.set('trackingNo', this.form.trackingNumber)
                    payload.set('reason', this.form.justification)
                    payload.set('newOwnerId', this.selectedId)
                    payload.set('supportingDocument', this.form.file)
                    payload.set('supportingDocumentDescription', this.form.justification)


                    this.transferDataUpdateApplicationOwnership(payload)
                }

            if (this.type === "bsi") {
                console.log("")
            }

            if (this.type === "ld") {
                console.log("")
            }

            if (this.type === "certification") {
                console.log("")
            }
        } else {
            this.errorToast("Transfer Error", "Please provide justification")
        }
    },
}
,
computed: {
...
    mapGetters(["getClients", "getTransferDetails", "getTransferError"]),
}
,
watch: {
    getClients(data)
    {
        this.loading = false
        this.clients = data.content
    }
,
    getTransferDetails()
    {
        this.loading = false
        this.successToast("Success", "Application transferred to new owner")
        window.location.reload()
    }
,
    getTransferError()
    {
        this.loading = false
        this.errorToast("Failed to transfer")
    }
}
}
</script>

<style scoped>
.button {
    @apply w-full bg-blue mt-1 px-2 py-1 rounded-sm text-white text-xs
}

.button:hover {
    @apply bg-green-dark;
}
</style>