<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Name Search</span>
        </b-row>
      </b-col>
    </b-row>
    <div  style="width: 98%" class="mx-auto mt-2">
      <b-row>
        <b-col cols="12" lg="6" md="6" sm="12" class="py-3">
          <form @submit.prevent="searchPhrase">
            <b-input-group  class="mx-auto" style="border: 1px solid #ced4da;  border-radius: 25px;">
              <b-input-group-prepend
                  class="pl-2 bg-white rounded-search">
                <b-input-group-text class="mt-1 border-0 bg-white text-blue">
                  <i class="fas fa-search"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                  v-model="searchName"
                  placeholder="Name to Search"
                  class="border-0 removeOnCLickBorder text-gray search-input"
                  type="text">
              </b-form-input>
              <b-input-group-append>
                <button
                    type="submit"
                    class="search-button">
                  Search
                </button>
              </b-input-group-append>
            </b-input-group>
            <small class="text-red">{{searchError}}</small>
          </form>
        </b-col>
        <b-col v-if="resultData.length > 0" cols="12" lg="3" md="3" sm="12" class="mt-2">
          <b-form-select
              class="form-select form-select-sm mt-3 noFocus"
              v-model="entityType"
              :options="types"
              @change="selectTrigger()">
          </b-form-select>
        </b-col>

        <b-col v-if="resultData.length > 0" cols="12" lg="3" md="3" sm="12" class="mt-2">
          <b-form-select
              v-model="status"
              @change="selectTrigger()"
              :options="statusList"
              class="form-select form-select-sm mt-3 noFocus">
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div class="mx-auto">
      <b-progress value="100" v-if="showResultProgress"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>
    <b-row>
      <b-col cols="12">
        <table class="w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">#</th>
            <th class="t-header">BRN/RN</th>
            <th class="t-header">Name</th>
            <th class="t-header">Entity Type</th>
            <th class="t-header text-center">Text Score</th>
            <th class="t-header text-center">Sound Score</th>
            <th class="t-header text-center">Status</th>
            <th class="t-header text-center">Res / Reg / Inc Date</th>
            <th class="t-header">Last Change</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td v-if="resultData.length < 1" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No similar names found</span>
            </td>
          </tr>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" class="hover:bg-header_grey" v-for="(list, idx) in resultData" :key="idx">
            <td class="t-body">{{idx+1}}</td>
            <td class="t-body text-blue font-bold">
              {{list.no}}
            </td>
            <td class="t-body text-gray font-bold">{{list.name}}</td>
            <td class="t-body text-gray text-capitalize">{{list.type}}</td>
            <td class="t-body text-gray text-capitalize text-center">
              <span :class="(list.score.text > 70) ? scores.above : scores.below">Text: {{list.score.text}}%</span>
            </td>
            <td class="t-body text-gray text-capitalize text-center">
              <span :class="(list.score.text > 70) ? scores.above : scores.below">Sound: {{list.score.sound}}%</span>
            </td>
            <td class="t-body text-white text-center">
              <b-badge class="bg-orange mx-auto" v-if="list.status === 'Reserved'">{{list.status}}</b-badge>
              <b-badge class="bg-red mx-auto" v-else-if="list.status === 'Struck Off'">{{list.status}}</b-badge>
              <b-badge class="bg-blue mx-auto" v-else>{{list.status}}</b-badge>
            </td>
            <td class="t-body text-gray italic text-center">
              {{list.createdAt}}
            </td>
            <td class="t-body text-gray italic">
              {{list.updatedAt}}
            </td>

          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "QuickSearch",
  data(){
    return{
      searchName:null,
      searchError:null,
      resultData:[],
      showBalertResult:false,
      showResultProgress:false,
      statusList:[],
      types:[],
      entityType: "",
      status: "",
      rows:{
        odd:"border-b border-grey",
        even:"border-b border-grey bg-header_grey"
      },
      scores:{
        above:"bg-red px-1 py-1 text-white mx-1 rounded",
        below:"bg-green px-1 py-1 text-white mx-1 rounded"
      }
    }
  },
  mounted() {
    this.doGetFilters();
  },
  methods:{
    ...mapActions(
        [
            "doSearch",
            "doGetFilters"
        ]
    ),
    searchPhrase(){
      this.searchError = ""
      this.showResultProgress = true
      this.doSearch(this.appendQueryParams({"name":this.searchName, "scope":'text'}))
    },
    filterStatus(data){
      let obj = {text:"Select status"}
      return {...obj, ...data}
    },
    filterTypes(data){
      let obj = {text:"Select type"}
      return {...obj, ...data}
    },
    selectTrigger() {
      let filters = {
        name: this.pname,
        type: this.entityType !== "text" ? this.entityType : "",
        status: this.status !== "text"  ? this.status : "",
      }
      this.doSearch(this.appendQueryParams(filters));
    },
  },
  computed:{
    ...mapGetters(
        [
            "getSearchResults",
            "getFilters",
            "getMessageError"
        ]
    )
  },
  watch:{
    getSearchResults(data){
      if(data){
        this.resultData = data
        this.showBalertResult = data.length < 1
        this.showResultProgress = false
      }
    },
    getFilters(data) {
      if(data){
        this.statusList =  this.filterStatus(data.statuses)
        this.types = this.filterTypes(data.types)
        this.entityType = "text"
        this.status = "text"
      }
    },
    getMessageError(error){
      if(error){
        this.showResultProgress = false
        this.searchError = error.message
        this.errorToast("Error", error.message)
      }
    }
  }
}
</script>

<style scoped>
@import "../../../../assets/styles/table.css";

.search-button{
  @apply border-0 removeOnCLickBorder px-5;
  height: 43px;
  color: #ffffff;
  background: #157ec2;
  border-radius: 0 25px 25px 0;
}

.search-button:hover{
  background: #0467a8;
}

.rounded-search{
  border-radius: 25px 0 0 25px;
}

.search-input{
  border-radius: 0 0 0 0;
  height: 43px;
}

.removeOnCLickBorder:focus {
  outline: none 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.removeOnCLickBorder {
  box-shadow: none !important
}

i{
  height: 10px;
  margin-top: 4px;
}

@media only screen and (max-width: 880px) {
  .search-button{
    @apply px-2;
    height: 33px;
    font-size: 11px;
    border-radius: 0 25px 25px 0;
  }

  .search-input{
    height: 33px;
    font-size: 12px;
  }

  i{
    height: 10px;
    margin-top: 0px;
  }

}

@media only screen and (max-width: 440px) {
  .search-button{
    font-size: 10px;
  }
}

</style>