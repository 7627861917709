<template>
    <div>
        <b-row>
            <b-col cols="12" sm="12" md="12" lg="8" class="mx-auto">
                <b-row class="mt-3">
                    <b-col>
                        <b-alert variant="info" class="text-sm" show>
                            <i class="fa fa-info-circle mr-2"></i> Fill in this information
                        </b-alert>
                    </b-col>
                </b-row>



                <b-row>
                    <b-col class="mx-auto mt-2">
                        <label class="text-gray">Date of appointment</label>
                        <datepicker
                                :disabled-dates="{from:new Date()}"
                                placeholder="dd/mm/yyyy"
                                class="noFocus text-sm"
                                :typeable="false"
                                bootstrap-styling
                                id="dateOfBirth"
                                format="dd/MM/yyyy"
                                required
                                v-model="form.appointmentDate">
                        </datepicker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mx-auto">
                        <b-form-group
                                class="text-sm"
                                id="input-group-1"
                                label="Select Practitioner"
                                label-for="input-1">

                            <b-form-select
                                    id="nationality"
                                    class="form-select noFocus text-gray text-sm"
                                    v-model="form.practitionerId"
                                    :options="practitioners"
                            >
                            </b-form-select>

                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>


        <b-row class="mt-3">
            <b-col cols="12">
                <button id="backBtn" type="button" class="back-button" @click="prev">
           <span class="mx-3">
              Back
           </span>
                </button>


                <button id="nextBtn" type="button" class="button float-end" @click="moveNext">
           <span class="mx-3">
              Save & Continue
              <b-spinner small v-if="loading"></b-spinner>
           </span>
                </button>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { api } from "@/app/services/api.service";
import Datepicker from "vuejs-datepicker";

export default {
    name: "AppointPractitioner",
    components: {
        Datepicker
    },
    mounted() {

        if (sessionStorage.getItem("tracking-number")) {
            this.trackingNo = sessionStorage.getItem("tracking-number");
            this.fetchDraftData(this.trackingNo);

        }

    },
    data() {
        return {
            currentTab: 0,
            trackingNo: null,
            loading: false,
            form: {
                trackingNumber: null,
                practitionerId: "",
                appointmentDate: null
            },
            practitioners: [],
            type: "winding-up"
        };
    },
    methods: {
        ...mapActions([
            "fetchWindingDraftData","fetchBankruptcyDraftData",
        ]),
        async refresh() {
            let url = "insolvency/practitioner/all?showOfficialReceiver=true";
            if (this.type === "BANKRUPTCY_FILING") {
                url = "insolvency/practitioner/all";
            }
            api().get(url).then((response)=>{
                if(response){
                    let draft = response.data.data
                    let obj = {}
                    let arr  = [{ text: "--Select Practitioner--", value: "" }]
                    draft.forEach((item)=>{
                        let id = item.practitionerId ? item.practitionerId : null
                        let sur = item.surName ? item.surName : ""
                        let given = item.givenName ? item.givenName : ""
                        obj = {
                            value: id,
                            text: `${sur} ${given}`
                        }
                        arr.push(obj)
                    });
                    this.practitioners = arr
                }

            }).catch((err) => {
                this.errorToast("Error", err.response.data.message);
            });
        },

        moveNext() {
            this.loading = true;
            api().post("insolvency/process/appoint-practitioner", this.form)
                .then((response) => {
                    this.loading = false;
                    this.$emit("nextTab",response.data.data);
                })
                .catch((err) => {
                    this.loading = false;
                    this.errorToast("Error", err.response.data.message);
                });
        },
        prev() {
            this.$emit("backTab");
        }
        ,  fetchDraftData(){
            // if route path contains provisional-appointment
            if(this.$route.path.includes("provisional-appointment")){
                this.fetchBankruptcyDraftData(this.trackingNo)
            } else {
                this.fetchWindingDraftData(this.trackingNo)
            }

        },
    },

    computed: {
        ...mapGetters(
            [
                "getWindingDraftData",
                "getWindingDraftDataError",

            ]
        )
    },
    watch: {
        getWindingDraftData(data) {
            let info = data.data

            this.trackingNo = info.trackingNumber;
            this.form.trackingNumber = info.trackingNumber;
            this.type = info.type;
            let appoint = info.practitionerAppointment
            if(appoint){
                this.form.practitionerId  = appoint.practitionerId
                this.form.appointmentDate  = appoint.appointmentDate
            }
            sessionStorage.removeItem("tracking-number");
            this.refresh();





        },
        getWindingDraftDataError(err) {
            this.errorToast("Error", err.response.data.message);
        }

    }
};
</script>


<style scoped>

.button {
    @apply bg-blue text-white py-2 rounded;
}

.button:hover {
    @apply bg-blue-dark;
}


.back-button {
    @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
    @apply bg-gray text-white;
}
</style>