<template>
    <div>
        <b-row>
            <b-col>

                <label class="text-gray" style="font-size: 14px;">New Name</label>
                <b-form-group
                        id="input-group-1"
                        label-for="input-1">
                    <b-form-input
                            id="plot"
                            class="form-text mb-1 noFocus text-sm"
                            type="text"
                            v-model="form.name"
                            required>
                    </b-form-input>
                </b-form-group>

            </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col cols="12">
                <button id="nextBtn" type="button" class="button float-end" @click="onSubmit">
                   <span class="mx-3">
                    Save changes <b-spinner small v-if="loading"></b-spinner>
                   </span>
                </button>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import {api} from "@/app/services/api.service";

export default {
    name: "ChangeName",
    props : {
        brn:String,
        oldValue:String
    },
    data() {
        return {
            loading:false,
            form: {
                name: null,
                brn:null

            }
        }
    },
    mounted() {
       this.form.name = this.oldValue
    },
    methods : {
        async onSubmit() {
            if (!this.brn){
                this.errorToast("Error", "Please provide a BRN")
            }
            this.form.brn = this.brn
            await api().post("incorporation/data-correction/name", this.form)
                .then((response) => {
                    console.log(response)
                    this.loading = false
                    this.$emit("done", true);
                })
                .catch((error) => {
                    this.loading = false
                    this.errorToast("Error", error.response.data.message)

                });
        },
    }
}
</script>
<style scoped>
.button {
    @apply bg-blue text-white py-2 rounded;
}

.button:hover {
    @apply bg-blue-dark;
}

</style>