<template>
<span>
  <b-row class="mb-3">
                <b-col cols="6">
                  <span>
                    <label class="text-gray mb-1" style="font-size: 14px;">Registration<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-form-select
                        id="registrationType"
                        class="form-select noFocus text-gray text-sm"
                        v-model="form.registrationType"
                        @change="checkCountryOfOrigin(form.registrationType)"
                        :disabled="readOnlyForm || lockedToUganda"
                        :options="registrationTypeList">
                    </b-form-select>
                </span>


                   <span v-if="form.registrationType === 'mda'">
                    <label class="text-gray mb-1" style="font-size: 14px;">Select MDA<i style="font-size: 10px"
                                                                                      class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-form-select
                        id="registrationType"
                        class="form-select noFocus text-gray text-sm"
                        v-model="form.brn"
                        @change="checkMdaAccount(form.brn)"
                        :disabled="readOnlyForm"
                        :options="mdaList">
                    </b-form-select>
                </span>


                  <label class="text-gray mb-1" v-if="form.registrationType" style="font-size: 14px;">Country Of Origin<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-form-select
                        :disabled="readOnlyForm"
                        v-if="form.registrationType"
                        id="countryOfOrigin"
                        class="form-select noFocus text-gray text-sm"
                        v-model="form.countryOfOrigin"
                        :options="countryListData">
                    </b-form-select>

      <span>
        <label class="text-gray mb-1" style="font-size: 14px;">Business Registration Number<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-form-input
                        id="brn"
                        :maxlength="(form.registrationType==='registered_in_uganda') ? 14 : 21"
                        @keypress="(form.registrationType==='registered_in_uganda') ? onlyNumberValidator($event) : ''"
                        data-test='typeUserEmail'
                        required
                        v-model="form.brn"
                        :disabled="readOnlyForm"
                        type="text"
                        @input="validateBrn"
                        class="noFocus text-input text-sm">
                    </b-form-input>
         <b-progress v-if="loaderProgress" :value="100" height="3px" variant="#007ac5" animated="true"></b-progress>
       <div class="mt-3" v-if="brnErrorMessage">
          <b-alert variant="danger" show>
                     <small class="text-red" style="font-size: 14px;">{{ brnErrorMessage }}</small>
          </b-alert>
      </div>
      </span>

                 <span>
                   <label class="text-gray mb-1" style="font-size: 14px;">Company Name<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                     <b-form-input
                         :readonly="brnReadOnlyFields"
                         id="name"
                         data-test='typeUserEmail'
                         required
                         v-model="form.registeredName"
                         :disabled="readOnlyForm"
                         type="text"
                         class="noFocus text-input text-uppercase text-sm">
                    </b-form-input>
                </span>

                  <span v-show="showExtraDetails" >
                    <span v-if="form.registrationType !== 'mda'">
                    <label class="text-gray mb-1" style="font-size: 14px;">Date of Registration<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                       <datepicker
                           :disabled="brnReadOnlyFields || readOnlyForm || showBrnDate"
                           placeholder="dd/mm/yyyy"
                           :disabled-dates="{from:new Date()}"
                           class="noFocus text-sm"
                           :typeable="false"
                           bootstrap-styling
                           id="dateOfBirth"
                           format="dd/MM/yyyy"
                           v-model="form.dateOfIncorporation"
                       ></datepicker>
                    </span>
                </span>
              <span v-show="showExtraDetails" >
               <label class="text-gray mb-1" style="font-size: 14px;">Entity Subtype<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-form-select
                        :disabled="brnReadOnlyFields || readOnlyForm"
                        id="subType"
                        class="form-select noFocus text-gray text-sm"
                        v-model="form.subType"
                        :options="subTypeList">
                    </b-form-select>
                </span>

                     <span v-show="showExtraDetails" >

                 <label class="text-gray mb-1" style="font-size: 14px;">Email<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
                    <b-form-input
                        :readonly="brnReadOnlyFields"
                        id="primaryEmail"
                        data-test='typeUserEmail'
                        required
                        :disabled="readOnlyForm"
                        v-model="form.primaryEmail"
                        @input="validateEmailAddress(form.primaryEmail)"
                        type="email"
                        maxlength="60"
                        class="noFocus text-input text-sm">
                    </b-form-input>
                       <span v-if="emailErrorResponse"> <small style="color: red;">{{ emailErrorResponse }}</small> <br/> </span>
                </span>
                  <span v-show="showExtraDetails" >

          <label class="text-gray mb-1" style="font-size: 14px;">Phone Number<i style="font-size: 10px" class="fas fa-asterisk ml-1 text-red"></i></label>
            <b-input-group style="border:1px solid #ced4da;border-radius:  2px 0 0 2px;">
                <b-input-group-prepend
                    style="background: #f1f1f1;">
              <p class="pt-2 float-end px-2 text-sm" style="color: #505050;">+{{ countryCodeNumber }}</p>
              </b-input-group-prepend>
            <b-input-group-prepend>
              <vue-country-code
                  v-if="!readOnlyForm"
                  :disabledFetchingCountry="true"
                  :enabledFlags="false"
                  class="countryCodeStyle text-sm"
                  @onSelect="onSelectCountryCode"
                  :preferredCountries="['UG']">
              </vue-country-code>
            </b-input-group-prepend>
            <b-form-input
                autocomplete="off"
                @paste.prevent
                :readonly="brnReadOnlyFields"
                id="phoneNumber"
                data-test="typeUserPhone"
                required
                v-mask="'#############'"
                maxlength="13"
                v-model="phoneNo"
                @input="validatePhone(phoneNo)"
                @keypress="onlyNumberValidator($event)"
                :disabled="readOnlyForm"
                type="tel"
                style="color:#505050; height: 32px;"
                class="form-text noFocus border-0 removeOnClickBorder mb-1 text-sm">
            </b-form-input>
          </b-input-group>
                </span>

                </b-col>

              <b-col  v-show="showExtraDetails" cols="6">
                  <residenceof-partner
                      v-on:residenceInfo="residenceInfo"
                      :readOnlyDisableFields="readOnlyForm"
                      :villageDataInfo="villageDataInfo"
                      ref="company_address_component"
                      v-on:removePlotStreet="removePlotStreetMain"
                  ></residenceof-partner>
                  <span>
                <label class="text-gray mb-1" style="font-size: 14px;">
                  Postal Address
                </label>

                    <b-input-group
                        :style="addressColor === 'red' ? 'border: 1px solid red' :''"
                        style="border:1px solid #e6e9ec;border-radius:  2px 0 0 2px;
              margin-top: 6px;">
            <b-input-group-prepend
                style="background: #f1f1f1;">
              <p class="pt-2 float-end px-2 text-sm" style="color: #505050;">P.O.BOX </p>
            </b-input-group-prepend>
            <b-input-group-prepend
                class="mr-1"
                style="background: #ffffff; width: 35%; border-right: 1px solid #e6e9ec;">
              <b-form-input
                  autocomplete="off"
                  @paste.prevent
                  :disabled="readOnlyForm"
                  :style="addressColor === 'red' ? 'border: 1px solid red' :''"
                  id="phoneNumber"
                  required
                  v-model="boxNumber"
                  v-on:blur="validatePostalAddress()"
                  type="text"
                  style="color:#505050; height: 32px;"
                  class="form-text noFocus border-0 removeOnClickBorder text-sm">
              </b-form-input>

            </b-input-group-prepend>
            <b-form-select
                :disabled="readOnlyForm"
                id="idType"
                style="color: #505050;"
                data-test='typeIdType'
                class="form-select  noFocus border-0 removeOnClickBorder text-gray text-sm"
                v-model="boxStation"
                @change="validatePostalAddress()"
                :options="stationList">
            </b-form-select>
          </b-input-group>

                    <b-progress v-if="loaderPostalProgress" :value="100" height="3px" variant="#007ac5" animated="true"></b-progress>
                    <small  style="color: #afaeae;">[ P.O Box 123 Kampala ]</small>
                    <small class="float-end" v-if="postalAddressStatusMsg" :style="'color:'+addressColor" v-html="postalAddressStatusMsg"></small>
                </span>
            <br />

      <label v-if="showPlotAndStreet" class="text-gray mb-1" style="font-size: 14px;">Plot</label>
            <b-form-group
                v-if="showPlotAndStreet"
                id="input-group-1"
                label-for="input-1">
              <b-form-input
                  :disabled="readOnlyForm"
                  :readonly="brnReadOnlyFields"
                  id="input-1"
                  class="form-text noFocus text-uppercase text-sm"
                  type="text"
                  v-model="form.registeredAddress.plot">
              </b-form-input>
            </b-form-group>


       <label v-if="showPlotAndStreet" class="text-gray mb-1" style="font-size: 14px;">Street</label>
            <b-form-group
                v-if="showPlotAndStreet"
                id="input-group-1"
                label-for="input-1">
              <b-form-input
                  :readonly="brnReadOnlyFields"
                  id="street"
                  :disabled="readOnlyForm"
                  class="form-text mb-3 noFocus text-uppercase text-sm"
                  type="text"
                  v-model="form.registeredAddress.street">
              </b-form-input>
            </b-form-group>

                </b-col>

  </b-row>

</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Datepicker from "vuejs-datepicker";
import {api} from "@/app/services/api.service";
import ResidenceofPartner from "@/views/home/services/insolvency/winding-up/components/ResidenceofPartner.vue";
import moment from "moment";

export default {
  name: "EntityInformation",
  components: {ResidenceofPartner, Datepicker},
  props: {
    index: Number,
    showExtraDetails: {
      type: Boolean,
      default: true
    },

    subscriberList: {},
    selectedPositionData: {},
    form18SubscriberValidation: String
  },
  data() {
    return {
      brnErrorMessage: null,
      loaderProgress: false,
      showBrnDate: false,
      villageDataInfo: {},
      brnReadOnlyFields: false,
      emailErrorResponse: "",
      errorBrnValidator:"",
      readOnlyForm:false,
      lockedToUganda:false,
      readOnlyDisableFields:false,
      form: {
        countryId : null,
        brn: null,
        registrationType: null,
        registeredName: null,
        countryOfOrigin: "",
        dateOfIncorporation: null,
        subType: "",
        primaryEmail: null,
        primaryPhone: null,
        addressInCountryOfIncorporation: null,
        registeredPhysicalAddressId: null,
        registeredPhysicalAddress: null,
        partnerId:null,
        registeredAddress: {
          description: null,
          physicalAddress: null,
          plot: null,
          postalAddress: null,
          street: null,
        },
        type: null,
        selectedPositions:null,
        country:null
      },
      phoneNo: null,
      countryCodeNumber: "256",
      registrationType: "",
      countryListData: [],
      mdaList: [],
      registrationTypeList: [
        {
          value: "",
          text: "--Select Registration --"
        },
        {
          value: "registered_in_uganda",
          text: "Registered in Uganda"
        },
        {
          value: "foreign_entity",
          text: "Registered outside Uganda"
        },
        {
          value: "mda",
          text: "MDA"
        },
        {
          value: "other",
          text: "Other"
        },
      ],
      subTypeList: [
        {
          value: "",
          text: "--Select Sub Type--"
        },
        {
          value: "single-member-company",
          text: "Single Member Company"
        },
        {
          value: "private-company-limited-by-shares",
          text: "Private Company Limited By Shares"
        },
        {
          value: "private-company-limited-by-guarantee-with-shares",
          text: "Private Company Limited By Guarantee With Shares"
        },
        {
          value: "private-company-limited-by-guarantee-without-shares",
          text: "Private Company Limited By Guarantee Without Shares"
        },
        {
          value: "public-company",
          text: "Public Company"
        },
        {
          value: "foreign-company",
          text: "Foreign Company"
        },
        {
          value: "unlimited-company",
          text: "Unlimited Company"
        },
        {
          value: "others",
          text: "Others"
        },
      ],
      showPlotAndStreet:true,
      postalAddressStatusMsg:"",
      addressColor:null,
      boxNumber:null,
      boxStation:"",
      stationList:null,
      loaderPostalProgress: false,
    }
  },
  mounted() {
    this.fetchMdaData();
    this.checkEntityInfo()
    this.checkPosition()
    this.fetchStations()

  },
  methods: {
    ...mapActions(
        [
          "fetchStations"
        ]
    ),
    onSelectCountryCode({ dialCode}) {
      if(dialCode){
        this.countryCodeNumber = dialCode
        this. validatePhone(this.phoneNo)
      }
    },
    async fetchMdaData() {
      await api().get("/configuration/mda-registry?paginated=false")
          .then((response) => {
            if (response) {
              this.filterMda(response.data.content);
            }
          })
          .catch((error) => {
            if (error) {
              // this.loaderProgress = false
              // this.brnErrorMessage = error.response.data.message
            }
          });
    },
    filterMda(data) {
      let obj = {};
      let arr = [{ value: "", text: "--Select MDA--" }];
      data.forEach(lst => {
        obj = {
          value: lst.no,
          text: lst.name
        };
        arr.push(obj);
      });

      this.mdaList = arr;

    },
    checkMdaAccount(mda) {
      this.disableMda = true;
      this.form.registeredName = this.mdaList.find(lst => {
        return lst.value === mda;
      }).text;
    },
    validatePhone(data) {
      if(data){
        //  phone number should all start with a zero
        if (data.charAt(0) === '0') {
          this.phoneNo = data.substring(1)
        }

        let code = this.countryCodeNumber ? this.countryCodeNumber : "256"
        this.countryCodeNumber = code
        this.form.primaryPhone = `${"("+code+")"}${this.phoneNo}`
      }

    },
    checkEntityInfo() {
      if (this.subscriberList) {
        this.readOnlyForm  =    this.subscriberList.readOnly ? this.subscriberList.readOnly : false
        let data = this.subscriberList.corporate ? this.subscriberList.corporate : this.subscriberList
        if(data) {
          this.form.brn = data.brn ? data.brn : null
          this.form.registeredName = data.registeredName ? data.registeredName : null
          this.form.dateOfIncorporation = data.dateOfIncorporation ? data.dateOfIncorporation : null
          this.form.primaryEmail = data.primaryEmail ? data.primaryEmail : null


          if (data.primaryPhone && data.primaryPhone.includes("(")) {
            if (data.primaryPhone) {
              let phoneArray = data.primaryPhone.replace('(', '').split(")")
              this.countryCodeNumber = phoneArray[0]
              this.phoneNo = phoneArray[1]
              this.form.primaryPhone = `${"(" + phoneArray[0] + ")"}${phoneArray[1]}`;
            }
          } else {
            this.phoneNo = data.primaryPhone ? data.primaryPhone.substring(3) : ""
            this.countryCodeNumber = data.primaryPhone ? data.primaryPhone.slice(0, 3) : "256"
            this.form.primaryPhone = data.primaryPhone ? `${"(" + data.primaryPhone.slice(0, 3) + ")"}${data.primaryPhone.substring(3)}` : null
          }

          this.form.registeredAddress.plot = data.registeredAddress ? data.registeredAddress.plot : null
          this.form.registeredAddress.street = data.registeredAddress ? data.registeredAddress.street : null
          this.form.registeredPhysicalAddressId = data.registeredPhysicalAddressId ? data.registeredPhysicalAddressId : null
          this.form.registeredPhysicalAddress = data.registeredPhysicalAddress ? data.registeredPhysicalAddress : null
          this.form.partnerId = data.partnerId ? data.partnerId : null
          this.form.type = data.type ? data.type : null
          this.form.addressInCountryOfIncorporation = data.addressInCountryOfIncorporation ? data.addressInCountryOfIncorporation : null
          this.form.country = data.country ? data.country : null
          this.form.countryId = data.countryId ? data.countryId : null

          this.form.registeredAddress.postalAddress = data.registeredAddress ? data.registeredAddress.postalAddress : null

          if (data.registeredAddress && data.registeredAddress.postalAddress) {
            let boxNumber = data.registeredAddress.postalAddress.split(" ");
            this.boxNumber = boxNumber[0]
            this.boxStation = data.registeredAddress.postalAddress.replace(boxNumber[0] + " ", "")
          }

          let addressData = data.registeredPhysicalAddress ? data.registeredPhysicalAddress : null

          let addrs = {
            foreignPhysicalAddress: data.addressInCountryOfIncorporation ? data.addressInCountryOfIncorporation : null,
            countryId: data.countryId ? data.countryId : null
          }
          let country = data.country ? data.country : null

          this.villageDataInfo = {...addressData, ...addrs, ...country}


          this.form.subType = data.subType ? data.subType : null




          this.form.countryOfOrigin = data.countryOfOrigin ? data.countryOfOrigin : null

          let countryTypeCheck = data.countryOfOrigin !== "Uganda" ? "foreign_entity" : "registered_in_uganda"
          this.countryDataList(countryTypeCheck)
          this.form.registrationType = data.registrationType ? data.registrationType : countryTypeCheck

          if (data.type) {
            if (data.type === "business") {
              this.fetchBusinessTypeInfo()
            } else if (data.type === "company") {
              this.fetchCompanyTypeInfo()
            } else if (data.type === "building-society") {
              this.fetchBuildingSocietyTypeInfo()
            }
          }

        }

      }
    },
    async validateBrn() {
      this.brnErrorMessage = ""
      if (this.form.brn.length === 14 && this.form.registrationType === 'registered_in_uganda') {
        this.loaderProgress = true
        await this.validateBrnInfo(this.form.brn)
      } else {
        this.loaderProgress = false
        this.clearFormData()
        this.brnReadOnlyFields = false
      }
    },
    clearFormData() {
      this.form.registeredName = null
      this.form.primaryEmail = null
      this.form.primaryPhone = null
      this.form.dateOfIncorporation = null
      this.form.postalAddress = null
      this.form.plotNumber = null
      this.form.street = null
      this.phoneNo = null
      this.form.subType = null
    },
    async validateBrnInfo(brnData) {

        await api().get("/namesearch/by-no?brn=" + brnData)
            .then((response) => {
              this.filterEntityDraftData(response.data)
            })
            .catch((error) => {
                  if(error && error.response) {
                    this.loaderProgress = false
                    this.brnErrorMessage = error.response.data.message
                  }
            });
    },
    filterEntityBrsDraftData(data){
      if(data){
        let regDate = data.reg_date.includes("\n  ") ? data.incorporation_date : data.reg_date
        this.form.registeredName = data.entity_name ? data.entity_name : null
        this.form.dateOfIncorporation = regDate ? moment(regDate).utc().format() : null
        this.loaderProgress = false
        this.showBrnDate = true
        this.fetchSubTypeBrs(data)
      }
    },
    filterEntityDraftData(data) {
      if(data) {

        if (data.brnType.toLowerCase() === "business" && this.form18SubscriberValidation === "subValidate") {
          this.brnErrorMessage = "Business name can not be a Subscriber!!!"
          return
        }

          this.subTypeList = []
          this.form.registeredName = data ? data.name ? data.name : '' : ''
          this.brnReadOnlyFields = true


        //-----
        let typeBrn = ""
        if (data.brnType.toLowerCase() === "building_society") {
          typeBrn = "building-society"
        }

        if (data.brnType.toLowerCase() === "company") {
          typeBrn = "company"
        }

        if (data.brnType.toLowerCase() === "business") {
          typeBrn = "business"
        }
        //-------

        this.form.type = typeBrn ? typeBrn : null

        this.subTypeList = []
        //-------------------------------------
        if (data.brnType.toLowerCase() === "business") {
          this.fetchBusinessTypeInfo(data)
          this.fetchBrnBusinessNameInfo(data.brn)
          this.form.subType = data.subType
          //this.validateBusinessName(data.brnType)
        }
        //-----------------------

        if (data.brnType.toLowerCase() === "company") {
          this.fetchCompanyTypeInfo(data)
          this.fetchBrnCompanyNameInfo(data.brn)
          this.form.subType = data.subType
        }

        //  --------------------
        if (data.brnType.toLowerCase() === "building_society") {
          this.fetchBuildingSocietyTypeInfo(data)
          this.fetchBrnBuildingSocietyInfo(data.brn)
          this.form.subType = data.subType
        }
      }

    },
    validateBusinessName(brnType){
      if(brnType === 'BUSINESS_NAME' &&  this.form.selectedPosition !== 'SECRETARY'){
        this.brnErrorMessage = "Business name can only be a Secretary!!!"
      }
    },
    checkPosition() {
      if(this.selectedPositionData) {
        this.form.selectedPositions = this.selectedPositionData
      }
    },
    fetchSubTypeBrs() {
      this.subTypeList = [
        {
          value: "",
          text: "--Select Sub Type --"
        },
        {
          value: "sole-proprietor",
          text: "Sole Proprietor"
        },
        {
          value: "general-partnership",
          text: "General Partnership"
        },
        {
          value: "limited-liability-partnership",
          text: "Limited Liability Partnership"
        },
        {
          value: "joint-venture",
          text: "Joint Venture"
        },
        {
          value: "single-member-company",
          text: "Single Member Company"
        },
        {
          value: "private-company-limited-by-shares",
          text: "Private Company Limited By Shares"
        },
        {
          value: "private-company-limited-by-guarantee-with-shares",
          text: "Private Company Limited By Guarantee With Shares"
        },
        {
          value: "private-company-limited-by-guarantee-without-shares",
          text: "Private Company Limited By Guarantee Without Shares"
        },
        {
          value: "public-company",
          text: "Public Company"
        },
        {
          value: "foreign-company",
          text: "Foreign Company"
        },
        {
          value: "unlimited-company",
          text: "Unlimited Company"
        },
        {
          value: "others",
          text: "Others"
        },
      ]
    },
    fetchBusinessTypeInfo() {
      this.subTypeList = [
        {
          value: "",
          text: "--Select Sub Type --"
        },
        {
          value: "sole-proprietor",
          text: "Sole Proprietor"
        },
        {
          value: "general-partnership",
          text: "General Partnership"
        },
        {
          value: "limited-liability-partnership",
          text: "Limited Liability Partnership"
        },
        {
          value: "joint-venture",
          text: "Joint Venture"
        },
      ]
    },
    fetchCompanyTypeInfo() {
      this.subTypeList = [
        {
          value: "",
          text: "--Select Sub Type--"
        },
        {
          value: "single-member-company",
          text: "Single Member Company"
        },
        {
          value: "private-company-limited-by-shares",
          text: "Private Company Limited By Shares"
        },
        {
          value: "private-company-limited-by-guarantee-with-shares",
          text: "Private Company Limited By Guarantee With Shares"
        },
        {
          value: "private-company-limited-by-guarantee-without-shares",
          text: "Private Company Limited By Guarantee Without Shares"
        },
        {
          value: "public-company",
          text: "Public Company"
        },
        {
          value: "foreign-company",
          text: "Foreign Company"
        },
        {
          value: "unlimited-company",
          text: "Unlimited Company"
        },
        {
          value: "others",
          text: "Others"
        },
      ]
    },
    fetchBuildingSocietyTypeInfo() {
      this.subTypeList = [
        {
          value: "",
          text: "--Select Sub Type--"
        },
        {
          value: "foreign-building-society",
          text: "Foreign Building Society"
        },
        {
          value: "association-of-personal-development",
          text: "Association Of Personal Development"
        },
        {
          value: "investment-club",
          text: "Investment Club"
        },
      ]
    },
    async fetchBrnBuildingSocietyInfo(brnData) {
      await api().get("/building-societies/societies/registry/" + brnData)
          .then((response) => {
            this.filterBuildingSocietyData(response.data.data)
          })
          .catch((error) => {
                if(error && error.response){
                this.loaderProgress = false
                 this.brnErrorMessage = error.response.data.message
                  }
          });
    },
    filterBuildingSocietyData(data) {
      if(data){

        this.form.registeredAddress.plot = data.address ? data.address.plot : null
        this.form.registeredAddress.street = data.address ? data.address.street : null
        this.form.dateOfIncorporation = data.dateOfIncorporation ? moment(data.dateOfIncorporation).utc().format() : null
        this.villageDataInfo = data.address.physicalAddress ? data.address.physicalAddress : {}
        this.form.registeredPhysicalAddressId = data.registeredPhysicalAddressId ? data.registeredPhysicalAddressId : null
        this.form.primaryEmail = data.primaryEmail ? data.primaryEmail : null

        this.form.registeredAddress.postalAddress = data.address ? data.address.postalAddress : null
        if(data.address && data.address.postalAddress){
          let boxNumber = data.address.postalAddress.split(" ");
          this.boxNumber = boxNumber[0]
          this.boxStation =  data.address.postalAddress.replace(boxNumber[0]+" ", "")
        }

        if(data.primaryPhone && data.primaryPhone.includes("(")){
          if(data.primaryPhone) {
            let phoneArray = data.primaryPhone.replace('(', '').split(")")
            this.countryCodeNumber = phoneArray[0]
            this.phoneNo = phoneArray[1]
            this.form.primaryPhone = `${"("+phoneArray[0]+")"}${phoneArray[1]}`;
          }
        }else {
          this.phoneNo = data.primaryPhone ? data.primaryPhone.substring(3) : ""
          this.countryCodeNumber = data.primaryPhone ? data.primaryPhone.slice(0, 3) : "256"
          this.form.primaryPhone = data.primaryPhone ? `${"("+data.primaryPhone.slice(0, 3)+")"}${data.primaryPhone.substring(3)}` : null
        }

        this.loaderProgress = false
      }
    },
    async fetchBrnCompanyNameInfo(brnData) {
      await api().get("/incorporation/companies/continuous-filing/basic-info-by-brn/" + brnData)
          .then((response) => {
            this.filterCompanyData(response.data.data)
          })
          .catch((error) => {
            if(error && error.response){
              this.loaderProgress = false
              this.brnErrorMessage =  error.response.data.message
            }
          });
    },
    filterCompanyData(data) {
      if(data){
        this.form.registeredAddress.plot = data.registeredAddress ? data.registeredAddress.plot : null
        this.form.registeredAddress.street = data.registeredAddress ? data.registeredAddress.street : null
        this.form.dateOfIncorporation = data.dateOfIncorporation ? moment(data.dateOfIncorporation).utc().format() : null
        this.villageDataInfo = data.registeredPhysicalAddress ? data.registeredPhysicalAddress : {}
        this.form.registeredPhysicalAddressId = data.registeredPhysicalAddressId ? data.registeredPhysicalAddressId : null
        this.form.primaryEmail = data.primaryEmail ? data.primaryEmail : null

        this.form.registeredAddress.postalAddress = data.registeredAddress ? data.registeredAddress.postalAddress : null

        if(data.registeredAddress && data.registeredAddress.postalAddress){
          let boxNumber = data.registeredAddress.postalAddress.split(" ");
          this.boxNumber = boxNumber[0]
          this.boxStation =  data.registeredAddress.postalAddress.replace(boxNumber[0]+" ", "")
        }


        if(data.primaryPhone && data.primaryPhone.includes("(")){
          if(data.primaryPhone) {
            let phoneArray = data.primaryPhone.replace('(', '').split(")")
            this.countryCodeNumber = phoneArray[0]
            this.phoneNo = phoneArray[1]
            this.form.primaryPhone = `${"("+phoneArray[0]+")"}${phoneArray[1]}`;
          }
        }else {
          this.phoneNo = data.primaryPhone ? data.primaryPhone.substring(3) : ""
          this.countryCodeNumber = data.primaryPhone ? data.primaryPhone.slice(0, 3) : "256"
          this.form.primaryPhone = data.primaryPhone ? `${"("+data.primaryPhone.slice(0, 3)+")"}${data.primaryPhone.substring(3)}` : null
        }

        this.loaderProgress = false
      }

    },
    async fetchBrnBusinessNameInfo(brnData) {
      await api().get("/business-registration/check/" + brnData )
          .then((response) => {
            this.filterBusinessNameData(response.data)
          })
          .catch((error) => {
            if(error && error.response) {
              this.loaderProgress = false
              this.brnErrorMessage = error.response.data.message
            }
          });
    },

    filterBusinessNameData(data) {
      this.form.registeredAddress.plot = data ? data.plot : null
      this.form.registeredAddress.street = data ? data.street : null
      this.form.dateOfIncorporation = data.dateOfIncorporation ? moment(data.dateOfIncorporation).utc().format() : null
      this.villageDataInfo = data.physicalAddress ? data.physicalAddress : {}
      this.form.registeredPhysicalAddressId = data.physicalAddress ? data.physicalAddress.village ? data.physicalAddress.village.id : "" : null
      this.form.primaryEmail = data.email ? data.email : null

      this.form.registeredAddress.postalAddress = data.postalAddress ? data.postalAddress : null

      if(data.postalAddress){
        let boxNumber = data.postalAddress.split(" ");
        this.boxNumber = boxNumber[0]
        this.boxStation =  data.postalAddress.replace(boxNumber[0]+" ", "")
      }

      if(data.phone && data.phone.includes("(")){
        if(data.phone) {
          let phoneArray = data.phone.replace('(', '').split(")")
          this.countryCodeNumber = phoneArray[0]
          this.phoneNo = phoneArray[1]
          this.form.primaryPhone = `${"("+phoneArray[0]+")"}${phoneArray[1]}`;
        }
      }else {
        this.phoneNo = data.phone ? data.phone.substring(3) : ""
        this.countryCodeNumber = data.phone ? data.phone.slice(0, 3) : "256"
        this.form.primaryPhone = data.phone ? `${"("+data.phone.slice(0, 3)+")"}${data.phone.substring(3)}` : null
      }


      this.loaderProgress = false
    },
    removePlotStreetMain(data) {
      if(data) {
        this.showPlotAndStreet = data === "UGANDA"
        if (data !== "UGANDA") {
          this.form.registeredAddress.plot = ""
          this.form.registeredAddress.street = ""

        }
      }
    },
    residenceInfo(data) {
      this.form.registeredPhysicalAddressId = data.villageId ? data.villageId : null
      this.form.countryId = data.countryId ? data.countryId : null
      this.form.addressInCountryOfIncorporation = data.foreignPhysicalAddress ? data.foreignPhysicalAddress : null
    },
    validateEmailAddress(email) {
      const re = /\S+@\S+\.\S+/;
      let emailResp = re.test(email);
      if(email) {
        this.emailErrorResponse = emailResp ? "" : "Please provide a valid email"
      }else {
        this.emailErrorResponse = ""
      }
    },
    checkCountryOfOrigin(data) {
      this.countryDataList(data)
      this.form.countryOfOrigin = data === "registered_in_uganda" ? "Uganda" : ""
    },
    countryDataList(data){
      this.countryListData = []
      let countryArray = []
      let CountryUg = {
        text: "Uganda",
        value: "Uganda"
      }
      let country = [...this.getCountryList]


      if (data === "registered_in_uganda") {
        countryArray.push(CountryUg);
      } else if (data === "other" || data === "mda") {
        countryArray = country;
        countryArray.splice(1, 0, CountryUg);
      } else {
        countryArray = country;
      }


      this.countryListData = countryArray

    },
    validatePostalAddress() {
      if (this.boxStation && this.boxNumber) {
        this.postalAddressStatusMsg = ""
        if(!this.form.registeredName) {
          this.addressColor = 'red'
          this.postalAddressStatusMsg = "Entity name Missing"
          return
        }

        this.loaderPostalProgress = true
        this.addressColor = null
// 22852
        let obj = {
          boxStation: this.boxStation,
          boxNumber: this.boxNumber,
          boxRenter: this.form.registeredName ? this.form.registeredName.replace(/\s{2,}/g, ' ') : null
        }

        this.form.registeredAddress.postalAddress = this.boxNumber+" "+this.boxStation
        this.fetchPostalAddressData(obj)
      } else {
        this.addressColor = ""
        this.postalAddressStatusMsg = ""
        this.form.registeredAddress.postalAddress = null;
        this.removePostalAddressError();
      }
    },
    async fetchPostalAddressData(brnData) {
      await api().post("/integration/api/eposta/verify/company" , brnData)
          .then((response) => {
            if(response) {
              this.loaderPostalProgress = false
              this.postalAddressStatusMsg = "Postal address belongs to: <br /><b>"+response.data.data.box_renter+"</b>"
              this.addressColor = "green"

              this.removePostalAddressError()
            }
          })
          .catch((error) => {
            if(error && error.response){
              this.loaderPostalProgress = false
              this.postalAddressStatusMsg = error.response.data.message+(error.response.data.data ? ", <br /><b>Renter:"+error.response.data.data.box_renter+"</b>" : "")
              this.addressColor = "red"

              //save error message when postal address is wrong
              let errorArray = JSON.parse(sessionStorage.getItem("postalAddressError"));
              if (!errorArray.includes(this.form.brn)) {
                errorArray.push(this.form.brn);
              }
              sessionStorage.setItem("postalAddressError", JSON.stringify(errorArray));
              this.arrayData = errorArray;
              ///////

            }
          });
    },
    removePostalAddressError() {
      let errorArray = JSON.parse(sessionStorage.getItem("postalAddressError"));
      let index = errorArray.indexOf(this.form.brn);
      if (index > -1) {
        errorArray.splice(index, 1);
      }
      sessionStorage.setItem("postalAddressError", JSON.stringify(errorArray));
      this.arrayData = errorArray;
    },
    lockToUgandaAndDisable(){
      this.registrationTypeList = []
    this.registrationTypeList = [
        {
          value: "",
          text: "--Select Registration --"
        },
        {
          value: "registered_in_uganda",
          text: "Registered in Uganda"
        },]
      //this.lockedToUganda = true
    }
  },
  computed: {
    ...mapGetters(
        [
          "getCountryList",
          "getStationsResults"
        ]
    )
  },
  watch: {
    form: {
      handler(data) {
        this.$emit("corporateData", {data: data, index: this.index})
      },
      deep: true
    },
    selectedPositionData: {
      handler(data) {
        if(data){
          this.checkPosition()
        }

      },
      deep: true
    },
    subscriberList() {
      this.checkEntityInfo()
    },
    getStationsResults(data){
      if(data){
        if(data.data.length > 0){
          let obj = {}
          let arr = [{text: "--Select Station--", value: ""}]
          data.data.forEach(lst => {
            obj = {text: lst.branch_name, value: lst.branch_name}
            arr.push(obj)
          })
          this.stationList = arr
        }

      }
    }
  }
}
</script>

<style scoped>
.countryCodeStyle, .countryCodeStyle:hover {
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
}
</style>