import { render, staticRenderFns } from "./AllReservationSubmissions.vue?vue&type=template&id=1794f32c&scoped=true&"
import script from "./AllReservationSubmissions.vue?vue&type=script&lang=js&"
export * from "./AllReservationSubmissions.vue?vue&type=script&lang=js&"
import style0 from "./AllReservationSubmissions.vue?vue&type=style&index=0&id=1794f32c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1794f32c",
  null
  
)

export default component.exports