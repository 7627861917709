import {api} from "@/app/services/api.service";

const state = {
    dispute_resolutions: null,
    dispute_resolutions_scheduled: null,
    escalations: [],
    dispute_resolutionStatuses: null,
    dispute_resolution_task: {},
    dispute_resolution_task_error: null,
    dispute_resolution_details: null,
    dispute_resolution_stats: {},
    dispute_resolution_count: {},
    success: {},
    successAssignmentDr: {},
    disputeSuccessEscalation: {},
    errorAssignment: {},
    successStatusUpdate: {},
    dispute_error: null,
};

const getters = {
    getDisputeResolutionSubmissions: (state) => state.dispute_resolutions,
    getScheduledDisputeResolutionSubmissions: (state) => state.dispute_resolutions_scheduled,
    getDisputeResolutionEscalations: (state) => state.escalations,
    getDisputeResolutionTask: (state) => state.dispute_resolution_task,
    getDisputeResolutionTaskError: (state) => state.dispute_resolution_task_error,
    getDisputeResolutionDetails: (state) => state.dispute_resolution_details,
    getDisputeResolutionStatistics: (state) => state.dispute_resolution_stats,
    getDisputeResolutionStatuses: (state) => state.dispute_resolutionStatuses,
    getDisputeResolutionStatusUpdate: (state) => state.successStatusUpdate,
    getDisputeResolutionCount: (state) => state.dispute_resolution_count,
    getDisputeResolutionSuccess: (state) => state.success,
    getDisputeResolutionEscalationSuccess: (state) => state.disputeSuccessEscalation,
    getDisputeResolutionAssignmentSuccess: (state) => state.successAssignmentDr,
    getDisputeResolutionAssignmentError: (state) => state.errorAssignment,
    getDisputeResolutionError: (state) => state.dispute_error,
    getDisputeResolutionEscalationError: (state) => state.dispute_error,
};

const actions = {
    async submitDisputeResolutionApplicationPayment({commit}, data) {
        await api().post("dispute-resolution/disputes/add-payment", data)
            .then((response) => {
                commit("setDisputeResolution", response.data);
            })
            .catch((error) => {
                commit("setDisputeResolutionTaskError", error.response);
            });
    },
    async fetchDisputeResolutionSubmissions({commit}, data) {
        let path = "/dispute-resolution/disputes?status=" + data.statuses + "&page=" + data.page + "&pageSize=" + data.limit
        if (data.search !== "") {
            path += "&search=" + data.search
        }
        api().get(path).then(response => {
            commit('setDisputeResolutions', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    async fetchDisputeResolutionEscalations({commit}, data) {
        api().get("/dispute-resolution/disputes/queue/my-escalations?status=" + data.status + "&page=" + data.page + "&perPage=" + data.limit).then(response => {
            commit('setCFEscalations', response.data);
        }).catch(error => {
            commit("setDisputeResolutionEscalationError", error.response)
        });
    },
    async fetchDisputeResolutionStatuses({commit}, id) {
        api().get("/dispute-resolution/disputes/tasks/" + id + "/thread").then(response => {
            commit('setDisputeResolutionStatuses', response);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    async fetchNextDisputeResolution({commit},params = null) {
        commit('setDisputeResolutionToNull');
        let url = "/dispute-resolution/disputes/tasks/next";
        if(params){
            url = url+params
        }

        api().get(url).then(response => {
            commit('setDisputeResolution', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    async fetchDisputeResolutionDetails({commit}, trackingNumber) {
        api().get("/dispute-resolution/disputes/" + trackingNumber + "/tracking-number").then(response => {
            commit('setDisputeResolutionDetails', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    async fetchDisputeResolutionStats({commit}) {
        api().get("/dispute-resolution/disputes/tasks/status-statistics").then(response => {
            commit('setDisputeResolutionStats', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    async fetchDisputeResolutionCount({commit}) {
        api().get("/dispute-resolution/disputes/tasks/count").then(response => {
            commit('setDisputeResolutionCount', response);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    updateDisputeResolutionStatus({commit}, data) {
        api().post("/dispute-resolution/disputes/tasks/" + data.id+"/task",     {status: data.status,
            rationale:data.rationale
    }).then(response => {
            commit('setDisputeResolutionStatusUpdated', response.data);
        }).catch(error => {
            commit("setDisputeResolutionAssignmentError", error.response)
        });
    },
    approveDisputeResolutionApplication({commit}, data) {
        api().post("/dispute-resolution/disputes/tasks/" + data.get("id") + "/receive", data).then(response => {
            commit('setDisputeResolutionStatusUpdated', response.data);
        }).catch(error => {
            commit("setDisputeResolutionAssignmentError", error.response)
        });
    },
    queryDisputeResolutionChargeStatus({commit}, data) {
        api().post("/dispute-resolution/disputes/queue/query-for-payment", data).then(response => {
            commit('setDisputeResolutionStatusUpdated', response.data);
        }).catch(error => {
            commit("setDisputeResolutionAssignmentError", error.response)
        });
    },
    rejectDisputeResolutionChargeStatus({commit}, data) {
        api().post("/dispute-resolution/disputes/queue/query-for-payment", data).then(response => {
            commit('setDisputeResolutionStatusUpdated', response.data);
        }).catch(error => {
            commit("setDisputeResolutionAssignmentError", error.response)
        });
    },
    assignDisputeResolutionTask({commit}, data) {
        api().post("/dispute-resolution/disputes/tasks/assign-task", data).then(response => {
            commit('setDisputeResolutionAssignmentAction', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    assignEscalationDisputeResolutionTask({commit}, data) {
        api().post("/dispute-resolution/disputes/escalation/queue/re-assign", data).then(response => {
            commit('setDisputeResolutionAssignmentAction', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    escalateDisputeResolutionTask({commit}, data) {
        api().post("/dispute-resolution/disputes/tasks/"+data.taskId+"/escalate", data).then(response => {
            commit('setDisputeResolutionEscalationAction', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    reEscalateDisputeResolutionTask({commit}, data) {
        api().post("/dispute-resolution/disputes/queue/escalate", data).then(response => {
            commit('setDisputeResolutionEscalationAction', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
    async fetchScheduledDisputes({commit}, data) {
        let path = "/dispute-resolution/scheduled/events?status=" + data.statuses + "&page=" + data.page + "&size=" + data.limit +"&type="+data.type
    /*    if (data.search) {
            path += "&search=" + data.search
        }*/
        api().get(path).then(response => {
            commit('setScheduledDisputeResolutions', response.data);
        }).catch(error => {
            commit("setDisputeResolutionError", error.response)
        });
    },
};

const mutations = {
    setDisputeResolutions: (state, response) => {
        state.dispute_resolutions = response;
    }, setScheduledDisputeResolutions: (state, response) => {
        state.dispute_resolutions_scheduled = response;
    },
    setCFEscalations: (state, response) => {
        state.escalations = response;
    },
    setDisputeResolution: (state, response) => {
        state.dispute_resolution_task = response;
    },
    setDisputeResolutionTaskError: (state, error) => {
        state.dispute_resolution_task_error = error;
    },
    setDisputeResolutionDetails: (state, response) => {
        state.dispute_resolution_details = response;
    },
    setDisputeResolutionToNull: (state) => {
        state.dispute_resolution = null;
    },
    setDisputeResolutionStatuses: (state, response) => {
        state.dispute_resolutionStatuses = response.data;
    },
    setDisputeResolutionStats: (state, response) => {
        state.dispute_resolution_stats = response;
    },
    setDisputeResolutionCount: (state, response) => {
        state.dispute_resolution_count = response.data;
    },
    setDisputeResolutionAction: (state, response) => {
        state.success = response;
    },
    setDisputeResolutionAssignmentAction: (state, response) => {
        state.successAssignmentDr = response;
    },
    setDisputeResolutionStatusUpdated: (state, response) => {
        state.successStatusUpdate = response;
    },
    setDisputeResolutionEscalationAction: (state, response) => {
        state.disputeSuccessEscalation = response;
    },
    setDisputeResolutionError: (state, error) => {
        state.dispute_error = error.data;
    },
    setDisputeResolutionEscalationError: (state, error) => {
        state.dispute_error = error.data;
    },
    setDisputeResolutionAssignmentError: (state, error) => {
        state.errorAssignment = error;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};