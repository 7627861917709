<template>
  <div class="bg-white rounded-sm mx-3 my-3">
    <b-row>
      <b-col cols="12" class="mt-3">
        <b-row class="mx-1">
          <b-col cols="11">
            <span class="title mx-1">Legal Document</span>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <b-row class="mx-3 mt-2">
      <b-col cols="12" class="mx-auto bg-header_grey py-3 rounded">
        <span class="cursor-pointer text-blue hover:text-blue-dark hover:underline" @click="back">Back</span> <span class="mx-3 text-gray">Document / {{instrumentNumber}} / {{cleanDocumentType(document.documentType.toUpperCase())}}</span>
      </b-col>
    </b-row>

    <b-row class="mx-3 mt-2">
      <b-col>
        <b-row>
          <b-col cols="12" class="mt-2">
            <span class="font-bold text-gray h4">{{document.subjectMatter}}</span><br/>
            <div class="flex my-1">
              <b-badge class="bg-blue text-capitalize">
                {{cleanDocumentType(document.documentType.toLowerCase())}}
              </b-badge>
            </div>
            <span class="text-gray text-xs font-bold" >Registration Date: {{dateFormat(document.filingDate)}}</span><br/>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === 'details') ? 'menu-item-active' : 'menu-item']" @click="select('details')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Details</div>
                </div>
              </div>
              <div :class="[(currentRoute === 'attachment') ? 'menu-item-active' : 'menu-item']" @click="select('attachment')">
                <div class="flex mt-6">
                  <div style="font-size: 14px">Attachment</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="py-4">
          <b-col cols="12">
            <LegalDocumentDetails :document="document" v-if="currentRoute === 'details'"/>
            <RegisteredLegalDocument :instrument-number="document.instrumentNumber" v-if="currentRoute === 'attachment'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import LegalDocumentDetails from "@/views/home/services/registry/legal-documents/tabs/LegalDocumentDetails";
import router from "@/router";
import RegisteredLegalDocument from "@/views/home/services/registry/legal-documents/tabs/RegisteredLegalDocument.vue";

export default {
  name: "LegalDocumentFileWrapper",
  components: { RegisteredLegalDocument, LegalDocumentDetails},
  data(){
    return{
      instrumentNumber:null,
      loading: false,
      document:null,
      currentRoute:"details"
    }
  },
  mounted(){
    this.instrumentNumber = this.$route.params.id
    this.fetchLegalDocumentDetails(this.instrumentNumber)
  },
  methods:{
    ...mapActions(["fetchLegalDocumentDetails"]),
    select(route){
      this.currentRoute = route
    },
    cleanDocumentType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    back() {
      router.push({name:"document-registry"})
    }
  },
  computed:{
    ...mapGetters(["getLegalDocumentDetails"])
  },
  watch:{
    getLegalDocumentDetails(data){
      this.document = data
    }
  }
};
</script>

<style scoped>

</style>