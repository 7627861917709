<template>
  <b-col>
    <b-row>
      <b-col cols="12" class="mx-auto mt-4">
        <table class="w-full leading-normal text-gray"
               style="border: solid 1px #dedede;">
          <thead class="bg-gray-pale" >
          <tr>
            <th colspan="12" class="py-2">
              <span class="px-2">Partner(s)</span>
            </th>
          </tr>
          </thead>
          <tbody>
            <PartnerDetails v-if="getBusinessPartners" class="p-1"
                            :individuals="getBusinessPartners.individualPartners"
                            :corporates="getBusinessPartners.corporatePartners"/>
            <tr v-else>
              <td colspan="8" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PartnerDetails from "@/views/home/services/registry/business/files/tabs/business_name_data/PartnerDetails";

export default {
  name: "BusinessNamePartners",
  props:{brn:String},
  components: {PartnerDetails},
  mounted() {
    this.fetchBusinessNamePartners(this.brn)
  },
  methods:{
    ...mapActions(["fetchBusinessNamePartners"])
  },
  computed:{
    ...mapGetters(["getBusinessPartners"])
  }
}
</script>

<style scoped>

</style>