<template>
  <div>
    <b-row>
      <b-col cols="5" class="mb-3">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Attachments</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">#</th>
                    <th class="px-2 py-1">File Name</th>
                    <th class="px-2 py-1 float-end">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(file, idx) in application.attachments" :key="idx" style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">{{idx + 1}}</td>
                    <td class="px-2 py-1 text-capitalize">{{cleanName(file.purpose)}}</td>
                    <td class="px-2 py-1">
                      <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="7">
        <DispensationAttachments v-if="application.attachments.length > 0" ref="attachmentScreen"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import DispensationAttachments from "@/views/home/services/dispensation/task/DispensationAttachments.vue";
import server from "@/app/env/server.json";

export default {
  name: "DispensationFormFilesEscalated",
  components: {DispensationAttachments},
  props:{
    application:Object
  },
  data(){
    return{
      attachmentUrl:null
    }
  },
  mounted() {
    this.openFile(this.application.attachments[0])
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    openFile(data){
      let fName = data ? data.attachment.trim() : ""
      this.attachmentUrl = server.IP+"/incorporation/companies/downloads?attachment="+fName
      this.$refs.attachmentScreen.openFile(this.attachmentUrl)
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>