<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Notice Of Cessation
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <p v-if="data.hasCertificate" class="border border-gray rounded-sm bg-header_grey px-2 py-2 my-3">
           <i class="fa fa-info-circle text-gray mr-2"></i> I have the Original Certificate and I intend to cease its use on ceasing of this business name
          </p>
          <p v-else class="border border-gray rounded-sm bg-header_grey px-2 py-2 my-3">
            <i class="fa fa-info-circle text-gray mr-2"></i> I do not have the Original Certificate and I have attached a police letter.
          </p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";

export default {
  name: "NoticeOfCessation",
  props:{
    data:Object,
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>

</style>