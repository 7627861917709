 <template>
  <b-row>
      <b-col>
        <b-row class="mx-1 my-2">
          <b-col cols="12" >
            <b-row v-for="(message, idx) in  getBNDataUpdateTaskMessages" :key="idx">
              <b-col cols="6" class="py-2 rounded-md bg-header_grey my-2 text-gray">
                <b-row>
                  <b-col>
                    <span class="text-sm font-bold text-capitalize" v-if="message.user">{{message.user.firstName}} {{message.user.lastName}}
                      <b-badge class="bg-green text-capitalize" v-if="message.user">
                       {{(message.user.accountType === 'INDIVIDUAL') ? 'Applicant' : 'URSB' }}
                      </b-badge>
                    </span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="text-sm">{{message.description}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span style="font-size:9px" class="text-blue italic">{{message.createdAt}} | {{message.statusLabel}}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="getBNDataUpdateTaskMessages.length === 0">
              <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
                <b-row>
                  <b-col>
                    <span class="text-xs font-bold">No messages yet</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BusinessNameDataUpdateDetailsMessages",
  props:{
    id:String
  },
  mounted(){
    this.fetchDataUpdateMessages(this.id)
  },
  methods:{
    ...mapActions(["fetchDataUpdateMessages"])
  },
  computed:{
    ...mapGetters(["getBNDataUpdateTaskMessages"])
  },
}
</script>

<style scoped>

</style>