<template>
    <b-row>
        <b-col>
            <b-row>
                <b-col cols="12">
                    <div class="flex h-full" style="cursor: pointer;">

                        <div :class="[(currentRoute === 'appointments') ? 'menu-item-active' : 'menu-item']" @click="select('appointments')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Appointments</div>
                            </div>
                        </div>

                        <div :class="[(currentRoute === 'info') ? 'menu-item-active' : 'menu-item']" @click="select('info')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Practitioner Information</div>
                            </div>
                        </div>
                        <div :class="[(currentRoute === 'annual-returns') ? 'menu-item-active' : 'menu-item']" @click="select('annual-returns')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Annual Returns</div>
                            </div>
                        </div>
                        <div :class="[(currentRoute === 'renewals') ? 'menu-item-active' : 'menu-item']" @click="select('renewals')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Renewals</div>
                            </div>
                        </div>
                        <!--           <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']" @click="select('attachments')">
                                    <div class="flex mt-6">
                                      <div style="font-size: 14px">Filing History</div>
                                    </div>
                                  </div>
                                  <div :class="[(currentRoute === 'complaints') ? 'menu-item-active' : 'menu-item']" @click="select('complaints')">
                                    <div class="flex mt-6">
                                      <div style="font-size: 14px">Complaints</div>
                                    </div>
                                  </div>-->
                    </div>
                </b-col>
            </b-row>
            <b-row class="my-2">
                <b-col>
                    <b-row v-if="currentRoute === 'appointments'">
                        <b-col cols="12">
                            <PractitionerAppointments :upn="draft.no"></PractitionerAppointments>
                        </b-col>
                    </b-row>


                    <b-row v-if="currentRoute === 'annual-returns'">
                        <b-col cols="12">
                            <PractitionerAnnualReturnsDetails :upn="draft.no"></PractitionerAnnualReturnsDetails>
                        </b-col>
                    </b-row>

                    <b-row v-if="currentRoute === 'renewals'">
                        <b-col cols="12">
                            <PractitionerRenewals :upn="draft.no"></PractitionerRenewals>
                        </b-col>
                    </b-row>

                    <b-row v-if="currentRoute === 'info'">
                        <b-col cols="12">
                            <b-row class="mt-4">
                                <b-col cols="6">
                                    <span class="h5">Bio</span>
                                </b-col>
                                <b-col cols="6">
                                    <span class="h5">Professional Conduct</span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    <b-row>
                                        <b-col cols="6" v-if="draft">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Surname"
                                                    label-for="input-1">
                                                <b-form-input
                                                        class="form-text text-sm text-uppercase noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.surName"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6" v-if="draft">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Given Name"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-xs text-uppercase noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.givenName"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6" v-if="draft">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Phone Number"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.phoneNumber"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6" v-if="draft">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Email"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.emailAddress"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="6" v-if="draft">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Gender"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.sex"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6" v-if="draft">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Date Of Birth"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="dateFormat(draft.dateOfBirth)"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" v-if="draft">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Postal Address"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.postalAddress"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="6">
                                    <b-row class="mt-3">
                                        <b-col cols="12">
                                            <small class="text-sm font-bold mr-3">Applicant Subjected To Any Disciplinary Proceedings</small>
                                            <small :class="(draft.profConductInfo.disciplined) === 'YES' ? 'bg-green' : 'bg-red'" class="px-2 py-1 rounded-sm text-white text-xs my-auto" v-if="draft && draft.profConductInfo">{{draft.profConductInfo.disciplined}}</small><br/>
                                            <span class="text-sm ml-3" v-if="draft">{{draft.profConductInfo ? draft.profConductInfo.disciplinedDetails : ""}}</span>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            <small class="text-sm font-bold mr-3">Applicant Disqualified From Practicing Insolvency</small>
                                            <small  :class="(draft.profConductInfo.prohibited) === 'YES' ? 'bg-green' : 'bg-red'" class="px-2 py-1 rounded-sm text-white text-xs my-auto" v-if="draft">{{draft.profConductInfo ? draft.profConductInfo.prohibited : ""}}</small><br/>
                                            <span class="text-sm ml-3" v-if="draft">{{draft.profConductInfo ? draft.profConductInfo.prohibitedDetails : ""}}</span>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12">
                                            <small class="text-sm font-bold mr-3">Applicant Subjected to Any Prohibition Under the Law</small>
                                            <small :class="(draft.profConductInfo.disqualified) === 'YES' ? 'bg-green' : 'bg-red'" class="px-2 py-1 rounded-sm text-white text-xs my-auto" v-if="draft">{{draft.profConductInfo ? draft.profConductInfo.disqualified : ""}}</small><br/>
                                            <span class="text-sm ml-3" v-if="draft">{{draft.profConductInfo ? draft.profConductInfo.disqualifiedDetails : ""}}</span>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <br />
                            <br />
                            <hr />
                            <b-row class="mt-4">
                                <b-col>
                                    <span class="h5">Address</span>
                                </b-col>
                                <b-col>
                                    <span class="h5">Bank Information</span>
                                </b-col>
                            </b-row>


                            <b-row>
                                <b-col>
                                    <b-row v-if="draft">
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Country"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.physicalAddress.country.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>

                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="District"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.physicalAddress.district.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>

                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Parish"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.physicalAddress.parish.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Region"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.physicalAddress.region.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>

                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Sub County"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.physicalAddress.subcounty.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>

                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Village"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.physicalAddress.village.name"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col v-if="draft">
                                    <b-row>
                                        <b-col cols="3">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Bank Name"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.bankInformation.bankName"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Account Name"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.bankInformation.accountName"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="3">
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Account Number"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="draft.bankInformation.accountNumber"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <br />
                            <br />
                            <hr />

                            <b-row class="mt-4">
                                <b-col>
                                    <span class="h5">Qualifications</span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col v-if="draft">
                                    <b-row v-for="(item, idx) in draft.qualificationInfo" :key="idx">
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Award Type"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.awardName"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Award"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.award"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Awarding Institution"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.awardingBody"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Year Of Award"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.year"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <br />
                            <br />
                            <hr />

                            <b-row class="mt-4">
                                <b-col>
                                    <span class="h5">Professions</span>
                                </b-col>
                            </b-row>
                            <b-row v-if="draft">
                                <b-col>
                                    <b-row v-for="(item, idx) in draft.professionalInfo" :key="idx">
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Profession"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.professionalQualificationName"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Professional Body"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.professionalBodyName"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Membership ID"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.memberShipNumber"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Membership Expiry"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="dateFormat(item.expiryOfMemberShip)"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <br />
                            <br />
                            <hr />

                            <b-row class="mt-4">
                                <b-col>
                                    <span class="h5">Indemnity</span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col v-if="draft">
                                    <b-row v-for="(item, idx) in draft.professionalIndemnity" :key="idx">
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Insurance Policy Holder"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.insurancePolicyHolder"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Insurer"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.insurer"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Insurance Cover Duration"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.insuranceCoverDuration"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Value of Insurance"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="item.valueOfInsurance"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="Start Date"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="dateFormat(item.dateOfIssuance)"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group
                                                    class="text-sm my-1"
                                                    id="input-group-1"
                                                    label="End Date"
                                                    label-for="input-1">
                                                <b-form-input
                                                        id="companyName"
                                                        class="form-text text-uppercase text-sm noFocus"
                                                        type="text"
                                                        :disabled="true"
                                                        :value="dateFormat(item.expiryOfInsurance)"
                                                        required>
                                                </b-form-input>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>


                            <br />
                            <br />
                            <hr />

                        </b-col>
                    </b-row>
                    <b-row v-if="currentRoute === 'attachments'">
                        <!--            <b-col cols="5" class="mb-3">
                                      <b-row v-if="draft">
                                        <b-col cols="12">
                                          <b-row v-for="(form, idx) in form1s" :key="idx" class="mb-4">
                                            <b-col cols="12">
                                              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                                                <thead>
                                                <tr class="bg-gray-pale">
                                                  <td v-if="form.status.toUpperCase() === 'APPROVED'" colspan="12" class="px-2 py-2 font-bold text-capitalize">{{ form.applicationType.toLowerCase() }} Documents - {{ form1Year(form) }}</td>
                                                  <td v-else colspan="12" class="px-2 py-2 font-bold text-capitalize">{{ form.applicationType.toLowerCase() }} Attachments - {{ form1Year(form) }}</td>
                                                </tr>
                                                <tr>
                                                  <th class="px-2 py-1">#</th>
                                                  <th class="px-2 py-1">File Name</th>
                                                  <th class="px-2 py-1 float-end">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(file, idx) in form.form1AttachmentList" :key="idx" style="border: solid 1px #dedede;">
                                                  <td class="px-2 py-1">{{idx + 1}}</td>
                                                  <td class="px-2 py-1 text-capitalize">{{cleanName(file.insolvencyDocument.replace(".PDF", ""))}}</td>
                                                  <td class="px-2 py-1">
                                                    <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                                                  </td>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                      </b-row>
                                    </b-col>-->
                        <b-col cols="7">
                            <!--              <PractitionerAttachments ref="attachmentScreen"/>-->
                        </b-col>
                    </b-row>

                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import moment from "moment/moment";
import server from "../../../../../app/env/server.json";
import {mapActions, mapGetters} from "vuex";
import PractitionerAnnualReturnsDetails
    from "@/views/home/services/registry/practitioners/PractitionerAnnualReturnsDetails.vue";
import PractitionerAppointments from "@/views/home/services/registry/practitioners/PractitionerAppointments.vue";
import PractitionerRenewals from "@/views/home/services/registry/practitioners/PractitionerRenewals.vue";

export default {
    name: "PractitionerDetails",
    components: {
        PractitionerRenewals,
        PractitionerAppointments,
        PractitionerAnnualReturnsDetails

    },
    props:{
        practitioner: {},
        upn:String,
    },
    data(){
        return{
            currentRoute:"appointments",
            attachmentUrl:null,
            address:null,
            draft:null
        }
    },
    mounted() {
        this.filterDraftInfo()
    },
    methods:{
        ...mapActions(["fetchAddressByVillageId"]),
        dateFormat(date){
            return moment(date).format("DD-MM-YYYY")
        },
        select(route){
            this.currentRoute = route
        },
        openFile(data){
            this.attachmentUrl = server.IP+"/insolvency/api/practitioner-registration/attachment/"+data.filename
        },
        openCertificate(){
            this.attachmentUrl = server.IP+"/insolvency/api/practitioners/certificate?upn="+this.upn
        },
        cleanName(name){
            return name.replaceAll(".pdf", "")
        },
        form1Year(form) {
            return moment(form.registrationDate).year();
        },
        filterDraftInfo(){
            if(this.practitioner && this.practitioner.id){
                this.draft = this.practitioner
            }
        }
    },
    computed:{
        ...mapGetters(["getAddress"]),
        practitionerGender() {
            if (this.form1s.length === 0) {
                return null
            }

            return this.form1s[0].gender
        },
        practitionerFullName() {
            if (this.form1s.length === 0) {
                return null
            }

            return `${this.form1s[0].firstName} ${this.form1s[0].lastName}`
        },
        isDisciplined() {
            if (this.data === null) {
                return false
            }
            return this.data.profConductDetails.find(c=>{
                return c.subjection === 'SUBJECTED_TO_DISCIPLINARY_PROCEEDING' && c.detailedInformation !== null
            }) !== undefined
        },
        isProhibited() {
            if (this.data === null) {
                return false
            }
            return this.data.profConductDetails.find(c=>{
                return c.subjection === 'SUBJECTED_TO_PROHIBITION' && c.detailedInformation !== null
            }) !== undefined
        },
        isDisqualified() {
            if (this.data === null) {
                return false
            }
            return this.data.profConductDetails.find(c=>{
                return c.subjection === 'DISQUALIFIED_FROM_INSOLVENCY' && c.detailedInformation !== null
            }) !== undefined
        },
    },
    watch:{
        practitioner(data){
            if(data){
                this.filterDraftInfo()
            }
        },
        /* data: {
           handler(d) {
             if (d !== null) {
               this.fetchAddressByVillageId(d.villageId)
             }
           },
           deep:true
         },
         getAddress(data){
           this.address = data.data
         },*/
        attachmentUrl:{
            handler(url){
                this.$refs.attachmentScreen.openFile(url)
            },
            deep:true
        }
    },
};
</script>

<style scoped>
th, td{
    font-size: 12px;
}
</style>