<template>
  <b-row>
    <b-col>

    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DataCorrectionRequest",
  props:{
    trackingNumber:String
  }
};
</script>

<style scoped>

</style>