<template>
  <div class="bg-white rounded-sm m-2 mt-4 overflow-hidden">
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Track Application</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="py-3">
        <b-row class="mt-3 mx-1">
          <b-col cols="6" class="mx-auto">
            <b-input-group  style="border: 1px solid #ced4da;  border-radius: 25px;">
              <b-input-group-prepend
                  class="pl-2"
                  style="
                    background: #ffffff;
                     border-radius:  25px 0 0 25px;">
                <b-input-group-text class="mt-2 border-0" style="background: #ffffff; color: #959595;">
                  <i class="fas fa-search"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                  v-model="applicationName"
                  placeholder="Enter Tracking Number"
                  class="search-text border-0 removeOnClickBorder"
                  type="text">
              </b-form-input>
              <b-input-group-append>
                <b-button
                    @click="search"
                    class="border-0 removeOnClickBorder px-3"
                    style="
                            height: 43px;
                            color: #ffffff;
                            background: #157ec2;
                            border-radius: 0 25px 25px 0;">
                  <span>Search</span>
                  <b-spinner small v-if="loading" class="ml-1"></b-spinner>
                </b-button>
              </b-input-group-append>
              <b-form-valid-feedback style="color: red;" >
                Looks Good.
              </b-form-valid-feedback>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <tracking-tree></tracking-tree>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import TrackingTree from "@/views/home/services/tracking/TrackingTree";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Tracking",
  components: {TrackingTree},
  data(){
    return{
      applicationName:null,
      searchError:"",
      userProfile:{},
      loading:false
    }
  },
  mounted() {
    this.userProfile = this.$userProfile
    this.applicationName= this.$route.query.q;
    if(this.$route.query.q) {
      this.doTrackApplication(this.appendQueryParams({"trackingNo": this.$route.query.q}))
    }
  },
  methods:{
    ...mapActions(["doTrackApplication"]),
    search() {
      this.searchError = ""
      if (this.applicationName) {
        this.loading = true
        this.doTrackApplication(this.appendQueryParams({"trackingNo":this.applicationName}))
      }
    },
  },
  computed:{
    ...mapGetters(["getTrackResults","getTrackError"])
  },
  watch:{
    getTrackResults(){
      this.loading = false
    },
    getTrackError(data){
      this.loading = false
      if(data){
        this.errorToast("Error", data.message)
      }
    }
  }
}
</script>

<style scoped>

.topBar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #f0f2f5;
  color: white;
  border-bottom: 1px solid #eaecef;
}


.button {
  @apply text-center mr-2 rounded-sm bg-white text-blue border-2 border-blue px-5 py-1 font-bold;
  font-size: 14px;
}

.button:hover {
  @apply bg-header_grey text-blue-dark border-blue-dark;
}

.removeOnClickBorder:focus {
  outline: none 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.removeOnClickBorder {
  box-shadow: none !important
}

.pointer-cursor{
  cursor: pointer;
}

.search-text{
  border-radius: 0 0 0 0;
  color: #959595;
  height: 43px;
}

</style>