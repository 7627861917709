<template>
  <b-row>
    <b-col cols="12">
      <b-row class="py-1 border-b-2 border-grey">
        <b-col>
          <b-row>
            <b-col cols="12">
              <b-row  class="mt-1">
                <b-col>
                  <b-badge :class="(entityType === 'CORPORATE') ? 'bg-blue' : 'bg-orange'">#{{entityType.toLowerCase()}}</b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" v-if="entityType === 'CORPORATE'">
              <b-row>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Entity Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.registeredName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Registration Number (BRN)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.brn"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.primaryEmail"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Official Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.primaryPhone"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Incorporation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.dateOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Company Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="cleanEntityType(person.subType)"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.countryOfOrigin"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Address in Country of Origin"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.addressInCountryOfIncorporation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      rows="4"
                      disabled="true"
                      :value="person.postalAddress"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" v-if="entityType === 'INDIVIDUAL'">
              <b-row>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Given Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.givenName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.middleName"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Middle Name"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.middleName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Surname"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.surName"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.idType"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Type"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.idType.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="ID Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.idNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Nationality"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.nationality"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Email Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.email"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Phone Number"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.phoneNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    v-if="person.sex"
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Gender (at birth)"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus text-capitalize"
                      type="text"
                      disabled="true"
                      :value="person.sex.toLowerCase()"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Date of Birth"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="dateFormat(person.dateOfBirth)"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Occupation"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.occupation"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col v-if="person.physicalAddress">
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="District"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.physicalAddress.district.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Region"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.physicalAddress.region.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Sub county"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.physicalAddress.subcounty.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Parish"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.physicalAddress.parish.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Village"
                    label-for="input-1">
                    <b-form-input
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.physicalAddress.village.name"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Postal Address"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.postalAddress"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Street"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.street"
                      required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="text-sm my-1"
                    id="input-group-1"
                    label="Plot"
                    label-for="input-1">
                    <b-form-input
                      id="companyName"
                      class="form-text text-sm noFocus"
                      type="text"
                      disabled="true"
                      :value="person.plotNumber"
                      required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";

export default {
  name: "LegalPerson",
  props:{
    person:Object,
    entityType:String
  },
  methods:{
    dateFormat(date){
      if(date){
        return moment(date).format("DD/MM/YYYY")
      }
      return ""
    },
    cleanEntityType(name){
      if(name){
        let n = name.replaceAll("-", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
  }
}
</script>

<style scoped>

</style>