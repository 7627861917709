<template>
  <div>
    <b-row>
      <b-col class="mt-3">

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <router-view></router-view>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: "InsolvencyTab",

}
</script>

<style scoped>

</style>