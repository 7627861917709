<template>
  <b-row>
    <b-col class="my-3">
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Statement of Intent of Conversion to LLP
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <b-alert variant="success" show>
            <i class="fa fa-file-archive mr-2"></i>
            <span>
              The business name has decided to convert to a Limited Liability Partnership with immediate effect.
              This action on approval will cause the name of this Business Name to be updated by adding the prefix <b>LLP</b>
              to the end.
            </span>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="11" class="mx-auto">
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Contributions</td>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border: solid 1px #dedede;">
                    <td class="t-body">Partner liability</td>
                    <td class="t-body">{{data.contributions.partnerLiability}}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr style="border: solid 1px #dedede;" class="bg-gray-pale">
                    <th class="t-header">Partner</th>
                    <th class="t-header">Contribution</th>
                    <th class="t-header">Form of Contribution</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="border: solid 1px #dedede;" v-for="(contribution, idx) in data.contributions.contributions" :key="idx">
                    <td class="t-body font-bold">
                      <span v-if="contribution.partner.partnerType === 'Individual'">
                        {{contribution.partner.givenName}} {{contribution.partner.surName}}
                      </span>
                      <span v-if="contribution.partner.partnerType === 'Entity'">
                        {{contribution.partner.registeredName}} <b-badge class="bg-blue">entity</b-badge>
                      </span>
                    </td>
                    <td class="t-body">
                      <span v-if="contribution.monetaryContribution">
                        {{contribution.monetaryContribution.toLocaleString()}}
                      </span>
                    </td>
                    <td class="t-body">
                      {{contribution.formOfContribution}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-3 text-gray" style="border: solid 1px #dedede;">
                <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Beneficial Owners</td>
                  </tr>
                </thead>
                <tbody v-if="data.beneficialOwnerFormRequest">
                  <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Country</td>
                    <td class="t-body">
                      {{data.beneficialOwnerFormRequest.country.name}}
                    </td>
                  </tr>
                  <tr style="border: solid 1px #dedede;">
                    <td class="t-body font-bold">Physical Address</td>
                    <td class="t-body text-uppercase">
                      <p v-if="data.beneficialOwnerFormRequest.village">
                        <span class="mr-1">{{data.beneficialOwnerFormRequest.village.region.name}} > </span>
                        <span class="mx-1">{{data.beneficialOwnerFormRequest.village.district.name}} > </span>
                        <span class="mx-1">{{data.beneficialOwnerFormRequest.village.subcounty.name}} > </span>
                        <span class="mx-1">{{data.beneficialOwnerFormRequest.village.parish.name}} > </span>
                        <span class="mx-1">{{data.beneficialOwnerFormRequest.village.village.name}}</span>
                      </p>
                      <p v-else>N/A</p>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="p-2">
                    <td class="t-body" colspan="12">
                      No beneficial owners submitted
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="min-w-full leading-normal text-gray" style="border: solid 1px #dedede;">
                <tbody v-if="data.beneficialOwnerFormRequest">
                <tr>
                  <td colspan="12">
                    <BeneficialOwners
                        :persons="data.beneficialOwnerFormRequest.beneficialOwners"
                        :entity-persons="data.beneficialOwnerFormRequest.entityBeneficialOwners"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import BeneficialOwners from "@/views/home/commons/BeneficialOwners.vue";

export default {
  name: "StatementOfIntentOfConversionToLLP",
  components: {BeneficialOwners},
  props:{
    data:Object,
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("_", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
  },
};
</script>

<style scoped>

</style>