<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-modal title="Resolution" hide-footer ref="resolution" size="lg">
            <b-overlay :show="loading" rounded="sm" class="py-4">
              <EDMSDocument :file="getEDMSFile"/>
            </b-overlay>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Forfeiture of Shares
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row>
            <b-col cols="10" class="mx-auto mt-3">
              <span class="font-bold">Call On Shares Resolution</span>
              <p>
                <b>Tracking No</b>: {{data.callOnSharesResolution.trackingNo}}<br/>
                <b>Organ</b>: {{data.callOnSharesResolution.organ.replaceAll("_", " ")}}<br/>
                <b>Resolution type</b>: {{data.callOnSharesResolution.resolutionName.replaceAll("_", " ")}}<br/>
                <b>Date Of Meeting</b>: {{dateFormat(data.callOnSharesResolution.dateOfMeeting)}}<br/>
                <b>Subject</b>: {{data.callOnSharesResolution.subject}}<br/>
                <b>Description</b>: {{data.callOnSharesResolution.description}}<br/>
              </p>
              <button class="mt-2 px-3 py-1 bg-blue text-white rounded-sm hover:bg-blue-dark hover:underline" @click="showResolution"> <i class="fa fa-file mr-2"></i> Resolution File</button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8" class="mx-auto">
          <b-row v-for="(forfeiture, idx) in data.forfeitures" :key="idx" class="border-b border-gray py-3">
            <b-col>
              <b-row>
                <b-col>
                  <p>Shareholder: <b class="font-bold text-uppercase">{{forfeiture.shareholding.shareholder.name}}</b></p>
                </b-col>
                <b-col>
                  <p>Shares Forfeited: <b-badge class="bg-red"> <i class="fa fa-times mr-1"></i>{{forfeiture.totalNumberOfShares.toLocaleString()}}</b-badge></p>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;" >
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Shares Forfeited Breakdown</td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in forfeiture.sharesForfeited " :key="idx">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.numberOfShares.toLocaleString()}}
                                </span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.numberOfShares.toLocaleString()}}
                                </span>
                        </td>
                      </tr>
                      </tbody>

                    </table>
                  </b-col>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <b-col>
                    <table class="min-w-full leading-normal text-gray"
                           style="border: solid 1px #dedede;" >
                      <thead>
                      <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Shares After Forfeiture <b-badge class="bg-green font-monospace">final</b-badge></td>
                      </tr>
                      </thead>
                      <thead>
                      <tr>
                        <th class="px-2 py-1">Share Class</th>
                        <th class="px-2 py-1 text-center">Shares</th>
                        <th class="px-2 py-1 text-center">Paid</th>
                        <th class="px-2 py-1 text-center">Not Paid</th>
                      </tr>
                      </thead>
                      <tbody v-for="(share, idx) in forfeiture.finalSharesAfterForfeiture " :key="idx">
                      <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-uppercase font-bold">
                          <span v-if="share.nominalClass !== 'OTHER'">{{share.nominalClass}}</span>
                          <span v-else>{{share.otherClassName}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.numberOfShares.toLocaleString()}}
                                </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountPaid.toLocaleString()}}
                                </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span v-if="share.subClasses.length === 0">
                                  {{share.amountUnPaid.toLocaleString()}}
                                </span>
                        </td>
                      </tr>
                      <tr v-for="(subClass, idx3) in share.subClasses" :key="idx3">
                        <td class="px-2 py-1 text-capitalize">
                          <span>Class {{subClass.nominalClass}}</span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.numberOfShares.toLocaleString()}}
                                </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountPaid.toLocaleString()}}
                                </span>
                        </td>
                        <td class="px-2 py-1 text-center">
                                <span>
                                  {{subClass.amountUnPaid.toLocaleString()}}
                                </span>
                        </td>
                      </tr>
                      </tbody>

                    </table>
                  </b-col>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>

import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ForfeitureOfShares",
  components: { EDMSDocument },
  props:{
    data:Object
  },
  data(){
    return{
      loading:false
    }
  },
  methods:{
    ...mapActions(["fetchCompanyEDMSFile"]),
    showResolution(){
      let data = {
        documentHandle:this.data.callOnSharesResolution.edmsId,
        overlay:""
      }
      this.loading = true
      this.fetchCompanyEDMSFile(data)
      this.$refs.resolution.show()
    }
  },
  computed:{
    ...mapGetters(["getEDMSFile", "getEntityFileError"])
  },
  watch:{
    getEDMSFile(){
      this.loading = false
    },
    getEntityFileError(){
      this.loading = false
      this.errorToast("Download Error", "Retrying...")
      this.showResolution()
    }
  }
};
</script>

<style scoped>

</style>