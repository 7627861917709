<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal title="Application Details" ref="applicationDetails" size="xl" hide-footer>
        <AmendmentFilingDetails :tracking-number="trackingNumber"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Business Name Amendment Applications</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="flex mx-3 mt-3">
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="pre-waiting">
          <span class="h3 mx-auto">{{getAmendmentStatistics.draft.toLocaleString()}}</span>
        </div>
        <div class="pre-waiting">
          <span class="text-sm mx-auto">Drafts</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="waiting">
          <span class="h3 mx-auto">{{getAmendmentStatistics.pending.toLocaleString()}}</span>
        </div>
        <div class="waiting">
          <span class="text-sm mx-auto">In Queue</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="normal">
          <span class="h3 mx-auto">{{getAmendmentStatistics.assigned.toLocaleString()}}</span>
        </div>
        <div class="normal">
          <span class="text-sm mx-auto">Assigned</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="off-normal">
          <span class="h3 mx-auto">{{getAmendmentStatistics.escalated.toLocaleString()}}</span>
        </div>
        <div class="off-normal">
          <span class="text-sm mx-auto">Escalated</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning">
          <span class="h3 mx-auto">{{getAmendmentStatistics.queried.toLocaleString()}}</span>
        </div>
        <div class="warning">
          <span class="text-sm mx-auto">Queried</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="positive">
          <span class="h3 mx-auto">{{getAmendmentStatistics.approved.toLocaleString()}}</span>
        </div>
        <div class="positive">
          <span class="text-sm mx-auto">Approved</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" >
        <div class="warning-secondary">
          <span class="h3 mx-auto">{{getAmendmentStatistics.total.toLocaleString()}}</span>
        </div>
        <div class="warning-secondary">
          <span class="text-sm mx-auto">Total</span>
        </div>
      </div>

    </b-row>
    <b-row class="flex">
      <b-col cols="12">
        <b-row class="pt-3">
          <b-col>
            <AllBusinessAmendmentSubmissions v-on:openDetails="openDetails" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import AllBusinessAmendmentSubmissions
  from "@/views/home/services/amendments/submissions/AllBusinessAmendmentSubmissions";
import AmendmentFilingDetails from "@/views/home/services/amendments/submissions/AmendmentFilingDetails";

export default {
  name: "BusinessNameAmendmentApplications",
  components: {
    AmendmentFilingDetails,
    AllBusinessAmendmentSubmissions,
  },
  data(){
    return{
      currentRoute: "pending",
      trackingNumber:null,
    }
  },
  mounted() {
    this.fetchAmendmentStatistics()
  },
  methods:{
    ...mapActions(["fetchAmendmentStatistics"]),
    select(route){
      this.currentRoute = route
    },
    canViewMenuItem(permission) {
      return this.$can(permission,"general");
    },
    openDetails(trackingNumber){
      this.trackingNumber = trackingNumber
      this.$refs.applicationDetails.show()
    }
  },
  computed:{
    ...mapGetters(["getAmendmentStatistics", "getAmendmentStatisticsError"])
  },
  watch:{
    getAmendmentStatisticsError(){
      this.loading = false
      this.errorToast("Error", "Failed to get amendments statistics")
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.pre-waiting{
  @apply flex text-gray_title;
}

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.off-normal{
  @apply flex text-blue-light;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.warning-secondary{
  @apply flex text-flopurple;
}

.negative{
  @apply flex text-red-light;
}
.stat-count{
  display: inline;
  width: 12%;
  @apply my-1;
}

</style>