<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Form 10 (Share Allotments)
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="10" class="mx-auto">
          <Form10Resolution :data="data" badge="File Share Allotments"/>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Form10Resolution from "@/views/home/services/continuous-filing/task/forms/Form10Resolution";
export default {
  name: "FileForm10",
  components: { Form10Resolution },
  props:{
    data:Object
  },
};
</script>

<style scoped>

</style>