<template>
  <b-row class="my-4">
    <b-col>
      <b-row>
        <b-col cols="8" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Form 18 (Optional Form)
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="8" class="mx-auto">
          <Form18Resolution badge="Filed Address Form" :data="data"></Form18Resolution>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Form18Resolution from "@/views/home/services/continuous-filing/task/forms/Form18Resolution";
export default {
  name: "FileForm18",
  components: { Form18Resolution },
  props:{
    data:Object
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>