<template>
  <b-row>
    <b-col>
      <b-overlay :show="loading" rounded="sm">
        <b-row>
          <b-col cols="12">
            <b-row class="mx-3" v-if="application.lastEscalation">
              <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-green">
                <b-row v-if="messages[0] !== undefined">
                  <b-col>
                    <div class="flex">
                      <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
                      <span class="text-sm font-bold underline text-capitalize">{{messages[0].messageData.sender.toLowerCase()}}</span>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="messages[0] !== undefined">
                  <b-col>
                    <span class="text-sm">{{messages[0].messageData.comment}}</span>
                  </b-col>
                </b-row>
                <b-row v-if="messages[0] !== undefined">
                  <b-col>
                    <span style="font-size:9px">{{dateFormat(messages[0].messageData.createdAt)}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="bg-green rounded text-white" style="padding: 2px 4px; font-size: 9px;">Escalation Response</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mx-3" v-if="application.lastQuery">
              <b-col cols="12" class="py-2 my-2 shadow-md rounded-sm text-gray border-1 border-orange">
                <b-row v-if="messages[0] !== undefined">
                  <b-col>
                    <div class="flex">
                      <i class="fa fa-comment-alt text-orange mr-1 mt-1"></i>
                      <span class="text-sm font-bold underline">{{messages[0].messageData.sender}}</span>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="messages[0] !== undefined">
                  <b-col>
                    <span class="text-sm">{{messages[0].messageData.comment}}</span>
                  </b-col>
                </b-row>
                <b-row v-if="messages[0] !== undefined">
                  <b-col>
                    <span style="font-size:9px">{{dateFormat(messages[0].messageData.createdAt)}}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span class="bg-orange rounded text-white" style="padding: 2px 4px; font-size: 9px;">Query Response</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mx-1 mt-4">
              <b-col>
                <span class="h3 font-bold">{{application.data.companyName}}</span>
              </b-col>
            </b-row>
            <b-row class="mx-1">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2">filing Details</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Tracking Number</th>
                    <th class="px-2 py-1">Status</th>
                    <th class="px-2 py-1">Transaction Type(s)</th>
                    <th class="px-2 py-1">Submission Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" >
                    <td class="px-2 py-1">
                  <span class="text-gray">
                    {{application.data.trackingNo}}
                  </span>
                    </td>
                    <td class="px-2 py-1">
                      <span class="text-blue text-capitalize">{{application.data.status.toLowerCase().replaceAll("_", " ")}}</span>
                    </td>
                    <td class="px-2 py-1">
                        <span v-if="application.data.resolutionType === 'MULTI_FILING'">
                          <p class="text-gray text-capitalize" v-for="(change, idx) in application.data.embeddedChangesBeingFiled" :key="idx">
                            <span v-if="change.typeOfChangeBeingFiled.includes('APPEND')">
                              - Resolution
                            </span>
                            <span v-else>
                              - {{cleanString(change.typeOfChangeBeingFiled)}}
                            </span>
                          </p>
                        </span>
                        <span class="text-gray text-capitalize">
                          {{application.data.resolutionType.replaceAll("_", " ").toLowerCase()}}
                        </span>
                    </td>
                    <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(application.createdAt)}}
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
                <b-col cols="12" v-if="application.data.resolutionType === 'BENEFICIAL_OWNER_SHIP_FORM_METADATA' || application.data.resolutionType === 'NOTICE_OF_CHANGE_OF_BENEFICIAL_OWNER' " >
                    <label class="text-blue border-1 rounded-b-sm w-full my-2 text-center ">
                        <span class="text-center">Payment Not Applicable</span>
                    </label>
                </b-col>
              <b-col v-else cols="12">
                <PRNViewer v-if="application.status !== 'DRAFT'" :tracking-number="application.data.trackingNo"/>
              </b-col>
            </b-row>

            <b-row class="mx-1 mb-5">
              <b-col cols="12">
                <b-row>
                  <b-col>
                    <div class="flex h-full" style="cursor: pointer;">
                      <div :class="[(currentRoute === 'info') ? 'menu-item-active' : 'menu-item']"
                           @click="select('info')">
                        <div class="flex mt-6">
                          <div style="font-size: 12px">Filing Information</div>
                        </div>
                      </div>
                      <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']"
                           @click="select('attachments')">
                        <div class="flex mt-6">
                          <div style="font-size: 12px">Attachments</div>
                        </div>
                      </div>
                      <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']"
                           @click="select('messages')">
                        <div class="flex mt-6">
                          <div style="font-size: 12px">Messages</div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <ContinuousFilingFormDetailsBox :application="application" v-if="currentRoute === 'info'"/>
                    <ContinuousFilingFilesDetails :application="application" v-if="currentRoute === 'attachments'"/>
                    <ContinuousFilingMessagesDetails :application="application" v-if="currentRoute === 'messages'"/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import ContinuousFilingFormDetailsBox
  from "@/views/home/services/continuous-filing/submissions/components/ContinuousFilingFormDetailsBox";
import ContinuousFilingFilesDetails
  from "@/views/home/services/continuous-filing/submissions/components/ContinuousFilingFilesDetails";
import ContinuousFilingMessagesDetails
  from "@/views/home/services/continuous-filing/submissions/components/ContinuousFilingMessagesDetails";
import PRNViewer from "@/views/home/commons/PRNViewer";

export default {
  name: "ContinuousFilingDetails",
  components: { PRNViewer, ContinuousFilingMessagesDetails, ContinuousFilingFilesDetails, ContinuousFilingFormDetailsBox },
  props:{
    trackingNumber:String
  },
  data(){
    return{
      loading: true,
      application: null,
      currentRoute: "info",
      menu:[
        {
          title:"Document Information",
          route:"info",
        },
        {
          title:"Attachment",
          route:"attachments",
        },
      ],
    }
  },
  mounted() {
    this.fetchContinuousFilingDetails(this.trackingNumber)
  },
  methods:{
    ...mapActions(["fetchContinuousFilingDetails"]),
    select(route){
      this.currentRoute = route
    },
    cleanString(name){
      if(name){
        let n = name.replaceAll("_", " ")
        return n.charAt(0).toUpperCase() + n.slice(1)
      }
      return ""
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
    ...mapGetters(["getContinuousFilingDetails"])
  },
  watch:{
    getContinuousFilingDetails(data){
      this.loading = false
      this.application = data
    }
  }
};
</script>

<style scoped>
.title{
  font-size: 22px;
}

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}
</style>