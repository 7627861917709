<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="10" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Amended Memarts
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="10" class="mx-auto">
          <table class="min-w-full leading-normal mt-1 text-gray"
                 style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2 font-bold">Memorandum & Articles of Association</td>
            </tr>
            </thead>
            <tbody>
            <tr class="border-b border-gray-light">
              <td class="px-2 py-1 text-capitalize">Altered Memorandum of Association</td>
              <td class="px-2 py-1">
                <span>{{data.altersMemorandumOfAssociation}}</span>
              </td>
            </tr>
            <tr class="border-b border-gray-light">
              <td class="px-2 py-1 text-capitalize">Altered Articles of Association</td>
              <td class="px-2 py-1">
                <span>{{data.altersArticlesOfAssociation}}</span>
              </td>
            </tr>
            <tr class="border-b border-gray-light" v-if="data.maximumContributionPerSubscriber">
              <td class="px-2 py-1 text-capitalize">Maximum Liability Amount</td>
              <td class="px-2 py-1">
                <span>{{data.maximumContributionPerSubscriber.toLocaleString()}}</span>
              </td>
            </tr>
            </tbody>

          </table>
          <table class="min-w-full leading-normal text-gray"
                 style="border: solid 1px #dedede;">
            <thead>
            <tr class="bg-gray-pale">
              <td colspan="12" class="px-2 py-2 font-bold">Objectives</td>
            </tr>
            </thead>
            <tbody>
            <tr class="border-b border-gray-light" v-for="(item, idx) in data.objectives" :key="idx">
              <td class="px-2 py-1 text-capitalize">
                <b-form-textarea class="text-sm" :value="item.objective" disabled="true"/>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "AmendedMemArts",
  props:{
    data:Object
  },
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>