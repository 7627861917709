<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign to Registrar">
        <AppealAssignment :task-id="taskId" permission="can_view_reservation_tasks"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-modal id="modal-reject" hide-footer ref="rejectReservation" title="Reject Reservation Appeal">
        <b-col cols="12">
          <b-form class="w-full">
            <b-form-textarea
                v-model="description"
                type="text"
                placeholder="Give reason for rejection"
                class="mt-1"
                required>
            </b-form-textarea>
            <b-row>
              <b-col cols="4">
                <button class="btn-reject-hover" variant="danger" @click="onReject">
                  Reject <b-spinner v-if="loadingInvalid" small class="ml-1"></b-spinner>
                </button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-modal>
    </b-row>
    <b-row v-if="reservation != null">
      <b-col cols="12">
        <b-row class="mx-3 mb-3s">
          <b-col cols="6">
            <b-row class="py-2 my-2 shadow-md rounded-sm mr-1 border-1 border-red">
              <b-col>
                <b-badge class="bg-red">Client</b-badge>
                <div class="flex">
                  <i class="fa fa-comment-alt text-red mr-1 mt-1"></i>
                  <span class="text-capitalize font-bold underline hover:text-red">{{reservation.user.fullName.toLowerCase()}}</span>
                </div>
                <p class="text-gray mt-2">
                  {{message}}
                </p>
                <span class="italic text-gray" style="font-size: 10px;">{{dateFormat(time)}}</span>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-row class=" py-2 my-2 shadow-md rounded-sm ml-1 border-1 border-green" >
              <b-col cols="12">
                <b-badge class="bg-green">Registrar</b-badge>
                <div class="flex">
                  <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
                  <span class="text-capitalize font-bold underline hover:text-green">{{reservationStatus.user.firstName}} {{reservationStatus.user.lastName}}</span>
                </div>
                <p class="text-gray mt-2">
                  {{reservationStatus.description}}
                </p>
                <span class="italic text-gray" style="font-size: 10px;">{{dateFormat(reservationStatus.createdAt)}}</span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mx-1 my-2">
          <b-col>
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Reservation Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{reservation.no}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue">{{reservation.status}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(reservation.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateFormat(reservation.createdAt)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1 my-2">
          <b-col>
            <PRNViewer :tracking-number="reservation.no" :prn="reservation.prn"/>
          </b-col>
        </b-row>
        <b-row class="mx-1 mt-2">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Name Options</td>
              </tr>
              <tr>
                <th class="px-2 py-1">#</th>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Text Analysis</th>
                <th class="px-2 py-1">Sound Analysis</th>
                <th class="px-2 py-1">Flags</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" v-for="(option, idx) in getAnalysisReport" :key="idx">
                <td class="px-2 py-1">{{idx + 1}}</td>
                <td class="px-2 py-1">
                          <span class="font-bold" v-if="option.flags.length > 0">
                            <NameHighlight :name="option.name" :flags="option.flags" :idx="idx"/>
                          </span>
                  <span v-else class="font-bold">{{option.name}}</span>
                </td>
                <td class="px-2 py-2">
                  <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer" @click="select('analysis-report')" v-if="option != null">{{option.nameScore.text}}%</span>
                  <span class="text-gray" v-else>--</span>
                </td>
                <td class="px-2 py-2">
                  <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer" @click="select('analysis-report')" v-if="option != null">{{option.nameScore.sound}}%</span>
                  <span class="text-gray " v-else>--</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue underline hover:text-blue-dark" style="cursor: pointer" @click="select('flags')" v-if="option != null">{{option.flags.length + option.rejectedNames.length}}</span>
                  <span class="text-gray " v-else>--</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mx-1 mt-2">
          <b-col cols="6"></b-col>
          <b-col cols="6">
            <b-row>
              <b-col>
                <div class="flex float-end">
                  <button class="btn-approve" @click="assignPrompt">
                    <i class="fa fa-reply text-white mr-1"></i> Assign
                  </button>
                  <button class="btn-reject" @click="rejectPrompt">
                    <i class="fa fa-ban text-white mr-1"></i> Reject
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col cols="12">
            <div class="flex h-full" style="cursor: pointer;">
              <div :class="[(currentRoute === item.route) ? 'menu-item-active' : 'menu-item']" v-for="(item, idx) in menu" :key="idx" @click="select(item.route)">
                <div class="flex mt-6">
                  <div style="font-size: 14px">{{item.title}}</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col>
            <NameReservationFormAppeal :reservation="reservation" v-if="currentRoute === 'form'"/>
            <AnalysisReportAppeal :reservation="reservation" v-if="currentRoute === 'analysis-report'"/>
            <SystemFlagsAppeal :reservation="reservation" v-if="currentRoute === 'flags'"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import NameHighlight from "@/views/home/services/reservation/components/NameHighlight";
import NameReservationFormAppeal from "@/views/home/services/reservation/appeals/tabs/NameReservationFormAppeal";
import AnalysisReportAppeal from "@/views/home/services/reservation/appeals/tabs/AnalysisReportAppeal";
import SystemFlagsAppeal from "@/views/home/services/reservation/appeals/tabs/SystemFlagsAppeal";
import moment from "moment";
import AppealAssignment from "@/views/home/commons/AppealAssignment";
import PRNViewer from "@/views/home/commons/PRNViewer";

export default {
  name: "AppealFile",
  components: {
    PRNViewer,
    AppealAssignment,
    SystemFlagsAppeal,
    AnalysisReportAppeal,
    NameReservationFormAppeal, NameHighlight},
  props:{reservation:{}, message:String, time:String},
  data(){
    return{
      hasComments: false,
      currentRoute: "form",
      selectedId:null,
      margin:"2px",
      hasContent:false,
      loading:true,
      loadingValid:false,
      loadingInvalid:false,
      reservationStatus:{},
      description:null,
      taskId:null,
      report:[],
      menu:[
        {
          title:"Reservation Details",
          route:"form",
        },
        {
          title:"Analysis Report",
          route:"analysis-report",
        },
        {
          title:"System Flags",
          route:"flags",
        },
      ],
      showFinalDecision:false,
      isValid:false
    }
  },
  methods:{
    ...mapActions(["fetchAnalysisReport", "updateAppealStatus", "emptyAnalysisReport"]),
    select(route){
      this.currentRoute = route
    },
    assignPrompt(){
      this.taskId = this.reservation.id
      this.$refs.assignSubmissions.show();
    },
    rejectPrompt(){
      this.$refs.rejectReservation.show();
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    onReject(){
      let data = {
        status:"Invalid",
        description:this.description,
        reservationId:this.reservation.id
      }
      if(this.description != null){
        this.loadingInvalid = true
        this.updateAppealStatus(data)
      }else {
        this.warningToast("Action", "Enter a comment or instruction")
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
    ...mapGetters(["getAnalysisReport", "getAnalysisReportError", "getReservationStatuses", "getAppealSuccess",
      "getAssignmentSuccess", "getAssignmentError", "getReservationError"]),
  },
  watch:{
    getAnalysisReport(){

    },
    getAnalysisReportError(){

    },
    getReservationStatuses(data){
      this.reservationStatus = data[data.length - 1]
    },
    getAppealSuccess(){
      this.successToast("Success", "Appeal validated and assigned")
      this.loadingValid = false
      this.$refs.assignSubmissions.hide();
    },
    getAssignmentSuccess(){
      this.$refs.assignSubmissions.hide();
      window.location.reload()
    },
    getReservationError(data){
      this.loadingInvalid = false
      this.loadingValid = false
      this.errorToast("Error", data.message)
      this.assignPrompt()
    },
    getReservationAssignmentError(data){
      this.loadingInvalid = false
      this.loadingValid = false
      this.errorToast("Error", data.message)
    }
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

th, td{
  font-size: 12px;
}

.btn-approve{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

.btn-reject-hover{
  @apply bg-red border-red text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-reject-hover:hover{
  @apply bg-red-dark;
}
</style>