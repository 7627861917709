<template>
  <div class="bg-white rounded-sm mt-5">
    <b-row>
      <b-modal id="modal-details" size="xl" hide-footer ref="extension-details" title="Extension Details">
        <b-col cols="12">
          <b-row v-if="extension.reservation != null">
            <b-col cols="12" class="px-2 py-1">
              <b-row class="my-2">
                <b-col cols="12" class="py-2" style="background: #b0ea75;">
                  <div class="flex">
                    <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
                    <span class="text-capitalize font-bold underline hover:text-red">{{extension.reservation.user.fullName.toLowerCase()}}</span>
                  </div>
                  <p class="text-gray mt-2">
                    {{extension.reason}}
                  </p>
                  <span class="italic text-gray" style="font-size: 10px;">{{dateFormat(extension.updatedAt)}}</span>
                </b-col>
              </b-row>

              <b-row class="my-2">
                <b-col cols="12" class="bg-inertgrey py-1">
                  <b-row>
                    <b-col cols="6">
                      <p>
                        <span class="text-sm font-bold">Tracking Number</span><br/>
                        <span class="h5 text-blue font-bold">{{extension.reservation.no}}</span>
                        <br/>
                        <span class="text-gray border-l border-t border-b border-blue px-2 py-1 text-xs">Status</span>
                        <span class="text-white border border-blue bg-blue px-2 py-1 text-xs font-bold">{{extension.reservation.status}}</span>
                      </p>
                    </b-col>
                    <b-col cols="6"></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6" class="mt-2">
                      <span class="text-xs border-2 border-blue px-2 py-1 text-blue font-bold text-capitalize">{{cleanEntityType(extension.reservation.type)}}</span>
                      <span class="text-xs border-2 border-green text-green px-2 py-1 ml-2 font-bold text-capitalize">{{cleanEntityType(extension.reservation.subType)}}</span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6" class="mt-2">
                      <p class="text-gray text-xs italic">
                        Submitted {{dateFormat(extension.reservation.createdAt)}}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="my-2">
                  <span class="h3 mr-1">Name:</span>
                  <span class="h3 font-bold">{{extension.reservation.name}}</span>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="8"></b-col>

                <b-col cols="4">
                  <div class="flex float-end">
                    <button class="btn-approve"  @click="onApprove()">
                      <i class="fa fa-check-circle text-white mr-1"></i>Approve
                    </button>
                    <button class="btn-reject" @click="onReject()">
                      <i class="fa fa-ban text-white mr-1"></i>Reject
                    </button>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mt-4">
                  <table class="min-w-full leading-normal mt-1 text-orange-dark" style="border: solid 1px #f1b778;">
                    <thead>
                      <tr class="bg-orange-pale">
                        <td colspan="12" class="px-2 py-2">Timelines</td>
                      </tr>
                      <tr>
                        <th class="px-2 py-1">Reservation Date</th>
                        <th class="px-2 py-1">Expiry Date</th>
                        <th class="px-2 py-1">Days Left</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="border: solid 1px #f1b778;">
                        <td class="px-2 py-1">{{dateFormat(extension.reservation.createdAt)}}</td>
                        <td class="px-2 py-1">{{dateFormat(extension.reservation.expiresOn)}}</td>
                        <td class="px-2 py-1">{{daysLeft}}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="mt-4">
                  <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                      <td colspan="12" class="px-2 py-2">Nature of Business</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1">ISIC Code</th>
                      <th class="px-2 py-1">Breakdown</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                      <td class="px-2 py-1">{{extension.reservation.isic.code}}</td>
                      <td class="px-2 py-1">
                        <ul class="text-blue">
                          <li v-for="(section, idx) in extension.reservation.isic.breakdown"  :key="idx">
                            <span class="text-gray italic hover:font-bold" :style="'margin-left: '+(idx * 15)+'px'">-> {{section}}</span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="mt-4">
                  <table class="min-w-full leading-normal mt-1 text-blue-dark" style="border: solid 1px #bdd7f1;">
                    <thead>
                    <tr class="bg-blue-pale">
                      <td colspan="12" class="px-2 py-2">Applicant Details</td>
                    </tr>
                    <tr>
                      <th class="px-2 py-1">Name</th>
                      <th class="px-2 py-1">Email</th>
                      <th class="px-2 py-1">Phone</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #bdd7f1;">
                      <td class="px-2 py-1 text-capitalize">{{extension.reservation.user.fullName.toLowerCase()}}</td>
                      <td class="px-2 py-1">{{extension.reservation.user.email}}</td>
                      <td class="px-2 py-1">{{extension.reservation.user.phoneNumber}}</td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="mt-4">
                  <PRNViewer :tracking-number="extension.reservation.no" :prn="extension.reservation.prn"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-modal>
    </b-row>

    <div class="mx-auto">
      <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
    </div>

    <b-row>
      <b-col cols="12">
        <table class="min-w-full leading-normal">
          <thead>
          <tr class="border-b border-t border-grey">
            <th class="t-header">Tracking No.</th>
            <th class="t-header">Name</th>
            <th class="t-header">Entity Type</th>
            <th class="t-header">Sub-Entity Type</th>
            <th class="t-header">Submitted by</th>
            <th class="t-header">Submission Date</th>
            <th class="t-header">Expiry Date</th>
            <th class="t-header">Action</th>
          </tr>
          </thead>

          <tbody>
          <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in extensions" :key="idx">
            <td class="t-body text-blue font-bold">{{submission.reservation.no}}</td>
            <td class="t-body font-bold">{{submission.reservation.name}}</td>
            <td class="t-body text-capitalize">{{submission.reservation.type}}</td>
            <td class="t-body text-capitalize">{{cleanEntityType(submission.reservation.subType)}}</td>
            <td class="t-body text-capitalize">{{submission.reservation.user.fullName.toLowerCase()}}</td>
            <td class="t-body">{{dateFormat(submission.reservation.createdAt)}}</td>
            <td class="t-body">{{dateFormat(submission.reservation.expiresOn)}}</td>
            <td class="t-body">
              <div class="flex">
                <button @click="view(submission)" class="view-btn w-full">View</button>
              </div>
            </td>
          </tr>
          <tr>
            <td v-if="extensions.length == 0" colspan="8" class="bg-inertgrey text-center py-1">
              <span class="text-sm">No results found</span>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-row class="mx-1" v-if="pagination.totalPages > 1">
      <b-col cols="1">
        <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
      </b-col>
      <b-col cols="1">
        <b-row>
          <b-col cols="12">
            <p class="paginate-details text-center">
              <b-badge class="bg-blue text-white">
                {{currentPage}}/{{pagination.totalPages}}
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" >
        <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">Next</button>
      </b-col>
      <b-col class="9"></b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import PRNViewer from "@/views/home/commons/PRNViewer";

export default {
  name: "PendingReservationExtensions",
  components: { PRNViewer },
  data(){
    return{
      selectedId:null,
      margin:"2px",
      hasContent:false,
      paymentInfo:null,
      extension:{
        reservation:null
      },
      loading:true,
      message:null,
      currentPage:1,
      limit:6,
      extensions:[],
      pagination:{
        totalElements: 0,
        totalPages:0
      },
      form:{
        extensionId:null,
        status:null
      },
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey bg-header_grey"
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchReservationExtensions", "extendReservation"]),
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    fetch(){
      let data = {
        statuses:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchReservationExtensions(data)
    },
    next(){
      if(this.currentPage < this.pagination.totalPages){
        this.currentPage++
        this.fetch()
      }
    },
    previous(){
      if(this.currentPage > 1){
        this.currentPage--
        this.fetch()
      }else{
        this.warningToast("Observation", "No more data to show")
      }
    },
    view(submission){
      this.extension = submission
      this.form.extensionId = submission.id
      this.$refs["extension-details"].show()
    },
    onApprove(){
      this.loading = true
      this.form.status = "Approved"
      this.extendReservation(this.form)
      this.$refs["extension-details"].hide()
    },
    onReject(){
      this.loading = true
      this.form.status = "Rejected"
      this.extendReservation(this.form)
      this.$refs["extension-details"].hide()
    },
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    clearForm(){
      this.form = {
        description:null,
        reservationId:null,
        status:null
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
    ...mapGetters([ "getReservationExtensions", "getReservationSuccess", "getReservationError"]),
    daysLeft(){
      let now = moment(new Date()).format("YYYY-MM-DD")
      return Math.floor((Date.parse(this.extension.reservation.expiresOn) - Date.parse(now)) / (1000*60*60*24))
    }
  },
  watch:{
    getReservationExtensions(data){
      this.loading = false
      this.extensions = data.content
      this.pagination = {
        totalPages:data.totalPages,
        totalElements:data.totalElements
      }
    },
    getReservationSuccess(){
      this.loading = false
      this.successToast("Action on Extension!", "Done Successfully")
      this.fetch()
      this.clearForm()
    },
    getReservationError(data){
      this.loading = false
      this.errorToast("Error", JSON.stringify(data))
      this.clearForm()
    },
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/table.css";

.icon{
  width: 170px;
}

.view-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-blue text-blue text-xs w-full;
}

.view-btn:hover{
  @apply bg-blue text-white;
}

.approve-btn{
  @apply px-3 py-1 bg-white border-2 font-bold border-green text-green text-xs w-full;
}

.approve-btn:hover{
  @apply bg-green text-white;
}

.reject-btn{
  @apply px-3 py-1 bg-white border-2 border-red text-red font-bold text-xs w-full;
}

.reject-btn:hover{
  @apply bg-red text-white;
}
.paginate-details{
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn{
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover{
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn{
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

.btn-approve{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-reject{
  @apply border bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1 bg-white;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-reject:hover{
  @apply bg-red border-red text-white;
}

</style>