<template>
  <div>
    <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Data Update">
        <EscalationDataUpdate :application-id="task.applicationId" :level="2" type="ci-data-update" permission="can_view_company_data_update_escalations"/>
      </b-modal>
    </b-row>
    <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
      <b-row>
        <b-col>
          <p class="text-sm">
            <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
            This action can only be reversed by your superiors. Only confirm if you are certain that this is the appropriate Final Decision
          </p>
          <p class="mt-3 text-sm italic">
            <b>Note:</b><br/>
            All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the account that initiated are tagged to the action.
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="4">
          <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full" @click="cancelConfirmation">
            <i class="fa fa-minus-square text-blue mr-2"></i>Cancel</button>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="4">
          <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark" @click="yesConfirmation">
            <i class="fa fa-check-circle text-white mr-1"></i> Accept</button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
      <b-col cols="12">
        <b-form class="w-full">
          <b-form-textarea
            v-model="form.description"
            type="text"
            placeholder="Give description of query"
            class="mt-1"
            required>
          </b-form-textarea>
          <p v-if="form.description" class="my-2 text-xs">{{form.description.length}}/{{maxText}}</p>
          <b-row>
            <b-col cols="4">
              <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                Query
                <b-spinner small v-if="loading"></b-spinner>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-modal>
    <b-row class="mx-1">
      <b-col cols="12">
        <b-row class="mt-2">
          <b-col>
            <b-alert variant="success" show>
              <i class="fa fa-info-circle mx-1"></i> This application was fully approved on <b>Verification Level 2</b>. Go through it once again to ensure that nothing has been left unchecked.
              This is a security mechanism to allow a testimony of more than one so that we have a thorough check of every entity before it is updated on the platform
            </b-alert>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span style="font-size: 30px; font-weight: bold">
              {{task.applicationResponse.name}} <label v-if="task.applicationResponse.nameOnFile" class="mx-1 text-blue">( Name on File:  {{task.applicationResponse.nameOnFile}} )</label>
            </span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Update Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Registration Number</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
                <th class="px-2 py-1">Level</th>
                <th class="px-2 py-1">Submission Date</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.trackingNo}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{task.applicationResponse.brn}}
                  </span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-blue text-capitalize">{{task.status.toLowerCase()}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{cleanEntityType(task.applicationResponse.subType)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">Level {{task.applicationResponse.applicationLevel}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{dateTimeFormat(task.applicationResponse.submissionDate)}}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Summary</td>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                  <td class="px-2 py-1 font-bold">
                      Date of Registration in Uganda
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray">
                   {{dateFormat(task.applicationResponse.registrationDate)}}
                  </span>
                  </td>
              </tr>
              <tr style="border: solid 1px #dedede;" v-if="task.applicationResponse.subType === 'foreign-company'">
                  <td class="px-2 py-1 font-bold">

                      Date of Incorporation in the country of origin
                  </td>
                  <td class="px-2 py-1">
                  <span class="text-gray">
                   {{dateFormat(task.form24.foreignIncorporationDate)}}
                  </span>
                  </td>
              </tr>

              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1 font-bold">
                  Last filed Annual Returns
                </td>
                <td class="px-2 py-1">
                  <pre class="text-gray">
                   {{task.lastYearFiled}}
                  </pre>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="my-3">
          <b-col cols="12" md="4" lg="6"></b-col>
          <b-col cols="12" md="8" lg="6">
            <div class="flex float-end">
<!--              <button class="btn-escalate" @click="escalatePrompt">-->
<!--                <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate-->
<!--              </button>-->
              <button type="button" class="btn-approve" @click="onApprove">
                <i class="fa fa-check-circle text-white mr-1"></i> Approve
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <span class="font-bold">Total EDMS Documents: {{edmsDocuments.length}}</span>
                <button class="bg-blue px-2 py-1 text-white rounded-sm mx-2" @click="pullDocuments(task)">Reload</button>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col class="left">
                <table class="min-w-full leading-normal mt-2">
                  <thead>
                  <tr class="border-b border-t border-grey">
                    <th class="t-header">#</th>
                    <th class="t-header">Name</th>
                    <th class="t-header">Classification</th>
                    <th class="t-header text-center">Action</th>
                  </tr>
                  </thead>
                  <thead>
                  <tr>
                    <td colspan="12">
                      <div class="mx-auto">
                        <b-progress value="100" v-if="loadingDocuments"  variant="#007ac5" height="0.2rem" animated></b-progress>
                      </div>
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(document, idx) in edmsDocuments" :key="idx">
                    <td class="t-body">{{idx + 1}}</td>
                    <td class="t-body font-bold">
                      {{document.fileName}}
                      <p class="mt-1 italic" style="font-size: 9px;">{{dateTimeFormat(document.fileDate)}}</p>
                    </td>
                    <td class="t-body">
                      <b-form-select v-model="document.documentKey"
                                     class="w-full text-xs form-select paginate-details"
                                     v-on:change="fetch" :options="classifications">
                      </b-form-select>
                    </td>
                    <td class="t-body text-center">
                      <button class="bg-blue text-white px-2 py-1 rounded-sm" @click="openFile(document)">View <b-spinner v-if="fileLoader && selectedDocumentHandle === document.edmsId" small></b-spinner></button>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="edmsDocuments.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                      <span class="text-sm">No documents found</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col class="right bg-gray" style="min-height: 540px;">
                <EDMSDocument v-if="file.Base64FileStream" :file="file"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import EscalationDataUpdate from "@/views/home/commons/EscalationDataUpdate.vue";
import EDMSDocument from "@/views/home/services/registry/business/document/EDMSDocument.vue";

export default {
  name: "CompanyDataUpdateTaskLevel3",
  components: { EDMSDocument, EscalationDataUpdate},
  props:{
    task:Object
  },
  data(){
    return{
      margin:"2px",
      currentRoute: "forms",
      loading:false,
      fileLoader:false,
      messages:[],
      taskId:null,
      maxText:500,
      snapshot:null,
      snapshots:[],
      form:{
        applicationId:null,
        description:null,
        documentMapper:[],
        status:null,
      },
      file:{
        extension:"pdf",
        Base64FileStream:null
      },
      edmsDocuments:[],
      loadingDocuments:false,
      selectedDocumentHandle:null,
      action:null,
      rows:{
        odd:"border-t border-grey hover:bg-header_grey",
        even:"border-t border-grey hover:bg-header_grey"
      },
      classifications:[
        {text:"Form 1", value:"SIGNED_COPY_FORM_1"},
        {text:"Form S18", value:"SIGNED_COPY_FORM_S18"},
        {text:"Form A1", value:"SIGNED_COPY_FORM_A1"},
        {text:"Form 18", value:"SIGNED_COPY_FORM_18"},
        {text:"Form 20", value:"SIGNED_COPY_FORM_20"},
        {text:"Form 10", value:"SIGNED_COPY_FORM_10"},
        {text:"Form 19", value:"SIGNED_COPY_FORM_19"},
        {text:"Form 13", value:"SIGNED_COPY_FORM_13"},
        {text:"Form A23", value:"SIGNED_COPY_FORM_A23"},
        {text:"Form 24", value:"SIGNED_COPY_FORM_24"},
        {text:"Form 25", value:"SIGNED_COPY_FORM_25"},
        {text:"Form 26", value:"SIGNED_COPY_FORM_26"},
        {text:"Form 30", value:"SIGNED_COPY_FORM_30"},
        {text:"Form 12", value:"SIGNED_COPY_FORM_12"},
        {text:"Form 11", value:"SIGNED_COPY_FORM_11"},
        {text:"Form 14", value:"SIGNED_COPY_FORM_14"},
        {text:"Form 15", value:"SIGNED_COPY_FORM_15"},
        {text:"Form 17", value:"SIGNED_COPY_FORM_17"},
        {text:"Form 4", value:"SIGNED_COPY_FORM_4"},
        {text:"Form 5", value:"SIGNED_COPY_FORM_5"},
        {text:"Form 21", value:"SIGNED_COPY_FORM_21"},
        {text:"Form 22", value:"SIGNED_COPY_FORM_22"},
        {text:"Form 2", value:"SIGNED_COPY_FORM_2"},
        {text:"Form 3", value:"SIGNED_COPY_FORM_3"},
        {text:"Form 6", value:"SIGNED_COPY_FORM_6"},
        {text:"Form 7", value:"SIGNED_COPY_FORM_7"},
        {text:"Certificate", value:"CERTIFICATE"},
        {text:"Annual Returns", value:"ANNUAL_RETURNS"},
        {text:"Re registration Form 5", value:"SIGNED_COPY_RE_REGISTRATION_FORM_5"},
        {text:"Re registration Form 4", value:"SIGNED_COPY_RE_REGISTRATION_FORM_4"},
        {text:"Memarts", value:"MEMARTS"},
        {text:"Statement in Lieu of Prospectus", value:"STATEMENT_IN_LIEU_OF_PROSPECTUS"},
        {text:"Re registration Certificate", value:"REGISTRATION_CERTIFICATE"},
        {text:"Conversion Certificate", value:"CONVERSION_CERTIFICATE"},
        {text:"Name Change Certificate", value:"CHANGE_NAME_CERTIFICATE"},
        {text:"Name Gazette", value:"CHANGE_NAME_GAZETTE"},
        {text:"Resolution", value:"RESOLUTION"},
        {text:"Balance Sheet", value:"BALANCE_SHEET"},
        {text:"Statutory Declaration", value:"STATUTORY_DECLARATION"},
        {text:"Valuation Report", value:"VALUATION_REPORT"},
        {text:"Transfer Form", value:"TRANSFER_FORM"},
        {text:"Call on Shares Notice", value:"CALL_ON_SHARES_NOTICE"},
        {text:"Call on Shares", value:"CALL_ON_SHARES"},
        {text:"Forfeiture Notice", value:"FORFEITURE_NOTICE"},
        {text:"Charge Certificate", value:"CHARGE_CERTIFICATE"},
        {text:"Dispensation Letter", value:"LETTER_REQUESTING_TO_DISPENSE_WITH_THE_WORD_LIMITED"},
        {text:"License to Dispense", value:"LICENSE_TO_DISPENSE_WITH_LIMITED"},
        {text:"Other", value:"OTHER"}
      ]
    }
  },
  methods:{
    ...mapActions(["updateCompanyDataUpdateStatus", "fetchAllCompanyEDMSDocuments", "fetchCompanyEDMSFile"]),
    onApprove(){
      this.form.applicationId = this.task.applicationId
      this.form.status = "APPROVED"
      this.action = "final"
      this.$refs.confirm.show();
    },
    onQuery(){
      this.form.applicationId = this.task.applicationId
      this.form.status = "QUERIED"
      this.action = "final"
      if(this.form.description != null){
        this.$refs.confirm.show();
      }else{
        this.errorToast("Missing Information", "Provide a message for this final decision")
      }
    },
    select(route){
      this.currentRoute = route
    },
    yesConfirmation(){
      if(this.action === "final"){

        let mappedDocuments = []
        for(let i = 0 ; i < this.edmsDocuments.length; i++){
          let doc = this.edmsDocuments[i]
          if(doc.documentKey != null){
            mappedDocuments.push(doc)
          }
        }

        if(mappedDocuments.length < this.edmsDocuments.length){
          this.errorToast("Error", "Classify all documents before approval")
        }else{
          this.loading = true
          let i = 0
          mappedDocuments.forEach(e => {
            e.name = e.documentKey
            if(e.documentKey === "OTHER"){
              e.documentKey = e.fileName +" "+ e.fileDate + i
            }
            i++
          })
          this.form.documentMapper = mappedDocuments
          this.updateCompanyDataUpdateStatus(this.form)
        }

      }
      if(this.action === "escalate"){
        this.$refs.escalateSubmissions.show();
      }
      this.cancelConfirmation()
    },
    cancelConfirmation(){
      this.action = null
      this.$refs.confirm.hide();
    },
    escalatePrompt(){
      this.action = "escalate"
      this.$refs.confirm.show();
    },
    openFile(file) {
      this.selectedDocumentHandle = file.edmsId
      this.file = {
        extension:"pdf",
        Base64FileStream:null
      }
      let data = {
        documentHandle:file.edmsId,
        overlay:""
      }
      this.fileLoader = true
      this.fetchCompanyEDMSFile(data)
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    dateTimeFormat(date){
      return moment(date).format("DD-MM-YYYY HH:mm")
    },
    pullDocuments(task){
      let data = {
        Keywords:{
          NewRegistrationNo:task.applicationResponse.brn
        }
      }
      this.loadingDocuments = true
      this.fetchAllCompanyEDMSDocuments(data)
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    }
  },
  computed:{
    ...mapGetters([
      "getDataUpdateStatusSuccess",
      "getDataUpdateCompanyUpdateStatusError",
      "getCompanyDataUpdateTask",
      "getCompanyDataUpdateTaskError",
      "getAllCompanyEDMSDocuments", "getEDMSFile",
        "getCompanyDataUpdateError"
    ])
  },
  watch:{
    getEDMSFile(data){
      this.fileLoader = false
      this.file = data
    },
    getCompanyDataUpdateTask(task){
      this.pullDocuments(task)
    },
    getCompanyDataUpdateTaskError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getDataUpdateStatusSuccess(){
      this.loading = false
      this.successToast("Success!", "Final Decision Successful")
      window.location.reload()
    },
    getCompanyUpdateStatusSuccessError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getAllCompanyEDMSDocuments(data){
      this.loadingDocuments = false
      this.edmsDocuments = []
      data.DocumentResults.forEach(document => {
        let item = {
          edmsId:document.documentHandle,
          name:null,
          fileName:document.Name,
          fileDate: document.docDate,
          documentKey:null
        }
        this.edmsDocuments.push(item)
      })
    },
      getCompanyDataUpdateError(error){
          this.errorToast("Data Update Error", error.data.message);
      }
  }
}
</script>

<style scoped>

.icon{
  width: 170px;
}

th, td{
  font-size: 12px;
}

.btn-escalate{
  @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
  @apply text-blue-dark;
}

.btn-approve{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-cancel{
  @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
  @apply bg-green border-green text-white;
}

.btn-query:hover{
  @apply bg-orange border-orange text-white;
}

.btn-cancel:hover{
  @apply bg-red border-red text-white;
}

.btn-approve-hover{
  @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
  @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover{
  @apply bg-green-dark;
}

.btn-query-hover:hover{
  @apply bg-orange-dark;
}

.left{
  float:left;
  display:block;
  overflow-y:auto;
  height: 800px;
}

.right{
  float:none;
  display:block;
  overflow:auto;
}
</style>