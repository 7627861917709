 <template>
  <b-row>
    <b-col>
      <b-row class="mx-1 my-2">
        <b-col cols="12" >
          <b-row v-for="(message, idx) in messages" :key="idx">
            <b-col cols="11" class="py-2 rounded-md bg-header_grey my-2 text-gray">
              <b-row>
                <b-col>
                  <span class="text-sm font-bold" v-if="message.user">{{message.user.firstName}} {{message.user.lastName}}</span>
                  <span v-if="message.user.accountType === 'INDIVIDUAL'" class="bg-green rounded text-white text-capitalize px-1 ml-1"
                        style="font-size: 8px; padding-bottom: 2px; padding-top: 2px;">
                      Applicant
                  </span>
                  <span v-else class="bg-blue rounded text-white text-capitalize px-1 ml-1"
                        style="font-size: 8px; padding-bottom: 2px; padding-top: 2px;">
                      Backoffice
                  </span>
                  <br/>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span class="text-sm">{{message.description}}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span style="font-size:9px" class="text-blue italic">{{message.dateTime}} | {{message.statusLabel}}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="messages.length === 0">
            <b-col cols="11" class="mx-auto rounded-md bg-header_grey my-2 text-gray">
              <b-row>
                <b-col>
                  <span class="text-xs font-bold">No messages yet</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ReservationDetailsMessages",
  props:{
    reservationId:String
  },
  data(){
    return{
      messages:[]
    }
  },
  mounted(){
    this.fetchReservationStatuses(this.reservationId)
  },
  methods:{
    ...mapActions(["fetchReservationStatuses"])

  },
  computed:{
    ...mapGetters(["getReservationStatuses", "getReservationTask"])
  },
  watch:{
    getReservationStatuses(data){
      for(let i = 0; i<data.length; i++){
        let message = data[i]
        if(message.description !== '' && message.description != null){
          this.messages.push(message)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>