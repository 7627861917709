<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col cols="12" class="mx-auto">
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Form 17 (Charge)
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12" class="mx-auto">
          <b-row>
            <b-col>
              <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;" v-if="data">
                <thead>
                <tr class="bg-gray-pale">
                  <td colspan="12" class="px-2 py-2 font-bold">Property Charges</td>
                </tr>
                </thead>
                <tr>
                  <th class="px-2 py-1">Type of Charge</th>
                  <th class="px-2 py-1">Nature Of Satisfaction</th>
                  <th class="px-2 py-1">Amount Secured</th>
                  <th class="px-2 py-1">Property Particulars</th>
                  <th class="px-2 py-1">Commission</th>
                  <th class="px-2 py-1">Allowance</th>
                  <th class="px-2 py-1">Discount</th>
                  <th class="px-2 py-1">Property is Foreign</th>
                  <th class="px-2 py-1">DOI of Evidence</th>
                  <th class="px-2 py-1">Date of Charge</th>
                  <th class="px-2 py-1">Entitled Entities</th>
                  <th class="px-2 py-1">Secured Amounts</th>
                </tr>
                <tbody>
                <tr style="border: solid 1px #dedede;" v-for="(satisfaction, idx) in data.satisfactions" :key="idx">
                  <td class="px-2 py-1 text-capitalize text-blue">{{satisfaction.charge.typeOfCharge}}</td>
                  <td class="px-2 py-1 text-capitalize text-blue">{{satisfaction.natureOfSatisfaction}}</td>
                  <td class="px-2 py-1 text-capitalize">
                    <span v-if="satisfaction.charge.currency">{{satisfaction.charge.currency}}. </span>
                    {{satisfaction.charge.amountSecured.toLocaleString()}}
                  </td>
                  <td class="px-2 py-1 text-capitalize">{{satisfaction.charge.shortParticularsOrDescriptionOfCharge}}</td>
                  <td class="px-2 py-1 text-capitalize">{{satisfaction.charge.commission.toLocaleString()}}</td>
                  <td class="px-2 py-1 text-capitalize">
                        <span v-if="satisfaction.charge.allowance">
                          {{satisfaction.charge.allowance.toLocaleString()}}
                        </span>
                    <span v-else>NIL</span>
                  </td>
                  <td class="px-2 py-1 text-capitalize">
                        <span v-if="satisfaction.charge.discount">
                          {{satisfaction.charge.discount.toLocaleString()}}
                        </span>
                    <span v-else>NIL</span>
                  </td>
                  <td class="px-2 py-1 font-monospace text-lowercase">{{satisfaction.charge.isPropertyForeign}}</td>
                  <td class="px-2 py-1 text-capitalize">{{dateFormat(satisfaction.charge.dateOfInstrumentOfEvidence)}}</td>
                  <td class="px-2 py-1 text-capitalize">{{dateFormat(satisfaction.charge.dateOfCharge)}}</td>
                  <td class="px-2 py-1 font-monospace">
                    <button class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-green-dark" @click="view(satisfaction.charge.entitledEntities)">
                      View <span v-if="satisfaction.charge.entitledEntities"
                                 class="cursor-pointer bg-white rounded-full px-1 text-blue">
                       {{satisfaction.charge.entitledEntities.length}}</span>
                    </button>
                  </td>
                  <td class="px-2 py-1 font-monospace">
                    <button class="bg-blue text-white px-2 py-1 rounded-sm hover:bg-green-dark" @click="viewSecuredAmounts(satisfaction.securedAmountBreakdowns)">
                      View <span v-if="satisfaction.securedAmountBreakdowns"
                                 class="cursor-pointer bg-white rounded-full px-1 text-blue">
                       {{satisfaction.securedAmountBreakdowns.length}}</span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-modal ref="entitledEntities" size="lg" title="Entitled Entities" hide-footer>
            <b-row>
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  </thead>
                  <tr>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1">Description </th>
                    <th class="px-2 py-1">Address</th>
                  </tr>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" v-for="(entity, idx) in entities" :key="idx">
                    <td class="px-2 py-1 text-capitalize">{{entity.name}}</td>
                    <td class="px-2 py-1 text-capitalize">{{entity.description}}</td>
                    <td class="px-2 py-1 text-capitalize">
                      <span v-if="entity.registeredPhysicalAddress">
                        {{entity.registeredPhysicalAddress.country.name}} ->
                        {{entity.registeredPhysicalAddress.region.name}} ->
                        {{entity.registeredPhysicalAddress.district.name}} ->
                        {{entity.registeredPhysicalAddress.subcounty.name}} ->
                        {{entity.registeredPhysicalAddress.parish.name}} ->
                        {{entity.registeredPhysicalAddress.village.name}}
                      </span>
                      <span v-else>
                        {{foreignPostalAddress}}
                      </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-modal>
          <b-modal ref="amountBreakdowns" size="lg" title="Secured Amount Breakdowns" hide-footer>
            <b-row>
              <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  </thead>
                  <tr>
                    <th class="px-2 py-1">Instrument Number</th>
                    <th class="px-2 py-1">Currency</th>
                    <th class="px-2 py-1">Amount Secured</th>
                    <th class="px-2 py-1">Amount Satisfied</th>
                  </tr>
                  <tbody>
                  <tr style="border: solid 1px #dedede;" v-for="(entity, idx) in amounts" :key="idx">
                    <td class="px-2 py-1 text-capitalize">{{entity.instrumentNumber}}</td>
                    <td class="px-2 py-1 text-uppercase">{{entity.currency}}</td>
                    <td class="px-2 py-1 text-capitalize">{{entity.securedAmount.toLocaleString()}}</td>
                    <td class="px-2 py-1 text-capitalize">{{entity.amountSatisfied.toLocaleString()}}</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-modal>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "FileForm17",
  props:{
    data:Object
  },
  data(){
    return{
      entities:[],
      amounts:[]
    }
  },
  methods:{
    dateFormat(date){
     if(date){
       return moment(date).format("DD-MM-YYYY")
     }
     return ""
    },
    view(entities){
      this.entities = entities
      this.$refs.entitledEntities.show()
    },
    viewSecuredAmounts(amounts){
      this.amounts = amounts
      this.$refs.amountBreakdowns.show()
    },
  }
};
</script>

<style scoped>

th, td{
  font-size: 12px;
}

</style>